/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
GENERAL SETTINGS
----------------------------------------
Read more about settings and
USWDS style tokens in the documentation:
https://designsystem.digital.gov/design-tokens
----------------------------------------
*/
/*
----------------------------------------
Image path
----------------------------------------
Relative image file path
----------------------------------------
*/
/*
----------------------------------------
Show compile warnings
----------------------------------------
Show Sass warnings when functions and
mixins use non-standard tokens.
AND
Show updates and notifications.
----------------------------------------
*/
/*
----------------------------------------
Namespace
----------------------------------------
*/
/*
----------------------------------------
Prefix separator
----------------------------------------
Set the character the separates
responsive and state prefixes from the
main class name.
The default (":") needs to be preceded
by two backslashes to be properly
escaped.
----------------------------------------
*/
/*
----------------------------------------
Layout grid
----------------------------------------
Should the layout grid classes output
with !important
----------------------------------------
*/
/*
----------------------------------------
Border box sizing
----------------------------------------
When set to true, sets the box-sizing
property of all site elements to
`border-box`.
----------------------------------------
*/
/*
----------------------------------------
Focus styles
----------------------------------------
*/
/*
----------------------------------------
Icons
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
TYPOGRAPHY SETTINGS
----------------------------------------
Read more about settings and
USWDS typography tokens in the documentation:
https://designsystem.digital.gov/design-tokens/typesetting/overview/
----------------------------------------
*/
/*
----------------------------------------
Root font size
----------------------------------------
Setting $theme-respect-user-font-size to
true sets the root font size to 100% and
uses ems for media queries
----------------------------------------
$theme-root-font-size only applies when
$theme-respect-user-font-size is set to
false.

This will set the root font size
as a specific px value and use px values
for media queries.

Accepts true or false
----------------------------------------
*/
/*
----------------------------------------
Global styles
----------------------------------------
Adds basic styling for the following
unclassed elements:

- paragraph: paragraph text
- link: links
- content: paragraph text, links,
  headings, lists, and tables
----------------------------------------
*/
/*
----------------------------------------
Broswer compatibility mode
----------------------------------------
When true, outputs woff and ttf font 
formats in addition to woff2
----------------------------------------
*/
/*
----------------------------------------
Font path
----------------------------------------
Relative font file path
----------------------------------------
*/
/*
----------------------------------------
Custom typeface tokens
----------------------------------------
Add a new custom typeface token if
your project uses a typeface not already
defined by USWDS.
----------------------------------------
USWDS defines the following tokens
by default:
----------------------------------------
'georgia'
'helvetica'
'merriweather'
'open-sans'
'public-sans'
'roboto-mono'
'source-sans-pro'
'system'
'tahoma'
'verdana'
----------------------------------------
Add as many new tokens as you have
custom typefaces. Reference your new
token(s) in the type-based font settings
using the quoted name of the token.

For example:

$theme-font-type-cond: 'example-font-token';

display-name:
The display name of your font

cap-height:
The height of a 500px `N` in Sketch
----------------------------------------
You should change `example-[style]-token`
names to something more descriptive.
----------------------------------------
*/
/*
----------------------------------------
Type-based font settings
----------------------------------------
Set the type-based tokens for your
project from the following tokens,
or from any new font tokens you added in
$theme-typeface-tokens.
----------------------------------------
'georgia'
'helvetica'
'merriweather'
'open-sans'
'public-sans'
'roboto-mono'
'source-sans-pro'
'system'
'tahoma'
'verdana'
----------------------------------------
*/
/*
----------------------------------------
Custom font stacks
----------------------------------------
Add custom font stacks to any of the
type-based fonts. Any USWDS typeface
token already has a default stack.

Custom stacks don't need to include the
font's display name. It will
automatically appear at the start of
the stack.
----------------------------------------
Example:
$theme-font-type-sans: 'source-sans-pro';
$theme-font-sans-custom-stack: "Helvetica Neue", Helvetica, Arial, sans;

Output:
font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans;
----------------------------------------
*/
/*
----------------------------------------
Add any custom font source files
----------------------------------------
If you want USWDS to generate additional
@font-face declarations, add your font
data below, following the example that
follows.
----------------------------------------
USWDS automatically generates @font-face
declarations for the following

'merriweather'
'public-sans'
'roboto-mono'
'source-sans-pro'

These typefaces not require custom
source files.
----------------------------------------
EXAMPLE

- dir:
  Directory relative to $theme-font-path
- This directory should include fonts saved as
  .woff2
  ExampleSerif-Normal.woff2

$theme-font-serif-custom-src: (
  dir: 'custom/example-serif',
  roman: (
    100: false,
    200: false,
    300: 'ExampleSerif-Light',
    400: 'ExampleSerif-Normal',
    500: false,
    600: false,
    700: 'ExampleSerif-Bold',
    800: false,
    900: false,
  ),
  italic: (
    100: false,
    200: false,
    300: 'ExampleSerif-LightItalic',
    400: 'ExampleSerif-Italic',
    500: false,
    600: false,
    700: 'ExampleSerif-BoldItalic',
    800: false,
    900: false,
  ),
);
----------------------------------------
*/
/*
----------------------------------------
Role-based font settings
----------------------------------------
Set the role-based tokens for your
project from the following font-type
tokens.
----------------------------------------
'cond'
'icon'
'lang'
'mono'
'sans'
'serif'
----------------------------------------
*/
/*
----------------------------------------
Type scale
----------------------------------------
Define your project's type scale using
values from the USWDS system type scale

1-20
----------------------------------------
*/
/*
----------------------------------------
Font weights
----------------------------------------
Assign weights 100-900
Or use `false` for unneeded weights.
----------------------------------------
*/
/*
----------------------------------------
General typography settings
----------------------------------------
Type scale tokens
----------------------------------------
micro:      10px
1:          12px
2:          13px
3:          14px
4:          15px
5:          16px
6:          17px
7:          18px
8:          20px
9:          22px
10:         24px
11:         28px
12:         32px
13:         36px
14:         40px
15:         48px
16:         56px
17:         64px
18:         80px
19:         120px
20:         140px
----------------------------------------
Line height tokens
----------------------------------------
1:    1
2:    1.15
3:    1.35
4:    1.5
5:    1.62
6:    1.75
----------------------------------------
Font role tokens
----------------------------------------
'ui'
'heading'
'body'
'code'
'alt'
----------------------------------------
Measure (max-width) tokens
----------------------------------------
1:       44ex
2:       60ex
3:       64ex
4:       68ex
5:       74ex
6:       88ex
none:    none
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
COLOR SETTINGS
----------------------------------------
Read more about settings and
USWDS color tokens in the documentation:
https://designsystem.digital.gov/design-tokens/color
----------------------------------------
*/
/*
----------------------------------------
Theme palette colors
----------------------------------------
*/
/*
----------------------------------------
State palette colors
----------------------------------------
*/
/*
----------------------------------------
General colors
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
COMPONENT SETTINGS
----------------------------------------
Read more about settings and
USWDS style tokens in the documentation:
https://designsystem.digital.gov/design-tokens
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
SPACING SETTINGS
----------------------------------------
Read more about settings and
USWDS spacing units tokens in the
documentation:
https://designsystem.digital.gov/design-tokens/spacing-units
----------------------------------------
*/
/*
----------------------------------------
Border radius
----------------------------------------
2px           2px
0.5           4px
1             8px
1.5           12px
2             16px
2.5           20px
3             24px
4             32px
5             40px
6             48px
7             56px
8             64px
9             72px
----------------------------------------
*/
/*
----------------------------------------
Column gap
----------------------------------------
2px         2px
0.5         4px
1           8px
2           16px
3           24px
4           32px
5           40px
6           48px
----------------------------------------
*/
/*
----------------------------------------
Grid container max-width
----------------------------------------
mobile
mobile-lg
tablet
tablet-lg
desktop
desktop-lg
widescreen
----------------------------------------
*/
/*
----------------------------------------
Site
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
UTILITIES SETTINGS
----------------------------------------
Read more about settings and
USWDS utilities in the documentation:
https://designsystem.digital.gov/utilities
----------------------------------------
*/
/*
----------------------------------------
map-collect()
----------------------------------------
Collect multiple maps into a single
large map
source: https://gist.github.com/bigglesrocks/d75091700f8f2be5abfe
----------------------------------------
*/
/*
----------------------------------------
Utility breakpoints
----------------------------------------
Which breakpoints does your project
need? Select as `true` any breakpoint
used by utilities or layout grid
----------------------------------------
*/
/*
----------------------------------------
Global colors
----------------------------------------
The following palettes will be added to
- background-color
- border-color
- color
- text-decoration-color
----------------------------------------
*/
/*
----------------------------------------
Settings
----------------------------------------
*/
/*
----------------------------------------
Values
----------------------------------------
*/
/*
----------------------------------------
color()
----------------------------------------
Derive a color from a color shortcode
----------------------------------------
*/
/*
----------------------------------------
append-important()
----------------------------------------
Append `!important` to a list
----------------------------------------
*/
/*
----------------------------------------
get-last()
----------------------------------------
Return the last item of a list,
Return null if the value is null
----------------------------------------
*/
/*
----------------------------------------
de-list()
----------------------------------------
Transform a one-element list or arglist
into that single element.
----------------------------------------
(1) => 1
((1)) => (1)
----------------------------------------
*/
/*
----------------------------------------
error-not-token()
----------------------------------------
Returns a common not-a-token error.
----------------------------------------
*/
/*
----------------------------------------
uswds-error()
----------------------------------------
Allow the system to pass an error as text
to test error states in unit testing
----------------------------------------
*/
/*
----------------------------------------
get-default()
----------------------------------------
Returns the default value from a map
of project defaults
get-default("bg-color")
> $theme-body-background-color
----------------------------------------
*/
/*
----------------------------------------
has-important()
----------------------------------------
Check to see if `!important` is
being passed in a mixin's props
----------------------------------------
*/
/*
----------------------------------------
map-deep-get()
----------------------------------------
@author Hugo Giraudel
@access public
@param {Map} $map - Map
@param {Arglist} $keys - Key chain
@return {*} - Desired value
----------------------------------------
*/
/*
----------------------------------------
multi-cat()
----------------------------------------
Concatenate two lists
----------------------------------------
*/
/*
----------------------------------------
remove()
----------------------------------------
Remove a value from a list
----------------------------------------
*/
/*
----------------------------------------
smart-quote()
----------------------------------------
Quotes strings
Inspects `px`, `xs`, and `xl` numbers
Leaves bools as is
----------------------------------------
*/
/*
----------------------------------------
str-replace()
----------------------------------------
Replace any substring with another
string
----------------------------------------
*/
/*
----------------------------------------
str-split()
----------------------------------------
Split a string at a given separator
and convert into a list of substrings
----------------------------------------
*/
/*
----------------------------------------
strip-unit()
----------------------------------------
Remove the unit of a length
@author Hugo Giraudel
@param {Number} $number - Number to remove unit from
@return {Number} - Unitless number
----------------------------------------
*/
/*
----------------------------------------
base-to-map()
@TODO: Deprecate and delete
----------------------------------------
Convert a single base to a USWDS
value map.

Candidate for deprecation if we remove
isReadable
----------------------------------------
*/
/*
----------------------------------------
to-number()
----------------------------------------
Casts a string into a number
----------------------------------------
@param {String | Number} $value - Value to be parsed
@return {Number}
----------------------------------------
*/
/*
----------------------------------------
unpack()
----------------------------------------
Create lists of single items from lists
of lists.
----------------------------------------
(1, (2.1, 2.2), 3) -->
(1, 2.1, 2.2, 3)
----------------------------------------
*/
/*
----------------------------------------
color()
----------------------------------------
Derive a color from a color shortcode
----------------------------------------
*/
/*
----------------------------------------
color()
----------------------------------------
Derive a color from a color shortcode
----------------------------------------
*/
/*
----------------------------------------
get-system-color()
----------------------------------------
Derive a system color from its
family, value, and vivid or a passed
variable that is, itself, a list
----------------------------------------
*/
/*
----------------------------------------
color()
----------------------------------------
Derive a color from a color shortcode
----------------------------------------
*/
/*
----------------------------------------
color()
----------------------------------------
Derive a color from a color shortcode
----------------------------------------
*/
/*
----------------------------------------
color()
----------------------------------------
Derive a color from a color shortcode
----------------------------------------
*/
/*
----------------------------------------
set-theme-color()
----------------------------------------
Derive a color from a system color token
or a hex value
----------------------------------------
*/
/*
----------------------------------------
px-to-rem()
----------------------------------------
Converts a value in px to a value in rem
----------------------------------------
*/
/*
----------------------------------------
rem-to-user-em()
----------------------------------------
Converts a value in rem to a value in
[user-settings] em for use in media
queries
----------------------------------------
*/
/*
----------------------------------------
px-to-user-em()
----------------------------------------
Converts a value in px to a value in em
----------------------------------------
*/
/*
----------------------------------------
rem-to-px()
----------------------------------------
Converts a value in rem to a value in px
----------------------------------------
*/
/*
----------------------------------------
spacing-multiple()
----------------------------------------
Converts a spacing unit multiple into
the desired final units (currently rem)
----------------------------------------
*/
/*
----------------------------------------
units()
----------------------------------------
Converts a spacing unit into
the desired final units (currently rem)
----------------------------------------
*/
/*
----------------------------------------
number-to-token()
----------------------------------------
Converts an integer or numeric value
into a system value

Ex: 0.5   --> '05'
    -1px  --> 'neg-1px'
----------------------------------------
*/
/*
----------------------------------------
Project fonts
----------------------------------------
Collects font settings in a map for
looping.
----------------------------------------
*/
/*
----------------------------------------
Luminance ranges
----------------------------------------
*/
/*
----------------------------------------
ns()
----------------------------------------
Add a namesspace of $type if that
namespace is set to output
----------------------------------------
*/
/*
----------------------------------------
Line height
----------------------------------------
*/
/*
----------------------------------------
Measure
----------------------------------------
*/
/*
----------------------------------------
cap-height()
----------------------------------------
Get the cap height of a valid typeface
----------------------------------------
*/
/*
----------------------------------------
validate-typeface-token()
----------------------------------------
Check to see if a typeface-token exists.
Throw an error if a passed token does
not exist in the typeface-token map.
----------------------------------------
*/
/*
----------------------------------------
convert-to-font-type()
----------------------------------------
Converts a font-role token into a
font-type token. Leaves font-type tokens
unchanged.
----------------------------------------
*/
/*
----------------------------------------
font-sources()
----------------------------------------
Outputs a list of font sources used in
a @font-face declaration.

$theme-font-browser-compatibility: true - output woff2, woff, ttf
$theme-font-browser-compatibility: false - output woff2

@param stem: string - [font path]/[custom-src.dir]/[custom-src.[style].[weight]]
@output: string

filetypes must be one of the filetypes set in variables/$project-font-face-filetypes (woff, woff2, ttf)
----------------------------------------
*/
/*
----------------------------------------
get-font-stack()
----------------------------------------
Get a font stack from a style- or
role-based font token.
----------------------------------------
*/
/*
----------------------------------------
get-typeface-token()
----------------------------------------
Get a typeface token from a font-type or
font-role token.
----------------------------------------
*/
/*
----------------------------------------
normalize-type-scale()
----------------------------------------
Normalizes a specific face's optical size
to a set target
----------------------------------------
*/
/*
----------------------------------------
system-type-scale()
----------------------------------------
Get a value from the system type scale
----------------------------------------
*/
/*
----------------------------------------
Easing
----------------------------------------
*/
/*  deprecated.scss
    ---
    Occasionally the design system will deprecate
    old variables or functionality. If we replace
    the old functionality with something new, this is a
    place to connect the old functionality to the
    new functionality, in the service of better
    continuity and backwards compatibility within a
    major release cycle.

    Note the USWDS version where we deprecated the
    old functionality in a comment.

    Be sure to update notifications.scss.

    This file should started fresh at each
    major version.
*/
/*
----------------------------------------
advanced-color()
----------------------------------------
Derive a color from a color triplet:
[family], [grade], [variant]
----------------------------------------
*/
/*
----------------------------------------
calculate-grade()
----------------------------------------
Derive the grade equivalent any color,
even non-token colors
----------------------------------------
*/
/*
----------------------------------------
color-token-assignment()
----------------------------------------
Get the system token equivalent of any
theme color token
----------------------------------------
*/
/*
----------------------------------------
is-system-color-token()
----------------------------------------
Return whether a token is a system
color token
----------------------------------------
*/
/*
----------------------------------------
is-theme-color-token()
----------------------------------------
Return whether a token is a theme
color token
----------------------------------------
*/
/*
----------------------------------------
color-token-family()
----------------------------------------
Returns the family of a color token.
Returns: color-family
color-token-family("accent-warm-vivid")
> "accent-warm"
color-token-family("red-50v")
> "red"
color-token-variant(("red", 50, "vivid"))
> "red"
----------------------------------------
*/
/*
----------------------------------------
decompose()
----------------------------------------
Convert a color token into into a list
of form [family], [grade], [variant]
Vivid variants return "vivid" as the
variant.
If neither grade nor variant exists,
returns 'null'
----------------------------------------
*/
/*
----------------------------------------
color-token-family()
----------------------------------------
Returns the family of a color token.
Returns: color-family
color-token-family("accent-warm-vivid")
> "accent-warm"
color-token-family("red-50v")
> "red"
color-token-variant(("red", 50, "vivid"))
> "red"
----------------------------------------
*/
/*
----------------------------------------
color-token-grade()
----------------------------------------
Returns the grade of a USWDS color token.
Returns: color-grade
color-token-grade("accent-warm")
> "root"
color-token-grade("accent-warm-vivid")
> "root"
color-token-grade("accent-warm-darker")
> "darker"
color-token-grade("red-50v")
> 50
color-token-variant(("red", 50, "vivid"))
> 50
----------------------------------------
*/
/*
----------------------------------------
color-token-family()
----------------------------------------
Returns the family of a color token.
Returns: color-family
color-token-family("accent-warm-vivid")
> "accent-warm"
color-token-family("red-50v")
> "red"
color-token-variant(("red", 50, "vivid"))
> "red"
----------------------------------------
*/
/*
----------------------------------------
is-color-token()
----------------------------------------
Returns whether a given string is a
USWDS color token.
----------------------------------------
*/
/*
----------------------------------------
pow()
----------------------------------------
Raises a unitless number to the power
of another unitless number
Includes helper functions
----------------------------------------
*/
/*
----------------------------------------
Helper functions
----------------------------------------
*/
/* factorial()
----------------------------------------
*/
/* summation()
----------------------------------------
*/
/* exp-maclaurin()
----------------------------------------
*/
/* ln()
----------------------------------------
*/
/*
----------------------------------------
color-token-type()
----------------------------------------
Returns the type of a color token.
Returns: "system" | "theme"
----------------------------------------
*/
/*
----------------------------------------
color-token-variant()
----------------------------------------
Returns the variant of color token.
Returns: "vivid" | false
color-token-variant("accent-warm")
> false
color-token-variant("accent-warm-vivid")
> "vivid"
color-token-variant("red-50v")
> "vivid"
color-token-variant(("red", 50, "vivid"))
> "vivid"
----------------------------------------
*/
/*
----------------------------------------
magic-number()
----------------------------------------
Returns the magic number of two color
grades. Takes numbers or color tokens.
magic-number(50, 10)
return: 40
magic-number("red-50", "red-10")
return: 40
----------------------------------------
*/
/*
----------------------------------------
is-accessible-magic-number()
----------------------------------------
Returns whether two grades achieve
specified target color contrast
Returns: true | false
is-accessible-magic-number(10, 50, "AA")
> false
is-accessible-magic-number(10, 60, "AA")
> true
----------------------------------------
*/
/*
----------------------------------------
wcag-magic-number()
----------------------------------------
Returns the magic number of a specific
wcag grade:
"AA"
"AA-Large"
"AAA"
wcag-magic-number("AA")
> 50
----------------------------------------
*/
/*
----------------------------------------
get-link-tokens-from-bg()
----------------------------------------
Get accessible link colors for a given
background color
returns: link-token, hover-token
get-link-tokens-from-bg(
  "black",
  "red-60",
  "red-10",
  "AA")
> "red-10", "red-5"
get-link-tokens-from-bg(
  "black",
  "red-60v",
  "red-10v",
  "AA-large")
> "red-60v", "red-50v"
get-link-tokens-from-bg(
  "black",
  "red-5v",
  "red-60v",
  "AA")
> "red-5v", "white"
get-link-tokens-from-bg(
  "black",
  "white",
  "red-60v",
  "AA")
> "white", "white"
----------------------------------------
*/
/*
----------------------------------------
next-token()
----------------------------------------
Returns next "darker" or "lighter" color
token of the same token type and variant.
Returns: color-token | false
next-token("accent-warm", "lighter")
> "accent-warm-light"
next-token("gray-10", "lighter")
> "gray-5"
next-token("gray-5", "lighter")
> "white"
next-token("white", "lighter")
> false
next-token("red-50v", "darker")
> "red-60v"
next-token("red-50", "darker")
> "red-60"
next-token("red-80v", "darker")
> "red-90"
next-token("red-90", "darker")
> "black"
next-token("white", "darker")
> "gray-5"
next-token("black", "lighter")
> "gray-90"
----------------------------------------
*/
/*
----------------------------------------
test-colors()
----------------------------------------
Check to see if all system colors
fall between the proper relative
luminance range for their grade.
Has a couple quirks, as the luminance()
function returns slightly different
results than expected.
----------------------------------------
*/
/*
----------------------------------------
columns()
----------------------------------------
outputs a grid-col number based on
the number of desired columns in the
12-column grid

Ex: columns(2) --> 6
    grid-col(columns(2))
----------------------------------------
*/
/*
----------------------------------------
USWDS Properties
----------------------------------------
*/
/*
----------------------------------------
get-uswds-value()
----------------------------------------
Finds and outputs a value from the
USWDS standard values.

Used to build other standard utility
functions and mixins.
----------------------------------------
*/
/*
----------------------------------------
get-standard-values()
----------------------------------------
Gets a map of USWDS standard values
for a property
----------------------------------------
*/
/*
----------------------------------------
border-radius()
----------------------------------------
Get a border-radius from the system
border-radii
----------------------------------------
*/
/*
----------------------------------------
font-weight()
fw()
----------------------------------------
Get a font-weight value from the
system font-weight
----------------------------------------
*/
/*
----------------------------------------
feature()
----------------------------------------
Gets a valid USWDS font feature setting
----------------------------------------
*/
/*
----------------------------------------
flex()
----------------------------------------
Gets a valid USWDS flex value
----------------------------------------
*/
/*
----------------------------------------
font-family()
family()
----------------------------------------
Get a font-family stack from a
role-based or type-based font family
----------------------------------------
*/
/*
----------------------------------------
letter-spacing()
ls()
----------------------------------------
Get a letter-spacing value from the
system letter-spacing
----------------------------------------
*/
/*
----------------------------------------
measure()
----------------------------------------
Gets a valid USWDS reading line length
----------------------------------------
*/
/*
----------------------------------------
opacity()
----------------------------------------
Get an opacity from the system
opacities
----------------------------------------
*/
/*
----------------------------------------
order()
----------------------------------------
Get an order value from the
system orders
----------------------------------------
*/
/*
----------------------------------------
radius()
----------------------------------------
Get a border-radius value from the
system letter-spacing
----------------------------------------
*/
/*
----------------------------------------
font-size()
----------------------------------------
Get type scale value from a [family] and
[scale]
----------------------------------------
*/
/*
----------------------------------------
z-index()
z()
----------------------------------------
Get a z-index value from the
system z-index
----------------------------------------
*/
/*
----------------------------------------
utility-font()
----------------------------------------
Get a normalized font-size in rem from
a family and a type size in either
system scale or project scale
----------------------------------------
Not the public-facing function.
Used for building the utilities and
withholds certain errors.
----------------------------------------
*/
/*
----------------------------------------
family()
----------------------------------------
Get a font-family stack
----------------------------------------
*/
/*
----------------------------------------
size()
----------------------------------------
Get a normalized font-size in rem from
a family and a type size in either
system scale or project scale
----------------------------------------
*/
/*
----------------------------------------
font()
----------------------------------------
Get a font-family stack
AND
Get a normalized font-size in rem from
a family and a type size in either
system scale or project scale
----------------------------------------
*/
/*
----------------------------------------
typeset()
----------------------------------------
Sets:
- family
- size
- line-height
----------------------------------------
*/
/* stylelint-disable max-nesting-depth */
/*
----------------------------------------
@render-pseudoclass
----------------------------------------
Build a pseucoclass utiliy from values
calculated in the @render-utilities-in
loop
----------------------------------------
*/
/*
----------------------------------------
@render-utility
----------------------------------------
Build a utility from values calculated
in the @render-utilities-in loop
----------------------------------------
TODO: Determine the proper use of
unquote() in the following. Changed to
account for a 'interpolation near
operators will be simplified in a
future version of Sass' warning.
----------------------------------------
*/
/*
----------------------------------------
@render-utilities-in
----------------------------------------
The master loop that sets the building
blocks of utilities from the values
in individual rule settings and loops
through all possible variants
----------------------------------------
*/
/* stylelint-enable */
/*  notifications.scss
    ---
    Adds a notification at the top of each USWDS
    compile. Use this file for important notifications
    and updates to the design system.

    This file should started fresh at each
    major version.

*/
/* prettier-ignore */
/* prettier-ignore */
.usa-input {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.4;
}
.usa-input {
  border-width: 1px;
  border-color: #565c65;
  border-style: solid;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0;
  color: #1c1d1f;
  display: block;
  height: 2.5rem;
  margin-top: 0.5rem;
  max-width: 30rem;
  padding: 0.5rem;
  width: 100%;
}
/*********** VEDAUI THEME PALETTE ***********/
/*
----------------------------------------
Set basic font rules for the font
utilities to reference.
----------------------------------------
*/
[class*=font-mono-] {
  font-family: Roboto Mono Web, Bitstream Vera Sans Mono, Consolas, Courier, monospace !important;
}
[class*=font-sans-] {
  font-family: var(--base-font-family),  !important;
}
[class*=font-serif-] {
  font-family: var(--base-font-family),  !important;
}
[class*=font-ui-] {
  font-family: var(--base-font-family),  !important;
}
[class*=font-heading-] {
  font-family: var(--base-font-family),  !important;
}
[class*=font-body-] {
  font-family: var(--base-font-family),  !important;
}
[class*=font-code-] {
  font-family: Roboto Mono Web, Bitstream Vera Sans Mono, Consolas, Courier, monospace !important;
}
[class*=font-alt-] {
  font-family: var(--base-font-family),  !important;
}
/*
========================================
ASPECT
----------------------------------------
usage:
  .add-aspect-[key]
----------------------------------------
output:
  aspect-ratio: [value];
----------------------------------------
example:
  .add-aspect-16x9 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1.7777777778;
    max-width: 100%;
  }
----------------------------------------
*/
.add-aspect-9x16 {
  box-sizing: border-box;
  height: 0;
  overflow: hidden;
  padding-bottom: 177.77778%;
  position: relative;
}
@supports (aspect-ratio: 1) {
  .add-aspect-9x16 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 0.5625;
    max-width: 100%;
  }
  .add-aspect-9x16 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img.add-aspect-9x16, .add-aspect-9x16 > img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.add-aspect-16x9 {
  box-sizing: border-box;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}
@supports (aspect-ratio: 1) {
  .add-aspect-16x9 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1.7777777778;
    max-width: 100%;
  }
  .add-aspect-16x9 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img.add-aspect-16x9, .add-aspect-16x9 > img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.add-aspect-1x1 {
  box-sizing: border-box;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
}
@supports (aspect-ratio: 1) {
  .add-aspect-1x1 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1;
    max-width: 100%;
  }
  .add-aspect-1x1 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img.add-aspect-1x1, .add-aspect-1x1 > img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.add-aspect-4x3 {
  box-sizing: border-box;
  height: 0;
  overflow: hidden;
  padding-bottom: 75%;
  position: relative;
}
@supports (aspect-ratio: 1) {
  .add-aspect-4x3 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1.3333333333;
    max-width: 100%;
  }
  .add-aspect-4x3 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img.add-aspect-4x3, .add-aspect-4x3 > img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.add-aspect-2x1 {
  box-sizing: border-box;
  height: 0;
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;
}
@supports (aspect-ratio: 1) {
  .add-aspect-2x1 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 2;
    max-width: 100%;
  }
  .add-aspect-2x1 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img.add-aspect-2x1, .add-aspect-2x1 > img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@supports (aspect-ratio: 1) {
  :where(img[class*=add-aspect-]) {
    width: -moz-min-content;
    width: min-content;
  }
}
/*
========================================
LIST RESET
----------------------------------------
usage:
  .add-list-reset
----------------------------------------
output:
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  list-style: none;
----------------------------------------
example:
  .add-list-reset {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    list-style: none;
  }
----------------------------------------
*/
/*
========================================
align-items
----------------------------------------
usage:
  .flex-align-[key]
----------------------------------------
output:
  align-items: [value];
----------------------------------------
example:
  .flex-align-start {
    align-items: flex-start;
  }
----------------------------------------
*/
/*
========================================
PALETTE REGISTRY
----------------------------------------
This map collects the
available utility palettes
----------------------------------------
*/
/*
========================================
FONT PLUGINS
----------------------------------------
These plugins collect packaged
font-related values for inclusion in
utilities
----------------------------------------
*/
/*
----------------------------------------
role-based theme sizes
----------------------------------------
*/
/*
----------------------------------------
system font sizes
----------------------------------------
*/
/*
========================================
MISC PLUGINS
----------------------------------------
These plugins collect packaged
spacing-related values for inclusion
in utilities
----------------------------------------
*/
/*
========================================
PALETTE REGISTRY
----------------------------------------
This map collects the
available utility palettes
----------------------------------------
*/
/*
========================================
STANDARD PLUGINS
----------------------------------------
These plugins collect standard property
values as plugin
----------------------------------------
*/
/*
----------------------------------------
get-palettes()
----------------------------------------
Build a single map of plugin values
from a list of plugin keys.
----------------------------------------
*/
/*
========================================
align-self
----------------------------------------
usage:
  .flex-align-self-[key]
----------------------------------------
output:
  align-self: [value];
----------------------------------------
example:
  .flex-align-self-start {
    align-self: flex-start;
  }
----------------------------------------
*/
/*
========================================
background-color
----------------------------------------
usage:
  .bg-[key]
----------------------------------------
output:
  background-color: [value];
----------------------------------------
example:
  .bg-red {
    background-color: #e52207;
  }
----------------------------------------
*/
/*
========================================
border
----------------------------------------
Also applies `solid`
Needs to come before other border utils
----------------------------------------
usage:
  .border-[modifier]*-[key]
----------------------------------------
output:
  border-[modifier]: [value];
----------------------------------------
example:
  .border-top-1px {
    border-top: 1px solid;
  }

  .border-0 {
    border: 0 solid;
  }
----------------------------------------
*/
/*
========================================
border-color
----------------------------------------
usage:
  .border-[key]
----------------------------------------
output:
  border-color: [value];
----------------------------------------
example:
  .border-red {
    border-color: #e52207;
  }
----------------------------------------
*/
/*
========================================
border-radius
----------------------------------------
usage:
  .radius-[key]
----------------------------------------
output:
  border-radius: [value];
----------------------------------------
example:
  .radius-0 {
    border-radius: 0;
  }

  .radius-pill {
    border-radius: 99rem;
  }
----------------------------------------
*/
/*
========================================
border-style
----------------------------------------
usage:
  .border-[key]
----------------------------------------
output:
  border-style: [value];
----------------------------------------
example:
  .border-dotted {
    border-style: dotted;
  }
----------------------------------------
*/
/*
========================================
border-width
----------------------------------------
usage:
  .border-[modifier]*-width-[key]
----------------------------------------
output:
  border-[modifier]-width: [value];
----------------------------------------
example:
  .border-top-width-1px {
    border-top-width: 1px;
  }
----------------------------------------
*/
/*
========================================
bottom
----------------------------------------
usage:
  .bottom-[key]
----------------------------------------
output:
  bottom: [value];
----------------------------------------
example:
  .bottom-3 {
    bottom: 1.5rem;
  }

  .bottom-neg-2px {
    bottom: -2px;
  }
----------------------------------------
*/
/*
========================================
box-shadow
----------------------------------------
usage:
  .shadow-[key]
----------------------------------------
output:
  box-shadow: [value];
----------------------------------------
example:
  .shadow-1 {
    box-shadow: 0 1px 0.25rem 0 rgba(0, 0, 0, 0.1);
  }
----------------------------------------
*/
/*
========================================
circle
----------------------------------------
usage:
  .circle-[key]
----------------------------------------
output:
  height: [value];
  width: [value];
  border-radius: 50%;
----------------------------------------
example:
  .circle-6 {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
  }
----------------------------------------
*/
/*
========================================
.clearfix
----------------------------------------
usage:
  .clearfix
----------------------------------------
example:
  .clearfix::after {
    clear: both;
    content: "";
    display: block;
  }
----------------------------------------
*/
/*
========================================
color
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  color: [value];
----------------------------------------
example:
  .text-black {
    color: black;
  }
----------------------------------------
*/
/*
========================================
cursor
----------------------------------------
usage:
  .cursor-[key]
----------------------------------------
output:
  cursor: [value];
----------------------------------------
example:
  .cursor-pointer {
    cursor: pointer;
  }
----------------------------------------
*/
/*
========================================
display
----------------------------------------
usage:
  .display-[key]
----------------------------------------
output:
  display: [value];
----------------------------------------
example:
  .display-none {
    display: none;
  }
----------------------------------------
*/
/*
========================================
float
----------------------------------------
usage:
  .float-[value]
----------------------------------------
output:
  float: [value];
----------------------------------------
example:
  .float-left {
    float: left;
  }
----------------------------------------
*/
/*
========================================
flex
----------------------------------------
usage:
  .flex-[key]
----------------------------------------
output:
  flex: [value];
----------------------------------------
example:
  .flex-1 {
    flex: 1 0 0;
  }

  .flex-fill {
    flex: 1 0 0;
  }

  .flex-auto {
    flex: 0 1 auto;
  }
----------------------------------------
*/
/*
========================================
flex-direction
----------------------------------------
usage:
  .flex-[key]
----------------------------------------
output:
  flex-direction: [value];
----------------------------------------
example:
  .flex-row {
    flex-direction: row;
  }

  .flex-column {
    flex-direction: column;
  }
----------------------------------------
*/
/*
========================================
flex-wrap
----------------------------------------
usage:
  .flex-[key]
----------------------------------------
output:
  flex-wrap: [value];
----------------------------------------
example:
  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-no-wrap {
    flex-wrap: nowrap;
  }
----------------------------------------
*/
/*
========================================
font-size
----------------------------------------
usage:
  .font-[key]-[key]
----------------------------------------
output:
  font-size: [value];
----------------------------------------
example:
  .font-sans-md {
    font-size: 1.13rem;
  }
----------------------------------------
*/
/*
========================================
font-family
----------------------------------------
usage:
  .font-family-[key]
----------------------------------------
output:
  font-family: [value];
----------------------------------------
example:
  .font-family-sans {
    font-family:
      Source Sans Pro Web,
      Helvetica Neue,
      Helvetica,
      Roboto,
      Arial,
      sans-serif;
  }
----------------------------------------
*/
/*
========================================
font-feature-settings
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  font-feature-settings: [value];
----------------------------------------
example:
  .text-tabular {
    font-feature-settings:
      'tnum' 1, 'kern' 1;
  }
----------------------------------------
*/
/*
========================================
font-style
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  font-style: [value];
----------------------------------------
example:
  .text-italic {
    font-style: italic;
  }

  .text-no-italic {
    font-style: normal;
  }
----------------------------------------
*/
/*
========================================
font-weight
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  font-weight: [value];
----------------------------------------
example:
  .text-bold {
    font-weight: 700;
  }

  .text-light{
    font-weight: 300;
  }
----------------------------------------
*/
/*
========================================
height
----------------------------------------
usage:
  .height-[key]
----------------------------------------
output:
  height: [value];
----------------------------------------
example:
  .height-6 {
    height: 3rem;
  }
----------------------------------------
*/
/*
========================================
justify-content
----------------------------------------
usage:
  .flex-justify-[key]
----------------------------------------
output:
  justify-content: [value];
----------------------------------------
example:
  .flex-justify-start {
    justify-content: flex-start;
  }
----------------------------------------
*/
/*
========================================
left
----------------------------------------
usage:
  .left-[key]
----------------------------------------
output:
  left: [value];
----------------------------------------
example:
  .left-neg-2px {
    left: -2px;
  }
----------------------------------------
*/
/*
========================================
letter-spacing
----------------------------------------
usage:
  .text-ls-[key]
----------------------------------------
output:
  letter-spacing: [value];
----------------------------------------
example:
  .text-ls-2 {
    letter-spacing: .1em;
  }
----------------------------------------
*/
/*
========================================
line-height
----------------------------------------
usage:
  .line-height-[modifier]-[key]
----------------------------------------
output:
  line-height: [value];
----------------------------------------
example:
  .line-height-sans-2 {
    line-height: 1.1;
  }
----------------------------------------
*/
/*
========================================
margin
----------------------------------------
usage:
  .margin-[modifier]*-[key]
----------------------------------------
output:
  margin: [value];
----------------------------------------
example:
  .margin-x-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .margin-105 {
    margin: .75rem;
  }
----------------------------------------
*/
/*
========================================
max-height
----------------------------------------
usage:
  .maxh-[key]
----------------------------------------
output:
  max-height: [value];
----------------------------------------
example:
  .maxh-viewport {
    max-height: 100vh;
  }
----------------------------------------
*/
/*
========================================
max-width
----------------------------------------
usage:
  .maxw-[key]
----------------------------------------
output:
  max-width: [value];
----------------------------------------
example:
  .maxw-desktop {
    max-width: 64rem;
  }
----------------------------------------
*/
/*
========================================
.measure
----------------------------------------
property: max-width
----------------------------------------
usage:
  .measure-[key]
----------------------------------------
output:
  max-width: [value];
----------------------------------------
example:
  .measure-4 {
    max-width: 68ex;
  }
----------------------------------------
*/
/*
========================================
min-height
----------------------------------------
usage:
  .minh-[key]
----------------------------------------
output:
  min-height: [value];
----------------------------------------
example:
  .minh-card {
    min-height: 10rem;
  }
----------------------------------------
*/
/*
========================================
min-width
----------------------------------------
usage:
  .minw-[key]
----------------------------------------
output:
  min-width: [value];
----------------------------------------
example:
  .minw-15 {
    min-width: 7.5rem;
  }
----------------------------------------
*/
/*
========================================
opacity
----------------------------------------
usage:
  .opacity-[key]
----------------------------------------
output:
  opacity: [value];
----------------------------------------
example:
  .opacity-0 {
    opacity: 0;
  }

  .opacity-30 {
    opacity: 0.3;
  }
----------------------------------------
*/
/*
========================================
outline
----------------------------------------
sets width and style:solid
----------------------------------------
usage:
  .outline-[modifier]
----------------------------------------
output:
  outline: [modifier] solid;
----------------------------------------
example:
  .outline-2px {
    outline: 2px solid;
  }
----------------------------------------
*/
/*
========================================
outline-color
----------------------------------------
usage:
  .outline-[key]
----------------------------------------
output:
  outline-color: [value];
----------------------------------------
example:
  .outline-yellow {
    outline-color: #fee685;
  }
----------------------------------------
*/
/*
========================================
overflow
----------------------------------------
usage:
  .overflow-[modifier]*-[key]
----------------------------------------
output:
  overflow[-modifier]: [value];
----------------------------------------
example:
  .overflow-y-hidden {
    overflow-y: hidden;
  }
----------------------------------------
*/
/*
========================================
order
----------------------------------------
usage:
  .order-[key]
----------------------------------------
output:
  order: [value];
----------------------------------------
example:
  .order-first {
    order: -1;
  }

  .order-2 {
    order: 2;
  }
----------------------------------------
*/
/*
========================================
padding
----------------------------------------
usage:
  .padding-[modifier]*-[key]
----------------------------------------
output:
  padding[-modifier]: [value];
----------------------------------------
example:
  .padding-0 {
    padding: 0;
  }

  .padding-bottom-1px {
    padding-bottom: 1px;
  }
----------------------------------------
*/
/*
========================================
PIN
----------------------------------------
usage:
  .pin-[key]
----------------------------------------
example:
  .pin-all {
    bottom:0;
    left:0;
    right:0;
    top:0;
    position:absolute;
  }

  .pin-left {
    left:0;
    position:absolute;
  }
----------------------------------------
*/
/*
========================================
position
----------------------------------------
usage:
  .position-[key]
----------------------------------------
output:
  position: [value];
----------------------------------------
example:
  .position-absolute {
    position: absolute;
  }
----------------------------------------
*/
/*
========================================
right
----------------------------------------
usage:
  .right-[key]
----------------------------------------
output:
  right: [value];
----------------------------------------
example:
  .right-neg-2px {
    right: -2px;
  }
----------------------------------------
*/
/*
========================================
SQUARE
----------------------------------------
usage:
  .square-[key]
----------------------------------------
output:
  height: [value];
  width: [value];
----------------------------------------
example:
  .square-6 {
    height: 3rem;
    width: 3rem;
  }
----------------------------------------
*/
/*
========================================
text-align
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  text-align: [value];
----------------------------------------
example:
  .text-left {
    text-align: left;
  }
----------------------------------------
*/
/*
========================================
text-decoration
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  text-decoration: [value];
----------------------------------------
example:
  .text-no-underline {
    text-decoration: none;
  }
----------------------------------------
*/
/*
========================================
text-decoration-color
----------------------------------------
usage:
  .underline-[key]
----------------------------------------
output:
  text-decoration-color: [value];
----------------------------------------
example:
  .underline-red {
    text-decoration-color: #e52207;
  }
----------------------------------------
*/
/*
========================================
text-indent
----------------------------------------
usage:
  .text-indent-[key]
----------------------------------------
output:
  text-indent: [value];
----------------------------------------
example:
  .text-indent-0 {
    text-ident: 0;
  }
----------------------------------------
*/
/*
========================================
text-transform
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  text-transform: [value];
----------------------------------------
example:
  .text-uppercase {
    text-transform: uppercase;
  }
----------------------------------------
*/
/*
========================================
top
----------------------------------------
usage:
  .top-[key]
----------------------------------------
output:
  top: [value];
----------------------------------------
example:
  .top-neg-2px {
    top: -2px;
  }
----------------------------------------
*/
/*
========================================
vertical-align
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  vertical-align: [value];
----------------------------------------
example:
  .text-top {
    vertical-align: top;
  }
----------------------------------------
*/
/*
========================================
white-space
----------------------------------------
usage:
  text-[key]
----------------------------------------
output:
  white-space: [value];
----------------------------------------
example:
  .text-pre-wrap {
    white-space: pre-wrap;
  }
----------------------------------------
*/
/*
========================================
width
----------------------------------------
usage:
  .width-[key]
----------------------------------------
output:
  width: [value];
----------------------------------------
example:
  .width-2 {
    width: 1rem;
  }
----------------------------------------
*/
/*
========================================
z-index
----------------------------------------
usage:
  z-[key]
----------------------------------------
output:
  z-index: [value];
----------------------------------------
example:
  .z-100 {
    z-index: 100;
  }

  .z-top {
    z-index: 99999;
  }
----------------------------------------
*/
.add-list-reset {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-left: 0 !important;
  list-style: none !important;
}
.flex-align-start {
  align-items: flex-start !important;
}
.flex-align-end {
  align-items: flex-end !important;
}
.flex-align-center {
  align-items: center !important;
}
.flex-align-stretch {
  align-items: stretch !important;
}
.flex-align-baseline {
  align-items: baseline !important;
}
.flex-align-self-start {
  align-self: flex-start !important;
}
.flex-align-self-end {
  align-self: flex-end !important;
}
.flex-align-self-center {
  align-self: center !important;
}
.flex-align-self-stretch {
  align-self: stretch !important;
}
.flex-align-self-baseline {
  align-self: baseline !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.hover\:bg-transparent:hover {
  background-color: transparent !important;
}
.bg-black {
  background-color: black !important;
}
.hover\:bg-black:hover {
  background-color: black !important;
}
.bg-white {
  background-color: white !important;
}
.hover\:bg-white:hover {
  background-color: white !important;
}
.bg-red {
  background-color: #e52207 !important;
}
.hover\:bg-red:hover {
  background-color: #e52207 !important;
}
.bg-orange {
  background-color: #e66f0e !important;
}
.hover\:bg-orange:hover {
  background-color: #e66f0e !important;
}
.bg-gold {
  background-color: #ffbe2e !important;
}
.hover\:bg-gold:hover {
  background-color: #ffbe2e !important;
}
.bg-yellow {
  background-color: #fee685 !important;
}
.hover\:bg-yellow:hover {
  background-color: #fee685 !important;
}
.bg-green {
  background-color: #538200 !important;
}
.hover\:bg-green:hover {
  background-color: #538200 !important;
}
.bg-mint {
  background-color: #04c585 !important;
}
.hover\:bg-mint:hover {
  background-color: #04c585 !important;
}
.bg-cyan {
  background-color: #009ec1 !important;
}
.hover\:bg-cyan:hover {
  background-color: #009ec1 !important;
}
.bg-blue {
  background-color: #0076d6 !important;
}
.hover\:bg-blue:hover {
  background-color: #0076d6 !important;
}
.bg-indigo {
  background-color: #676cc8 !important;
}
.hover\:bg-indigo:hover {
  background-color: #676cc8 !important;
}
.bg-violet {
  background-color: #8168b3 !important;
}
.hover\:bg-violet:hover {
  background-color: #8168b3 !important;
}
.bg-magenta {
  background-color: #d72d79 !important;
}
.hover\:bg-magenta:hover {
  background-color: #d72d79 !important;
}
.bg-gray-5 {
  background-color: #f0f0f0 !important;
}
.hover\:bg-gray-5:hover {
  background-color: #f0f0f0 !important;
}
.bg-gray-10 {
  background-color: #e6e6e6 !important;
}
.hover\:bg-gray-10:hover {
  background-color: #e6e6e6 !important;
}
.bg-gray-30 {
  background-color: #adadad !important;
}
.hover\:bg-gray-30:hover {
  background-color: #adadad !important;
}
.bg-gray-50 {
  background-color: #757575 !important;
}
.hover\:bg-gray-50:hover {
  background-color: #757575 !important;
}
.bg-gray-70 {
  background-color: #454545 !important;
}
.hover\:bg-gray-70:hover {
  background-color: #454545 !important;
}
.bg-gray-90 {
  background-color: #1b1b1b !important;
}
.hover\:bg-gray-90:hover {
  background-color: #1b1b1b !important;
}
.bg-base-lightest {
  background-color: #f0f0f0 !important;
}
.hover\:bg-base-lightest:hover {
  background-color: #f0f0f0 !important;
}
.bg-base-lighter {
  background-color: #dfe1e2 !important;
}
.hover\:bg-base-lighter:hover {
  background-color: #dfe1e2 !important;
}
.bg-base-light {
  background-color: #a9aeb1 !important;
}
.hover\:bg-base-light:hover {
  background-color: #a9aeb1 !important;
}
.bg-base {
  background-color: #71767a !important;
}
.hover\:bg-base:hover {
  background-color: #71767a !important;
}
.bg-base-dark {
  background-color: #565c65 !important;
}
.hover\:bg-base-dark:hover {
  background-color: #565c65 !important;
}
.bg-base-darker {
  background-color: #3d4551 !important;
}
.hover\:bg-base-darker:hover {
  background-color: #3d4551 !important;
}
.bg-base-darkest {
  background-color: #2d2e2f !important;
}
.hover\:bg-base-darkest:hover {
  background-color: #2d2e2f !important;
}
.bg-ink {
  background-color: #1c1d1f !important;
}
.hover\:bg-ink:hover {
  background-color: #1c1d1f !important;
}
.bg-primary-lighter {
  background-color: #d9e8f6 !important;
}
.hover\:bg-primary-lighter:hover {
  background-color: #d9e8f6 !important;
}
.bg-primary-light {
  background-color: #73b3e7 !important;
}
.hover\:bg-primary-light:hover {
  background-color: #73b3e7 !important;
}
.bg-primary {
  background-color: #005ea2 !important;
}
.hover\:bg-primary:hover {
  background-color: #005ea2 !important;
}
.bg-primary-vivid {
  background-color: #0050d8 !important;
}
.hover\:bg-primary-vivid:hover {
  background-color: #0050d8 !important;
}
.bg-primary-dark {
  background-color: #1a4480 !important;
}
.hover\:bg-primary-dark:hover {
  background-color: #1a4480 !important;
}
.bg-primary-darker {
  background-color: #162e51 !important;
}
.hover\:bg-primary-darker:hover {
  background-color: #162e51 !important;
}
.bg-secondary-lighter {
  background-color: #f3e1e4 !important;
}
.hover\:bg-secondary-lighter:hover {
  background-color: #f3e1e4 !important;
}
.bg-secondary-light {
  background-color: #f2938c !important;
}
.hover\:bg-secondary-light:hover {
  background-color: #f2938c !important;
}
.bg-secondary {
  background-color: #d83933 !important;
}
.hover\:bg-secondary:hover {
  background-color: #d83933 !important;
}
.bg-secondary-vivid {
  background-color: #e41d3d !important;
}
.hover\:bg-secondary-vivid:hover {
  background-color: #e41d3d !important;
}
.bg-secondary-dark {
  background-color: #b50909 !important;
}
.hover\:bg-secondary-dark:hover {
  background-color: #b50909 !important;
}
.bg-secondary-darker {
  background-color: #8b0a03 !important;
}
.hover\:bg-secondary-darker:hover {
  background-color: #8b0a03 !important;
}
.bg-accent-warm-darker {
  background-color: #775540 !important;
}
.hover\:bg-accent-warm-darker:hover {
  background-color: #775540 !important;
}
.bg-accent-warm-dark {
  background-color: #c05600 !important;
}
.hover\:bg-accent-warm-dark:hover {
  background-color: #c05600 !important;
}
.bg-accent-warm {
  background-color: #fa9441 !important;
}
.hover\:bg-accent-warm:hover {
  background-color: #fa9441 !important;
}
.bg-accent-warm-light {
  background-color: #ffbc78 !important;
}
.hover\:bg-accent-warm-light:hover {
  background-color: #ffbc78 !important;
}
.bg-accent-warm-lighter {
  background-color: #f2e4d4 !important;
}
.hover\:bg-accent-warm-lighter:hover {
  background-color: #f2e4d4 !important;
}
.bg-accent-cool-darker {
  background-color: #07648d !important;
}
.hover\:bg-accent-cool-darker:hover {
  background-color: #07648d !important;
}
.bg-accent-cool-dark {
  background-color: #28a0cb !important;
}
.hover\:bg-accent-cool-dark:hover {
  background-color: #28a0cb !important;
}
.bg-accent-cool {
  background-color: #00bde3 !important;
}
.hover\:bg-accent-cool:hover {
  background-color: #00bde3 !important;
}
.bg-accent-cool-light {
  background-color: #97d4ea !important;
}
.hover\:bg-accent-cool-light:hover {
  background-color: #97d4ea !important;
}
.bg-accent-cool-lighter {
  background-color: #e1f3f8 !important;
}
.hover\:bg-accent-cool-lighter:hover {
  background-color: #e1f3f8 !important;
}
.bg-error-lighter {
  background-color: #f4e3db !important;
}
.hover\:bg-error-lighter:hover {
  background-color: #f4e3db !important;
}
.bg-error-light {
  background-color: #f39268 !important;
}
.hover\:bg-error-light:hover {
  background-color: #f39268 !important;
}
.bg-error {
  background-color: #d54309 !important;
}
.hover\:bg-error:hover {
  background-color: #d54309 !important;
}
.bg-error-dark {
  background-color: #b50909 !important;
}
.hover\:bg-error-dark:hover {
  background-color: #b50909 !important;
}
.bg-error-darker {
  background-color: #6f3331 !important;
}
.hover\:bg-error-darker:hover {
  background-color: #6f3331 !important;
}
.bg-warning-lighter {
  background-color: #faf3d1 !important;
}
.hover\:bg-warning-lighter:hover {
  background-color: #faf3d1 !important;
}
.bg-warning-light {
  background-color: #fee685 !important;
}
.hover\:bg-warning-light:hover {
  background-color: #fee685 !important;
}
.bg-warning {
  background-color: #ffbe2e !important;
}
.hover\:bg-warning:hover {
  background-color: #ffbe2e !important;
}
.bg-warning-dark {
  background-color: #e5a000 !important;
}
.hover\:bg-warning-dark:hover {
  background-color: #e5a000 !important;
}
.bg-warning-darker {
  background-color: #936f38 !important;
}
.hover\:bg-warning-darker:hover {
  background-color: #936f38 !important;
}
.bg-success-lighter {
  background-color: #ecf3ec !important;
}
.hover\:bg-success-lighter:hover {
  background-color: #ecf3ec !important;
}
.bg-success-light {
  background-color: #70e17b !important;
}
.hover\:bg-success-light:hover {
  background-color: #70e17b !important;
}
.bg-success {
  background-color: #00a91c !important;
}
.hover\:bg-success:hover {
  background-color: #00a91c !important;
}
.bg-success-dark {
  background-color: #008817 !important;
}
.hover\:bg-success-dark:hover {
  background-color: #008817 !important;
}
.bg-success-darker {
  background-color: #216e1f !important;
}
.hover\:bg-success-darker:hover {
  background-color: #216e1f !important;
}
.bg-info-lighter {
  background-color: #e7f6f8 !important;
}
.hover\:bg-info-lighter:hover {
  background-color: #e7f6f8 !important;
}
.bg-info-light {
  background-color: #99deea !important;
}
.hover\:bg-info-light:hover {
  background-color: #99deea !important;
}
.bg-info {
  background-color: #00bde3 !important;
}
.hover\:bg-info:hover {
  background-color: #00bde3 !important;
}
.bg-info-dark {
  background-color: #009ec1 !important;
}
.hover\:bg-info-dark:hover {
  background-color: #009ec1 !important;
}
.bg-info-darker {
  background-color: #2e6276 !important;
}
.hover\:bg-info-darker:hover {
  background-color: #2e6276 !important;
}
.bg-disabled-lighter {
  background-color: #c9c9c9 !important;
}
.hover\:bg-disabled-lighter:hover {
  background-color: #c9c9c9 !important;
}
.bg-disabled-light {
  background-color: #919191 !important;
}
.hover\:bg-disabled-light:hover {
  background-color: #919191 !important;
}
.bg-disabled {
  background-color: #757575 !important;
}
.hover\:bg-disabled:hover {
  background-color: #757575 !important;
}
.bg-disabled-dark {
  background-color: #454545 !important;
}
.hover\:bg-disabled-dark:hover {
  background-color: #454545 !important;
}
.bg-disabled-darker {
  background-color: #1b1b1b !important;
}
.hover\:bg-disabled-darker:hover {
  background-color: #1b1b1b !important;
}
.bg-emergency {
  background-color: #9c3d10 !important;
}
.hover\:bg-emergency:hover {
  background-color: #9c3d10 !important;
}
.bg-emergency-dark {
  background-color: #332d29 !important;
}
.hover\:bg-emergency-dark:hover {
  background-color: #332d29 !important;
}
.border-1px {
  border: 1px solid !important;
}
.hover\:border-1px:hover {
  border: 1px solid !important;
}
.border-y-1px {
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}
.hover\:border-y-1px:hover {
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}
.border-x-1px {
  border-left: 1px solid !important;
  border-right: 1px solid !important;
}
.hover\:border-x-1px:hover {
  border-left: 1px solid !important;
  border-right: 1px solid !important;
}
.border-top-1px {
  border-top: 1px solid !important;
}
.hover\:border-top-1px:hover {
  border-top: 1px solid !important;
}
.border-right-1px {
  border-right: 1px solid !important;
}
.hover\:border-right-1px:hover {
  border-right: 1px solid !important;
}
.border-bottom-1px {
  border-bottom: 1px solid !important;
}
.hover\:border-bottom-1px:hover {
  border-bottom: 1px solid !important;
}
.border-left-1px {
  border-left: 1px solid !important;
}
.hover\:border-left-1px:hover {
  border-left: 1px solid !important;
}
.border-2px {
  border: 2px solid !important;
}
.hover\:border-2px:hover {
  border: 2px solid !important;
}
.border-y-2px {
  border-top: 2px solid !important;
  border-bottom: 2px solid !important;
}
.hover\:border-y-2px:hover {
  border-top: 2px solid !important;
  border-bottom: 2px solid !important;
}
.border-x-2px {
  border-left: 2px solid !important;
  border-right: 2px solid !important;
}
.hover\:border-x-2px:hover {
  border-left: 2px solid !important;
  border-right: 2px solid !important;
}
.border-top-2px {
  border-top: 2px solid !important;
}
.hover\:border-top-2px:hover {
  border-top: 2px solid !important;
}
.border-right-2px {
  border-right: 2px solid !important;
}
.hover\:border-right-2px:hover {
  border-right: 2px solid !important;
}
.border-bottom-2px {
  border-bottom: 2px solid !important;
}
.hover\:border-bottom-2px:hover {
  border-bottom: 2px solid !important;
}
.border-left-2px {
  border-left: 2px solid !important;
}
.hover\:border-left-2px:hover {
  border-left: 2px solid !important;
}
.border-05 {
  border: 0.25rem solid !important;
}
.hover\:border-05:hover {
  border: 0.25rem solid !important;
}
.border-y-05 {
  border-top: 0.25rem solid !important;
  border-bottom: 0.25rem solid !important;
}
.hover\:border-y-05:hover {
  border-top: 0.25rem solid !important;
  border-bottom: 0.25rem solid !important;
}
.border-x-05 {
  border-left: 0.25rem solid !important;
  border-right: 0.25rem solid !important;
}
.hover\:border-x-05:hover {
  border-left: 0.25rem solid !important;
  border-right: 0.25rem solid !important;
}
.border-top-05 {
  border-top: 0.25rem solid !important;
}
.hover\:border-top-05:hover {
  border-top: 0.25rem solid !important;
}
.border-right-05 {
  border-right: 0.25rem solid !important;
}
.hover\:border-right-05:hover {
  border-right: 0.25rem solid !important;
}
.border-bottom-05 {
  border-bottom: 0.25rem solid !important;
}
.hover\:border-bottom-05:hover {
  border-bottom: 0.25rem solid !important;
}
.border-left-05 {
  border-left: 0.25rem solid !important;
}
.hover\:border-left-05:hover {
  border-left: 0.25rem solid !important;
}
.border-1 {
  border: 0.5rem solid !important;
}
.hover\:border-1:hover {
  border: 0.5rem solid !important;
}
.border-y-1 {
  border-top: 0.5rem solid !important;
  border-bottom: 0.5rem solid !important;
}
.hover\:border-y-1:hover {
  border-top: 0.5rem solid !important;
  border-bottom: 0.5rem solid !important;
}
.border-x-1 {
  border-left: 0.5rem solid !important;
  border-right: 0.5rem solid !important;
}
.hover\:border-x-1:hover {
  border-left: 0.5rem solid !important;
  border-right: 0.5rem solid !important;
}
.border-top-1 {
  border-top: 0.5rem solid !important;
}
.hover\:border-top-1:hover {
  border-top: 0.5rem solid !important;
}
.border-right-1 {
  border-right: 0.5rem solid !important;
}
.hover\:border-right-1:hover {
  border-right: 0.5rem solid !important;
}
.border-bottom-1 {
  border-bottom: 0.5rem solid !important;
}
.hover\:border-bottom-1:hover {
  border-bottom: 0.5rem solid !important;
}
.border-left-1 {
  border-left: 0.5rem solid !important;
}
.hover\:border-left-1:hover {
  border-left: 0.5rem solid !important;
}
.border-105 {
  border: 0.75rem solid !important;
}
.hover\:border-105:hover {
  border: 0.75rem solid !important;
}
.border-y-105 {
  border-top: 0.75rem solid !important;
  border-bottom: 0.75rem solid !important;
}
.hover\:border-y-105:hover {
  border-top: 0.75rem solid !important;
  border-bottom: 0.75rem solid !important;
}
.border-x-105 {
  border-left: 0.75rem solid !important;
  border-right: 0.75rem solid !important;
}
.hover\:border-x-105:hover {
  border-left: 0.75rem solid !important;
  border-right: 0.75rem solid !important;
}
.border-top-105 {
  border-top: 0.75rem solid !important;
}
.hover\:border-top-105:hover {
  border-top: 0.75rem solid !important;
}
.border-right-105 {
  border-right: 0.75rem solid !important;
}
.hover\:border-right-105:hover {
  border-right: 0.75rem solid !important;
}
.border-bottom-105 {
  border-bottom: 0.75rem solid !important;
}
.hover\:border-bottom-105:hover {
  border-bottom: 0.75rem solid !important;
}
.border-left-105 {
  border-left: 0.75rem solid !important;
}
.hover\:border-left-105:hover {
  border-left: 0.75rem solid !important;
}
.border-2 {
  border: 1rem solid !important;
}
.hover\:border-2:hover {
  border: 1rem solid !important;
}
.border-y-2 {
  border-top: 1rem solid !important;
  border-bottom: 1rem solid !important;
}
.hover\:border-y-2:hover {
  border-top: 1rem solid !important;
  border-bottom: 1rem solid !important;
}
.border-x-2 {
  border-left: 1rem solid !important;
  border-right: 1rem solid !important;
}
.hover\:border-x-2:hover {
  border-left: 1rem solid !important;
  border-right: 1rem solid !important;
}
.border-top-2 {
  border-top: 1rem solid !important;
}
.hover\:border-top-2:hover {
  border-top: 1rem solid !important;
}
.border-right-2 {
  border-right: 1rem solid !important;
}
.hover\:border-right-2:hover {
  border-right: 1rem solid !important;
}
.border-bottom-2 {
  border-bottom: 1rem solid !important;
}
.hover\:border-bottom-2:hover {
  border-bottom: 1rem solid !important;
}
.border-left-2 {
  border-left: 1rem solid !important;
}
.hover\:border-left-2:hover {
  border-left: 1rem solid !important;
}
.border-205 {
  border: 1.25rem solid !important;
}
.hover\:border-205:hover {
  border: 1.25rem solid !important;
}
.border-y-205 {
  border-top: 1.25rem solid !important;
  border-bottom: 1.25rem solid !important;
}
.hover\:border-y-205:hover {
  border-top: 1.25rem solid !important;
  border-bottom: 1.25rem solid !important;
}
.border-x-205 {
  border-left: 1.25rem solid !important;
  border-right: 1.25rem solid !important;
}
.hover\:border-x-205:hover {
  border-left: 1.25rem solid !important;
  border-right: 1.25rem solid !important;
}
.border-top-205 {
  border-top: 1.25rem solid !important;
}
.hover\:border-top-205:hover {
  border-top: 1.25rem solid !important;
}
.border-right-205 {
  border-right: 1.25rem solid !important;
}
.hover\:border-right-205:hover {
  border-right: 1.25rem solid !important;
}
.border-bottom-205 {
  border-bottom: 1.25rem solid !important;
}
.hover\:border-bottom-205:hover {
  border-bottom: 1.25rem solid !important;
}
.border-left-205 {
  border-left: 1.25rem solid !important;
}
.hover\:border-left-205:hover {
  border-left: 1.25rem solid !important;
}
.border-3 {
  border: 1.5rem solid !important;
}
.hover\:border-3:hover {
  border: 1.5rem solid !important;
}
.border-y-3 {
  border-top: 1.5rem solid !important;
  border-bottom: 1.5rem solid !important;
}
.hover\:border-y-3:hover {
  border-top: 1.5rem solid !important;
  border-bottom: 1.5rem solid !important;
}
.border-x-3 {
  border-left: 1.5rem solid !important;
  border-right: 1.5rem solid !important;
}
.hover\:border-x-3:hover {
  border-left: 1.5rem solid !important;
  border-right: 1.5rem solid !important;
}
.border-top-3 {
  border-top: 1.5rem solid !important;
}
.hover\:border-top-3:hover {
  border-top: 1.5rem solid !important;
}
.border-right-3 {
  border-right: 1.5rem solid !important;
}
.hover\:border-right-3:hover {
  border-right: 1.5rem solid !important;
}
.border-bottom-3 {
  border-bottom: 1.5rem solid !important;
}
.hover\:border-bottom-3:hover {
  border-bottom: 1.5rem solid !important;
}
.border-left-3 {
  border-left: 1.5rem solid !important;
}
.hover\:border-left-3:hover {
  border-left: 1.5rem solid !important;
}
.border-0 {
  border: 0 solid !important;
}
.hover\:border-0:hover {
  border: 0 solid !important;
}
.border-y-0 {
  border-top: 0 solid !important;
  border-bottom: 0 solid !important;
}
.hover\:border-y-0:hover {
  border-top: 0 solid !important;
  border-bottom: 0 solid !important;
}
.border-x-0 {
  border-left: 0 solid !important;
  border-right: 0 solid !important;
}
.hover\:border-x-0:hover {
  border-left: 0 solid !important;
  border-right: 0 solid !important;
}
.border-top-0 {
  border-top: 0 solid !important;
}
.hover\:border-top-0:hover {
  border-top: 0 solid !important;
}
.border-right-0 {
  border-right: 0 solid !important;
}
.hover\:border-right-0:hover {
  border-right: 0 solid !important;
}
.border-bottom-0 {
  border-bottom: 0 solid !important;
}
.hover\:border-bottom-0:hover {
  border-bottom: 0 solid !important;
}
.border-left-0 {
  border-left: 0 solid !important;
}
.hover\:border-left-0:hover {
  border-left: 0 solid !important;
}
.border {
  border: 1px solid !important;
}
.hover\:border:hover {
  border: 1px solid !important;
}
.border-y {
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}
.hover\:border-y:hover {
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}
.border-x {
  border-left: 1px solid !important;
  border-right: 1px solid !important;
}
.hover\:border-x:hover {
  border-left: 1px solid !important;
  border-right: 1px solid !important;
}
.border-top {
  border-top: 1px solid !important;
}
.hover\:border-top:hover {
  border-top: 1px solid !important;
}
.border-right {
  border-right: 1px solid !important;
}
.hover\:border-right:hover {
  border-right: 1px solid !important;
}
.border-bottom {
  border-bottom: 1px solid !important;
}
.hover\:border-bottom:hover {
  border-bottom: 1px solid !important;
}
.border-left {
  border-left: 1px solid !important;
}
.hover\:border-left:hover {
  border-left: 1px solid !important;
}
.border-transparent {
  border-color: transparent !important;
}
.hover\:border-transparent:hover {
  border-color: transparent !important;
}
.border-black {
  border-color: black !important;
}
.hover\:border-black:hover {
  border-color: black !important;
}
.border-white {
  border-color: white !important;
}
.hover\:border-white:hover {
  border-color: white !important;
}
.border-red {
  border-color: #e52207 !important;
}
.hover\:border-red:hover {
  border-color: #e52207 !important;
}
.border-orange {
  border-color: #e66f0e !important;
}
.hover\:border-orange:hover {
  border-color: #e66f0e !important;
}
.border-gold {
  border-color: #ffbe2e !important;
}
.hover\:border-gold:hover {
  border-color: #ffbe2e !important;
}
.border-yellow {
  border-color: #fee685 !important;
}
.hover\:border-yellow:hover {
  border-color: #fee685 !important;
}
.border-green {
  border-color: #538200 !important;
}
.hover\:border-green:hover {
  border-color: #538200 !important;
}
.border-mint {
  border-color: #04c585 !important;
}
.hover\:border-mint:hover {
  border-color: #04c585 !important;
}
.border-cyan {
  border-color: #009ec1 !important;
}
.hover\:border-cyan:hover {
  border-color: #009ec1 !important;
}
.border-blue {
  border-color: #0076d6 !important;
}
.hover\:border-blue:hover {
  border-color: #0076d6 !important;
}
.border-indigo {
  border-color: #676cc8 !important;
}
.hover\:border-indigo:hover {
  border-color: #676cc8 !important;
}
.border-violet {
  border-color: #8168b3 !important;
}
.hover\:border-violet:hover {
  border-color: #8168b3 !important;
}
.border-magenta {
  border-color: #d72d79 !important;
}
.hover\:border-magenta:hover {
  border-color: #d72d79 !important;
}
.border-gray-5 {
  border-color: #f0f0f0 !important;
}
.hover\:border-gray-5:hover {
  border-color: #f0f0f0 !important;
}
.border-gray-10 {
  border-color: #e6e6e6 !important;
}
.hover\:border-gray-10:hover {
  border-color: #e6e6e6 !important;
}
.border-gray-30 {
  border-color: #adadad !important;
}
.hover\:border-gray-30:hover {
  border-color: #adadad !important;
}
.border-gray-50 {
  border-color: #757575 !important;
}
.hover\:border-gray-50:hover {
  border-color: #757575 !important;
}
.border-gray-70 {
  border-color: #454545 !important;
}
.hover\:border-gray-70:hover {
  border-color: #454545 !important;
}
.border-gray-90 {
  border-color: #1b1b1b !important;
}
.hover\:border-gray-90:hover {
  border-color: #1b1b1b !important;
}
.border-base-lightest {
  border-color: #f0f0f0 !important;
}
.hover\:border-base-lightest:hover {
  border-color: #f0f0f0 !important;
}
.border-base-lighter {
  border-color: #dfe1e2 !important;
}
.hover\:border-base-lighter:hover {
  border-color: #dfe1e2 !important;
}
.border-base-light {
  border-color: #a9aeb1 !important;
}
.hover\:border-base-light:hover {
  border-color: #a9aeb1 !important;
}
.border-base {
  border-color: #71767a !important;
}
.hover\:border-base:hover {
  border-color: #71767a !important;
}
.border-base-dark {
  border-color: #565c65 !important;
}
.hover\:border-base-dark:hover {
  border-color: #565c65 !important;
}
.border-base-darker {
  border-color: #3d4551 !important;
}
.hover\:border-base-darker:hover {
  border-color: #3d4551 !important;
}
.border-base-darkest {
  border-color: #2d2e2f !important;
}
.hover\:border-base-darkest:hover {
  border-color: #2d2e2f !important;
}
.border-ink {
  border-color: #1c1d1f !important;
}
.hover\:border-ink:hover {
  border-color: #1c1d1f !important;
}
.border-primary-lighter {
  border-color: #d9e8f6 !important;
}
.hover\:border-primary-lighter:hover {
  border-color: #d9e8f6 !important;
}
.border-primary-light {
  border-color: #73b3e7 !important;
}
.hover\:border-primary-light:hover {
  border-color: #73b3e7 !important;
}
.border-primary {
  border-color: #005ea2 !important;
}
.hover\:border-primary:hover {
  border-color: #005ea2 !important;
}
.border-primary-vivid {
  border-color: #0050d8 !important;
}
.hover\:border-primary-vivid:hover {
  border-color: #0050d8 !important;
}
.border-primary-dark {
  border-color: #1a4480 !important;
}
.hover\:border-primary-dark:hover {
  border-color: #1a4480 !important;
}
.border-primary-darker {
  border-color: #162e51 !important;
}
.hover\:border-primary-darker:hover {
  border-color: #162e51 !important;
}
.border-secondary-lighter {
  border-color: #f3e1e4 !important;
}
.hover\:border-secondary-lighter:hover {
  border-color: #f3e1e4 !important;
}
.border-secondary-light {
  border-color: #f2938c !important;
}
.hover\:border-secondary-light:hover {
  border-color: #f2938c !important;
}
.border-secondary {
  border-color: #d83933 !important;
}
.hover\:border-secondary:hover {
  border-color: #d83933 !important;
}
.border-secondary-vivid {
  border-color: #e41d3d !important;
}
.hover\:border-secondary-vivid:hover {
  border-color: #e41d3d !important;
}
.border-secondary-dark {
  border-color: #b50909 !important;
}
.hover\:border-secondary-dark:hover {
  border-color: #b50909 !important;
}
.border-secondary-darker {
  border-color: #8b0a03 !important;
}
.hover\:border-secondary-darker:hover {
  border-color: #8b0a03 !important;
}
.border-accent-warm-darker {
  border-color: #775540 !important;
}
.hover\:border-accent-warm-darker:hover {
  border-color: #775540 !important;
}
.border-accent-warm-dark {
  border-color: #c05600 !important;
}
.hover\:border-accent-warm-dark:hover {
  border-color: #c05600 !important;
}
.border-accent-warm {
  border-color: #fa9441 !important;
}
.hover\:border-accent-warm:hover {
  border-color: #fa9441 !important;
}
.border-accent-warm-light {
  border-color: #ffbc78 !important;
}
.hover\:border-accent-warm-light:hover {
  border-color: #ffbc78 !important;
}
.border-accent-warm-lighter {
  border-color: #f2e4d4 !important;
}
.hover\:border-accent-warm-lighter:hover {
  border-color: #f2e4d4 !important;
}
.border-accent-cool-darker {
  border-color: #07648d !important;
}
.hover\:border-accent-cool-darker:hover {
  border-color: #07648d !important;
}
.border-accent-cool-dark {
  border-color: #28a0cb !important;
}
.hover\:border-accent-cool-dark:hover {
  border-color: #28a0cb !important;
}
.border-accent-cool {
  border-color: #00bde3 !important;
}
.hover\:border-accent-cool:hover {
  border-color: #00bde3 !important;
}
.border-accent-cool-light {
  border-color: #97d4ea !important;
}
.hover\:border-accent-cool-light:hover {
  border-color: #97d4ea !important;
}
.border-accent-cool-lighter {
  border-color: #e1f3f8 !important;
}
.hover\:border-accent-cool-lighter:hover {
  border-color: #e1f3f8 !important;
}
.border-error-lighter {
  border-color: #f4e3db !important;
}
.hover\:border-error-lighter:hover {
  border-color: #f4e3db !important;
}
.border-error-light {
  border-color: #f39268 !important;
}
.hover\:border-error-light:hover {
  border-color: #f39268 !important;
}
.border-error {
  border-color: #d54309 !important;
}
.hover\:border-error:hover {
  border-color: #d54309 !important;
}
.border-error-dark {
  border-color: #b50909 !important;
}
.hover\:border-error-dark:hover {
  border-color: #b50909 !important;
}
.border-error-darker {
  border-color: #6f3331 !important;
}
.hover\:border-error-darker:hover {
  border-color: #6f3331 !important;
}
.border-warning-lighter {
  border-color: #faf3d1 !important;
}
.hover\:border-warning-lighter:hover {
  border-color: #faf3d1 !important;
}
.border-warning-light {
  border-color: #fee685 !important;
}
.hover\:border-warning-light:hover {
  border-color: #fee685 !important;
}
.border-warning {
  border-color: #ffbe2e !important;
}
.hover\:border-warning:hover {
  border-color: #ffbe2e !important;
}
.border-warning-dark {
  border-color: #e5a000 !important;
}
.hover\:border-warning-dark:hover {
  border-color: #e5a000 !important;
}
.border-warning-darker {
  border-color: #936f38 !important;
}
.hover\:border-warning-darker:hover {
  border-color: #936f38 !important;
}
.border-success-lighter {
  border-color: #ecf3ec !important;
}
.hover\:border-success-lighter:hover {
  border-color: #ecf3ec !important;
}
.border-success-light {
  border-color: #70e17b !important;
}
.hover\:border-success-light:hover {
  border-color: #70e17b !important;
}
.border-success {
  border-color: #00a91c !important;
}
.hover\:border-success:hover {
  border-color: #00a91c !important;
}
.border-success-dark {
  border-color: #008817 !important;
}
.hover\:border-success-dark:hover {
  border-color: #008817 !important;
}
.border-success-darker {
  border-color: #216e1f !important;
}
.hover\:border-success-darker:hover {
  border-color: #216e1f !important;
}
.border-info-lighter {
  border-color: #e7f6f8 !important;
}
.hover\:border-info-lighter:hover {
  border-color: #e7f6f8 !important;
}
.border-info-light {
  border-color: #99deea !important;
}
.hover\:border-info-light:hover {
  border-color: #99deea !important;
}
.border-info {
  border-color: #00bde3 !important;
}
.hover\:border-info:hover {
  border-color: #00bde3 !important;
}
.border-info-dark {
  border-color: #009ec1 !important;
}
.hover\:border-info-dark:hover {
  border-color: #009ec1 !important;
}
.border-info-darker {
  border-color: #2e6276 !important;
}
.hover\:border-info-darker:hover {
  border-color: #2e6276 !important;
}
.border-disabled-lighter {
  border-color: #c9c9c9 !important;
}
.hover\:border-disabled-lighter:hover {
  border-color: #c9c9c9 !important;
}
.border-disabled-light {
  border-color: #919191 !important;
}
.hover\:border-disabled-light:hover {
  border-color: #919191 !important;
}
.border-disabled {
  border-color: #757575 !important;
}
.hover\:border-disabled:hover {
  border-color: #757575 !important;
}
.border-disabled-dark {
  border-color: #454545 !important;
}
.hover\:border-disabled-dark:hover {
  border-color: #454545 !important;
}
.border-disabled-darker {
  border-color: #1b1b1b !important;
}
.hover\:border-disabled-darker:hover {
  border-color: #1b1b1b !important;
}
.border-emergency {
  border-color: #9c3d10 !important;
}
.hover\:border-emergency:hover {
  border-color: #9c3d10 !important;
}
.border-emergency-dark {
  border-color: #332d29 !important;
}
.hover\:border-emergency-dark:hover {
  border-color: #332d29 !important;
}
.radius-0 {
  border-radius: 0 !important;
}
.radius-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.radius-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.radius-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.radius-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.radius-sm {
  border-radius: 2px !important;
}
.radius-top-sm {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
}
.radius-right-sm {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
.radius-bottom-sm {
  border-bottom-left-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
.radius-left-sm {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}
.radius-md {
  border-radius: 0.25rem !important;
}
.radius-top-md {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.radius-right-md {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.radius-bottom-md {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.radius-left-md {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.radius-lg {
  border-radius: 0.5rem !important;
}
.radius-top-lg {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}
.radius-right-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
.radius-bottom-lg {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
.radius-left-lg {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}
.radius-pill {
  border-radius: 99rem !important;
}
.radius-top-pill {
  border-top-left-radius: 99rem !important;
  border-top-right-radius: 99rem !important;
}
.radius-right-pill {
  border-top-right-radius: 99rem !important;
  border-bottom-right-radius: 99rem !important;
}
.radius-bottom-pill {
  border-bottom-left-radius: 99rem !important;
  border-bottom-right-radius: 99rem !important;
}
.radius-left-pill {
  border-top-left-radius: 99rem !important;
  border-bottom-left-radius: 99rem !important;
}
.border-dashed {
  border-style: dashed !important;
}
.border-dotted {
  border-style: dotted !important;
}
.border-solid {
  border-style: solid !important;
}
.border-width-1px {
  border-width: 1px !important;
}
.border-y-width-1px {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}
.border-x-width-1px {
  border-left-width: 1px !important;
  border-right-width: 1px !important;
}
.border-top-width-1px {
  border-top-width: 1px !important;
}
.border-right-width-1px {
  border-right-width: 1px !important;
}
.border-bottom-width-1px {
  border-bottom-width: 1px !important;
}
.border-left-width-1px {
  border-left-width: 1px !important;
}
.border-width-2px {
  border-width: 2px !important;
}
.border-y-width-2px {
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}
.border-x-width-2px {
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}
.border-top-width-2px {
  border-top-width: 2px !important;
}
.border-right-width-2px {
  border-right-width: 2px !important;
}
.border-bottom-width-2px {
  border-bottom-width: 2px !important;
}
.border-left-width-2px {
  border-left-width: 2px !important;
}
.border-width-05 {
  border-width: 0.25rem !important;
}
.border-y-width-05 {
  border-top-width: 0.25rem !important;
  border-bottom-width: 0.25rem !important;
}
.border-x-width-05 {
  border-left-width: 0.25rem !important;
  border-right-width: 0.25rem !important;
}
.border-top-width-05 {
  border-top-width: 0.25rem !important;
}
.border-right-width-05 {
  border-right-width: 0.25rem !important;
}
.border-bottom-width-05 {
  border-bottom-width: 0.25rem !important;
}
.border-left-width-05 {
  border-left-width: 0.25rem !important;
}
.border-width-1 {
  border-width: 0.5rem !important;
}
.border-y-width-1 {
  border-top-width: 0.5rem !important;
  border-bottom-width: 0.5rem !important;
}
.border-x-width-1 {
  border-left-width: 0.5rem !important;
  border-right-width: 0.5rem !important;
}
.border-top-width-1 {
  border-top-width: 0.5rem !important;
}
.border-right-width-1 {
  border-right-width: 0.5rem !important;
}
.border-bottom-width-1 {
  border-bottom-width: 0.5rem !important;
}
.border-left-width-1 {
  border-left-width: 0.5rem !important;
}
.border-width-105 {
  border-width: 0.75rem !important;
}
.border-y-width-105 {
  border-top-width: 0.75rem !important;
  border-bottom-width: 0.75rem !important;
}
.border-x-width-105 {
  border-left-width: 0.75rem !important;
  border-right-width: 0.75rem !important;
}
.border-top-width-105 {
  border-top-width: 0.75rem !important;
}
.border-right-width-105 {
  border-right-width: 0.75rem !important;
}
.border-bottom-width-105 {
  border-bottom-width: 0.75rem !important;
}
.border-left-width-105 {
  border-left-width: 0.75rem !important;
}
.border-width-2 {
  border-width: 1rem !important;
}
.border-y-width-2 {
  border-top-width: 1rem !important;
  border-bottom-width: 1rem !important;
}
.border-x-width-2 {
  border-left-width: 1rem !important;
  border-right-width: 1rem !important;
}
.border-top-width-2 {
  border-top-width: 1rem !important;
}
.border-right-width-2 {
  border-right-width: 1rem !important;
}
.border-bottom-width-2 {
  border-bottom-width: 1rem !important;
}
.border-left-width-2 {
  border-left-width: 1rem !important;
}
.border-width-205 {
  border-width: 1.25rem !important;
}
.border-y-width-205 {
  border-top-width: 1.25rem !important;
  border-bottom-width: 1.25rem !important;
}
.border-x-width-205 {
  border-left-width: 1.25rem !important;
  border-right-width: 1.25rem !important;
}
.border-top-width-205 {
  border-top-width: 1.25rem !important;
}
.border-right-width-205 {
  border-right-width: 1.25rem !important;
}
.border-bottom-width-205 {
  border-bottom-width: 1.25rem !important;
}
.border-left-width-205 {
  border-left-width: 1.25rem !important;
}
.border-width-3 {
  border-width: 1.5rem !important;
}
.border-y-width-3 {
  border-top-width: 1.5rem !important;
  border-bottom-width: 1.5rem !important;
}
.border-x-width-3 {
  border-left-width: 1.5rem !important;
  border-right-width: 1.5rem !important;
}
.border-top-width-3 {
  border-top-width: 1.5rem !important;
}
.border-right-width-3 {
  border-right-width: 1.5rem !important;
}
.border-bottom-width-3 {
  border-bottom-width: 1.5rem !important;
}
.border-left-width-3 {
  border-left-width: 1.5rem !important;
}
.border-width-0 {
  border-width: 0 !important;
}
.border-y-width-0 {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
}
.border-x-width-0 {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}
.border-top-width-0 {
  border-top-width: 0 !important;
}
.border-right-width-0 {
  border-right-width: 0 !important;
}
.border-bottom-width-0 {
  border-bottom-width: 0 !important;
}
.border-left-width-0 {
  border-left-width: 0 !important;
}
.bottom-1px {
  bottom: 1px !important;
}
.bottom-2px {
  bottom: 2px !important;
}
.bottom-05 {
  bottom: 0.25rem !important;
}
.bottom-1 {
  bottom: 0.5rem !important;
}
.bottom-105 {
  bottom: 0.75rem !important;
}
.bottom-2 {
  bottom: 1rem !important;
}
.bottom-205 {
  bottom: 1.25rem !important;
}
.bottom-3 {
  bottom: 1.5rem !important;
}
.bottom-neg-1px {
  bottom: -1px !important;
}
.bottom-neg-2px {
  bottom: -2px !important;
}
.bottom-neg-05 {
  bottom: -0.25rem !important;
}
.bottom-neg-1 {
  bottom: -0.5rem !important;
}
.bottom-neg-105 {
  bottom: -0.75rem !important;
}
.bottom-neg-2 {
  bottom: -1rem !important;
}
.bottom-neg-205 {
  bottom: -1.25rem !important;
}
.bottom-neg-3 {
  bottom: -1.5rem !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.bottom-auto {
  bottom: auto !important;
}
.bottom-full {
  bottom: 100% !important;
}
.shadow-none {
  box-shadow: none !important;
}
.hover\:shadow-none:hover {
  box-shadow: none !important;
}
.shadow-1 {
  box-shadow: 0 1px 0.25rem 0 rgba(0, 0, 0, 0.1) !important;
}
.hover\:shadow-1:hover {
  box-shadow: 0 1px 0.25rem 0 rgba(0, 0, 0, 0.1) !important;
}
.shadow-2 {
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1) !important;
}
.hover\:shadow-2:hover {
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1) !important;
}
.shadow-3 {
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1) !important;
}
.hover\:shadow-3:hover {
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1) !important;
}
.shadow-4 {
  box-shadow: 0 0.75rem 1.5rem 0 rgba(0, 0, 0, 0.1) !important;
}
.hover\:shadow-4:hover {
  box-shadow: 0 0.75rem 1.5rem 0 rgba(0, 0, 0, 0.1) !important;
}
.shadow-5 {
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.1) !important;
}
.hover\:shadow-5:hover {
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.1) !important;
}
.circle-1px {
  height: 1px !important;
  width: 1px !important;
  border-radius: 50% !important;
}
.circle-2px {
  height: 2px !important;
  width: 2px !important;
  border-radius: 50% !important;
}
.circle-05 {
  height: 0.25rem !important;
  width: 0.25rem !important;
  border-radius: 50% !important;
}
.circle-1 {
  height: 0.5rem !important;
  width: 0.5rem !important;
  border-radius: 50% !important;
}
.circle-105 {
  height: 0.75rem !important;
  width: 0.75rem !important;
  border-radius: 50% !important;
}
.circle-2 {
  height: 1rem !important;
  width: 1rem !important;
  border-radius: 50% !important;
}
.circle-205 {
  height: 1.25rem !important;
  width: 1.25rem !important;
  border-radius: 50% !important;
}
.circle-3 {
  height: 1.5rem !important;
  width: 1.5rem !important;
  border-radius: 50% !important;
}
.circle-4 {
  height: 2rem !important;
  width: 2rem !important;
  border-radius: 50% !important;
}
.circle-5 {
  height: 2.5rem !important;
  width: 2.5rem !important;
  border-radius: 50% !important;
}
.circle-6 {
  height: 3rem !important;
  width: 3rem !important;
  border-radius: 50% !important;
}
.circle-7 {
  height: 3.5rem !important;
  width: 3.5rem !important;
  border-radius: 50% !important;
}
.circle-8 {
  height: 4rem !important;
  width: 4rem !important;
  border-radius: 50% !important;
}
.circle-9 {
  height: 4.5rem !important;
  width: 4.5rem !important;
  border-radius: 50% !important;
}
.circle-10 {
  height: 5rem !important;
  width: 5rem !important;
  border-radius: 50% !important;
}
.circle-15 {
  height: 7.5rem !important;
  width: 7.5rem !important;
  border-radius: 50% !important;
}
.circle-card {
  height: 10rem !important;
  width: 10rem !important;
  border-radius: 50% !important;
}
.circle-card-lg {
  height: 15rem !important;
  width: 15rem !important;
  border-radius: 50% !important;
}
.circle-mobile {
  height: 20rem !important;
  width: 20rem !important;
  border-radius: 50% !important;
}
.clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}
.text-transparent {
  color: transparent !important;
}
.hover\:text-transparent:hover {
  color: transparent !important;
}
.text-black {
  color: black !important;
}
.hover\:text-black:hover {
  color: black !important;
}
.text-white {
  color: white !important;
}
.hover\:text-white:hover {
  color: white !important;
}
.text-red {
  color: #e52207 !important;
}
.hover\:text-red:hover {
  color: #e52207 !important;
}
.text-orange {
  color: #e66f0e !important;
}
.hover\:text-orange:hover {
  color: #e66f0e !important;
}
.text-gold {
  color: #ffbe2e !important;
}
.hover\:text-gold:hover {
  color: #ffbe2e !important;
}
.text-yellow {
  color: #fee685 !important;
}
.hover\:text-yellow:hover {
  color: #fee685 !important;
}
.text-green {
  color: #538200 !important;
}
.hover\:text-green:hover {
  color: #538200 !important;
}
.text-mint {
  color: #04c585 !important;
}
.hover\:text-mint:hover {
  color: #04c585 !important;
}
.text-cyan {
  color: #009ec1 !important;
}
.hover\:text-cyan:hover {
  color: #009ec1 !important;
}
.text-blue {
  color: #0076d6 !important;
}
.hover\:text-blue:hover {
  color: #0076d6 !important;
}
.text-indigo {
  color: #676cc8 !important;
}
.hover\:text-indigo:hover {
  color: #676cc8 !important;
}
.text-violet {
  color: #8168b3 !important;
}
.hover\:text-violet:hover {
  color: #8168b3 !important;
}
.text-magenta {
  color: #d72d79 !important;
}
.hover\:text-magenta:hover {
  color: #d72d79 !important;
}
.text-gray-5 {
  color: #f0f0f0 !important;
}
.hover\:text-gray-5:hover {
  color: #f0f0f0 !important;
}
.text-gray-10 {
  color: #e6e6e6 !important;
}
.hover\:text-gray-10:hover {
  color: #e6e6e6 !important;
}
.text-gray-30 {
  color: #adadad !important;
}
.hover\:text-gray-30:hover {
  color: #adadad !important;
}
.text-gray-50 {
  color: #757575 !important;
}
.hover\:text-gray-50:hover {
  color: #757575 !important;
}
.text-gray-70 {
  color: #454545 !important;
}
.hover\:text-gray-70:hover {
  color: #454545 !important;
}
.text-gray-90 {
  color: #1b1b1b !important;
}
.hover\:text-gray-90:hover {
  color: #1b1b1b !important;
}
.text-base-lightest {
  color: #f0f0f0 !important;
}
.hover\:text-base-lightest:hover {
  color: #f0f0f0 !important;
}
.text-base-lighter {
  color: #dfe1e2 !important;
}
.hover\:text-base-lighter:hover {
  color: #dfe1e2 !important;
}
.text-base-light {
  color: #a9aeb1 !important;
}
.hover\:text-base-light:hover {
  color: #a9aeb1 !important;
}
.text-base {
  color: #71767a !important;
}
.hover\:text-base:hover {
  color: #71767a !important;
}
.text-base-dark {
  color: #565c65 !important;
}
.hover\:text-base-dark:hover {
  color: #565c65 !important;
}
.text-base-darker {
  color: #3d4551 !important;
}
.hover\:text-base-darker:hover {
  color: #3d4551 !important;
}
.text-base-darkest {
  color: #2d2e2f !important;
}
.hover\:text-base-darkest:hover {
  color: #2d2e2f !important;
}
.text-ink {
  color: #1c1d1f !important;
}
.hover\:text-ink:hover {
  color: #1c1d1f !important;
}
.text-primary-lighter {
  color: #d9e8f6 !important;
}
.hover\:text-primary-lighter:hover {
  color: #d9e8f6 !important;
}
.text-primary-light {
  color: #73b3e7 !important;
}
.hover\:text-primary-light:hover {
  color: #73b3e7 !important;
}
.text-primary {
  color: #005ea2 !important;
}
.hover\:text-primary:hover {
  color: #005ea2 !important;
}
.text-primary-vivid {
  color: #0050d8 !important;
}
.hover\:text-primary-vivid:hover {
  color: #0050d8 !important;
}
.text-primary-dark {
  color: #1a4480 !important;
}
.hover\:text-primary-dark:hover {
  color: #1a4480 !important;
}
.text-primary-darker {
  color: #162e51 !important;
}
.hover\:text-primary-darker:hover {
  color: #162e51 !important;
}
.text-secondary-lighter {
  color: #f3e1e4 !important;
}
.hover\:text-secondary-lighter:hover {
  color: #f3e1e4 !important;
}
.text-secondary-light {
  color: #f2938c !important;
}
.hover\:text-secondary-light:hover {
  color: #f2938c !important;
}
.text-secondary {
  color: #d83933 !important;
}
.hover\:text-secondary:hover {
  color: #d83933 !important;
}
.text-secondary-vivid {
  color: #e41d3d !important;
}
.hover\:text-secondary-vivid:hover {
  color: #e41d3d !important;
}
.text-secondary-dark {
  color: #b50909 !important;
}
.hover\:text-secondary-dark:hover {
  color: #b50909 !important;
}
.text-secondary-darker {
  color: #8b0a03 !important;
}
.hover\:text-secondary-darker:hover {
  color: #8b0a03 !important;
}
.text-accent-warm-darker {
  color: #775540 !important;
}
.hover\:text-accent-warm-darker:hover {
  color: #775540 !important;
}
.text-accent-warm-dark {
  color: #c05600 !important;
}
.hover\:text-accent-warm-dark:hover {
  color: #c05600 !important;
}
.text-accent-warm {
  color: #fa9441 !important;
}
.hover\:text-accent-warm:hover {
  color: #fa9441 !important;
}
.text-accent-warm-light {
  color: #ffbc78 !important;
}
.hover\:text-accent-warm-light:hover {
  color: #ffbc78 !important;
}
.text-accent-warm-lighter {
  color: #f2e4d4 !important;
}
.hover\:text-accent-warm-lighter:hover {
  color: #f2e4d4 !important;
}
.text-accent-cool-darker {
  color: #07648d !important;
}
.hover\:text-accent-cool-darker:hover {
  color: #07648d !important;
}
.text-accent-cool-dark {
  color: #28a0cb !important;
}
.hover\:text-accent-cool-dark:hover {
  color: #28a0cb !important;
}
.text-accent-cool {
  color: #00bde3 !important;
}
.hover\:text-accent-cool:hover {
  color: #00bde3 !important;
}
.text-accent-cool-light {
  color: #97d4ea !important;
}
.hover\:text-accent-cool-light:hover {
  color: #97d4ea !important;
}
.text-accent-cool-lighter {
  color: #e1f3f8 !important;
}
.hover\:text-accent-cool-lighter:hover {
  color: #e1f3f8 !important;
}
.text-error-lighter {
  color: #f4e3db !important;
}
.hover\:text-error-lighter:hover {
  color: #f4e3db !important;
}
.text-error-light {
  color: #f39268 !important;
}
.hover\:text-error-light:hover {
  color: #f39268 !important;
}
.text-error {
  color: #d54309 !important;
}
.hover\:text-error:hover {
  color: #d54309 !important;
}
.text-error-dark {
  color: #b50909 !important;
}
.hover\:text-error-dark:hover {
  color: #b50909 !important;
}
.text-error-darker {
  color: #6f3331 !important;
}
.hover\:text-error-darker:hover {
  color: #6f3331 !important;
}
.text-warning-lighter {
  color: #faf3d1 !important;
}
.hover\:text-warning-lighter:hover {
  color: #faf3d1 !important;
}
.text-warning-light {
  color: #fee685 !important;
}
.hover\:text-warning-light:hover {
  color: #fee685 !important;
}
.text-warning {
  color: #ffbe2e !important;
}
.hover\:text-warning:hover {
  color: #ffbe2e !important;
}
.text-warning-dark {
  color: #e5a000 !important;
}
.hover\:text-warning-dark:hover {
  color: #e5a000 !important;
}
.text-warning-darker {
  color: #936f38 !important;
}
.hover\:text-warning-darker:hover {
  color: #936f38 !important;
}
.text-success-lighter {
  color: #ecf3ec !important;
}
.hover\:text-success-lighter:hover {
  color: #ecf3ec !important;
}
.text-success-light {
  color: #70e17b !important;
}
.hover\:text-success-light:hover {
  color: #70e17b !important;
}
.text-success {
  color: #00a91c !important;
}
.hover\:text-success:hover {
  color: #00a91c !important;
}
.text-success-dark {
  color: #008817 !important;
}
.hover\:text-success-dark:hover {
  color: #008817 !important;
}
.text-success-darker {
  color: #216e1f !important;
}
.hover\:text-success-darker:hover {
  color: #216e1f !important;
}
.text-info-lighter {
  color: #e7f6f8 !important;
}
.hover\:text-info-lighter:hover {
  color: #e7f6f8 !important;
}
.text-info-light {
  color: #99deea !important;
}
.hover\:text-info-light:hover {
  color: #99deea !important;
}
.text-info {
  color: #00bde3 !important;
}
.hover\:text-info:hover {
  color: #00bde3 !important;
}
.text-info-dark {
  color: #009ec1 !important;
}
.hover\:text-info-dark:hover {
  color: #009ec1 !important;
}
.text-info-darker {
  color: #2e6276 !important;
}
.hover\:text-info-darker:hover {
  color: #2e6276 !important;
}
.text-disabled-lighter {
  color: #c9c9c9 !important;
}
.hover\:text-disabled-lighter:hover {
  color: #c9c9c9 !important;
}
.text-disabled-light {
  color: #919191 !important;
}
.hover\:text-disabled-light:hover {
  color: #919191 !important;
}
.text-disabled {
  color: #757575 !important;
}
.hover\:text-disabled:hover {
  color: #757575 !important;
}
.text-disabled-dark {
  color: #454545 !important;
}
.hover\:text-disabled-dark:hover {
  color: #454545 !important;
}
.text-disabled-darker {
  color: #1b1b1b !important;
}
.hover\:text-disabled-darker:hover {
  color: #1b1b1b !important;
}
.text-emergency {
  color: #9c3d10 !important;
}
.hover\:text-emergency:hover {
  color: #9c3d10 !important;
}
.text-emergency-dark {
  color: #332d29 !important;
}
.hover\:text-emergency-dark:hover {
  color: #332d29 !important;
}
.cursor-auto {
  cursor: auto !important;
}
.cursor-default {
  cursor: default !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-wait {
  cursor: wait !important;
}
.cursor-move {
  cursor: move !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.display-block {
  display: block !important;
}
.display-flex {
  display: flex !important;
}
.display-none {
  display: none !important;
}
.display-inline {
  display: inline !important;
}
.display-inline-block {
  display: inline-block !important;
}
.display-inline-flex {
  display: inline-flex !important;
}
.display-table {
  display: table !important;
}
.display-table-cell {
  display: table-cell !important;
}
.display-table-row {
  display: table-row !important;
}
.flex-1 {
  flex: 1 1 0% !important;
}
.flex-2 {
  flex: 2 1 0% !important;
}
.flex-3 {
  flex: 3 1 0% !important;
}
.flex-4 {
  flex: 4 1 0% !important;
}
.flex-5 {
  flex: 5 1 0% !important;
}
.flex-6 {
  flex: 6 1 0% !important;
}
.flex-7 {
  flex: 7 1 0% !important;
}
.flex-8 {
  flex: 8 1 0% !important;
}
.flex-9 {
  flex: 9 1 0% !important;
}
.flex-10 {
  flex: 10 1 0% !important;
}
.flex-11 {
  flex: 11 1 0% !important;
}
.flex-12 {
  flex: 12 1 0% !important;
}
.flex-fill {
  flex: 1 1 0% !important;
}
.flex-auto {
  flex: 0 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-no-wrap {
  flex-wrap: nowrap !important;
}
.float-left {
  float: left !important;
}
.float-none {
  float: none !important;
}
.float-right {
  float: right !important;
}
.font-mono-3xs {
  font-size: 0.77rem !important;
}
.font-mono-2xs {
  font-size: 0.83rem !important;
}
.font-mono-xs {
  font-size: 0.89rem !important;
}
.font-mono-sm {
  font-size: 0.95rem !important;
}
.font-mono-md {
  font-size: 1.19rem !important;
}
.font-mono-lg {
  font-size: 1.31rem !important;
}
.font-mono-xl {
  font-size: 1.91rem !important;
}
.font-mono-2xl {
  font-size: 2.38rem !important;
}
.font-mono-3xl {
  font-size: 2.86rem !important;
}
.font-sans-3xs {
  font-size: 0.81rem !important;
}
.font-sans-2xs {
  font-size: 0.87rem !important;
}
.font-sans-xs {
  font-size: 0.93rem !important;
}
.font-sans-sm {
  font-size: 0.99rem !important;
}
.font-sans-md {
  font-size: 1.24rem !important;
}
.font-sans-lg {
  font-size: 1.37rem !important;
}
.font-sans-xl {
  font-size: 1.99rem !important;
}
.font-sans-2xl {
  font-size: 2.49rem !important;
}
.font-sans-3xl {
  font-size: 2.98rem !important;
}
.font-serif-3xs {
  font-size: 0.81rem !important;
}
.font-serif-2xs {
  font-size: 0.87rem !important;
}
.font-serif-xs {
  font-size: 0.93rem !important;
}
.font-serif-sm {
  font-size: 0.99rem !important;
}
.font-serif-md {
  font-size: 1.24rem !important;
}
.font-serif-lg {
  font-size: 1.37rem !important;
}
.font-serif-xl {
  font-size: 1.99rem !important;
}
.font-serif-2xl {
  font-size: 2.49rem !important;
}
.font-serif-3xl {
  font-size: 2.98rem !important;
}
.font-heading-3xs {
  font-size: 0.81rem !important;
}
.font-heading-2xs {
  font-size: 0.87rem !important;
}
.font-heading-xs {
  font-size: 0.93rem !important;
}
.font-heading-sm {
  font-size: 0.99rem !important;
}
.font-heading-md {
  font-size: 1.24rem !important;
}
.font-heading-lg {
  font-size: 1.37rem !important;
}
.font-heading-xl {
  font-size: 1.99rem !important;
}
.font-heading-2xl {
  font-size: 2.49rem !important;
}
.font-heading-3xl {
  font-size: 2.98rem !important;
}
.font-body-3xs {
  font-size: 0.81rem !important;
}
.font-body-2xs {
  font-size: 0.87rem !important;
}
.font-body-xs {
  font-size: 0.93rem !important;
}
.font-body-sm {
  font-size: 0.99rem !important;
}
.font-body-md {
  font-size: 1.24rem !important;
}
.font-body-lg {
  font-size: 1.37rem !important;
}
.font-body-xl {
  font-size: 1.99rem !important;
}
.font-body-2xl {
  font-size: 2.49rem !important;
}
.font-body-3xl {
  font-size: 2.98rem !important;
}
.font-code-3xs {
  font-size: 0.77rem !important;
}
.font-code-2xs {
  font-size: 0.83rem !important;
}
.font-code-xs {
  font-size: 0.89rem !important;
}
.font-code-sm {
  font-size: 0.95rem !important;
}
.font-code-md {
  font-size: 1.19rem !important;
}
.font-code-lg {
  font-size: 1.31rem !important;
}
.font-code-xl {
  font-size: 1.91rem !important;
}
.font-code-2xl {
  font-size: 2.38rem !important;
}
.font-code-3xl {
  font-size: 2.86rem !important;
}
.font-alt-3xs {
  font-size: 0.81rem !important;
}
.font-alt-2xs {
  font-size: 0.87rem !important;
}
.font-alt-xs {
  font-size: 0.93rem !important;
}
.font-alt-sm {
  font-size: 0.99rem !important;
}
.font-alt-md {
  font-size: 1.24rem !important;
}
.font-alt-lg {
  font-size: 1.37rem !important;
}
.font-alt-xl {
  font-size: 1.99rem !important;
}
.font-alt-2xl {
  font-size: 2.49rem !important;
}
.font-alt-3xl {
  font-size: 2.98rem !important;
}
.font-ui-3xs {
  font-size: 0.81rem !important;
}
.font-ui-2xs {
  font-size: 0.87rem !important;
}
.font-ui-xs {
  font-size: 0.93rem !important;
}
.font-ui-sm {
  font-size: 0.99rem !important;
}
.font-ui-md {
  font-size: 1.24rem !important;
}
.font-ui-lg {
  font-size: 1.37rem !important;
}
.font-ui-xl {
  font-size: 1.99rem !important;
}
.font-ui-2xl {
  font-size: 2.49rem !important;
}
.font-ui-3xl {
  font-size: 2.98rem !important;
}
.font-family-mono {
  font-family: Roboto Mono Web, Bitstream Vera Sans Mono, Consolas, Courier, monospace !important;
}
.font-family-sans {
  font-family: var(--base-font-family),  !important;
}
.font-family-serif {
  font-family: var(--base-font-family),  !important;
}
.font-family-ui {
  font-family: var(--base-font-family),  !important;
}
.font-family-heading {
  font-family: var(--base-font-family),  !important;
}
.font-family-body {
  font-family: var(--base-font-family),  !important;
}
.font-family-code {
  font-family: Roboto Mono Web, Bitstream Vera Sans Mono, Consolas, Courier, monospace !important;
}
.font-family-alt {
  font-family: var(--base-font-family),  !important;
}
.text-tabular {
  font-feature-settings: "tnum" 1, "kern" 1 !important;
}
.text-no-tabular {
  font-feature-settings: "kern" 1 !important;
}
.text-italic {
  font-style: italic !important;
}
.text-no-italic {
  font-style: normal !important;
}
.text-light {
  font-weight: 300 !important;
}
.text-normal {
  font-weight: normal !important;
}
.text-semibold {
  font-weight: 600 !important;
}
.text-bold {
  font-weight: 700 !important;
}
.height-1px {
  height: 1px !important;
}
.height-2px {
  height: 2px !important;
}
.height-05 {
  height: 0.25rem !important;
}
.height-1 {
  height: 0.5rem !important;
}
.height-105 {
  height: 0.75rem !important;
}
.height-2 {
  height: 1rem !important;
}
.height-205 {
  height: 1.25rem !important;
}
.height-3 {
  height: 1.5rem !important;
}
.height-4 {
  height: 2rem !important;
}
.height-5 {
  height: 2.5rem !important;
}
.height-6 {
  height: 3rem !important;
}
.height-7 {
  height: 3.5rem !important;
}
.height-8 {
  height: 4rem !important;
}
.height-9 {
  height: 4.5rem !important;
}
.height-10 {
  height: 5rem !important;
}
.height-15 {
  height: 7.5rem !important;
}
.height-card {
  height: 10rem !important;
}
.height-card-lg {
  height: 15rem !important;
}
.height-mobile {
  height: 20rem !important;
}
.height-0 {
  height: 0 !important;
}
.height-auto {
  height: auto !important;
}
.height-full {
  height: 100% !important;
}
.height-viewport {
  height: 100vh !important;
}
.flex-justify-center {
  justify-content: center !important;
}
.flex-justify-start {
  justify-content: flex-start !important;
}
.flex-justify-end {
  justify-content: flex-end !important;
}
.flex-justify {
  justify-content: space-between !important;
}
.left-1px {
  left: 1px !important;
}
.left-2px {
  left: 2px !important;
}
.left-05 {
  left: 0.25rem !important;
}
.left-1 {
  left: 0.5rem !important;
}
.left-105 {
  left: 0.75rem !important;
}
.left-2 {
  left: 1rem !important;
}
.left-205 {
  left: 1.25rem !important;
}
.left-3 {
  left: 1.5rem !important;
}
.left-neg-1px {
  left: -1px !important;
}
.left-neg-2px {
  left: -2px !important;
}
.left-neg-05 {
  left: -0.25rem !important;
}
.left-neg-1 {
  left: -0.5rem !important;
}
.left-neg-105 {
  left: -0.75rem !important;
}
.left-neg-2 {
  left: -1rem !important;
}
.left-neg-205 {
  left: -1.25rem !important;
}
.left-neg-3 {
  left: -1.5rem !important;
}
.left-0 {
  left: 0 !important;
}
.left-auto {
  left: auto !important;
}
.left-full {
  left: 100% !important;
}
.text-ls-auto {
  letter-spacing: initial !important;
}
.text-ls-neg-3 {
  letter-spacing: -0.03em !important;
}
.text-ls-neg-2 {
  letter-spacing: -0.02em !important;
}
.text-ls-neg-1 {
  letter-spacing: -0.01em !important;
}
.text-ls-1 {
  letter-spacing: 0.025em !important;
}
.text-ls-2 {
  letter-spacing: 0.1em !important;
}
.text-ls-3 {
  letter-spacing: 0.15em !important;
}
.line-height-sans-1 {
  line-height: 1 !important;
}
.line-height-sans-2 {
  line-height: 1.2 !important;
}
.line-height-sans-3 {
  line-height: 1.4 !important;
}
.line-height-sans-4 {
  line-height: 1.5 !important;
}
.line-height-sans-5 {
  line-height: 1.6 !important;
}
.line-height-sans-6 {
  line-height: 1.8 !important;
}
.line-height-serif-1 {
  line-height: 1 !important;
}
.line-height-serif-2 {
  line-height: 1.2 !important;
}
.line-height-serif-3 {
  line-height: 1.4 !important;
}
.line-height-serif-4 {
  line-height: 1.5 !important;
}
.line-height-serif-5 {
  line-height: 1.6 !important;
}
.line-height-serif-6 {
  line-height: 1.8 !important;
}
.line-height-mono-1 {
  line-height: 1 !important;
}
.line-height-mono-2 {
  line-height: 1.3 !important;
}
.line-height-mono-3 {
  line-height: 1.4 !important;
}
.line-height-mono-4 {
  line-height: 1.6 !important;
}
.line-height-mono-5 {
  line-height: 1.7 !important;
}
.line-height-mono-6 {
  line-height: 1.8 !important;
}
.line-height-heading-1 {
  line-height: 1 !important;
}
.line-height-heading-2 {
  line-height: 1.2 !important;
}
.line-height-heading-3 {
  line-height: 1.4 !important;
}
.line-height-heading-4 {
  line-height: 1.5 !important;
}
.line-height-heading-5 {
  line-height: 1.6 !important;
}
.line-height-heading-6 {
  line-height: 1.8 !important;
}
.line-height-ui-1 {
  line-height: 1 !important;
}
.line-height-ui-2 {
  line-height: 1.2 !important;
}
.line-height-ui-3 {
  line-height: 1.4 !important;
}
.line-height-ui-4 {
  line-height: 1.5 !important;
}
.line-height-ui-5 {
  line-height: 1.6 !important;
}
.line-height-ui-6 {
  line-height: 1.8 !important;
}
.line-height-body-1 {
  line-height: 1 !important;
}
.line-height-body-2 {
  line-height: 1.2 !important;
}
.line-height-body-3 {
  line-height: 1.4 !important;
}
.line-height-body-4 {
  line-height: 1.5 !important;
}
.line-height-body-5 {
  line-height: 1.6 !important;
}
.line-height-body-6 {
  line-height: 1.8 !important;
}
.line-height-code-1 {
  line-height: 1 !important;
}
.line-height-code-2 {
  line-height: 1.3 !important;
}
.line-height-code-3 {
  line-height: 1.4 !important;
}
.line-height-code-4 {
  line-height: 1.6 !important;
}
.line-height-code-5 {
  line-height: 1.7 !important;
}
.line-height-code-6 {
  line-height: 1.8 !important;
}
.line-height-alt-1 {
  line-height: 1 !important;
}
.line-height-alt-2 {
  line-height: 1.2 !important;
}
.line-height-alt-3 {
  line-height: 1.4 !important;
}
.line-height-alt-4 {
  line-height: 1.5 !important;
}
.line-height-alt-5 {
  line-height: 1.6 !important;
}
.line-height-alt-6 {
  line-height: 1.8 !important;
}
.margin-neg-1px {
  margin: -1px !important;
}
.margin-neg-2px {
  margin: -2px !important;
}
.margin-neg-05 {
  margin: -0.25rem !important;
}
.margin-neg-1 {
  margin: -0.5rem !important;
}
.margin-neg-105 {
  margin: -0.75rem !important;
}
.margin-neg-2 {
  margin: -1rem !important;
}
.margin-neg-205 {
  margin: -1.25rem !important;
}
.margin-neg-3 {
  margin: -1.5rem !important;
}
.margin-1px {
  margin: 1px !important;
}
.margin-2px {
  margin: 2px !important;
}
.margin-05 {
  margin: 0.25rem !important;
}
.margin-1 {
  margin: 0.5rem !important;
}
.margin-105 {
  margin: 0.75rem !important;
}
.margin-2 {
  margin: 1rem !important;
}
.margin-205 {
  margin: 1.25rem !important;
}
.margin-3 {
  margin: 1.5rem !important;
}
.margin-neg-4 {
  margin: -2rem !important;
}
.margin-neg-5 {
  margin: -2.5rem !important;
}
.margin-neg-6 {
  margin: -3rem !important;
}
.margin-neg-7 {
  margin: -3.5rem !important;
}
.margin-neg-8 {
  margin: -4rem !important;
}
.margin-neg-9 {
  margin: -4.5rem !important;
}
.margin-neg-10 {
  margin: -5rem !important;
}
.margin-neg-15 {
  margin: -7.5rem !important;
}
.margin-4 {
  margin: 2rem !important;
}
.margin-5 {
  margin: 2.5rem !important;
}
.margin-6 {
  margin: 3rem !important;
}
.margin-7 {
  margin: 3.5rem !important;
}
.margin-8 {
  margin: 4rem !important;
}
.margin-9 {
  margin: 4.5rem !important;
}
.margin-10 {
  margin: 5rem !important;
}
.margin-15 {
  margin: 7.5rem !important;
}
.margin-05em {
  margin: 0.5em !important;
}
.margin-1em {
  margin: 1em !important;
}
.margin-105em {
  margin: 1.5em !important;
}
.margin-2em {
  margin: 2em !important;
}
.margin-0 {
  margin: 0 !important;
}
.margin-y-1px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}
.margin-top-1px {
  margin-top: 1px !important;
}
.margin-bottom-1px {
  margin-bottom: 1px !important;
}
.margin-y-2px {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.margin-top-2px {
  margin-top: 2px !important;
}
.margin-bottom-2px {
  margin-bottom: 2px !important;
}
.margin-y-05 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.margin-top-05 {
  margin-top: 0.25rem !important;
}
.margin-bottom-05 {
  margin-bottom: 0.25rem !important;
}
.margin-y-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.margin-top-1 {
  margin-top: 0.5rem !important;
}
.margin-bottom-1 {
  margin-bottom: 0.5rem !important;
}
.margin-y-105 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.margin-top-105 {
  margin-top: 0.75rem !important;
}
.margin-bottom-105 {
  margin-bottom: 0.75rem !important;
}
.margin-y-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.margin-top-2 {
  margin-top: 1rem !important;
}
.margin-bottom-2 {
  margin-bottom: 1rem !important;
}
.margin-y-205 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}
.margin-top-205 {
  margin-top: 1.25rem !important;
}
.margin-bottom-205 {
  margin-bottom: 1.25rem !important;
}
.margin-y-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.margin-top-3 {
  margin-top: 1.5rem !important;
}
.margin-bottom-3 {
  margin-bottom: 1.5rem !important;
}
.margin-y-neg-1px {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}
.margin-top-neg-1px {
  margin-top: -1px !important;
}
.margin-bottom-neg-1px {
  margin-bottom: -1px !important;
}
.margin-y-neg-2px {
  margin-top: -2px !important;
  margin-bottom: -2px !important;
}
.margin-top-neg-2px {
  margin-top: -2px !important;
}
.margin-bottom-neg-2px {
  margin-bottom: -2px !important;
}
.margin-y-neg-05 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}
.margin-top-neg-05 {
  margin-top: -0.25rem !important;
}
.margin-bottom-neg-05 {
  margin-bottom: -0.25rem !important;
}
.margin-y-neg-1 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}
.margin-top-neg-1 {
  margin-top: -0.5rem !important;
}
.margin-bottom-neg-1 {
  margin-bottom: -0.5rem !important;
}
.margin-y-neg-105 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}
.margin-top-neg-105 {
  margin-top: -0.75rem !important;
}
.margin-bottom-neg-105 {
  margin-bottom: -0.75rem !important;
}
.margin-y-neg-2 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}
.margin-top-neg-2 {
  margin-top: -1rem !important;
}
.margin-bottom-neg-2 {
  margin-bottom: -1rem !important;
}
.margin-y-neg-205 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}
.margin-top-neg-205 {
  margin-top: -1.25rem !important;
}
.margin-bottom-neg-205 {
  margin-bottom: -1.25rem !important;
}
.margin-y-neg-3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}
.margin-top-neg-3 {
  margin-top: -1.5rem !important;
}
.margin-bottom-neg-3 {
  margin-bottom: -1.5rem !important;
}
.margin-y-neg-4 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}
.margin-top-neg-4 {
  margin-top: -2rem !important;
}
.margin-bottom-neg-4 {
  margin-bottom: -2rem !important;
}
.margin-y-neg-5 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}
.margin-top-neg-5 {
  margin-top: -2.5rem !important;
}
.margin-bottom-neg-5 {
  margin-bottom: -2.5rem !important;
}
.margin-y-neg-6 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}
.margin-top-neg-6 {
  margin-top: -3rem !important;
}
.margin-bottom-neg-6 {
  margin-bottom: -3rem !important;
}
.margin-y-neg-7 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}
.margin-top-neg-7 {
  margin-top: -3.5rem !important;
}
.margin-bottom-neg-7 {
  margin-bottom: -3.5rem !important;
}
.margin-y-neg-8 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}
.margin-top-neg-8 {
  margin-top: -4rem !important;
}
.margin-bottom-neg-8 {
  margin-bottom: -4rem !important;
}
.margin-y-neg-9 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}
.margin-top-neg-9 {
  margin-top: -4.5rem !important;
}
.margin-bottom-neg-9 {
  margin-bottom: -4.5rem !important;
}
.margin-y-neg-10 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}
.margin-top-neg-10 {
  margin-top: -5rem !important;
}
.margin-bottom-neg-10 {
  margin-bottom: -5rem !important;
}
.margin-y-neg-15 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important;
}
.margin-top-neg-15 {
  margin-top: -7.5rem !important;
}
.margin-bottom-neg-15 {
  margin-bottom: -7.5rem !important;
}
.margin-y-4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.margin-top-4 {
  margin-top: 2rem !important;
}
.margin-bottom-4 {
  margin-bottom: 2rem !important;
}
.margin-y-5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}
.margin-top-5 {
  margin-top: 2.5rem !important;
}
.margin-bottom-5 {
  margin-bottom: 2.5rem !important;
}
.margin-y-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.margin-top-6 {
  margin-top: 3rem !important;
}
.margin-bottom-6 {
  margin-bottom: 3rem !important;
}
.margin-y-7 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}
.margin-top-7 {
  margin-top: 3.5rem !important;
}
.margin-bottom-7 {
  margin-bottom: 3.5rem !important;
}
.margin-y-8 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
.margin-top-8 {
  margin-top: 4rem !important;
}
.margin-bottom-8 {
  margin-bottom: 4rem !important;
}
.margin-y-9 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}
.margin-top-9 {
  margin-top: 4.5rem !important;
}
.margin-bottom-9 {
  margin-bottom: 4.5rem !important;
}
.margin-y-10 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}
.margin-top-10 {
  margin-top: 5rem !important;
}
.margin-bottom-10 {
  margin-bottom: 5rem !important;
}
.margin-y-15 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}
.margin-top-15 {
  margin-top: 7.5rem !important;
}
.margin-bottom-15 {
  margin-bottom: 7.5rem !important;
}
.margin-y-05em {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.margin-top-05em {
  margin-top: 0.5em !important;
}
.margin-bottom-05em {
  margin-bottom: 0.5em !important;
}
.margin-y-1em {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}
.margin-top-1em {
  margin-top: 1em !important;
}
.margin-bottom-1em {
  margin-bottom: 1em !important;
}
.margin-y-105em {
  margin-top: 1.5em !important;
  margin-bottom: 1.5em !important;
}
.margin-top-105em {
  margin-top: 1.5em !important;
}
.margin-bottom-105em {
  margin-bottom: 1.5em !important;
}
.margin-y-2em {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}
.margin-top-2em {
  margin-top: 2em !important;
}
.margin-bottom-2em {
  margin-bottom: 2em !important;
}
.margin-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.margin-top-0 {
  margin-top: 0 !important;
}
.margin-bottom-0 {
  margin-bottom: 0 !important;
}
.margin-y-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.margin-top-auto {
  margin-top: auto !important;
}
.margin-bottom-auto {
  margin-bottom: auto !important;
}
.margin-x-1px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}
.margin-right-1px {
  margin-right: 1px !important;
}
.margin-left-1px {
  margin-left: 1px !important;
}
.margin-x-2px {
  margin-left: 2px !important;
  margin-right: 2px !important;
}
.margin-right-2px {
  margin-right: 2px !important;
}
.margin-left-2px {
  margin-left: 2px !important;
}
.margin-x-05 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.margin-right-05 {
  margin-right: 0.25rem !important;
}
.margin-left-05 {
  margin-left: 0.25rem !important;
}
.margin-x-1 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.margin-right-1 {
  margin-right: 0.5rem !important;
}
.margin-left-1 {
  margin-left: 0.5rem !important;
}
.margin-x-105 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.margin-right-105 {
  margin-right: 0.75rem !important;
}
.margin-left-105 {
  margin-left: 0.75rem !important;
}
.margin-x-2 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.margin-right-2 {
  margin-right: 1rem !important;
}
.margin-left-2 {
  margin-left: 1rem !important;
}
.margin-x-205 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}
.margin-right-205 {
  margin-right: 1.25rem !important;
}
.margin-left-205 {
  margin-left: 1.25rem !important;
}
.margin-x-3 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.margin-right-3 {
  margin-right: 1.5rem !important;
}
.margin-left-3 {
  margin-left: 1.5rem !important;
}
.margin-x-neg-1px {
  margin-left: -1px !important;
  margin-right: -1px !important;
}
.margin-right-neg-1px {
  margin-right: -1px !important;
}
.margin-left-neg-1px {
  margin-left: -1px !important;
}
.margin-x-neg-2px {
  margin-left: -2px !important;
  margin-right: -2px !important;
}
.margin-right-neg-2px {
  margin-right: -2px !important;
}
.margin-left-neg-2px {
  margin-left: -2px !important;
}
.margin-x-neg-05 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}
.margin-right-neg-05 {
  margin-right: -0.25rem !important;
}
.margin-left-neg-05 {
  margin-left: -0.25rem !important;
}
.margin-x-neg-1 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.margin-right-neg-1 {
  margin-right: -0.5rem !important;
}
.margin-left-neg-1 {
  margin-left: -0.5rem !important;
}
.margin-x-neg-105 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}
.margin-right-neg-105 {
  margin-right: -0.75rem !important;
}
.margin-left-neg-105 {
  margin-left: -0.75rem !important;
}
.margin-x-neg-2 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}
.margin-right-neg-2 {
  margin-right: -1rem !important;
}
.margin-left-neg-2 {
  margin-left: -1rem !important;
}
.margin-x-neg-205 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}
.margin-right-neg-205 {
  margin-right: -1.25rem !important;
}
.margin-left-neg-205 {
  margin-left: -1.25rem !important;
}
.margin-x-neg-3 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}
.margin-right-neg-3 {
  margin-right: -1.5rem !important;
}
.margin-left-neg-3 {
  margin-left: -1.5rem !important;
}
.margin-x-neg-4 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}
.margin-right-neg-4 {
  margin-right: -2rem !important;
}
.margin-left-neg-4 {
  margin-left: -2rem !important;
}
.margin-x-neg-5 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}
.margin-right-neg-5 {
  margin-right: -2.5rem !important;
}
.margin-left-neg-5 {
  margin-left: -2.5rem !important;
}
.margin-x-neg-6 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}
.margin-right-neg-6 {
  margin-right: -3rem !important;
}
.margin-left-neg-6 {
  margin-left: -3rem !important;
}
.margin-x-neg-7 {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}
.margin-right-neg-7 {
  margin-right: -3.5rem !important;
}
.margin-left-neg-7 {
  margin-left: -3.5rem !important;
}
.margin-x-neg-8 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}
.margin-right-neg-8 {
  margin-right: -4rem !important;
}
.margin-left-neg-8 {
  margin-left: -4rem !important;
}
.margin-x-neg-9 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}
.margin-right-neg-9 {
  margin-right: -4.5rem !important;
}
.margin-left-neg-9 {
  margin-left: -4.5rem !important;
}
.margin-x-neg-10 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}
.margin-right-neg-10 {
  margin-right: -5rem !important;
}
.margin-left-neg-10 {
  margin-left: -5rem !important;
}
.margin-x-neg-15 {
  margin-left: -7.5rem !important;
  margin-right: -7.5rem !important;
}
.margin-right-neg-15 {
  margin-right: -7.5rem !important;
}
.margin-left-neg-15 {
  margin-left: -7.5rem !important;
}
.margin-x-4 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.margin-right-4 {
  margin-right: 2rem !important;
}
.margin-left-4 {
  margin-left: 2rem !important;
}
.margin-x-5 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}
.margin-right-5 {
  margin-right: 2.5rem !important;
}
.margin-left-5 {
  margin-left: 2.5rem !important;
}
.margin-x-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.margin-right-6 {
  margin-right: 3rem !important;
}
.margin-left-6 {
  margin-left: 3rem !important;
}
.margin-x-7 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}
.margin-right-7 {
  margin-right: 3.5rem !important;
}
.margin-left-7 {
  margin-left: 3.5rem !important;
}
.margin-x-8 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}
.margin-right-8 {
  margin-right: 4rem !important;
}
.margin-left-8 {
  margin-left: 4rem !important;
}
.margin-x-9 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}
.margin-right-9 {
  margin-right: 4.5rem !important;
}
.margin-left-9 {
  margin-left: 4.5rem !important;
}
.margin-x-10 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}
.margin-right-10 {
  margin-right: 5rem !important;
}
.margin-left-10 {
  margin-left: 5rem !important;
}
.margin-x-15 {
  margin-left: 7.5rem !important;
  margin-right: 7.5rem !important;
}
.margin-right-15 {
  margin-right: 7.5rem !important;
}
.margin-left-15 {
  margin-left: 7.5rem !important;
}
.margin-x-card {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}
.margin-right-card {
  margin-right: 10rem !important;
}
.margin-left-card {
  margin-left: 10rem !important;
}
.margin-x-card-lg {
  margin-left: 15rem !important;
  margin-right: 15rem !important;
}
.margin-right-card-lg {
  margin-right: 15rem !important;
}
.margin-left-card-lg {
  margin-left: 15rem !important;
}
.margin-x-mobile {
  margin-left: 20rem !important;
  margin-right: 20rem !important;
}
.margin-right-mobile {
  margin-right: 20rem !important;
}
.margin-left-mobile {
  margin-left: 20rem !important;
}
.margin-x-05em {
  margin-left: 0.5em !important;
  margin-right: 0.5em !important;
}
.margin-right-05em {
  margin-right: 0.5em !important;
}
.margin-left-05em {
  margin-left: 0.5em !important;
}
.margin-x-1em {
  margin-left: 1em !important;
  margin-right: 1em !important;
}
.margin-right-1em {
  margin-right: 1em !important;
}
.margin-left-1em {
  margin-left: 1em !important;
}
.margin-x-105em {
  margin-left: 1.5em !important;
  margin-right: 1.5em !important;
}
.margin-right-105em {
  margin-right: 1.5em !important;
}
.margin-left-105em {
  margin-left: 1.5em !important;
}
.margin-x-2em {
  margin-left: 2em !important;
  margin-right: 2em !important;
}
.margin-right-2em {
  margin-right: 2em !important;
}
.margin-left-2em {
  margin-left: 2em !important;
}
.margin-x-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.margin-right-0 {
  margin-right: 0 !important;
}
.margin-left-0 {
  margin-left: 0 !important;
}
.margin-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.margin-right-auto {
  margin-right: auto !important;
}
.margin-left-auto {
  margin-left: auto !important;
}
.maxh-05 {
  max-height: 0.25rem !important;
}
.maxh-1 {
  max-height: 0.5rem !important;
}
.maxh-105 {
  max-height: 0.75rem !important;
}
.maxh-2 {
  max-height: 1rem !important;
}
.maxh-205 {
  max-height: 1.25rem !important;
}
.maxh-3 {
  max-height: 1.5rem !important;
}
.maxh-4 {
  max-height: 2rem !important;
}
.maxh-5 {
  max-height: 2.5rem !important;
}
.maxh-6 {
  max-height: 3rem !important;
}
.maxh-7 {
  max-height: 3.5rem !important;
}
.maxh-8 {
  max-height: 4rem !important;
}
.maxh-9 {
  max-height: 4.5rem !important;
}
.maxh-10 {
  max-height: 5rem !important;
}
.maxh-15 {
  max-height: 7.5rem !important;
}
.maxh-card {
  max-height: 10rem !important;
}
.maxh-card-lg {
  max-height: 15rem !important;
}
.maxh-mobile {
  max-height: 20rem !important;
}
.maxh-mobile-lg {
  max-height: 30rem !important;
}
.maxh-tablet {
  max-height: 40rem !important;
}
.maxh-tablet-lg {
  max-height: 55rem !important;
}
.maxh-none {
  max-height: none !important;
}
.maxh-viewport {
  max-height: 100vh !important;
}
.maxw-05 {
  max-width: 0.25rem !important;
}
.maxw-1 {
  max-width: 0.5rem !important;
}
.maxw-105 {
  max-width: 0.75rem !important;
}
.maxw-2 {
  max-width: 1rem !important;
}
.maxw-205 {
  max-width: 1.25rem !important;
}
.maxw-3 {
  max-width: 1.5rem !important;
}
.maxw-4 {
  max-width: 2rem !important;
}
.maxw-5 {
  max-width: 2.5rem !important;
}
.maxw-6 {
  max-width: 3rem !important;
}
.maxw-7 {
  max-width: 3.5rem !important;
}
.maxw-8 {
  max-width: 4rem !important;
}
.maxw-9 {
  max-width: 4.5rem !important;
}
.maxw-10 {
  max-width: 5rem !important;
}
.maxw-15 {
  max-width: 7.5rem !important;
}
.maxw-card {
  max-width: 10rem !important;
}
.maxw-card-lg {
  max-width: 15rem !important;
}
.maxw-mobile {
  max-width: 20rem !important;
}
.maxw-mobile-lg {
  max-width: 30rem !important;
}
.maxw-tablet {
  max-width: 40rem !important;
}
.maxw-tablet-lg {
  max-width: 55rem !important;
}
.maxw-desktop {
  max-width: 64rem !important;
}
.maxw-desktop-lg {
  max-width: 75rem !important;
}
.maxw-widescreen {
  max-width: 87.5rem !important;
}
.maxw-none {
  max-width: none !important;
}
.maxw-full {
  max-width: 100% !important;
}
.measure-1 {
  max-width: 44ex !important;
}
.measure-2 {
  max-width: 60ex !important;
}
.measure-3 {
  max-width: 64ex !important;
}
.measure-4 {
  max-width: 68ex !important;
}
.measure-5 {
  max-width: 72ex !important;
}
.measure-6 {
  max-width: 88ex !important;
}
.measure-none {
  max-width: none !important;
}
.minh-1px {
  min-height: 1px !important;
}
.minh-2px {
  min-height: 2px !important;
}
.minh-05 {
  min-height: 0.25rem !important;
}
.minh-1 {
  min-height: 0.5rem !important;
}
.minh-105 {
  min-height: 0.75rem !important;
}
.minh-2 {
  min-height: 1rem !important;
}
.minh-205 {
  min-height: 1.25rem !important;
}
.minh-3 {
  min-height: 1.5rem !important;
}
.minh-4 {
  min-height: 2rem !important;
}
.minh-5 {
  min-height: 2.5rem !important;
}
.minh-6 {
  min-height: 3rem !important;
}
.minh-7 {
  min-height: 3.5rem !important;
}
.minh-8 {
  min-height: 4rem !important;
}
.minh-9 {
  min-height: 4.5rem !important;
}
.minh-10 {
  min-height: 5rem !important;
}
.minh-15 {
  min-height: 7.5rem !important;
}
.minh-card {
  min-height: 10rem !important;
}
.minh-card-lg {
  min-height: 15rem !important;
}
.minh-mobile {
  min-height: 20rem !important;
}
.minh-mobile-lg {
  min-height: 30rem !important;
}
.minh-tablet {
  min-height: 40rem !important;
}
.minh-tablet-lg {
  min-height: 55rem !important;
}
.minh-0 {
  min-height: 0 !important;
}
.minh-full {
  min-height: 100% !important;
}
.minh-viewport {
  min-height: 100vh !important;
}
.minw-05 {
  min-width: 0.25rem !important;
}
.minw-1 {
  min-width: 0.5rem !important;
}
.minw-105 {
  min-width: 0.75rem !important;
}
.minw-2 {
  min-width: 1rem !important;
}
.minw-205 {
  min-width: 1.25rem !important;
}
.minw-3 {
  min-width: 1.5rem !important;
}
.minw-4 {
  min-width: 2rem !important;
}
.minw-5 {
  min-width: 2.5rem !important;
}
.minw-6 {
  min-width: 3rem !important;
}
.minw-7 {
  min-width: 3.5rem !important;
}
.minw-8 {
  min-width: 4rem !important;
}
.minw-9 {
  min-width: 4.5rem !important;
}
.minw-10 {
  min-width: 5rem !important;
}
.minw-15 {
  min-width: 7.5rem !important;
}
.minw-0 {
  min-width: 0 !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-10 {
  opacity: 0.1 !important;
}
.opacity-20 {
  opacity: 0.2 !important;
}
.opacity-30 {
  opacity: 0.3 !important;
}
.opacity-40 {
  opacity: 0.4 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-60 {
  opacity: 0.6 !important;
}
.opacity-70 {
  opacity: 0.7 !important;
}
.opacity-80 {
  opacity: 0.8 !important;
}
.opacity-90 {
  opacity: 0.9 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.order-first {
  order: -1 !important;
}
.order-last {
  order: 999 !important;
}
.order-initial {
  order: initial !important;
}
.order-0 {
  order: 0 !important;
}
.order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-4 {
  order: 4 !important;
}
.order-5 {
  order: 5 !important;
}
.order-6 {
  order: 6 !important;
}
.order-7 {
  order: 7 !important;
}
.order-8 {
  order: 8 !important;
}
.order-9 {
  order: 9 !important;
}
.order-10 {
  order: 10 !important;
}
.order-11 {
  order: 11 !important;
}
.outline-1px {
  outline: 1px solid !important;
}
.outline-2px {
  outline: 2px solid !important;
}
.outline-0 {
  outline: 0 solid !important;
}
.outline-05 {
  outline: 0.25rem solid !important;
}
.outline-transparent {
  outline-color: transparent !important;
}
.outline-black {
  outline-color: black !important;
}
.outline-white {
  outline-color: white !important;
}
.outline-red {
  outline-color: #e52207 !important;
}
.outline-orange {
  outline-color: #e66f0e !important;
}
.outline-gold {
  outline-color: #ffbe2e !important;
}
.outline-yellow {
  outline-color: #fee685 !important;
}
.outline-green {
  outline-color: #538200 !important;
}
.outline-mint {
  outline-color: #04c585 !important;
}
.outline-cyan {
  outline-color: #009ec1 !important;
}
.outline-blue {
  outline-color: #0076d6 !important;
}
.outline-indigo {
  outline-color: #676cc8 !important;
}
.outline-violet {
  outline-color: #8168b3 !important;
}
.outline-magenta {
  outline-color: #d72d79 !important;
}
.outline-gray-5 {
  outline-color: #f0f0f0 !important;
}
.outline-gray-10 {
  outline-color: #e6e6e6 !important;
}
.outline-gray-30 {
  outline-color: #adadad !important;
}
.outline-gray-50 {
  outline-color: #757575 !important;
}
.outline-gray-70 {
  outline-color: #454545 !important;
}
.outline-gray-90 {
  outline-color: #1b1b1b !important;
}
.outline-base-lightest {
  outline-color: #f0f0f0 !important;
}
.outline-base-lighter {
  outline-color: #dfe1e2 !important;
}
.outline-base-light {
  outline-color: #a9aeb1 !important;
}
.outline-base {
  outline-color: #71767a !important;
}
.outline-base-dark {
  outline-color: #565c65 !important;
}
.outline-base-darker {
  outline-color: #3d4551 !important;
}
.outline-base-darkest {
  outline-color: #2d2e2f !important;
}
.outline-ink {
  outline-color: #1c1d1f !important;
}
.outline-primary-lighter {
  outline-color: #d9e8f6 !important;
}
.outline-primary-light {
  outline-color: #73b3e7 !important;
}
.outline-primary {
  outline-color: #005ea2 !important;
}
.outline-primary-vivid {
  outline-color: #0050d8 !important;
}
.outline-primary-dark {
  outline-color: #1a4480 !important;
}
.outline-primary-darker {
  outline-color: #162e51 !important;
}
.outline-secondary-lighter {
  outline-color: #f3e1e4 !important;
}
.outline-secondary-light {
  outline-color: #f2938c !important;
}
.outline-secondary {
  outline-color: #d83933 !important;
}
.outline-secondary-vivid {
  outline-color: #e41d3d !important;
}
.outline-secondary-dark {
  outline-color: #b50909 !important;
}
.outline-secondary-darker {
  outline-color: #8b0a03 !important;
}
.outline-accent-warm-darker {
  outline-color: #775540 !important;
}
.outline-accent-warm-dark {
  outline-color: #c05600 !important;
}
.outline-accent-warm {
  outline-color: #fa9441 !important;
}
.outline-accent-warm-light {
  outline-color: #ffbc78 !important;
}
.outline-accent-warm-lighter {
  outline-color: #f2e4d4 !important;
}
.outline-accent-cool-darker {
  outline-color: #07648d !important;
}
.outline-accent-cool-dark {
  outline-color: #28a0cb !important;
}
.outline-accent-cool {
  outline-color: #00bde3 !important;
}
.outline-accent-cool-light {
  outline-color: #97d4ea !important;
}
.outline-accent-cool-lighter {
  outline-color: #e1f3f8 !important;
}
.outline-error-lighter {
  outline-color: #f4e3db !important;
}
.outline-error-light {
  outline-color: #f39268 !important;
}
.outline-error {
  outline-color: #d54309 !important;
}
.outline-error-dark {
  outline-color: #b50909 !important;
}
.outline-error-darker {
  outline-color: #6f3331 !important;
}
.outline-warning-lighter {
  outline-color: #faf3d1 !important;
}
.outline-warning-light {
  outline-color: #fee685 !important;
}
.outline-warning {
  outline-color: #ffbe2e !important;
}
.outline-warning-dark {
  outline-color: #e5a000 !important;
}
.outline-warning-darker {
  outline-color: #936f38 !important;
}
.outline-success-lighter {
  outline-color: #ecf3ec !important;
}
.outline-success-light {
  outline-color: #70e17b !important;
}
.outline-success {
  outline-color: #00a91c !important;
}
.outline-success-dark {
  outline-color: #008817 !important;
}
.outline-success-darker {
  outline-color: #216e1f !important;
}
.outline-info-lighter {
  outline-color: #e7f6f8 !important;
}
.outline-info-light {
  outline-color: #99deea !important;
}
.outline-info {
  outline-color: #00bde3 !important;
}
.outline-info-dark {
  outline-color: #009ec1 !important;
}
.outline-info-darker {
  outline-color: #2e6276 !important;
}
.outline-disabled-lighter {
  outline-color: #c9c9c9 !important;
}
.outline-disabled-light {
  outline-color: #919191 !important;
}
.outline-disabled {
  outline-color: #757575 !important;
}
.outline-disabled-dark {
  outline-color: #454545 !important;
}
.outline-disabled-darker {
  outline-color: #1b1b1b !important;
}
.outline-emergency {
  outline-color: #9c3d10 !important;
}
.outline-emergency-dark {
  outline-color: #332d29 !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-y-hidden {
  overflow-y: hidden !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.overflow-y-scroll {
  overflow-y: scroll !important;
}
.overflow-x-scroll {
  overflow-x: scroll !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-y-visible {
  overflow-y: visible !important;
}
.overflow-x-visible {
  overflow-x: visible !important;
}
.padding-1px {
  padding: 1px !important;
}
.padding-y-1px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.padding-x-1px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}
.padding-top-1px {
  padding-top: 1px !important;
}
.padding-right-1px {
  padding-right: 1px !important;
}
.padding-bottom-1px {
  padding-bottom: 1px !important;
}
.padding-left-1px {
  padding-left: 1px !important;
}
.padding-2px {
  padding: 2px !important;
}
.padding-y-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.padding-x-2px {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.padding-top-2px {
  padding-top: 2px !important;
}
.padding-right-2px {
  padding-right: 2px !important;
}
.padding-bottom-2px {
  padding-bottom: 2px !important;
}
.padding-left-2px {
  padding-left: 2px !important;
}
.padding-05 {
  padding: 0.25rem !important;
}
.padding-y-05 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.padding-x-05 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.padding-top-05 {
  padding-top: 0.25rem !important;
}
.padding-right-05 {
  padding-right: 0.25rem !important;
}
.padding-bottom-05 {
  padding-bottom: 0.25rem !important;
}
.padding-left-05 {
  padding-left: 0.25rem !important;
}
.padding-1 {
  padding: 0.5rem !important;
}
.padding-y-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.padding-x-1 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.padding-top-1 {
  padding-top: 0.5rem !important;
}
.padding-right-1 {
  padding-right: 0.5rem !important;
}
.padding-bottom-1 {
  padding-bottom: 0.5rem !important;
}
.padding-left-1 {
  padding-left: 0.5rem !important;
}
.padding-105 {
  padding: 0.75rem !important;
}
.padding-y-105 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.padding-x-105 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.padding-top-105 {
  padding-top: 0.75rem !important;
}
.padding-right-105 {
  padding-right: 0.75rem !important;
}
.padding-bottom-105 {
  padding-bottom: 0.75rem !important;
}
.padding-left-105 {
  padding-left: 0.75rem !important;
}
.padding-2 {
  padding: 1rem !important;
}
.padding-y-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.padding-x-2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.padding-top-2 {
  padding-top: 1rem !important;
}
.padding-right-2 {
  padding-right: 1rem !important;
}
.padding-bottom-2 {
  padding-bottom: 1rem !important;
}
.padding-left-2 {
  padding-left: 1rem !important;
}
.padding-205 {
  padding: 1.25rem !important;
}
.padding-y-205 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}
.padding-x-205 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.padding-top-205 {
  padding-top: 1.25rem !important;
}
.padding-right-205 {
  padding-right: 1.25rem !important;
}
.padding-bottom-205 {
  padding-bottom: 1.25rem !important;
}
.padding-left-205 {
  padding-left: 1.25rem !important;
}
.padding-3 {
  padding: 1.5rem !important;
}
.padding-y-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.padding-x-3 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.padding-top-3 {
  padding-top: 1.5rem !important;
}
.padding-right-3 {
  padding-right: 1.5rem !important;
}
.padding-bottom-3 {
  padding-bottom: 1.5rem !important;
}
.padding-left-3 {
  padding-left: 1.5rem !important;
}
.padding-4 {
  padding: 2rem !important;
}
.padding-y-4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.padding-x-4 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.padding-top-4 {
  padding-top: 2rem !important;
}
.padding-right-4 {
  padding-right: 2rem !important;
}
.padding-bottom-4 {
  padding-bottom: 2rem !important;
}
.padding-left-4 {
  padding-left: 2rem !important;
}
.padding-5 {
  padding: 2.5rem !important;
}
.padding-y-5 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}
.padding-x-5 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
.padding-top-5 {
  padding-top: 2.5rem !important;
}
.padding-right-5 {
  padding-right: 2.5rem !important;
}
.padding-bottom-5 {
  padding-bottom: 2.5rem !important;
}
.padding-left-5 {
  padding-left: 2.5rem !important;
}
.padding-6 {
  padding: 3rem !important;
}
.padding-y-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.padding-x-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.padding-top-6 {
  padding-top: 3rem !important;
}
.padding-right-6 {
  padding-right: 3rem !important;
}
.padding-bottom-6 {
  padding-bottom: 3rem !important;
}
.padding-left-6 {
  padding-left: 3rem !important;
}
.padding-7 {
  padding: 3.5rem !important;
}
.padding-y-7 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}
.padding-x-7 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}
.padding-top-7 {
  padding-top: 3.5rem !important;
}
.padding-right-7 {
  padding-right: 3.5rem !important;
}
.padding-bottom-7 {
  padding-bottom: 3.5rem !important;
}
.padding-left-7 {
  padding-left: 3.5rem !important;
}
.padding-8 {
  padding: 4rem !important;
}
.padding-y-8 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
.padding-x-8 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}
.padding-top-8 {
  padding-top: 4rem !important;
}
.padding-right-8 {
  padding-right: 4rem !important;
}
.padding-bottom-8 {
  padding-bottom: 4rem !important;
}
.padding-left-8 {
  padding-left: 4rem !important;
}
.padding-9 {
  padding: 4.5rem !important;
}
.padding-y-9 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}
.padding-x-9 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}
.padding-top-9 {
  padding-top: 4.5rem !important;
}
.padding-right-9 {
  padding-right: 4.5rem !important;
}
.padding-bottom-9 {
  padding-bottom: 4.5rem !important;
}
.padding-left-9 {
  padding-left: 4.5rem !important;
}
.padding-10 {
  padding: 5rem !important;
}
.padding-y-10 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}
.padding-x-10 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}
.padding-top-10 {
  padding-top: 5rem !important;
}
.padding-right-10 {
  padding-right: 5rem !important;
}
.padding-bottom-10 {
  padding-bottom: 5rem !important;
}
.padding-left-10 {
  padding-left: 5rem !important;
}
.padding-15 {
  padding: 7.5rem !important;
}
.padding-y-15 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}
.padding-x-15 {
  padding-left: 7.5rem !important;
  padding-right: 7.5rem !important;
}
.padding-top-15 {
  padding-top: 7.5rem !important;
}
.padding-right-15 {
  padding-right: 7.5rem !important;
}
.padding-bottom-15 {
  padding-bottom: 7.5rem !important;
}
.padding-left-15 {
  padding-left: 7.5rem !important;
}
.padding-0 {
  padding: 0 !important;
}
.padding-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.padding-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.padding-top-0 {
  padding-top: 0 !important;
}
.padding-right-0 {
  padding-right: 0 !important;
}
.padding-bottom-0 {
  padding-bottom: 0 !important;
}
.padding-left-0 {
  padding-left: 0 !important;
}
.pin-all {
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  position: absolute !important;
}
.pin-x {
  left: 0 !important;
  right: 0 !important;
  position: absolute !important;
}
.pin-y {
  bottom: 0 !important;
  top: 0 !important;
  position: absolute !important;
}
.pin-bottom {
  bottom: 0 !important;
  position: absolute !important;
}
.pin-left {
  left: 0 !important;
  position: absolute !important;
}
.pin-right {
  right: 0 !important;
  position: absolute !important;
}
.pin-top {
  top: 0 !important;
  position: absolute !important;
}
.pin-none {
  bottom: auto !important;
  left: auto !important;
  right: auto !important;
  top: auto !important;
  position: static !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-relative {
  position: relative !important;
}
.position-static {
  position: static !important;
}
.position-sticky {
  position: sticky !important;
}
.right-1px {
  right: 1px !important;
}
.right-2px {
  right: 2px !important;
}
.right-05 {
  right: 0.25rem !important;
}
.right-1 {
  right: 0.5rem !important;
}
.right-105 {
  right: 0.75rem !important;
}
.right-2 {
  right: 1rem !important;
}
.right-205 {
  right: 1.25rem !important;
}
.right-3 {
  right: 1.5rem !important;
}
.right-neg-1px {
  right: -1px !important;
}
.right-neg-2px {
  right: -2px !important;
}
.right-neg-05 {
  right: -0.25rem !important;
}
.right-neg-1 {
  right: -0.5rem !important;
}
.right-neg-105 {
  right: -0.75rem !important;
}
.right-neg-2 {
  right: -1rem !important;
}
.right-neg-205 {
  right: -1.25rem !important;
}
.right-neg-3 {
  right: -1.5rem !important;
}
.right-0 {
  right: 0 !important;
}
.right-auto {
  right: auto !important;
}
.right-full {
  right: 100% !important;
}
.square-1px {
  height: 1px !important;
  width: 1px !important;
}
.square-2px {
  height: 2px !important;
  width: 2px !important;
}
.square-05 {
  height: 0.25rem !important;
  width: 0.25rem !important;
}
.square-1 {
  height: 0.5rem !important;
  width: 0.5rem !important;
}
.square-105 {
  height: 0.75rem !important;
  width: 0.75rem !important;
}
.square-2 {
  height: 1rem !important;
  width: 1rem !important;
}
.square-205 {
  height: 1.25rem !important;
  width: 1.25rem !important;
}
.square-3 {
  height: 1.5rem !important;
  width: 1.5rem !important;
}
.square-4 {
  height: 2rem !important;
  width: 2rem !important;
}
.square-5 {
  height: 2.5rem !important;
  width: 2.5rem !important;
}
.square-6 {
  height: 3rem !important;
  width: 3rem !important;
}
.square-7 {
  height: 3.5rem !important;
  width: 3.5rem !important;
}
.square-8 {
  height: 4rem !important;
  width: 4rem !important;
}
.square-9 {
  height: 4.5rem !important;
  width: 4.5rem !important;
}
.square-10 {
  height: 5rem !important;
  width: 5rem !important;
}
.square-15 {
  height: 7.5rem !important;
  width: 7.5rem !important;
}
.square-card {
  height: 10rem !important;
  width: 10rem !important;
}
.square-card-lg {
  height: 15rem !important;
  width: 15rem !important;
}
.square-mobile {
  height: 20rem !important;
  width: 20rem !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-justify {
  text-align: justify !important;
}
.text-right {
  text-align: right !important;
}
.text-strike {
  text-decoration: line-through !important;
}
.hover\:text-strike:hover {
  text-decoration: line-through !important;
}
.text-underline {
  text-decoration: underline !important;
}
.hover\:text-underline:hover {
  text-decoration: underline !important;
}
.text-no-underline {
  text-decoration: none !important;
}
.hover\:text-no-underline:hover {
  text-decoration: none !important;
}
.text-no-strike {
  text-decoration: none !important;
}
.hover\:text-no-strike:hover {
  text-decoration: none !important;
}
.underline-red {
  text-decoration-color: #e52207 !important;
}
.hover\:underline-red:hover {
  text-decoration-color: #e52207 !important;
}
.underline-orange {
  text-decoration-color: #e66f0e !important;
}
.hover\:underline-orange:hover {
  text-decoration-color: #e66f0e !important;
}
.underline-gold {
  text-decoration-color: #ffbe2e !important;
}
.hover\:underline-gold:hover {
  text-decoration-color: #ffbe2e !important;
}
.underline-yellow {
  text-decoration-color: #fee685 !important;
}
.hover\:underline-yellow:hover {
  text-decoration-color: #fee685 !important;
}
.underline-green {
  text-decoration-color: #538200 !important;
}
.hover\:underline-green:hover {
  text-decoration-color: #538200 !important;
}
.underline-mint {
  text-decoration-color: #04c585 !important;
}
.hover\:underline-mint:hover {
  text-decoration-color: #04c585 !important;
}
.underline-cyan {
  text-decoration-color: #009ec1 !important;
}
.hover\:underline-cyan:hover {
  text-decoration-color: #009ec1 !important;
}
.underline-blue {
  text-decoration-color: #0076d6 !important;
}
.hover\:underline-blue:hover {
  text-decoration-color: #0076d6 !important;
}
.underline-indigo {
  text-decoration-color: #676cc8 !important;
}
.hover\:underline-indigo:hover {
  text-decoration-color: #676cc8 !important;
}
.underline-violet {
  text-decoration-color: #8168b3 !important;
}
.hover\:underline-violet:hover {
  text-decoration-color: #8168b3 !important;
}
.underline-magenta {
  text-decoration-color: #d72d79 !important;
}
.hover\:underline-magenta:hover {
  text-decoration-color: #d72d79 !important;
}
.underline-gray-5 {
  text-decoration-color: #f0f0f0 !important;
}
.hover\:underline-gray-5:hover {
  text-decoration-color: #f0f0f0 !important;
}
.underline-gray-10 {
  text-decoration-color: #e6e6e6 !important;
}
.hover\:underline-gray-10:hover {
  text-decoration-color: #e6e6e6 !important;
}
.underline-gray-30 {
  text-decoration-color: #adadad !important;
}
.hover\:underline-gray-30:hover {
  text-decoration-color: #adadad !important;
}
.underline-gray-50 {
  text-decoration-color: #757575 !important;
}
.hover\:underline-gray-50:hover {
  text-decoration-color: #757575 !important;
}
.underline-gray-70 {
  text-decoration-color: #454545 !important;
}
.hover\:underline-gray-70:hover {
  text-decoration-color: #454545 !important;
}
.underline-gray-90 {
  text-decoration-color: #1b1b1b !important;
}
.hover\:underline-gray-90:hover {
  text-decoration-color: #1b1b1b !important;
}
.underline-base-lightest {
  text-decoration-color: #f0f0f0 !important;
}
.hover\:underline-base-lightest:hover {
  text-decoration-color: #f0f0f0 !important;
}
.underline-base-lighter {
  text-decoration-color: #dfe1e2 !important;
}
.hover\:underline-base-lighter:hover {
  text-decoration-color: #dfe1e2 !important;
}
.underline-base-light {
  text-decoration-color: #a9aeb1 !important;
}
.hover\:underline-base-light:hover {
  text-decoration-color: #a9aeb1 !important;
}
.underline-base {
  text-decoration-color: #71767a !important;
}
.hover\:underline-base:hover {
  text-decoration-color: #71767a !important;
}
.underline-base-dark {
  text-decoration-color: #565c65 !important;
}
.hover\:underline-base-dark:hover {
  text-decoration-color: #565c65 !important;
}
.underline-base-darker {
  text-decoration-color: #3d4551 !important;
}
.hover\:underline-base-darker:hover {
  text-decoration-color: #3d4551 !important;
}
.underline-base-darkest {
  text-decoration-color: #2d2e2f !important;
}
.hover\:underline-base-darkest:hover {
  text-decoration-color: #2d2e2f !important;
}
.underline-ink {
  text-decoration-color: #1c1d1f !important;
}
.hover\:underline-ink:hover {
  text-decoration-color: #1c1d1f !important;
}
.underline-primary-lighter {
  text-decoration-color: #d9e8f6 !important;
}
.hover\:underline-primary-lighter:hover {
  text-decoration-color: #d9e8f6 !important;
}
.underline-primary-light {
  text-decoration-color: #73b3e7 !important;
}
.hover\:underline-primary-light:hover {
  text-decoration-color: #73b3e7 !important;
}
.underline-primary {
  text-decoration-color: #005ea2 !important;
}
.hover\:underline-primary:hover {
  text-decoration-color: #005ea2 !important;
}
.underline-primary-vivid {
  text-decoration-color: #0050d8 !important;
}
.hover\:underline-primary-vivid:hover {
  text-decoration-color: #0050d8 !important;
}
.underline-primary-dark {
  text-decoration-color: #1a4480 !important;
}
.hover\:underline-primary-dark:hover {
  text-decoration-color: #1a4480 !important;
}
.underline-primary-darker {
  text-decoration-color: #162e51 !important;
}
.hover\:underline-primary-darker:hover {
  text-decoration-color: #162e51 !important;
}
.underline-secondary-lighter {
  text-decoration-color: #f3e1e4 !important;
}
.hover\:underline-secondary-lighter:hover {
  text-decoration-color: #f3e1e4 !important;
}
.underline-secondary-light {
  text-decoration-color: #f2938c !important;
}
.hover\:underline-secondary-light:hover {
  text-decoration-color: #f2938c !important;
}
.underline-secondary {
  text-decoration-color: #d83933 !important;
}
.hover\:underline-secondary:hover {
  text-decoration-color: #d83933 !important;
}
.underline-secondary-vivid {
  text-decoration-color: #e41d3d !important;
}
.hover\:underline-secondary-vivid:hover {
  text-decoration-color: #e41d3d !important;
}
.underline-secondary-dark {
  text-decoration-color: #b50909 !important;
}
.hover\:underline-secondary-dark:hover {
  text-decoration-color: #b50909 !important;
}
.underline-secondary-darker {
  text-decoration-color: #8b0a03 !important;
}
.hover\:underline-secondary-darker:hover {
  text-decoration-color: #8b0a03 !important;
}
.underline-accent-warm-darker {
  text-decoration-color: #775540 !important;
}
.hover\:underline-accent-warm-darker:hover {
  text-decoration-color: #775540 !important;
}
.underline-accent-warm-dark {
  text-decoration-color: #c05600 !important;
}
.hover\:underline-accent-warm-dark:hover {
  text-decoration-color: #c05600 !important;
}
.underline-accent-warm {
  text-decoration-color: #fa9441 !important;
}
.hover\:underline-accent-warm:hover {
  text-decoration-color: #fa9441 !important;
}
.underline-accent-warm-light {
  text-decoration-color: #ffbc78 !important;
}
.hover\:underline-accent-warm-light:hover {
  text-decoration-color: #ffbc78 !important;
}
.underline-accent-warm-lighter {
  text-decoration-color: #f2e4d4 !important;
}
.hover\:underline-accent-warm-lighter:hover {
  text-decoration-color: #f2e4d4 !important;
}
.underline-accent-cool-darker {
  text-decoration-color: #07648d !important;
}
.hover\:underline-accent-cool-darker:hover {
  text-decoration-color: #07648d !important;
}
.underline-accent-cool-dark {
  text-decoration-color: #28a0cb !important;
}
.hover\:underline-accent-cool-dark:hover {
  text-decoration-color: #28a0cb !important;
}
.underline-accent-cool {
  text-decoration-color: #00bde3 !important;
}
.hover\:underline-accent-cool:hover {
  text-decoration-color: #00bde3 !important;
}
.underline-accent-cool-light {
  text-decoration-color: #97d4ea !important;
}
.hover\:underline-accent-cool-light:hover {
  text-decoration-color: #97d4ea !important;
}
.underline-accent-cool-lighter {
  text-decoration-color: #e1f3f8 !important;
}
.hover\:underline-accent-cool-lighter:hover {
  text-decoration-color: #e1f3f8 !important;
}
.underline-error-lighter {
  text-decoration-color: #f4e3db !important;
}
.hover\:underline-error-lighter:hover {
  text-decoration-color: #f4e3db !important;
}
.underline-error-light {
  text-decoration-color: #f39268 !important;
}
.hover\:underline-error-light:hover {
  text-decoration-color: #f39268 !important;
}
.underline-error {
  text-decoration-color: #d54309 !important;
}
.hover\:underline-error:hover {
  text-decoration-color: #d54309 !important;
}
.underline-error-dark {
  text-decoration-color: #b50909 !important;
}
.hover\:underline-error-dark:hover {
  text-decoration-color: #b50909 !important;
}
.underline-error-darker {
  text-decoration-color: #6f3331 !important;
}
.hover\:underline-error-darker:hover {
  text-decoration-color: #6f3331 !important;
}
.underline-warning-lighter {
  text-decoration-color: #faf3d1 !important;
}
.hover\:underline-warning-lighter:hover {
  text-decoration-color: #faf3d1 !important;
}
.underline-warning-light {
  text-decoration-color: #fee685 !important;
}
.hover\:underline-warning-light:hover {
  text-decoration-color: #fee685 !important;
}
.underline-warning {
  text-decoration-color: #ffbe2e !important;
}
.hover\:underline-warning:hover {
  text-decoration-color: #ffbe2e !important;
}
.underline-warning-dark {
  text-decoration-color: #e5a000 !important;
}
.hover\:underline-warning-dark:hover {
  text-decoration-color: #e5a000 !important;
}
.underline-warning-darker {
  text-decoration-color: #936f38 !important;
}
.hover\:underline-warning-darker:hover {
  text-decoration-color: #936f38 !important;
}
.underline-success-lighter {
  text-decoration-color: #ecf3ec !important;
}
.hover\:underline-success-lighter:hover {
  text-decoration-color: #ecf3ec !important;
}
.underline-success-light {
  text-decoration-color: #70e17b !important;
}
.hover\:underline-success-light:hover {
  text-decoration-color: #70e17b !important;
}
.underline-success {
  text-decoration-color: #00a91c !important;
}
.hover\:underline-success:hover {
  text-decoration-color: #00a91c !important;
}
.underline-success-dark {
  text-decoration-color: #008817 !important;
}
.hover\:underline-success-dark:hover {
  text-decoration-color: #008817 !important;
}
.underline-success-darker {
  text-decoration-color: #216e1f !important;
}
.hover\:underline-success-darker:hover {
  text-decoration-color: #216e1f !important;
}
.underline-info-lighter {
  text-decoration-color: #e7f6f8 !important;
}
.hover\:underline-info-lighter:hover {
  text-decoration-color: #e7f6f8 !important;
}
.underline-info-light {
  text-decoration-color: #99deea !important;
}
.hover\:underline-info-light:hover {
  text-decoration-color: #99deea !important;
}
.underline-info {
  text-decoration-color: #00bde3 !important;
}
.hover\:underline-info:hover {
  text-decoration-color: #00bde3 !important;
}
.underline-info-dark {
  text-decoration-color: #009ec1 !important;
}
.hover\:underline-info-dark:hover {
  text-decoration-color: #009ec1 !important;
}
.underline-info-darker {
  text-decoration-color: #2e6276 !important;
}
.hover\:underline-info-darker:hover {
  text-decoration-color: #2e6276 !important;
}
.underline-disabled-lighter {
  text-decoration-color: #c9c9c9 !important;
}
.hover\:underline-disabled-lighter:hover {
  text-decoration-color: #c9c9c9 !important;
}
.underline-disabled-light {
  text-decoration-color: #919191 !important;
}
.hover\:underline-disabled-light:hover {
  text-decoration-color: #919191 !important;
}
.underline-disabled {
  text-decoration-color: #757575 !important;
}
.hover\:underline-disabled:hover {
  text-decoration-color: #757575 !important;
}
.underline-disabled-dark {
  text-decoration-color: #454545 !important;
}
.hover\:underline-disabled-dark:hover {
  text-decoration-color: #454545 !important;
}
.underline-disabled-darker {
  text-decoration-color: #1b1b1b !important;
}
.hover\:underline-disabled-darker:hover {
  text-decoration-color: #1b1b1b !important;
}
.underline-emergency {
  text-decoration-color: #9c3d10 !important;
}
.hover\:underline-emergency:hover {
  text-decoration-color: #9c3d10 !important;
}
.underline-emergency-dark {
  text-decoration-color: #332d29 !important;
}
.hover\:underline-emergency-dark:hover {
  text-decoration-color: #332d29 !important;
}
.text-indent-0 {
  text-indent: 0 !important;
}
.text-indent-05 {
  text-indent: 0.25rem !important;
}
.text-indent-1 {
  text-indent: 0.5rem !important;
}
.text-indent-105 {
  text-indent: 0.75rem !important;
}
.text-indent-2 {
  text-indent: 1rem !important;
}
.text-indent-205 {
  text-indent: 1.25rem !important;
}
.text-indent-3 {
  text-indent: 1.5rem !important;
}
.text-indent-neg-05 {
  text-indent: -0.25rem !important;
}
.text-indent-neg-1 {
  text-indent: -0.5rem !important;
}
.text-indent-neg-105 {
  text-indent: -0.75rem !important;
}
.text-indent-neg-2 {
  text-indent: -1rem !important;
}
.text-indent-neg-205 {
  text-indent: -1.25rem !important;
}
.text-indent-neg-3 {
  text-indent: -1.5rem !important;
}
.text-indent-4 {
  text-indent: 2rem !important;
}
.text-indent-5 {
  text-indent: 2.5rem !important;
}
.text-indent-6 {
  text-indent: 3rem !important;
}
.text-indent-7 {
  text-indent: 3.5rem !important;
}
.text-indent-8 {
  text-indent: 4rem !important;
}
.text-indent-9 {
  text-indent: 4.5rem !important;
}
.text-indent-10 {
  text-indent: 5rem !important;
}
.text-indent-15 {
  text-indent: 7.5rem !important;
}
.text-indent-neg-4 {
  text-indent: -2rem !important;
}
.text-indent-neg-5 {
  text-indent: -2.5rem !important;
}
.text-indent-neg-6 {
  text-indent: -3rem !important;
}
.text-indent-neg-7 {
  text-indent: -3.5rem !important;
}
.text-indent-neg-8 {
  text-indent: -4rem !important;
}
.text-indent-neg-9 {
  text-indent: -4.5rem !important;
}
.text-indent-neg-10 {
  text-indent: -5rem !important;
}
.text-indent-neg-15 {
  text-indent: -7.5rem !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-no-uppercase {
  text-transform: none !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-no-lowercase {
  text-transform: none !important;
}
.top-1px {
  top: 1px !important;
}
.top-2px {
  top: 2px !important;
}
.top-05 {
  top: 0.25rem !important;
}
.top-1 {
  top: 0.5rem !important;
}
.top-105 {
  top: 0.75rem !important;
}
.top-2 {
  top: 1rem !important;
}
.top-205 {
  top: 1.25rem !important;
}
.top-3 {
  top: 1.5rem !important;
}
.top-neg-1px {
  top: -1px !important;
}
.top-neg-2px {
  top: -2px !important;
}
.top-neg-05 {
  top: -0.25rem !important;
}
.top-neg-1 {
  top: -0.5rem !important;
}
.top-neg-105 {
  top: -0.75rem !important;
}
.top-neg-2 {
  top: -1rem !important;
}
.top-neg-205 {
  top: -1.25rem !important;
}
.top-neg-3 {
  top: -1.5rem !important;
}
.top-0 {
  top: 0 !important;
}
.top-auto {
  top: auto !important;
}
.top-full {
  top: 100% !important;
}
.text-baseline {
  vertical-align: baseline !important;
}
.text-bottom {
  vertical-align: bottom !important;
}
.text-middle {
  vertical-align: middle !important;
}
.text-sub {
  vertical-align: sub !important;
}
.text-super {
  vertical-align: super !important;
}
.text-tbottom {
  vertical-align: text-bottom !important;
}
.text-ttop {
  vertical-align: text-top !important;
}
.text-top {
  vertical-align: top !important;
}
.text-pre {
  white-space: pre !important;
}
.text-pre-line {
  white-space: pre-line !important;
}
.text-pre-wrap {
  white-space: pre-wrap !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-no-wrap {
  white-space: nowrap !important;
}
.width-1px {
  width: 1px !important;
}
.width-2px {
  width: 2px !important;
}
.width-05 {
  width: 0.25rem !important;
}
.width-1 {
  width: 0.5rem !important;
}
.width-105 {
  width: 0.75rem !important;
}
.width-2 {
  width: 1rem !important;
}
.width-205 {
  width: 1.25rem !important;
}
.width-3 {
  width: 1.5rem !important;
}
.width-4 {
  width: 2rem !important;
}
.width-5 {
  width: 2.5rem !important;
}
.width-6 {
  width: 3rem !important;
}
.width-7 {
  width: 3.5rem !important;
}
.width-8 {
  width: 4rem !important;
}
.width-9 {
  width: 4.5rem !important;
}
.width-10 {
  width: 5rem !important;
}
.width-15 {
  width: 7.5rem !important;
}
.width-card {
  width: 10rem !important;
}
.width-card-lg {
  width: 15rem !important;
}
.width-mobile {
  width: 20rem !important;
}
.width-mobile-lg {
  width: 30rem !important;
}
.width-tablet {
  width: 40rem !important;
}
.width-tablet-lg {
  width: 55rem !important;
}
.width-desktop {
  width: 64rem !important;
}
.width-desktop-lg {
  width: 75rem !important;
}
.width-widescreen {
  width: 87.5rem !important;
}
.width-0 {
  width: 0 !important;
}
.width-full {
  width: 100% !important;
}
.width-auto {
  width: auto !important;
}
.z-auto {
  z-index: auto !important;
}
.z-bottom {
  z-index: -100 !important;
}
.z-top {
  z-index: 99999 !important;
}
.z-0 {
  z-index: 0 !important;
}
.z-100 {
  z-index: 100 !important;
}
.z-200 {
  z-index: 200 !important;
}
.z-300 {
  z-index: 300 !important;
}
.z-400 {
  z-index: 400 !important;
}
.z-500 {
  z-index: 500 !important;
}
@media all and (min-width: 30em) {
  .mobile-lg\:border-1px {
    border: 1px solid !important;
  }
  .mobile-lg\:hover\:border-1px:hover {
    border: 1px solid !important;
  }
  .mobile-lg\:border-y-1px {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }
  .mobile-lg\:hover\:border-y-1px:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }
  .mobile-lg\:border-x-1px {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }
  .mobile-lg\:hover\:border-x-1px:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }
  .mobile-lg\:border-top-1px {
    border-top: 1px solid !important;
  }
  .mobile-lg\:hover\:border-top-1px:hover {
    border-top: 1px solid !important;
  }
  .mobile-lg\:border-right-1px {
    border-right: 1px solid !important;
  }
  .mobile-lg\:hover\:border-right-1px:hover {
    border-right: 1px solid !important;
  }
  .mobile-lg\:border-bottom-1px {
    border-bottom: 1px solid !important;
  }
  .mobile-lg\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid !important;
  }
  .mobile-lg\:border-left-1px {
    border-left: 1px solid !important;
  }
  .mobile-lg\:hover\:border-left-1px:hover {
    border-left: 1px solid !important;
  }
  .mobile-lg\:border-2px {
    border: 2px solid !important;
  }
  .mobile-lg\:hover\:border-2px:hover {
    border: 2px solid !important;
  }
  .mobile-lg\:border-y-2px {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }
  .mobile-lg\:hover\:border-y-2px:hover {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }
  .mobile-lg\:border-x-2px {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }
  .mobile-lg\:hover\:border-x-2px:hover {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }
  .mobile-lg\:border-top-2px {
    border-top: 2px solid !important;
  }
  .mobile-lg\:hover\:border-top-2px:hover {
    border-top: 2px solid !important;
  }
  .mobile-lg\:border-right-2px {
    border-right: 2px solid !important;
  }
  .mobile-lg\:hover\:border-right-2px:hover {
    border-right: 2px solid !important;
  }
  .mobile-lg\:border-bottom-2px {
    border-bottom: 2px solid !important;
  }
  .mobile-lg\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid !important;
  }
  .mobile-lg\:border-left-2px {
    border-left: 2px solid !important;
  }
  .mobile-lg\:hover\:border-left-2px:hover {
    border-left: 2px solid !important;
  }
  .mobile-lg\:border-05 {
    border: 0.25rem solid !important;
  }
  .mobile-lg\:hover\:border-05:hover {
    border: 0.25rem solid !important;
  }
  .mobile-lg\:border-y-05 {
    border-top: 0.25rem solid !important;
    border-bottom: 0.25rem solid !important;
  }
  .mobile-lg\:hover\:border-y-05:hover {
    border-top: 0.25rem solid !important;
    border-bottom: 0.25rem solid !important;
  }
  .mobile-lg\:border-x-05 {
    border-left: 0.25rem solid !important;
    border-right: 0.25rem solid !important;
  }
  .mobile-lg\:hover\:border-x-05:hover {
    border-left: 0.25rem solid !important;
    border-right: 0.25rem solid !important;
  }
  .mobile-lg\:border-top-05 {
    border-top: 0.25rem solid !important;
  }
  .mobile-lg\:hover\:border-top-05:hover {
    border-top: 0.25rem solid !important;
  }
  .mobile-lg\:border-right-05 {
    border-right: 0.25rem solid !important;
  }
  .mobile-lg\:hover\:border-right-05:hover {
    border-right: 0.25rem solid !important;
  }
  .mobile-lg\:border-bottom-05 {
    border-bottom: 0.25rem solid !important;
  }
  .mobile-lg\:hover\:border-bottom-05:hover {
    border-bottom: 0.25rem solid !important;
  }
  .mobile-lg\:border-left-05 {
    border-left: 0.25rem solid !important;
  }
  .mobile-lg\:hover\:border-left-05:hover {
    border-left: 0.25rem solid !important;
  }
  .mobile-lg\:border-1 {
    border: 0.5rem solid !important;
  }
  .mobile-lg\:hover\:border-1:hover {
    border: 0.5rem solid !important;
  }
  .mobile-lg\:border-y-1 {
    border-top: 0.5rem solid !important;
    border-bottom: 0.5rem solid !important;
  }
  .mobile-lg\:hover\:border-y-1:hover {
    border-top: 0.5rem solid !important;
    border-bottom: 0.5rem solid !important;
  }
  .mobile-lg\:border-x-1 {
    border-left: 0.5rem solid !important;
    border-right: 0.5rem solid !important;
  }
  .mobile-lg\:hover\:border-x-1:hover {
    border-left: 0.5rem solid !important;
    border-right: 0.5rem solid !important;
  }
  .mobile-lg\:border-top-1 {
    border-top: 0.5rem solid !important;
  }
  .mobile-lg\:hover\:border-top-1:hover {
    border-top: 0.5rem solid !important;
  }
  .mobile-lg\:border-right-1 {
    border-right: 0.5rem solid !important;
  }
  .mobile-lg\:hover\:border-right-1:hover {
    border-right: 0.5rem solid !important;
  }
  .mobile-lg\:border-bottom-1 {
    border-bottom: 0.5rem solid !important;
  }
  .mobile-lg\:hover\:border-bottom-1:hover {
    border-bottom: 0.5rem solid !important;
  }
  .mobile-lg\:border-left-1 {
    border-left: 0.5rem solid !important;
  }
  .mobile-lg\:hover\:border-left-1:hover {
    border-left: 0.5rem solid !important;
  }
  .mobile-lg\:border-105 {
    border: 0.75rem solid !important;
  }
  .mobile-lg\:hover\:border-105:hover {
    border: 0.75rem solid !important;
  }
  .mobile-lg\:border-y-105 {
    border-top: 0.75rem solid !important;
    border-bottom: 0.75rem solid !important;
  }
  .mobile-lg\:hover\:border-y-105:hover {
    border-top: 0.75rem solid !important;
    border-bottom: 0.75rem solid !important;
  }
  .mobile-lg\:border-x-105 {
    border-left: 0.75rem solid !important;
    border-right: 0.75rem solid !important;
  }
  .mobile-lg\:hover\:border-x-105:hover {
    border-left: 0.75rem solid !important;
    border-right: 0.75rem solid !important;
  }
  .mobile-lg\:border-top-105 {
    border-top: 0.75rem solid !important;
  }
  .mobile-lg\:hover\:border-top-105:hover {
    border-top: 0.75rem solid !important;
  }
  .mobile-lg\:border-right-105 {
    border-right: 0.75rem solid !important;
  }
  .mobile-lg\:hover\:border-right-105:hover {
    border-right: 0.75rem solid !important;
  }
  .mobile-lg\:border-bottom-105 {
    border-bottom: 0.75rem solid !important;
  }
  .mobile-lg\:hover\:border-bottom-105:hover {
    border-bottom: 0.75rem solid !important;
  }
  .mobile-lg\:border-left-105 {
    border-left: 0.75rem solid !important;
  }
  .mobile-lg\:hover\:border-left-105:hover {
    border-left: 0.75rem solid !important;
  }
  .mobile-lg\:border-2 {
    border: 1rem solid !important;
  }
  .mobile-lg\:hover\:border-2:hover {
    border: 1rem solid !important;
  }
  .mobile-lg\:border-y-2 {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }
  .mobile-lg\:hover\:border-y-2:hover {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }
  .mobile-lg\:border-x-2 {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }
  .mobile-lg\:hover\:border-x-2:hover {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }
  .mobile-lg\:border-top-2 {
    border-top: 1rem solid !important;
  }
  .mobile-lg\:hover\:border-top-2:hover {
    border-top: 1rem solid !important;
  }
  .mobile-lg\:border-right-2 {
    border-right: 1rem solid !important;
  }
  .mobile-lg\:hover\:border-right-2:hover {
    border-right: 1rem solid !important;
  }
  .mobile-lg\:border-bottom-2 {
    border-bottom: 1rem solid !important;
  }
  .mobile-lg\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid !important;
  }
  .mobile-lg\:border-left-2 {
    border-left: 1rem solid !important;
  }
  .mobile-lg\:hover\:border-left-2:hover {
    border-left: 1rem solid !important;
  }
  .mobile-lg\:border-205 {
    border: 1.25rem solid !important;
  }
  .mobile-lg\:hover\:border-205:hover {
    border: 1.25rem solid !important;
  }
  .mobile-lg\:border-y-205 {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }
  .mobile-lg\:hover\:border-y-205:hover {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }
  .mobile-lg\:border-x-205 {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }
  .mobile-lg\:hover\:border-x-205:hover {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }
  .mobile-lg\:border-top-205 {
    border-top: 1.25rem solid !important;
  }
  .mobile-lg\:hover\:border-top-205:hover {
    border-top: 1.25rem solid !important;
  }
  .mobile-lg\:border-right-205 {
    border-right: 1.25rem solid !important;
  }
  .mobile-lg\:hover\:border-right-205:hover {
    border-right: 1.25rem solid !important;
  }
  .mobile-lg\:border-bottom-205 {
    border-bottom: 1.25rem solid !important;
  }
  .mobile-lg\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid !important;
  }
  .mobile-lg\:border-left-205 {
    border-left: 1.25rem solid !important;
  }
  .mobile-lg\:hover\:border-left-205:hover {
    border-left: 1.25rem solid !important;
  }
  .mobile-lg\:border-3 {
    border: 1.5rem solid !important;
  }
  .mobile-lg\:hover\:border-3:hover {
    border: 1.5rem solid !important;
  }
  .mobile-lg\:border-y-3 {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }
  .mobile-lg\:hover\:border-y-3:hover {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }
  .mobile-lg\:border-x-3 {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }
  .mobile-lg\:hover\:border-x-3:hover {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }
  .mobile-lg\:border-top-3 {
    border-top: 1.5rem solid !important;
  }
  .mobile-lg\:hover\:border-top-3:hover {
    border-top: 1.5rem solid !important;
  }
  .mobile-lg\:border-right-3 {
    border-right: 1.5rem solid !important;
  }
  .mobile-lg\:hover\:border-right-3:hover {
    border-right: 1.5rem solid !important;
  }
  .mobile-lg\:border-bottom-3 {
    border-bottom: 1.5rem solid !important;
  }
  .mobile-lg\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid !important;
  }
  .mobile-lg\:border-left-3 {
    border-left: 1.5rem solid !important;
  }
  .mobile-lg\:hover\:border-left-3:hover {
    border-left: 1.5rem solid !important;
  }
  .mobile-lg\:border-0 {
    border: 0 solid !important;
  }
  .mobile-lg\:hover\:border-0:hover {
    border: 0 solid !important;
  }
  .mobile-lg\:border-y-0 {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }
  .mobile-lg\:hover\:border-y-0:hover {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }
  .mobile-lg\:border-x-0 {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }
  .mobile-lg\:hover\:border-x-0:hover {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }
  .mobile-lg\:border-top-0 {
    border-top: 0 solid !important;
  }
  .mobile-lg\:hover\:border-top-0:hover {
    border-top: 0 solid !important;
  }
  .mobile-lg\:border-right-0 {
    border-right: 0 solid !important;
  }
  .mobile-lg\:hover\:border-right-0:hover {
    border-right: 0 solid !important;
  }
  .mobile-lg\:border-bottom-0 {
    border-bottom: 0 solid !important;
  }
  .mobile-lg\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid !important;
  }
  .mobile-lg\:border-left-0 {
    border-left: 0 solid !important;
  }
  .mobile-lg\:hover\:border-left-0:hover {
    border-left: 0 solid !important;
  }
  .mobile-lg\:border {
    border: 1px solid !important;
  }
  .mobile-lg\:hover\:border:hover {
    border: 1px solid !important;
  }
  .mobile-lg\:border-y {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }
  .mobile-lg\:hover\:border-y:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }
  .mobile-lg\:border-x {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }
  .mobile-lg\:hover\:border-x:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }
  .mobile-lg\:border-top {
    border-top: 1px solid !important;
  }
  .mobile-lg\:hover\:border-top:hover {
    border-top: 1px solid !important;
  }
  .mobile-lg\:border-right {
    border-right: 1px solid !important;
  }
  .mobile-lg\:hover\:border-right:hover {
    border-right: 1px solid !important;
  }
  .mobile-lg\:border-bottom {
    border-bottom: 1px solid !important;
  }
  .mobile-lg\:hover\:border-bottom:hover {
    border-bottom: 1px solid !important;
  }
  .mobile-lg\:border-left {
    border-left: 1px solid !important;
  }
  .mobile-lg\:hover\:border-left:hover {
    border-left: 1px solid !important;
  }
  .mobile-lg\:border-transparent {
    border-color: transparent !important;
  }
  .mobile-lg\:hover\:border-transparent:hover {
    border-color: transparent !important;
  }
  .mobile-lg\:border-black {
    border-color: black !important;
  }
  .mobile-lg\:hover\:border-black:hover {
    border-color: black !important;
  }
  .mobile-lg\:border-white {
    border-color: white !important;
  }
  .mobile-lg\:hover\:border-white:hover {
    border-color: white !important;
  }
  .mobile-lg\:border-red {
    border-color: #e52207 !important;
  }
  .mobile-lg\:hover\:border-red:hover {
    border-color: #e52207 !important;
  }
  .mobile-lg\:border-orange {
    border-color: #e66f0e !important;
  }
  .mobile-lg\:hover\:border-orange:hover {
    border-color: #e66f0e !important;
  }
  .mobile-lg\:border-gold {
    border-color: #ffbe2e !important;
  }
  .mobile-lg\:hover\:border-gold:hover {
    border-color: #ffbe2e !important;
  }
  .mobile-lg\:border-yellow {
    border-color: #fee685 !important;
  }
  .mobile-lg\:hover\:border-yellow:hover {
    border-color: #fee685 !important;
  }
  .mobile-lg\:border-green {
    border-color: #538200 !important;
  }
  .mobile-lg\:hover\:border-green:hover {
    border-color: #538200 !important;
  }
  .mobile-lg\:border-mint {
    border-color: #04c585 !important;
  }
  .mobile-lg\:hover\:border-mint:hover {
    border-color: #04c585 !important;
  }
  .mobile-lg\:border-cyan {
    border-color: #009ec1 !important;
  }
  .mobile-lg\:hover\:border-cyan:hover {
    border-color: #009ec1 !important;
  }
  .mobile-lg\:border-blue {
    border-color: #0076d6 !important;
  }
  .mobile-lg\:hover\:border-blue:hover {
    border-color: #0076d6 !important;
  }
  .mobile-lg\:border-indigo {
    border-color: #676cc8 !important;
  }
  .mobile-lg\:hover\:border-indigo:hover {
    border-color: #676cc8 !important;
  }
  .mobile-lg\:border-violet {
    border-color: #8168b3 !important;
  }
  .mobile-lg\:hover\:border-violet:hover {
    border-color: #8168b3 !important;
  }
  .mobile-lg\:border-magenta {
    border-color: #d72d79 !important;
  }
  .mobile-lg\:hover\:border-magenta:hover {
    border-color: #d72d79 !important;
  }
  .mobile-lg\:border-gray-5 {
    border-color: #f0f0f0 !important;
  }
  .mobile-lg\:hover\:border-gray-5:hover {
    border-color: #f0f0f0 !important;
  }
  .mobile-lg\:border-gray-10 {
    border-color: #e6e6e6 !important;
  }
  .mobile-lg\:hover\:border-gray-10:hover {
    border-color: #e6e6e6 !important;
  }
  .mobile-lg\:border-gray-30 {
    border-color: #adadad !important;
  }
  .mobile-lg\:hover\:border-gray-30:hover {
    border-color: #adadad !important;
  }
  .mobile-lg\:border-gray-50 {
    border-color: #757575 !important;
  }
  .mobile-lg\:hover\:border-gray-50:hover {
    border-color: #757575 !important;
  }
  .mobile-lg\:border-gray-70 {
    border-color: #454545 !important;
  }
  .mobile-lg\:hover\:border-gray-70:hover {
    border-color: #454545 !important;
  }
  .mobile-lg\:border-gray-90 {
    border-color: #1b1b1b !important;
  }
  .mobile-lg\:hover\:border-gray-90:hover {
    border-color: #1b1b1b !important;
  }
  .mobile-lg\:border-base-lightest {
    border-color: #f0f0f0 !important;
  }
  .mobile-lg\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }
  .mobile-lg\:border-base-lighter {
    border-color: #dfe1e2 !important;
  }
  .mobile-lg\:hover\:border-base-lighter:hover {
    border-color: #dfe1e2 !important;
  }
  .mobile-lg\:border-base-light {
    border-color: #a9aeb1 !important;
  }
  .mobile-lg\:hover\:border-base-light:hover {
    border-color: #a9aeb1 !important;
  }
  .mobile-lg\:border-base {
    border-color: #71767a !important;
  }
  .mobile-lg\:hover\:border-base:hover {
    border-color: #71767a !important;
  }
  .mobile-lg\:border-base-dark {
    border-color: #565c65 !important;
  }
  .mobile-lg\:hover\:border-base-dark:hover {
    border-color: #565c65 !important;
  }
  .mobile-lg\:border-base-darker {
    border-color: #3d4551 !important;
  }
  .mobile-lg\:hover\:border-base-darker:hover {
    border-color: #3d4551 !important;
  }
  .mobile-lg\:border-base-darkest {
    border-color: #2d2e2f !important;
  }
  .mobile-lg\:hover\:border-base-darkest:hover {
    border-color: #2d2e2f !important;
  }
  .mobile-lg\:border-ink {
    border-color: #1c1d1f !important;
  }
  .mobile-lg\:hover\:border-ink:hover {
    border-color: #1c1d1f !important;
  }
  .mobile-lg\:border-primary-lighter {
    border-color: #d9e8f6 !important;
  }
  .mobile-lg\:hover\:border-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }
  .mobile-lg\:border-primary-light {
    border-color: #73b3e7 !important;
  }
  .mobile-lg\:hover\:border-primary-light:hover {
    border-color: #73b3e7 !important;
  }
  .mobile-lg\:border-primary {
    border-color: #005ea2 !important;
  }
  .mobile-lg\:hover\:border-primary:hover {
    border-color: #005ea2 !important;
  }
  .mobile-lg\:border-primary-vivid {
    border-color: #0050d8 !important;
  }
  .mobile-lg\:hover\:border-primary-vivid:hover {
    border-color: #0050d8 !important;
  }
  .mobile-lg\:border-primary-dark {
    border-color: #1a4480 !important;
  }
  .mobile-lg\:hover\:border-primary-dark:hover {
    border-color: #1a4480 !important;
  }
  .mobile-lg\:border-primary-darker {
    border-color: #162e51 !important;
  }
  .mobile-lg\:hover\:border-primary-darker:hover {
    border-color: #162e51 !important;
  }
  .mobile-lg\:border-secondary-lighter {
    border-color: #f3e1e4 !important;
  }
  .mobile-lg\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4 !important;
  }
  .mobile-lg\:border-secondary-light {
    border-color: #f2938c !important;
  }
  .mobile-lg\:hover\:border-secondary-light:hover {
    border-color: #f2938c !important;
  }
  .mobile-lg\:border-secondary {
    border-color: #d83933 !important;
  }
  .mobile-lg\:hover\:border-secondary:hover {
    border-color: #d83933 !important;
  }
  .mobile-lg\:border-secondary-vivid {
    border-color: #e41d3d !important;
  }
  .mobile-lg\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }
  .mobile-lg\:border-secondary-dark {
    border-color: #b50909 !important;
  }
  .mobile-lg\:hover\:border-secondary-dark:hover {
    border-color: #b50909 !important;
  }
  .mobile-lg\:border-secondary-darker {
    border-color: #8b0a03 !important;
  }
  .mobile-lg\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03 !important;
  }
  .mobile-lg\:border-accent-warm-darker {
    border-color: #775540 !important;
  }
  .mobile-lg\:hover\:border-accent-warm-darker:hover {
    border-color: #775540 !important;
  }
  .mobile-lg\:border-accent-warm-dark {
    border-color: #c05600 !important;
  }
  .mobile-lg\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }
  .mobile-lg\:border-accent-warm {
    border-color: #fa9441 !important;
  }
  .mobile-lg\:hover\:border-accent-warm:hover {
    border-color: #fa9441 !important;
  }
  .mobile-lg\:border-accent-warm-light {
    border-color: #ffbc78 !important;
  }
  .mobile-lg\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }
  .mobile-lg\:border-accent-warm-lighter {
    border-color: #f2e4d4 !important;
  }
  .mobile-lg\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }
  .mobile-lg\:border-accent-cool-darker {
    border-color: #07648d !important;
  }
  .mobile-lg\:hover\:border-accent-cool-darker:hover {
    border-color: #07648d !important;
  }
  .mobile-lg\:border-accent-cool-dark {
    border-color: #28a0cb !important;
  }
  .mobile-lg\:hover\:border-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }
  .mobile-lg\:border-accent-cool {
    border-color: #00bde3 !important;
  }
  .mobile-lg\:hover\:border-accent-cool:hover {
    border-color: #00bde3 !important;
  }
  .mobile-lg\:border-accent-cool-light {
    border-color: #97d4ea !important;
  }
  .mobile-lg\:hover\:border-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }
  .mobile-lg\:border-accent-cool-lighter {
    border-color: #e1f3f8 !important;
  }
  .mobile-lg\:hover\:border-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }
  .mobile-lg\:border-error-lighter {
    border-color: #f4e3db !important;
  }
  .mobile-lg\:hover\:border-error-lighter:hover {
    border-color: #f4e3db !important;
  }
  .mobile-lg\:border-error-light {
    border-color: #f39268 !important;
  }
  .mobile-lg\:hover\:border-error-light:hover {
    border-color: #f39268 !important;
  }
  .mobile-lg\:border-error {
    border-color: #d54309 !important;
  }
  .mobile-lg\:hover\:border-error:hover {
    border-color: #d54309 !important;
  }
  .mobile-lg\:border-error-dark {
    border-color: #b50909 !important;
  }
  .mobile-lg\:hover\:border-error-dark:hover {
    border-color: #b50909 !important;
  }
  .mobile-lg\:border-error-darker {
    border-color: #6f3331 !important;
  }
  .mobile-lg\:hover\:border-error-darker:hover {
    border-color: #6f3331 !important;
  }
  .mobile-lg\:border-warning-lighter {
    border-color: #faf3d1 !important;
  }
  .mobile-lg\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1 !important;
  }
  .mobile-lg\:border-warning-light {
    border-color: #fee685 !important;
  }
  .mobile-lg\:hover\:border-warning-light:hover {
    border-color: #fee685 !important;
  }
  .mobile-lg\:border-warning {
    border-color: #ffbe2e !important;
  }
  .mobile-lg\:hover\:border-warning:hover {
    border-color: #ffbe2e !important;
  }
  .mobile-lg\:border-warning-dark {
    border-color: #e5a000 !important;
  }
  .mobile-lg\:hover\:border-warning-dark:hover {
    border-color: #e5a000 !important;
  }
  .mobile-lg\:border-warning-darker {
    border-color: #936f38 !important;
  }
  .mobile-lg\:hover\:border-warning-darker:hover {
    border-color: #936f38 !important;
  }
  .mobile-lg\:border-success-lighter {
    border-color: #ecf3ec !important;
  }
  .mobile-lg\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec !important;
  }
  .mobile-lg\:border-success-light {
    border-color: #70e17b !important;
  }
  .mobile-lg\:hover\:border-success-light:hover {
    border-color: #70e17b !important;
  }
  .mobile-lg\:border-success {
    border-color: #00a91c !important;
  }
  .mobile-lg\:hover\:border-success:hover {
    border-color: #00a91c !important;
  }
  .mobile-lg\:border-success-dark {
    border-color: #008817 !important;
  }
  .mobile-lg\:hover\:border-success-dark:hover {
    border-color: #008817 !important;
  }
  .mobile-lg\:border-success-darker {
    border-color: #216e1f !important;
  }
  .mobile-lg\:hover\:border-success-darker:hover {
    border-color: #216e1f !important;
  }
  .mobile-lg\:border-info-lighter {
    border-color: #e7f6f8 !important;
  }
  .mobile-lg\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8 !important;
  }
  .mobile-lg\:border-info-light {
    border-color: #99deea !important;
  }
  .mobile-lg\:hover\:border-info-light:hover {
    border-color: #99deea !important;
  }
  .mobile-lg\:border-info {
    border-color: #00bde3 !important;
  }
  .mobile-lg\:hover\:border-info:hover {
    border-color: #00bde3 !important;
  }
  .mobile-lg\:border-info-dark {
    border-color: #009ec1 !important;
  }
  .mobile-lg\:hover\:border-info-dark:hover {
    border-color: #009ec1 !important;
  }
  .mobile-lg\:border-info-darker {
    border-color: #2e6276 !important;
  }
  .mobile-lg\:hover\:border-info-darker:hover {
    border-color: #2e6276 !important;
  }
  .mobile-lg\:border-disabled-lighter {
    border-color: #c9c9c9 !important;
  }
  .mobile-lg\:hover\:border-disabled-lighter:hover {
    border-color: #c9c9c9 !important;
  }
  .mobile-lg\:border-disabled-light {
    border-color: #919191 !important;
  }
  .mobile-lg\:hover\:border-disabled-light:hover {
    border-color: #919191 !important;
  }
  .mobile-lg\:border-disabled {
    border-color: #757575 !important;
  }
  .mobile-lg\:hover\:border-disabled:hover {
    border-color: #757575 !important;
  }
  .mobile-lg\:border-disabled-dark {
    border-color: #454545 !important;
  }
  .mobile-lg\:hover\:border-disabled-dark:hover {
    border-color: #454545 !important;
  }
  .mobile-lg\:border-disabled-darker {
    border-color: #1b1b1b !important;
  }
  .mobile-lg\:hover\:border-disabled-darker:hover {
    border-color: #1b1b1b !important;
  }
  .mobile-lg\:border-emergency {
    border-color: #9c3d10 !important;
  }
  .mobile-lg\:hover\:border-emergency:hover {
    border-color: #9c3d10 !important;
  }
  .mobile-lg\:border-emergency-dark {
    border-color: #332d29 !important;
  }
  .mobile-lg\:hover\:border-emergency-dark:hover {
    border-color: #332d29 !important;
  }
  .mobile-lg\:radius-0 {
    border-radius: 0 !important;
  }
  .mobile-lg\:radius-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .mobile-lg\:radius-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .mobile-lg\:radius-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .mobile-lg\:radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .mobile-lg\:radius-sm {
    border-radius: 2px !important;
  }
  .mobile-lg\:radius-top-sm {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
  }
  .mobile-lg\:radius-right-sm {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }
  .mobile-lg\:radius-bottom-sm {
    border-bottom-left-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }
  .mobile-lg\:radius-left-sm {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }
  .mobile-lg\:radius-md {
    border-radius: 0.25rem !important;
  }
  .mobile-lg\:radius-top-md {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .mobile-lg\:radius-right-md {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .mobile-lg\:radius-bottom-md {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .mobile-lg\:radius-left-md {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .mobile-lg\:radius-lg {
    border-radius: 0.5rem !important;
  }
  .mobile-lg\:radius-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .mobile-lg\:radius-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .mobile-lg\:radius-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .mobile-lg\:radius-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .mobile-lg\:radius-pill {
    border-radius: 99rem !important;
  }
  .mobile-lg\:radius-top-pill {
    border-top-left-radius: 99rem !important;
    border-top-right-radius: 99rem !important;
  }
  .mobile-lg\:radius-right-pill {
    border-top-right-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }
  .mobile-lg\:radius-bottom-pill {
    border-bottom-left-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }
  .mobile-lg\:radius-left-pill {
    border-top-left-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }
  .mobile-lg\:display-block {
    display: block !important;
  }
  .mobile-lg\:display-flex {
    display: flex !important;
  }
  .mobile-lg\:display-none {
    display: none !important;
  }
  .mobile-lg\:display-inline {
    display: inline !important;
  }
  .mobile-lg\:display-inline-block {
    display: inline-block !important;
  }
  .mobile-lg\:display-inline-flex {
    display: inline-flex !important;
  }
  .mobile-lg\:display-table {
    display: table !important;
  }
  .mobile-lg\:display-table-cell {
    display: table-cell !important;
  }
  .mobile-lg\:display-table-row {
    display: table-row !important;
  }
  .mobile-lg\:font-mono-3xs {
    font-size: 0.77rem !important;
  }
  .mobile-lg\:font-mono-2xs {
    font-size: 0.83rem !important;
  }
  .mobile-lg\:font-mono-xs {
    font-size: 0.89rem !important;
  }
  .mobile-lg\:font-mono-sm {
    font-size: 0.95rem !important;
  }
  .mobile-lg\:font-mono-md {
    font-size: 1.19rem !important;
  }
  .mobile-lg\:font-mono-lg {
    font-size: 1.31rem !important;
  }
  .mobile-lg\:font-mono-xl {
    font-size: 1.91rem !important;
  }
  .mobile-lg\:font-mono-2xl {
    font-size: 2.38rem !important;
  }
  .mobile-lg\:font-mono-3xl {
    font-size: 2.86rem !important;
  }
  .mobile-lg\:font-sans-3xs {
    font-size: 0.81rem !important;
  }
  .mobile-lg\:font-sans-2xs {
    font-size: 0.87rem !important;
  }
  .mobile-lg\:font-sans-xs {
    font-size: 0.93rem !important;
  }
  .mobile-lg\:font-sans-sm {
    font-size: 0.99rem !important;
  }
  .mobile-lg\:font-sans-md {
    font-size: 1.24rem !important;
  }
  .mobile-lg\:font-sans-lg {
    font-size: 1.37rem !important;
  }
  .mobile-lg\:font-sans-xl {
    font-size: 1.99rem !important;
  }
  .mobile-lg\:font-sans-2xl {
    font-size: 2.49rem !important;
  }
  .mobile-lg\:font-sans-3xl {
    font-size: 2.98rem !important;
  }
  .mobile-lg\:font-serif-3xs {
    font-size: 0.81rem !important;
  }
  .mobile-lg\:font-serif-2xs {
    font-size: 0.87rem !important;
  }
  .mobile-lg\:font-serif-xs {
    font-size: 0.93rem !important;
  }
  .mobile-lg\:font-serif-sm {
    font-size: 0.99rem !important;
  }
  .mobile-lg\:font-serif-md {
    font-size: 1.24rem !important;
  }
  .mobile-lg\:font-serif-lg {
    font-size: 1.37rem !important;
  }
  .mobile-lg\:font-serif-xl {
    font-size: 1.99rem !important;
  }
  .mobile-lg\:font-serif-2xl {
    font-size: 2.49rem !important;
  }
  .mobile-lg\:font-serif-3xl {
    font-size: 2.98rem !important;
  }
  .mobile-lg\:font-heading-3xs {
    font-size: 0.81rem !important;
  }
  .mobile-lg\:font-heading-2xs {
    font-size: 0.87rem !important;
  }
  .mobile-lg\:font-heading-xs {
    font-size: 0.93rem !important;
  }
  .mobile-lg\:font-heading-sm {
    font-size: 0.99rem !important;
  }
  .mobile-lg\:font-heading-md {
    font-size: 1.24rem !important;
  }
  .mobile-lg\:font-heading-lg {
    font-size: 1.37rem !important;
  }
  .mobile-lg\:font-heading-xl {
    font-size: 1.99rem !important;
  }
  .mobile-lg\:font-heading-2xl {
    font-size: 2.49rem !important;
  }
  .mobile-lg\:font-heading-3xl {
    font-size: 2.98rem !important;
  }
  .mobile-lg\:font-body-3xs {
    font-size: 0.81rem !important;
  }
  .mobile-lg\:font-body-2xs {
    font-size: 0.87rem !important;
  }
  .mobile-lg\:font-body-xs {
    font-size: 0.93rem !important;
  }
  .mobile-lg\:font-body-sm {
    font-size: 0.99rem !important;
  }
  .mobile-lg\:font-body-md {
    font-size: 1.24rem !important;
  }
  .mobile-lg\:font-body-lg {
    font-size: 1.37rem !important;
  }
  .mobile-lg\:font-body-xl {
    font-size: 1.99rem !important;
  }
  .mobile-lg\:font-body-2xl {
    font-size: 2.49rem !important;
  }
  .mobile-lg\:font-body-3xl {
    font-size: 2.98rem !important;
  }
  .mobile-lg\:font-code-3xs {
    font-size: 0.77rem !important;
  }
  .mobile-lg\:font-code-2xs {
    font-size: 0.83rem !important;
  }
  .mobile-lg\:font-code-xs {
    font-size: 0.89rem !important;
  }
  .mobile-lg\:font-code-sm {
    font-size: 0.95rem !important;
  }
  .mobile-lg\:font-code-md {
    font-size: 1.19rem !important;
  }
  .mobile-lg\:font-code-lg {
    font-size: 1.31rem !important;
  }
  .mobile-lg\:font-code-xl {
    font-size: 1.91rem !important;
  }
  .mobile-lg\:font-code-2xl {
    font-size: 2.38rem !important;
  }
  .mobile-lg\:font-code-3xl {
    font-size: 2.86rem !important;
  }
  .mobile-lg\:font-alt-3xs {
    font-size: 0.81rem !important;
  }
  .mobile-lg\:font-alt-2xs {
    font-size: 0.87rem !important;
  }
  .mobile-lg\:font-alt-xs {
    font-size: 0.93rem !important;
  }
  .mobile-lg\:font-alt-sm {
    font-size: 0.99rem !important;
  }
  .mobile-lg\:font-alt-md {
    font-size: 1.24rem !important;
  }
  .mobile-lg\:font-alt-lg {
    font-size: 1.37rem !important;
  }
  .mobile-lg\:font-alt-xl {
    font-size: 1.99rem !important;
  }
  .mobile-lg\:font-alt-2xl {
    font-size: 2.49rem !important;
  }
  .mobile-lg\:font-alt-3xl {
    font-size: 2.98rem !important;
  }
  .mobile-lg\:font-ui-3xs {
    font-size: 0.81rem !important;
  }
  .mobile-lg\:font-ui-2xs {
    font-size: 0.87rem !important;
  }
  .mobile-lg\:font-ui-xs {
    font-size: 0.93rem !important;
  }
  .mobile-lg\:font-ui-sm {
    font-size: 0.99rem !important;
  }
  .mobile-lg\:font-ui-md {
    font-size: 1.24rem !important;
  }
  .mobile-lg\:font-ui-lg {
    font-size: 1.37rem !important;
  }
  .mobile-lg\:font-ui-xl {
    font-size: 1.99rem !important;
  }
  .mobile-lg\:font-ui-2xl {
    font-size: 2.49rem !important;
  }
  .mobile-lg\:font-ui-3xl {
    font-size: 2.98rem !important;
  }
  .mobile-lg\:text-light {
    font-weight: 300 !important;
  }
  .mobile-lg\:text-normal {
    font-weight: normal !important;
  }
  .mobile-lg\:text-semibold {
    font-weight: 600 !important;
  }
  .mobile-lg\:text-bold {
    font-weight: 700 !important;
  }
  .mobile-lg\:flex-justify-center {
    justify-content: center !important;
  }
  .mobile-lg\:flex-justify-start {
    justify-content: flex-start !important;
  }
  .mobile-lg\:flex-justify-end {
    justify-content: flex-end !important;
  }
  .mobile-lg\:flex-justify {
    justify-content: space-between !important;
  }
  .mobile-lg\:line-height-sans-1 {
    line-height: 1 !important;
  }
  .mobile-lg\:line-height-sans-2 {
    line-height: 1.2 !important;
  }
  .mobile-lg\:line-height-sans-3 {
    line-height: 1.4 !important;
  }
  .mobile-lg\:line-height-sans-4 {
    line-height: 1.5 !important;
  }
  .mobile-lg\:line-height-sans-5 {
    line-height: 1.6 !important;
  }
  .mobile-lg\:line-height-sans-6 {
    line-height: 1.8 !important;
  }
  .mobile-lg\:line-height-serif-1 {
    line-height: 1 !important;
  }
  .mobile-lg\:line-height-serif-2 {
    line-height: 1.2 !important;
  }
  .mobile-lg\:line-height-serif-3 {
    line-height: 1.4 !important;
  }
  .mobile-lg\:line-height-serif-4 {
    line-height: 1.5 !important;
  }
  .mobile-lg\:line-height-serif-5 {
    line-height: 1.6 !important;
  }
  .mobile-lg\:line-height-serif-6 {
    line-height: 1.8 !important;
  }
  .mobile-lg\:line-height-mono-1 {
    line-height: 1 !important;
  }
  .mobile-lg\:line-height-mono-2 {
    line-height: 1.3 !important;
  }
  .mobile-lg\:line-height-mono-3 {
    line-height: 1.4 !important;
  }
  .mobile-lg\:line-height-mono-4 {
    line-height: 1.6 !important;
  }
  .mobile-lg\:line-height-mono-5 {
    line-height: 1.7 !important;
  }
  .mobile-lg\:line-height-mono-6 {
    line-height: 1.8 !important;
  }
  .mobile-lg\:line-height-heading-1 {
    line-height: 1 !important;
  }
  .mobile-lg\:line-height-heading-2 {
    line-height: 1.2 !important;
  }
  .mobile-lg\:line-height-heading-3 {
    line-height: 1.4 !important;
  }
  .mobile-lg\:line-height-heading-4 {
    line-height: 1.5 !important;
  }
  .mobile-lg\:line-height-heading-5 {
    line-height: 1.6 !important;
  }
  .mobile-lg\:line-height-heading-6 {
    line-height: 1.8 !important;
  }
  .mobile-lg\:line-height-ui-1 {
    line-height: 1 !important;
  }
  .mobile-lg\:line-height-ui-2 {
    line-height: 1.2 !important;
  }
  .mobile-lg\:line-height-ui-3 {
    line-height: 1.4 !important;
  }
  .mobile-lg\:line-height-ui-4 {
    line-height: 1.5 !important;
  }
  .mobile-lg\:line-height-ui-5 {
    line-height: 1.6 !important;
  }
  .mobile-lg\:line-height-ui-6 {
    line-height: 1.8 !important;
  }
  .mobile-lg\:line-height-body-1 {
    line-height: 1 !important;
  }
  .mobile-lg\:line-height-body-2 {
    line-height: 1.2 !important;
  }
  .mobile-lg\:line-height-body-3 {
    line-height: 1.4 !important;
  }
  .mobile-lg\:line-height-body-4 {
    line-height: 1.5 !important;
  }
  .mobile-lg\:line-height-body-5 {
    line-height: 1.6 !important;
  }
  .mobile-lg\:line-height-body-6 {
    line-height: 1.8 !important;
  }
  .mobile-lg\:line-height-code-1 {
    line-height: 1 !important;
  }
  .mobile-lg\:line-height-code-2 {
    line-height: 1.3 !important;
  }
  .mobile-lg\:line-height-code-3 {
    line-height: 1.4 !important;
  }
  .mobile-lg\:line-height-code-4 {
    line-height: 1.6 !important;
  }
  .mobile-lg\:line-height-code-5 {
    line-height: 1.7 !important;
  }
  .mobile-lg\:line-height-code-6 {
    line-height: 1.8 !important;
  }
  .mobile-lg\:line-height-alt-1 {
    line-height: 1 !important;
  }
  .mobile-lg\:line-height-alt-2 {
    line-height: 1.2 !important;
  }
  .mobile-lg\:line-height-alt-3 {
    line-height: 1.4 !important;
  }
  .mobile-lg\:line-height-alt-4 {
    line-height: 1.5 !important;
  }
  .mobile-lg\:line-height-alt-5 {
    line-height: 1.6 !important;
  }
  .mobile-lg\:line-height-alt-6 {
    line-height: 1.8 !important;
  }
  .mobile-lg\:margin-neg-1px {
    margin: -1px !important;
  }
  .mobile-lg\:margin-neg-2px {
    margin: -2px !important;
  }
  .mobile-lg\:margin-neg-05 {
    margin: -0.25rem !important;
  }
  .mobile-lg\:margin-neg-1 {
    margin: -0.5rem !important;
  }
  .mobile-lg\:margin-neg-105 {
    margin: -0.75rem !important;
  }
  .mobile-lg\:margin-neg-2 {
    margin: -1rem !important;
  }
  .mobile-lg\:margin-neg-205 {
    margin: -1.25rem !important;
  }
  .mobile-lg\:margin-neg-3 {
    margin: -1.5rem !important;
  }
  .mobile-lg\:margin-1px {
    margin: 1px !important;
  }
  .mobile-lg\:margin-2px {
    margin: 2px !important;
  }
  .mobile-lg\:margin-05 {
    margin: 0.25rem !important;
  }
  .mobile-lg\:margin-1 {
    margin: 0.5rem !important;
  }
  .mobile-lg\:margin-105 {
    margin: 0.75rem !important;
  }
  .mobile-lg\:margin-2 {
    margin: 1rem !important;
  }
  .mobile-lg\:margin-205 {
    margin: 1.25rem !important;
  }
  .mobile-lg\:margin-3 {
    margin: 1.5rem !important;
  }
  .mobile-lg\:margin-neg-4 {
    margin: -2rem !important;
  }
  .mobile-lg\:margin-neg-5 {
    margin: -2.5rem !important;
  }
  .mobile-lg\:margin-neg-6 {
    margin: -3rem !important;
  }
  .mobile-lg\:margin-neg-7 {
    margin: -3.5rem !important;
  }
  .mobile-lg\:margin-neg-8 {
    margin: -4rem !important;
  }
  .mobile-lg\:margin-neg-9 {
    margin: -4.5rem !important;
  }
  .mobile-lg\:margin-neg-10 {
    margin: -5rem !important;
  }
  .mobile-lg\:margin-neg-15 {
    margin: -7.5rem !important;
  }
  .mobile-lg\:margin-4 {
    margin: 2rem !important;
  }
  .mobile-lg\:margin-5 {
    margin: 2.5rem !important;
  }
  .mobile-lg\:margin-6 {
    margin: 3rem !important;
  }
  .mobile-lg\:margin-7 {
    margin: 3.5rem !important;
  }
  .mobile-lg\:margin-8 {
    margin: 4rem !important;
  }
  .mobile-lg\:margin-9 {
    margin: 4.5rem !important;
  }
  .mobile-lg\:margin-10 {
    margin: 5rem !important;
  }
  .mobile-lg\:margin-15 {
    margin: 7.5rem !important;
  }
  .mobile-lg\:margin-05em {
    margin: 0.5em !important;
  }
  .mobile-lg\:margin-1em {
    margin: 1em !important;
  }
  .mobile-lg\:margin-105em {
    margin: 1.5em !important;
  }
  .mobile-lg\:margin-2em {
    margin: 2em !important;
  }
  .mobile-lg\:margin-0 {
    margin: 0 !important;
  }
  .mobile-lg\:margin-y-1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .mobile-lg\:margin-top-1px {
    margin-top: 1px !important;
  }
  .mobile-lg\:margin-bottom-1px {
    margin-bottom: 1px !important;
  }
  .mobile-lg\:margin-y-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .mobile-lg\:margin-top-2px {
    margin-top: 2px !important;
  }
  .mobile-lg\:margin-bottom-2px {
    margin-bottom: 2px !important;
  }
  .mobile-lg\:margin-y-05 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .mobile-lg\:margin-top-05 {
    margin-top: 0.25rem !important;
  }
  .mobile-lg\:margin-bottom-05 {
    margin-bottom: 0.25rem !important;
  }
  .mobile-lg\:margin-y-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .mobile-lg\:margin-top-1 {
    margin-top: 0.5rem !important;
  }
  .mobile-lg\:margin-bottom-1 {
    margin-bottom: 0.5rem !important;
  }
  .mobile-lg\:margin-y-105 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .mobile-lg\:margin-top-105 {
    margin-top: 0.75rem !important;
  }
  .mobile-lg\:margin-bottom-105 {
    margin-bottom: 0.75rem !important;
  }
  .mobile-lg\:margin-y-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .mobile-lg\:margin-top-2 {
    margin-top: 1rem !important;
  }
  .mobile-lg\:margin-bottom-2 {
    margin-bottom: 1rem !important;
  }
  .mobile-lg\:margin-y-205 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .mobile-lg\:margin-top-205 {
    margin-top: 1.25rem !important;
  }
  .mobile-lg\:margin-bottom-205 {
    margin-bottom: 1.25rem !important;
  }
  .mobile-lg\:margin-y-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .mobile-lg\:margin-top-3 {
    margin-top: 1.5rem !important;
  }
  .mobile-lg\:margin-bottom-3 {
    margin-bottom: 1.5rem !important;
  }
  .mobile-lg\:margin-y-neg-1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .mobile-lg\:margin-top-neg-1px {
    margin-top: -1px !important;
  }
  .mobile-lg\:margin-bottom-neg-1px {
    margin-bottom: -1px !important;
  }
  .mobile-lg\:margin-y-neg-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .mobile-lg\:margin-top-neg-2px {
    margin-top: -2px !important;
  }
  .mobile-lg\:margin-bottom-neg-2px {
    margin-bottom: -2px !important;
  }
  .mobile-lg\:margin-y-neg-05 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .mobile-lg\:margin-top-neg-05 {
    margin-top: -0.25rem !important;
  }
  .mobile-lg\:margin-bottom-neg-05 {
    margin-bottom: -0.25rem !important;
  }
  .mobile-lg\:margin-y-neg-1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .mobile-lg\:margin-top-neg-1 {
    margin-top: -0.5rem !important;
  }
  .mobile-lg\:margin-bottom-neg-1 {
    margin-bottom: -0.5rem !important;
  }
  .mobile-lg\:margin-y-neg-105 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .mobile-lg\:margin-top-neg-105 {
    margin-top: -0.75rem !important;
  }
  .mobile-lg\:margin-bottom-neg-105 {
    margin-bottom: -0.75rem !important;
  }
  .mobile-lg\:margin-y-neg-2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .mobile-lg\:margin-top-neg-2 {
    margin-top: -1rem !important;
  }
  .mobile-lg\:margin-bottom-neg-2 {
    margin-bottom: -1rem !important;
  }
  .mobile-lg\:margin-y-neg-205 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .mobile-lg\:margin-top-neg-205 {
    margin-top: -1.25rem !important;
  }
  .mobile-lg\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem !important;
  }
  .mobile-lg\:margin-y-neg-3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .mobile-lg\:margin-top-neg-3 {
    margin-top: -1.5rem !important;
  }
  .mobile-lg\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem !important;
  }
  .mobile-lg\:margin-y-neg-4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .mobile-lg\:margin-top-neg-4 {
    margin-top: -2rem !important;
  }
  .mobile-lg\:margin-bottom-neg-4 {
    margin-bottom: -2rem !important;
  }
  .mobile-lg\:margin-y-neg-5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .mobile-lg\:margin-top-neg-5 {
    margin-top: -2.5rem !important;
  }
  .mobile-lg\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem !important;
  }
  .mobile-lg\:margin-y-neg-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mobile-lg\:margin-top-neg-6 {
    margin-top: -3rem !important;
  }
  .mobile-lg\:margin-bottom-neg-6 {
    margin-bottom: -3rem !important;
  }
  .mobile-lg\:margin-y-neg-7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .mobile-lg\:margin-top-neg-7 {
    margin-top: -3.5rem !important;
  }
  .mobile-lg\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem !important;
  }
  .mobile-lg\:margin-y-neg-8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .mobile-lg\:margin-top-neg-8 {
    margin-top: -4rem !important;
  }
  .mobile-lg\:margin-bottom-neg-8 {
    margin-bottom: -4rem !important;
  }
  .mobile-lg\:margin-y-neg-9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mobile-lg\:margin-top-neg-9 {
    margin-top: -4.5rem !important;
  }
  .mobile-lg\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem !important;
  }
  .mobile-lg\:margin-y-neg-10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .mobile-lg\:margin-top-neg-10 {
    margin-top: -5rem !important;
  }
  .mobile-lg\:margin-bottom-neg-10 {
    margin-bottom: -5rem !important;
  }
  .mobile-lg\:margin-y-neg-15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .mobile-lg\:margin-top-neg-15 {
    margin-top: -7.5rem !important;
  }
  .mobile-lg\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem !important;
  }
  .mobile-lg\:margin-y-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .mobile-lg\:margin-top-4 {
    margin-top: 2rem !important;
  }
  .mobile-lg\:margin-bottom-4 {
    margin-bottom: 2rem !important;
  }
  .mobile-lg\:margin-y-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .mobile-lg\:margin-top-5 {
    margin-top: 2.5rem !important;
  }
  .mobile-lg\:margin-bottom-5 {
    margin-bottom: 2.5rem !important;
  }
  .mobile-lg\:margin-y-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .mobile-lg\:margin-top-6 {
    margin-top: 3rem !important;
  }
  .mobile-lg\:margin-bottom-6 {
    margin-bottom: 3rem !important;
  }
  .mobile-lg\:margin-y-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .mobile-lg\:margin-top-7 {
    margin-top: 3.5rem !important;
  }
  .mobile-lg\:margin-bottom-7 {
    margin-bottom: 3.5rem !important;
  }
  .mobile-lg\:margin-y-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .mobile-lg\:margin-top-8 {
    margin-top: 4rem !important;
  }
  .mobile-lg\:margin-bottom-8 {
    margin-bottom: 4rem !important;
  }
  .mobile-lg\:margin-y-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .mobile-lg\:margin-top-9 {
    margin-top: 4.5rem !important;
  }
  .mobile-lg\:margin-bottom-9 {
    margin-bottom: 4.5rem !important;
  }
  .mobile-lg\:margin-y-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .mobile-lg\:margin-top-10 {
    margin-top: 5rem !important;
  }
  .mobile-lg\:margin-bottom-10 {
    margin-bottom: 5rem !important;
  }
  .mobile-lg\:margin-y-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .mobile-lg\:margin-top-15 {
    margin-top: 7.5rem !important;
  }
  .mobile-lg\:margin-bottom-15 {
    margin-bottom: 7.5rem !important;
  }
  .mobile-lg\:margin-y-05em {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }
  .mobile-lg\:margin-top-05em {
    margin-top: 0.5em !important;
  }
  .mobile-lg\:margin-bottom-05em {
    margin-bottom: 0.5em !important;
  }
  .mobile-lg\:margin-y-1em {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }
  .mobile-lg\:margin-top-1em {
    margin-top: 1em !important;
  }
  .mobile-lg\:margin-bottom-1em {
    margin-bottom: 1em !important;
  }
  .mobile-lg\:margin-y-105em {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }
  .mobile-lg\:margin-top-105em {
    margin-top: 1.5em !important;
  }
  .mobile-lg\:margin-bottom-105em {
    margin-bottom: 1.5em !important;
  }
  .mobile-lg\:margin-y-2em {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }
  .mobile-lg\:margin-top-2em {
    margin-top: 2em !important;
  }
  .mobile-lg\:margin-bottom-2em {
    margin-bottom: 2em !important;
  }
  .mobile-lg\:margin-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .mobile-lg\:margin-top-0 {
    margin-top: 0 !important;
  }
  .mobile-lg\:margin-bottom-0 {
    margin-bottom: 0 !important;
  }
  .mobile-lg\:margin-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mobile-lg\:margin-top-auto {
    margin-top: auto !important;
  }
  .mobile-lg\:margin-bottom-auto {
    margin-bottom: auto !important;
  }
  .mobile-lg\:margin-x-1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .mobile-lg\:margin-right-1px {
    margin-right: 1px !important;
  }
  .mobile-lg\:margin-left-1px {
    margin-left: 1px !important;
  }
  .mobile-lg\:margin-x-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .mobile-lg\:margin-right-2px {
    margin-right: 2px !important;
  }
  .mobile-lg\:margin-left-2px {
    margin-left: 2px !important;
  }
  .mobile-lg\:margin-x-05 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mobile-lg\:margin-right-05 {
    margin-right: 0.25rem !important;
  }
  .mobile-lg\:margin-left-05 {
    margin-left: 0.25rem !important;
  }
  .mobile-lg\:margin-x-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mobile-lg\:margin-right-1 {
    margin-right: 0.5rem !important;
  }
  .mobile-lg\:margin-left-1 {
    margin-left: 0.5rem !important;
  }
  .mobile-lg\:margin-x-105 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mobile-lg\:margin-right-105 {
    margin-right: 0.75rem !important;
  }
  .mobile-lg\:margin-left-105 {
    margin-left: 0.75rem !important;
  }
  .mobile-lg\:margin-x-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mobile-lg\:margin-right-2 {
    margin-right: 1rem !important;
  }
  .mobile-lg\:margin-left-2 {
    margin-left: 1rem !important;
  }
  .mobile-lg\:margin-x-205 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mobile-lg\:margin-right-205 {
    margin-right: 1.25rem !important;
  }
  .mobile-lg\:margin-left-205 {
    margin-left: 1.25rem !important;
  }
  .mobile-lg\:margin-x-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mobile-lg\:margin-right-3 {
    margin-right: 1.5rem !important;
  }
  .mobile-lg\:margin-left-3 {
    margin-left: 1.5rem !important;
  }
  .mobile-lg\:margin-x-neg-1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }
  .mobile-lg\:margin-right-neg-1px {
    margin-right: -1px !important;
  }
  .mobile-lg\:margin-left-neg-1px {
    margin-left: -1px !important;
  }
  .mobile-lg\:margin-x-neg-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }
  .mobile-lg\:margin-right-neg-2px {
    margin-right: -2px !important;
  }
  .mobile-lg\:margin-left-neg-2px {
    margin-left: -2px !important;
  }
  .mobile-lg\:margin-x-neg-05 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mobile-lg\:margin-right-neg-05 {
    margin-right: -0.25rem !important;
  }
  .mobile-lg\:margin-left-neg-05 {
    margin-left: -0.25rem !important;
  }
  .mobile-lg\:margin-x-neg-1 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mobile-lg\:margin-right-neg-1 {
    margin-right: -0.5rem !important;
  }
  .mobile-lg\:margin-left-neg-1 {
    margin-left: -0.5rem !important;
  }
  .mobile-lg\:margin-x-neg-105 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mobile-lg\:margin-right-neg-105 {
    margin-right: -0.75rem !important;
  }
  .mobile-lg\:margin-left-neg-105 {
    margin-left: -0.75rem !important;
  }
  .mobile-lg\:margin-x-neg-2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mobile-lg\:margin-right-neg-2 {
    margin-right: -1rem !important;
  }
  .mobile-lg\:margin-left-neg-2 {
    margin-left: -1rem !important;
  }
  .mobile-lg\:margin-x-neg-205 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mobile-lg\:margin-right-neg-205 {
    margin-right: -1.25rem !important;
  }
  .mobile-lg\:margin-left-neg-205 {
    margin-left: -1.25rem !important;
  }
  .mobile-lg\:margin-x-neg-3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mobile-lg\:margin-right-neg-3 {
    margin-right: -1.5rem !important;
  }
  .mobile-lg\:margin-left-neg-3 {
    margin-left: -1.5rem !important;
  }
  .mobile-lg\:margin-x-neg-4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mobile-lg\:margin-right-neg-4 {
    margin-right: -2rem !important;
  }
  .mobile-lg\:margin-left-neg-4 {
    margin-left: -2rem !important;
  }
  .mobile-lg\:margin-x-neg-5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mobile-lg\:margin-right-neg-5 {
    margin-right: -2.5rem !important;
  }
  .mobile-lg\:margin-left-neg-5 {
    margin-left: -2.5rem !important;
  }
  .mobile-lg\:margin-x-neg-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mobile-lg\:margin-right-neg-6 {
    margin-right: -3rem !important;
  }
  .mobile-lg\:margin-left-neg-6 {
    margin-left: -3rem !important;
  }
  .mobile-lg\:margin-x-neg-7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mobile-lg\:margin-right-neg-7 {
    margin-right: -3.5rem !important;
  }
  .mobile-lg\:margin-left-neg-7 {
    margin-left: -3.5rem !important;
  }
  .mobile-lg\:margin-x-neg-8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mobile-lg\:margin-right-neg-8 {
    margin-right: -4rem !important;
  }
  .mobile-lg\:margin-left-neg-8 {
    margin-left: -4rem !important;
  }
  .mobile-lg\:margin-x-neg-9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mobile-lg\:margin-right-neg-9 {
    margin-right: -4.5rem !important;
  }
  .mobile-lg\:margin-left-neg-9 {
    margin-left: -4.5rem !important;
  }
  .mobile-lg\:margin-x-neg-10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mobile-lg\:margin-right-neg-10 {
    margin-right: -5rem !important;
  }
  .mobile-lg\:margin-left-neg-10 {
    margin-left: -5rem !important;
  }
  .mobile-lg\:margin-x-neg-15 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }
  .mobile-lg\:margin-right-neg-15 {
    margin-right: -7.5rem !important;
  }
  .mobile-lg\:margin-left-neg-15 {
    margin-left: -7.5rem !important;
  }
  .mobile-lg\:margin-x-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mobile-lg\:margin-right-4 {
    margin-right: 2rem !important;
  }
  .mobile-lg\:margin-left-4 {
    margin-left: 2rem !important;
  }
  .mobile-lg\:margin-x-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mobile-lg\:margin-right-5 {
    margin-right: 2.5rem !important;
  }
  .mobile-lg\:margin-left-5 {
    margin-left: 2.5rem !important;
  }
  .mobile-lg\:margin-x-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mobile-lg\:margin-right-6 {
    margin-right: 3rem !important;
  }
  .mobile-lg\:margin-left-6 {
    margin-left: 3rem !important;
  }
  .mobile-lg\:margin-x-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mobile-lg\:margin-right-7 {
    margin-right: 3.5rem !important;
  }
  .mobile-lg\:margin-left-7 {
    margin-left: 3.5rem !important;
  }
  .mobile-lg\:margin-x-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mobile-lg\:margin-right-8 {
    margin-right: 4rem !important;
  }
  .mobile-lg\:margin-left-8 {
    margin-left: 4rem !important;
  }
  .mobile-lg\:margin-x-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mobile-lg\:margin-right-9 {
    margin-right: 4.5rem !important;
  }
  .mobile-lg\:margin-left-9 {
    margin-left: 4.5rem !important;
  }
  .mobile-lg\:margin-x-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mobile-lg\:margin-right-10 {
    margin-right: 5rem !important;
  }
  .mobile-lg\:margin-left-10 {
    margin-left: 5rem !important;
  }
  .mobile-lg\:margin-x-15 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }
  .mobile-lg\:margin-right-15 {
    margin-right: 7.5rem !important;
  }
  .mobile-lg\:margin-left-15 {
    margin-left: 7.5rem !important;
  }
  .mobile-lg\:margin-x-card {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mobile-lg\:margin-right-card {
    margin-right: 10rem !important;
  }
  .mobile-lg\:margin-left-card {
    margin-left: 10rem !important;
  }
  .mobile-lg\:margin-x-card-lg {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }
  .mobile-lg\:margin-right-card-lg {
    margin-right: 15rem !important;
  }
  .mobile-lg\:margin-left-card-lg {
    margin-left: 15rem !important;
  }
  .mobile-lg\:margin-x-mobile {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }
  .mobile-lg\:margin-right-mobile {
    margin-right: 20rem !important;
  }
  .mobile-lg\:margin-left-mobile {
    margin-left: 20rem !important;
  }
  .mobile-lg\:margin-x-05em {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
  }
  .mobile-lg\:margin-right-05em {
    margin-right: 0.5em !important;
  }
  .mobile-lg\:margin-left-05em {
    margin-left: 0.5em !important;
  }
  .mobile-lg\:margin-x-1em {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }
  .mobile-lg\:margin-right-1em {
    margin-right: 1em !important;
  }
  .mobile-lg\:margin-left-1em {
    margin-left: 1em !important;
  }
  .mobile-lg\:margin-x-105em {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }
  .mobile-lg\:margin-right-105em {
    margin-right: 1.5em !important;
  }
  .mobile-lg\:margin-left-105em {
    margin-left: 1.5em !important;
  }
  .mobile-lg\:margin-x-2em {
    margin-left: 2em !important;
    margin-right: 2em !important;
  }
  .mobile-lg\:margin-right-2em {
    margin-right: 2em !important;
  }
  .mobile-lg\:margin-left-2em {
    margin-left: 2em !important;
  }
  .mobile-lg\:margin-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mobile-lg\:margin-right-0 {
    margin-right: 0 !important;
  }
  .mobile-lg\:margin-left-0 {
    margin-left: 0 !important;
  }
  .mobile-lg\:margin-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .mobile-lg\:margin-right-auto {
    margin-right: auto !important;
  }
  .mobile-lg\:margin-left-auto {
    margin-left: auto !important;
  }
  .mobile-lg\:measure-1 {
    max-width: 44ex !important;
  }
  .mobile-lg\:measure-2 {
    max-width: 60ex !important;
  }
  .mobile-lg\:measure-3 {
    max-width: 64ex !important;
  }
  .mobile-lg\:measure-4 {
    max-width: 68ex !important;
  }
  .mobile-lg\:measure-5 {
    max-width: 72ex !important;
  }
  .mobile-lg\:measure-6 {
    max-width: 88ex !important;
  }
  .mobile-lg\:measure-none {
    max-width: none !important;
  }
  .mobile-lg\:order-first {
    order: -1 !important;
  }
  .mobile-lg\:order-last {
    order: 999 !important;
  }
  .mobile-lg\:order-initial {
    order: initial !important;
  }
  .mobile-lg\:order-0 {
    order: 0 !important;
  }
  .mobile-lg\:order-1 {
    order: 1 !important;
  }
  .mobile-lg\:order-2 {
    order: 2 !important;
  }
  .mobile-lg\:order-3 {
    order: 3 !important;
  }
  .mobile-lg\:order-4 {
    order: 4 !important;
  }
  .mobile-lg\:order-5 {
    order: 5 !important;
  }
  .mobile-lg\:order-6 {
    order: 6 !important;
  }
  .mobile-lg\:order-7 {
    order: 7 !important;
  }
  .mobile-lg\:order-8 {
    order: 8 !important;
  }
  .mobile-lg\:order-9 {
    order: 9 !important;
  }
  .mobile-lg\:order-10 {
    order: 10 !important;
  }
  .mobile-lg\:order-11 {
    order: 11 !important;
  }
  .mobile-lg\:padding-1px {
    padding: 1px !important;
  }
  .mobile-lg\:padding-y-1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .mobile-lg\:padding-x-1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .mobile-lg\:padding-top-1px {
    padding-top: 1px !important;
  }
  .mobile-lg\:padding-right-1px {
    padding-right: 1px !important;
  }
  .mobile-lg\:padding-bottom-1px {
    padding-bottom: 1px !important;
  }
  .mobile-lg\:padding-left-1px {
    padding-left: 1px !important;
  }
  .mobile-lg\:padding-2px {
    padding: 2px !important;
  }
  .mobile-lg\:padding-y-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .mobile-lg\:padding-x-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .mobile-lg\:padding-top-2px {
    padding-top: 2px !important;
  }
  .mobile-lg\:padding-right-2px {
    padding-right: 2px !important;
  }
  .mobile-lg\:padding-bottom-2px {
    padding-bottom: 2px !important;
  }
  .mobile-lg\:padding-left-2px {
    padding-left: 2px !important;
  }
  .mobile-lg\:padding-05 {
    padding: 0.25rem !important;
  }
  .mobile-lg\:padding-y-05 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .mobile-lg\:padding-x-05 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .mobile-lg\:padding-top-05 {
    padding-top: 0.25rem !important;
  }
  .mobile-lg\:padding-right-05 {
    padding-right: 0.25rem !important;
  }
  .mobile-lg\:padding-bottom-05 {
    padding-bottom: 0.25rem !important;
  }
  .mobile-lg\:padding-left-05 {
    padding-left: 0.25rem !important;
  }
  .mobile-lg\:padding-1 {
    padding: 0.5rem !important;
  }
  .mobile-lg\:padding-y-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .mobile-lg\:padding-x-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .mobile-lg\:padding-top-1 {
    padding-top: 0.5rem !important;
  }
  .mobile-lg\:padding-right-1 {
    padding-right: 0.5rem !important;
  }
  .mobile-lg\:padding-bottom-1 {
    padding-bottom: 0.5rem !important;
  }
  .mobile-lg\:padding-left-1 {
    padding-left: 0.5rem !important;
  }
  .mobile-lg\:padding-105 {
    padding: 0.75rem !important;
  }
  .mobile-lg\:padding-y-105 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .mobile-lg\:padding-x-105 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .mobile-lg\:padding-top-105 {
    padding-top: 0.75rem !important;
  }
  .mobile-lg\:padding-right-105 {
    padding-right: 0.75rem !important;
  }
  .mobile-lg\:padding-bottom-105 {
    padding-bottom: 0.75rem !important;
  }
  .mobile-lg\:padding-left-105 {
    padding-left: 0.75rem !important;
  }
  .mobile-lg\:padding-2 {
    padding: 1rem !important;
  }
  .mobile-lg\:padding-y-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .mobile-lg\:padding-x-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .mobile-lg\:padding-top-2 {
    padding-top: 1rem !important;
  }
  .mobile-lg\:padding-right-2 {
    padding-right: 1rem !important;
  }
  .mobile-lg\:padding-bottom-2 {
    padding-bottom: 1rem !important;
  }
  .mobile-lg\:padding-left-2 {
    padding-left: 1rem !important;
  }
  .mobile-lg\:padding-205 {
    padding: 1.25rem !important;
  }
  .mobile-lg\:padding-y-205 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .mobile-lg\:padding-x-205 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .mobile-lg\:padding-top-205 {
    padding-top: 1.25rem !important;
  }
  .mobile-lg\:padding-right-205 {
    padding-right: 1.25rem !important;
  }
  .mobile-lg\:padding-bottom-205 {
    padding-bottom: 1.25rem !important;
  }
  .mobile-lg\:padding-left-205 {
    padding-left: 1.25rem !important;
  }
  .mobile-lg\:padding-3 {
    padding: 1.5rem !important;
  }
  .mobile-lg\:padding-y-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .mobile-lg\:padding-x-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .mobile-lg\:padding-top-3 {
    padding-top: 1.5rem !important;
  }
  .mobile-lg\:padding-right-3 {
    padding-right: 1.5rem !important;
  }
  .mobile-lg\:padding-bottom-3 {
    padding-bottom: 1.5rem !important;
  }
  .mobile-lg\:padding-left-3 {
    padding-left: 1.5rem !important;
  }
  .mobile-lg\:padding-4 {
    padding: 2rem !important;
  }
  .mobile-lg\:padding-y-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .mobile-lg\:padding-x-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .mobile-lg\:padding-top-4 {
    padding-top: 2rem !important;
  }
  .mobile-lg\:padding-right-4 {
    padding-right: 2rem !important;
  }
  .mobile-lg\:padding-bottom-4 {
    padding-bottom: 2rem !important;
  }
  .mobile-lg\:padding-left-4 {
    padding-left: 2rem !important;
  }
  .mobile-lg\:padding-5 {
    padding: 2.5rem !important;
  }
  .mobile-lg\:padding-y-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .mobile-lg\:padding-x-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .mobile-lg\:padding-top-5 {
    padding-top: 2.5rem !important;
  }
  .mobile-lg\:padding-right-5 {
    padding-right: 2.5rem !important;
  }
  .mobile-lg\:padding-bottom-5 {
    padding-bottom: 2.5rem !important;
  }
  .mobile-lg\:padding-left-5 {
    padding-left: 2.5rem !important;
  }
  .mobile-lg\:padding-6 {
    padding: 3rem !important;
  }
  .mobile-lg\:padding-y-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .mobile-lg\:padding-x-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .mobile-lg\:padding-top-6 {
    padding-top: 3rem !important;
  }
  .mobile-lg\:padding-right-6 {
    padding-right: 3rem !important;
  }
  .mobile-lg\:padding-bottom-6 {
    padding-bottom: 3rem !important;
  }
  .mobile-lg\:padding-left-6 {
    padding-left: 3rem !important;
  }
  .mobile-lg\:padding-7 {
    padding: 3.5rem !important;
  }
  .mobile-lg\:padding-y-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .mobile-lg\:padding-x-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .mobile-lg\:padding-top-7 {
    padding-top: 3.5rem !important;
  }
  .mobile-lg\:padding-right-7 {
    padding-right: 3.5rem !important;
  }
  .mobile-lg\:padding-bottom-7 {
    padding-bottom: 3.5rem !important;
  }
  .mobile-lg\:padding-left-7 {
    padding-left: 3.5rem !important;
  }
  .mobile-lg\:padding-8 {
    padding: 4rem !important;
  }
  .mobile-lg\:padding-y-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .mobile-lg\:padding-x-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .mobile-lg\:padding-top-8 {
    padding-top: 4rem !important;
  }
  .mobile-lg\:padding-right-8 {
    padding-right: 4rem !important;
  }
  .mobile-lg\:padding-bottom-8 {
    padding-bottom: 4rem !important;
  }
  .mobile-lg\:padding-left-8 {
    padding-left: 4rem !important;
  }
  .mobile-lg\:padding-9 {
    padding: 4.5rem !important;
  }
  .mobile-lg\:padding-y-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .mobile-lg\:padding-x-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .mobile-lg\:padding-top-9 {
    padding-top: 4.5rem !important;
  }
  .mobile-lg\:padding-right-9 {
    padding-right: 4.5rem !important;
  }
  .mobile-lg\:padding-bottom-9 {
    padding-bottom: 4.5rem !important;
  }
  .mobile-lg\:padding-left-9 {
    padding-left: 4.5rem !important;
  }
  .mobile-lg\:padding-10 {
    padding: 5rem !important;
  }
  .mobile-lg\:padding-y-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .mobile-lg\:padding-x-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .mobile-lg\:padding-top-10 {
    padding-top: 5rem !important;
  }
  .mobile-lg\:padding-right-10 {
    padding-right: 5rem !important;
  }
  .mobile-lg\:padding-bottom-10 {
    padding-bottom: 5rem !important;
  }
  .mobile-lg\:padding-left-10 {
    padding-left: 5rem !important;
  }
  .mobile-lg\:padding-15 {
    padding: 7.5rem !important;
  }
  .mobile-lg\:padding-y-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .mobile-lg\:padding-x-15 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .mobile-lg\:padding-top-15 {
    padding-top: 7.5rem !important;
  }
  .mobile-lg\:padding-right-15 {
    padding-right: 7.5rem !important;
  }
  .mobile-lg\:padding-bottom-15 {
    padding-bottom: 7.5rem !important;
  }
  .mobile-lg\:padding-left-15 {
    padding-left: 7.5rem !important;
  }
  .mobile-lg\:padding-0 {
    padding: 0 !important;
  }
  .mobile-lg\:padding-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .mobile-lg\:padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .mobile-lg\:padding-top-0 {
    padding-top: 0 !important;
  }
  .mobile-lg\:padding-right-0 {
    padding-right: 0 !important;
  }
  .mobile-lg\:padding-bottom-0 {
    padding-bottom: 0 !important;
  }
  .mobile-lg\:padding-left-0 {
    padding-left: 0 !important;
  }
  .mobile-lg\:width-1px {
    width: 1px !important;
  }
  .mobile-lg\:width-2px {
    width: 2px !important;
  }
  .mobile-lg\:width-05 {
    width: 0.25rem !important;
  }
  .mobile-lg\:width-1 {
    width: 0.5rem !important;
  }
  .mobile-lg\:width-105 {
    width: 0.75rem !important;
  }
  .mobile-lg\:width-2 {
    width: 1rem !important;
  }
  .mobile-lg\:width-205 {
    width: 1.25rem !important;
  }
  .mobile-lg\:width-3 {
    width: 1.5rem !important;
  }
  .mobile-lg\:width-4 {
    width: 2rem !important;
  }
  .mobile-lg\:width-5 {
    width: 2.5rem !important;
  }
  .mobile-lg\:width-6 {
    width: 3rem !important;
  }
  .mobile-lg\:width-7 {
    width: 3.5rem !important;
  }
  .mobile-lg\:width-8 {
    width: 4rem !important;
  }
  .mobile-lg\:width-9 {
    width: 4.5rem !important;
  }
  .mobile-lg\:width-10 {
    width: 5rem !important;
  }
  .mobile-lg\:width-15 {
    width: 7.5rem !important;
  }
  .mobile-lg\:width-card {
    width: 10rem !important;
  }
  .mobile-lg\:width-card-lg {
    width: 15rem !important;
  }
  .mobile-lg\:width-mobile {
    width: 20rem !important;
  }
  .mobile-lg\:width-mobile-lg {
    width: 30rem !important;
  }
  .mobile-lg\:width-tablet {
    width: 40rem !important;
  }
  .mobile-lg\:width-tablet-lg {
    width: 55rem !important;
  }
  .mobile-lg\:width-desktop {
    width: 64rem !important;
  }
  .mobile-lg\:width-desktop-lg {
    width: 75rem !important;
  }
  .mobile-lg\:width-widescreen {
    width: 87.5rem !important;
  }
  .mobile-lg\:width-0 {
    width: 0 !important;
  }
  .mobile-lg\:width-full {
    width: 100% !important;
  }
  .mobile-lg\:width-auto {
    width: auto !important;
  }
}
@media all and (min-width: 40em) {
  .tablet\:border-1px {
    border: 1px solid !important;
  }
  .tablet\:hover\:border-1px:hover {
    border: 1px solid !important;
  }
  .tablet\:border-y-1px {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }
  .tablet\:hover\:border-y-1px:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }
  .tablet\:border-x-1px {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }
  .tablet\:hover\:border-x-1px:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }
  .tablet\:border-top-1px {
    border-top: 1px solid !important;
  }
  .tablet\:hover\:border-top-1px:hover {
    border-top: 1px solid !important;
  }
  .tablet\:border-right-1px {
    border-right: 1px solid !important;
  }
  .tablet\:hover\:border-right-1px:hover {
    border-right: 1px solid !important;
  }
  .tablet\:border-bottom-1px {
    border-bottom: 1px solid !important;
  }
  .tablet\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid !important;
  }
  .tablet\:border-left-1px {
    border-left: 1px solid !important;
  }
  .tablet\:hover\:border-left-1px:hover {
    border-left: 1px solid !important;
  }
  .tablet\:border-2px {
    border: 2px solid !important;
  }
  .tablet\:hover\:border-2px:hover {
    border: 2px solid !important;
  }
  .tablet\:border-y-2px {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }
  .tablet\:hover\:border-y-2px:hover {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }
  .tablet\:border-x-2px {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }
  .tablet\:hover\:border-x-2px:hover {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }
  .tablet\:border-top-2px {
    border-top: 2px solid !important;
  }
  .tablet\:hover\:border-top-2px:hover {
    border-top: 2px solid !important;
  }
  .tablet\:border-right-2px {
    border-right: 2px solid !important;
  }
  .tablet\:hover\:border-right-2px:hover {
    border-right: 2px solid !important;
  }
  .tablet\:border-bottom-2px {
    border-bottom: 2px solid !important;
  }
  .tablet\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid !important;
  }
  .tablet\:border-left-2px {
    border-left: 2px solid !important;
  }
  .tablet\:hover\:border-left-2px:hover {
    border-left: 2px solid !important;
  }
  .tablet\:border-05 {
    border: 0.25rem solid !important;
  }
  .tablet\:hover\:border-05:hover {
    border: 0.25rem solid !important;
  }
  .tablet\:border-y-05 {
    border-top: 0.25rem solid !important;
    border-bottom: 0.25rem solid !important;
  }
  .tablet\:hover\:border-y-05:hover {
    border-top: 0.25rem solid !important;
    border-bottom: 0.25rem solid !important;
  }
  .tablet\:border-x-05 {
    border-left: 0.25rem solid !important;
    border-right: 0.25rem solid !important;
  }
  .tablet\:hover\:border-x-05:hover {
    border-left: 0.25rem solid !important;
    border-right: 0.25rem solid !important;
  }
  .tablet\:border-top-05 {
    border-top: 0.25rem solid !important;
  }
  .tablet\:hover\:border-top-05:hover {
    border-top: 0.25rem solid !important;
  }
  .tablet\:border-right-05 {
    border-right: 0.25rem solid !important;
  }
  .tablet\:hover\:border-right-05:hover {
    border-right: 0.25rem solid !important;
  }
  .tablet\:border-bottom-05 {
    border-bottom: 0.25rem solid !important;
  }
  .tablet\:hover\:border-bottom-05:hover {
    border-bottom: 0.25rem solid !important;
  }
  .tablet\:border-left-05 {
    border-left: 0.25rem solid !important;
  }
  .tablet\:hover\:border-left-05:hover {
    border-left: 0.25rem solid !important;
  }
  .tablet\:border-1 {
    border: 0.5rem solid !important;
  }
  .tablet\:hover\:border-1:hover {
    border: 0.5rem solid !important;
  }
  .tablet\:border-y-1 {
    border-top: 0.5rem solid !important;
    border-bottom: 0.5rem solid !important;
  }
  .tablet\:hover\:border-y-1:hover {
    border-top: 0.5rem solid !important;
    border-bottom: 0.5rem solid !important;
  }
  .tablet\:border-x-1 {
    border-left: 0.5rem solid !important;
    border-right: 0.5rem solid !important;
  }
  .tablet\:hover\:border-x-1:hover {
    border-left: 0.5rem solid !important;
    border-right: 0.5rem solid !important;
  }
  .tablet\:border-top-1 {
    border-top: 0.5rem solid !important;
  }
  .tablet\:hover\:border-top-1:hover {
    border-top: 0.5rem solid !important;
  }
  .tablet\:border-right-1 {
    border-right: 0.5rem solid !important;
  }
  .tablet\:hover\:border-right-1:hover {
    border-right: 0.5rem solid !important;
  }
  .tablet\:border-bottom-1 {
    border-bottom: 0.5rem solid !important;
  }
  .tablet\:hover\:border-bottom-1:hover {
    border-bottom: 0.5rem solid !important;
  }
  .tablet\:border-left-1 {
    border-left: 0.5rem solid !important;
  }
  .tablet\:hover\:border-left-1:hover {
    border-left: 0.5rem solid !important;
  }
  .tablet\:border-105 {
    border: 0.75rem solid !important;
  }
  .tablet\:hover\:border-105:hover {
    border: 0.75rem solid !important;
  }
  .tablet\:border-y-105 {
    border-top: 0.75rem solid !important;
    border-bottom: 0.75rem solid !important;
  }
  .tablet\:hover\:border-y-105:hover {
    border-top: 0.75rem solid !important;
    border-bottom: 0.75rem solid !important;
  }
  .tablet\:border-x-105 {
    border-left: 0.75rem solid !important;
    border-right: 0.75rem solid !important;
  }
  .tablet\:hover\:border-x-105:hover {
    border-left: 0.75rem solid !important;
    border-right: 0.75rem solid !important;
  }
  .tablet\:border-top-105 {
    border-top: 0.75rem solid !important;
  }
  .tablet\:hover\:border-top-105:hover {
    border-top: 0.75rem solid !important;
  }
  .tablet\:border-right-105 {
    border-right: 0.75rem solid !important;
  }
  .tablet\:hover\:border-right-105:hover {
    border-right: 0.75rem solid !important;
  }
  .tablet\:border-bottom-105 {
    border-bottom: 0.75rem solid !important;
  }
  .tablet\:hover\:border-bottom-105:hover {
    border-bottom: 0.75rem solid !important;
  }
  .tablet\:border-left-105 {
    border-left: 0.75rem solid !important;
  }
  .tablet\:hover\:border-left-105:hover {
    border-left: 0.75rem solid !important;
  }
  .tablet\:border-2 {
    border: 1rem solid !important;
  }
  .tablet\:hover\:border-2:hover {
    border: 1rem solid !important;
  }
  .tablet\:border-y-2 {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }
  .tablet\:hover\:border-y-2:hover {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }
  .tablet\:border-x-2 {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }
  .tablet\:hover\:border-x-2:hover {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }
  .tablet\:border-top-2 {
    border-top: 1rem solid !important;
  }
  .tablet\:hover\:border-top-2:hover {
    border-top: 1rem solid !important;
  }
  .tablet\:border-right-2 {
    border-right: 1rem solid !important;
  }
  .tablet\:hover\:border-right-2:hover {
    border-right: 1rem solid !important;
  }
  .tablet\:border-bottom-2 {
    border-bottom: 1rem solid !important;
  }
  .tablet\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid !important;
  }
  .tablet\:border-left-2 {
    border-left: 1rem solid !important;
  }
  .tablet\:hover\:border-left-2:hover {
    border-left: 1rem solid !important;
  }
  .tablet\:border-205 {
    border: 1.25rem solid !important;
  }
  .tablet\:hover\:border-205:hover {
    border: 1.25rem solid !important;
  }
  .tablet\:border-y-205 {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }
  .tablet\:hover\:border-y-205:hover {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }
  .tablet\:border-x-205 {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }
  .tablet\:hover\:border-x-205:hover {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }
  .tablet\:border-top-205 {
    border-top: 1.25rem solid !important;
  }
  .tablet\:hover\:border-top-205:hover {
    border-top: 1.25rem solid !important;
  }
  .tablet\:border-right-205 {
    border-right: 1.25rem solid !important;
  }
  .tablet\:hover\:border-right-205:hover {
    border-right: 1.25rem solid !important;
  }
  .tablet\:border-bottom-205 {
    border-bottom: 1.25rem solid !important;
  }
  .tablet\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid !important;
  }
  .tablet\:border-left-205 {
    border-left: 1.25rem solid !important;
  }
  .tablet\:hover\:border-left-205:hover {
    border-left: 1.25rem solid !important;
  }
  .tablet\:border-3 {
    border: 1.5rem solid !important;
  }
  .tablet\:hover\:border-3:hover {
    border: 1.5rem solid !important;
  }
  .tablet\:border-y-3 {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }
  .tablet\:hover\:border-y-3:hover {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }
  .tablet\:border-x-3 {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }
  .tablet\:hover\:border-x-3:hover {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }
  .tablet\:border-top-3 {
    border-top: 1.5rem solid !important;
  }
  .tablet\:hover\:border-top-3:hover {
    border-top: 1.5rem solid !important;
  }
  .tablet\:border-right-3 {
    border-right: 1.5rem solid !important;
  }
  .tablet\:hover\:border-right-3:hover {
    border-right: 1.5rem solid !important;
  }
  .tablet\:border-bottom-3 {
    border-bottom: 1.5rem solid !important;
  }
  .tablet\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid !important;
  }
  .tablet\:border-left-3 {
    border-left: 1.5rem solid !important;
  }
  .tablet\:hover\:border-left-3:hover {
    border-left: 1.5rem solid !important;
  }
  .tablet\:border-0 {
    border: 0 solid !important;
  }
  .tablet\:hover\:border-0:hover {
    border: 0 solid !important;
  }
  .tablet\:border-y-0 {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }
  .tablet\:hover\:border-y-0:hover {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }
  .tablet\:border-x-0 {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }
  .tablet\:hover\:border-x-0:hover {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }
  .tablet\:border-top-0 {
    border-top: 0 solid !important;
  }
  .tablet\:hover\:border-top-0:hover {
    border-top: 0 solid !important;
  }
  .tablet\:border-right-0 {
    border-right: 0 solid !important;
  }
  .tablet\:hover\:border-right-0:hover {
    border-right: 0 solid !important;
  }
  .tablet\:border-bottom-0 {
    border-bottom: 0 solid !important;
  }
  .tablet\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid !important;
  }
  .tablet\:border-left-0 {
    border-left: 0 solid !important;
  }
  .tablet\:hover\:border-left-0:hover {
    border-left: 0 solid !important;
  }
  .tablet\:border {
    border: 1px solid !important;
  }
  .tablet\:hover\:border:hover {
    border: 1px solid !important;
  }
  .tablet\:border-y {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }
  .tablet\:hover\:border-y:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }
  .tablet\:border-x {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }
  .tablet\:hover\:border-x:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }
  .tablet\:border-top {
    border-top: 1px solid !important;
  }
  .tablet\:hover\:border-top:hover {
    border-top: 1px solid !important;
  }
  .tablet\:border-right {
    border-right: 1px solid !important;
  }
  .tablet\:hover\:border-right:hover {
    border-right: 1px solid !important;
  }
  .tablet\:border-bottom {
    border-bottom: 1px solid !important;
  }
  .tablet\:hover\:border-bottom:hover {
    border-bottom: 1px solid !important;
  }
  .tablet\:border-left {
    border-left: 1px solid !important;
  }
  .tablet\:hover\:border-left:hover {
    border-left: 1px solid !important;
  }
  .tablet\:border-transparent {
    border-color: transparent !important;
  }
  .tablet\:hover\:border-transparent:hover {
    border-color: transparent !important;
  }
  .tablet\:border-black {
    border-color: black !important;
  }
  .tablet\:hover\:border-black:hover {
    border-color: black !important;
  }
  .tablet\:border-white {
    border-color: white !important;
  }
  .tablet\:hover\:border-white:hover {
    border-color: white !important;
  }
  .tablet\:border-red {
    border-color: #e52207 !important;
  }
  .tablet\:hover\:border-red:hover {
    border-color: #e52207 !important;
  }
  .tablet\:border-orange {
    border-color: #e66f0e !important;
  }
  .tablet\:hover\:border-orange:hover {
    border-color: #e66f0e !important;
  }
  .tablet\:border-gold {
    border-color: #ffbe2e !important;
  }
  .tablet\:hover\:border-gold:hover {
    border-color: #ffbe2e !important;
  }
  .tablet\:border-yellow {
    border-color: #fee685 !important;
  }
  .tablet\:hover\:border-yellow:hover {
    border-color: #fee685 !important;
  }
  .tablet\:border-green {
    border-color: #538200 !important;
  }
  .tablet\:hover\:border-green:hover {
    border-color: #538200 !important;
  }
  .tablet\:border-mint {
    border-color: #04c585 !important;
  }
  .tablet\:hover\:border-mint:hover {
    border-color: #04c585 !important;
  }
  .tablet\:border-cyan {
    border-color: #009ec1 !important;
  }
  .tablet\:hover\:border-cyan:hover {
    border-color: #009ec1 !important;
  }
  .tablet\:border-blue {
    border-color: #0076d6 !important;
  }
  .tablet\:hover\:border-blue:hover {
    border-color: #0076d6 !important;
  }
  .tablet\:border-indigo {
    border-color: #676cc8 !important;
  }
  .tablet\:hover\:border-indigo:hover {
    border-color: #676cc8 !important;
  }
  .tablet\:border-violet {
    border-color: #8168b3 !important;
  }
  .tablet\:hover\:border-violet:hover {
    border-color: #8168b3 !important;
  }
  .tablet\:border-magenta {
    border-color: #d72d79 !important;
  }
  .tablet\:hover\:border-magenta:hover {
    border-color: #d72d79 !important;
  }
  .tablet\:border-gray-5 {
    border-color: #f0f0f0 !important;
  }
  .tablet\:hover\:border-gray-5:hover {
    border-color: #f0f0f0 !important;
  }
  .tablet\:border-gray-10 {
    border-color: #e6e6e6 !important;
  }
  .tablet\:hover\:border-gray-10:hover {
    border-color: #e6e6e6 !important;
  }
  .tablet\:border-gray-30 {
    border-color: #adadad !important;
  }
  .tablet\:hover\:border-gray-30:hover {
    border-color: #adadad !important;
  }
  .tablet\:border-gray-50 {
    border-color: #757575 !important;
  }
  .tablet\:hover\:border-gray-50:hover {
    border-color: #757575 !important;
  }
  .tablet\:border-gray-70 {
    border-color: #454545 !important;
  }
  .tablet\:hover\:border-gray-70:hover {
    border-color: #454545 !important;
  }
  .tablet\:border-gray-90 {
    border-color: #1b1b1b !important;
  }
  .tablet\:hover\:border-gray-90:hover {
    border-color: #1b1b1b !important;
  }
  .tablet\:border-base-lightest {
    border-color: #f0f0f0 !important;
  }
  .tablet\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }
  .tablet\:border-base-lighter {
    border-color: #dfe1e2 !important;
  }
  .tablet\:hover\:border-base-lighter:hover {
    border-color: #dfe1e2 !important;
  }
  .tablet\:border-base-light {
    border-color: #a9aeb1 !important;
  }
  .tablet\:hover\:border-base-light:hover {
    border-color: #a9aeb1 !important;
  }
  .tablet\:border-base {
    border-color: #71767a !important;
  }
  .tablet\:hover\:border-base:hover {
    border-color: #71767a !important;
  }
  .tablet\:border-base-dark {
    border-color: #565c65 !important;
  }
  .tablet\:hover\:border-base-dark:hover {
    border-color: #565c65 !important;
  }
  .tablet\:border-base-darker {
    border-color: #3d4551 !important;
  }
  .tablet\:hover\:border-base-darker:hover {
    border-color: #3d4551 !important;
  }
  .tablet\:border-base-darkest {
    border-color: #2d2e2f !important;
  }
  .tablet\:hover\:border-base-darkest:hover {
    border-color: #2d2e2f !important;
  }
  .tablet\:border-ink {
    border-color: #1c1d1f !important;
  }
  .tablet\:hover\:border-ink:hover {
    border-color: #1c1d1f !important;
  }
  .tablet\:border-primary-lighter {
    border-color: #d9e8f6 !important;
  }
  .tablet\:hover\:border-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }
  .tablet\:border-primary-light {
    border-color: #73b3e7 !important;
  }
  .tablet\:hover\:border-primary-light:hover {
    border-color: #73b3e7 !important;
  }
  .tablet\:border-primary {
    border-color: #005ea2 !important;
  }
  .tablet\:hover\:border-primary:hover {
    border-color: #005ea2 !important;
  }
  .tablet\:border-primary-vivid {
    border-color: #0050d8 !important;
  }
  .tablet\:hover\:border-primary-vivid:hover {
    border-color: #0050d8 !important;
  }
  .tablet\:border-primary-dark {
    border-color: #1a4480 !important;
  }
  .tablet\:hover\:border-primary-dark:hover {
    border-color: #1a4480 !important;
  }
  .tablet\:border-primary-darker {
    border-color: #162e51 !important;
  }
  .tablet\:hover\:border-primary-darker:hover {
    border-color: #162e51 !important;
  }
  .tablet\:border-secondary-lighter {
    border-color: #f3e1e4 !important;
  }
  .tablet\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4 !important;
  }
  .tablet\:border-secondary-light {
    border-color: #f2938c !important;
  }
  .tablet\:hover\:border-secondary-light:hover {
    border-color: #f2938c !important;
  }
  .tablet\:border-secondary {
    border-color: #d83933 !important;
  }
  .tablet\:hover\:border-secondary:hover {
    border-color: #d83933 !important;
  }
  .tablet\:border-secondary-vivid {
    border-color: #e41d3d !important;
  }
  .tablet\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }
  .tablet\:border-secondary-dark {
    border-color: #b50909 !important;
  }
  .tablet\:hover\:border-secondary-dark:hover {
    border-color: #b50909 !important;
  }
  .tablet\:border-secondary-darker {
    border-color: #8b0a03 !important;
  }
  .tablet\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03 !important;
  }
  .tablet\:border-accent-warm-darker {
    border-color: #775540 !important;
  }
  .tablet\:hover\:border-accent-warm-darker:hover {
    border-color: #775540 !important;
  }
  .tablet\:border-accent-warm-dark {
    border-color: #c05600 !important;
  }
  .tablet\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }
  .tablet\:border-accent-warm {
    border-color: #fa9441 !important;
  }
  .tablet\:hover\:border-accent-warm:hover {
    border-color: #fa9441 !important;
  }
  .tablet\:border-accent-warm-light {
    border-color: #ffbc78 !important;
  }
  .tablet\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }
  .tablet\:border-accent-warm-lighter {
    border-color: #f2e4d4 !important;
  }
  .tablet\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }
  .tablet\:border-accent-cool-darker {
    border-color: #07648d !important;
  }
  .tablet\:hover\:border-accent-cool-darker:hover {
    border-color: #07648d !important;
  }
  .tablet\:border-accent-cool-dark {
    border-color: #28a0cb !important;
  }
  .tablet\:hover\:border-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }
  .tablet\:border-accent-cool {
    border-color: #00bde3 !important;
  }
  .tablet\:hover\:border-accent-cool:hover {
    border-color: #00bde3 !important;
  }
  .tablet\:border-accent-cool-light {
    border-color: #97d4ea !important;
  }
  .tablet\:hover\:border-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }
  .tablet\:border-accent-cool-lighter {
    border-color: #e1f3f8 !important;
  }
  .tablet\:hover\:border-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }
  .tablet\:border-error-lighter {
    border-color: #f4e3db !important;
  }
  .tablet\:hover\:border-error-lighter:hover {
    border-color: #f4e3db !important;
  }
  .tablet\:border-error-light {
    border-color: #f39268 !important;
  }
  .tablet\:hover\:border-error-light:hover {
    border-color: #f39268 !important;
  }
  .tablet\:border-error {
    border-color: #d54309 !important;
  }
  .tablet\:hover\:border-error:hover {
    border-color: #d54309 !important;
  }
  .tablet\:border-error-dark {
    border-color: #b50909 !important;
  }
  .tablet\:hover\:border-error-dark:hover {
    border-color: #b50909 !important;
  }
  .tablet\:border-error-darker {
    border-color: #6f3331 !important;
  }
  .tablet\:hover\:border-error-darker:hover {
    border-color: #6f3331 !important;
  }
  .tablet\:border-warning-lighter {
    border-color: #faf3d1 !important;
  }
  .tablet\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1 !important;
  }
  .tablet\:border-warning-light {
    border-color: #fee685 !important;
  }
  .tablet\:hover\:border-warning-light:hover {
    border-color: #fee685 !important;
  }
  .tablet\:border-warning {
    border-color: #ffbe2e !important;
  }
  .tablet\:hover\:border-warning:hover {
    border-color: #ffbe2e !important;
  }
  .tablet\:border-warning-dark {
    border-color: #e5a000 !important;
  }
  .tablet\:hover\:border-warning-dark:hover {
    border-color: #e5a000 !important;
  }
  .tablet\:border-warning-darker {
    border-color: #936f38 !important;
  }
  .tablet\:hover\:border-warning-darker:hover {
    border-color: #936f38 !important;
  }
  .tablet\:border-success-lighter {
    border-color: #ecf3ec !important;
  }
  .tablet\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec !important;
  }
  .tablet\:border-success-light {
    border-color: #70e17b !important;
  }
  .tablet\:hover\:border-success-light:hover {
    border-color: #70e17b !important;
  }
  .tablet\:border-success {
    border-color: #00a91c !important;
  }
  .tablet\:hover\:border-success:hover {
    border-color: #00a91c !important;
  }
  .tablet\:border-success-dark {
    border-color: #008817 !important;
  }
  .tablet\:hover\:border-success-dark:hover {
    border-color: #008817 !important;
  }
  .tablet\:border-success-darker {
    border-color: #216e1f !important;
  }
  .tablet\:hover\:border-success-darker:hover {
    border-color: #216e1f !important;
  }
  .tablet\:border-info-lighter {
    border-color: #e7f6f8 !important;
  }
  .tablet\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8 !important;
  }
  .tablet\:border-info-light {
    border-color: #99deea !important;
  }
  .tablet\:hover\:border-info-light:hover {
    border-color: #99deea !important;
  }
  .tablet\:border-info {
    border-color: #00bde3 !important;
  }
  .tablet\:hover\:border-info:hover {
    border-color: #00bde3 !important;
  }
  .tablet\:border-info-dark {
    border-color: #009ec1 !important;
  }
  .tablet\:hover\:border-info-dark:hover {
    border-color: #009ec1 !important;
  }
  .tablet\:border-info-darker {
    border-color: #2e6276 !important;
  }
  .tablet\:hover\:border-info-darker:hover {
    border-color: #2e6276 !important;
  }
  .tablet\:border-disabled-lighter {
    border-color: #c9c9c9 !important;
  }
  .tablet\:hover\:border-disabled-lighter:hover {
    border-color: #c9c9c9 !important;
  }
  .tablet\:border-disabled-light {
    border-color: #919191 !important;
  }
  .tablet\:hover\:border-disabled-light:hover {
    border-color: #919191 !important;
  }
  .tablet\:border-disabled {
    border-color: #757575 !important;
  }
  .tablet\:hover\:border-disabled:hover {
    border-color: #757575 !important;
  }
  .tablet\:border-disabled-dark {
    border-color: #454545 !important;
  }
  .tablet\:hover\:border-disabled-dark:hover {
    border-color: #454545 !important;
  }
  .tablet\:border-disabled-darker {
    border-color: #1b1b1b !important;
  }
  .tablet\:hover\:border-disabled-darker:hover {
    border-color: #1b1b1b !important;
  }
  .tablet\:border-emergency {
    border-color: #9c3d10 !important;
  }
  .tablet\:hover\:border-emergency:hover {
    border-color: #9c3d10 !important;
  }
  .tablet\:border-emergency-dark {
    border-color: #332d29 !important;
  }
  .tablet\:hover\:border-emergency-dark:hover {
    border-color: #332d29 !important;
  }
  .tablet\:radius-0 {
    border-radius: 0 !important;
  }
  .tablet\:radius-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .tablet\:radius-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .tablet\:radius-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .tablet\:radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .tablet\:radius-sm {
    border-radius: 2px !important;
  }
  .tablet\:radius-top-sm {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
  }
  .tablet\:radius-right-sm {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }
  .tablet\:radius-bottom-sm {
    border-bottom-left-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }
  .tablet\:radius-left-sm {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }
  .tablet\:radius-md {
    border-radius: 0.25rem !important;
  }
  .tablet\:radius-top-md {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .tablet\:radius-right-md {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .tablet\:radius-bottom-md {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .tablet\:radius-left-md {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .tablet\:radius-lg {
    border-radius: 0.5rem !important;
  }
  .tablet\:radius-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .tablet\:radius-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .tablet\:radius-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .tablet\:radius-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .tablet\:radius-pill {
    border-radius: 99rem !important;
  }
  .tablet\:radius-top-pill {
    border-top-left-radius: 99rem !important;
    border-top-right-radius: 99rem !important;
  }
  .tablet\:radius-right-pill {
    border-top-right-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }
  .tablet\:radius-bottom-pill {
    border-bottom-left-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }
  .tablet\:radius-left-pill {
    border-top-left-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }
  .tablet\:display-block {
    display: block !important;
  }
  .tablet\:display-flex {
    display: flex !important;
  }
  .tablet\:display-none {
    display: none !important;
  }
  .tablet\:display-inline {
    display: inline !important;
  }
  .tablet\:display-inline-block {
    display: inline-block !important;
  }
  .tablet\:display-inline-flex {
    display: inline-flex !important;
  }
  .tablet\:display-table {
    display: table !important;
  }
  .tablet\:display-table-cell {
    display: table-cell !important;
  }
  .tablet\:display-table-row {
    display: table-row !important;
  }
  .tablet\:font-mono-3xs {
    font-size: 0.77rem !important;
  }
  .tablet\:font-mono-2xs {
    font-size: 0.83rem !important;
  }
  .tablet\:font-mono-xs {
    font-size: 0.89rem !important;
  }
  .tablet\:font-mono-sm {
    font-size: 0.95rem !important;
  }
  .tablet\:font-mono-md {
    font-size: 1.19rem !important;
  }
  .tablet\:font-mono-lg {
    font-size: 1.31rem !important;
  }
  .tablet\:font-mono-xl {
    font-size: 1.91rem !important;
  }
  .tablet\:font-mono-2xl {
    font-size: 2.38rem !important;
  }
  .tablet\:font-mono-3xl {
    font-size: 2.86rem !important;
  }
  .tablet\:font-sans-3xs {
    font-size: 0.81rem !important;
  }
  .tablet\:font-sans-2xs {
    font-size: 0.87rem !important;
  }
  .tablet\:font-sans-xs {
    font-size: 0.93rem !important;
  }
  .tablet\:font-sans-sm {
    font-size: 0.99rem !important;
  }
  .tablet\:font-sans-md {
    font-size: 1.24rem !important;
  }
  .tablet\:font-sans-lg {
    font-size: 1.37rem !important;
  }
  .tablet\:font-sans-xl {
    font-size: 1.99rem !important;
  }
  .tablet\:font-sans-2xl {
    font-size: 2.49rem !important;
  }
  .tablet\:font-sans-3xl {
    font-size: 2.98rem !important;
  }
  .tablet\:font-serif-3xs {
    font-size: 0.81rem !important;
  }
  .tablet\:font-serif-2xs {
    font-size: 0.87rem !important;
  }
  .tablet\:font-serif-xs {
    font-size: 0.93rem !important;
  }
  .tablet\:font-serif-sm {
    font-size: 0.99rem !important;
  }
  .tablet\:font-serif-md {
    font-size: 1.24rem !important;
  }
  .tablet\:font-serif-lg {
    font-size: 1.37rem !important;
  }
  .tablet\:font-serif-xl {
    font-size: 1.99rem !important;
  }
  .tablet\:font-serif-2xl {
    font-size: 2.49rem !important;
  }
  .tablet\:font-serif-3xl {
    font-size: 2.98rem !important;
  }
  .tablet\:font-heading-3xs {
    font-size: 0.81rem !important;
  }
  .tablet\:font-heading-2xs {
    font-size: 0.87rem !important;
  }
  .tablet\:font-heading-xs {
    font-size: 0.93rem !important;
  }
  .tablet\:font-heading-sm {
    font-size: 0.99rem !important;
  }
  .tablet\:font-heading-md {
    font-size: 1.24rem !important;
  }
  .tablet\:font-heading-lg {
    font-size: 1.37rem !important;
  }
  .tablet\:font-heading-xl {
    font-size: 1.99rem !important;
  }
  .tablet\:font-heading-2xl {
    font-size: 2.49rem !important;
  }
  .tablet\:font-heading-3xl {
    font-size: 2.98rem !important;
  }
  .tablet\:font-body-3xs {
    font-size: 0.81rem !important;
  }
  .tablet\:font-body-2xs {
    font-size: 0.87rem !important;
  }
  .tablet\:font-body-xs {
    font-size: 0.93rem !important;
  }
  .tablet\:font-body-sm {
    font-size: 0.99rem !important;
  }
  .tablet\:font-body-md {
    font-size: 1.24rem !important;
  }
  .tablet\:font-body-lg {
    font-size: 1.37rem !important;
  }
  .tablet\:font-body-xl {
    font-size: 1.99rem !important;
  }
  .tablet\:font-body-2xl {
    font-size: 2.49rem !important;
  }
  .tablet\:font-body-3xl {
    font-size: 2.98rem !important;
  }
  .tablet\:font-code-3xs {
    font-size: 0.77rem !important;
  }
  .tablet\:font-code-2xs {
    font-size: 0.83rem !important;
  }
  .tablet\:font-code-xs {
    font-size: 0.89rem !important;
  }
  .tablet\:font-code-sm {
    font-size: 0.95rem !important;
  }
  .tablet\:font-code-md {
    font-size: 1.19rem !important;
  }
  .tablet\:font-code-lg {
    font-size: 1.31rem !important;
  }
  .tablet\:font-code-xl {
    font-size: 1.91rem !important;
  }
  .tablet\:font-code-2xl {
    font-size: 2.38rem !important;
  }
  .tablet\:font-code-3xl {
    font-size: 2.86rem !important;
  }
  .tablet\:font-alt-3xs {
    font-size: 0.81rem !important;
  }
  .tablet\:font-alt-2xs {
    font-size: 0.87rem !important;
  }
  .tablet\:font-alt-xs {
    font-size: 0.93rem !important;
  }
  .tablet\:font-alt-sm {
    font-size: 0.99rem !important;
  }
  .tablet\:font-alt-md {
    font-size: 1.24rem !important;
  }
  .tablet\:font-alt-lg {
    font-size: 1.37rem !important;
  }
  .tablet\:font-alt-xl {
    font-size: 1.99rem !important;
  }
  .tablet\:font-alt-2xl {
    font-size: 2.49rem !important;
  }
  .tablet\:font-alt-3xl {
    font-size: 2.98rem !important;
  }
  .tablet\:font-ui-3xs {
    font-size: 0.81rem !important;
  }
  .tablet\:font-ui-2xs {
    font-size: 0.87rem !important;
  }
  .tablet\:font-ui-xs {
    font-size: 0.93rem !important;
  }
  .tablet\:font-ui-sm {
    font-size: 0.99rem !important;
  }
  .tablet\:font-ui-md {
    font-size: 1.24rem !important;
  }
  .tablet\:font-ui-lg {
    font-size: 1.37rem !important;
  }
  .tablet\:font-ui-xl {
    font-size: 1.99rem !important;
  }
  .tablet\:font-ui-2xl {
    font-size: 2.49rem !important;
  }
  .tablet\:font-ui-3xl {
    font-size: 2.98rem !important;
  }
  .tablet\:text-light {
    font-weight: 300 !important;
  }
  .tablet\:text-normal {
    font-weight: normal !important;
  }
  .tablet\:text-semibold {
    font-weight: 600 !important;
  }
  .tablet\:text-bold {
    font-weight: 700 !important;
  }
  .tablet\:flex-justify-center {
    justify-content: center !important;
  }
  .tablet\:flex-justify-start {
    justify-content: flex-start !important;
  }
  .tablet\:flex-justify-end {
    justify-content: flex-end !important;
  }
  .tablet\:flex-justify {
    justify-content: space-between !important;
  }
  .tablet\:line-height-sans-1 {
    line-height: 1 !important;
  }
  .tablet\:line-height-sans-2 {
    line-height: 1.2 !important;
  }
  .tablet\:line-height-sans-3 {
    line-height: 1.4 !important;
  }
  .tablet\:line-height-sans-4 {
    line-height: 1.5 !important;
  }
  .tablet\:line-height-sans-5 {
    line-height: 1.6 !important;
  }
  .tablet\:line-height-sans-6 {
    line-height: 1.8 !important;
  }
  .tablet\:line-height-serif-1 {
    line-height: 1 !important;
  }
  .tablet\:line-height-serif-2 {
    line-height: 1.2 !important;
  }
  .tablet\:line-height-serif-3 {
    line-height: 1.4 !important;
  }
  .tablet\:line-height-serif-4 {
    line-height: 1.5 !important;
  }
  .tablet\:line-height-serif-5 {
    line-height: 1.6 !important;
  }
  .tablet\:line-height-serif-6 {
    line-height: 1.8 !important;
  }
  .tablet\:line-height-mono-1 {
    line-height: 1 !important;
  }
  .tablet\:line-height-mono-2 {
    line-height: 1.3 !important;
  }
  .tablet\:line-height-mono-3 {
    line-height: 1.4 !important;
  }
  .tablet\:line-height-mono-4 {
    line-height: 1.6 !important;
  }
  .tablet\:line-height-mono-5 {
    line-height: 1.7 !important;
  }
  .tablet\:line-height-mono-6 {
    line-height: 1.8 !important;
  }
  .tablet\:line-height-heading-1 {
    line-height: 1 !important;
  }
  .tablet\:line-height-heading-2 {
    line-height: 1.2 !important;
  }
  .tablet\:line-height-heading-3 {
    line-height: 1.4 !important;
  }
  .tablet\:line-height-heading-4 {
    line-height: 1.5 !important;
  }
  .tablet\:line-height-heading-5 {
    line-height: 1.6 !important;
  }
  .tablet\:line-height-heading-6 {
    line-height: 1.8 !important;
  }
  .tablet\:line-height-ui-1 {
    line-height: 1 !important;
  }
  .tablet\:line-height-ui-2 {
    line-height: 1.2 !important;
  }
  .tablet\:line-height-ui-3 {
    line-height: 1.4 !important;
  }
  .tablet\:line-height-ui-4 {
    line-height: 1.5 !important;
  }
  .tablet\:line-height-ui-5 {
    line-height: 1.6 !important;
  }
  .tablet\:line-height-ui-6 {
    line-height: 1.8 !important;
  }
  .tablet\:line-height-body-1 {
    line-height: 1 !important;
  }
  .tablet\:line-height-body-2 {
    line-height: 1.2 !important;
  }
  .tablet\:line-height-body-3 {
    line-height: 1.4 !important;
  }
  .tablet\:line-height-body-4 {
    line-height: 1.5 !important;
  }
  .tablet\:line-height-body-5 {
    line-height: 1.6 !important;
  }
  .tablet\:line-height-body-6 {
    line-height: 1.8 !important;
  }
  .tablet\:line-height-code-1 {
    line-height: 1 !important;
  }
  .tablet\:line-height-code-2 {
    line-height: 1.3 !important;
  }
  .tablet\:line-height-code-3 {
    line-height: 1.4 !important;
  }
  .tablet\:line-height-code-4 {
    line-height: 1.6 !important;
  }
  .tablet\:line-height-code-5 {
    line-height: 1.7 !important;
  }
  .tablet\:line-height-code-6 {
    line-height: 1.8 !important;
  }
  .tablet\:line-height-alt-1 {
    line-height: 1 !important;
  }
  .tablet\:line-height-alt-2 {
    line-height: 1.2 !important;
  }
  .tablet\:line-height-alt-3 {
    line-height: 1.4 !important;
  }
  .tablet\:line-height-alt-4 {
    line-height: 1.5 !important;
  }
  .tablet\:line-height-alt-5 {
    line-height: 1.6 !important;
  }
  .tablet\:line-height-alt-6 {
    line-height: 1.8 !important;
  }
  .tablet\:margin-neg-1px {
    margin: -1px !important;
  }
  .tablet\:margin-neg-2px {
    margin: -2px !important;
  }
  .tablet\:margin-neg-05 {
    margin: -0.25rem !important;
  }
  .tablet\:margin-neg-1 {
    margin: -0.5rem !important;
  }
  .tablet\:margin-neg-105 {
    margin: -0.75rem !important;
  }
  .tablet\:margin-neg-2 {
    margin: -1rem !important;
  }
  .tablet\:margin-neg-205 {
    margin: -1.25rem !important;
  }
  .tablet\:margin-neg-3 {
    margin: -1.5rem !important;
  }
  .tablet\:margin-1px {
    margin: 1px !important;
  }
  .tablet\:margin-2px {
    margin: 2px !important;
  }
  .tablet\:margin-05 {
    margin: 0.25rem !important;
  }
  .tablet\:margin-1 {
    margin: 0.5rem !important;
  }
  .tablet\:margin-105 {
    margin: 0.75rem !important;
  }
  .tablet\:margin-2 {
    margin: 1rem !important;
  }
  .tablet\:margin-205 {
    margin: 1.25rem !important;
  }
  .tablet\:margin-3 {
    margin: 1.5rem !important;
  }
  .tablet\:margin-neg-4 {
    margin: -2rem !important;
  }
  .tablet\:margin-neg-5 {
    margin: -2.5rem !important;
  }
  .tablet\:margin-neg-6 {
    margin: -3rem !important;
  }
  .tablet\:margin-neg-7 {
    margin: -3.5rem !important;
  }
  .tablet\:margin-neg-8 {
    margin: -4rem !important;
  }
  .tablet\:margin-neg-9 {
    margin: -4.5rem !important;
  }
  .tablet\:margin-neg-10 {
    margin: -5rem !important;
  }
  .tablet\:margin-neg-15 {
    margin: -7.5rem !important;
  }
  .tablet\:margin-4 {
    margin: 2rem !important;
  }
  .tablet\:margin-5 {
    margin: 2.5rem !important;
  }
  .tablet\:margin-6 {
    margin: 3rem !important;
  }
  .tablet\:margin-7 {
    margin: 3.5rem !important;
  }
  .tablet\:margin-8 {
    margin: 4rem !important;
  }
  .tablet\:margin-9 {
    margin: 4.5rem !important;
  }
  .tablet\:margin-10 {
    margin: 5rem !important;
  }
  .tablet\:margin-15 {
    margin: 7.5rem !important;
  }
  .tablet\:margin-05em {
    margin: 0.5em !important;
  }
  .tablet\:margin-1em {
    margin: 1em !important;
  }
  .tablet\:margin-105em {
    margin: 1.5em !important;
  }
  .tablet\:margin-2em {
    margin: 2em !important;
  }
  .tablet\:margin-0 {
    margin: 0 !important;
  }
  .tablet\:margin-y-1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .tablet\:margin-top-1px {
    margin-top: 1px !important;
  }
  .tablet\:margin-bottom-1px {
    margin-bottom: 1px !important;
  }
  .tablet\:margin-y-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .tablet\:margin-top-2px {
    margin-top: 2px !important;
  }
  .tablet\:margin-bottom-2px {
    margin-bottom: 2px !important;
  }
  .tablet\:margin-y-05 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .tablet\:margin-top-05 {
    margin-top: 0.25rem !important;
  }
  .tablet\:margin-bottom-05 {
    margin-bottom: 0.25rem !important;
  }
  .tablet\:margin-y-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .tablet\:margin-top-1 {
    margin-top: 0.5rem !important;
  }
  .tablet\:margin-bottom-1 {
    margin-bottom: 0.5rem !important;
  }
  .tablet\:margin-y-105 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .tablet\:margin-top-105 {
    margin-top: 0.75rem !important;
  }
  .tablet\:margin-bottom-105 {
    margin-bottom: 0.75rem !important;
  }
  .tablet\:margin-y-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .tablet\:margin-top-2 {
    margin-top: 1rem !important;
  }
  .tablet\:margin-bottom-2 {
    margin-bottom: 1rem !important;
  }
  .tablet\:margin-y-205 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .tablet\:margin-top-205 {
    margin-top: 1.25rem !important;
  }
  .tablet\:margin-bottom-205 {
    margin-bottom: 1.25rem !important;
  }
  .tablet\:margin-y-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .tablet\:margin-top-3 {
    margin-top: 1.5rem !important;
  }
  .tablet\:margin-bottom-3 {
    margin-bottom: 1.5rem !important;
  }
  .tablet\:margin-y-neg-1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .tablet\:margin-top-neg-1px {
    margin-top: -1px !important;
  }
  .tablet\:margin-bottom-neg-1px {
    margin-bottom: -1px !important;
  }
  .tablet\:margin-y-neg-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .tablet\:margin-top-neg-2px {
    margin-top: -2px !important;
  }
  .tablet\:margin-bottom-neg-2px {
    margin-bottom: -2px !important;
  }
  .tablet\:margin-y-neg-05 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .tablet\:margin-top-neg-05 {
    margin-top: -0.25rem !important;
  }
  .tablet\:margin-bottom-neg-05 {
    margin-bottom: -0.25rem !important;
  }
  .tablet\:margin-y-neg-1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .tablet\:margin-top-neg-1 {
    margin-top: -0.5rem !important;
  }
  .tablet\:margin-bottom-neg-1 {
    margin-bottom: -0.5rem !important;
  }
  .tablet\:margin-y-neg-105 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .tablet\:margin-top-neg-105 {
    margin-top: -0.75rem !important;
  }
  .tablet\:margin-bottom-neg-105 {
    margin-bottom: -0.75rem !important;
  }
  .tablet\:margin-y-neg-2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .tablet\:margin-top-neg-2 {
    margin-top: -1rem !important;
  }
  .tablet\:margin-bottom-neg-2 {
    margin-bottom: -1rem !important;
  }
  .tablet\:margin-y-neg-205 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .tablet\:margin-top-neg-205 {
    margin-top: -1.25rem !important;
  }
  .tablet\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem !important;
  }
  .tablet\:margin-y-neg-3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .tablet\:margin-top-neg-3 {
    margin-top: -1.5rem !important;
  }
  .tablet\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem !important;
  }
  .tablet\:margin-y-neg-4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .tablet\:margin-top-neg-4 {
    margin-top: -2rem !important;
  }
  .tablet\:margin-bottom-neg-4 {
    margin-bottom: -2rem !important;
  }
  .tablet\:margin-y-neg-5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .tablet\:margin-top-neg-5 {
    margin-top: -2.5rem !important;
  }
  .tablet\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem !important;
  }
  .tablet\:margin-y-neg-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .tablet\:margin-top-neg-6 {
    margin-top: -3rem !important;
  }
  .tablet\:margin-bottom-neg-6 {
    margin-bottom: -3rem !important;
  }
  .tablet\:margin-y-neg-7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .tablet\:margin-top-neg-7 {
    margin-top: -3.5rem !important;
  }
  .tablet\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem !important;
  }
  .tablet\:margin-y-neg-8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .tablet\:margin-top-neg-8 {
    margin-top: -4rem !important;
  }
  .tablet\:margin-bottom-neg-8 {
    margin-bottom: -4rem !important;
  }
  .tablet\:margin-y-neg-9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .tablet\:margin-top-neg-9 {
    margin-top: -4.5rem !important;
  }
  .tablet\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem !important;
  }
  .tablet\:margin-y-neg-10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .tablet\:margin-top-neg-10 {
    margin-top: -5rem !important;
  }
  .tablet\:margin-bottom-neg-10 {
    margin-bottom: -5rem !important;
  }
  .tablet\:margin-y-neg-15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .tablet\:margin-top-neg-15 {
    margin-top: -7.5rem !important;
  }
  .tablet\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem !important;
  }
  .tablet\:margin-y-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .tablet\:margin-top-4 {
    margin-top: 2rem !important;
  }
  .tablet\:margin-bottom-4 {
    margin-bottom: 2rem !important;
  }
  .tablet\:margin-y-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .tablet\:margin-top-5 {
    margin-top: 2.5rem !important;
  }
  .tablet\:margin-bottom-5 {
    margin-bottom: 2.5rem !important;
  }
  .tablet\:margin-y-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .tablet\:margin-top-6 {
    margin-top: 3rem !important;
  }
  .tablet\:margin-bottom-6 {
    margin-bottom: 3rem !important;
  }
  .tablet\:margin-y-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .tablet\:margin-top-7 {
    margin-top: 3.5rem !important;
  }
  .tablet\:margin-bottom-7 {
    margin-bottom: 3.5rem !important;
  }
  .tablet\:margin-y-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .tablet\:margin-top-8 {
    margin-top: 4rem !important;
  }
  .tablet\:margin-bottom-8 {
    margin-bottom: 4rem !important;
  }
  .tablet\:margin-y-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .tablet\:margin-top-9 {
    margin-top: 4.5rem !important;
  }
  .tablet\:margin-bottom-9 {
    margin-bottom: 4.5rem !important;
  }
  .tablet\:margin-y-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .tablet\:margin-top-10 {
    margin-top: 5rem !important;
  }
  .tablet\:margin-bottom-10 {
    margin-bottom: 5rem !important;
  }
  .tablet\:margin-y-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .tablet\:margin-top-15 {
    margin-top: 7.5rem !important;
  }
  .tablet\:margin-bottom-15 {
    margin-bottom: 7.5rem !important;
  }
  .tablet\:margin-y-05em {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }
  .tablet\:margin-top-05em {
    margin-top: 0.5em !important;
  }
  .tablet\:margin-bottom-05em {
    margin-bottom: 0.5em !important;
  }
  .tablet\:margin-y-1em {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }
  .tablet\:margin-top-1em {
    margin-top: 1em !important;
  }
  .tablet\:margin-bottom-1em {
    margin-bottom: 1em !important;
  }
  .tablet\:margin-y-105em {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }
  .tablet\:margin-top-105em {
    margin-top: 1.5em !important;
  }
  .tablet\:margin-bottom-105em {
    margin-bottom: 1.5em !important;
  }
  .tablet\:margin-y-2em {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }
  .tablet\:margin-top-2em {
    margin-top: 2em !important;
  }
  .tablet\:margin-bottom-2em {
    margin-bottom: 2em !important;
  }
  .tablet\:margin-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .tablet\:margin-top-0 {
    margin-top: 0 !important;
  }
  .tablet\:margin-bottom-0 {
    margin-bottom: 0 !important;
  }
  .tablet\:margin-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .tablet\:margin-top-auto {
    margin-top: auto !important;
  }
  .tablet\:margin-bottom-auto {
    margin-bottom: auto !important;
  }
  .tablet\:margin-x-1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .tablet\:margin-right-1px {
    margin-right: 1px !important;
  }
  .tablet\:margin-left-1px {
    margin-left: 1px !important;
  }
  .tablet\:margin-x-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .tablet\:margin-right-2px {
    margin-right: 2px !important;
  }
  .tablet\:margin-left-2px {
    margin-left: 2px !important;
  }
  .tablet\:margin-x-05 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .tablet\:margin-right-05 {
    margin-right: 0.25rem !important;
  }
  .tablet\:margin-left-05 {
    margin-left: 0.25rem !important;
  }
  .tablet\:margin-x-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .tablet\:margin-right-1 {
    margin-right: 0.5rem !important;
  }
  .tablet\:margin-left-1 {
    margin-left: 0.5rem !important;
  }
  .tablet\:margin-x-105 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .tablet\:margin-right-105 {
    margin-right: 0.75rem !important;
  }
  .tablet\:margin-left-105 {
    margin-left: 0.75rem !important;
  }
  .tablet\:margin-x-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .tablet\:margin-right-2 {
    margin-right: 1rem !important;
  }
  .tablet\:margin-left-2 {
    margin-left: 1rem !important;
  }
  .tablet\:margin-x-205 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .tablet\:margin-right-205 {
    margin-right: 1.25rem !important;
  }
  .tablet\:margin-left-205 {
    margin-left: 1.25rem !important;
  }
  .tablet\:margin-x-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .tablet\:margin-right-3 {
    margin-right: 1.5rem !important;
  }
  .tablet\:margin-left-3 {
    margin-left: 1.5rem !important;
  }
  .tablet\:margin-x-neg-1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }
  .tablet\:margin-right-neg-1px {
    margin-right: -1px !important;
  }
  .tablet\:margin-left-neg-1px {
    margin-left: -1px !important;
  }
  .tablet\:margin-x-neg-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }
  .tablet\:margin-right-neg-2px {
    margin-right: -2px !important;
  }
  .tablet\:margin-left-neg-2px {
    margin-left: -2px !important;
  }
  .tablet\:margin-x-neg-05 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .tablet\:margin-right-neg-05 {
    margin-right: -0.25rem !important;
  }
  .tablet\:margin-left-neg-05 {
    margin-left: -0.25rem !important;
  }
  .tablet\:margin-x-neg-1 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .tablet\:margin-right-neg-1 {
    margin-right: -0.5rem !important;
  }
  .tablet\:margin-left-neg-1 {
    margin-left: -0.5rem !important;
  }
  .tablet\:margin-x-neg-105 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .tablet\:margin-right-neg-105 {
    margin-right: -0.75rem !important;
  }
  .tablet\:margin-left-neg-105 {
    margin-left: -0.75rem !important;
  }
  .tablet\:margin-x-neg-2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .tablet\:margin-right-neg-2 {
    margin-right: -1rem !important;
  }
  .tablet\:margin-left-neg-2 {
    margin-left: -1rem !important;
  }
  .tablet\:margin-x-neg-205 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .tablet\:margin-right-neg-205 {
    margin-right: -1.25rem !important;
  }
  .tablet\:margin-left-neg-205 {
    margin-left: -1.25rem !important;
  }
  .tablet\:margin-x-neg-3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .tablet\:margin-right-neg-3 {
    margin-right: -1.5rem !important;
  }
  .tablet\:margin-left-neg-3 {
    margin-left: -1.5rem !important;
  }
  .tablet\:margin-x-neg-4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .tablet\:margin-right-neg-4 {
    margin-right: -2rem !important;
  }
  .tablet\:margin-left-neg-4 {
    margin-left: -2rem !important;
  }
  .tablet\:margin-x-neg-5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .tablet\:margin-right-neg-5 {
    margin-right: -2.5rem !important;
  }
  .tablet\:margin-left-neg-5 {
    margin-left: -2.5rem !important;
  }
  .tablet\:margin-x-neg-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .tablet\:margin-right-neg-6 {
    margin-right: -3rem !important;
  }
  .tablet\:margin-left-neg-6 {
    margin-left: -3rem !important;
  }
  .tablet\:margin-x-neg-7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .tablet\:margin-right-neg-7 {
    margin-right: -3.5rem !important;
  }
  .tablet\:margin-left-neg-7 {
    margin-left: -3.5rem !important;
  }
  .tablet\:margin-x-neg-8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .tablet\:margin-right-neg-8 {
    margin-right: -4rem !important;
  }
  .tablet\:margin-left-neg-8 {
    margin-left: -4rem !important;
  }
  .tablet\:margin-x-neg-9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .tablet\:margin-right-neg-9 {
    margin-right: -4.5rem !important;
  }
  .tablet\:margin-left-neg-9 {
    margin-left: -4.5rem !important;
  }
  .tablet\:margin-x-neg-10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .tablet\:margin-right-neg-10 {
    margin-right: -5rem !important;
  }
  .tablet\:margin-left-neg-10 {
    margin-left: -5rem !important;
  }
  .tablet\:margin-x-neg-15 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }
  .tablet\:margin-right-neg-15 {
    margin-right: -7.5rem !important;
  }
  .tablet\:margin-left-neg-15 {
    margin-left: -7.5rem !important;
  }
  .tablet\:margin-x-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .tablet\:margin-right-4 {
    margin-right: 2rem !important;
  }
  .tablet\:margin-left-4 {
    margin-left: 2rem !important;
  }
  .tablet\:margin-x-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .tablet\:margin-right-5 {
    margin-right: 2.5rem !important;
  }
  .tablet\:margin-left-5 {
    margin-left: 2.5rem !important;
  }
  .tablet\:margin-x-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .tablet\:margin-right-6 {
    margin-right: 3rem !important;
  }
  .tablet\:margin-left-6 {
    margin-left: 3rem !important;
  }
  .tablet\:margin-x-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .tablet\:margin-right-7 {
    margin-right: 3.5rem !important;
  }
  .tablet\:margin-left-7 {
    margin-left: 3.5rem !important;
  }
  .tablet\:margin-x-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .tablet\:margin-right-8 {
    margin-right: 4rem !important;
  }
  .tablet\:margin-left-8 {
    margin-left: 4rem !important;
  }
  .tablet\:margin-x-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .tablet\:margin-right-9 {
    margin-right: 4.5rem !important;
  }
  .tablet\:margin-left-9 {
    margin-left: 4.5rem !important;
  }
  .tablet\:margin-x-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .tablet\:margin-right-10 {
    margin-right: 5rem !important;
  }
  .tablet\:margin-left-10 {
    margin-left: 5rem !important;
  }
  .tablet\:margin-x-15 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }
  .tablet\:margin-right-15 {
    margin-right: 7.5rem !important;
  }
  .tablet\:margin-left-15 {
    margin-left: 7.5rem !important;
  }
  .tablet\:margin-x-card {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .tablet\:margin-right-card {
    margin-right: 10rem !important;
  }
  .tablet\:margin-left-card {
    margin-left: 10rem !important;
  }
  .tablet\:margin-x-card-lg {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }
  .tablet\:margin-right-card-lg {
    margin-right: 15rem !important;
  }
  .tablet\:margin-left-card-lg {
    margin-left: 15rem !important;
  }
  .tablet\:margin-x-mobile {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }
  .tablet\:margin-right-mobile {
    margin-right: 20rem !important;
  }
  .tablet\:margin-left-mobile {
    margin-left: 20rem !important;
  }
  .tablet\:margin-x-05em {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
  }
  .tablet\:margin-right-05em {
    margin-right: 0.5em !important;
  }
  .tablet\:margin-left-05em {
    margin-left: 0.5em !important;
  }
  .tablet\:margin-x-1em {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }
  .tablet\:margin-right-1em {
    margin-right: 1em !important;
  }
  .tablet\:margin-left-1em {
    margin-left: 1em !important;
  }
  .tablet\:margin-x-105em {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }
  .tablet\:margin-right-105em {
    margin-right: 1.5em !important;
  }
  .tablet\:margin-left-105em {
    margin-left: 1.5em !important;
  }
  .tablet\:margin-x-2em {
    margin-left: 2em !important;
    margin-right: 2em !important;
  }
  .tablet\:margin-right-2em {
    margin-right: 2em !important;
  }
  .tablet\:margin-left-2em {
    margin-left: 2em !important;
  }
  .tablet\:margin-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .tablet\:margin-right-0 {
    margin-right: 0 !important;
  }
  .tablet\:margin-left-0 {
    margin-left: 0 !important;
  }
  .tablet\:margin-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .tablet\:margin-right-auto {
    margin-right: auto !important;
  }
  .tablet\:margin-left-auto {
    margin-left: auto !important;
  }
  .tablet\:measure-1 {
    max-width: 44ex !important;
  }
  .tablet\:measure-2 {
    max-width: 60ex !important;
  }
  .tablet\:measure-3 {
    max-width: 64ex !important;
  }
  .tablet\:measure-4 {
    max-width: 68ex !important;
  }
  .tablet\:measure-5 {
    max-width: 72ex !important;
  }
  .tablet\:measure-6 {
    max-width: 88ex !important;
  }
  .tablet\:measure-none {
    max-width: none !important;
  }
  .tablet\:order-first {
    order: -1 !important;
  }
  .tablet\:order-last {
    order: 999 !important;
  }
  .tablet\:order-initial {
    order: initial !important;
  }
  .tablet\:order-0 {
    order: 0 !important;
  }
  .tablet\:order-1 {
    order: 1 !important;
  }
  .tablet\:order-2 {
    order: 2 !important;
  }
  .tablet\:order-3 {
    order: 3 !important;
  }
  .tablet\:order-4 {
    order: 4 !important;
  }
  .tablet\:order-5 {
    order: 5 !important;
  }
  .tablet\:order-6 {
    order: 6 !important;
  }
  .tablet\:order-7 {
    order: 7 !important;
  }
  .tablet\:order-8 {
    order: 8 !important;
  }
  .tablet\:order-9 {
    order: 9 !important;
  }
  .tablet\:order-10 {
    order: 10 !important;
  }
  .tablet\:order-11 {
    order: 11 !important;
  }
  .tablet\:padding-1px {
    padding: 1px !important;
  }
  .tablet\:padding-y-1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .tablet\:padding-x-1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .tablet\:padding-top-1px {
    padding-top: 1px !important;
  }
  .tablet\:padding-right-1px {
    padding-right: 1px !important;
  }
  .tablet\:padding-bottom-1px {
    padding-bottom: 1px !important;
  }
  .tablet\:padding-left-1px {
    padding-left: 1px !important;
  }
  .tablet\:padding-2px {
    padding: 2px !important;
  }
  .tablet\:padding-y-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .tablet\:padding-x-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .tablet\:padding-top-2px {
    padding-top: 2px !important;
  }
  .tablet\:padding-right-2px {
    padding-right: 2px !important;
  }
  .tablet\:padding-bottom-2px {
    padding-bottom: 2px !important;
  }
  .tablet\:padding-left-2px {
    padding-left: 2px !important;
  }
  .tablet\:padding-05 {
    padding: 0.25rem !important;
  }
  .tablet\:padding-y-05 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .tablet\:padding-x-05 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .tablet\:padding-top-05 {
    padding-top: 0.25rem !important;
  }
  .tablet\:padding-right-05 {
    padding-right: 0.25rem !important;
  }
  .tablet\:padding-bottom-05 {
    padding-bottom: 0.25rem !important;
  }
  .tablet\:padding-left-05 {
    padding-left: 0.25rem !important;
  }
  .tablet\:padding-1 {
    padding: 0.5rem !important;
  }
  .tablet\:padding-y-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .tablet\:padding-x-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .tablet\:padding-top-1 {
    padding-top: 0.5rem !important;
  }
  .tablet\:padding-right-1 {
    padding-right: 0.5rem !important;
  }
  .tablet\:padding-bottom-1 {
    padding-bottom: 0.5rem !important;
  }
  .tablet\:padding-left-1 {
    padding-left: 0.5rem !important;
  }
  .tablet\:padding-105 {
    padding: 0.75rem !important;
  }
  .tablet\:padding-y-105 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .tablet\:padding-x-105 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .tablet\:padding-top-105 {
    padding-top: 0.75rem !important;
  }
  .tablet\:padding-right-105 {
    padding-right: 0.75rem !important;
  }
  .tablet\:padding-bottom-105 {
    padding-bottom: 0.75rem !important;
  }
  .tablet\:padding-left-105 {
    padding-left: 0.75rem !important;
  }
  .tablet\:padding-2 {
    padding: 1rem !important;
  }
  .tablet\:padding-y-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .tablet\:padding-x-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .tablet\:padding-top-2 {
    padding-top: 1rem !important;
  }
  .tablet\:padding-right-2 {
    padding-right: 1rem !important;
  }
  .tablet\:padding-bottom-2 {
    padding-bottom: 1rem !important;
  }
  .tablet\:padding-left-2 {
    padding-left: 1rem !important;
  }
  .tablet\:padding-205 {
    padding: 1.25rem !important;
  }
  .tablet\:padding-y-205 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .tablet\:padding-x-205 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .tablet\:padding-top-205 {
    padding-top: 1.25rem !important;
  }
  .tablet\:padding-right-205 {
    padding-right: 1.25rem !important;
  }
  .tablet\:padding-bottom-205 {
    padding-bottom: 1.25rem !important;
  }
  .tablet\:padding-left-205 {
    padding-left: 1.25rem !important;
  }
  .tablet\:padding-3 {
    padding: 1.5rem !important;
  }
  .tablet\:padding-y-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .tablet\:padding-x-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .tablet\:padding-top-3 {
    padding-top: 1.5rem !important;
  }
  .tablet\:padding-right-3 {
    padding-right: 1.5rem !important;
  }
  .tablet\:padding-bottom-3 {
    padding-bottom: 1.5rem !important;
  }
  .tablet\:padding-left-3 {
    padding-left: 1.5rem !important;
  }
  .tablet\:padding-4 {
    padding: 2rem !important;
  }
  .tablet\:padding-y-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .tablet\:padding-x-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .tablet\:padding-top-4 {
    padding-top: 2rem !important;
  }
  .tablet\:padding-right-4 {
    padding-right: 2rem !important;
  }
  .tablet\:padding-bottom-4 {
    padding-bottom: 2rem !important;
  }
  .tablet\:padding-left-4 {
    padding-left: 2rem !important;
  }
  .tablet\:padding-5 {
    padding: 2.5rem !important;
  }
  .tablet\:padding-y-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .tablet\:padding-x-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .tablet\:padding-top-5 {
    padding-top: 2.5rem !important;
  }
  .tablet\:padding-right-5 {
    padding-right: 2.5rem !important;
  }
  .tablet\:padding-bottom-5 {
    padding-bottom: 2.5rem !important;
  }
  .tablet\:padding-left-5 {
    padding-left: 2.5rem !important;
  }
  .tablet\:padding-6 {
    padding: 3rem !important;
  }
  .tablet\:padding-y-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .tablet\:padding-x-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .tablet\:padding-top-6 {
    padding-top: 3rem !important;
  }
  .tablet\:padding-right-6 {
    padding-right: 3rem !important;
  }
  .tablet\:padding-bottom-6 {
    padding-bottom: 3rem !important;
  }
  .tablet\:padding-left-6 {
    padding-left: 3rem !important;
  }
  .tablet\:padding-7 {
    padding: 3.5rem !important;
  }
  .tablet\:padding-y-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .tablet\:padding-x-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .tablet\:padding-top-7 {
    padding-top: 3.5rem !important;
  }
  .tablet\:padding-right-7 {
    padding-right: 3.5rem !important;
  }
  .tablet\:padding-bottom-7 {
    padding-bottom: 3.5rem !important;
  }
  .tablet\:padding-left-7 {
    padding-left: 3.5rem !important;
  }
  .tablet\:padding-8 {
    padding: 4rem !important;
  }
  .tablet\:padding-y-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .tablet\:padding-x-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .tablet\:padding-top-8 {
    padding-top: 4rem !important;
  }
  .tablet\:padding-right-8 {
    padding-right: 4rem !important;
  }
  .tablet\:padding-bottom-8 {
    padding-bottom: 4rem !important;
  }
  .tablet\:padding-left-8 {
    padding-left: 4rem !important;
  }
  .tablet\:padding-9 {
    padding: 4.5rem !important;
  }
  .tablet\:padding-y-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .tablet\:padding-x-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .tablet\:padding-top-9 {
    padding-top: 4.5rem !important;
  }
  .tablet\:padding-right-9 {
    padding-right: 4.5rem !important;
  }
  .tablet\:padding-bottom-9 {
    padding-bottom: 4.5rem !important;
  }
  .tablet\:padding-left-9 {
    padding-left: 4.5rem !important;
  }
  .tablet\:padding-10 {
    padding: 5rem !important;
  }
  .tablet\:padding-y-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .tablet\:padding-x-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .tablet\:padding-top-10 {
    padding-top: 5rem !important;
  }
  .tablet\:padding-right-10 {
    padding-right: 5rem !important;
  }
  .tablet\:padding-bottom-10 {
    padding-bottom: 5rem !important;
  }
  .tablet\:padding-left-10 {
    padding-left: 5rem !important;
  }
  .tablet\:padding-15 {
    padding: 7.5rem !important;
  }
  .tablet\:padding-y-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .tablet\:padding-x-15 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .tablet\:padding-top-15 {
    padding-top: 7.5rem !important;
  }
  .tablet\:padding-right-15 {
    padding-right: 7.5rem !important;
  }
  .tablet\:padding-bottom-15 {
    padding-bottom: 7.5rem !important;
  }
  .tablet\:padding-left-15 {
    padding-left: 7.5rem !important;
  }
  .tablet\:padding-0 {
    padding: 0 !important;
  }
  .tablet\:padding-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .tablet\:padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .tablet\:padding-top-0 {
    padding-top: 0 !important;
  }
  .tablet\:padding-right-0 {
    padding-right: 0 !important;
  }
  .tablet\:padding-bottom-0 {
    padding-bottom: 0 !important;
  }
  .tablet\:padding-left-0 {
    padding-left: 0 !important;
  }
  .tablet\:width-1px {
    width: 1px !important;
  }
  .tablet\:width-2px {
    width: 2px !important;
  }
  .tablet\:width-05 {
    width: 0.25rem !important;
  }
  .tablet\:width-1 {
    width: 0.5rem !important;
  }
  .tablet\:width-105 {
    width: 0.75rem !important;
  }
  .tablet\:width-2 {
    width: 1rem !important;
  }
  .tablet\:width-205 {
    width: 1.25rem !important;
  }
  .tablet\:width-3 {
    width: 1.5rem !important;
  }
  .tablet\:width-4 {
    width: 2rem !important;
  }
  .tablet\:width-5 {
    width: 2.5rem !important;
  }
  .tablet\:width-6 {
    width: 3rem !important;
  }
  .tablet\:width-7 {
    width: 3.5rem !important;
  }
  .tablet\:width-8 {
    width: 4rem !important;
  }
  .tablet\:width-9 {
    width: 4.5rem !important;
  }
  .tablet\:width-10 {
    width: 5rem !important;
  }
  .tablet\:width-15 {
    width: 7.5rem !important;
  }
  .tablet\:width-card {
    width: 10rem !important;
  }
  .tablet\:width-card-lg {
    width: 15rem !important;
  }
  .tablet\:width-mobile {
    width: 20rem !important;
  }
  .tablet\:width-mobile-lg {
    width: 30rem !important;
  }
  .tablet\:width-tablet {
    width: 40rem !important;
  }
  .tablet\:width-tablet-lg {
    width: 55rem !important;
  }
  .tablet\:width-desktop {
    width: 64rem !important;
  }
  .tablet\:width-desktop-lg {
    width: 75rem !important;
  }
  .tablet\:width-widescreen {
    width: 87.5rem !important;
  }
  .tablet\:width-0 {
    width: 0 !important;
  }
  .tablet\:width-full {
    width: 100% !important;
  }
  .tablet\:width-auto {
    width: auto !important;
  }
}
@media all and (min-width: 64em) {
  .desktop\:border-1px {
    border: 1px solid !important;
  }
  .desktop\:hover\:border-1px:hover {
    border: 1px solid !important;
  }
  .desktop\:border-y-1px {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }
  .desktop\:hover\:border-y-1px:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }
  .desktop\:border-x-1px {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }
  .desktop\:hover\:border-x-1px:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }
  .desktop\:border-top-1px {
    border-top: 1px solid !important;
  }
  .desktop\:hover\:border-top-1px:hover {
    border-top: 1px solid !important;
  }
  .desktop\:border-right-1px {
    border-right: 1px solid !important;
  }
  .desktop\:hover\:border-right-1px:hover {
    border-right: 1px solid !important;
  }
  .desktop\:border-bottom-1px {
    border-bottom: 1px solid !important;
  }
  .desktop\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid !important;
  }
  .desktop\:border-left-1px {
    border-left: 1px solid !important;
  }
  .desktop\:hover\:border-left-1px:hover {
    border-left: 1px solid !important;
  }
  .desktop\:border-2px {
    border: 2px solid !important;
  }
  .desktop\:hover\:border-2px:hover {
    border: 2px solid !important;
  }
  .desktop\:border-y-2px {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }
  .desktop\:hover\:border-y-2px:hover {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }
  .desktop\:border-x-2px {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }
  .desktop\:hover\:border-x-2px:hover {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }
  .desktop\:border-top-2px {
    border-top: 2px solid !important;
  }
  .desktop\:hover\:border-top-2px:hover {
    border-top: 2px solid !important;
  }
  .desktop\:border-right-2px {
    border-right: 2px solid !important;
  }
  .desktop\:hover\:border-right-2px:hover {
    border-right: 2px solid !important;
  }
  .desktop\:border-bottom-2px {
    border-bottom: 2px solid !important;
  }
  .desktop\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid !important;
  }
  .desktop\:border-left-2px {
    border-left: 2px solid !important;
  }
  .desktop\:hover\:border-left-2px:hover {
    border-left: 2px solid !important;
  }
  .desktop\:border-05 {
    border: 0.25rem solid !important;
  }
  .desktop\:hover\:border-05:hover {
    border: 0.25rem solid !important;
  }
  .desktop\:border-y-05 {
    border-top: 0.25rem solid !important;
    border-bottom: 0.25rem solid !important;
  }
  .desktop\:hover\:border-y-05:hover {
    border-top: 0.25rem solid !important;
    border-bottom: 0.25rem solid !important;
  }
  .desktop\:border-x-05 {
    border-left: 0.25rem solid !important;
    border-right: 0.25rem solid !important;
  }
  .desktop\:hover\:border-x-05:hover {
    border-left: 0.25rem solid !important;
    border-right: 0.25rem solid !important;
  }
  .desktop\:border-top-05 {
    border-top: 0.25rem solid !important;
  }
  .desktop\:hover\:border-top-05:hover {
    border-top: 0.25rem solid !important;
  }
  .desktop\:border-right-05 {
    border-right: 0.25rem solid !important;
  }
  .desktop\:hover\:border-right-05:hover {
    border-right: 0.25rem solid !important;
  }
  .desktop\:border-bottom-05 {
    border-bottom: 0.25rem solid !important;
  }
  .desktop\:hover\:border-bottom-05:hover {
    border-bottom: 0.25rem solid !important;
  }
  .desktop\:border-left-05 {
    border-left: 0.25rem solid !important;
  }
  .desktop\:hover\:border-left-05:hover {
    border-left: 0.25rem solid !important;
  }
  .desktop\:border-1 {
    border: 0.5rem solid !important;
  }
  .desktop\:hover\:border-1:hover {
    border: 0.5rem solid !important;
  }
  .desktop\:border-y-1 {
    border-top: 0.5rem solid !important;
    border-bottom: 0.5rem solid !important;
  }
  .desktop\:hover\:border-y-1:hover {
    border-top: 0.5rem solid !important;
    border-bottom: 0.5rem solid !important;
  }
  .desktop\:border-x-1 {
    border-left: 0.5rem solid !important;
    border-right: 0.5rem solid !important;
  }
  .desktop\:hover\:border-x-1:hover {
    border-left: 0.5rem solid !important;
    border-right: 0.5rem solid !important;
  }
  .desktop\:border-top-1 {
    border-top: 0.5rem solid !important;
  }
  .desktop\:hover\:border-top-1:hover {
    border-top: 0.5rem solid !important;
  }
  .desktop\:border-right-1 {
    border-right: 0.5rem solid !important;
  }
  .desktop\:hover\:border-right-1:hover {
    border-right: 0.5rem solid !important;
  }
  .desktop\:border-bottom-1 {
    border-bottom: 0.5rem solid !important;
  }
  .desktop\:hover\:border-bottom-1:hover {
    border-bottom: 0.5rem solid !important;
  }
  .desktop\:border-left-1 {
    border-left: 0.5rem solid !important;
  }
  .desktop\:hover\:border-left-1:hover {
    border-left: 0.5rem solid !important;
  }
  .desktop\:border-105 {
    border: 0.75rem solid !important;
  }
  .desktop\:hover\:border-105:hover {
    border: 0.75rem solid !important;
  }
  .desktop\:border-y-105 {
    border-top: 0.75rem solid !important;
    border-bottom: 0.75rem solid !important;
  }
  .desktop\:hover\:border-y-105:hover {
    border-top: 0.75rem solid !important;
    border-bottom: 0.75rem solid !important;
  }
  .desktop\:border-x-105 {
    border-left: 0.75rem solid !important;
    border-right: 0.75rem solid !important;
  }
  .desktop\:hover\:border-x-105:hover {
    border-left: 0.75rem solid !important;
    border-right: 0.75rem solid !important;
  }
  .desktop\:border-top-105 {
    border-top: 0.75rem solid !important;
  }
  .desktop\:hover\:border-top-105:hover {
    border-top: 0.75rem solid !important;
  }
  .desktop\:border-right-105 {
    border-right: 0.75rem solid !important;
  }
  .desktop\:hover\:border-right-105:hover {
    border-right: 0.75rem solid !important;
  }
  .desktop\:border-bottom-105 {
    border-bottom: 0.75rem solid !important;
  }
  .desktop\:hover\:border-bottom-105:hover {
    border-bottom: 0.75rem solid !important;
  }
  .desktop\:border-left-105 {
    border-left: 0.75rem solid !important;
  }
  .desktop\:hover\:border-left-105:hover {
    border-left: 0.75rem solid !important;
  }
  .desktop\:border-2 {
    border: 1rem solid !important;
  }
  .desktop\:hover\:border-2:hover {
    border: 1rem solid !important;
  }
  .desktop\:border-y-2 {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }
  .desktop\:hover\:border-y-2:hover {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }
  .desktop\:border-x-2 {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }
  .desktop\:hover\:border-x-2:hover {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }
  .desktop\:border-top-2 {
    border-top: 1rem solid !important;
  }
  .desktop\:hover\:border-top-2:hover {
    border-top: 1rem solid !important;
  }
  .desktop\:border-right-2 {
    border-right: 1rem solid !important;
  }
  .desktop\:hover\:border-right-2:hover {
    border-right: 1rem solid !important;
  }
  .desktop\:border-bottom-2 {
    border-bottom: 1rem solid !important;
  }
  .desktop\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid !important;
  }
  .desktop\:border-left-2 {
    border-left: 1rem solid !important;
  }
  .desktop\:hover\:border-left-2:hover {
    border-left: 1rem solid !important;
  }
  .desktop\:border-205 {
    border: 1.25rem solid !important;
  }
  .desktop\:hover\:border-205:hover {
    border: 1.25rem solid !important;
  }
  .desktop\:border-y-205 {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }
  .desktop\:hover\:border-y-205:hover {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }
  .desktop\:border-x-205 {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }
  .desktop\:hover\:border-x-205:hover {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }
  .desktop\:border-top-205 {
    border-top: 1.25rem solid !important;
  }
  .desktop\:hover\:border-top-205:hover {
    border-top: 1.25rem solid !important;
  }
  .desktop\:border-right-205 {
    border-right: 1.25rem solid !important;
  }
  .desktop\:hover\:border-right-205:hover {
    border-right: 1.25rem solid !important;
  }
  .desktop\:border-bottom-205 {
    border-bottom: 1.25rem solid !important;
  }
  .desktop\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid !important;
  }
  .desktop\:border-left-205 {
    border-left: 1.25rem solid !important;
  }
  .desktop\:hover\:border-left-205:hover {
    border-left: 1.25rem solid !important;
  }
  .desktop\:border-3 {
    border: 1.5rem solid !important;
  }
  .desktop\:hover\:border-3:hover {
    border: 1.5rem solid !important;
  }
  .desktop\:border-y-3 {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }
  .desktop\:hover\:border-y-3:hover {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }
  .desktop\:border-x-3 {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }
  .desktop\:hover\:border-x-3:hover {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }
  .desktop\:border-top-3 {
    border-top: 1.5rem solid !important;
  }
  .desktop\:hover\:border-top-3:hover {
    border-top: 1.5rem solid !important;
  }
  .desktop\:border-right-3 {
    border-right: 1.5rem solid !important;
  }
  .desktop\:hover\:border-right-3:hover {
    border-right: 1.5rem solid !important;
  }
  .desktop\:border-bottom-3 {
    border-bottom: 1.5rem solid !important;
  }
  .desktop\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid !important;
  }
  .desktop\:border-left-3 {
    border-left: 1.5rem solid !important;
  }
  .desktop\:hover\:border-left-3:hover {
    border-left: 1.5rem solid !important;
  }
  .desktop\:border-0 {
    border: 0 solid !important;
  }
  .desktop\:hover\:border-0:hover {
    border: 0 solid !important;
  }
  .desktop\:border-y-0 {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }
  .desktop\:hover\:border-y-0:hover {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }
  .desktop\:border-x-0 {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }
  .desktop\:hover\:border-x-0:hover {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }
  .desktop\:border-top-0 {
    border-top: 0 solid !important;
  }
  .desktop\:hover\:border-top-0:hover {
    border-top: 0 solid !important;
  }
  .desktop\:border-right-0 {
    border-right: 0 solid !important;
  }
  .desktop\:hover\:border-right-0:hover {
    border-right: 0 solid !important;
  }
  .desktop\:border-bottom-0 {
    border-bottom: 0 solid !important;
  }
  .desktop\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid !important;
  }
  .desktop\:border-left-0 {
    border-left: 0 solid !important;
  }
  .desktop\:hover\:border-left-0:hover {
    border-left: 0 solid !important;
  }
  .desktop\:border {
    border: 1px solid !important;
  }
  .desktop\:hover\:border:hover {
    border: 1px solid !important;
  }
  .desktop\:border-y {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }
  .desktop\:hover\:border-y:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }
  .desktop\:border-x {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }
  .desktop\:hover\:border-x:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }
  .desktop\:border-top {
    border-top: 1px solid !important;
  }
  .desktop\:hover\:border-top:hover {
    border-top: 1px solid !important;
  }
  .desktop\:border-right {
    border-right: 1px solid !important;
  }
  .desktop\:hover\:border-right:hover {
    border-right: 1px solid !important;
  }
  .desktop\:border-bottom {
    border-bottom: 1px solid !important;
  }
  .desktop\:hover\:border-bottom:hover {
    border-bottom: 1px solid !important;
  }
  .desktop\:border-left {
    border-left: 1px solid !important;
  }
  .desktop\:hover\:border-left:hover {
    border-left: 1px solid !important;
  }
  .desktop\:border-transparent {
    border-color: transparent !important;
  }
  .desktop\:hover\:border-transparent:hover {
    border-color: transparent !important;
  }
  .desktop\:border-black {
    border-color: black !important;
  }
  .desktop\:hover\:border-black:hover {
    border-color: black !important;
  }
  .desktop\:border-white {
    border-color: white !important;
  }
  .desktop\:hover\:border-white:hover {
    border-color: white !important;
  }
  .desktop\:border-red {
    border-color: #e52207 !important;
  }
  .desktop\:hover\:border-red:hover {
    border-color: #e52207 !important;
  }
  .desktop\:border-orange {
    border-color: #e66f0e !important;
  }
  .desktop\:hover\:border-orange:hover {
    border-color: #e66f0e !important;
  }
  .desktop\:border-gold {
    border-color: #ffbe2e !important;
  }
  .desktop\:hover\:border-gold:hover {
    border-color: #ffbe2e !important;
  }
  .desktop\:border-yellow {
    border-color: #fee685 !important;
  }
  .desktop\:hover\:border-yellow:hover {
    border-color: #fee685 !important;
  }
  .desktop\:border-green {
    border-color: #538200 !important;
  }
  .desktop\:hover\:border-green:hover {
    border-color: #538200 !important;
  }
  .desktop\:border-mint {
    border-color: #04c585 !important;
  }
  .desktop\:hover\:border-mint:hover {
    border-color: #04c585 !important;
  }
  .desktop\:border-cyan {
    border-color: #009ec1 !important;
  }
  .desktop\:hover\:border-cyan:hover {
    border-color: #009ec1 !important;
  }
  .desktop\:border-blue {
    border-color: #0076d6 !important;
  }
  .desktop\:hover\:border-blue:hover {
    border-color: #0076d6 !important;
  }
  .desktop\:border-indigo {
    border-color: #676cc8 !important;
  }
  .desktop\:hover\:border-indigo:hover {
    border-color: #676cc8 !important;
  }
  .desktop\:border-violet {
    border-color: #8168b3 !important;
  }
  .desktop\:hover\:border-violet:hover {
    border-color: #8168b3 !important;
  }
  .desktop\:border-magenta {
    border-color: #d72d79 !important;
  }
  .desktop\:hover\:border-magenta:hover {
    border-color: #d72d79 !important;
  }
  .desktop\:border-gray-5 {
    border-color: #f0f0f0 !important;
  }
  .desktop\:hover\:border-gray-5:hover {
    border-color: #f0f0f0 !important;
  }
  .desktop\:border-gray-10 {
    border-color: #e6e6e6 !important;
  }
  .desktop\:hover\:border-gray-10:hover {
    border-color: #e6e6e6 !important;
  }
  .desktop\:border-gray-30 {
    border-color: #adadad !important;
  }
  .desktop\:hover\:border-gray-30:hover {
    border-color: #adadad !important;
  }
  .desktop\:border-gray-50 {
    border-color: #757575 !important;
  }
  .desktop\:hover\:border-gray-50:hover {
    border-color: #757575 !important;
  }
  .desktop\:border-gray-70 {
    border-color: #454545 !important;
  }
  .desktop\:hover\:border-gray-70:hover {
    border-color: #454545 !important;
  }
  .desktop\:border-gray-90 {
    border-color: #1b1b1b !important;
  }
  .desktop\:hover\:border-gray-90:hover {
    border-color: #1b1b1b !important;
  }
  .desktop\:border-base-lightest {
    border-color: #f0f0f0 !important;
  }
  .desktop\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }
  .desktop\:border-base-lighter {
    border-color: #dfe1e2 !important;
  }
  .desktop\:hover\:border-base-lighter:hover {
    border-color: #dfe1e2 !important;
  }
  .desktop\:border-base-light {
    border-color: #a9aeb1 !important;
  }
  .desktop\:hover\:border-base-light:hover {
    border-color: #a9aeb1 !important;
  }
  .desktop\:border-base {
    border-color: #71767a !important;
  }
  .desktop\:hover\:border-base:hover {
    border-color: #71767a !important;
  }
  .desktop\:border-base-dark {
    border-color: #565c65 !important;
  }
  .desktop\:hover\:border-base-dark:hover {
    border-color: #565c65 !important;
  }
  .desktop\:border-base-darker {
    border-color: #3d4551 !important;
  }
  .desktop\:hover\:border-base-darker:hover {
    border-color: #3d4551 !important;
  }
  .desktop\:border-base-darkest {
    border-color: #2d2e2f !important;
  }
  .desktop\:hover\:border-base-darkest:hover {
    border-color: #2d2e2f !important;
  }
  .desktop\:border-ink {
    border-color: #1c1d1f !important;
  }
  .desktop\:hover\:border-ink:hover {
    border-color: #1c1d1f !important;
  }
  .desktop\:border-primary-lighter {
    border-color: #d9e8f6 !important;
  }
  .desktop\:hover\:border-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }
  .desktop\:border-primary-light {
    border-color: #73b3e7 !important;
  }
  .desktop\:hover\:border-primary-light:hover {
    border-color: #73b3e7 !important;
  }
  .desktop\:border-primary {
    border-color: #005ea2 !important;
  }
  .desktop\:hover\:border-primary:hover {
    border-color: #005ea2 !important;
  }
  .desktop\:border-primary-vivid {
    border-color: #0050d8 !important;
  }
  .desktop\:hover\:border-primary-vivid:hover {
    border-color: #0050d8 !important;
  }
  .desktop\:border-primary-dark {
    border-color: #1a4480 !important;
  }
  .desktop\:hover\:border-primary-dark:hover {
    border-color: #1a4480 !important;
  }
  .desktop\:border-primary-darker {
    border-color: #162e51 !important;
  }
  .desktop\:hover\:border-primary-darker:hover {
    border-color: #162e51 !important;
  }
  .desktop\:border-secondary-lighter {
    border-color: #f3e1e4 !important;
  }
  .desktop\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4 !important;
  }
  .desktop\:border-secondary-light {
    border-color: #f2938c !important;
  }
  .desktop\:hover\:border-secondary-light:hover {
    border-color: #f2938c !important;
  }
  .desktop\:border-secondary {
    border-color: #d83933 !important;
  }
  .desktop\:hover\:border-secondary:hover {
    border-color: #d83933 !important;
  }
  .desktop\:border-secondary-vivid {
    border-color: #e41d3d !important;
  }
  .desktop\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }
  .desktop\:border-secondary-dark {
    border-color: #b50909 !important;
  }
  .desktop\:hover\:border-secondary-dark:hover {
    border-color: #b50909 !important;
  }
  .desktop\:border-secondary-darker {
    border-color: #8b0a03 !important;
  }
  .desktop\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03 !important;
  }
  .desktop\:border-accent-warm-darker {
    border-color: #775540 !important;
  }
  .desktop\:hover\:border-accent-warm-darker:hover {
    border-color: #775540 !important;
  }
  .desktop\:border-accent-warm-dark {
    border-color: #c05600 !important;
  }
  .desktop\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }
  .desktop\:border-accent-warm {
    border-color: #fa9441 !important;
  }
  .desktop\:hover\:border-accent-warm:hover {
    border-color: #fa9441 !important;
  }
  .desktop\:border-accent-warm-light {
    border-color: #ffbc78 !important;
  }
  .desktop\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }
  .desktop\:border-accent-warm-lighter {
    border-color: #f2e4d4 !important;
  }
  .desktop\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }
  .desktop\:border-accent-cool-darker {
    border-color: #07648d !important;
  }
  .desktop\:hover\:border-accent-cool-darker:hover {
    border-color: #07648d !important;
  }
  .desktop\:border-accent-cool-dark {
    border-color: #28a0cb !important;
  }
  .desktop\:hover\:border-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }
  .desktop\:border-accent-cool {
    border-color: #00bde3 !important;
  }
  .desktop\:hover\:border-accent-cool:hover {
    border-color: #00bde3 !important;
  }
  .desktop\:border-accent-cool-light {
    border-color: #97d4ea !important;
  }
  .desktop\:hover\:border-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }
  .desktop\:border-accent-cool-lighter {
    border-color: #e1f3f8 !important;
  }
  .desktop\:hover\:border-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }
  .desktop\:border-error-lighter {
    border-color: #f4e3db !important;
  }
  .desktop\:hover\:border-error-lighter:hover {
    border-color: #f4e3db !important;
  }
  .desktop\:border-error-light {
    border-color: #f39268 !important;
  }
  .desktop\:hover\:border-error-light:hover {
    border-color: #f39268 !important;
  }
  .desktop\:border-error {
    border-color: #d54309 !important;
  }
  .desktop\:hover\:border-error:hover {
    border-color: #d54309 !important;
  }
  .desktop\:border-error-dark {
    border-color: #b50909 !important;
  }
  .desktop\:hover\:border-error-dark:hover {
    border-color: #b50909 !important;
  }
  .desktop\:border-error-darker {
    border-color: #6f3331 !important;
  }
  .desktop\:hover\:border-error-darker:hover {
    border-color: #6f3331 !important;
  }
  .desktop\:border-warning-lighter {
    border-color: #faf3d1 !important;
  }
  .desktop\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1 !important;
  }
  .desktop\:border-warning-light {
    border-color: #fee685 !important;
  }
  .desktop\:hover\:border-warning-light:hover {
    border-color: #fee685 !important;
  }
  .desktop\:border-warning {
    border-color: #ffbe2e !important;
  }
  .desktop\:hover\:border-warning:hover {
    border-color: #ffbe2e !important;
  }
  .desktop\:border-warning-dark {
    border-color: #e5a000 !important;
  }
  .desktop\:hover\:border-warning-dark:hover {
    border-color: #e5a000 !important;
  }
  .desktop\:border-warning-darker {
    border-color: #936f38 !important;
  }
  .desktop\:hover\:border-warning-darker:hover {
    border-color: #936f38 !important;
  }
  .desktop\:border-success-lighter {
    border-color: #ecf3ec !important;
  }
  .desktop\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec !important;
  }
  .desktop\:border-success-light {
    border-color: #70e17b !important;
  }
  .desktop\:hover\:border-success-light:hover {
    border-color: #70e17b !important;
  }
  .desktop\:border-success {
    border-color: #00a91c !important;
  }
  .desktop\:hover\:border-success:hover {
    border-color: #00a91c !important;
  }
  .desktop\:border-success-dark {
    border-color: #008817 !important;
  }
  .desktop\:hover\:border-success-dark:hover {
    border-color: #008817 !important;
  }
  .desktop\:border-success-darker {
    border-color: #216e1f !important;
  }
  .desktop\:hover\:border-success-darker:hover {
    border-color: #216e1f !important;
  }
  .desktop\:border-info-lighter {
    border-color: #e7f6f8 !important;
  }
  .desktop\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8 !important;
  }
  .desktop\:border-info-light {
    border-color: #99deea !important;
  }
  .desktop\:hover\:border-info-light:hover {
    border-color: #99deea !important;
  }
  .desktop\:border-info {
    border-color: #00bde3 !important;
  }
  .desktop\:hover\:border-info:hover {
    border-color: #00bde3 !important;
  }
  .desktop\:border-info-dark {
    border-color: #009ec1 !important;
  }
  .desktop\:hover\:border-info-dark:hover {
    border-color: #009ec1 !important;
  }
  .desktop\:border-info-darker {
    border-color: #2e6276 !important;
  }
  .desktop\:hover\:border-info-darker:hover {
    border-color: #2e6276 !important;
  }
  .desktop\:border-disabled-lighter {
    border-color: #c9c9c9 !important;
  }
  .desktop\:hover\:border-disabled-lighter:hover {
    border-color: #c9c9c9 !important;
  }
  .desktop\:border-disabled-light {
    border-color: #919191 !important;
  }
  .desktop\:hover\:border-disabled-light:hover {
    border-color: #919191 !important;
  }
  .desktop\:border-disabled {
    border-color: #757575 !important;
  }
  .desktop\:hover\:border-disabled:hover {
    border-color: #757575 !important;
  }
  .desktop\:border-disabled-dark {
    border-color: #454545 !important;
  }
  .desktop\:hover\:border-disabled-dark:hover {
    border-color: #454545 !important;
  }
  .desktop\:border-disabled-darker {
    border-color: #1b1b1b !important;
  }
  .desktop\:hover\:border-disabled-darker:hover {
    border-color: #1b1b1b !important;
  }
  .desktop\:border-emergency {
    border-color: #9c3d10 !important;
  }
  .desktop\:hover\:border-emergency:hover {
    border-color: #9c3d10 !important;
  }
  .desktop\:border-emergency-dark {
    border-color: #332d29 !important;
  }
  .desktop\:hover\:border-emergency-dark:hover {
    border-color: #332d29 !important;
  }
  .desktop\:radius-0 {
    border-radius: 0 !important;
  }
  .desktop\:radius-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .desktop\:radius-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .desktop\:radius-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .desktop\:radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .desktop\:radius-sm {
    border-radius: 2px !important;
  }
  .desktop\:radius-top-sm {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
  }
  .desktop\:radius-right-sm {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }
  .desktop\:radius-bottom-sm {
    border-bottom-left-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }
  .desktop\:radius-left-sm {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }
  .desktop\:radius-md {
    border-radius: 0.25rem !important;
  }
  .desktop\:radius-top-md {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .desktop\:radius-right-md {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .desktop\:radius-bottom-md {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .desktop\:radius-left-md {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .desktop\:radius-lg {
    border-radius: 0.5rem !important;
  }
  .desktop\:radius-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .desktop\:radius-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .desktop\:radius-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .desktop\:radius-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .desktop\:radius-pill {
    border-radius: 99rem !important;
  }
  .desktop\:radius-top-pill {
    border-top-left-radius: 99rem !important;
    border-top-right-radius: 99rem !important;
  }
  .desktop\:radius-right-pill {
    border-top-right-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }
  .desktop\:radius-bottom-pill {
    border-bottom-left-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }
  .desktop\:radius-left-pill {
    border-top-left-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }
  .desktop\:display-block {
    display: block !important;
  }
  .desktop\:display-flex {
    display: flex !important;
  }
  .desktop\:display-none {
    display: none !important;
  }
  .desktop\:display-inline {
    display: inline !important;
  }
  .desktop\:display-inline-block {
    display: inline-block !important;
  }
  .desktop\:display-inline-flex {
    display: inline-flex !important;
  }
  .desktop\:display-table {
    display: table !important;
  }
  .desktop\:display-table-cell {
    display: table-cell !important;
  }
  .desktop\:display-table-row {
    display: table-row !important;
  }
  .desktop\:font-mono-3xs {
    font-size: 0.77rem !important;
  }
  .desktop\:font-mono-2xs {
    font-size: 0.83rem !important;
  }
  .desktop\:font-mono-xs {
    font-size: 0.89rem !important;
  }
  .desktop\:font-mono-sm {
    font-size: 0.95rem !important;
  }
  .desktop\:font-mono-md {
    font-size: 1.19rem !important;
  }
  .desktop\:font-mono-lg {
    font-size: 1.31rem !important;
  }
  .desktop\:font-mono-xl {
    font-size: 1.91rem !important;
  }
  .desktop\:font-mono-2xl {
    font-size: 2.38rem !important;
  }
  .desktop\:font-mono-3xl {
    font-size: 2.86rem !important;
  }
  .desktop\:font-sans-3xs {
    font-size: 0.81rem !important;
  }
  .desktop\:font-sans-2xs {
    font-size: 0.87rem !important;
  }
  .desktop\:font-sans-xs {
    font-size: 0.93rem !important;
  }
  .desktop\:font-sans-sm {
    font-size: 0.99rem !important;
  }
  .desktop\:font-sans-md {
    font-size: 1.24rem !important;
  }
  .desktop\:font-sans-lg {
    font-size: 1.37rem !important;
  }
  .desktop\:font-sans-xl {
    font-size: 1.99rem !important;
  }
  .desktop\:font-sans-2xl {
    font-size: 2.49rem !important;
  }
  .desktop\:font-sans-3xl {
    font-size: 2.98rem !important;
  }
  .desktop\:font-serif-3xs {
    font-size: 0.81rem !important;
  }
  .desktop\:font-serif-2xs {
    font-size: 0.87rem !important;
  }
  .desktop\:font-serif-xs {
    font-size: 0.93rem !important;
  }
  .desktop\:font-serif-sm {
    font-size: 0.99rem !important;
  }
  .desktop\:font-serif-md {
    font-size: 1.24rem !important;
  }
  .desktop\:font-serif-lg {
    font-size: 1.37rem !important;
  }
  .desktop\:font-serif-xl {
    font-size: 1.99rem !important;
  }
  .desktop\:font-serif-2xl {
    font-size: 2.49rem !important;
  }
  .desktop\:font-serif-3xl {
    font-size: 2.98rem !important;
  }
  .desktop\:font-heading-3xs {
    font-size: 0.81rem !important;
  }
  .desktop\:font-heading-2xs {
    font-size: 0.87rem !important;
  }
  .desktop\:font-heading-xs {
    font-size: 0.93rem !important;
  }
  .desktop\:font-heading-sm {
    font-size: 0.99rem !important;
  }
  .desktop\:font-heading-md {
    font-size: 1.24rem !important;
  }
  .desktop\:font-heading-lg {
    font-size: 1.37rem !important;
  }
  .desktop\:font-heading-xl {
    font-size: 1.99rem !important;
  }
  .desktop\:font-heading-2xl {
    font-size: 2.49rem !important;
  }
  .desktop\:font-heading-3xl {
    font-size: 2.98rem !important;
  }
  .desktop\:font-body-3xs {
    font-size: 0.81rem !important;
  }
  .desktop\:font-body-2xs {
    font-size: 0.87rem !important;
  }
  .desktop\:font-body-xs {
    font-size: 0.93rem !important;
  }
  .desktop\:font-body-sm {
    font-size: 0.99rem !important;
  }
  .desktop\:font-body-md {
    font-size: 1.24rem !important;
  }
  .desktop\:font-body-lg {
    font-size: 1.37rem !important;
  }
  .desktop\:font-body-xl {
    font-size: 1.99rem !important;
  }
  .desktop\:font-body-2xl {
    font-size: 2.49rem !important;
  }
  .desktop\:font-body-3xl {
    font-size: 2.98rem !important;
  }
  .desktop\:font-code-3xs {
    font-size: 0.77rem !important;
  }
  .desktop\:font-code-2xs {
    font-size: 0.83rem !important;
  }
  .desktop\:font-code-xs {
    font-size: 0.89rem !important;
  }
  .desktop\:font-code-sm {
    font-size: 0.95rem !important;
  }
  .desktop\:font-code-md {
    font-size: 1.19rem !important;
  }
  .desktop\:font-code-lg {
    font-size: 1.31rem !important;
  }
  .desktop\:font-code-xl {
    font-size: 1.91rem !important;
  }
  .desktop\:font-code-2xl {
    font-size: 2.38rem !important;
  }
  .desktop\:font-code-3xl {
    font-size: 2.86rem !important;
  }
  .desktop\:font-alt-3xs {
    font-size: 0.81rem !important;
  }
  .desktop\:font-alt-2xs {
    font-size: 0.87rem !important;
  }
  .desktop\:font-alt-xs {
    font-size: 0.93rem !important;
  }
  .desktop\:font-alt-sm {
    font-size: 0.99rem !important;
  }
  .desktop\:font-alt-md {
    font-size: 1.24rem !important;
  }
  .desktop\:font-alt-lg {
    font-size: 1.37rem !important;
  }
  .desktop\:font-alt-xl {
    font-size: 1.99rem !important;
  }
  .desktop\:font-alt-2xl {
    font-size: 2.49rem !important;
  }
  .desktop\:font-alt-3xl {
    font-size: 2.98rem !important;
  }
  .desktop\:font-ui-3xs {
    font-size: 0.81rem !important;
  }
  .desktop\:font-ui-2xs {
    font-size: 0.87rem !important;
  }
  .desktop\:font-ui-xs {
    font-size: 0.93rem !important;
  }
  .desktop\:font-ui-sm {
    font-size: 0.99rem !important;
  }
  .desktop\:font-ui-md {
    font-size: 1.24rem !important;
  }
  .desktop\:font-ui-lg {
    font-size: 1.37rem !important;
  }
  .desktop\:font-ui-xl {
    font-size: 1.99rem !important;
  }
  .desktop\:font-ui-2xl {
    font-size: 2.49rem !important;
  }
  .desktop\:font-ui-3xl {
    font-size: 2.98rem !important;
  }
  .desktop\:text-light {
    font-weight: 300 !important;
  }
  .desktop\:text-normal {
    font-weight: normal !important;
  }
  .desktop\:text-semibold {
    font-weight: 600 !important;
  }
  .desktop\:text-bold {
    font-weight: 700 !important;
  }
  .desktop\:flex-justify-center {
    justify-content: center !important;
  }
  .desktop\:flex-justify-start {
    justify-content: flex-start !important;
  }
  .desktop\:flex-justify-end {
    justify-content: flex-end !important;
  }
  .desktop\:flex-justify {
    justify-content: space-between !important;
  }
  .desktop\:line-height-sans-1 {
    line-height: 1 !important;
  }
  .desktop\:line-height-sans-2 {
    line-height: 1.2 !important;
  }
  .desktop\:line-height-sans-3 {
    line-height: 1.4 !important;
  }
  .desktop\:line-height-sans-4 {
    line-height: 1.5 !important;
  }
  .desktop\:line-height-sans-5 {
    line-height: 1.6 !important;
  }
  .desktop\:line-height-sans-6 {
    line-height: 1.8 !important;
  }
  .desktop\:line-height-serif-1 {
    line-height: 1 !important;
  }
  .desktop\:line-height-serif-2 {
    line-height: 1.2 !important;
  }
  .desktop\:line-height-serif-3 {
    line-height: 1.4 !important;
  }
  .desktop\:line-height-serif-4 {
    line-height: 1.5 !important;
  }
  .desktop\:line-height-serif-5 {
    line-height: 1.6 !important;
  }
  .desktop\:line-height-serif-6 {
    line-height: 1.8 !important;
  }
  .desktop\:line-height-mono-1 {
    line-height: 1 !important;
  }
  .desktop\:line-height-mono-2 {
    line-height: 1.3 !important;
  }
  .desktop\:line-height-mono-3 {
    line-height: 1.4 !important;
  }
  .desktop\:line-height-mono-4 {
    line-height: 1.6 !important;
  }
  .desktop\:line-height-mono-5 {
    line-height: 1.7 !important;
  }
  .desktop\:line-height-mono-6 {
    line-height: 1.8 !important;
  }
  .desktop\:line-height-heading-1 {
    line-height: 1 !important;
  }
  .desktop\:line-height-heading-2 {
    line-height: 1.2 !important;
  }
  .desktop\:line-height-heading-3 {
    line-height: 1.4 !important;
  }
  .desktop\:line-height-heading-4 {
    line-height: 1.5 !important;
  }
  .desktop\:line-height-heading-5 {
    line-height: 1.6 !important;
  }
  .desktop\:line-height-heading-6 {
    line-height: 1.8 !important;
  }
  .desktop\:line-height-ui-1 {
    line-height: 1 !important;
  }
  .desktop\:line-height-ui-2 {
    line-height: 1.2 !important;
  }
  .desktop\:line-height-ui-3 {
    line-height: 1.4 !important;
  }
  .desktop\:line-height-ui-4 {
    line-height: 1.5 !important;
  }
  .desktop\:line-height-ui-5 {
    line-height: 1.6 !important;
  }
  .desktop\:line-height-ui-6 {
    line-height: 1.8 !important;
  }
  .desktop\:line-height-body-1 {
    line-height: 1 !important;
  }
  .desktop\:line-height-body-2 {
    line-height: 1.2 !important;
  }
  .desktop\:line-height-body-3 {
    line-height: 1.4 !important;
  }
  .desktop\:line-height-body-4 {
    line-height: 1.5 !important;
  }
  .desktop\:line-height-body-5 {
    line-height: 1.6 !important;
  }
  .desktop\:line-height-body-6 {
    line-height: 1.8 !important;
  }
  .desktop\:line-height-code-1 {
    line-height: 1 !important;
  }
  .desktop\:line-height-code-2 {
    line-height: 1.3 !important;
  }
  .desktop\:line-height-code-3 {
    line-height: 1.4 !important;
  }
  .desktop\:line-height-code-4 {
    line-height: 1.6 !important;
  }
  .desktop\:line-height-code-5 {
    line-height: 1.7 !important;
  }
  .desktop\:line-height-code-6 {
    line-height: 1.8 !important;
  }
  .desktop\:line-height-alt-1 {
    line-height: 1 !important;
  }
  .desktop\:line-height-alt-2 {
    line-height: 1.2 !important;
  }
  .desktop\:line-height-alt-3 {
    line-height: 1.4 !important;
  }
  .desktop\:line-height-alt-4 {
    line-height: 1.5 !important;
  }
  .desktop\:line-height-alt-5 {
    line-height: 1.6 !important;
  }
  .desktop\:line-height-alt-6 {
    line-height: 1.8 !important;
  }
  .desktop\:margin-neg-1px {
    margin: -1px !important;
  }
  .desktop\:margin-neg-2px {
    margin: -2px !important;
  }
  .desktop\:margin-neg-05 {
    margin: -0.25rem !important;
  }
  .desktop\:margin-neg-1 {
    margin: -0.5rem !important;
  }
  .desktop\:margin-neg-105 {
    margin: -0.75rem !important;
  }
  .desktop\:margin-neg-2 {
    margin: -1rem !important;
  }
  .desktop\:margin-neg-205 {
    margin: -1.25rem !important;
  }
  .desktop\:margin-neg-3 {
    margin: -1.5rem !important;
  }
  .desktop\:margin-1px {
    margin: 1px !important;
  }
  .desktop\:margin-2px {
    margin: 2px !important;
  }
  .desktop\:margin-05 {
    margin: 0.25rem !important;
  }
  .desktop\:margin-1 {
    margin: 0.5rem !important;
  }
  .desktop\:margin-105 {
    margin: 0.75rem !important;
  }
  .desktop\:margin-2 {
    margin: 1rem !important;
  }
  .desktop\:margin-205 {
    margin: 1.25rem !important;
  }
  .desktop\:margin-3 {
    margin: 1.5rem !important;
  }
  .desktop\:margin-neg-4 {
    margin: -2rem !important;
  }
  .desktop\:margin-neg-5 {
    margin: -2.5rem !important;
  }
  .desktop\:margin-neg-6 {
    margin: -3rem !important;
  }
  .desktop\:margin-neg-7 {
    margin: -3.5rem !important;
  }
  .desktop\:margin-neg-8 {
    margin: -4rem !important;
  }
  .desktop\:margin-neg-9 {
    margin: -4.5rem !important;
  }
  .desktop\:margin-neg-10 {
    margin: -5rem !important;
  }
  .desktop\:margin-neg-15 {
    margin: -7.5rem !important;
  }
  .desktop\:margin-4 {
    margin: 2rem !important;
  }
  .desktop\:margin-5 {
    margin: 2.5rem !important;
  }
  .desktop\:margin-6 {
    margin: 3rem !important;
  }
  .desktop\:margin-7 {
    margin: 3.5rem !important;
  }
  .desktop\:margin-8 {
    margin: 4rem !important;
  }
  .desktop\:margin-9 {
    margin: 4.5rem !important;
  }
  .desktop\:margin-10 {
    margin: 5rem !important;
  }
  .desktop\:margin-15 {
    margin: 7.5rem !important;
  }
  .desktop\:margin-05em {
    margin: 0.5em !important;
  }
  .desktop\:margin-1em {
    margin: 1em !important;
  }
  .desktop\:margin-105em {
    margin: 1.5em !important;
  }
  .desktop\:margin-2em {
    margin: 2em !important;
  }
  .desktop\:margin-0 {
    margin: 0 !important;
  }
  .desktop\:margin-y-1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .desktop\:margin-top-1px {
    margin-top: 1px !important;
  }
  .desktop\:margin-bottom-1px {
    margin-bottom: 1px !important;
  }
  .desktop\:margin-y-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .desktop\:margin-top-2px {
    margin-top: 2px !important;
  }
  .desktop\:margin-bottom-2px {
    margin-bottom: 2px !important;
  }
  .desktop\:margin-y-05 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .desktop\:margin-top-05 {
    margin-top: 0.25rem !important;
  }
  .desktop\:margin-bottom-05 {
    margin-bottom: 0.25rem !important;
  }
  .desktop\:margin-y-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .desktop\:margin-top-1 {
    margin-top: 0.5rem !important;
  }
  .desktop\:margin-bottom-1 {
    margin-bottom: 0.5rem !important;
  }
  .desktop\:margin-y-105 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .desktop\:margin-top-105 {
    margin-top: 0.75rem !important;
  }
  .desktop\:margin-bottom-105 {
    margin-bottom: 0.75rem !important;
  }
  .desktop\:margin-y-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .desktop\:margin-top-2 {
    margin-top: 1rem !important;
  }
  .desktop\:margin-bottom-2 {
    margin-bottom: 1rem !important;
  }
  .desktop\:margin-y-205 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .desktop\:margin-top-205 {
    margin-top: 1.25rem !important;
  }
  .desktop\:margin-bottom-205 {
    margin-bottom: 1.25rem !important;
  }
  .desktop\:margin-y-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .desktop\:margin-top-3 {
    margin-top: 1.5rem !important;
  }
  .desktop\:margin-bottom-3 {
    margin-bottom: 1.5rem !important;
  }
  .desktop\:margin-y-neg-1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .desktop\:margin-top-neg-1px {
    margin-top: -1px !important;
  }
  .desktop\:margin-bottom-neg-1px {
    margin-bottom: -1px !important;
  }
  .desktop\:margin-y-neg-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .desktop\:margin-top-neg-2px {
    margin-top: -2px !important;
  }
  .desktop\:margin-bottom-neg-2px {
    margin-bottom: -2px !important;
  }
  .desktop\:margin-y-neg-05 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .desktop\:margin-top-neg-05 {
    margin-top: -0.25rem !important;
  }
  .desktop\:margin-bottom-neg-05 {
    margin-bottom: -0.25rem !important;
  }
  .desktop\:margin-y-neg-1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .desktop\:margin-top-neg-1 {
    margin-top: -0.5rem !important;
  }
  .desktop\:margin-bottom-neg-1 {
    margin-bottom: -0.5rem !important;
  }
  .desktop\:margin-y-neg-105 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .desktop\:margin-top-neg-105 {
    margin-top: -0.75rem !important;
  }
  .desktop\:margin-bottom-neg-105 {
    margin-bottom: -0.75rem !important;
  }
  .desktop\:margin-y-neg-2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .desktop\:margin-top-neg-2 {
    margin-top: -1rem !important;
  }
  .desktop\:margin-bottom-neg-2 {
    margin-bottom: -1rem !important;
  }
  .desktop\:margin-y-neg-205 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .desktop\:margin-top-neg-205 {
    margin-top: -1.25rem !important;
  }
  .desktop\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem !important;
  }
  .desktop\:margin-y-neg-3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .desktop\:margin-top-neg-3 {
    margin-top: -1.5rem !important;
  }
  .desktop\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem !important;
  }
  .desktop\:margin-y-neg-4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .desktop\:margin-top-neg-4 {
    margin-top: -2rem !important;
  }
  .desktop\:margin-bottom-neg-4 {
    margin-bottom: -2rem !important;
  }
  .desktop\:margin-y-neg-5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .desktop\:margin-top-neg-5 {
    margin-top: -2.5rem !important;
  }
  .desktop\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem !important;
  }
  .desktop\:margin-y-neg-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .desktop\:margin-top-neg-6 {
    margin-top: -3rem !important;
  }
  .desktop\:margin-bottom-neg-6 {
    margin-bottom: -3rem !important;
  }
  .desktop\:margin-y-neg-7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .desktop\:margin-top-neg-7 {
    margin-top: -3.5rem !important;
  }
  .desktop\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem !important;
  }
  .desktop\:margin-y-neg-8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .desktop\:margin-top-neg-8 {
    margin-top: -4rem !important;
  }
  .desktop\:margin-bottom-neg-8 {
    margin-bottom: -4rem !important;
  }
  .desktop\:margin-y-neg-9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .desktop\:margin-top-neg-9 {
    margin-top: -4.5rem !important;
  }
  .desktop\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem !important;
  }
  .desktop\:margin-y-neg-10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .desktop\:margin-top-neg-10 {
    margin-top: -5rem !important;
  }
  .desktop\:margin-bottom-neg-10 {
    margin-bottom: -5rem !important;
  }
  .desktop\:margin-y-neg-15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .desktop\:margin-top-neg-15 {
    margin-top: -7.5rem !important;
  }
  .desktop\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem !important;
  }
  .desktop\:margin-y-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .desktop\:margin-top-4 {
    margin-top: 2rem !important;
  }
  .desktop\:margin-bottom-4 {
    margin-bottom: 2rem !important;
  }
  .desktop\:margin-y-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .desktop\:margin-top-5 {
    margin-top: 2.5rem !important;
  }
  .desktop\:margin-bottom-5 {
    margin-bottom: 2.5rem !important;
  }
  .desktop\:margin-y-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .desktop\:margin-top-6 {
    margin-top: 3rem !important;
  }
  .desktop\:margin-bottom-6 {
    margin-bottom: 3rem !important;
  }
  .desktop\:margin-y-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .desktop\:margin-top-7 {
    margin-top: 3.5rem !important;
  }
  .desktop\:margin-bottom-7 {
    margin-bottom: 3.5rem !important;
  }
  .desktop\:margin-y-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .desktop\:margin-top-8 {
    margin-top: 4rem !important;
  }
  .desktop\:margin-bottom-8 {
    margin-bottom: 4rem !important;
  }
  .desktop\:margin-y-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .desktop\:margin-top-9 {
    margin-top: 4.5rem !important;
  }
  .desktop\:margin-bottom-9 {
    margin-bottom: 4.5rem !important;
  }
  .desktop\:margin-y-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .desktop\:margin-top-10 {
    margin-top: 5rem !important;
  }
  .desktop\:margin-bottom-10 {
    margin-bottom: 5rem !important;
  }
  .desktop\:margin-y-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .desktop\:margin-top-15 {
    margin-top: 7.5rem !important;
  }
  .desktop\:margin-bottom-15 {
    margin-bottom: 7.5rem !important;
  }
  .desktop\:margin-y-05em {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }
  .desktop\:margin-top-05em {
    margin-top: 0.5em !important;
  }
  .desktop\:margin-bottom-05em {
    margin-bottom: 0.5em !important;
  }
  .desktop\:margin-y-1em {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }
  .desktop\:margin-top-1em {
    margin-top: 1em !important;
  }
  .desktop\:margin-bottom-1em {
    margin-bottom: 1em !important;
  }
  .desktop\:margin-y-105em {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }
  .desktop\:margin-top-105em {
    margin-top: 1.5em !important;
  }
  .desktop\:margin-bottom-105em {
    margin-bottom: 1.5em !important;
  }
  .desktop\:margin-y-2em {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }
  .desktop\:margin-top-2em {
    margin-top: 2em !important;
  }
  .desktop\:margin-bottom-2em {
    margin-bottom: 2em !important;
  }
  .desktop\:margin-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .desktop\:margin-top-0 {
    margin-top: 0 !important;
  }
  .desktop\:margin-bottom-0 {
    margin-bottom: 0 !important;
  }
  .desktop\:margin-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .desktop\:margin-top-auto {
    margin-top: auto !important;
  }
  .desktop\:margin-bottom-auto {
    margin-bottom: auto !important;
  }
  .desktop\:margin-x-1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .desktop\:margin-right-1px {
    margin-right: 1px !important;
  }
  .desktop\:margin-left-1px {
    margin-left: 1px !important;
  }
  .desktop\:margin-x-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .desktop\:margin-right-2px {
    margin-right: 2px !important;
  }
  .desktop\:margin-left-2px {
    margin-left: 2px !important;
  }
  .desktop\:margin-x-05 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .desktop\:margin-right-05 {
    margin-right: 0.25rem !important;
  }
  .desktop\:margin-left-05 {
    margin-left: 0.25rem !important;
  }
  .desktop\:margin-x-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .desktop\:margin-right-1 {
    margin-right: 0.5rem !important;
  }
  .desktop\:margin-left-1 {
    margin-left: 0.5rem !important;
  }
  .desktop\:margin-x-105 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .desktop\:margin-right-105 {
    margin-right: 0.75rem !important;
  }
  .desktop\:margin-left-105 {
    margin-left: 0.75rem !important;
  }
  .desktop\:margin-x-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .desktop\:margin-right-2 {
    margin-right: 1rem !important;
  }
  .desktop\:margin-left-2 {
    margin-left: 1rem !important;
  }
  .desktop\:margin-x-205 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .desktop\:margin-right-205 {
    margin-right: 1.25rem !important;
  }
  .desktop\:margin-left-205 {
    margin-left: 1.25rem !important;
  }
  .desktop\:margin-x-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .desktop\:margin-right-3 {
    margin-right: 1.5rem !important;
  }
  .desktop\:margin-left-3 {
    margin-left: 1.5rem !important;
  }
  .desktop\:margin-x-neg-1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }
  .desktop\:margin-right-neg-1px {
    margin-right: -1px !important;
  }
  .desktop\:margin-left-neg-1px {
    margin-left: -1px !important;
  }
  .desktop\:margin-x-neg-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }
  .desktop\:margin-right-neg-2px {
    margin-right: -2px !important;
  }
  .desktop\:margin-left-neg-2px {
    margin-left: -2px !important;
  }
  .desktop\:margin-x-neg-05 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .desktop\:margin-right-neg-05 {
    margin-right: -0.25rem !important;
  }
  .desktop\:margin-left-neg-05 {
    margin-left: -0.25rem !important;
  }
  .desktop\:margin-x-neg-1 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .desktop\:margin-right-neg-1 {
    margin-right: -0.5rem !important;
  }
  .desktop\:margin-left-neg-1 {
    margin-left: -0.5rem !important;
  }
  .desktop\:margin-x-neg-105 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .desktop\:margin-right-neg-105 {
    margin-right: -0.75rem !important;
  }
  .desktop\:margin-left-neg-105 {
    margin-left: -0.75rem !important;
  }
  .desktop\:margin-x-neg-2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .desktop\:margin-right-neg-2 {
    margin-right: -1rem !important;
  }
  .desktop\:margin-left-neg-2 {
    margin-left: -1rem !important;
  }
  .desktop\:margin-x-neg-205 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .desktop\:margin-right-neg-205 {
    margin-right: -1.25rem !important;
  }
  .desktop\:margin-left-neg-205 {
    margin-left: -1.25rem !important;
  }
  .desktop\:margin-x-neg-3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .desktop\:margin-right-neg-3 {
    margin-right: -1.5rem !important;
  }
  .desktop\:margin-left-neg-3 {
    margin-left: -1.5rem !important;
  }
  .desktop\:margin-x-neg-4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .desktop\:margin-right-neg-4 {
    margin-right: -2rem !important;
  }
  .desktop\:margin-left-neg-4 {
    margin-left: -2rem !important;
  }
  .desktop\:margin-x-neg-5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .desktop\:margin-right-neg-5 {
    margin-right: -2.5rem !important;
  }
  .desktop\:margin-left-neg-5 {
    margin-left: -2.5rem !important;
  }
  .desktop\:margin-x-neg-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .desktop\:margin-right-neg-6 {
    margin-right: -3rem !important;
  }
  .desktop\:margin-left-neg-6 {
    margin-left: -3rem !important;
  }
  .desktop\:margin-x-neg-7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .desktop\:margin-right-neg-7 {
    margin-right: -3.5rem !important;
  }
  .desktop\:margin-left-neg-7 {
    margin-left: -3.5rem !important;
  }
  .desktop\:margin-x-neg-8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .desktop\:margin-right-neg-8 {
    margin-right: -4rem !important;
  }
  .desktop\:margin-left-neg-8 {
    margin-left: -4rem !important;
  }
  .desktop\:margin-x-neg-9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .desktop\:margin-right-neg-9 {
    margin-right: -4.5rem !important;
  }
  .desktop\:margin-left-neg-9 {
    margin-left: -4.5rem !important;
  }
  .desktop\:margin-x-neg-10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .desktop\:margin-right-neg-10 {
    margin-right: -5rem !important;
  }
  .desktop\:margin-left-neg-10 {
    margin-left: -5rem !important;
  }
  .desktop\:margin-x-neg-15 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }
  .desktop\:margin-right-neg-15 {
    margin-right: -7.5rem !important;
  }
  .desktop\:margin-left-neg-15 {
    margin-left: -7.5rem !important;
  }
  .desktop\:margin-x-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .desktop\:margin-right-4 {
    margin-right: 2rem !important;
  }
  .desktop\:margin-left-4 {
    margin-left: 2rem !important;
  }
  .desktop\:margin-x-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .desktop\:margin-right-5 {
    margin-right: 2.5rem !important;
  }
  .desktop\:margin-left-5 {
    margin-left: 2.5rem !important;
  }
  .desktop\:margin-x-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .desktop\:margin-right-6 {
    margin-right: 3rem !important;
  }
  .desktop\:margin-left-6 {
    margin-left: 3rem !important;
  }
  .desktop\:margin-x-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .desktop\:margin-right-7 {
    margin-right: 3.5rem !important;
  }
  .desktop\:margin-left-7 {
    margin-left: 3.5rem !important;
  }
  .desktop\:margin-x-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .desktop\:margin-right-8 {
    margin-right: 4rem !important;
  }
  .desktop\:margin-left-8 {
    margin-left: 4rem !important;
  }
  .desktop\:margin-x-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .desktop\:margin-right-9 {
    margin-right: 4.5rem !important;
  }
  .desktop\:margin-left-9 {
    margin-left: 4.5rem !important;
  }
  .desktop\:margin-x-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .desktop\:margin-right-10 {
    margin-right: 5rem !important;
  }
  .desktop\:margin-left-10 {
    margin-left: 5rem !important;
  }
  .desktop\:margin-x-15 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }
  .desktop\:margin-right-15 {
    margin-right: 7.5rem !important;
  }
  .desktop\:margin-left-15 {
    margin-left: 7.5rem !important;
  }
  .desktop\:margin-x-card {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .desktop\:margin-right-card {
    margin-right: 10rem !important;
  }
  .desktop\:margin-left-card {
    margin-left: 10rem !important;
  }
  .desktop\:margin-x-card-lg {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }
  .desktop\:margin-right-card-lg {
    margin-right: 15rem !important;
  }
  .desktop\:margin-left-card-lg {
    margin-left: 15rem !important;
  }
  .desktop\:margin-x-mobile {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }
  .desktop\:margin-right-mobile {
    margin-right: 20rem !important;
  }
  .desktop\:margin-left-mobile {
    margin-left: 20rem !important;
  }
  .desktop\:margin-x-05em {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
  }
  .desktop\:margin-right-05em {
    margin-right: 0.5em !important;
  }
  .desktop\:margin-left-05em {
    margin-left: 0.5em !important;
  }
  .desktop\:margin-x-1em {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }
  .desktop\:margin-right-1em {
    margin-right: 1em !important;
  }
  .desktop\:margin-left-1em {
    margin-left: 1em !important;
  }
  .desktop\:margin-x-105em {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }
  .desktop\:margin-right-105em {
    margin-right: 1.5em !important;
  }
  .desktop\:margin-left-105em {
    margin-left: 1.5em !important;
  }
  .desktop\:margin-x-2em {
    margin-left: 2em !important;
    margin-right: 2em !important;
  }
  .desktop\:margin-right-2em {
    margin-right: 2em !important;
  }
  .desktop\:margin-left-2em {
    margin-left: 2em !important;
  }
  .desktop\:margin-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .desktop\:margin-right-0 {
    margin-right: 0 !important;
  }
  .desktop\:margin-left-0 {
    margin-left: 0 !important;
  }
  .desktop\:margin-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .desktop\:margin-right-auto {
    margin-right: auto !important;
  }
  .desktop\:margin-left-auto {
    margin-left: auto !important;
  }
  .desktop\:measure-1 {
    max-width: 44ex !important;
  }
  .desktop\:measure-2 {
    max-width: 60ex !important;
  }
  .desktop\:measure-3 {
    max-width: 64ex !important;
  }
  .desktop\:measure-4 {
    max-width: 68ex !important;
  }
  .desktop\:measure-5 {
    max-width: 72ex !important;
  }
  .desktop\:measure-6 {
    max-width: 88ex !important;
  }
  .desktop\:measure-none {
    max-width: none !important;
  }
  .desktop\:order-first {
    order: -1 !important;
  }
  .desktop\:order-last {
    order: 999 !important;
  }
  .desktop\:order-initial {
    order: initial !important;
  }
  .desktop\:order-0 {
    order: 0 !important;
  }
  .desktop\:order-1 {
    order: 1 !important;
  }
  .desktop\:order-2 {
    order: 2 !important;
  }
  .desktop\:order-3 {
    order: 3 !important;
  }
  .desktop\:order-4 {
    order: 4 !important;
  }
  .desktop\:order-5 {
    order: 5 !important;
  }
  .desktop\:order-6 {
    order: 6 !important;
  }
  .desktop\:order-7 {
    order: 7 !important;
  }
  .desktop\:order-8 {
    order: 8 !important;
  }
  .desktop\:order-9 {
    order: 9 !important;
  }
  .desktop\:order-10 {
    order: 10 !important;
  }
  .desktop\:order-11 {
    order: 11 !important;
  }
  .desktop\:padding-1px {
    padding: 1px !important;
  }
  .desktop\:padding-y-1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .desktop\:padding-x-1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .desktop\:padding-top-1px {
    padding-top: 1px !important;
  }
  .desktop\:padding-right-1px {
    padding-right: 1px !important;
  }
  .desktop\:padding-bottom-1px {
    padding-bottom: 1px !important;
  }
  .desktop\:padding-left-1px {
    padding-left: 1px !important;
  }
  .desktop\:padding-2px {
    padding: 2px !important;
  }
  .desktop\:padding-y-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .desktop\:padding-x-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .desktop\:padding-top-2px {
    padding-top: 2px !important;
  }
  .desktop\:padding-right-2px {
    padding-right: 2px !important;
  }
  .desktop\:padding-bottom-2px {
    padding-bottom: 2px !important;
  }
  .desktop\:padding-left-2px {
    padding-left: 2px !important;
  }
  .desktop\:padding-05 {
    padding: 0.25rem !important;
  }
  .desktop\:padding-y-05 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .desktop\:padding-x-05 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .desktop\:padding-top-05 {
    padding-top: 0.25rem !important;
  }
  .desktop\:padding-right-05 {
    padding-right: 0.25rem !important;
  }
  .desktop\:padding-bottom-05 {
    padding-bottom: 0.25rem !important;
  }
  .desktop\:padding-left-05 {
    padding-left: 0.25rem !important;
  }
  .desktop\:padding-1 {
    padding: 0.5rem !important;
  }
  .desktop\:padding-y-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .desktop\:padding-x-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .desktop\:padding-top-1 {
    padding-top: 0.5rem !important;
  }
  .desktop\:padding-right-1 {
    padding-right: 0.5rem !important;
  }
  .desktop\:padding-bottom-1 {
    padding-bottom: 0.5rem !important;
  }
  .desktop\:padding-left-1 {
    padding-left: 0.5rem !important;
  }
  .desktop\:padding-105 {
    padding: 0.75rem !important;
  }
  .desktop\:padding-y-105 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .desktop\:padding-x-105 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .desktop\:padding-top-105 {
    padding-top: 0.75rem !important;
  }
  .desktop\:padding-right-105 {
    padding-right: 0.75rem !important;
  }
  .desktop\:padding-bottom-105 {
    padding-bottom: 0.75rem !important;
  }
  .desktop\:padding-left-105 {
    padding-left: 0.75rem !important;
  }
  .desktop\:padding-2 {
    padding: 1rem !important;
  }
  .desktop\:padding-y-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .desktop\:padding-x-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .desktop\:padding-top-2 {
    padding-top: 1rem !important;
  }
  .desktop\:padding-right-2 {
    padding-right: 1rem !important;
  }
  .desktop\:padding-bottom-2 {
    padding-bottom: 1rem !important;
  }
  .desktop\:padding-left-2 {
    padding-left: 1rem !important;
  }
  .desktop\:padding-205 {
    padding: 1.25rem !important;
  }
  .desktop\:padding-y-205 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .desktop\:padding-x-205 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .desktop\:padding-top-205 {
    padding-top: 1.25rem !important;
  }
  .desktop\:padding-right-205 {
    padding-right: 1.25rem !important;
  }
  .desktop\:padding-bottom-205 {
    padding-bottom: 1.25rem !important;
  }
  .desktop\:padding-left-205 {
    padding-left: 1.25rem !important;
  }
  .desktop\:padding-3 {
    padding: 1.5rem !important;
  }
  .desktop\:padding-y-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .desktop\:padding-x-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .desktop\:padding-top-3 {
    padding-top: 1.5rem !important;
  }
  .desktop\:padding-right-3 {
    padding-right: 1.5rem !important;
  }
  .desktop\:padding-bottom-3 {
    padding-bottom: 1.5rem !important;
  }
  .desktop\:padding-left-3 {
    padding-left: 1.5rem !important;
  }
  .desktop\:padding-4 {
    padding: 2rem !important;
  }
  .desktop\:padding-y-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .desktop\:padding-x-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .desktop\:padding-top-4 {
    padding-top: 2rem !important;
  }
  .desktop\:padding-right-4 {
    padding-right: 2rem !important;
  }
  .desktop\:padding-bottom-4 {
    padding-bottom: 2rem !important;
  }
  .desktop\:padding-left-4 {
    padding-left: 2rem !important;
  }
  .desktop\:padding-5 {
    padding: 2.5rem !important;
  }
  .desktop\:padding-y-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .desktop\:padding-x-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .desktop\:padding-top-5 {
    padding-top: 2.5rem !important;
  }
  .desktop\:padding-right-5 {
    padding-right: 2.5rem !important;
  }
  .desktop\:padding-bottom-5 {
    padding-bottom: 2.5rem !important;
  }
  .desktop\:padding-left-5 {
    padding-left: 2.5rem !important;
  }
  .desktop\:padding-6 {
    padding: 3rem !important;
  }
  .desktop\:padding-y-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .desktop\:padding-x-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .desktop\:padding-top-6 {
    padding-top: 3rem !important;
  }
  .desktop\:padding-right-6 {
    padding-right: 3rem !important;
  }
  .desktop\:padding-bottom-6 {
    padding-bottom: 3rem !important;
  }
  .desktop\:padding-left-6 {
    padding-left: 3rem !important;
  }
  .desktop\:padding-7 {
    padding: 3.5rem !important;
  }
  .desktop\:padding-y-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .desktop\:padding-x-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .desktop\:padding-top-7 {
    padding-top: 3.5rem !important;
  }
  .desktop\:padding-right-7 {
    padding-right: 3.5rem !important;
  }
  .desktop\:padding-bottom-7 {
    padding-bottom: 3.5rem !important;
  }
  .desktop\:padding-left-7 {
    padding-left: 3.5rem !important;
  }
  .desktop\:padding-8 {
    padding: 4rem !important;
  }
  .desktop\:padding-y-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .desktop\:padding-x-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .desktop\:padding-top-8 {
    padding-top: 4rem !important;
  }
  .desktop\:padding-right-8 {
    padding-right: 4rem !important;
  }
  .desktop\:padding-bottom-8 {
    padding-bottom: 4rem !important;
  }
  .desktop\:padding-left-8 {
    padding-left: 4rem !important;
  }
  .desktop\:padding-9 {
    padding: 4.5rem !important;
  }
  .desktop\:padding-y-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .desktop\:padding-x-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .desktop\:padding-top-9 {
    padding-top: 4.5rem !important;
  }
  .desktop\:padding-right-9 {
    padding-right: 4.5rem !important;
  }
  .desktop\:padding-bottom-9 {
    padding-bottom: 4.5rem !important;
  }
  .desktop\:padding-left-9 {
    padding-left: 4.5rem !important;
  }
  .desktop\:padding-10 {
    padding: 5rem !important;
  }
  .desktop\:padding-y-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .desktop\:padding-x-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .desktop\:padding-top-10 {
    padding-top: 5rem !important;
  }
  .desktop\:padding-right-10 {
    padding-right: 5rem !important;
  }
  .desktop\:padding-bottom-10 {
    padding-bottom: 5rem !important;
  }
  .desktop\:padding-left-10 {
    padding-left: 5rem !important;
  }
  .desktop\:padding-15 {
    padding: 7.5rem !important;
  }
  .desktop\:padding-y-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .desktop\:padding-x-15 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .desktop\:padding-top-15 {
    padding-top: 7.5rem !important;
  }
  .desktop\:padding-right-15 {
    padding-right: 7.5rem !important;
  }
  .desktop\:padding-bottom-15 {
    padding-bottom: 7.5rem !important;
  }
  .desktop\:padding-left-15 {
    padding-left: 7.5rem !important;
  }
  .desktop\:padding-0 {
    padding: 0 !important;
  }
  .desktop\:padding-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .desktop\:padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .desktop\:padding-top-0 {
    padding-top: 0 !important;
  }
  .desktop\:padding-right-0 {
    padding-right: 0 !important;
  }
  .desktop\:padding-bottom-0 {
    padding-bottom: 0 !important;
  }
  .desktop\:padding-left-0 {
    padding-left: 0 !important;
  }
  .desktop\:width-1px {
    width: 1px !important;
  }
  .desktop\:width-2px {
    width: 2px !important;
  }
  .desktop\:width-05 {
    width: 0.25rem !important;
  }
  .desktop\:width-1 {
    width: 0.5rem !important;
  }
  .desktop\:width-105 {
    width: 0.75rem !important;
  }
  .desktop\:width-2 {
    width: 1rem !important;
  }
  .desktop\:width-205 {
    width: 1.25rem !important;
  }
  .desktop\:width-3 {
    width: 1.5rem !important;
  }
  .desktop\:width-4 {
    width: 2rem !important;
  }
  .desktop\:width-5 {
    width: 2.5rem !important;
  }
  .desktop\:width-6 {
    width: 3rem !important;
  }
  .desktop\:width-7 {
    width: 3.5rem !important;
  }
  .desktop\:width-8 {
    width: 4rem !important;
  }
  .desktop\:width-9 {
    width: 4.5rem !important;
  }
  .desktop\:width-10 {
    width: 5rem !important;
  }
  .desktop\:width-15 {
    width: 7.5rem !important;
  }
  .desktop\:width-card {
    width: 10rem !important;
  }
  .desktop\:width-card-lg {
    width: 15rem !important;
  }
  .desktop\:width-mobile {
    width: 20rem !important;
  }
  .desktop\:width-mobile-lg {
    width: 30rem !important;
  }
  .desktop\:width-tablet {
    width: 40rem !important;
  }
  .desktop\:width-tablet-lg {
    width: 55rem !important;
  }
  .desktop\:width-desktop {
    width: 64rem !important;
  }
  .desktop\:width-desktop-lg {
    width: 75rem !important;
  }
  .desktop\:width-widescreen {
    width: 87.5rem !important;
  }
  .desktop\:width-0 {
    width: 0 !important;
  }
  .desktop\:width-full {
    width: 100% !important;
  }
  .desktop\:width-auto {
    width: auto !important;
  }
}
/* stylelint-disable */
.grid-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-card {
  margin-left: auto;
  margin-right: auto;
  max-width: 10rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-card-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 15rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-mobile {
  margin-left: auto;
  margin-right: auto;
  max-width: 20rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-mobile-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 30rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-tablet {
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-tablet-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 55rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-desktop {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-desktop-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-container-widescreen {
  margin-left: auto;
  margin-right: auto;
  max-width: 87.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.grid-row {
  display: flex;
  flex-wrap: wrap;
}
.grid-row.grid-gap {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.grid-row.grid-gap > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media all and (min-width: 64em) {
  .grid-row.grid-gap {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .grid-row.grid-gap > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.grid-row.grid-gap-0 {
  margin-left: 0;
  margin-right: 0;
}
.grid-row.grid-gap-0 > * {
  padding-left: 0;
  padding-right: 0;
}
.grid-row.grid-gap-2px {
  margin-left: -1px;
  margin-right: -1px;
}
.grid-row.grid-gap-2px > * {
  padding-left: 1px;
  padding-right: 1px;
}
.grid-row.grid-gap-05 {
  margin-left: -2px;
  margin-right: -2px;
}
.grid-row.grid-gap-05 > * {
  padding-left: 2px;
  padding-right: 2px;
}
.grid-row.grid-gap-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.grid-row.grid-gap-1 > * {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.grid-row.grid-gap-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.grid-row.grid-gap-2 > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.grid-row.grid-gap-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.grid-row.grid-gap-3 > * {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.grid-row.grid-gap-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}
.grid-row.grid-gap-4 > * {
  padding-left: 1rem;
  padding-right: 1rem;
}
.grid-row.grid-gap-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.grid-row.grid-gap-5 > * {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.grid-row.grid-gap-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.grid-row.grid-gap-6 > * {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.grid-row.grid-gap-sm {
  margin-left: -1px;
  margin-right: -1px;
}
.grid-row.grid-gap-sm > * {
  padding-left: 1px;
  padding-right: 1px;
}
.grid-row.grid-gap-md {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.grid-row.grid-gap-md > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.grid-row.grid-gap-lg {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.grid-row.grid-gap-lg > * {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
@media all and (min-width: 30em) {
  .grid-row.mobile-lg\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .grid-row.mobile-lg\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .grid-row.mobile-lg\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.mobile-lg\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.mobile-lg\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .grid-row.mobile-lg\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .grid-row.mobile-lg\:grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .grid-row.mobile-lg\:grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .grid-row.mobile-lg\:grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.mobile-lg\:grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .grid-row.mobile-lg\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .grid-row.mobile-lg\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .grid-row.mobile-lg\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .grid-row.mobile-lg\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .grid-row.mobile-lg\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.mobile-lg\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.mobile-lg\:grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.mobile-lg\:grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.mobile-lg\:grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
@media all and (min-width: 40em) {
  .grid-row.tablet\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .grid-row.tablet\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .grid-row.tablet\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.tablet\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.tablet\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .grid-row.tablet\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .grid-row.tablet\:grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .grid-row.tablet\:grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .grid-row.tablet\:grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.tablet\:grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.tablet\:grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.tablet\:grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .grid-row.tablet\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .grid-row.tablet\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .grid-row.tablet\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .grid-row.tablet\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .grid-row.tablet\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .grid-row.tablet\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .grid-row.tablet\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.tablet\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.tablet\:grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.tablet\:grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.tablet\:grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.tablet\:grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
@media all and (min-width: 64em) {
  .grid-row.desktop\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .grid-row.desktop\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .grid-row.desktop\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.desktop\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.desktop\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .grid-row.desktop\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .grid-row.desktop\:grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .grid-row.desktop\:grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .grid-row.desktop\:grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.desktop\:grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.desktop\:grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.desktop\:grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .grid-row.desktop\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .grid-row.desktop\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .grid-row.desktop\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .grid-row.desktop\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .grid-row.desktop\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .grid-row.desktop\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .grid-row.desktop\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .grid-row.desktop\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .grid-row.desktop\:grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .grid-row.desktop\:grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-row.desktop\:grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .grid-row.desktop\:grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
[class*=grid-col] {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.grid-col {
  flex: 1 1 0%;
  width: auto;
  max-width: 100%;
  min-width: 1px;
}
.grid-col-auto {
  flex: 0 1 auto;
  width: auto;
  max-width: 100%;
}
.grid-col-fill {
  flex: 1 1 0%;
  width: auto;
  max-width: 100%;
  min-width: 1px;
}
.grid-col-1 {
  flex: 0 1 auto;
  width: 8.3333333333%;
}
.grid-col-2 {
  flex: 0 1 auto;
  width: 16.6666666667%;
}
.grid-col-3 {
  flex: 0 1 auto;
  width: 25%;
}
.grid-col-4 {
  flex: 0 1 auto;
  width: 33.3333333333%;
}
.grid-col-5 {
  flex: 0 1 auto;
  width: 41.6666666667%;
}
.grid-col-6 {
  flex: 0 1 auto;
  width: 50%;
}
.grid-col-7 {
  flex: 0 1 auto;
  width: 58.3333333333%;
}
.grid-col-8 {
  flex: 0 1 auto;
  width: 66.6666666667%;
}
.grid-col-9 {
  flex: 0 1 auto;
  width: 75%;
}
.grid-col-10 {
  flex: 0 1 auto;
  width: 83.3333333333%;
}
.grid-col-11 {
  flex: 0 1 auto;
  width: 91.6666666667%;
}
.grid-col-12 {
  flex: 0 1 auto;
  width: 100%;
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .mobile-lg\:grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .mobile-lg\:grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .mobile-lg\:grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .mobile-lg\:grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .mobile-lg\:grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .mobile-lg\:grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .mobile-lg\:grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .mobile-lg\:grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .mobile-lg\:grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .mobile-lg\:grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .mobile-lg\:grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .mobile-lg\:grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .mobile-lg\:grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .mobile-lg\:grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .tablet\:grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .tablet\:grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .tablet\:grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .tablet\:grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .tablet\:grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .tablet\:grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .tablet\:grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .tablet\:grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .tablet\:grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .tablet\:grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .tablet\:grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .tablet\:grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .tablet\:grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .tablet\:grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .desktop\:grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .desktop\:grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .desktop\:grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .desktop\:grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .desktop\:grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .desktop\:grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .desktop\:grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .desktop\:grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .desktop\:grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .desktop\:grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .desktop\:grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .desktop\:grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .desktop\:grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .desktop\:grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
.grid-offset-1 {
  margin-left: 8.3333333333%;
}
.grid-offset-2 {
  margin-left: 16.6666666667%;
}
.grid-offset-3 {
  margin-left: 25%;
}
.grid-offset-4 {
  margin-left: 33.3333333333%;
}
.grid-offset-5 {
  margin-left: 41.6666666667%;
}
.grid-offset-6 {
  margin-left: 50%;
}
.grid-offset-7 {
  margin-left: 58.3333333333%;
}
.grid-offset-8 {
  margin-left: 66.6666666667%;
}
.grid-offset-9 {
  margin-left: 75%;
}
.grid-offset-10 {
  margin-left: 83.3333333333%;
}
.grid-offset-11 {
  margin-left: 91.6666666667%;
}
.grid-offset-12 {
  margin-left: 100%;
}
.grid-offset-none {
  margin-left: 0;
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:grid-offset-none {
    margin-left: 0;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:grid-offset-none {
    margin-left: 0;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:grid-offset-none {
    margin-left: 0;
  }
}
/* stylelint-enable */
/* stylelint-disable */
@font-face {
  font-family: "Roboto Mono Web";
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url(./fonts/roboto-mono/roboto-mono-v5-latin-300.woff2) format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url(./fonts/roboto-mono/roboto-mono-v5-latin-regular.woff2) format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url(./fonts/roboto-mono/roboto-mono-v5-latin-700.woff2) format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: url(./fonts/roboto-mono/roboto-mono-v5-latin-300italic.woff2) format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url(./fonts/roboto-mono/roboto-mono-v5-latin-italic.woff2) format("woff2");
}
@font-face {
  font-family: "Roboto Mono Web";
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url(./fonts/roboto-mono/roboto-mono-v5-latin-700italic.woff2) format("woff2");
}
/* stylelint-enable */
.usa-button {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1;
  color: white;
  background-color: #005ea2;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  align-items: center;
  border: 0;
  border-radius: 0.25rem;
  cursor: pointer;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  display: inline-flex;
  font-weight: 700;
  justify-content: center;
  margin-right: 0.5rem;
  padding: 0.75rem 1.25rem;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
@media all and (min-width: 30em) {
  .usa-button {
    width: auto;
  }
}
.usa-button:visited {
  color: white;
}
.usa-button:hover, .usa-button.usa-button--hover {
  color: white;
  background-color: #1a4480;
  border-bottom: 0;
  text-decoration: none;
}
.usa-button:active, .usa-button.usa-button--active {
  color: white;
  background-color: #162e51;
}
.usa-button:not([disabled]):focus, .usa-button:not([disabled]).usa-focus {
  outline-offset: 0.25rem;
}
.usa-button:disabled, .usa-button[aria-disabled=true] {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-button:disabled:hover, .usa-button:disabled:active, .usa-button:disabled:focus, .usa-button:disabled.usa-focus, .usa-button[aria-disabled=true]:hover, .usa-button[aria-disabled=true]:active, .usa-button[aria-disabled=true]:focus, .usa-button[aria-disabled=true].usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-button:disabled, .usa-button[aria-disabled=true] {
    border: 0;
    color: GrayText;
  }
  .usa-button:disabled:hover, .usa-button:disabled:active, .usa-button:disabled:focus, .usa-button:disabled.usa-focus, .usa-button[aria-disabled=true]:hover, .usa-button[aria-disabled=true]:active, .usa-button[aria-disabled=true]:focus, .usa-button[aria-disabled=true].usa-focus {
    color: GrayText;
  }
}
.usa-button:disabled.usa-button--hover, .usa-button:disabled.usa-button--active, .usa-button[aria-disabled=true].usa-button--hover, .usa-button[aria-disabled=true].usa-button--active {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
}
.usa-button:disabled.usa-button--hover:hover, .usa-button:disabled.usa-button--hover:active, .usa-button:disabled.usa-button--hover:focus, .usa-button:disabled.usa-button--hover.usa-focus, .usa-button:disabled.usa-button--active:hover, .usa-button:disabled.usa-button--active:active, .usa-button:disabled.usa-button--active:focus, .usa-button:disabled.usa-button--active.usa-focus, .usa-button[aria-disabled=true].usa-button--hover:hover, .usa-button[aria-disabled=true].usa-button--hover:active, .usa-button[aria-disabled=true].usa-button--hover:focus, .usa-button[aria-disabled=true].usa-button--hover.usa-focus, .usa-button[aria-disabled=true].usa-button--active:hover, .usa-button[aria-disabled=true].usa-button--active:active, .usa-button[aria-disabled=true].usa-button--active:focus, .usa-button[aria-disabled=true].usa-button--active.usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-button:disabled.usa-button--hover, .usa-button:disabled.usa-button--active, .usa-button[aria-disabled=true].usa-button--hover, .usa-button[aria-disabled=true].usa-button--active {
    border: 0;
    color: GrayText;
  }
  .usa-button:disabled.usa-button--hover:hover, .usa-button:disabled.usa-button--hover:active, .usa-button:disabled.usa-button--hover:focus, .usa-button:disabled.usa-button--hover.usa-focus, .usa-button:disabled.usa-button--active:hover, .usa-button:disabled.usa-button--active:active, .usa-button:disabled.usa-button--active:focus, .usa-button:disabled.usa-button--active.usa-focus, .usa-button[aria-disabled=true].usa-button--hover:hover, .usa-button[aria-disabled=true].usa-button--hover:active, .usa-button[aria-disabled=true].usa-button--hover:focus, .usa-button[aria-disabled=true].usa-button--hover.usa-focus, .usa-button[aria-disabled=true].usa-button--active:hover, .usa-button[aria-disabled=true].usa-button--active:active, .usa-button[aria-disabled=true].usa-button--active:focus, .usa-button[aria-disabled=true].usa-button--active.usa-focus {
    color: GrayText;
  }
}
@media (forced-colors: active) {
  .usa-button:disabled:not(.usa-button--unstyled), .usa-button[aria-disabled=true]:not(.usa-button--unstyled) {
    border: 2px solid GrayText;
  }
}
.usa-button .usa-icon {
  flex-shrink: 0;
}
@media (forced-colors: active) {
  .usa-button:not(.usa-button--unstyled) {
    border: 2px solid transparent;
  }
}
.usa-button--accent-cool {
  color: #1c1d1f;
  background-color: #00bde3;
}
.usa-button--accent-cool:visited {
  color: #1c1d1f;
  background-color: #00bde3;
}
.usa-button--accent-cool:hover, .usa-button--accent-cool.usa-button--hover {
  color: white;
  background-color: #28a0cb;
}
.usa-button--accent-cool:active, .usa-button--accent-cool.usa-button--active {
  color: white;
  background-color: #07648d;
}
.usa-button--accent-warm {
  color: #1c1d1f;
  background-color: #fa9441;
}
.usa-button--accent-warm:visited {
  color: #1c1d1f;
  background-color: #fa9441;
}
.usa-button--accent-warm:hover, .usa-button--accent-warm.usa-button--hover {
  color: white;
  background-color: #c05600;
}
.usa-button--accent-warm:active, .usa-button--accent-warm.usa-button--active {
  color: white;
  background-color: #775540;
}
.usa-button--outline {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #005ea2;
  color: #005ea2;
}
.usa-button--outline:visited {
  color: #005ea2;
}
.usa-button--outline:hover, .usa-button--outline.usa-button--hover {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #1a4480;
  color: #1a4480;
}
.usa-button--outline:active, .usa-button--outline.usa-button--active {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #162e51;
  color: #162e51;
}
.usa-button--outline.usa-button--inverse {
  box-shadow: inset 0 0 0 2px #dfe1e2;
  color: #dfe1e2;
}
.usa-button--outline.usa-button--inverse:visited {
  color: #dfe1e2;
}
.usa-button--outline.usa-button--inverse:hover, .usa-button--outline.usa-button--inverse.usa-button--hover {
  box-shadow: inset 0 0 0 2px #f0f0f0;
  color: #f0f0f0;
}
.usa-button--outline.usa-button--inverse:active, .usa-button--outline.usa-button--inverse.usa-button--active {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px white;
  color: white;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled {
  color: #005ea2;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  color: #dfe1e2;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:visited {
  color: #54278f;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:hover {
  color: #1a4480;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:active {
  color: #162e51;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true]:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled.usa-button--hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true].usa-button--hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled:active, .usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--active, .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:active, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true]:active, .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled.usa-button--active, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true].usa-button--active, .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:focus, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true]:focus, .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled.usa-focus, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true].usa-focus, .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true], .usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--hover {
  color: #1a4480;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--active {
  color: #162e51;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true], .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true]:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true], .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true]:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:visited {
  color: #dfe1e2;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--hover {
  color: #f0f0f0;
}
.usa-button--outline.usa-button--inverse.usa-button--unstyled:active, .usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--active {
  color: white;
}
.usa-button--base {
  color: white;
  background-color: #71767a;
}
.usa-button--base:hover, .usa-button--base.usa-button--hover {
  color: white;
  background-color: #565c65;
}
.usa-button--base:active, .usa-button--base.usa-button--active {
  color: white;
  background-color: #3d4551;
}
.usa-button--secondary {
  color: white;
  background-color: #d83933;
}
.usa-button--secondary:hover, .usa-button--secondary.usa-button--hover {
  color: white;
  background-color: #b50909;
}
.usa-button--secondary:active, .usa-button--secondary.usa-button--active {
  color: white;
  background-color: #8b0a03;
}
.usa-button--big {
  border-radius: 0.25rem;
  font-size: 1.37rem;
  padding: 1rem 1.5rem;
}
.usa-button--outline:disabled, .usa-button--outline:disabled:hover, .usa-button--outline:disabled:active, .usa-button--outline:disabled:focus,
.usa-button--outline[aria-disabled=true],
.usa-button--outline[aria-disabled=true]:hover,
.usa-button--outline[aria-disabled=true]:active,
.usa-button--outline[aria-disabled=true]:focus,
.usa-button--outline-inverse:disabled,
.usa-button--outline-inverse:disabled:hover,
.usa-button--outline-inverse:disabled:active,
.usa-button--outline-inverse:disabled:focus,
.usa-button--outline-inverse[aria-disabled=true],
.usa-button--outline-inverse[aria-disabled=true]:hover,
.usa-button--outline-inverse[aria-disabled=true]:active,
.usa-button--outline-inverse[aria-disabled=true]:focus {
  background-color: transparent;
  color: #757575;
}
.usa-button--outline:disabled,
.usa-button--outline[aria-disabled=true] {
  box-shadow: inset 0 0 0 2px #c9c9c9;
}
.usa-button--outline:disabled.usa-button--inverse,
.usa-button--outline[aria-disabled=true].usa-button--inverse {
  box-shadow: inset 0 0 0 2px #919191;
  color: #919191;
}
@media (forced-colors: active) {
  .usa-button--outline:disabled.usa-button--inverse,
  .usa-button--outline[aria-disabled=true].usa-button--inverse {
    color: GrayText;
  }
}
.usa-button--unstyled {
  color: #005ea2;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
}
.usa-button--unstyled:visited {
  color: #54278f;
}
.usa-button--unstyled:hover {
  color: #1a4480;
}
.usa-button--unstyled:active {
  color: #162e51;
}
.usa-button--unstyled:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-button--unstyled:hover, .usa-button--unstyled.usa-button--hover, .usa-button--unstyled:disabled:hover, .usa-button--unstyled[aria-disabled=true]:hover, .usa-button--unstyled:disabled.usa-button--hover, .usa-button--unstyled[aria-disabled=true].usa-button--hover, .usa-button--unstyled:active, .usa-button--unstyled.usa-button--active, .usa-button--unstyled:disabled:active, .usa-button--unstyled[aria-disabled=true]:active, .usa-button--unstyled:disabled.usa-button--active, .usa-button--unstyled[aria-disabled=true].usa-button--active, .usa-button--unstyled:disabled:focus, .usa-button--unstyled[aria-disabled=true]:focus, .usa-button--unstyled:disabled.usa-focus, .usa-button--unstyled[aria-disabled=true].usa-focus, .usa-button--unstyled:disabled, .usa-button--unstyled[aria-disabled=true], .usa-button--unstyled.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-button--unstyled.usa-button--hover {
  color: #1a4480;
}
.usa-button--unstyled.usa-button--active {
  color: #162e51;
}
.usa-button--unstyled:disabled, .usa-button--unstyled[aria-disabled=true], .usa-button--unstyled:disabled:hover, .usa-button--unstyled[aria-disabled=true]:hover, .usa-button--unstyled[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-button--unstyled:disabled, .usa-button--unstyled[aria-disabled=true], .usa-button--unstyled:disabled:hover, .usa-button--unstyled[aria-disabled=true]:hover, .usa-button--unstyled[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  position: relative;
  width: 1em;
}
.usa-icon--size-3 {
  height: 1.5rem;
  width: 1.5rem;
}
.usa-icon--size-4 {
  height: 2rem;
  width: 2rem;
}
.usa-icon--size-5 {
  height: 2.5rem;
  width: 2.5rem;
}
.usa-icon--size-6 {
  height: 3rem;
  width: 3rem;
}
.usa-icon--size-7 {
  height: 3.5rem;
  width: 3.5rem;
}
.usa-icon--size-8 {
  height: 4rem;
  width: 4rem;
}
.usa-icon--size-9 {
  height: 4.5rem;
  width: 4.5rem;
}
/* TODO:
 * Add a way to change the flag breakpoint as a modifier/class?
 */
.usa-card-group {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-prose .usa-card-group {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-card-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
@media all and (min-width: 40em) {
  .usa-card-group {
    flex-direction: row;
  }
}
.usa-card {
  margin-bottom: 2.5rem;
  max-width: none;
}
.usa-card:last-child {
  margin-bottom: 2.5rem;
}
@media all and (min-width: 40em) {
  .usa-card {
    margin-bottom: 2rem;
  }
  .usa-card:last-child {
    margin-bottom: 2rem;
  }
}
.usa-prose .usa-card {
  margin-bottom: 2.5rem;
  max-width: none;
}
.usa-prose .usa-card:last-child {
  margin-bottom: 2.5rem;
}
@media all and (min-width: 40em) {
  .usa-prose .usa-card {
    margin-bottom: 2rem;
  }
  .usa-prose .usa-card:last-child {
    margin-bottom: 2rem;
  }
}
.usa-card__container {
  color: #1c1d1f;
  background-color: white;
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.6;
  border-width: 2px;
  border-color: #dfe1e2;
  border-style: solid;
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
}
.usa-card:not(.usa-card--flag) .usa-card__container > :only-child {
  padding: 1.5rem;
}
.usa-card .usa-card__img {
  display: block;
}
.usa-card__header {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
}
.usa-card__header:last-child {
  padding-bottom: 1.5rem;
}
.usa-card__heading {
  font-family: var(--base-font-family), ;
  font-size: 1.37rem;
  line-height: 1.2;
  margin: 0;
}
.usa-card__media {
  order: -1;
  min-height: 1px;
}
.usa-card__img {
  border-top-left-radius: calc(0.5rem - 2px);
  border-top-right-radius: calc(0.5rem - 2px);
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
}
.usa-card__img img {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.usa-card__media--inset {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.usa-card__media--inset .usa-card__img {
  border-radius: 0;
}
.usa-card__body {
  flex: 1 1 0%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  flex-basis: auto;
}
.usa-card__body:last-child {
  padding-bottom: 1.5rem;
}
.usa-card__body:first-child {
  padding-top: 1.5rem;
}
.usa-card__body:only-child {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.usa-card__footer {
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
}
.usa-card__footer .usa-button:only-of-type {
  margin-right: 0;
}
.usa-card__header > :last-child,
.usa-card__body > :last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.usa-card__header > :first-child,
.usa-card__body > :first-child {
  margin-top: 0;
  padding-top: 0;
}
.usa-card__header > :only-child,
.usa-card__body > :only-child {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.usa-card__header--exdent,
.usa-card__media--exdent,
.usa-card__body--exdent,
.usa-card__footer--exdent {
  margin-inline: -2px;
}
.usa-card__header--exdent > *,
.usa-card__body--exdent > *,
.usa-card__footer--exdent > * {
  padding-left: 2px;
  padding-right: 2px;
}
.usa-card__media--exdent {
  margin-top: -2px;
}
.usa-card__media--exdent .usa-card__img {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.usa-card--header-first .usa-card__header {
  border-top-left-radius: calc(0.5rem - 2px);
  border-top-right-radius: calc(0.5rem - 2px);
  padding-bottom: 1rem;
}
.usa-card--header-first .usa-card__header--exdent {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-top: -2px;
}
.usa-card--header-first .usa-card__media--inset {
  padding-top: 0;
}
.usa-card--header-first .usa-card__media {
  order: 0;
}
.usa-card--header-first .usa-card__media--exdent {
  margin-top: 0;
}
.usa-card--header-first .usa-card__img {
  border-radius: 0;
}
.usa-card--header-first .usa-card__body {
  padding-top: 1rem;
}
@media all and (min-width: 40em) {
  .usa-card--flag .usa-card__media {
    display: flex;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    top: 0;
    position: absolute;
    left: 0;
    position: absolute;
    width: 15rem;
  }
  .usa-card--flag .usa-card__img {
    border-radius: 0;
    border-top-left-radius: calc(0.5rem - 2px);
    border-bottom-left-radius: calc(0.5rem - 2px);
  }
  .usa-card--flag .usa-card__header,
  .usa-card--flag .usa-card__body,
  .usa-card--flag .usa-card__footer {
    margin-left: 15rem;
  }
  .usa-card--flag .usa-card__media--exdent {
    margin: -2px;
  }
  .usa-card--flag .usa-card__media--exdent .usa-card__img {
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  }
  .usa-card--flag .usa-card__media--inset {
    padding-right: 0;
    padding-bottom: 1.5rem;
  }
  .usa-card--flag .usa-card__media--inset .usa-card__img {
    border-radius: 0;
  }
  .usa-card--flag.usa-card--header-first .usa-card__header {
    padding-bottom: 0.5rem;
  }
  .usa-card--flag.usa-card--header-first .usa-card__body {
    padding-top: 0.5rem;
  }
  .usa-card--flag.usa-card--header-first .usa-card__media--inset {
    padding-top: 1.5rem;
  }
  .usa-card--flag.usa-card--media-right .usa-card__media {
    left: auto;
    right: 0;
  }
  .usa-card--flag.usa-card--media-right .usa-card__media--inset {
    padding-left: 0;
    padding-right: 1.5rem;
  }
  .usa-card--flag.usa-card--media-right .usa-card__media--inset .usa-card__img {
    border-radius: 0;
  }
  .usa-card--flag.usa-card--media-right .usa-card__img {
    border-radius: 0;
    border-top-right-radius: calc(0.5rem - 2px);
    border-bottom-right-radius: calc(0.5rem - 2px);
  }
  .usa-card--flag.usa-card--media-right .usa-card__header,
  .usa-card--flag.usa-card--media-right .usa-card__body,
  .usa-card--flag.usa-card--media-right .usa-card__footer {
    margin-left: 0;
    margin-right: 15rem;
  }
  .usa-card--flag.usa-card--media-right .usa-card__img {
    border-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
}
.usa-card:not(.usa-card--flag).usa-card__media--set-aspect {
  box-sizing: border-box;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  position: relative;
}
@supports (aspect-ratio: 1) {
  .usa-card:not(.usa-card--flag).usa-card__media--set-aspect {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1.7777777778;
    max-width: 100%;
  }
  .usa-card:not(.usa-card--flag).usa-card__media--set-aspect > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img.usa-card:not(.usa-card--flag).usa-card__media--set-aspect, .usa-card:not(.usa-card--flag).usa-card__media--set-aspect > img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.usa-card:not(.usa-card--flag).usa-card__media--set-aspect .usa-card__img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.usa-button-group {
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style-type: none;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  padding-left: 0;
}
@media all and (min-width: 30em) {
  .usa-button-group {
    flex-wrap: nowrap;
    align-items: stretch;
    flex-direction: row;
  }
}
.usa-button-group .usa-button-group {
  height: 100%;
}
@media all and (min-width: 30em) {
  .usa-button-group .usa-button-group .usa-button-group__item {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.usa-button-group .usa-button-group--segmented .usa-button-group__item {
  margin-top: 0;
  margin-bottom: 0;
}
.usa-button-group__item {
  margin: 0.25rem;
}
@media all and (min-width: 30em) {
  .usa-button-group__item:last-child {
    margin-right: 0;
  }
}
.usa-button-group__item .usa-button {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
}
.usa-button-group--segmented {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
}
@media all and (min-width: 30em) {
  .usa-button-group--segmented {
    justify-content: flex-start;
  }
}
.usa-button-group--segmented .usa-button {
  position: relative;
  width: calc(100% + 2px);
}
@media all and (min-width: 30em) {
  .usa-button-group--segmented .usa-button {
    width: auto;
  }
}
.usa-button-group--segmented .usa-button:hover, .usa-button-group--segmented .usa-button:active {
  z-index: 2;
}
.usa-button-group--segmented .usa-button:focus {
  z-index: 3;
}
.usa-button-group--segmented .usa-button-group__item {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
@media all and (min-width: 30em) {
  .usa-button-group--segmented .usa-button-group__item {
    width: auto;
  }
}
.usa-button-group--segmented .usa-button-group__item:first-child > .usa-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}
.usa-button-group--segmented .usa-button-group__item:last-child > .usa-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-right: 0;
  margin-left: -2px;
  width: calc(100% + 2px);
}
@media all and (min-width: 30em) {
  .usa-button-group--segmented .usa-button-group__item:last-child > .usa-button {
    margin-left: -1px;
    width: auto;
  }
}
.usa-button-group--segmented .usa-button-group__item:where(:not(:first-child):not(:last-child)) > .usa-button {
  border-radius: 0;
  margin-right: -1px;
  margin-left: -1px;
}
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button::before {
  border-right: 1px solid #1a4480;
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: 1px;
  top: 0;
  width: 1px;
  z-index: 3;
}
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button--secondary::before {
  border-right-color: #b50909;
}
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button--accent-cool::before {
  border-right-color: #28a0cb;
}
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button--base::before {
  border-right-color: #565c65;
}
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) [class*=usa-button]:disabled::before,
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) [class*=usa-button][aria-disabled=true]::before {
  border-right-color: white;
}
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button:active::before,
.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button--outline::before {
  display: none;
}
.usa-js-no-click {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.usa-js-no-click .usa-modal,
.usa-js-no-click .usa-modal * {
  pointer-events: auto;
  -webkit-user-select: text;
     -moz-user-select: text;
          user-select: text;
}
[data-open-modal] * {
  pointer-events: none;
}
.usa-modal-wrapper {
  text-align: center;
  transition: opacity 0.15s ease-in-out;
}
.usa-modal-wrapper.is-hidden {
  visibility: hidden;
  opacity: 0;
  position: fixed;
}
.usa-modal-wrapper.is-visible {
  visibility: visible;
  opacity: 1;
  position: fixed;
  z-index: 99999;
}
.usa-modal-overlay {
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: scroll;
  overflow-x: hidden;
  padding: 1.5rem;
  position: fixed;
  scroll-behavior: smooth;
  top: 0;
  width: 100%;
}
.usa-modal-overlay:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.usa-modal-overlay[data-force-action=true] {
  pointer-events: none;
}
.usa-modal-overlay[data-force-action=true] * {
  pointer-events: auto;
}
.usa-js-loading .usa-modal-wrapper {
  position: absolute;
  left: -999em;
  right: auto;
}
.usa-js-loading .usa-modal-wrapper:target {
  position: static;
}
.usa-modal {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.6;
  border-radius: 0.5rem;
  background: white;
  color: #1c1d1f;
  display: inline-block;
  margin: 1.25rem auto;
  max-width: 30rem;
  position: relative;
  text-align: left;
  vertical-align: middle;
  width: 100%;
}
.usa-modal:focus {
  outline: none;
}
.usa-modal__content {
  display: flex;
  flex-direction: column-reverse;
  padding-top: 2rem;
  width: 100%;
}
.usa-modal__main {
  margin: 0 auto;
  padding: 0.5rem 2rem 2rem;
}
.usa-modal-wrapper [data-close-modal] > .usa-icon,
.usa-modal-wrapper [data-close-modal] > .usa-icon use {
  pointer-events: none !important;
}
.usa-modal__close {
  align-items: center;
  align-self: flex-end;
  background-color: transparent;
  color: #71767a;
  display: flex;
  flex-shrink: 0;
  font-size: 0.87rem;
  margin: -2rem 0 0 auto;
  padding: 0.25rem 0.25rem;
  width: auto;
}
.usa-modal__close:hover, .usa-modal__close:active {
  background-color: transparent;
  color: #1c1d1f;
}
.usa-modal__close:focus {
  outline-offset: 0;
}
.usa-modal__close .usa-icon {
  height: 2rem;
  margin: 2px 2px 0 0;
  width: 2rem;
}
.usa-modal__heading {
  font-family: var(--base-font-family), ;
  font-size: 1.37rem;
  line-height: 1.4;
  margin-top: 0;
}
.usa-modal__footer {
  margin-top: 1.5rem;
}
.usa-modal--lg {
  max-width: 55rem;
  width: 100%;
}
.usa-modal--lg .usa-modal__main {
  padding-bottom: 4rem;
  padding-top: 1.25rem;
  width: 100%;
  max-width: 40rem;
}
@media all and (min-width: 40em) {
  .usa-modal--lg .usa-modal__heading {
    font-family: var(--base-font-family), ;
    font-size: 1.99rem;
  }
}
.usa-js-modal--active {
  overflow: hidden;
}
.usa-input:disabled, .usa-input[aria-disabled=true] {
  color: #454545;
  background-color: #c9c9c9;
  cursor: not-allowed;
  opacity: 1;
  -webkit-text-fill-color: #454545;
}
.usa-input:disabled:hover, .usa-input:disabled:active, .usa-input:disabled:focus, .usa-input:disabled.usa-focus, .usa-input[aria-disabled=true]:hover, .usa-input[aria-disabled=true]:active, .usa-input[aria-disabled=true]:focus, .usa-input[aria-disabled=true].usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}
@media (forced-colors: active) {
  .usa-input:disabled, .usa-input[aria-disabled=true] {
    border: 0;
    color: GrayText;
  }
  .usa-input:disabled:hover, .usa-input:disabled:active, .usa-input:disabled:focus, .usa-input:disabled.usa-focus, .usa-input[aria-disabled=true]:hover, .usa-input[aria-disabled=true]:active, .usa-input[aria-disabled=true]:focus, .usa-input[aria-disabled=true].usa-focus {
    color: GrayText;
  }
}
@media (forced-colors: active) {
  .usa-input:disabled, .usa-input[aria-disabled=true] {
    border: 2px solid GrayText;
  }
}
.usa-input--error {
  border-width: 0.25rem;
  border-color: #b50909;
  border-style: solid;
  padding-top: calc(0.5rem - 0.25rem);
  padding-bottom: calc(0.5rem - 0.25rem);
}
.usa-input--success {
  border-width: 0.25rem;
  border-color: #00a91c;
  border-style: solid;
  padding-top: calc(0.5rem - 0.25rem);
  padding-bottom: calc(0.5rem - 0.25rem);
}
.usa-form {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.4;
}
@media all and (min-width: 30em) {
  .usa-form {
    max-width: 20rem;
  }
}
.usa-form abbr[title=required] {
  text-decoration: none;
}
.usa-form .usa-input,
.usa-form .usa-range,
.usa-form .usa-select,
.usa-form .usa-textarea {
  max-width: none;
}
.usa-form .usa-input--2xs,
.usa-form .usa-input-group--2xs {
  max-width: 5ex;
}
.usa-form .usa-input--xs,
.usa-form .usa-input-group--xs {
  max-width: 9ex;
}
.usa-form .usa-input--sm, .usa-form .usa-input--small,
.usa-form .usa-input-group--sm,
.usa-form .usa-input-group--small {
  max-width: 13ex;
}
.usa-form .usa-input--md, .usa-form .usa-input--medium,
.usa-form .usa-input-group--md,
.usa-form .usa-input-group--medium {
  max-width: 20ex;
}
.usa-form .usa-input--lg,
.usa-form .usa-input-group--lg {
  max-width: 30ex;
}
.usa-form .usa-input--xl,
.usa-form .usa-input-group--xl {
  max-width: 40ex;
}
.usa-form .usa-input--2xl,
.usa-form .usa-input-group--2xl {
  max-width: 50ex;
}
.usa-form .usa-button {
  margin-top: 0.5rem;
}
@media all and (min-width: 30em) {
  .usa-form .usa-button {
    margin-top: 1.5rem;
  }
}
.usa-form a:where(:not(.usa-button)) {
  color: #005ea2;
  text-decoration: underline;
}
.usa-form a:where(:not(.usa-button)):visited {
  color: #54278f;
}
.usa-form a:where(:not(.usa-button)):hover {
  color: #1a4480;
}
.usa-form a:where(:not(.usa-button)):active {
  color: #162e51;
}
.usa-form a:where(:not(.usa-button)):focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
@media all and (min-width: 30em) {
  .usa-form--large {
    max-width: 30rem;
  }
}
.usa-show-password {
  color: #005ea2;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  cursor: pointer;
}
.usa-show-password:visited {
  color: #54278f;
}
.usa-show-password:hover {
  color: #1a4480;
}
.usa-show-password:active {
  color: #162e51;
}
.usa-show-password:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-show-password:hover, .usa-show-password.usa-button--hover, .usa-show-password:disabled:hover, .usa-show-password[aria-disabled=true]:hover, .usa-show-password:disabled.usa-button--hover, .usa-show-password[aria-disabled=true].usa-button--hover, .usa-show-password:active, .usa-show-password.usa-button--active, .usa-show-password:disabled:active, .usa-show-password[aria-disabled=true]:active, .usa-show-password:disabled.usa-button--active, .usa-show-password[aria-disabled=true].usa-button--active, .usa-show-password:disabled:focus, .usa-show-password[aria-disabled=true]:focus, .usa-show-password:disabled.usa-focus, .usa-show-password[aria-disabled=true].usa-focus, .usa-show-password:disabled, .usa-show-password[aria-disabled=true], .usa-show-password.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-show-password.usa-button--hover {
  color: #1a4480;
}
.usa-show-password.usa-button--active {
  color: #162e51;
}
.usa-show-password:disabled, .usa-show-password[aria-disabled=true], .usa-show-password:disabled:hover, .usa-show-password[aria-disabled=true]:hover, .usa-show-password[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-show-password:disabled, .usa-show-password[aria-disabled=true], .usa-show-password:disabled:hover, .usa-show-password[aria-disabled=true]:hover, .usa-show-password[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-form__note,
.usa-show-password {
  font-family: var(--base-font-family), ;
  font-size: 0.87rem;
  line-height: 1.4;
  float: right;
  margin: 0.25rem 0 1rem;
}
.usa-label {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.4;
  display: block;
  font-weight: normal;
  margin-top: 1.5rem;
  max-width: 30rem;
}
.usa-label--error {
  font-weight: 700;
  margin-top: 0;
}
.usa-label--required {
  color: #b50909;
}
.usa-list {
  margin-bottom: 1em;
  margin-top: 1em;
  line-height: 1.6;
  padding-left: 3ch;
}
.usa-list:last-child {
  margin-bottom: 0;
}
.usa-list ul,
.usa-list ol {
  margin-top: 0.25em;
}
.usa-list li {
  margin-bottom: 0.25em;
  max-width: 68ex;
}
.usa-list li:last-child {
  margin-bottom: 0;
}
.usa-list--unstyled {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-list--unstyled > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-prose .usa-list--unstyled {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-prose .usa-list--unstyled > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-footer {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.6;
  overflow: hidden;
}
.usa-footer > .grid-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .usa-footer > .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.usa-footer__return-to-top {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  line-height: 1;
}
.usa-footer__return-to-top a {
  color: #005ea2;
  text-decoration: underline;
}
.usa-footer__return-to-top a:visited {
  color: #54278f;
}
.usa-footer__return-to-top a:hover {
  color: #1a4480;
}
.usa-footer__return-to-top a:active {
  color: #162e51;
}
.usa-footer__return-to-top a:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-footer__nav {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #a9aeb1;
}
@media all and (min-width: 64em) {
  .usa-footer__nav {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .usa-footer__nav {
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: none;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .usa-footer__nav {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.usa-footer__nav > ul {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-footer__primary-section {
  background-color: #f0f0f0;
}
.usa-footer__primary-section > .grid-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .usa-footer__primary-section > .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.usa-footer__primary-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .usa-footer__primary-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .usa-footer__primary-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.usa-footer__primary-content {
  line-height: 1.2;
}
.usa-footer__primary-link {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  color: #1c1d1f;
  font-weight: 700;
  display: block;
}
@media all and (min-width: 30em) {
  .usa-footer__primary-link {
    padding-left: 0;
    padding-right: 0;
  }
}
.usa-footer__primary-link--button {
  width: 100%;
  border: 0;
  cursor: pointer;
}
.usa-footer__primary-link--button::before {
  background-image: url("./img/usa-icons/expand_more.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.25rem 1.25rem;
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  content: "";
  vertical-align: middle;
  margin-right: 0.25rem;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-footer__primary-link--button::before {
    background: none;
    background-color: currentColor;
    -webkit-mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(transparent, transparent);
            mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
            mask-position: center center;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-size: 1.25rem 1.25rem;
            mask-size: 1.25rem 1.25rem;
  }
}
.usa-footer__primary-link--button:not([disabled]):focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: -0.25rem;
}
.usa-footer__primary-link--button::before {
  height: 1.25rem;
  width: 1.25rem;
  align-items: center;
  background-size: contain;
  content: "";
  display: inline-flex;
  justify-content: center;
  margin-right: 0.25rem;
  margin-left: -0.25rem;
}
@media (forced-colors: active) {
  .usa-footer__primary-link--button::before {
    background-color: buttonText !important;
  }
}
.usa-footer__primary-link--button + .usa-list--unstyled {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.usa-footer__primary-link--button[aria-expanded=false]::before {
  background-image: url("./img/usa-icons/navigate_next.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.25rem 1.25rem;
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  content: "";
  vertical-align: middle;
  margin-right: 0.25rem;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-footer__primary-link--button[aria-expanded=false]::before {
    background: none;
    background-color: currentColor;
    -webkit-mask-image: url("./img/usa-icons/navigate_next.svg"), linear-gradient(transparent, transparent);
            mask-image: url("./img/usa-icons/navigate_next.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
            mask-position: center center;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-size: 1.25rem 1.25rem;
            mask-size: 1.25rem 1.25rem;
  }
}
.usa-footer__primary-link--button[aria-expanded=false] + .usa-list--unstyled {
  display: none;
}
.usa-footer__secondary-link {
  line-height: 1.2;
  margin-left: 1rem;
  padding: 0;
}
.usa-footer__secondary-link a {
  color: #005ea2;
  text-decoration: underline;
}
.usa-footer__secondary-link a:visited {
  color: #54278f;
}
.usa-footer__secondary-link a:hover {
  color: #1a4480;
}
.usa-footer__secondary-link a:active {
  color: #162e51;
}
.usa-footer__secondary-link a:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-footer__secondary-link + .usa-footer__secondary-link {
  padding-top: 1rem;
}
@media all and (min-width: 30em) {
  .usa-footer__secondary-link {
    margin-left: 0;
  }
}
.usa-footer__contact-info {
  line-height: 1.2;
}
.usa-footer__contact-info a {
  color: #1c1d1f;
}
@media all and (min-width: 30em) {
  .usa-footer__contact-info {
    justify-content: flex-end;
    margin-top: 0.5rem;
  }
}
.usa-footer__primary-content {
  border-top: 1px solid #a9aeb1;
}
@media all and (min-width: 30em) {
  .usa-footer__primary-content {
    border: none;
  }
}
.usa-sign-up {
  padding-bottom: 2rem;
  padding-top: 1.5rem;
}
.usa-sign-up .usa-label,
.usa-sign-up .usa-button {
  margin-top: 0.75rem;
}
.usa-sign-up__heading {
  font-family: var(--base-font-family), ;
  font-size: 1.37rem;
  line-height: 1.2;
  font-weight: 700;
  margin: 0;
}
.usa-footer__secondary-section {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  color: #1c1d1f;
  background-color: #dfe1e2;
}
.usa-footer__secondary-section > .grid-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .usa-footer__secondary-section > .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.usa-footer__secondary-section > .grid-container > .grid-row {
  justify-content: space-between;
}
.usa-footer__secondary-section a {
  color: #1c1d1f;
}
.usa-footer__logo {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
@media all and (min-width: 30em) {
  .usa-footer__logo {
    margin-bottom: 0;
    margin-top: 0;
    align-items: center;
  }
}
.usa-footer__logo-img {
  max-width: 5rem;
}
.usa-footer__logo-heading {
  font-family: var(--base-font-family), ;
  font-size: 1.37rem;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.usa-footer__contact-links {
  margin-top: 1.5rem;
}
@media all and (min-width: 30em) {
  .usa-footer__contact-links {
    margin-top: 0;
    text-align: right;
  }
}
.usa-footer__contact-heading {
  font-family: var(--base-font-family), ;
  font-size: 1.37rem;
  line-height: 1.2;
  font-weight: 700;
  margin-top: 0;
}
@media all and (min-width: 30em) {
  .usa-footer__contact-heading {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
  }
}
.usa-footer__social-links {
  line-height: 1;
  padding-bottom: 0.5rem;
}
.usa-footer__social-links a {
  text-decoration: none;
}
@media all and (min-width: 30em) {
  .usa-footer__social-links {
    justify-content: flex-end;
  }
}
.usa-social-link {
  height: 3rem;
  width: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  display: inline-block;
  padding: 0.25rem;
}
.usa-social-link:hover {
  background-color: white;
}
@media (forced-colors: active) {
  .usa-social-link {
    background-color: lightgrey;
    forced-color-adjust: none;
  }
}
.usa-social-link__icon {
  display: block;
  height: auto;
  width: 100%;
}
@media all and (min-width: 30em) {
  .usa-footer__address {
    justify-content: flex-end;
  }
}
@media all and (min-width: 64em) {
  .usa-footer--slim .usa-footer__nav {
    padding-left: 0;
    padding-right: 0;
  }
}
.usa-footer--slim .usa-footer__address {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
@media all and (min-width: 30em) {
  .usa-footer--slim .usa-footer__address {
    padding: 0;
  }
}
.usa-footer--slim .usa-footer__logo {
  align-items: center;
}
.usa-footer--slim .usa-footer__logo-img {
  max-width: 3rem;
}
.usa-footer--slim .usa-footer__contact-info {
  display: inline-block;
}
@media all and (min-width: 30em) {
  .usa-footer--slim .usa-footer__contact-info {
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-top: 0;
  }
}
.usa-footer--big .usa-footer__nav {
  margin-left: -1rem;
  margin-right: -1rem;
}
@media all and (min-width: 30em) {
  .usa-footer--big .usa-footer__nav {
    border-bottom: 1px solid #a9aeb1;
    padding-top: 2rem;
  }
}
@media all and (min-width: 40em) {
  .usa-footer--big .usa-footer__nav {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    border-bottom: none;
  }
}
.usa-footer--big .usa-footer__primary-link {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.2;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
}
@media all and (min-width: 30em) {
  .usa-footer--big .usa-footer__primary-link {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 0.5rem;
  }
  .usa-footer--big .usa-footer__primary-link:hover {
    cursor: auto;
    text-decoration: none;
  }
}
.usa-footer--big .usa-footer__primary-content--collapsible .usa-footer__primary-link {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.usa-footer--big .usa-footer__primary-content--collapsible .usa-list--unstyled {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.25rem;
}
@media all and (min-width: 30em) {
  .usa-footer--big .usa-footer__primary-content--collapsible .usa-list--unstyled {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2rem;
    padding-top: 0.75rem;
  }
}
.usa-focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-sr-only {
  position: absolute;
  left: -999em;
  right: auto;
}
.usa-skipnav {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.6;
  color: #005ea2;
  text-decoration: underline;
  background: transparent;
  left: 0;
  padding: 0.5rem 1rem;
  position: absolute;
  top: -3.8rem;
  transition: 0.15s ease-in-out;
  z-index: 100;
}
.usa-skipnav:visited {
  color: #54278f;
}
.usa-skipnav:hover {
  color: #1a4480;
}
.usa-skipnav:active {
  color: #162e51;
}
.usa-skipnav:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-skipnav:focus, .usa-skipnav.usa-focus {
  background: white;
  left: 0;
  position: absolute;
  top: 0;
  transition: 0.15s ease-in-out;
}
.usa-skipnav__container {
  position: relative;
}
.usa-search {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.6;
  position: relative;
}
.usa-search::after {
  clear: both;
  content: "";
  display: block;
}
.usa-search[role=search], .usa-search[role=search] > div,
.usa-search [role=search] {
  display: flex;
}
.usa-search [type=submit] {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 2rem;
  margin: 0;
  padding: 0;
  width: 3rem;
}
@media all and (min-width: 30em) {
  .usa-search [type=submit] {
    padding-left: 1rem;
    padding-right: 1rem;
    width: auto;
  }
}
@media (forced-colors: active) {
  .usa-search [type=submit]::before {
    background-image: url("./img/usa-icons/search.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.5rem 1.5rem;
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    content: "";
    vertical-align: middle;
    margin-right: auto;
  }
  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-search [type=submit]::before {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("./img/usa-icons/search.svg"), linear-gradient(transparent, transparent);
              mask-image: url("./img/usa-icons/search.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
              mask-position: center center;
      -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
      -webkit-mask-size: 1.5rem 1.5rem;
              mask-size: 1.5rem 1.5rem;
    }
  }
  .usa-search [type=submit]:focus {
    outline-offset: 0;
  }
}
@media (forced-colors: active) and (min-width: 30em) {
  .usa-search [type=submit]::before {
    content: none;
  }
}
@media all and (min-width: 30em) {
  .usa-search__submit-icon {
    display: none;
  }
}
@media (forced-colors: active) {
  .usa-search__submit-icon {
    display: none;
  }
}
@media all and (min-width: 30em) {
  .usa-search--big [type=search],
  .usa-search--big .usa-search__input {
    font-size: 0.99rem;
    height: 3rem;
  }
}
@media all and (min-width: 30em) {
  .usa-search--big [type=submit],
  .usa-search--big .usa-search__submit {
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.37rem;
    height: 3rem;
    width: auto;
  }
}
.usa-search--small [type=submit],
.usa-search--small .usa-search__submit {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  min-width: 3rem;
}
@media (forced-colors: active) and (min-width: 30em) {
  .usa-search--small [type=submit]::before {
    content: "";
  }
}
.usa-search--small .usa-search__submit-icon {
  height: 1.5rem;
  width: 1.5rem;
  display: block;
}
@media (forced-colors: active) {
  .usa-search--small .usa-search__submit-icon {
    display: none;
  }
}
input[type=search] {
  /* stylelint-disable-line selector-no-qualifying-type */
  box-sizing: border-box;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
[type=search],
.usa-search__input {
  padding-bottom: 0;
  padding-top: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  border-top-right-radius: 0;
  box-sizing: border-box;
  float: left;
  font-size: 0.93rem;
  height: 2rem;
  margin: 0;
}
.usa-search__submit-text {
  display: none;
}
@media all and (min-width: 30em) {
  .usa-search__submit-text {
    display: block;
  }
}
.usa-accordion {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  color: #1c1d1f;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.6;
}
.usa-accordion > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-accordion > ul li ul {
  list-style: disc;
}
.usa-accordion > ul li ul > li > ul {
  list-style: circle;
}
.usa-accordion > ul li ul > li > ul > li > ul {
  list-style: square;
}
.usa-accordion + .usa-accordion,
.usa-accordion + .usa-accordion--bordered {
  margin-top: 0.5rem;
}
.usa-accordion--bordered .usa-accordion__content {
  border-bottom: 0.25rem solid #f0f0f0;
  border-left: 0.25rem solid #f0f0f0;
  border-right: 0.25rem solid #f0f0f0;
  padding-bottom: 1rem;
}
.usa-accordion--bordered .usa-accordion__heading {
  margin-bottom: 0;
}
.usa-accordion__heading,
.usa-prose .usa-accordion__heading {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1;
  margin: 0;
}
.usa-accordion__heading:not(:first-child),
.usa-prose .usa-accordion__heading:not(:first-child) {
  margin-top: 0.5rem;
}
.usa-accordion__content {
  color: #1c1d1f;
  background-color: white;
  margin-top: 0;
  overflow: auto;
  padding: 1rem 1.25rem calc(1rem - 0.25rem) 1.25rem;
}
.usa-accordion__content > *:first-child {
  margin-top: 0;
}
.usa-accordion__content > *:last-child {
  margin-bottom: 0;
}
.usa-accordion__button {
  color: #005ea2;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  color: #1c1d1f;
  background-color: #f0f0f0;
  background-image: url("./img/usa-icons/remove.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  background-size: 1.5rem;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  margin: 0;
  padding: 1rem 3.5rem 1rem 1.25rem;
  text-decoration: none;
  width: 100%;
}
.usa-accordion__button:visited {
  color: #54278f;
}
.usa-accordion__button:hover {
  color: #1a4480;
}
.usa-accordion__button:active {
  color: #162e51;
}
.usa-accordion__button:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-accordion__button:hover, .usa-accordion__button.usa-button--hover, .usa-accordion__button:disabled:hover, .usa-accordion__button[aria-disabled=true]:hover, .usa-accordion__button:disabled.usa-button--hover, .usa-accordion__button[aria-disabled=true].usa-button--hover, .usa-accordion__button:active, .usa-accordion__button.usa-button--active, .usa-accordion__button:disabled:active, .usa-accordion__button[aria-disabled=true]:active, .usa-accordion__button:disabled.usa-button--active, .usa-accordion__button[aria-disabled=true].usa-button--active, .usa-accordion__button:disabled:focus, .usa-accordion__button[aria-disabled=true]:focus, .usa-accordion__button:disabled.usa-focus, .usa-accordion__button[aria-disabled=true].usa-focus, .usa-accordion__button:disabled, .usa-accordion__button[aria-disabled=true], .usa-accordion__button.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-accordion__button.usa-button--hover {
  color: #1a4480;
}
.usa-accordion__button.usa-button--active {
  color: #162e51;
}
.usa-accordion__button:disabled, .usa-accordion__button[aria-disabled=true], .usa-accordion__button:disabled:hover, .usa-accordion__button[aria-disabled=true]:hover, .usa-accordion__button[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-accordion__button:disabled, .usa-accordion__button[aria-disabled=true], .usa-accordion__button:disabled:hover, .usa-accordion__button[aria-disabled=true]:hover, .usa-accordion__button[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-accordion__button:hover {
  color: #1c1d1f;
  background-color: #dfe1e2;
  background-image: url("./img/usa-icons/remove.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  text-decoration: none;
}
@media (forced-colors: active) {
  .usa-accordion__button {
    border: 2px solid transparent;
    position: relative;
  }
  .usa-accordion__button::before {
    background-image: url("./img/usa-icons/remove.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.5rem 1.5rem;
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    height: 100%;
    position: absolute;
    right: 1.25rem;
    top: 0;
    content: "";
  }
  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-accordion__button::before {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("./img/usa-icons/remove.svg"), linear-gradient(transparent, transparent);
              mask-image: url("./img/usa-icons/remove.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
              mask-position: center center;
      -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
      -webkit-mask-size: 1.5rem 1.5rem;
              mask-size: 1.5rem 1.5rem;
    }
  }
}
.usa-accordion__button[aria-expanded=false] {
  background-image: url("./img/usa-icons/add.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-size: 1.5rem;
}
.usa-accordion__button[aria-expanded=false]:hover {
  background-image: url("./img/usa-icons/add.svg"), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}
@media (forced-colors: active) {
  .usa-accordion__button[aria-expanded=false]::before {
    background-image: url("./img/usa-icons/add.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.5rem 1.5rem;
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    height: 100%;
    position: absolute;
    right: 1.25rem;
    top: 0;
  }
  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-accordion__button[aria-expanded=false]::before {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("./img/usa-icons/add.svg"), linear-gradient(transparent, transparent);
              mask-image: url("./img/usa-icons/add.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
              mask-position: center center;
      -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
      -webkit-mask-size: 1.5rem 1.5rem;
              mask-size: 1.5rem 1.5rem;
    }
  }
}
@keyframes slidein-left {
  from {
    transform: translateX(15rem);
  }
  to {
    transform: translateX(0);
  }
}
.usa-nav {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1;
}
@media all and (max-width: 63.99em) {
  .usa-nav {
    position: absolute;
    right: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    position: fixed;
    background: white;
    border-right: 0;
    display: none;
    flex-direction: column;
    overflow-y: auto;
    padding: 1rem;
    width: 15rem;
    z-index: 500;
  }
  .usa-nav.is-visible {
    animation: slidein-left 0.3s ease-in-out;
    display: flex;
  }
}
@media all and (min-width: 64em) {
  .usa-nav {
    float: right;
    position: relative;
  }
}
.usa-nav .usa-search {
  margin-top: 1rem;
}
@media all and (min-width: 64em) {
  .usa-nav .usa-search {
    margin-left: 1rem;
    margin-top: 0;
  }
}
.usa-nav .usa-accordion {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1;
}
@media all and (max-width: 63.99em) {
  .usa-nav__primary {
    margin-bottom: 0;
    margin-top: 0;
    list-style-type: none;
    padding-left: 0;
  }
  .usa-nav__primary > li {
    margin-bottom: 0;
    max-width: unset;
  }
  .usa-nav__primary-item {
    border-top: 1px solid #dfe1e2;
  }
  .usa-nav__primary a:not(.usa-button) {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
  }
  .usa-nav__primary a:not(.usa-button):hover {
    background-color: #f0f0f0;
    text-decoration: none;
  }
  .usa-nav__primary a:not(.usa-button):not(.usa-current) {
    color: #565c65;
  }
  .usa-nav__primary a:not(.usa-button):not(.usa-current):hover {
    color: #005ea2;
  }
  .usa-nav__primary a:not(.usa-button):not(.usa-current):focus {
    outline-offset: 0;
  }
  .usa-nav__primary .usa-current {
    position: relative;
    color: #005ea2;
    font-weight: 700;
  }
  .usa-nav__primary .usa-current::after {
    background-color: #005ea2;
    border-radius: 99rem;
    content: "";
    display: block;
    position: absolute;
    bottom: 0.25rem;
    top: 0.25rem;
    width: 0.25rem;
    left: 0.25rem;
  }
}
@media all and (max-width: 63.99em) and (min-width: 40em) {
  .usa-nav__primary .usa-current {
    position: relative;
  }
  .usa-nav__primary .usa-current::after {
    background-color: #005ea2;
    border-radius: 99rem;
    content: "";
    display: block;
    position: absolute;
    bottom: 0.25rem;
    top: 0.25rem;
    width: 0.25rem;
    left: 0rem;
  }
}
@media all and (max-width: 63.99em) {
  .usa-nav__primary a {
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary {
    display: flex;
    align-items: stretch;
  }
}
.usa-nav__primary .usa-nav__primary-item a {
  text-decoration: none;
}
.usa-nav__primary > .usa-nav__primary-item {
  line-height: 1.2;
}
@media all and (min-width: 64em) {
  .usa-nav__primary > .usa-nav__primary-item {
    font-size: 0.87rem;
    line-height: 1;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary > .usa-nav__primary-item > a {
    line-height: 1;
    padding: 1rem;
    align-items: center;
    color: #565c65;
    display: flex;
    font-weight: 700;
  }
  .usa-nav__primary > .usa-nav__primary-item > a:hover {
    color: #005ea2;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary > .usa-nav__primary-item > button,
  .usa-nav__primary > .usa-nav__primary-item > a {
    height: 100%;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary a {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}
.usa-nav__primary button {
  color: #005ea2;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  position: relative;
  color: #565c65;
  font-weight: normal;
  line-height: 1.2;
  padding: 0.75rem 1rem;
  text-decoration: none;
  width: 100%;
}
.usa-nav__primary button:visited {
  color: #54278f;
}
.usa-nav__primary button:hover {
  color: #1a4480;
}
.usa-nav__primary button:active {
  color: #162e51;
}
.usa-nav__primary button:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-nav__primary button:hover, .usa-nav__primary button.usa-button--hover, .usa-nav__primary button:disabled:hover, .usa-nav__primary button[aria-disabled=true]:hover, .usa-nav__primary button:disabled.usa-button--hover, .usa-nav__primary button[aria-disabled=true].usa-button--hover, .usa-nav__primary button:active, .usa-nav__primary button.usa-button--active, .usa-nav__primary button:disabled:active, .usa-nav__primary button[aria-disabled=true]:active, .usa-nav__primary button:disabled.usa-button--active, .usa-nav__primary button[aria-disabled=true].usa-button--active, .usa-nav__primary button:disabled:focus, .usa-nav__primary button[aria-disabled=true]:focus, .usa-nav__primary button:disabled.usa-focus, .usa-nav__primary button[aria-disabled=true].usa-focus, .usa-nav__primary button:disabled, .usa-nav__primary button[aria-disabled=true], .usa-nav__primary button.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-nav__primary button.usa-button--hover {
  color: #1a4480;
}
.usa-nav__primary button.usa-button--active {
  color: #162e51;
}
.usa-nav__primary button:disabled, .usa-nav__primary button[aria-disabled=true], .usa-nav__primary button:disabled:hover, .usa-nav__primary button[aria-disabled=true]:hover, .usa-nav__primary button[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-nav__primary button:disabled, .usa-nav__primary button[aria-disabled=true], .usa-nav__primary button:disabled:hover, .usa-nav__primary button[aria-disabled=true]:hover, .usa-nav__primary button[aria-disabled=true]:focus {
    color: GrayText;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary button {
    line-height: 1;
    padding: 1rem;
    font-size: 0.87rem;
    font-weight: 700;
  }
}
@media (forced-colors: active) {
  .usa-nav__primary button {
    forced-color-adjust: auto;
  }
}
.usa-nav__primary button:hover {
  color: #005ea2;
  background-color: #f0f0f0;
  text-decoration: none;
}
@media all and (min-width: 64em) {
  .usa-nav__primary button:hover {
    background-color: transparent;
  }
}
.usa-nav__primary button[aria-expanded], .usa-nav__primary button[aria-expanded]:hover {
  background-image: none;
}
@media (forced-colors: active) {
  .usa-nav__primary button[aria-expanded]::before, .usa-nav__primary button[aria-expanded]:hover::before {
    content: none;
  }
}
.usa-nav__primary button[aria-expanded] span::after {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.usa-nav__primary button[aria-expanded=false] span::after {
  background-image: url("./img/usa-icons/add.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.25rem 1.25rem;
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  content: "";
  vertical-align: middle;
  margin-left: auto;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-nav__primary button[aria-expanded=false] span::after {
    background: none;
    background-color: ButtonText;
    -webkit-mask-image: url("./img/usa-icons/add.svg"), linear-gradient(transparent, transparent);
            mask-image: url("./img/usa-icons/add.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
            mask-position: center center;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-size: 1.25rem 1.25rem;
            mask-size: 1.25rem 1.25rem;
  }
}
.usa-nav__primary button[aria-expanded=false] span::after:hover {
  background-color: buttonText;
}
@media all and (min-width: 64em) {
  .usa-nav__primary button[aria-expanded=false] span::after {
    background-image: url("./img/usa-icons/expand_more.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1rem 1rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    content: "";
    vertical-align: middle;
    margin-left: auto;
  }
  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-nav__primary button[aria-expanded=false] span::after {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(transparent, transparent);
              mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
              mask-position: center center;
      -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
      -webkit-mask-size: 1rem 1rem;
              mask-size: 1rem 1rem;
    }
  }
  .usa-nav__primary button[aria-expanded=false] span::after {
    right: 0.75rem;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary button[aria-expanded=false]:hover span::after {
    background-color: #1a4480;
  }
}
@media (min-width: 64em) and (forced-colors: active) {
  .usa-nav__primary button[aria-expanded=false]:hover span::after {
    background-color: ButtonText;
  }
}
.usa-nav__primary button[aria-expanded=true] span::after {
  background-image: url("./img/usa-icons/remove.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.25rem 1.25rem;
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  content: "";
  vertical-align: middle;
  margin-left: auto;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-nav__primary button[aria-expanded=true] span::after {
    background: none;
    background-color: ButtonText;
    -webkit-mask-image: url("./img/usa-icons/remove.svg"), linear-gradient(transparent, transparent);
            mask-image: url("./img/usa-icons/remove.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
            mask-position: center center;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-size: 1.25rem 1.25rem;
            mask-size: 1.25rem 1.25rem;
  }
}
.usa-nav__primary button[aria-expanded=true] span::after {
  position: absolute;
  right: 0;
}
@media (forced-colors: active) {
  .usa-nav__primary button[aria-expanded=true] span::after {
    background-color: ButtonText;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary button[aria-expanded=true] {
    background-image: none;
    background-color: #162e51;
    color: white;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .usa-nav__primary button[aria-expanded=true] span::after {
    background-image: url("./img/usa-icons/expand_less.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1rem 1rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    content: "";
    vertical-align: middle;
    margin-left: auto;
  }
  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-nav__primary button[aria-expanded=true] span::after {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("./img/usa-icons/expand_less.svg"), linear-gradient(transparent, transparent);
              mask-image: url("./img/usa-icons/expand_less.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
              mask-position: center center;
      -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
      -webkit-mask-size: 1rem 1rem;
              mask-size: 1rem 1rem;
    }
  }
  .usa-nav__primary button[aria-expanded=true] span::after {
    right: 0.75rem;
    background-color: white;
  }
}
@media (min-width: 64em) and (min-width: 64em) and (forced-colors: active) {
  .usa-nav__primary button[aria-expanded=true] span::after {
    background-color: ButtonText;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__primary .usa-accordion__button span {
    display: inline-block;
    margin-right: 0;
    padding-right: 1rem;
  }
}
.usa-nav__secondary {
  margin-top: 1rem;
}
@media all and (min-width: 64em) {
  .usa-nav__secondary {
    flex-direction: column;
    align-items: flex-end;
    bottom: 4rem;
    display: flex;
    font-size: 0.87rem;
    margin-top: 0.5rem;
    min-width: calc(27ch + 3rem);
    position: absolute;
    right: 2rem;
  }
}
.usa-nav__secondary .usa-search {
  width: 100%;
}
@media all and (min-width: 64em) {
  .usa-nav__secondary .usa-search {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}
.usa-nav__secondary-links {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  line-height: 1.4;
  margin-top: 1.5rem;
}
@media all and (min-width: 64em) {
  .usa-nav__secondary-links {
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    line-height: 1;
    margin-bottom: 0.25rem;
    margin-top: 0;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__secondary-links .usa-nav__secondary-item {
    padding-left: 0.25rem;
  }
  .usa-nav__secondary-links .usa-nav__secondary-item + .usa-nav__secondary-item {
    border-left: 1px solid #dfe1e2;
    padding-left: 0.5rem;
  }
}
.usa-nav__secondary-links a {
  color: #71767a;
  display: inline-block;
  font-size: 0.87rem;
  text-decoration: none;
}
.usa-nav__secondary-links a:hover {
  color: #005ea2;
  text-decoration: underline;
}
@media all and (max-width: 63.99em) {
  .usa-nav__submenu {
    margin-bottom: 0;
    margin-top: 0;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
  .usa-nav__submenu > li {
    margin-bottom: 0;
    max-width: unset;
  }
  .usa-nav__submenu-item {
    border-top: 1px solid #dfe1e2;
    font-size: 0.87rem;
  }
  .usa-nav__submenu .usa-current::after {
    display: none;
  }
}
@media all and (max-width: 63.99em) and (min-width: 40em) {
  .usa-nav__submenu .usa-current::after {
    display: none;
  }
}
@media all and (max-width: 63.99em) {
  .usa-nav__submenu a:not(.usa-button) {
    padding-left: 2rem;
  }
  .usa-nav__submenu .usa-nav__submenu a:not(.usa-button) {
    padding-left: 3rem;
  }
  .usa-nav__submenu .usa-nav__submenu .usa-nav__submenu a:not(.usa-button) {
    padding-left: 4rem;
  }
}
@media all and (min-width: 64em) {
  .usa-nav__submenu {
    margin-bottom: 0;
    margin-top: 0;
    list-style-type: none;
    padding-left: 0;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    background-color: #162e51;
    width: 15rem;
    position: absolute;
    z-index: 400;
  }
}
.usa-nav__submenu[aria-hidden=true] {
  display: none;
}
@media all and (min-width: 64em) {
  .usa-nav__submenu .usa-nav__submenu-item a {
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
    line-height: 1.4;
    display: block;
  }
  .usa-nav__submenu .usa-nav__submenu-item a:focus {
    outline-offset: -0.25rem;
  }
  .usa-nav__submenu .usa-nav__submenu-item a:hover {
    color: white;
    text-decoration: underline;
  }
}
.usa-nav__submenu-list {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
}
.usa-nav__submenu-list > li {
  margin-bottom: 0;
  max-width: unset;
}
.usa-nav__submenu-list .usa-nav__submenu-list-item {
  margin: 0;
  font-size: 0.87rem;
}
.usa-nav__submenu-list .usa-nav__submenu-list-item a {
  line-height: 1.4;
}
.usa-nav__close {
  color: #005ea2;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  height: 3rem;
  width: 3rem;
  background-image: none;
  color: currentColor;
  flex: none;
  float: right;
  margin: -0.75rem -1rem 1rem auto;
  text-align: center;
}
.usa-nav__close:visited {
  color: #54278f;
}
.usa-nav__close:hover {
  color: #1a4480;
}
.usa-nav__close:active {
  color: #162e51;
}
.usa-nav__close:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-nav__close:hover, .usa-nav__close.usa-button--hover, .usa-nav__close:disabled:hover, .usa-nav__close[aria-disabled=true]:hover, .usa-nav__close:disabled.usa-button--hover, .usa-nav__close[aria-disabled=true].usa-button--hover, .usa-nav__close:active, .usa-nav__close.usa-button--active, .usa-nav__close:disabled:active, .usa-nav__close[aria-disabled=true]:active, .usa-nav__close:disabled.usa-button--active, .usa-nav__close[aria-disabled=true].usa-button--active, .usa-nav__close:disabled:focus, .usa-nav__close[aria-disabled=true]:focus, .usa-nav__close:disabled.usa-focus, .usa-nav__close[aria-disabled=true].usa-focus, .usa-nav__close:disabled, .usa-nav__close[aria-disabled=true], .usa-nav__close.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-nav__close.usa-button--hover {
  color: #1a4480;
}
.usa-nav__close.usa-button--active {
  color: #162e51;
}
.usa-nav__close:disabled, .usa-nav__close[aria-disabled=true], .usa-nav__close:disabled:hover, .usa-nav__close[aria-disabled=true]:hover, .usa-nav__close[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-nav__close:disabled, .usa-nav__close[aria-disabled=true], .usa-nav__close:disabled:hover, .usa-nav__close[aria-disabled=true]:hover, .usa-nav__close[aria-disabled=true]:focus {
    color: GrayText;
  }
}
@media (forced-colors: active) {
  .usa-nav__close::before {
    background-image: url("./img/usa-icons/close.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.25rem 1.25rem;
    display: inline-block;
    height: 1.25rem;
    width: 1.25rem;
    content: "";
    vertical-align: middle;
    margin-right: auto;
  }
  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-nav__close::before {
      background: none;
      background-color: ButtonText;
      -webkit-mask-image: url("./img/usa-icons/close.svg"), linear-gradient(transparent, transparent);
              mask-image: url("./img/usa-icons/close.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
              mask-position: center center;
      -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
      -webkit-mask-size: 1.25rem 1.25rem;
              mask-size: 1.25rem 1.25rem;
    }
  }
  .usa-nav__close::before {
    background-color: buttonText;
  }
}
.usa-nav__close:hover {
  color: currentColor;
  text-decoration: none;
}
@media all and (min-width: 64em) {
  .usa-nav__close {
    display: none;
  }
}
.usa-nav__close img {
  width: 1.5rem;
}
@media (forced-colors: active) {
  .usa-nav__close img {
    display: none;
  }
}
.usa-nav__close + * {
  clear: both;
}
.usa-js-mobile-nav--active {
  overflow: hidden;
}
@media (min-width: 63.06rem) {
  .usa-js-mobile-nav--active.is-safari {
    overflow-y: scroll;
    position: fixed;
    top: var(--scrolltop, 0);
  }
}
.usa-header {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.6;
}
.usa-header::after {
  clear: both;
  content: "";
  display: block;
}
.usa-header a {
  border-bottom: none;
}
.usa-header .usa-search {
  margin-top: 0.5rem;
}
@media all and (min-width: 64em) {
  .usa-header .usa-search {
    float: right;
  }
}
@media all and (min-width: 64em) {
  .usa-header [role=search] {
    float: right;
    max-width: calc(27ch + 3rem);
    width: 100%;
  }
}
.usa-header [type=search] {
  min-width: 0;
}
@media all and (min-width: 64em) {
  .usa-header + .usa-hero {
    border-top: 1px solid white;
  }
}
@media all and (min-width: 64em) {
  .usa-header + .usa-section,
  .usa-header + main {
    border-top: 1px solid #dfe1e2;
  }
}
@media all and (max-width: 63.99em) {
  .usa-logo {
    flex: 1 1 0%;
    font-size: 0.87rem;
    line-height: 1;
    margin-left: 1rem;
  }
}
@media all and (min-width: 64em) {
  .usa-logo {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.37rem;
    line-height: 1.2;
  }
}
.usa-logo a {
  color: #1c1d1f;
  text-decoration: none;
}
.usa-logo__text {
  display: block;
  font-style: normal;
  font-weight: 700;
  margin: 0;
}
.usa-menu-btn {
  color: #005ea2;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  flex: 0 1 auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background-color: #005ea2;
  color: white;
  font-size: 0.81rem;
  height: 3rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
.usa-menu-btn:visited {
  color: #54278f;
}
.usa-menu-btn:hover {
  color: #1a4480;
}
.usa-menu-btn:active {
  color: #162e51;
}
.usa-menu-btn:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-menu-btn:hover, .usa-menu-btn.usa-button--hover, .usa-menu-btn:disabled:hover, .usa-menu-btn[aria-disabled=true]:hover, .usa-menu-btn:disabled.usa-button--hover, .usa-menu-btn[aria-disabled=true].usa-button--hover, .usa-menu-btn:active, .usa-menu-btn.usa-button--active, .usa-menu-btn:disabled:active, .usa-menu-btn[aria-disabled=true]:active, .usa-menu-btn:disabled.usa-button--active, .usa-menu-btn[aria-disabled=true].usa-button--active, .usa-menu-btn:disabled:focus, .usa-menu-btn[aria-disabled=true]:focus, .usa-menu-btn:disabled.usa-focus, .usa-menu-btn[aria-disabled=true].usa-focus, .usa-menu-btn:disabled, .usa-menu-btn[aria-disabled=true], .usa-menu-btn.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-menu-btn.usa-button--hover {
  color: #1a4480;
}
.usa-menu-btn.usa-button--active {
  color: #162e51;
}
.usa-menu-btn:disabled, .usa-menu-btn[aria-disabled=true], .usa-menu-btn:disabled:hover, .usa-menu-btn[aria-disabled=true]:hover, .usa-menu-btn[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-menu-btn:disabled, .usa-menu-btn[aria-disabled=true], .usa-menu-btn:disabled:hover, .usa-menu-btn[aria-disabled=true]:hover, .usa-menu-btn[aria-disabled=true]:focus {
    color: GrayText;
  }
}
@media all and (min-width: 64em) {
  .usa-menu-btn {
    display: none;
  }
}
.usa-menu-btn:hover {
  background-color: #1a4480;
  color: white;
  text-decoration: none;
}
.usa-menu-btn:active {
  color: white;
}
.usa-menu-btn:visited {
  color: white;
}
.usa-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  visibility: hidden;
  z-index: 400;
}
.usa-overlay.is-visible {
  opacity: 1;
  visibility: visible;
}
@media all and (min-width: 64em) {
  .usa-header--basic .usa-navbar {
    position: relative;
    width: 33%;
  }
  .usa-header--basic .usa-nav {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    padding: 0 0 0.5rem 0.5rem;
    width: 100%;
  }
  .usa-header--basic .usa-nav-container {
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
  }
  .usa-header--basic .usa-nav__primary-item > .usa-current,
  .usa-header--basic .usa-nav__link:hover {
    position: relative;
  }
  .usa-header--basic .usa-nav__primary-item > .usa-current::after,
  .usa-header--basic .usa-nav__link:hover::after {
    background-color: #005ea2;
    border-radius: 0;
    content: "";
    display: block;
    position: absolute;
    height: 0.25rem;
    left: 1rem;
    right: 1rem;
    bottom: -0.25rem;
  }
}
@media (min-width: 64em) and (forced-colors: active) {
  .usa-header--basic .usa-nav__primary-item > .usa-current::after,
  .usa-header--basic .usa-nav__link:hover::after {
    background-color: ButtonText;
  }
}
@media all and (min-width: 64em) {
  .usa-header--basic .usa-nav__link[aria-expanded=true]::after,
  .usa-header--basic .usa-nav__link[aria-expanded=true]:hover::after {
    display: none;
  }
  .usa-header--basic .usa-nav__primary {
    width: auto;
  }
  .usa-header--basic .usa-nav__primary-item:last-of-type {
    position: relative;
  }
  .usa-header--basic .usa-nav__primary-item:last-of-type .usa-nav__submenu {
    position: absolute;
    right: 0;
  }
  .usa-header--basic .usa-search {
    top: 0;
  }
}
.usa-header--basic.usa-header--megamenu .usa-nav__inner {
  display: flex;
  flex-direction: column;
}
@media all and (min-width: 64em) {
  .usa-header--basic.usa-header--megamenu .usa-nav__inner {
    display: block;
    float: right;
    margin-top: -2.5rem;
  }
}
@media all and (min-width: 64em) {
  .usa-header--basic.usa-header--megamenu .usa-nav__primary-item:last-of-type {
    position: static;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended {
    padding-top: 0;
  }
  .usa-header--extended .usa-nav__primary-item > .usa-current,
  .usa-header--extended .usa-nav__primary-item > .usa-nav__link:hover {
    position: relative;
  }
  .usa-header--extended .usa-nav__primary-item > .usa-current::after,
  .usa-header--extended .usa-nav__primary-item > .usa-nav__link:hover::after {
    background-color: #005ea2;
    border-radius: 0;
    content: "";
    display: block;
    position: absolute;
    height: 0.25rem;
    left: 1rem;
    right: 1rem;
    bottom: 0rem;
  }
}
@media (min-width: 64em) and (forced-colors: active) {
  .usa-header--extended .usa-nav__primary-item > .usa-current::after,
  .usa-header--extended .usa-nav__primary-item > .usa-nav__link:hover::after {
    background-color: ButtonText;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-nav__link[aria-expanded=true]::after,
  .usa-header--extended .usa-nav__link[aria-expanded=true]:hover::after {
    display: none;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-logo {
    font-size: 1.99rem;
    margin: 2rem 0 1.5rem;
    max-width: 33%;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-navbar {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: block;
    height: auto;
    overflow: auto;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .usa-header--extended .usa-navbar {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-nav {
    border-top: 1px solid #dfe1e2;
    padding: 0;
    width: 100%;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-nav__inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .usa-header--extended .usa-nav__inner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-nav__primary {
    margin-left: -1rem;
  }
  .usa-header--extended .usa-nav__primary::after {
    clear: both;
    content: "";
    display: block;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-nav__link {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-nav__submenu .usa-grid-full {
    padding-left: 0.75rem;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-nav__submenu.usa-megamenu {
    left: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
html.usa-js-loading .usa-nav__submenu,
html.usa-js-loading .usa-nav__submenu.usa-megamenu {
  position: absolute;
  left: -999em;
  right: auto;
}
.usa-megamenu .usa-col {
  flex: 1 1 auto;
}
@media all and (min-width: 64em) {
  .usa-megamenu .usa-col {
    flex: 4 1 0%;
  }
  .usa-megamenu .usa-col .usa-nav__submenu-item a {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .usa-megamenu .usa-col:first-child .usa-nav__submenu-item a {
    padding-left: 0;
  }
  .usa-megamenu .usa-col:last-child .usa-nav__submenu-item a {
    padding-right: 0;
  }
}
@media all and (min-width: 64em) {
  .usa-megamenu.usa-nav__submenu {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2rem;
    padding-top: 2rem;
    left: -33%;
    right: 0;
    width: auto;
  }
}
@media all and (min-width: 64em) {
  .usa-megamenu.usa-nav__submenu::before {
    position: absolute;
    bottom: 0;
    top: 0;
    background-color: #162e51;
    content: "";
    display: block;
    position: absolute;
    width: calc(50vw - 32rem + 2rem);
    right: 100%;
  }
}
@media all and (min-width: 64em) {
  .usa-megamenu.usa-nav__submenu::after {
    position: absolute;
    bottom: 0;
    top: 0;
    background-color: #162e51;
    content: "";
    display: block;
    position: absolute;
    width: calc(50vw - 32rem + 2rem);
    left: 100%;
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-megamenu.usa-nav__submenu::before {
    position: absolute;
    bottom: 0;
    top: 0;
    background-color: #162e51;
    content: "";
    display: block;
    position: absolute;
    width: calc(50vw - 32rem);
  }
}
@media all and (min-width: 64em) {
  .usa-header--extended .usa-megamenu.usa-nav__submenu::after {
    position: absolute;
    bottom: 0;
    top: 0;
    background-color: #162e51;
    content: "";
    display: block;
    position: absolute;
    width: calc(50vw - 32rem);
  }
}
@media all and (min-width: 64em) {
  .usa-nav-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .usa-nav-container::after {
    clear: both;
    content: "";
    display: block;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .usa-nav-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.usa-navbar {
  height: 3rem;
}
@media all and (max-width: 63.99em) {
  .usa-navbar {
    align-items: center;
    border-bottom: 1px solid #dfe1e2;
    display: flex;
  }
}
@media all and (min-width: 64em) {
  .usa-navbar {
    border-bottom: none;
    display: inline-block;
    height: auto;
  }
}
.usa-alert {
  background-color: #f0f0f0;
  border-left: 0.5rem solid #a9aeb1;
  color: #1c1d1f;
}
.usa-alert .usa-alert__body {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.6;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
}
.usa-alert .usa-alert__text {
  margin-bottom: 0;
  margin-top: 0;
}
.usa-alert .usa-alert__text:only-child {
  padding-bottom: 0;
  padding-top: 0;
}
.usa-alert .usa-alert__heading {
  font-family: var(--base-font-family), ;
  font-size: 1.37rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.usa-alert > .usa-list,
.usa-alert .usa-alert__body > .usa-list {
  padding-left: 2ch;
}
.usa-alert > .usa-list:last-child,
.usa-alert .usa-alert__body > .usa-list:last-child {
  margin-bottom: 0;
}
.usa-alert .usa-alert__body {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.usa-alert .usa-alert__body::before {
  left: 1.0833333333rem;
}
* + .usa-alert {
  margin-top: 1rem;
}
.usa-alert--success {
  background-color: #ecf3ec;
  border-left-color: #00a91c;
}
.usa-alert--success .usa-alert__body {
  color: #1c1d1f;
  background-color: #ecf3ec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.3333333333rem;
}
.usa-alert--success .usa-alert__body::before {
  background-image: url("./img/usa-icons/check_circle.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem 2rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.6666666667rem;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--success .usa-alert__body::before {
    background: none;
    background-color: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/check_circle.svg"), linear-gradient(transparent, transparent);
            mask-image: url("./img/usa-icons/check_circle.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
            mask-position: center center;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-size: 2rem 2rem;
            mask-size: 2rem 2rem;
  }
}
.usa-alert--success .usa-alert__body > * {
  margin-left: 2.5rem;
}
.usa-alert--success .usa-alert__body .usa-link {
  color: #005ea2;
}
.usa-alert--success .usa-alert__body .usa-link:visited {
  color: #54278f;
}
.usa-alert--success .usa-alert__body .usa-link:hover, .usa-alert--success .usa-alert__body .usa-link:active {
  color: #1a4480;
}
.usa-alert--warning {
  background-color: #faf3d1;
  border-left-color: #ffbe2e;
}
.usa-alert--warning .usa-alert__body {
  color: #1c1d1f;
  background-color: #faf3d1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.3333333333rem;
}
.usa-alert--warning .usa-alert__body::before {
  background-image: url("./img/usa-icons/warning.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem 2rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.6666666667rem;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--warning .usa-alert__body::before {
    background: none;
    background-color: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/warning.svg"), linear-gradient(transparent, transparent);
            mask-image: url("./img/usa-icons/warning.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
            mask-position: center center;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-size: 2rem 2rem;
            mask-size: 2rem 2rem;
  }
}
.usa-alert--warning .usa-alert__body > * {
  margin-left: 2.5rem;
}
.usa-alert--warning .usa-alert__body .usa-link {
  color: #005ea2;
}
.usa-alert--warning .usa-alert__body .usa-link:visited {
  color: #54278f;
}
.usa-alert--warning .usa-alert__body .usa-link:hover, .usa-alert--warning .usa-alert__body .usa-link:active {
  color: #1a4480;
}
.usa-alert--error {
  background-color: #f4e3db;
  border-left-color: #d54309;
}
.usa-alert--error .usa-alert__body {
  color: #1c1d1f;
  background-color: #f4e3db;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.3333333333rem;
}
.usa-alert--error .usa-alert__body::before {
  background-image: url("./img/usa-icons/error.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem 2rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.6666666667rem;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--error .usa-alert__body::before {
    background: none;
    background-color: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/error.svg"), linear-gradient(transparent, transparent);
            mask-image: url("./img/usa-icons/error.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
            mask-position: center center;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-size: 2rem 2rem;
            mask-size: 2rem 2rem;
  }
}
.usa-alert--error .usa-alert__body > * {
  margin-left: 2.5rem;
}
.usa-alert--error .usa-alert__body .usa-link {
  color: #005ea2;
}
.usa-alert--error .usa-alert__body .usa-link:visited {
  color: #54278f;
}
.usa-alert--error .usa-alert__body .usa-link:hover, .usa-alert--error .usa-alert__body .usa-link:active {
  color: #1a4480;
}
.usa-alert--info {
  background-color: #e7f6f8;
  border-left-color: #00bde3;
}
.usa-alert--info .usa-alert__body {
  color: #1c1d1f;
  background-color: #e7f6f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.3333333333rem;
}
.usa-alert--info .usa-alert__body::before {
  background-image: url("./img/usa-icons/info.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem 2rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.6666666667rem;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--info .usa-alert__body::before {
    background: none;
    background-color: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/info.svg"), linear-gradient(transparent, transparent);
            mask-image: url("./img/usa-icons/info.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
            mask-position: center center;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-size: 2rem 2rem;
            mask-size: 2rem 2rem;
  }
}
.usa-alert--info .usa-alert__body > * {
  margin-left: 2.5rem;
}
.usa-alert--info .usa-alert__body .usa-link {
  color: #005ea2;
}
.usa-alert--info .usa-alert__body .usa-link:visited {
  color: #54278f;
}
.usa-alert--info .usa-alert__body .usa-link:hover, .usa-alert--info .usa-alert__body .usa-link:active {
  color: #1a4480;
}
.usa-alert--emergency {
  background-color: #9c3d10;
  border-left-color: #9c3d10;
}
.usa-alert--emergency .usa-alert__body {
  color: white;
  background-color: #9c3d10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.3333333333rem;
}
.usa-alert--emergency .usa-alert__body::before {
  background-image: url("./img/usa-icons-bg/error--white.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem 2rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.6666666667rem;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--emergency .usa-alert__body::before {
    background: none;
    background-color: white;
    -webkit-mask-image: url("./img/usa-icons/error.svg"), linear-gradient(transparent, transparent);
            mask-image: url("./img/usa-icons/error.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
            mask-position: center center;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-size: 2rem 2rem;
            mask-size: 2rem 2rem;
  }
}
.usa-alert--emergency .usa-alert__body > * {
  margin-left: 2.5rem;
}
.usa-alert--emergency .usa-alert__body .usa-link {
  color: #dfe1e2;
}
.usa-alert--emergency .usa-alert__body .usa-link:visited {
  color: #dfe1e2;
}
.usa-alert--emergency .usa-alert__body .usa-link:hover, .usa-alert--emergency .usa-alert__body .usa-link:active {
  color: #f0f0f0;
}
.usa-alert--slim .usa-alert__body {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  min-height: 0;
}
.usa-alert--slim .usa-alert__body:before {
  height: 1.5rem;
  top: 0.5rem;
  width: 1.5rem;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--slim .usa-alert__body:before {
    -webkit-mask-size: 1.5rem;
            mask-size: 1.5rem;
  }
}
.usa-alert--slim .usa-alert__body > * {
  margin-left: 2rem;
}
.usa-alert--slim .usa-alert__body::before {
  left: 1.125rem;
}
.usa-alert--no-icon .usa-alert__body {
  min-height: 0;
}
.usa-alert--no-icon .usa-alert__body:before {
  display: none;
}
.usa-alert--no-icon .usa-alert__body > * {
  margin-left: 0;
}
.usa-alert--validation .usa-checklist {
  margin-top: 1rem;
}
.usa-site-alert .usa-alert {
  background-color: #f0f0f0;
  border-left: 0.5rem solid #a9aeb1;
  color: #1c1d1f;
}
.usa-site-alert .usa-alert .usa-alert__body {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.6;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
}
.usa-site-alert .usa-alert .usa-alert__text {
  margin-bottom: 0;
  margin-top: 0;
}
.usa-site-alert .usa-alert .usa-alert__text:only-child {
  padding-bottom: 0;
  padding-top: 0;
}
.usa-site-alert .usa-alert .usa-alert__heading {
  font-family: var(--base-font-family), ;
  font-size: 1.37rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.usa-site-alert .usa-alert > .usa-list,
.usa-site-alert .usa-alert .usa-alert__body > .usa-list {
  padding-left: 2ch;
}
.usa-site-alert .usa-alert > .usa-list:last-child,
.usa-site-alert .usa-alert .usa-alert__body > .usa-list:last-child {
  margin-bottom: 0;
}
.usa-site-alert .usa-alert .usa-alert__body {
  max-width: 64rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.usa-site-alert .usa-alert .usa-alert__body::before {
  left: 1.0833333333rem;
}
@media all and (min-width: 64em) {
  .usa-site-alert .usa-alert .usa-alert__body {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .usa-site-alert .usa-alert .usa-alert__body::before {
    left: 1.5833333333rem;
  }
}
.usa-site-alert--info .usa-alert {
  background-color: #e7f6f8;
  border-left-color: #00bde3;
}
.usa-site-alert--info .usa-alert .usa-alert__body {
  color: #1c1d1f;
  background-color: #e7f6f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.3333333333rem;
}
.usa-site-alert--info .usa-alert .usa-alert__body::before {
  background-image: url("./img/usa-icons/info.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem 2rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.6666666667rem;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-site-alert--info .usa-alert .usa-alert__body::before {
    background: none;
    background-color: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/info.svg"), linear-gradient(transparent, transparent);
            mask-image: url("./img/usa-icons/info.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
            mask-position: center center;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-size: 2rem 2rem;
            mask-size: 2rem 2rem;
  }
}
.usa-site-alert--info .usa-alert .usa-alert__body > * {
  margin-left: 2.5rem;
}
.usa-site-alert--info .usa-alert .usa-alert__body .usa-link {
  color: #005ea2;
}
.usa-site-alert--info .usa-alert .usa-alert__body .usa-link:visited {
  color: #54278f;
}
.usa-site-alert--info .usa-alert .usa-alert__body .usa-link:hover, .usa-site-alert--info .usa-alert .usa-alert__body .usa-link:active {
  color: #1a4480;
}
.usa-site-alert--emergency .usa-alert {
  background-color: #9c3d10;
  border-left-color: #9c3d10;
}
.usa-site-alert--emergency .usa-alert .usa-alert__body {
  color: white;
  background-color: #9c3d10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.3333333333rem;
}
.usa-site-alert--emergency .usa-alert .usa-alert__body::before {
  background-image: url("./img/usa-icons-bg/error--white.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2rem 2rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: "";
  display: block;
  position: absolute;
  top: 0.6666666667rem;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-site-alert--emergency .usa-alert .usa-alert__body::before {
    background: none;
    background-color: white;
    -webkit-mask-image: url("./img/usa-icons/error.svg"), linear-gradient(transparent, transparent);
            mask-image: url("./img/usa-icons/error.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
            mask-position: center center;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-size: 2rem 2rem;
            mask-size: 2rem 2rem;
  }
}
.usa-site-alert--emergency .usa-alert .usa-alert__body > * {
  margin-left: 2.5rem;
}
.usa-site-alert--emergency .usa-alert .usa-alert__body .usa-link {
  color: #dfe1e2;
}
.usa-site-alert--emergency .usa-alert .usa-alert__body .usa-link:visited {
  color: #dfe1e2;
}
.usa-site-alert--emergency .usa-alert .usa-alert__body .usa-link:hover, .usa-site-alert--emergency .usa-alert .usa-alert__body .usa-link:active {
  color: #f0f0f0;
}
.usa-site-alert--slim .usa-alert .usa-alert__body {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  min-height: 0;
}
.usa-site-alert--slim .usa-alert .usa-alert__body:before {
  height: 1.5rem;
  top: 0.5rem;
  width: 1.5rem;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-site-alert--slim .usa-alert .usa-alert__body:before {
    -webkit-mask-size: 1.5rem;
            mask-size: 1.5rem;
  }
}
.usa-site-alert--slim .usa-alert .usa-alert__body > * {
  margin-left: 2rem;
}
.usa-site-alert--slim .usa-alert .usa-alert__body::before {
  left: 1.125rem;
}
@media all and (min-width: 64em) {
  .usa-site-alert--slim .usa-alert .usa-alert__body::before {
    left: 1.625rem;
  }
}
.usa-site-alert--no-icon .usa-alert .usa-alert__body {
  min-height: 0;
}
.usa-site-alert--no-icon .usa-alert .usa-alert__body:before {
  display: none;
}
.usa-site-alert--no-icon .usa-alert .usa-alert__body > * {
  margin-left: 0;
}
.usa-media-block {
  align-items: flex-start;
  display: flex;
}
.usa-media-block__img {
  flex-shrink: 0;
  margin-right: 0.5rem;
}
.usa-media-block__body {
  flex: 1 1 0%;
}
.usa-banner {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.6;
  background-color: #f0f0f0;
}
@media all and (min-width: 40em) {
  .usa-banner {
    font-size: 0.81rem;
    padding-bottom: 0rem;
  }
}
.usa-banner .usa-accordion {
  font-family: var(--base-font-family), ;
  font-size: 0.99rem;
  line-height: 1.6;
}
.usa-banner .grid-row {
  display: flex;
  flex-wrap: wrap;
}
.usa-banner .grid-row.grid-gap-lg {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.usa-banner .grid-row.grid-gap-lg > * {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
@media all and (min-width: 40em) {
  .usa-banner .grid-row .tablet\:grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
}
.usa-banner__header,
.usa-banner__content {
  color: #1c1d1f;
}
.usa-banner__content {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: transparent;
  font-size: 0.93rem;
  overflow: hidden;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-top: 0.25rem;
  width: 100%;
}
@media all and (min-width: 64em) {
  .usa-banner__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .usa-banner__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__content {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }
}
.usa-banner__content p:first-child {
  margin: 0;
}
.usa-banner__guidance {
  display: flex;
  align-items: flex-start;
  max-width: 64ex;
  padding-top: 1rem;
}
@media all and (min-width: 40em) {
  .usa-banner__guidance {
    padding-top: 0rem;
  }
}
.usa-banner__lock-image {
  height: 1.5ex;
  width: 1.21875ex;
}
.usa-banner__lock-image path {
  fill: currentColor;
}
@media (forced-colors: active) {
  .usa-banner__lock-image path {
    fill: CanvasText;
  }
}
.usa-banner__inner {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-right: 0rem;
}
@media all and (min-width: 64em) {
  .usa-banner__inner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .usa-banner__inner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__inner {
    align-items: center;
  }
}
.usa-banner__header {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-size: 0.75rem;
  font-weight: normal;
  min-height: 3rem;
  position: relative;
}
@media all and (min-width: 40em) {
  .usa-banner__header {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    min-height: 0;
  }
}
.usa-banner__header-text {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 0.75rem;
  line-height: 1.2;
}
.usa-banner__header-action {
  color: #005ea2;
  line-height: 1.2;
  margin-bottom: 0rem;
  margin-top: 2px;
  text-decoration: underline;
}
.usa-banner__header-action::after {
  background-image: url("./img/usa-icons/expand_more.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1rem 1rem;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  content: "";
  vertical-align: middle;
  margin-left: auto;
}
@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-banner__header-action::after {
    background: none;
    background-color: #005ea2;
    -webkit-mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(transparent, transparent);
            mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(transparent, transparent);
    -webkit-mask-position: center center;
            mask-position: center center;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-size: 1rem 1rem;
            mask-size: 1rem 1rem;
  }
  .usa-banner__header-action::after:hover {
    background-color: #1a4480;
  }
}
.usa-banner__header-action:hover::after {
  content: "";
  background-color: #1a4480;
}
.usa-banner__header-action:visited {
  color: #54278f;
}
.usa-banner__header-action:hover, .usa-banner__header-action:active {
  color: #1a4480;
}
.usa-banner__header--expanded .usa-banner__header-action {
  display: none;
}
@media all and (min-width: 40em) {
  .usa-banner__header-action {
    display: none;
  }
}
@media (forced-colors: active) {
  .usa-banner__header-action {
    color: LinkText;
  }
  .usa-banner__header-action::after {
    background-color: ButtonText;
  }
}
.usa-banner__header-flag {
  float: left;
  margin-right: 0.5rem;
  width: 1rem;
}
@media all and (min-width: 40em) {
  .usa-banner__header-flag {
    margin-right: 0.5rem;
    padding-top: 0rem;
  }
}
.usa-banner__header--expanded {
  padding-right: 3.5rem;
}
@media all and (min-width: 40em) {
  .usa-banner__header--expanded {
    background-color: transparent;
    display: block;
    font-size: 0.75rem;
    font-weight: normal;
    min-height: 0rem;
    padding-right: 0rem;
  }
}
.usa-banner__header--expanded .usa-banner__inner {
  margin-left: 0rem;
}
@media all and (min-width: 40em) {
  .usa-banner__header--expanded .usa-banner__inner {
    margin-left: auto;
  }
}
.usa-banner__header--expanded .usa-banner__header-action {
  display: none;
}
.usa-banner__button {
  color: #005ea2;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-weight: normal;
  justify-content: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  width: auto;
  position: absolute;
  left: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  color: #005ea2;
  text-decoration: underline;
  color: #005ea2;
  display: block;
  font-size: 0.75rem;
  height: auto;
  line-height: 1.2;
  padding-top: 0rem;
  padding-left: 0rem;
  text-decoration: none;
  width: auto;
}
.usa-banner__button:visited {
  color: #54278f;
}
.usa-banner__button:hover {
  color: #1a4480;
}
.usa-banner__button:active {
  color: #162e51;
}
.usa-banner__button:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0rem;
}
.usa-banner__button:hover, .usa-banner__button.usa-button--hover, .usa-banner__button:disabled:hover, .usa-banner__button[aria-disabled=true]:hover, .usa-banner__button:disabled.usa-button--hover, .usa-banner__button[aria-disabled=true].usa-button--hover, .usa-banner__button:active, .usa-banner__button.usa-button--active, .usa-banner__button:disabled:active, .usa-banner__button[aria-disabled=true]:active, .usa-banner__button:disabled.usa-button--active, .usa-banner__button[aria-disabled=true].usa-button--active, .usa-banner__button:disabled:focus, .usa-banner__button[aria-disabled=true]:focus, .usa-banner__button:disabled.usa-focus, .usa-banner__button[aria-disabled=true].usa-focus, .usa-banner__button:disabled, .usa-banner__button[aria-disabled=true], .usa-banner__button.usa-button--disabled {
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
}
.usa-banner__button.usa-button--hover {
  color: #1a4480;
}
.usa-banner__button.usa-button--active {
  color: #162e51;
}
.usa-banner__button:disabled, .usa-banner__button[aria-disabled=true], .usa-banner__button:disabled:hover, .usa-banner__button[aria-disabled=true]:hover, .usa-banner__button[aria-disabled=true]:focus {
  color: #757575;
}
@media (forced-colors: active) {
  .usa-banner__button:disabled, .usa-banner__button[aria-disabled=true], .usa-banner__button:disabled:hover, .usa-banner__button[aria-disabled=true]:hover, .usa-banner__button[aria-disabled=true]:focus {
    color: GrayText;
  }
}
.usa-banner__button:visited {
  color: #54278f;
}
.usa-banner__button:hover, .usa-banner__button:active {
  color: #1a4480;
}
@media all and (max-width: 39.99em) {
  .usa-banner__button {
    width: 100%;
  }
  .usa-banner__button:enabled:focus {
    outline-offset: -0.25rem;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__button {
    color: #005ea2;
    position: static;
    bottom: auto;
    left: auto;
    right: auto;
    top: auto;
    display: inline;
    margin-left: 0.5rem;
    position: relative;
  }
  .usa-banner__button::after {
    background-image: url("./img/usa-icons/expand_more.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1rem 1rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    content: "";
    vertical-align: middle;
    margin-left: 2px;
  }
  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-banner__button::after {
      background: none;
      background-color: #005ea2;
      -webkit-mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(transparent, transparent);
              mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
              mask-position: center center;
      -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
      -webkit-mask-size: 1rem 1rem;
              mask-size: 1rem 1rem;
    }
    .usa-banner__button::after:hover {
      background-color: #1a4480;
    }
  }
  .usa-banner__button:hover::after {
    content: "";
    background-color: #1a4480;
  }
  .usa-banner__button:visited {
    color: #54278f;
  }
  .usa-banner__button:hover, .usa-banner__button:active {
    color: #1a4480;
  }
  .usa-banner__button::after, .usa-banner__button:hover::after {
    position: absolute;
  }
}
@media (min-width: 40em) and (forced-colors: active) {
  .usa-banner__button::after, .usa-banner__button:hover::after {
    background-color: ButtonText;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__button:hover {
    text-decoration: none;
  }
}
.usa-banner__button[aria-expanded=false], .usa-banner__button[aria-expanded=false]:hover, .usa-banner__button[aria-expanded=true], .usa-banner__button[aria-expanded=true]:hover {
  background-image: none;
}
@media (forced-colors: active) {
  .usa-banner__button[aria-expanded=false]::before, .usa-banner__button[aria-expanded=false]:hover::before, .usa-banner__button[aria-expanded=true]::before, .usa-banner__button[aria-expanded=true]:hover::before {
    content: none;
  }
}
@media all and (max-width: 39.99em) {
  .usa-banner__button[aria-expanded=true]::after {
    background-image: url("./img/usa-icons/close.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.5rem 1.5rem;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    content: "";
    vertical-align: middle;
    margin-left: 0rem;
  }
  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-banner__button[aria-expanded=true]::after {
      background: none;
      background-color: #005ea2;
      -webkit-mask-image: url("./img/usa-icons/close.svg"), linear-gradient(transparent, transparent);
              mask-image: url("./img/usa-icons/close.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
              mask-position: center center;
      -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
      -webkit-mask-size: 1.5rem 1.5rem;
              mask-size: 1.5rem 1.5rem;
    }
  }
  .usa-banner__button[aria-expanded=true]::before {
    position: absolute;
    bottom: 0;
    top: 0;
    position: absolute;
    right: 0;
    background-color: #dfe1e2;
    content: "";
    display: block;
    height: 3rem;
    width: 3rem;
  }
  .usa-banner__button[aria-expanded=true]::after {
    position: absolute;
    bottom: 0;
    top: 0;
    position: absolute;
    right: 0;
  }
}
@media all and (min-width: 40em) {
  .usa-banner__button[aria-expanded=true] {
    height: auto;
    padding: 0rem;
    position: relative;
  }
  .usa-banner__button[aria-expanded=true]::after {
    background-image: url("./img/usa-icons/expand_less.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1rem 1rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    content: "";
    vertical-align: middle;
    margin-left: 2px;
  }
  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-banner__button[aria-expanded=true]::after {
      background: none;
      background-color: #005ea2;
      -webkit-mask-image: url("./img/usa-icons/expand_less.svg"), linear-gradient(transparent, transparent);
              mask-image: url("./img/usa-icons/expand_less.svg"), linear-gradient(transparent, transparent);
      -webkit-mask-position: center center;
              mask-position: center center;
      -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
      -webkit-mask-size: 1rem 1rem;
              mask-size: 1rem 1rem;
    }
    .usa-banner__button[aria-expanded=true]::after:hover {
      background-color: #1a4480;
    }
  }
  .usa-banner__button[aria-expanded=true]:hover::after {
    content: "";
    background-color: #1a4480;
  }
  .usa-banner__button[aria-expanded=true]::after, .usa-banner__button[aria-expanded=true]:hover::after {
    position: absolute;
  }
}
@media (min-width: 40em) and (forced-colors: active) {
  .usa-banner__button[aria-expanded=true]::after, .usa-banner__button[aria-expanded=true]:hover::after {
    background-color: ButtonText;
  }
}
.usa-banner__button-text {
  position: absolute;
  left: -999em;
  right: auto;
  text-decoration: underline;
}
@media all and (min-width: 40em) {
  .usa-banner__button-text {
    position: static;
    display: inline;
  }
}
@media (forced-colors: active) {
  .usa-banner__button-text {
    color: LinkText;
  }
}
.usa-banner__icon {
  width: 2.5rem;
}
.usa-js-loading .usa-banner__content {
  position: absolute;
  left: -999em;
  right: auto;
}
.usa-logo {
  max-width: none !important;
}
.usa-nav__secondary-item {
  font-weight: normal;
}
@media (width >= 64rem) {
  .usa-nav__secondary-links {
    margin-bottom: 1.5rem;
  }
  .usa-nav__secondary-item .usa-nav__link {
    padding: 0;
    color: #565c65;
  }
  .usa-nav__primary button[aria-expanded=false] span:after {
    background-color: #565c65;
  }
}
.usa-nav__secondary-links button {
  color: #71767a;
  font-size: 0.87rem;
  text-decoration: none;
  display: inline-block;
  padding: 0;
}
@media (width <= 64rem) {
  .usa-nav__primary button {
    font-weight: 300;
  }
  .usa-nav__primary button[aria-expanded=false] span:after, .usa-nav__primary button[aria-expanded=true] span:after {
    display: none;
  }
  .usa-nav__primary button[aria-expanded=true] {
    font-weight: 700;
  }
  .usa-nav__primary button[aria-expanded=true]:after {
    background-color: #005ea2;
    content: "";
    display: block;
    position: absolute;
    bottom: 0.25rem;
    top: 0.25rem;
    width: 0.25rem;
    left: 0.25rem;
  }
  .usa-nav__primary button:hover {
    background-color: white;
  }
  .usa-nav__submenu-item {
    font-size: 0.99rem;
  }
}
#logo-container {
  display: flex;
  font-family: var(--base-font-family), ;
  gap: 0.75rem;
  max-height: 2.5rem;
}
#logo-container #logo-container-link {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: 700;
  font-size: 1.37rem;
  color: #1c1d1f;
}
#logo-container #nasa-logo-pos {
  opacity: 1;
  transform: translate(0, -100%);
  /* TODO: Fix the svg to not require any styles!
   * - set opacity to 1 in svg and fix translation
   */
}
#logo-container svg {
  height: 2.5rem;
  width: auto;
}
#logo-container #logo-container-beta-tag {
  color: white;
  align-self: center;
  font-size: 0.81rem;
  font-weight: normal;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  border-radius: 2px;
  background-color: #d83933;
}
.usa-banner__button:after {
  top: 3px;
}
.banner--left-aligned .usa-banner__inner, .banner--left-aligned .usa-banner__content {
  margin: 0;
}
.react-calendar {
  z-index: 99999;
  font-family: inherit !important;
}
.react-calendar * {
  font-family: var(--base-font-family), ;
  font-size: 0.87rem;
  line-height: 1.6;
  font-family: inherit !important;
}
.react-calendar__navigation {
  margin-bottom: 0 !important;
}
.react-calendar__month-view__weekdays__weekday {
  color: #1b1b1b;
}
.react-calendar__tile--active, .react-calendar__tile--hasActive {
  background-color: #0050d8 !important;
  color: #f0f0f0;
}
.react-calendar__tile--now:not(.react-calendar__tile--active), .react-calendar__tile--now:not(.react-calendar__tile--hasActive) {
  background: none !important;
  color: #1b1b1b !important;
}
.react-calendar__tile--now:not(.react-calendar__tile--active):hover, .react-calendar__tile--now:not(.react-calendar__tile--active):focus, .react-calendar__tile--now:not(.react-calendar__tile--hasActive):hover, .react-calendar__tile--now:not(.react-calendar__tile--hasActive):focus {
  background-color: #e6e6e6 !important;
}
.react-calendar__tile--now:disabled {
  background-color: #f0f0f0 !important;
  color: #adadad !important;
}
.react-calendar__tile--now:disabled:hover, .react-calendar__tile--now:disabled:focus {
  background-color: #f0f0f0 !important;
}
.react-calendar .usa-icon {
  width: 32px;
  height: 20px;
}
.react-calendar__navigation-button {
  min-width: 32px;
}
.react-calendar [class*="--weekend"]:not(.react-calendar__tile--active) {
  color: #1b1b1b !important;
}
.react-calendar abbr[title] {
  font-weight: normal;
  text-decoration: none;
}
.react-calendar .react-calendar__year-view .react-calendar__tile,
.react-calendar .react-calendar__decade-view .react-calendar__tile,
.react-calendar .react-calendar__century-view .react-calendar__tile {
  padding: 1em 0.1em !important;
}
.react-calendar__tippy {
  box-shadow: none !important;
  border: none !important;
  background: transparent !important;
}
.react-calendar__tippy[data-placement^=top] > .tippy-arrow::before {
  border-top-color: transparent !important;
}
.react-calendar__tippy[data-placement^=bottom] > .tippy-arrow::before {
  border-bottom-color: transparent !important;
}
.react-calendar__tippy[data-placement^=left] > .tippy-arrow::before {
  border-left-color: transparent !important;
}
.react-calendar__tippy[data-placement^=right] > .tippy-arrow::before {
  border-right-color: transparent !important;
}
.react-calendar__tippy .tippy-content {
  padding: 0 !important;
}
.footer-text {
  color: white;
  vertical-align: baseline;
  display: flex;
  font-size: 0.99rem;
  font-weight: normal;
  gap: 0.75rem;
}
.return-to-top-container {
  padding: 2rem 2.5rem;
  max-width: 64rem;
}
@media (width <= 40rem) {
  .return-to-top-container {
    padding: 1.5rem;
  }
}
.footer_primary_container {
  padding: 2rem 2.5rem;
  background-color: #f1f3f6;
}
@media (width <= 40rem) {
  .footer_primary_container {
    padding: 1.5rem;
  }
  .footer_primary_container .grid-row.grid-gap {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .footer_primary_container .grid-row.grid-gap > li {
    padding-left: 0;
    padding-right: 0;
  }
}
.usa-footer__secondary-section {
  background-color: #2d2e2f;
}
.usa-footer__primary-section {
  background-color: #f1f3f6;
  text-underline-offset: 0.25rem;
}
.usa-footer__address > .grid-row {
  justify-content: flex-end;
}
@media (width <= 40rem) {
  .usa-footer__address > .grid-row {
    justify-content: flex-start;
  }
}
@media (width <= 40rem) {
  .usa-footer__address {
    padding: 2rem 0 !important;
  }
}
#footer_secondary_container {
  flex-direction: row;
}
@media (width <= 40rem) {
  #footer_secondary_container {
    flex-direction: column;
  }
}
address {
  font-style: normal;
  font-weight: normal;
  font-size: 0.99rem;
  text-underline-offset: 0.25rem;
}
.usa-footer__contact-info {
  line-height: unset;
}
@media (width <= 40rem) {
  .usa-footer__contact-info {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.usa-footer__primary-content:first-child {
  border-top: none !important;
}
@media (width <= 64rem) {
  .usa-footer__address .mobile-lg\:grid-col-12,
  .usa-footer__nav .mobile-lg\:grid-col-6 {
    width: auto;
  }
}
@media (width <= 40rem) {
  .usa-footer__address .grid-row [class*=grid-col] {
    width: auto !important;
  }
  .usa-footer .grid-row [class*=grid-col] {
    width: 100%;
  }
  .usa-footer__primary-content {
    border-top: 1px solid #a9aeb1;
  }
  .usa-footer__primary-content:last-child {
    border-bottom: 1px solid #a9aeb1;
  }
}
@media (width <= 40rem) and (width <= 30rem) {
  .usa-footer__primary-content:last-child {
    border-bottom: 0;
  }
}