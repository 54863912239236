.usa-input {
  font-family: var(--base-font-family), ;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #1c1d1f;
  border: 1px solid #565c65;
  border-radius: 0;
  width: 100%;
  max-width: 30rem;
  height: 2.5rem;
  margin-top: .5rem;
  padding: .5rem;
  font-size: .99rem;
  line-height: 1.4;
  display: block;
}

[class*="font-sans-"], [class*="font-serif-"], [class*="font-ui-"], [class*="font-heading-"], [class*="font-body-"] {
  font-family: var(--base-font-family),  !important;
}

@supports (aspect-ratio: 1) {
  .add-aspect-9x16 {
    height: inherit;
    padding: inherit;
    aspect-ratio: .5625;
    max-width: 100%;
  }

  .add-aspect-9x16 > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  img.add-aspect-9x16, .add-aspect-9x16 > img {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@supports (aspect-ratio: 1) {
  .add-aspect-16x9 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1.77778;
    max-width: 100%;
  }

  .add-aspect-16x9 > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  img.add-aspect-16x9, .add-aspect-16x9 > img {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@supports (aspect-ratio: 1) {
  .add-aspect-1x1 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1;
    max-width: 100%;
  }

  .add-aspect-1x1 > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  img.add-aspect-1x1, .add-aspect-1x1 > img {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@supports (aspect-ratio: 1) {
  .add-aspect-4x3 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1.33333;
    max-width: 100%;
  }

  .add-aspect-4x3 > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  img.add-aspect-4x3, .add-aspect-4x3 > img {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@supports (aspect-ratio: 1) {
  .add-aspect-2x1 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 2;
    max-width: 100%;
  }

  .add-aspect-2x1 > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  img.add-aspect-2x1, .add-aspect-2x1 > img {
    -o-object-fit: cover;
    object-fit: cover;
  }

  :where(img[class*="add-aspect-"]) {
    width: min-content;
  }
}

.bg-transparent, .hover\:bg-transparent:hover {
  background-color: #0000 !important;
}

.bg-black, .hover\:bg-black:hover {
  background-color: #000 !important;
}

.bg-white, .hover\:bg-white:hover {
  background-color: #fff !important;
}

.bg-red, .hover\:bg-red:hover {
  background-color: #e52207 !important;
}

.bg-orange, .hover\:bg-orange:hover {
  background-color: #e66f0e !important;
}

.bg-gold, .hover\:bg-gold:hover {
  background-color: #ffbe2e !important;
}

.bg-yellow, .hover\:bg-yellow:hover {
  background-color: #fee685 !important;
}

.bg-green, .hover\:bg-green:hover {
  background-color: #538200 !important;
}

.bg-mint, .hover\:bg-mint:hover {
  background-color: #04c585 !important;
}

.bg-cyan, .hover\:bg-cyan:hover {
  background-color: #009ec1 !important;
}

.bg-blue, .hover\:bg-blue:hover {
  background-color: #0076d6 !important;
}

.bg-indigo, .hover\:bg-indigo:hover {
  background-color: #676cc8 !important;
}

.bg-violet, .hover\:bg-violet:hover {
  background-color: #8168b3 !important;
}

.bg-magenta, .hover\:bg-magenta:hover {
  background-color: #d72d79 !important;
}

.bg-gray-5, .hover\:bg-gray-5:hover {
  background-color: #f0f0f0 !important;
}

.bg-gray-10, .hover\:bg-gray-10:hover {
  background-color: #e6e6e6 !important;
}

.bg-gray-30, .hover\:bg-gray-30:hover {
  background-color: #adadad !important;
}

.bg-gray-50, .hover\:bg-gray-50:hover {
  background-color: #757575 !important;
}

.bg-gray-70, .hover\:bg-gray-70:hover {
  background-color: #454545 !important;
}

.bg-gray-90, .hover\:bg-gray-90:hover {
  background-color: #1b1b1b !important;
}

.bg-base-lightest, .hover\:bg-base-lightest:hover {
  background-color: #f0f0f0 !important;
}

.bg-base-lighter, .hover\:bg-base-lighter:hover {
  background-color: #dfe1e2 !important;
}

.bg-base-light, .hover\:bg-base-light:hover {
  background-color: #a9aeb1 !important;
}

.bg-base, .hover\:bg-base:hover {
  background-color: #71767a !important;
}

.bg-base-dark, .hover\:bg-base-dark:hover {
  background-color: #565c65 !important;
}

.bg-base-darker, .hover\:bg-base-darker:hover {
  background-color: #3d4551 !important;
}

.bg-base-darkest, .hover\:bg-base-darkest:hover {
  background-color: #2d2e2f !important;
}

.bg-ink, .hover\:bg-ink:hover {
  background-color: #1c1d1f !important;
}

.bg-primary-lighter, .hover\:bg-primary-lighter:hover {
  background-color: #d9e8f6 !important;
}

.bg-primary-light, .hover\:bg-primary-light:hover {
  background-color: #73b3e7 !important;
}

.bg-primary, .hover\:bg-primary:hover {
  background-color: #005ea2 !important;
}

.bg-primary-vivid, .hover\:bg-primary-vivid:hover {
  background-color: #0050d8 !important;
}

.bg-primary-dark, .hover\:bg-primary-dark:hover {
  background-color: #1a4480 !important;
}

.bg-primary-darker, .hover\:bg-primary-darker:hover {
  background-color: #162e51 !important;
}

.bg-secondary-lighter, .hover\:bg-secondary-lighter:hover {
  background-color: #f3e1e4 !important;
}

.bg-secondary-light, .hover\:bg-secondary-light:hover {
  background-color: #f2938c !important;
}

.bg-secondary, .hover\:bg-secondary:hover {
  background-color: #d83933 !important;
}

.bg-secondary-vivid, .hover\:bg-secondary-vivid:hover {
  background-color: #e41d3d !important;
}

.bg-secondary-dark, .hover\:bg-secondary-dark:hover {
  background-color: #b50909 !important;
}

.bg-secondary-darker, .hover\:bg-secondary-darker:hover {
  background-color: #8b0a03 !important;
}

.bg-accent-warm-darker, .hover\:bg-accent-warm-darker:hover {
  background-color: #775540 !important;
}

.bg-accent-warm-dark, .hover\:bg-accent-warm-dark:hover {
  background-color: #c05600 !important;
}

.bg-accent-warm, .hover\:bg-accent-warm:hover {
  background-color: #fa9441 !important;
}

.bg-accent-warm-light, .hover\:bg-accent-warm-light:hover {
  background-color: #ffbc78 !important;
}

.bg-accent-warm-lighter, .hover\:bg-accent-warm-lighter:hover {
  background-color: #f2e4d4 !important;
}

.bg-accent-cool-darker, .hover\:bg-accent-cool-darker:hover {
  background-color: #07648d !important;
}

.bg-accent-cool-dark, .hover\:bg-accent-cool-dark:hover {
  background-color: #28a0cb !important;
}

.bg-accent-cool, .hover\:bg-accent-cool:hover {
  background-color: #00bde3 !important;
}

.bg-accent-cool-light, .hover\:bg-accent-cool-light:hover {
  background-color: #97d4ea !important;
}

.bg-accent-cool-lighter, .hover\:bg-accent-cool-lighter:hover {
  background-color: #e1f3f8 !important;
}

.bg-error-lighter, .hover\:bg-error-lighter:hover {
  background-color: #f4e3db !important;
}

.bg-error-light, .hover\:bg-error-light:hover {
  background-color: #f39268 !important;
}

.bg-error, .hover\:bg-error:hover {
  background-color: #d54309 !important;
}

.bg-error-dark, .hover\:bg-error-dark:hover {
  background-color: #b50909 !important;
}

.bg-error-darker, .hover\:bg-error-darker:hover {
  background-color: #6f3331 !important;
}

.bg-warning-lighter, .hover\:bg-warning-lighter:hover {
  background-color: #faf3d1 !important;
}

.bg-warning-light, .hover\:bg-warning-light:hover {
  background-color: #fee685 !important;
}

.bg-warning, .hover\:bg-warning:hover {
  background-color: #ffbe2e !important;
}

.bg-warning-dark, .hover\:bg-warning-dark:hover {
  background-color: #e5a000 !important;
}

.bg-warning-darker, .hover\:bg-warning-darker:hover {
  background-color: #936f38 !important;
}

.bg-success-lighter, .hover\:bg-success-lighter:hover {
  background-color: #ecf3ec !important;
}

.bg-success-light, .hover\:bg-success-light:hover {
  background-color: #70e17b !important;
}

.bg-success, .hover\:bg-success:hover {
  background-color: #00a91c !important;
}

.bg-success-dark, .hover\:bg-success-dark:hover {
  background-color: #008817 !important;
}

.bg-success-darker, .hover\:bg-success-darker:hover {
  background-color: #216e1f !important;
}

.bg-info-lighter, .hover\:bg-info-lighter:hover {
  background-color: #e7f6f8 !important;
}

.bg-info-light, .hover\:bg-info-light:hover {
  background-color: #99deea !important;
}

.bg-info, .hover\:bg-info:hover {
  background-color: #00bde3 !important;
}

.bg-info-dark, .hover\:bg-info-dark:hover {
  background-color: #009ec1 !important;
}

.bg-info-darker, .hover\:bg-info-darker:hover {
  background-color: #2e6276 !important;
}

.bg-disabled-lighter, .hover\:bg-disabled-lighter:hover {
  background-color: #c9c9c9 !important;
}

.bg-disabled-light, .hover\:bg-disabled-light:hover {
  background-color: #919191 !important;
}

.bg-disabled, .hover\:bg-disabled:hover {
  background-color: #757575 !important;
}

.bg-disabled-dark, .hover\:bg-disabled-dark:hover {
  background-color: #454545 !important;
}

.bg-disabled-darker, .hover\:bg-disabled-darker:hover {
  background-color: #1b1b1b !important;
}

.bg-emergency, .hover\:bg-emergency:hover {
  background-color: #9c3d10 !important;
}

.bg-emergency-dark, .hover\:bg-emergency-dark:hover {
  background-color: #332d29 !important;
}

.border-1px, .hover\:border-1px:hover {
  border: 1px solid !important;
}

.border-y-1px, .hover\:border-y-1px:hover {
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}

.border-x-1px, .hover\:border-x-1px:hover {
  border-left: 1px solid !important;
  border-right: 1px solid !important;
}

.border-top-1px, .hover\:border-top-1px:hover {
  border-top: 1px solid !important;
}

.border-right-1px, .hover\:border-right-1px:hover {
  border-right: 1px solid !important;
}

.border-bottom-1px, .hover\:border-bottom-1px:hover {
  border-bottom: 1px solid !important;
}

.border-left-1px, .hover\:border-left-1px:hover {
  border-left: 1px solid !important;
}

.border-2px, .hover\:border-2px:hover {
  border: 2px solid !important;
}

.border-y-2px, .hover\:border-y-2px:hover {
  border-top: 2px solid !important;
  border-bottom: 2px solid !important;
}

.border-x-2px, .hover\:border-x-2px:hover {
  border-left: 2px solid !important;
  border-right: 2px solid !important;
}

.border-top-2px, .hover\:border-top-2px:hover {
  border-top: 2px solid !important;
}

.border-right-2px, .hover\:border-right-2px:hover {
  border-right: 2px solid !important;
}

.border-bottom-2px, .hover\:border-bottom-2px:hover {
  border-bottom: 2px solid !important;
}

.border-left-2px, .hover\:border-left-2px:hover {
  border-left: 2px solid !important;
}

.border-05, .hover\:border-05:hover {
  border: .25rem solid !important;
}

.border-y-05, .hover\:border-y-05:hover {
  border-top: .25rem solid !important;
  border-bottom: .25rem solid !important;
}

.border-x-05, .hover\:border-x-05:hover {
  border-left: .25rem solid !important;
  border-right: .25rem solid !important;
}

.border-top-05, .hover\:border-top-05:hover {
  border-top: .25rem solid !important;
}

.border-right-05, .hover\:border-right-05:hover {
  border-right: .25rem solid !important;
}

.border-bottom-05, .hover\:border-bottom-05:hover {
  border-bottom: .25rem solid !important;
}

.border-left-05, .hover\:border-left-05:hover {
  border-left: .25rem solid !important;
}

.border-1, .hover\:border-1:hover {
  border: .5rem solid !important;
}

.border-y-1, .hover\:border-y-1:hover {
  border-top: .5rem solid !important;
  border-bottom: .5rem solid !important;
}

.border-x-1, .hover\:border-x-1:hover {
  border-left: .5rem solid !important;
  border-right: .5rem solid !important;
}

.border-top-1, .hover\:border-top-1:hover {
  border-top: .5rem solid !important;
}

.border-right-1, .hover\:border-right-1:hover {
  border-right: .5rem solid !important;
}

.border-bottom-1, .hover\:border-bottom-1:hover {
  border-bottom: .5rem solid !important;
}

.border-left-1, .hover\:border-left-1:hover {
  border-left: .5rem solid !important;
}

.border-105, .hover\:border-105:hover {
  border: .75rem solid !important;
}

.border-y-105, .hover\:border-y-105:hover {
  border-top: .75rem solid !important;
  border-bottom: .75rem solid !important;
}

.border-x-105, .hover\:border-x-105:hover {
  border-left: .75rem solid !important;
  border-right: .75rem solid !important;
}

.border-top-105, .hover\:border-top-105:hover {
  border-top: .75rem solid !important;
}

.border-right-105, .hover\:border-right-105:hover {
  border-right: .75rem solid !important;
}

.border-bottom-105, .hover\:border-bottom-105:hover {
  border-bottom: .75rem solid !important;
}

.border-left-105, .hover\:border-left-105:hover {
  border-left: .75rem solid !important;
}

.border-2, .hover\:border-2:hover {
  border: 1rem solid !important;
}

.border-y-2, .hover\:border-y-2:hover {
  border-top: 1rem solid !important;
  border-bottom: 1rem solid !important;
}

.border-x-2, .hover\:border-x-2:hover {
  border-left: 1rem solid !important;
  border-right: 1rem solid !important;
}

.border-top-2, .hover\:border-top-2:hover {
  border-top: 1rem solid !important;
}

.border-right-2, .hover\:border-right-2:hover {
  border-right: 1rem solid !important;
}

.border-bottom-2, .hover\:border-bottom-2:hover {
  border-bottom: 1rem solid !important;
}

.border-left-2, .hover\:border-left-2:hover {
  border-left: 1rem solid !important;
}

.border-205, .hover\:border-205:hover {
  border: 1.25rem solid !important;
}

.border-y-205, .hover\:border-y-205:hover {
  border-top: 1.25rem solid !important;
  border-bottom: 1.25rem solid !important;
}

.border-x-205, .hover\:border-x-205:hover {
  border-left: 1.25rem solid !important;
  border-right: 1.25rem solid !important;
}

.border-top-205, .hover\:border-top-205:hover {
  border-top: 1.25rem solid !important;
}

.border-right-205, .hover\:border-right-205:hover {
  border-right: 1.25rem solid !important;
}

.border-bottom-205, .hover\:border-bottom-205:hover {
  border-bottom: 1.25rem solid !important;
}

.border-left-205, .hover\:border-left-205:hover {
  border-left: 1.25rem solid !important;
}

.border-3, .hover\:border-3:hover {
  border: 1.5rem solid !important;
}

.border-y-3, .hover\:border-y-3:hover {
  border-top: 1.5rem solid !important;
  border-bottom: 1.5rem solid !important;
}

.border-x-3, .hover\:border-x-3:hover {
  border-left: 1.5rem solid !important;
  border-right: 1.5rem solid !important;
}

.border-top-3, .hover\:border-top-3:hover {
  border-top: 1.5rem solid !important;
}

.border-right-3, .hover\:border-right-3:hover {
  border-right: 1.5rem solid !important;
}

.border-bottom-3, .hover\:border-bottom-3:hover {
  border-bottom: 1.5rem solid !important;
}

.border-left-3, .hover\:border-left-3:hover {
  border-left: 1.5rem solid !important;
}

.border-0, .hover\:border-0:hover {
  border: 0 solid !important;
}

.border-y-0, .hover\:border-y-0:hover {
  border-top: 0 solid !important;
  border-bottom: 0 solid !important;
}

.border-x-0, .hover\:border-x-0:hover {
  border-left: 0 solid !important;
  border-right: 0 solid !important;
}

.border-top-0, .hover\:border-top-0:hover {
  border-top: 0 solid !important;
}

.border-right-0, .hover\:border-right-0:hover {
  border-right: 0 solid !important;
}

.border-bottom-0, .hover\:border-bottom-0:hover {
  border-bottom: 0 solid !important;
}

.border-left-0, .hover\:border-left-0:hover {
  border-left: 0 solid !important;
}

.border, .hover\:border:hover {
  border: 1px solid !important;
}

.border-y, .hover\:border-y:hover {
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}

.border-x, .hover\:border-x:hover {
  border-left: 1px solid !important;
  border-right: 1px solid !important;
}

.border-top, .hover\:border-top:hover {
  border-top: 1px solid !important;
}

.border-right, .hover\:border-right:hover {
  border-right: 1px solid !important;
}

.border-bottom, .hover\:border-bottom:hover {
  border-bottom: 1px solid !important;
}

.border-left, .hover\:border-left:hover {
  border-left: 1px solid !important;
}

.border-transparent, .hover\:border-transparent:hover {
  border-color: #0000 !important;
}

.border-black, .hover\:border-black:hover {
  border-color: #000 !important;
}

.border-white, .hover\:border-white:hover {
  border-color: #fff !important;
}

.border-red, .hover\:border-red:hover {
  border-color: #e52207 !important;
}

.border-orange, .hover\:border-orange:hover {
  border-color: #e66f0e !important;
}

.border-gold, .hover\:border-gold:hover {
  border-color: #ffbe2e !important;
}

.border-yellow, .hover\:border-yellow:hover {
  border-color: #fee685 !important;
}

.border-green, .hover\:border-green:hover {
  border-color: #538200 !important;
}

.border-mint, .hover\:border-mint:hover {
  border-color: #04c585 !important;
}

.border-cyan, .hover\:border-cyan:hover {
  border-color: #009ec1 !important;
}

.border-blue, .hover\:border-blue:hover {
  border-color: #0076d6 !important;
}

.border-indigo, .hover\:border-indigo:hover {
  border-color: #676cc8 !important;
}

.border-violet, .hover\:border-violet:hover {
  border-color: #8168b3 !important;
}

.border-magenta, .hover\:border-magenta:hover {
  border-color: #d72d79 !important;
}

.border-gray-5, .hover\:border-gray-5:hover {
  border-color: #f0f0f0 !important;
}

.border-gray-10, .hover\:border-gray-10:hover {
  border-color: #e6e6e6 !important;
}

.border-gray-30, .hover\:border-gray-30:hover {
  border-color: #adadad !important;
}

.border-gray-50, .hover\:border-gray-50:hover {
  border-color: #757575 !important;
}

.border-gray-70, .hover\:border-gray-70:hover {
  border-color: #454545 !important;
}

.border-gray-90, .hover\:border-gray-90:hover {
  border-color: #1b1b1b !important;
}

.border-base-lightest, .hover\:border-base-lightest:hover {
  border-color: #f0f0f0 !important;
}

.border-base-lighter, .hover\:border-base-lighter:hover {
  border-color: #dfe1e2 !important;
}

.border-base-light, .hover\:border-base-light:hover {
  border-color: #a9aeb1 !important;
}

.border-base, .hover\:border-base:hover {
  border-color: #71767a !important;
}

.border-base-dark, .hover\:border-base-dark:hover {
  border-color: #565c65 !important;
}

.border-base-darker, .hover\:border-base-darker:hover {
  border-color: #3d4551 !important;
}

.border-base-darkest, .hover\:border-base-darkest:hover {
  border-color: #2d2e2f !important;
}

.border-ink, .hover\:border-ink:hover {
  border-color: #1c1d1f !important;
}

.border-primary-lighter, .hover\:border-primary-lighter:hover {
  border-color: #d9e8f6 !important;
}

.border-primary-light, .hover\:border-primary-light:hover {
  border-color: #73b3e7 !important;
}

.border-primary, .hover\:border-primary:hover {
  border-color: #005ea2 !important;
}

.border-primary-vivid, .hover\:border-primary-vivid:hover {
  border-color: #0050d8 !important;
}

.border-primary-dark, .hover\:border-primary-dark:hover {
  border-color: #1a4480 !important;
}

.border-primary-darker, .hover\:border-primary-darker:hover {
  border-color: #162e51 !important;
}

.border-secondary-lighter, .hover\:border-secondary-lighter:hover {
  border-color: #f3e1e4 !important;
}

.border-secondary-light, .hover\:border-secondary-light:hover {
  border-color: #f2938c !important;
}

.border-secondary, .hover\:border-secondary:hover {
  border-color: #d83933 !important;
}

.border-secondary-vivid, .hover\:border-secondary-vivid:hover {
  border-color: #e41d3d !important;
}

.border-secondary-dark, .hover\:border-secondary-dark:hover {
  border-color: #b50909 !important;
}

.border-secondary-darker, .hover\:border-secondary-darker:hover {
  border-color: #8b0a03 !important;
}

.border-accent-warm-darker, .hover\:border-accent-warm-darker:hover {
  border-color: #775540 !important;
}

.border-accent-warm-dark, .hover\:border-accent-warm-dark:hover {
  border-color: #c05600 !important;
}

.border-accent-warm, .hover\:border-accent-warm:hover {
  border-color: #fa9441 !important;
}

.border-accent-warm-light, .hover\:border-accent-warm-light:hover {
  border-color: #ffbc78 !important;
}

.border-accent-warm-lighter, .hover\:border-accent-warm-lighter:hover {
  border-color: #f2e4d4 !important;
}

.border-accent-cool-darker, .hover\:border-accent-cool-darker:hover {
  border-color: #07648d !important;
}

.border-accent-cool-dark, .hover\:border-accent-cool-dark:hover {
  border-color: #28a0cb !important;
}

.border-accent-cool, .hover\:border-accent-cool:hover {
  border-color: #00bde3 !important;
}

.border-accent-cool-light, .hover\:border-accent-cool-light:hover {
  border-color: #97d4ea !important;
}

.border-accent-cool-lighter, .hover\:border-accent-cool-lighter:hover {
  border-color: #e1f3f8 !important;
}

.border-error-lighter, .hover\:border-error-lighter:hover {
  border-color: #f4e3db !important;
}

.border-error-light, .hover\:border-error-light:hover {
  border-color: #f39268 !important;
}

.border-error, .hover\:border-error:hover {
  border-color: #d54309 !important;
}

.border-error-dark, .hover\:border-error-dark:hover {
  border-color: #b50909 !important;
}

.border-error-darker, .hover\:border-error-darker:hover {
  border-color: #6f3331 !important;
}

.border-warning-lighter, .hover\:border-warning-lighter:hover {
  border-color: #faf3d1 !important;
}

.border-warning-light, .hover\:border-warning-light:hover {
  border-color: #fee685 !important;
}

.border-warning, .hover\:border-warning:hover {
  border-color: #ffbe2e !important;
}

.border-warning-dark, .hover\:border-warning-dark:hover {
  border-color: #e5a000 !important;
}

.border-warning-darker, .hover\:border-warning-darker:hover {
  border-color: #936f38 !important;
}

.border-success-lighter, .hover\:border-success-lighter:hover {
  border-color: #ecf3ec !important;
}

.border-success-light, .hover\:border-success-light:hover {
  border-color: #70e17b !important;
}

.border-success, .hover\:border-success:hover {
  border-color: #00a91c !important;
}

.border-success-dark, .hover\:border-success-dark:hover {
  border-color: #008817 !important;
}

.border-success-darker, .hover\:border-success-darker:hover {
  border-color: #216e1f !important;
}

.border-info-lighter, .hover\:border-info-lighter:hover {
  border-color: #e7f6f8 !important;
}

.border-info-light, .hover\:border-info-light:hover {
  border-color: #99deea !important;
}

.border-info, .hover\:border-info:hover {
  border-color: #00bde3 !important;
}

.border-info-dark, .hover\:border-info-dark:hover {
  border-color: #009ec1 !important;
}

.border-info-darker, .hover\:border-info-darker:hover {
  border-color: #2e6276 !important;
}

.border-disabled-lighter, .hover\:border-disabled-lighter:hover {
  border-color: #c9c9c9 !important;
}

.border-disabled-light, .hover\:border-disabled-light:hover {
  border-color: #919191 !important;
}

.border-disabled, .hover\:border-disabled:hover {
  border-color: #757575 !important;
}

.border-disabled-dark, .hover\:border-disabled-dark:hover {
  border-color: #454545 !important;
}

.border-disabled-darker, .hover\:border-disabled-darker:hover {
  border-color: #1b1b1b !important;
}

.border-emergency, .hover\:border-emergency:hover {
  border-color: #9c3d10 !important;
}

.border-emergency-dark, .hover\:border-emergency-dark:hover {
  border-color: #332d29 !important;
}

.shadow-none, .hover\:shadow-none:hover {
  box-shadow: none !important;
}

.shadow-1, .hover\:shadow-1:hover {
  box-shadow: 0 1px .25rem #0000001a !important;
}

.shadow-2, .hover\:shadow-2:hover {
  box-shadow: 0 .25rem .5rem #0000001a !important;
}

.shadow-3, .hover\:shadow-3:hover {
  box-shadow: 0 .5rem 1rem #0000001a !important;
}

.shadow-4, .hover\:shadow-4:hover {
  box-shadow: 0 .75rem 1.5rem #0000001a !important;
}

.shadow-5, .hover\:shadow-5:hover {
  box-shadow: 0 1rem 2rem #0000001a !important;
}

.text-transparent, .hover\:text-transparent:hover {
  color: #0000 !important;
}

.text-black, .hover\:text-black:hover {
  color: #000 !important;
}

.text-white, .hover\:text-white:hover {
  color: #fff !important;
}

.text-red, .hover\:text-red:hover {
  color: #e52207 !important;
}

.text-orange, .hover\:text-orange:hover {
  color: #e66f0e !important;
}

.text-gold, .hover\:text-gold:hover {
  color: #ffbe2e !important;
}

.text-yellow, .hover\:text-yellow:hover {
  color: #fee685 !important;
}

.text-green, .hover\:text-green:hover {
  color: #538200 !important;
}

.text-mint, .hover\:text-mint:hover {
  color: #04c585 !important;
}

.text-cyan, .hover\:text-cyan:hover {
  color: #009ec1 !important;
}

.text-blue, .hover\:text-blue:hover {
  color: #0076d6 !important;
}

.text-indigo, .hover\:text-indigo:hover {
  color: #676cc8 !important;
}

.text-violet, .hover\:text-violet:hover {
  color: #8168b3 !important;
}

.text-magenta, .hover\:text-magenta:hover {
  color: #d72d79 !important;
}

.text-gray-5, .hover\:text-gray-5:hover {
  color: #f0f0f0 !important;
}

.text-gray-10, .hover\:text-gray-10:hover {
  color: #e6e6e6 !important;
}

.text-gray-30, .hover\:text-gray-30:hover {
  color: #adadad !important;
}

.text-gray-50, .hover\:text-gray-50:hover {
  color: #757575 !important;
}

.text-gray-70, .hover\:text-gray-70:hover {
  color: #454545 !important;
}

.text-gray-90, .hover\:text-gray-90:hover {
  color: #1b1b1b !important;
}

.text-base-lightest, .hover\:text-base-lightest:hover {
  color: #f0f0f0 !important;
}

.text-base-lighter, .hover\:text-base-lighter:hover {
  color: #dfe1e2 !important;
}

.text-base-light, .hover\:text-base-light:hover {
  color: #a9aeb1 !important;
}

.text-base, .hover\:text-base:hover {
  color: #71767a !important;
}

.text-base-dark, .hover\:text-base-dark:hover {
  color: #565c65 !important;
}

.text-base-darker, .hover\:text-base-darker:hover {
  color: #3d4551 !important;
}

.text-base-darkest, .hover\:text-base-darkest:hover {
  color: #2d2e2f !important;
}

.text-ink, .hover\:text-ink:hover {
  color: #1c1d1f !important;
}

.text-primary-lighter, .hover\:text-primary-lighter:hover {
  color: #d9e8f6 !important;
}

.text-primary-light, .hover\:text-primary-light:hover {
  color: #73b3e7 !important;
}

.text-primary, .hover\:text-primary:hover {
  color: #005ea2 !important;
}

.text-primary-vivid, .hover\:text-primary-vivid:hover {
  color: #0050d8 !important;
}

.text-primary-dark, .hover\:text-primary-dark:hover {
  color: #1a4480 !important;
}

.text-primary-darker, .hover\:text-primary-darker:hover {
  color: #162e51 !important;
}

.text-secondary-lighter, .hover\:text-secondary-lighter:hover {
  color: #f3e1e4 !important;
}

.text-secondary-light, .hover\:text-secondary-light:hover {
  color: #f2938c !important;
}

.text-secondary, .hover\:text-secondary:hover {
  color: #d83933 !important;
}

.text-secondary-vivid, .hover\:text-secondary-vivid:hover {
  color: #e41d3d !important;
}

.text-secondary-dark, .hover\:text-secondary-dark:hover {
  color: #b50909 !important;
}

.text-secondary-darker, .hover\:text-secondary-darker:hover {
  color: #8b0a03 !important;
}

.text-accent-warm-darker, .hover\:text-accent-warm-darker:hover {
  color: #775540 !important;
}

.text-accent-warm-dark, .hover\:text-accent-warm-dark:hover {
  color: #c05600 !important;
}

.text-accent-warm, .hover\:text-accent-warm:hover {
  color: #fa9441 !important;
}

.text-accent-warm-light, .hover\:text-accent-warm-light:hover {
  color: #ffbc78 !important;
}

.text-accent-warm-lighter, .hover\:text-accent-warm-lighter:hover {
  color: #f2e4d4 !important;
}

.text-accent-cool-darker, .hover\:text-accent-cool-darker:hover {
  color: #07648d !important;
}

.text-accent-cool-dark, .hover\:text-accent-cool-dark:hover {
  color: #28a0cb !important;
}

.text-accent-cool, .hover\:text-accent-cool:hover {
  color: #00bde3 !important;
}

.text-accent-cool-light, .hover\:text-accent-cool-light:hover {
  color: #97d4ea !important;
}

.text-accent-cool-lighter, .hover\:text-accent-cool-lighter:hover {
  color: #e1f3f8 !important;
}

.text-error-lighter, .hover\:text-error-lighter:hover {
  color: #f4e3db !important;
}

.text-error-light, .hover\:text-error-light:hover {
  color: #f39268 !important;
}

.text-error, .hover\:text-error:hover {
  color: #d54309 !important;
}

.text-error-dark, .hover\:text-error-dark:hover {
  color: #b50909 !important;
}

.text-error-darker, .hover\:text-error-darker:hover {
  color: #6f3331 !important;
}

.text-warning-lighter, .hover\:text-warning-lighter:hover {
  color: #faf3d1 !important;
}

.text-warning-light, .hover\:text-warning-light:hover {
  color: #fee685 !important;
}

.text-warning, .hover\:text-warning:hover {
  color: #ffbe2e !important;
}

.text-warning-dark, .hover\:text-warning-dark:hover {
  color: #e5a000 !important;
}

.text-warning-darker, .hover\:text-warning-darker:hover {
  color: #936f38 !important;
}

.text-success-lighter, .hover\:text-success-lighter:hover {
  color: #ecf3ec !important;
}

.text-success-light, .hover\:text-success-light:hover {
  color: #70e17b !important;
}

.text-success, .hover\:text-success:hover {
  color: #00a91c !important;
}

.text-success-dark, .hover\:text-success-dark:hover {
  color: #008817 !important;
}

.text-success-darker, .hover\:text-success-darker:hover {
  color: #216e1f !important;
}

.text-info-lighter, .hover\:text-info-lighter:hover {
  color: #e7f6f8 !important;
}

.text-info-light, .hover\:text-info-light:hover {
  color: #99deea !important;
}

.text-info, .hover\:text-info:hover {
  color: #00bde3 !important;
}

.text-info-dark, .hover\:text-info-dark:hover {
  color: #009ec1 !important;
}

.text-info-darker, .hover\:text-info-darker:hover {
  color: #2e6276 !important;
}

.text-disabled-lighter, .hover\:text-disabled-lighter:hover {
  color: #c9c9c9 !important;
}

.text-disabled-light, .hover\:text-disabled-light:hover {
  color: #919191 !important;
}

.text-disabled, .hover\:text-disabled:hover {
  color: #757575 !important;
}

.text-disabled-dark, .hover\:text-disabled-dark:hover {
  color: #454545 !important;
}

.text-disabled-darker, .hover\:text-disabled-darker:hover {
  color: #1b1b1b !important;
}

.text-emergency, .hover\:text-emergency:hover {
  color: #9c3d10 !important;
}

.text-emergency-dark, .hover\:text-emergency-dark:hover {
  color: #332d29 !important;
}

.font-family-sans, .font-family-serif, .font-family-ui, .font-family-heading, .font-family-body {
  font-family: var(--base-font-family),  !important;
}

.text-strike, .hover\:text-strike:hover {
  text-decoration: line-through !important;
}

.text-underline, .hover\:text-underline:hover {
  text-decoration: underline !important;
}

.text-no-underline, .hover\:text-no-underline:hover, .text-no-strike, .hover\:text-no-strike:hover {
  text-decoration: none !important;
}

.underline-red, .hover\:underline-red:hover {
  text-decoration-color: #e52207 !important;
}

.underline-orange, .hover\:underline-orange:hover {
  text-decoration-color: #e66f0e !important;
}

.underline-gold, .hover\:underline-gold:hover {
  text-decoration-color: #ffbe2e !important;
}

.underline-yellow, .hover\:underline-yellow:hover {
  text-decoration-color: #fee685 !important;
}

.underline-green, .hover\:underline-green:hover {
  text-decoration-color: #538200 !important;
}

.underline-mint, .hover\:underline-mint:hover {
  text-decoration-color: #04c585 !important;
}

.underline-cyan, .hover\:underline-cyan:hover {
  text-decoration-color: #009ec1 !important;
}

.underline-blue, .hover\:underline-blue:hover {
  text-decoration-color: #0076d6 !important;
}

.underline-indigo, .hover\:underline-indigo:hover {
  text-decoration-color: #676cc8 !important;
}

.underline-violet, .hover\:underline-violet:hover {
  text-decoration-color: #8168b3 !important;
}

.underline-magenta, .hover\:underline-magenta:hover {
  text-decoration-color: #d72d79 !important;
}

.underline-gray-5, .hover\:underline-gray-5:hover {
  text-decoration-color: #f0f0f0 !important;
}

.underline-gray-10, .hover\:underline-gray-10:hover {
  text-decoration-color: #e6e6e6 !important;
}

.underline-gray-30, .hover\:underline-gray-30:hover {
  text-decoration-color: #adadad !important;
}

.underline-gray-50, .hover\:underline-gray-50:hover {
  text-decoration-color: #757575 !important;
}

.underline-gray-70, .hover\:underline-gray-70:hover {
  text-decoration-color: #454545 !important;
}

.underline-gray-90, .hover\:underline-gray-90:hover {
  text-decoration-color: #1b1b1b !important;
}

.underline-base-lightest, .hover\:underline-base-lightest:hover {
  text-decoration-color: #f0f0f0 !important;
}

.underline-base-lighter, .hover\:underline-base-lighter:hover {
  text-decoration-color: #dfe1e2 !important;
}

.underline-base-light, .hover\:underline-base-light:hover {
  text-decoration-color: #a9aeb1 !important;
}

.underline-base, .hover\:underline-base:hover {
  text-decoration-color: #71767a !important;
}

.underline-base-dark, .hover\:underline-base-dark:hover {
  text-decoration-color: #565c65 !important;
}

.underline-base-darker, .hover\:underline-base-darker:hover {
  text-decoration-color: #3d4551 !important;
}

.underline-base-darkest, .hover\:underline-base-darkest:hover {
  text-decoration-color: #2d2e2f !important;
}

.underline-ink, .hover\:underline-ink:hover {
  text-decoration-color: #1c1d1f !important;
}

.underline-primary-lighter, .hover\:underline-primary-lighter:hover {
  text-decoration-color: #d9e8f6 !important;
}

.underline-primary-light, .hover\:underline-primary-light:hover {
  text-decoration-color: #73b3e7 !important;
}

.underline-primary, .hover\:underline-primary:hover {
  text-decoration-color: #005ea2 !important;
}

.underline-primary-vivid, .hover\:underline-primary-vivid:hover {
  text-decoration-color: #0050d8 !important;
}

.underline-primary-dark, .hover\:underline-primary-dark:hover {
  text-decoration-color: #1a4480 !important;
}

.underline-primary-darker, .hover\:underline-primary-darker:hover {
  text-decoration-color: #162e51 !important;
}

.underline-secondary-lighter, .hover\:underline-secondary-lighter:hover {
  text-decoration-color: #f3e1e4 !important;
}

.underline-secondary-light, .hover\:underline-secondary-light:hover {
  text-decoration-color: #f2938c !important;
}

.underline-secondary, .hover\:underline-secondary:hover {
  text-decoration-color: #d83933 !important;
}

.underline-secondary-vivid, .hover\:underline-secondary-vivid:hover {
  text-decoration-color: #e41d3d !important;
}

.underline-secondary-dark, .hover\:underline-secondary-dark:hover {
  text-decoration-color: #b50909 !important;
}

.underline-secondary-darker, .hover\:underline-secondary-darker:hover {
  text-decoration-color: #8b0a03 !important;
}

.underline-accent-warm-darker, .hover\:underline-accent-warm-darker:hover {
  text-decoration-color: #775540 !important;
}

.underline-accent-warm-dark, .hover\:underline-accent-warm-dark:hover {
  text-decoration-color: #c05600 !important;
}

.underline-accent-warm, .hover\:underline-accent-warm:hover {
  text-decoration-color: #fa9441 !important;
}

.underline-accent-warm-light, .hover\:underline-accent-warm-light:hover {
  text-decoration-color: #ffbc78 !important;
}

.underline-accent-warm-lighter, .hover\:underline-accent-warm-lighter:hover {
  text-decoration-color: #f2e4d4 !important;
}

.underline-accent-cool-darker, .hover\:underline-accent-cool-darker:hover {
  text-decoration-color: #07648d !important;
}

.underline-accent-cool-dark, .hover\:underline-accent-cool-dark:hover {
  text-decoration-color: #28a0cb !important;
}

.underline-accent-cool, .hover\:underline-accent-cool:hover {
  text-decoration-color: #00bde3 !important;
}

.underline-accent-cool-light, .hover\:underline-accent-cool-light:hover {
  text-decoration-color: #97d4ea !important;
}

.underline-accent-cool-lighter, .hover\:underline-accent-cool-lighter:hover {
  text-decoration-color: #e1f3f8 !important;
}

.underline-error-lighter, .hover\:underline-error-lighter:hover {
  text-decoration-color: #f4e3db !important;
}

.underline-error-light, .hover\:underline-error-light:hover {
  text-decoration-color: #f39268 !important;
}

.underline-error, .hover\:underline-error:hover {
  text-decoration-color: #d54309 !important;
}

.underline-error-dark, .hover\:underline-error-dark:hover {
  text-decoration-color: #b50909 !important;
}

.underline-error-darker, .hover\:underline-error-darker:hover {
  text-decoration-color: #6f3331 !important;
}

.underline-warning-lighter, .hover\:underline-warning-lighter:hover {
  text-decoration-color: #faf3d1 !important;
}

.underline-warning-light, .hover\:underline-warning-light:hover {
  text-decoration-color: #fee685 !important;
}

.underline-warning, .hover\:underline-warning:hover {
  text-decoration-color: #ffbe2e !important;
}

.underline-warning-dark, .hover\:underline-warning-dark:hover {
  text-decoration-color: #e5a000 !important;
}

.underline-warning-darker, .hover\:underline-warning-darker:hover {
  text-decoration-color: #936f38 !important;
}

.underline-success-lighter, .hover\:underline-success-lighter:hover {
  text-decoration-color: #ecf3ec !important;
}

.underline-success-light, .hover\:underline-success-light:hover {
  text-decoration-color: #70e17b !important;
}

.underline-success, .hover\:underline-success:hover {
  text-decoration-color: #00a91c !important;
}

.underline-success-dark, .hover\:underline-success-dark:hover {
  text-decoration-color: #008817 !important;
}

.underline-success-darker, .hover\:underline-success-darker:hover {
  text-decoration-color: #216e1f !important;
}

.underline-info-lighter, .hover\:underline-info-lighter:hover {
  text-decoration-color: #e7f6f8 !important;
}

.underline-info-light, .hover\:underline-info-light:hover {
  text-decoration-color: #99deea !important;
}

.underline-info, .hover\:underline-info:hover {
  text-decoration-color: #00bde3 !important;
}

.underline-info-dark, .hover\:underline-info-dark:hover {
  text-decoration-color: #009ec1 !important;
}

.underline-info-darker, .hover\:underline-info-darker:hover {
  text-decoration-color: #2e6276 !important;
}

.underline-disabled-lighter, .hover\:underline-disabled-lighter:hover {
  text-decoration-color: #c9c9c9 !important;
}

.underline-disabled-light, .hover\:underline-disabled-light:hover {
  text-decoration-color: #919191 !important;
}

.underline-disabled, .hover\:underline-disabled:hover {
  text-decoration-color: #757575 !important;
}

.underline-disabled-dark, .hover\:underline-disabled-dark:hover {
  text-decoration-color: #454545 !important;
}

.underline-disabled-darker, .hover\:underline-disabled-darker:hover {
  text-decoration-color: #1b1b1b !important;
}

.underline-emergency, .hover\:underline-emergency:hover {
  text-decoration-color: #9c3d10 !important;
}

.underline-emergency-dark, .hover\:underline-emergency-dark:hover {
  text-decoration-color: #332d29 !important;
}

@media (width >= 30em) {
  .mobile-lg\:border-1px, .mobile-lg\:hover\:border-1px:hover {
    border: 1px solid !important;
  }

  .mobile-lg\:border-y-1px, .mobile-lg\:hover\:border-y-1px:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .mobile-lg\:border-x-1px, .mobile-lg\:hover\:border-x-1px:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .mobile-lg\:border-top-1px, .mobile-lg\:hover\:border-top-1px:hover {
    border-top: 1px solid !important;
  }

  .mobile-lg\:border-right-1px, .mobile-lg\:hover\:border-right-1px:hover {
    border-right: 1px solid !important;
  }

  .mobile-lg\:border-bottom-1px, .mobile-lg\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid !important;
  }

  .mobile-lg\:border-left-1px, .mobile-lg\:hover\:border-left-1px:hover {
    border-left: 1px solid !important;
  }

  .mobile-lg\:border-2px, .mobile-lg\:hover\:border-2px:hover {
    border: 2px solid !important;
  }

  .mobile-lg\:border-y-2px, .mobile-lg\:hover\:border-y-2px:hover {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }

  .mobile-lg\:border-x-2px, .mobile-lg\:hover\:border-x-2px:hover {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }

  .mobile-lg\:border-top-2px, .mobile-lg\:hover\:border-top-2px:hover {
    border-top: 2px solid !important;
  }

  .mobile-lg\:border-right-2px, .mobile-lg\:hover\:border-right-2px:hover {
    border-right: 2px solid !important;
  }

  .mobile-lg\:border-bottom-2px, .mobile-lg\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid !important;
  }

  .mobile-lg\:border-left-2px, .mobile-lg\:hover\:border-left-2px:hover {
    border-left: 2px solid !important;
  }

  .mobile-lg\:border-05, .mobile-lg\:hover\:border-05:hover {
    border: .25rem solid !important;
  }

  .mobile-lg\:border-y-05, .mobile-lg\:hover\:border-y-05:hover {
    border-top: .25rem solid !important;
    border-bottom: .25rem solid !important;
  }

  .mobile-lg\:border-x-05, .mobile-lg\:hover\:border-x-05:hover {
    border-left: .25rem solid !important;
    border-right: .25rem solid !important;
  }

  .mobile-lg\:border-top-05, .mobile-lg\:hover\:border-top-05:hover {
    border-top: .25rem solid !important;
  }

  .mobile-lg\:border-right-05, .mobile-lg\:hover\:border-right-05:hover {
    border-right: .25rem solid !important;
  }

  .mobile-lg\:border-bottom-05, .mobile-lg\:hover\:border-bottom-05:hover {
    border-bottom: .25rem solid !important;
  }

  .mobile-lg\:border-left-05, .mobile-lg\:hover\:border-left-05:hover {
    border-left: .25rem solid !important;
  }

  .mobile-lg\:border-1, .mobile-lg\:hover\:border-1:hover {
    border: .5rem solid !important;
  }

  .mobile-lg\:border-y-1, .mobile-lg\:hover\:border-y-1:hover {
    border-top: .5rem solid !important;
    border-bottom: .5rem solid !important;
  }

  .mobile-lg\:border-x-1, .mobile-lg\:hover\:border-x-1:hover {
    border-left: .5rem solid !important;
    border-right: .5rem solid !important;
  }

  .mobile-lg\:border-top-1, .mobile-lg\:hover\:border-top-1:hover {
    border-top: .5rem solid !important;
  }

  .mobile-lg\:border-right-1, .mobile-lg\:hover\:border-right-1:hover {
    border-right: .5rem solid !important;
  }

  .mobile-lg\:border-bottom-1, .mobile-lg\:hover\:border-bottom-1:hover {
    border-bottom: .5rem solid !important;
  }

  .mobile-lg\:border-left-1, .mobile-lg\:hover\:border-left-1:hover {
    border-left: .5rem solid !important;
  }

  .mobile-lg\:border-105, .mobile-lg\:hover\:border-105:hover {
    border: .75rem solid !important;
  }

  .mobile-lg\:border-y-105, .mobile-lg\:hover\:border-y-105:hover {
    border-top: .75rem solid !important;
    border-bottom: .75rem solid !important;
  }

  .mobile-lg\:border-x-105, .mobile-lg\:hover\:border-x-105:hover {
    border-left: .75rem solid !important;
    border-right: .75rem solid !important;
  }

  .mobile-lg\:border-top-105, .mobile-lg\:hover\:border-top-105:hover {
    border-top: .75rem solid !important;
  }

  .mobile-lg\:border-right-105, .mobile-lg\:hover\:border-right-105:hover {
    border-right: .75rem solid !important;
  }

  .mobile-lg\:border-bottom-105, .mobile-lg\:hover\:border-bottom-105:hover {
    border-bottom: .75rem solid !important;
  }

  .mobile-lg\:border-left-105, .mobile-lg\:hover\:border-left-105:hover {
    border-left: .75rem solid !important;
  }

  .mobile-lg\:border-2, .mobile-lg\:hover\:border-2:hover {
    border: 1rem solid !important;
  }

  .mobile-lg\:border-y-2, .mobile-lg\:hover\:border-y-2:hover {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }

  .mobile-lg\:border-x-2, .mobile-lg\:hover\:border-x-2:hover {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }

  .mobile-lg\:border-top-2, .mobile-lg\:hover\:border-top-2:hover {
    border-top: 1rem solid !important;
  }

  .mobile-lg\:border-right-2, .mobile-lg\:hover\:border-right-2:hover {
    border-right: 1rem solid !important;
  }

  .mobile-lg\:border-bottom-2, .mobile-lg\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid !important;
  }

  .mobile-lg\:border-left-2, .mobile-lg\:hover\:border-left-2:hover {
    border-left: 1rem solid !important;
  }

  .mobile-lg\:border-205, .mobile-lg\:hover\:border-205:hover {
    border: 1.25rem solid !important;
  }

  .mobile-lg\:border-y-205, .mobile-lg\:hover\:border-y-205:hover {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }

  .mobile-lg\:border-x-205, .mobile-lg\:hover\:border-x-205:hover {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }

  .mobile-lg\:border-top-205, .mobile-lg\:hover\:border-top-205:hover {
    border-top: 1.25rem solid !important;
  }

  .mobile-lg\:border-right-205, .mobile-lg\:hover\:border-right-205:hover {
    border-right: 1.25rem solid !important;
  }

  .mobile-lg\:border-bottom-205, .mobile-lg\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid !important;
  }

  .mobile-lg\:border-left-205, .mobile-lg\:hover\:border-left-205:hover {
    border-left: 1.25rem solid !important;
  }

  .mobile-lg\:border-3, .mobile-lg\:hover\:border-3:hover {
    border: 1.5rem solid !important;
  }

  .mobile-lg\:border-y-3, .mobile-lg\:hover\:border-y-3:hover {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }

  .mobile-lg\:border-x-3, .mobile-lg\:hover\:border-x-3:hover {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }

  .mobile-lg\:border-top-3, .mobile-lg\:hover\:border-top-3:hover {
    border-top: 1.5rem solid !important;
  }

  .mobile-lg\:border-right-3, .mobile-lg\:hover\:border-right-3:hover {
    border-right: 1.5rem solid !important;
  }

  .mobile-lg\:border-bottom-3, .mobile-lg\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid !important;
  }

  .mobile-lg\:border-left-3, .mobile-lg\:hover\:border-left-3:hover {
    border-left: 1.5rem solid !important;
  }

  .mobile-lg\:border-0, .mobile-lg\:hover\:border-0:hover {
    border: 0 solid !important;
  }

  .mobile-lg\:border-y-0, .mobile-lg\:hover\:border-y-0:hover {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }

  .mobile-lg\:border-x-0, .mobile-lg\:hover\:border-x-0:hover {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }

  .mobile-lg\:border-top-0, .mobile-lg\:hover\:border-top-0:hover {
    border-top: 0 solid !important;
  }

  .mobile-lg\:border-right-0, .mobile-lg\:hover\:border-right-0:hover {
    border-right: 0 solid !important;
  }

  .mobile-lg\:border-bottom-0, .mobile-lg\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid !important;
  }

  .mobile-lg\:border-left-0, .mobile-lg\:hover\:border-left-0:hover {
    border-left: 0 solid !important;
  }

  .mobile-lg\:border, .mobile-lg\:hover\:border:hover {
    border: 1px solid !important;
  }

  .mobile-lg\:border-y, .mobile-lg\:hover\:border-y:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .mobile-lg\:border-x, .mobile-lg\:hover\:border-x:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .mobile-lg\:border-top, .mobile-lg\:hover\:border-top:hover {
    border-top: 1px solid !important;
  }

  .mobile-lg\:border-right, .mobile-lg\:hover\:border-right:hover {
    border-right: 1px solid !important;
  }

  .mobile-lg\:border-bottom, .mobile-lg\:hover\:border-bottom:hover {
    border-bottom: 1px solid !important;
  }

  .mobile-lg\:border-left, .mobile-lg\:hover\:border-left:hover {
    border-left: 1px solid !important;
  }

  .mobile-lg\:border-transparent, .mobile-lg\:hover\:border-transparent:hover {
    border-color: #0000 !important;
  }

  .mobile-lg\:border-black, .mobile-lg\:hover\:border-black:hover {
    border-color: #000 !important;
  }

  .mobile-lg\:border-white, .mobile-lg\:hover\:border-white:hover {
    border-color: #fff !important;
  }

  .mobile-lg\:border-red, .mobile-lg\:hover\:border-red:hover {
    border-color: #e52207 !important;
  }

  .mobile-lg\:border-orange, .mobile-lg\:hover\:border-orange:hover {
    border-color: #e66f0e !important;
  }

  .mobile-lg\:border-gold, .mobile-lg\:hover\:border-gold:hover {
    border-color: #ffbe2e !important;
  }

  .mobile-lg\:border-yellow, .mobile-lg\:hover\:border-yellow:hover {
    border-color: #fee685 !important;
  }

  .mobile-lg\:border-green, .mobile-lg\:hover\:border-green:hover {
    border-color: #538200 !important;
  }

  .mobile-lg\:border-mint, .mobile-lg\:hover\:border-mint:hover {
    border-color: #04c585 !important;
  }

  .mobile-lg\:border-cyan, .mobile-lg\:hover\:border-cyan:hover {
    border-color: #009ec1 !important;
  }

  .mobile-lg\:border-blue, .mobile-lg\:hover\:border-blue:hover {
    border-color: #0076d6 !important;
  }

  .mobile-lg\:border-indigo, .mobile-lg\:hover\:border-indigo:hover {
    border-color: #676cc8 !important;
  }

  .mobile-lg\:border-violet, .mobile-lg\:hover\:border-violet:hover {
    border-color: #8168b3 !important;
  }

  .mobile-lg\:border-magenta, .mobile-lg\:hover\:border-magenta:hover {
    border-color: #d72d79 !important;
  }

  .mobile-lg\:border-gray-5, .mobile-lg\:hover\:border-gray-5:hover {
    border-color: #f0f0f0 !important;
  }

  .mobile-lg\:border-gray-10, .mobile-lg\:hover\:border-gray-10:hover {
    border-color: #e6e6e6 !important;
  }

  .mobile-lg\:border-gray-30, .mobile-lg\:hover\:border-gray-30:hover {
    border-color: #adadad !important;
  }

  .mobile-lg\:border-gray-50, .mobile-lg\:hover\:border-gray-50:hover {
    border-color: #757575 !important;
  }

  .mobile-lg\:border-gray-70, .mobile-lg\:hover\:border-gray-70:hover {
    border-color: #454545 !important;
  }

  .mobile-lg\:border-gray-90, .mobile-lg\:hover\:border-gray-90:hover {
    border-color: #1b1b1b !important;
  }

  .mobile-lg\:border-base-lightest, .mobile-lg\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }

  .mobile-lg\:border-base-lighter, .mobile-lg\:hover\:border-base-lighter:hover {
    border-color: #dfe1e2 !important;
  }

  .mobile-lg\:border-base-light, .mobile-lg\:hover\:border-base-light:hover {
    border-color: #a9aeb1 !important;
  }

  .mobile-lg\:border-base, .mobile-lg\:hover\:border-base:hover {
    border-color: #71767a !important;
  }

  .mobile-lg\:border-base-dark, .mobile-lg\:hover\:border-base-dark:hover {
    border-color: #565c65 !important;
  }

  .mobile-lg\:border-base-darker, .mobile-lg\:hover\:border-base-darker:hover {
    border-color: #3d4551 !important;
  }

  .mobile-lg\:border-base-darkest, .mobile-lg\:hover\:border-base-darkest:hover {
    border-color: #2d2e2f !important;
  }

  .mobile-lg\:border-ink, .mobile-lg\:hover\:border-ink:hover {
    border-color: #1c1d1f !important;
  }

  .mobile-lg\:border-primary-lighter, .mobile-lg\:hover\:border-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }

  .mobile-lg\:border-primary-light, .mobile-lg\:hover\:border-primary-light:hover {
    border-color: #73b3e7 !important;
  }

  .mobile-lg\:border-primary, .mobile-lg\:hover\:border-primary:hover {
    border-color: #005ea2 !important;
  }

  .mobile-lg\:border-primary-vivid, .mobile-lg\:hover\:border-primary-vivid:hover {
    border-color: #0050d8 !important;
  }

  .mobile-lg\:border-primary-dark, .mobile-lg\:hover\:border-primary-dark:hover {
    border-color: #1a4480 !important;
  }

  .mobile-lg\:border-primary-darker, .mobile-lg\:hover\:border-primary-darker:hover {
    border-color: #162e51 !important;
  }

  .mobile-lg\:border-secondary-lighter, .mobile-lg\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4 !important;
  }

  .mobile-lg\:border-secondary-light, .mobile-lg\:hover\:border-secondary-light:hover {
    border-color: #f2938c !important;
  }

  .mobile-lg\:border-secondary, .mobile-lg\:hover\:border-secondary:hover {
    border-color: #d83933 !important;
  }

  .mobile-lg\:border-secondary-vivid, .mobile-lg\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }

  .mobile-lg\:border-secondary-dark, .mobile-lg\:hover\:border-secondary-dark:hover {
    border-color: #b50909 !important;
  }

  .mobile-lg\:border-secondary-darker, .mobile-lg\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03 !important;
  }

  .mobile-lg\:border-accent-warm-darker, .mobile-lg\:hover\:border-accent-warm-darker:hover {
    border-color: #775540 !important;
  }

  .mobile-lg\:border-accent-warm-dark, .mobile-lg\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }

  .mobile-lg\:border-accent-warm, .mobile-lg\:hover\:border-accent-warm:hover {
    border-color: #fa9441 !important;
  }

  .mobile-lg\:border-accent-warm-light, .mobile-lg\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }

  .mobile-lg\:border-accent-warm-lighter, .mobile-lg\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }

  .mobile-lg\:border-accent-cool-darker, .mobile-lg\:hover\:border-accent-cool-darker:hover {
    border-color: #07648d !important;
  }

  .mobile-lg\:border-accent-cool-dark, .mobile-lg\:hover\:border-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }

  .mobile-lg\:border-accent-cool, .mobile-lg\:hover\:border-accent-cool:hover {
    border-color: #00bde3 !important;
  }

  .mobile-lg\:border-accent-cool-light, .mobile-lg\:hover\:border-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }

  .mobile-lg\:border-accent-cool-lighter, .mobile-lg\:hover\:border-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }

  .mobile-lg\:border-error-lighter, .mobile-lg\:hover\:border-error-lighter:hover {
    border-color: #f4e3db !important;
  }

  .mobile-lg\:border-error-light, .mobile-lg\:hover\:border-error-light:hover {
    border-color: #f39268 !important;
  }

  .mobile-lg\:border-error, .mobile-lg\:hover\:border-error:hover {
    border-color: #d54309 !important;
  }

  .mobile-lg\:border-error-dark, .mobile-lg\:hover\:border-error-dark:hover {
    border-color: #b50909 !important;
  }

  .mobile-lg\:border-error-darker, .mobile-lg\:hover\:border-error-darker:hover {
    border-color: #6f3331 !important;
  }

  .mobile-lg\:border-warning-lighter, .mobile-lg\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1 !important;
  }

  .mobile-lg\:border-warning-light, .mobile-lg\:hover\:border-warning-light:hover {
    border-color: #fee685 !important;
  }

  .mobile-lg\:border-warning, .mobile-lg\:hover\:border-warning:hover {
    border-color: #ffbe2e !important;
  }

  .mobile-lg\:border-warning-dark, .mobile-lg\:hover\:border-warning-dark:hover {
    border-color: #e5a000 !important;
  }

  .mobile-lg\:border-warning-darker, .mobile-lg\:hover\:border-warning-darker:hover {
    border-color: #936f38 !important;
  }

  .mobile-lg\:border-success-lighter, .mobile-lg\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec !important;
  }

  .mobile-lg\:border-success-light, .mobile-lg\:hover\:border-success-light:hover {
    border-color: #70e17b !important;
  }

  .mobile-lg\:border-success, .mobile-lg\:hover\:border-success:hover {
    border-color: #00a91c !important;
  }

  .mobile-lg\:border-success-dark, .mobile-lg\:hover\:border-success-dark:hover {
    border-color: #008817 !important;
  }

  .mobile-lg\:border-success-darker, .mobile-lg\:hover\:border-success-darker:hover {
    border-color: #216e1f !important;
  }

  .mobile-lg\:border-info-lighter, .mobile-lg\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8 !important;
  }

  .mobile-lg\:border-info-light, .mobile-lg\:hover\:border-info-light:hover {
    border-color: #99deea !important;
  }

  .mobile-lg\:border-info, .mobile-lg\:hover\:border-info:hover {
    border-color: #00bde3 !important;
  }

  .mobile-lg\:border-info-dark, .mobile-lg\:hover\:border-info-dark:hover {
    border-color: #009ec1 !important;
  }

  .mobile-lg\:border-info-darker, .mobile-lg\:hover\:border-info-darker:hover {
    border-color: #2e6276 !important;
  }

  .mobile-lg\:border-disabled-lighter, .mobile-lg\:hover\:border-disabled-lighter:hover {
    border-color: #c9c9c9 !important;
  }

  .mobile-lg\:border-disabled-light, .mobile-lg\:hover\:border-disabled-light:hover {
    border-color: #919191 !important;
  }

  .mobile-lg\:border-disabled, .mobile-lg\:hover\:border-disabled:hover {
    border-color: #757575 !important;
  }

  .mobile-lg\:border-disabled-dark, .mobile-lg\:hover\:border-disabled-dark:hover {
    border-color: #454545 !important;
  }

  .mobile-lg\:border-disabled-darker, .mobile-lg\:hover\:border-disabled-darker:hover {
    border-color: #1b1b1b !important;
  }

  .mobile-lg\:border-emergency, .mobile-lg\:hover\:border-emergency:hover {
    border-color: #9c3d10 !important;
  }

  .mobile-lg\:border-emergency-dark, .mobile-lg\:hover\:border-emergency-dark:hover {
    border-color: #332d29 !important;
  }

  .mobile-lg\:radius-0 {
    border-radius: 0 !important;
  }

  .mobile-lg\:radius-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .mobile-lg\:radius-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .mobile-lg\:radius-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .mobile-lg\:radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .mobile-lg\:radius-sm {
    border-radius: 2px !important;
  }

  .mobile-lg\:radius-top-sm {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
  }

  .mobile-lg\:radius-right-sm {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  .mobile-lg\:radius-bottom-sm {
    border-bottom-right-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .mobile-lg\:radius-left-sm {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .mobile-lg\:radius-md {
    border-radius: .25rem !important;
  }

  .mobile-lg\:radius-top-md {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .mobile-lg\:radius-right-md {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .mobile-lg\:radius-bottom-md {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .mobile-lg\:radius-left-md {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .mobile-lg\:radius-lg {
    border-radius: .5rem !important;
  }

  .mobile-lg\:radius-top-lg {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .mobile-lg\:radius-right-lg {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .mobile-lg\:radius-bottom-lg {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .mobile-lg\:radius-left-lg {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .mobile-lg\:radius-pill {
    border-radius: 99rem !important;
  }

  .mobile-lg\:radius-top-pill {
    border-top-left-radius: 99rem !important;
    border-top-right-radius: 99rem !important;
  }

  .mobile-lg\:radius-right-pill {
    border-top-right-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }

  .mobile-lg\:radius-bottom-pill {
    border-bottom-right-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  .mobile-lg\:radius-left-pill {
    border-top-left-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  .mobile-lg\:display-block {
    display: block !important;
  }

  .mobile-lg\:display-flex {
    display: flex !important;
  }

  .mobile-lg\:display-none {
    display: none !important;
  }

  .mobile-lg\:display-inline {
    display: inline !important;
  }

  .mobile-lg\:display-inline-block {
    display: inline-block !important;
  }

  .mobile-lg\:display-inline-flex {
    display: inline-flex !important;
  }

  .mobile-lg\:display-table {
    display: table !important;
  }

  .mobile-lg\:display-table-cell {
    display: table-cell !important;
  }

  .mobile-lg\:display-table-row {
    display: table-row !important;
  }

  .mobile-lg\:font-mono-3xs {
    font-size: .77rem !important;
  }

  .mobile-lg\:font-mono-2xs {
    font-size: .83rem !important;
  }

  .mobile-lg\:font-mono-xs {
    font-size: .89rem !important;
  }

  .mobile-lg\:font-mono-sm {
    font-size: .95rem !important;
  }

  .mobile-lg\:font-mono-md {
    font-size: 1.19rem !important;
  }

  .mobile-lg\:font-mono-lg {
    font-size: 1.31rem !important;
  }

  .mobile-lg\:font-mono-xl {
    font-size: 1.91rem !important;
  }

  .mobile-lg\:font-mono-2xl {
    font-size: 2.38rem !important;
  }

  .mobile-lg\:font-mono-3xl {
    font-size: 2.86rem !important;
  }

  .mobile-lg\:font-sans-3xs {
    font-size: .81rem !important;
  }

  .mobile-lg\:font-sans-2xs {
    font-size: .87rem !important;
  }

  .mobile-lg\:font-sans-xs {
    font-size: .93rem !important;
  }

  .mobile-lg\:font-sans-sm {
    font-size: .99rem !important;
  }

  .mobile-lg\:font-sans-md {
    font-size: 1.24rem !important;
  }

  .mobile-lg\:font-sans-lg {
    font-size: 1.37rem !important;
  }

  .mobile-lg\:font-sans-xl {
    font-size: 1.99rem !important;
  }

  .mobile-lg\:font-sans-2xl {
    font-size: 2.49rem !important;
  }

  .mobile-lg\:font-sans-3xl {
    font-size: 2.98rem !important;
  }

  .mobile-lg\:font-serif-3xs {
    font-size: .81rem !important;
  }

  .mobile-lg\:font-serif-2xs {
    font-size: .87rem !important;
  }

  .mobile-lg\:font-serif-xs {
    font-size: .93rem !important;
  }

  .mobile-lg\:font-serif-sm {
    font-size: .99rem !important;
  }

  .mobile-lg\:font-serif-md {
    font-size: 1.24rem !important;
  }

  .mobile-lg\:font-serif-lg {
    font-size: 1.37rem !important;
  }

  .mobile-lg\:font-serif-xl {
    font-size: 1.99rem !important;
  }

  .mobile-lg\:font-serif-2xl {
    font-size: 2.49rem !important;
  }

  .mobile-lg\:font-serif-3xl {
    font-size: 2.98rem !important;
  }

  .mobile-lg\:font-heading-3xs {
    font-size: .81rem !important;
  }

  .mobile-lg\:font-heading-2xs {
    font-size: .87rem !important;
  }

  .mobile-lg\:font-heading-xs {
    font-size: .93rem !important;
  }

  .mobile-lg\:font-heading-sm {
    font-size: .99rem !important;
  }

  .mobile-lg\:font-heading-md {
    font-size: 1.24rem !important;
  }

  .mobile-lg\:font-heading-lg {
    font-size: 1.37rem !important;
  }

  .mobile-lg\:font-heading-xl {
    font-size: 1.99rem !important;
  }

  .mobile-lg\:font-heading-2xl {
    font-size: 2.49rem !important;
  }

  .mobile-lg\:font-heading-3xl {
    font-size: 2.98rem !important;
  }

  .mobile-lg\:font-body-3xs {
    font-size: .81rem !important;
  }

  .mobile-lg\:font-body-2xs {
    font-size: .87rem !important;
  }

  .mobile-lg\:font-body-xs {
    font-size: .93rem !important;
  }

  .mobile-lg\:font-body-sm {
    font-size: .99rem !important;
  }

  .mobile-lg\:font-body-md {
    font-size: 1.24rem !important;
  }

  .mobile-lg\:font-body-lg {
    font-size: 1.37rem !important;
  }

  .mobile-lg\:font-body-xl {
    font-size: 1.99rem !important;
  }

  .mobile-lg\:font-body-2xl {
    font-size: 2.49rem !important;
  }

  .mobile-lg\:font-body-3xl {
    font-size: 2.98rem !important;
  }

  .mobile-lg\:font-code-3xs {
    font-size: .77rem !important;
  }

  .mobile-lg\:font-code-2xs {
    font-size: .83rem !important;
  }

  .mobile-lg\:font-code-xs {
    font-size: .89rem !important;
  }

  .mobile-lg\:font-code-sm {
    font-size: .95rem !important;
  }

  .mobile-lg\:font-code-md {
    font-size: 1.19rem !important;
  }

  .mobile-lg\:font-code-lg {
    font-size: 1.31rem !important;
  }

  .mobile-lg\:font-code-xl {
    font-size: 1.91rem !important;
  }

  .mobile-lg\:font-code-2xl {
    font-size: 2.38rem !important;
  }

  .mobile-lg\:font-code-3xl {
    font-size: 2.86rem !important;
  }

  .mobile-lg\:font-alt-3xs {
    font-size: .81rem !important;
  }

  .mobile-lg\:font-alt-2xs {
    font-size: .87rem !important;
  }

  .mobile-lg\:font-alt-xs {
    font-size: .93rem !important;
  }

  .mobile-lg\:font-alt-sm {
    font-size: .99rem !important;
  }

  .mobile-lg\:font-alt-md {
    font-size: 1.24rem !important;
  }

  .mobile-lg\:font-alt-lg {
    font-size: 1.37rem !important;
  }

  .mobile-lg\:font-alt-xl {
    font-size: 1.99rem !important;
  }

  .mobile-lg\:font-alt-2xl {
    font-size: 2.49rem !important;
  }

  .mobile-lg\:font-alt-3xl {
    font-size: 2.98rem !important;
  }

  .mobile-lg\:font-ui-3xs {
    font-size: .81rem !important;
  }

  .mobile-lg\:font-ui-2xs {
    font-size: .87rem !important;
  }

  .mobile-lg\:font-ui-xs {
    font-size: .93rem !important;
  }

  .mobile-lg\:font-ui-sm {
    font-size: .99rem !important;
  }

  .mobile-lg\:font-ui-md {
    font-size: 1.24rem !important;
  }

  .mobile-lg\:font-ui-lg {
    font-size: 1.37rem !important;
  }

  .mobile-lg\:font-ui-xl {
    font-size: 1.99rem !important;
  }

  .mobile-lg\:font-ui-2xl {
    font-size: 2.49rem !important;
  }

  .mobile-lg\:font-ui-3xl {
    font-size: 2.98rem !important;
  }

  .mobile-lg\:text-light {
    font-weight: 300 !important;
  }

  .mobile-lg\:text-normal {
    font-weight: normal !important;
  }

  .mobile-lg\:text-semibold {
    font-weight: 600 !important;
  }

  .mobile-lg\:text-bold {
    font-weight: 700 !important;
  }

  .mobile-lg\:flex-justify-center {
    justify-content: center !important;
  }

  .mobile-lg\:flex-justify-start {
    justify-content: flex-start !important;
  }

  .mobile-lg\:flex-justify-end {
    justify-content: flex-end !important;
  }

  .mobile-lg\:flex-justify {
    justify-content: space-between !important;
  }

  .mobile-lg\:line-height-sans-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-sans-2 {
    line-height: 1.2 !important;
  }

  .mobile-lg\:line-height-sans-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-sans-4 {
    line-height: 1.5 !important;
  }

  .mobile-lg\:line-height-sans-5 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-sans-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:line-height-serif-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-serif-2 {
    line-height: 1.2 !important;
  }

  .mobile-lg\:line-height-serif-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-serif-4 {
    line-height: 1.5 !important;
  }

  .mobile-lg\:line-height-serif-5 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-serif-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:line-height-mono-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-mono-2 {
    line-height: 1.3 !important;
  }

  .mobile-lg\:line-height-mono-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-mono-4 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-mono-5 {
    line-height: 1.7 !important;
  }

  .mobile-lg\:line-height-mono-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:line-height-heading-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-heading-2 {
    line-height: 1.2 !important;
  }

  .mobile-lg\:line-height-heading-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-heading-4 {
    line-height: 1.5 !important;
  }

  .mobile-lg\:line-height-heading-5 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-heading-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:line-height-ui-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-ui-2 {
    line-height: 1.2 !important;
  }

  .mobile-lg\:line-height-ui-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-ui-4 {
    line-height: 1.5 !important;
  }

  .mobile-lg\:line-height-ui-5 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-ui-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:line-height-body-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-body-2 {
    line-height: 1.2 !important;
  }

  .mobile-lg\:line-height-body-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-body-4 {
    line-height: 1.5 !important;
  }

  .mobile-lg\:line-height-body-5 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-body-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:line-height-code-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-code-2 {
    line-height: 1.3 !important;
  }

  .mobile-lg\:line-height-code-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-code-4 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-code-5 {
    line-height: 1.7 !important;
  }

  .mobile-lg\:line-height-code-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:line-height-alt-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-alt-2 {
    line-height: 1.2 !important;
  }

  .mobile-lg\:line-height-alt-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-alt-4 {
    line-height: 1.5 !important;
  }

  .mobile-lg\:line-height-alt-5 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-alt-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:margin-neg-1px {
    margin: -1px !important;
  }

  .mobile-lg\:margin-neg-2px {
    margin: -2px !important;
  }

  .mobile-lg\:margin-neg-05 {
    margin: -.25rem !important;
  }

  .mobile-lg\:margin-neg-1 {
    margin: -.5rem !important;
  }

  .mobile-lg\:margin-neg-105 {
    margin: -.75rem !important;
  }

  .mobile-lg\:margin-neg-2 {
    margin: -1rem !important;
  }

  .mobile-lg\:margin-neg-205 {
    margin: -1.25rem !important;
  }

  .mobile-lg\:margin-neg-3 {
    margin: -1.5rem !important;
  }

  .mobile-lg\:margin-1px {
    margin: 1px !important;
  }

  .mobile-lg\:margin-2px {
    margin: 2px !important;
  }

  .mobile-lg\:margin-05 {
    margin: .25rem !important;
  }

  .mobile-lg\:margin-1 {
    margin: .5rem !important;
  }

  .mobile-lg\:margin-105 {
    margin: .75rem !important;
  }

  .mobile-lg\:margin-2 {
    margin: 1rem !important;
  }

  .mobile-lg\:margin-205 {
    margin: 1.25rem !important;
  }

  .mobile-lg\:margin-3 {
    margin: 1.5rem !important;
  }

  .mobile-lg\:margin-neg-4 {
    margin: -2rem !important;
  }

  .mobile-lg\:margin-neg-5 {
    margin: -2.5rem !important;
  }

  .mobile-lg\:margin-neg-6 {
    margin: -3rem !important;
  }

  .mobile-lg\:margin-neg-7 {
    margin: -3.5rem !important;
  }

  .mobile-lg\:margin-neg-8 {
    margin: -4rem !important;
  }

  .mobile-lg\:margin-neg-9 {
    margin: -4.5rem !important;
  }

  .mobile-lg\:margin-neg-10 {
    margin: -5rem !important;
  }

  .mobile-lg\:margin-neg-15 {
    margin: -7.5rem !important;
  }

  .mobile-lg\:margin-4 {
    margin: 2rem !important;
  }

  .mobile-lg\:margin-5 {
    margin: 2.5rem !important;
  }

  .mobile-lg\:margin-6 {
    margin: 3rem !important;
  }

  .mobile-lg\:margin-7 {
    margin: 3.5rem !important;
  }

  .mobile-lg\:margin-8 {
    margin: 4rem !important;
  }

  .mobile-lg\:margin-9 {
    margin: 4.5rem !important;
  }

  .mobile-lg\:margin-10 {
    margin: 5rem !important;
  }

  .mobile-lg\:margin-15 {
    margin: 7.5rem !important;
  }

  .mobile-lg\:margin-05em {
    margin: .5em !important;
  }

  .mobile-lg\:margin-1em {
    margin: 1em !important;
  }

  .mobile-lg\:margin-105em {
    margin: 1.5em !important;
  }

  .mobile-lg\:margin-2em {
    margin: 2em !important;
  }

  .mobile-lg\:margin-0 {
    margin: 0 !important;
  }

  .mobile-lg\:margin-y-1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mobile-lg\:margin-top-1px {
    margin-top: 1px !important;
  }

  .mobile-lg\:margin-bottom-1px {
    margin-bottom: 1px !important;
  }

  .mobile-lg\:margin-y-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mobile-lg\:margin-top-2px {
    margin-top: 2px !important;
  }

  .mobile-lg\:margin-bottom-2px {
    margin-bottom: 2px !important;
  }

  .mobile-lg\:margin-y-05 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .mobile-lg\:margin-top-05 {
    margin-top: .25rem !important;
  }

  .mobile-lg\:margin-bottom-05 {
    margin-bottom: .25rem !important;
  }

  .mobile-lg\:margin-y-1 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .mobile-lg\:margin-top-1 {
    margin-top: .5rem !important;
  }

  .mobile-lg\:margin-bottom-1 {
    margin-bottom: .5rem !important;
  }

  .mobile-lg\:margin-y-105 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .mobile-lg\:margin-top-105 {
    margin-top: .75rem !important;
  }

  .mobile-lg\:margin-bottom-105 {
    margin-bottom: .75rem !important;
  }

  .mobile-lg\:margin-y-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .mobile-lg\:margin-top-2 {
    margin-top: 1rem !important;
  }

  .mobile-lg\:margin-bottom-2 {
    margin-bottom: 1rem !important;
  }

  .mobile-lg\:margin-y-205 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .mobile-lg\:margin-top-205 {
    margin-top: 1.25rem !important;
  }

  .mobile-lg\:margin-bottom-205 {
    margin-bottom: 1.25rem !important;
  }

  .mobile-lg\:margin-y-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .mobile-lg\:margin-top-3 {
    margin-top: 1.5rem !important;
  }

  .mobile-lg\:margin-bottom-3 {
    margin-bottom: 1.5rem !important;
  }

  .mobile-lg\:margin-y-neg-1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .mobile-lg\:margin-top-neg-1px {
    margin-top: -1px !important;
  }

  .mobile-lg\:margin-bottom-neg-1px {
    margin-bottom: -1px !important;
  }

  .mobile-lg\:margin-y-neg-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .mobile-lg\:margin-top-neg-2px {
    margin-top: -2px !important;
  }

  .mobile-lg\:margin-bottom-neg-2px {
    margin-bottom: -2px !important;
  }

  .mobile-lg\:margin-y-neg-05 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .mobile-lg\:margin-top-neg-05 {
    margin-top: -.25rem !important;
  }

  .mobile-lg\:margin-bottom-neg-05 {
    margin-bottom: -.25rem !important;
  }

  .mobile-lg\:margin-y-neg-1 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .mobile-lg\:margin-top-neg-1 {
    margin-top: -.5rem !important;
  }

  .mobile-lg\:margin-bottom-neg-1 {
    margin-bottom: -.5rem !important;
  }

  .mobile-lg\:margin-y-neg-105 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .mobile-lg\:margin-top-neg-105 {
    margin-top: -.75rem !important;
  }

  .mobile-lg\:margin-bottom-neg-105 {
    margin-bottom: -.75rem !important;
  }

  .mobile-lg\:margin-y-neg-2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .mobile-lg\:margin-top-neg-2 {
    margin-top: -1rem !important;
  }

  .mobile-lg\:margin-bottom-neg-2 {
    margin-bottom: -1rem !important;
  }

  .mobile-lg\:margin-y-neg-205 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .mobile-lg\:margin-top-neg-205 {
    margin-top: -1.25rem !important;
  }

  .mobile-lg\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem !important;
  }

  .mobile-lg\:margin-y-neg-3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .mobile-lg\:margin-top-neg-3 {
    margin-top: -1.5rem !important;
  }

  .mobile-lg\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem !important;
  }

  .mobile-lg\:margin-y-neg-4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .mobile-lg\:margin-top-neg-4 {
    margin-top: -2rem !important;
  }

  .mobile-lg\:margin-bottom-neg-4 {
    margin-bottom: -2rem !important;
  }

  .mobile-lg\:margin-y-neg-5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mobile-lg\:margin-top-neg-5 {
    margin-top: -2.5rem !important;
  }

  .mobile-lg\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem !important;
  }

  .mobile-lg\:margin-y-neg-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mobile-lg\:margin-top-neg-6 {
    margin-top: -3rem !important;
  }

  .mobile-lg\:margin-bottom-neg-6 {
    margin-bottom: -3rem !important;
  }

  .mobile-lg\:margin-y-neg-7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .mobile-lg\:margin-top-neg-7 {
    margin-top: -3.5rem !important;
  }

  .mobile-lg\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem !important;
  }

  .mobile-lg\:margin-y-neg-8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .mobile-lg\:margin-top-neg-8 {
    margin-top: -4rem !important;
  }

  .mobile-lg\:margin-bottom-neg-8 {
    margin-bottom: -4rem !important;
  }

  .mobile-lg\:margin-y-neg-9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mobile-lg\:margin-top-neg-9 {
    margin-top: -4.5rem !important;
  }

  .mobile-lg\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem !important;
  }

  .mobile-lg\:margin-y-neg-10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mobile-lg\:margin-top-neg-10 {
    margin-top: -5rem !important;
  }

  .mobile-lg\:margin-bottom-neg-10 {
    margin-bottom: -5rem !important;
  }

  .mobile-lg\:margin-y-neg-15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .mobile-lg\:margin-top-neg-15 {
    margin-top: -7.5rem !important;
  }

  .mobile-lg\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem !important;
  }

  .mobile-lg\:margin-y-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .mobile-lg\:margin-top-4 {
    margin-top: 2rem !important;
  }

  .mobile-lg\:margin-bottom-4 {
    margin-bottom: 2rem !important;
  }

  .mobile-lg\:margin-y-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .mobile-lg\:margin-top-5 {
    margin-top: 2.5rem !important;
  }

  .mobile-lg\:margin-bottom-5 {
    margin-bottom: 2.5rem !important;
  }

  .mobile-lg\:margin-y-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .mobile-lg\:margin-top-6 {
    margin-top: 3rem !important;
  }

  .mobile-lg\:margin-bottom-6 {
    margin-bottom: 3rem !important;
  }

  .mobile-lg\:margin-y-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .mobile-lg\:margin-top-7 {
    margin-top: 3.5rem !important;
  }

  .mobile-lg\:margin-bottom-7 {
    margin-bottom: 3.5rem !important;
  }

  .mobile-lg\:margin-y-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .mobile-lg\:margin-top-8 {
    margin-top: 4rem !important;
  }

  .mobile-lg\:margin-bottom-8 {
    margin-bottom: 4rem !important;
  }

  .mobile-lg\:margin-y-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .mobile-lg\:margin-top-9 {
    margin-top: 4.5rem !important;
  }

  .mobile-lg\:margin-bottom-9 {
    margin-bottom: 4.5rem !important;
  }

  .mobile-lg\:margin-y-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .mobile-lg\:margin-top-10 {
    margin-top: 5rem !important;
  }

  .mobile-lg\:margin-bottom-10 {
    margin-bottom: 5rem !important;
  }

  .mobile-lg\:margin-y-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .mobile-lg\:margin-top-15 {
    margin-top: 7.5rem !important;
  }

  .mobile-lg\:margin-bottom-15 {
    margin-bottom: 7.5rem !important;
  }

  .mobile-lg\:margin-y-05em {
    margin-top: .5em !important;
    margin-bottom: .5em !important;
  }

  .mobile-lg\:margin-top-05em {
    margin-top: .5em !important;
  }

  .mobile-lg\:margin-bottom-05em {
    margin-bottom: .5em !important;
  }

  .mobile-lg\:margin-y-1em {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  .mobile-lg\:margin-top-1em {
    margin-top: 1em !important;
  }

  .mobile-lg\:margin-bottom-1em {
    margin-bottom: 1em !important;
  }

  .mobile-lg\:margin-y-105em {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }

  .mobile-lg\:margin-top-105em {
    margin-top: 1.5em !important;
  }

  .mobile-lg\:margin-bottom-105em {
    margin-bottom: 1.5em !important;
  }

  .mobile-lg\:margin-y-2em {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }

  .mobile-lg\:margin-top-2em {
    margin-top: 2em !important;
  }

  .mobile-lg\:margin-bottom-2em {
    margin-bottom: 2em !important;
  }

  .mobile-lg\:margin-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .mobile-lg\:margin-top-0 {
    margin-top: 0 !important;
  }

  .mobile-lg\:margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  .mobile-lg\:margin-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mobile-lg\:margin-top-auto {
    margin-top: auto !important;
  }

  .mobile-lg\:margin-bottom-auto {
    margin-bottom: auto !important;
  }

  .mobile-lg\:margin-x-1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mobile-lg\:margin-right-1px {
    margin-right: 1px !important;
  }

  .mobile-lg\:margin-left-1px {
    margin-left: 1px !important;
  }

  .mobile-lg\:margin-x-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mobile-lg\:margin-right-2px {
    margin-right: 2px !important;
  }

  .mobile-lg\:margin-left-2px {
    margin-left: 2px !important;
  }

  .mobile-lg\:margin-x-05 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mobile-lg\:margin-right-05 {
    margin-right: .25rem !important;
  }

  .mobile-lg\:margin-left-05 {
    margin-left: .25rem !important;
  }

  .mobile-lg\:margin-x-1 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mobile-lg\:margin-right-1 {
    margin-right: .5rem !important;
  }

  .mobile-lg\:margin-left-1 {
    margin-left: .5rem !important;
  }

  .mobile-lg\:margin-x-105 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mobile-lg\:margin-right-105 {
    margin-right: .75rem !important;
  }

  .mobile-lg\:margin-left-105 {
    margin-left: .75rem !important;
  }

  .mobile-lg\:margin-x-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mobile-lg\:margin-right-2 {
    margin-right: 1rem !important;
  }

  .mobile-lg\:margin-left-2 {
    margin-left: 1rem !important;
  }

  .mobile-lg\:margin-x-205 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mobile-lg\:margin-right-205 {
    margin-right: 1.25rem !important;
  }

  .mobile-lg\:margin-left-205 {
    margin-left: 1.25rem !important;
  }

  .mobile-lg\:margin-x-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mobile-lg\:margin-right-3 {
    margin-right: 1.5rem !important;
  }

  .mobile-lg\:margin-left-3 {
    margin-left: 1.5rem !important;
  }

  .mobile-lg\:margin-x-neg-1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .mobile-lg\:margin-right-neg-1px {
    margin-right: -1px !important;
  }

  .mobile-lg\:margin-left-neg-1px {
    margin-left: -1px !important;
  }

  .mobile-lg\:margin-x-neg-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .mobile-lg\:margin-right-neg-2px {
    margin-right: -2px !important;
  }

  .mobile-lg\:margin-left-neg-2px {
    margin-left: -2px !important;
  }

  .mobile-lg\:margin-x-neg-05 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mobile-lg\:margin-right-neg-05 {
    margin-right: -.25rem !important;
  }

  .mobile-lg\:margin-left-neg-05 {
    margin-left: -.25rem !important;
  }

  .mobile-lg\:margin-x-neg-1 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mobile-lg\:margin-right-neg-1 {
    margin-right: -.5rem !important;
  }

  .mobile-lg\:margin-left-neg-1 {
    margin-left: -.5rem !important;
  }

  .mobile-lg\:margin-x-neg-105 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mobile-lg\:margin-right-neg-105 {
    margin-right: -.75rem !important;
  }

  .mobile-lg\:margin-left-neg-105 {
    margin-left: -.75rem !important;
  }

  .mobile-lg\:margin-x-neg-2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mobile-lg\:margin-right-neg-2 {
    margin-right: -1rem !important;
  }

  .mobile-lg\:margin-left-neg-2 {
    margin-left: -1rem !important;
  }

  .mobile-lg\:margin-x-neg-205 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mobile-lg\:margin-right-neg-205 {
    margin-right: -1.25rem !important;
  }

  .mobile-lg\:margin-left-neg-205 {
    margin-left: -1.25rem !important;
  }

  .mobile-lg\:margin-x-neg-3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mobile-lg\:margin-right-neg-3 {
    margin-right: -1.5rem !important;
  }

  .mobile-lg\:margin-left-neg-3 {
    margin-left: -1.5rem !important;
  }

  .mobile-lg\:margin-x-neg-4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mobile-lg\:margin-right-neg-4 {
    margin-right: -2rem !important;
  }

  .mobile-lg\:margin-left-neg-4 {
    margin-left: -2rem !important;
  }

  .mobile-lg\:margin-x-neg-5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mobile-lg\:margin-right-neg-5 {
    margin-right: -2.5rem !important;
  }

  .mobile-lg\:margin-left-neg-5 {
    margin-left: -2.5rem !important;
  }

  .mobile-lg\:margin-x-neg-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mobile-lg\:margin-right-neg-6 {
    margin-right: -3rem !important;
  }

  .mobile-lg\:margin-left-neg-6 {
    margin-left: -3rem !important;
  }

  .mobile-lg\:margin-x-neg-7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mobile-lg\:margin-right-neg-7 {
    margin-right: -3.5rem !important;
  }

  .mobile-lg\:margin-left-neg-7 {
    margin-left: -3.5rem !important;
  }

  .mobile-lg\:margin-x-neg-8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mobile-lg\:margin-right-neg-8 {
    margin-right: -4rem !important;
  }

  .mobile-lg\:margin-left-neg-8 {
    margin-left: -4rem !important;
  }

  .mobile-lg\:margin-x-neg-9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mobile-lg\:margin-right-neg-9 {
    margin-right: -4.5rem !important;
  }

  .mobile-lg\:margin-left-neg-9 {
    margin-left: -4.5rem !important;
  }

  .mobile-lg\:margin-x-neg-10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mobile-lg\:margin-right-neg-10 {
    margin-right: -5rem !important;
  }

  .mobile-lg\:margin-left-neg-10 {
    margin-left: -5rem !important;
  }

  .mobile-lg\:margin-x-neg-15 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .mobile-lg\:margin-right-neg-15 {
    margin-right: -7.5rem !important;
  }

  .mobile-lg\:margin-left-neg-15 {
    margin-left: -7.5rem !important;
  }

  .mobile-lg\:margin-x-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mobile-lg\:margin-right-4 {
    margin-right: 2rem !important;
  }

  .mobile-lg\:margin-left-4 {
    margin-left: 2rem !important;
  }

  .mobile-lg\:margin-x-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mobile-lg\:margin-right-5 {
    margin-right: 2.5rem !important;
  }

  .mobile-lg\:margin-left-5 {
    margin-left: 2.5rem !important;
  }

  .mobile-lg\:margin-x-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mobile-lg\:margin-right-6 {
    margin-right: 3rem !important;
  }

  .mobile-lg\:margin-left-6 {
    margin-left: 3rem !important;
  }

  .mobile-lg\:margin-x-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mobile-lg\:margin-right-7 {
    margin-right: 3.5rem !important;
  }

  .mobile-lg\:margin-left-7 {
    margin-left: 3.5rem !important;
  }

  .mobile-lg\:margin-x-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mobile-lg\:margin-right-8 {
    margin-right: 4rem !important;
  }

  .mobile-lg\:margin-left-8 {
    margin-left: 4rem !important;
  }

  .mobile-lg\:margin-x-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mobile-lg\:margin-right-9 {
    margin-right: 4.5rem !important;
  }

  .mobile-lg\:margin-left-9 {
    margin-left: 4.5rem !important;
  }

  .mobile-lg\:margin-x-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mobile-lg\:margin-right-10 {
    margin-right: 5rem !important;
  }

  .mobile-lg\:margin-left-10 {
    margin-left: 5rem !important;
  }

  .mobile-lg\:margin-x-15 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .mobile-lg\:margin-right-15 {
    margin-right: 7.5rem !important;
  }

  .mobile-lg\:margin-left-15 {
    margin-left: 7.5rem !important;
  }

  .mobile-lg\:margin-x-card {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .mobile-lg\:margin-right-card {
    margin-right: 10rem !important;
  }

  .mobile-lg\:margin-left-card {
    margin-left: 10rem !important;
  }

  .mobile-lg\:margin-x-card-lg {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .mobile-lg\:margin-right-card-lg {
    margin-right: 15rem !important;
  }

  .mobile-lg\:margin-left-card-lg {
    margin-left: 15rem !important;
  }

  .mobile-lg\:margin-x-mobile {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .mobile-lg\:margin-right-mobile {
    margin-right: 20rem !important;
  }

  .mobile-lg\:margin-left-mobile {
    margin-left: 20rem !important;
  }

  .mobile-lg\:margin-x-05em {
    margin-left: .5em !important;
    margin-right: .5em !important;
  }

  .mobile-lg\:margin-right-05em {
    margin-right: .5em !important;
  }

  .mobile-lg\:margin-left-05em {
    margin-left: .5em !important;
  }

  .mobile-lg\:margin-x-1em {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }

  .mobile-lg\:margin-right-1em {
    margin-right: 1em !important;
  }

  .mobile-lg\:margin-left-1em {
    margin-left: 1em !important;
  }

  .mobile-lg\:margin-x-105em {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }

  .mobile-lg\:margin-right-105em {
    margin-right: 1.5em !important;
  }

  .mobile-lg\:margin-left-105em {
    margin-left: 1.5em !important;
  }

  .mobile-lg\:margin-x-2em {
    margin-left: 2em !important;
    margin-right: 2em !important;
  }

  .mobile-lg\:margin-right-2em {
    margin-right: 2em !important;
  }

  .mobile-lg\:margin-left-2em {
    margin-left: 2em !important;
  }

  .mobile-lg\:margin-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mobile-lg\:margin-right-0 {
    margin-right: 0 !important;
  }

  .mobile-lg\:margin-left-0 {
    margin-left: 0 !important;
  }

  .mobile-lg\:margin-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .mobile-lg\:margin-right-auto {
    margin-right: auto !important;
  }

  .mobile-lg\:margin-left-auto {
    margin-left: auto !important;
  }

  .mobile-lg\:measure-1 {
    max-width: 44ex !important;
  }

  .mobile-lg\:measure-2 {
    max-width: 60ex !important;
  }

  .mobile-lg\:measure-3 {
    max-width: 64ex !important;
  }

  .mobile-lg\:measure-4 {
    max-width: 68ex !important;
  }

  .mobile-lg\:measure-5 {
    max-width: 72ex !important;
  }

  .mobile-lg\:measure-6 {
    max-width: 88ex !important;
  }

  .mobile-lg\:measure-none {
    max-width: none !important;
  }

  .mobile-lg\:order-first {
    order: -1 !important;
  }

  .mobile-lg\:order-last {
    order: 999 !important;
  }

  .mobile-lg\:order-initial {
    order: initial !important;
  }

  .mobile-lg\:order-0 {
    order: 0 !important;
  }

  .mobile-lg\:order-1 {
    order: 1 !important;
  }

  .mobile-lg\:order-2 {
    order: 2 !important;
  }

  .mobile-lg\:order-3 {
    order: 3 !important;
  }

  .mobile-lg\:order-4 {
    order: 4 !important;
  }

  .mobile-lg\:order-5 {
    order: 5 !important;
  }

  .mobile-lg\:order-6 {
    order: 6 !important;
  }

  .mobile-lg\:order-7 {
    order: 7 !important;
  }

  .mobile-lg\:order-8 {
    order: 8 !important;
  }

  .mobile-lg\:order-9 {
    order: 9 !important;
  }

  .mobile-lg\:order-10 {
    order: 10 !important;
  }

  .mobile-lg\:order-11 {
    order: 11 !important;
  }

  .mobile-lg\:padding-1px {
    padding: 1px !important;
  }

  .mobile-lg\:padding-y-1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .mobile-lg\:padding-x-1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .mobile-lg\:padding-top-1px {
    padding-top: 1px !important;
  }

  .mobile-lg\:padding-right-1px {
    padding-right: 1px !important;
  }

  .mobile-lg\:padding-bottom-1px {
    padding-bottom: 1px !important;
  }

  .mobile-lg\:padding-left-1px {
    padding-left: 1px !important;
  }

  .mobile-lg\:padding-2px {
    padding: 2px !important;
  }

  .mobile-lg\:padding-y-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .mobile-lg\:padding-x-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .mobile-lg\:padding-top-2px {
    padding-top: 2px !important;
  }

  .mobile-lg\:padding-right-2px {
    padding-right: 2px !important;
  }

  .mobile-lg\:padding-bottom-2px {
    padding-bottom: 2px !important;
  }

  .mobile-lg\:padding-left-2px {
    padding-left: 2px !important;
  }

  .mobile-lg\:padding-05 {
    padding: .25rem !important;
  }

  .mobile-lg\:padding-y-05 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .mobile-lg\:padding-x-05 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .mobile-lg\:padding-top-05 {
    padding-top: .25rem !important;
  }

  .mobile-lg\:padding-right-05 {
    padding-right: .25rem !important;
  }

  .mobile-lg\:padding-bottom-05 {
    padding-bottom: .25rem !important;
  }

  .mobile-lg\:padding-left-05 {
    padding-left: .25rem !important;
  }

  .mobile-lg\:padding-1 {
    padding: .5rem !important;
  }

  .mobile-lg\:padding-y-1 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .mobile-lg\:padding-x-1 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .mobile-lg\:padding-top-1 {
    padding-top: .5rem !important;
  }

  .mobile-lg\:padding-right-1 {
    padding-right: .5rem !important;
  }

  .mobile-lg\:padding-bottom-1 {
    padding-bottom: .5rem !important;
  }

  .mobile-lg\:padding-left-1 {
    padding-left: .5rem !important;
  }

  .mobile-lg\:padding-105 {
    padding: .75rem !important;
  }

  .mobile-lg\:padding-y-105 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .mobile-lg\:padding-x-105 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .mobile-lg\:padding-top-105 {
    padding-top: .75rem !important;
  }

  .mobile-lg\:padding-right-105 {
    padding-right: .75rem !important;
  }

  .mobile-lg\:padding-bottom-105 {
    padding-bottom: .75rem !important;
  }

  .mobile-lg\:padding-left-105 {
    padding-left: .75rem !important;
  }

  .mobile-lg\:padding-2 {
    padding: 1rem !important;
  }

  .mobile-lg\:padding-y-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .mobile-lg\:padding-x-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .mobile-lg\:padding-top-2 {
    padding-top: 1rem !important;
  }

  .mobile-lg\:padding-right-2 {
    padding-right: 1rem !important;
  }

  .mobile-lg\:padding-bottom-2 {
    padding-bottom: 1rem !important;
  }

  .mobile-lg\:padding-left-2 {
    padding-left: 1rem !important;
  }

  .mobile-lg\:padding-205 {
    padding: 1.25rem !important;
  }

  .mobile-lg\:padding-y-205 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .mobile-lg\:padding-x-205 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .mobile-lg\:padding-top-205 {
    padding-top: 1.25rem !important;
  }

  .mobile-lg\:padding-right-205 {
    padding-right: 1.25rem !important;
  }

  .mobile-lg\:padding-bottom-205 {
    padding-bottom: 1.25rem !important;
  }

  .mobile-lg\:padding-left-205 {
    padding-left: 1.25rem !important;
  }

  .mobile-lg\:padding-3 {
    padding: 1.5rem !important;
  }

  .mobile-lg\:padding-y-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .mobile-lg\:padding-x-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .mobile-lg\:padding-top-3 {
    padding-top: 1.5rem !important;
  }

  .mobile-lg\:padding-right-3 {
    padding-right: 1.5rem !important;
  }

  .mobile-lg\:padding-bottom-3 {
    padding-bottom: 1.5rem !important;
  }

  .mobile-lg\:padding-left-3 {
    padding-left: 1.5rem !important;
  }

  .mobile-lg\:padding-4 {
    padding: 2rem !important;
  }

  .mobile-lg\:padding-y-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .mobile-lg\:padding-x-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .mobile-lg\:padding-top-4 {
    padding-top: 2rem !important;
  }

  .mobile-lg\:padding-right-4 {
    padding-right: 2rem !important;
  }

  .mobile-lg\:padding-bottom-4 {
    padding-bottom: 2rem !important;
  }

  .mobile-lg\:padding-left-4 {
    padding-left: 2rem !important;
  }

  .mobile-lg\:padding-5 {
    padding: 2.5rem !important;
  }

  .mobile-lg\:padding-y-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .mobile-lg\:padding-x-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .mobile-lg\:padding-top-5 {
    padding-top: 2.5rem !important;
  }

  .mobile-lg\:padding-right-5 {
    padding-right: 2.5rem !important;
  }

  .mobile-lg\:padding-bottom-5 {
    padding-bottom: 2.5rem !important;
  }

  .mobile-lg\:padding-left-5 {
    padding-left: 2.5rem !important;
  }

  .mobile-lg\:padding-6 {
    padding: 3rem !important;
  }

  .mobile-lg\:padding-y-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .mobile-lg\:padding-x-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .mobile-lg\:padding-top-6 {
    padding-top: 3rem !important;
  }

  .mobile-lg\:padding-right-6 {
    padding-right: 3rem !important;
  }

  .mobile-lg\:padding-bottom-6 {
    padding-bottom: 3rem !important;
  }

  .mobile-lg\:padding-left-6 {
    padding-left: 3rem !important;
  }

  .mobile-lg\:padding-7 {
    padding: 3.5rem !important;
  }

  .mobile-lg\:padding-y-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .mobile-lg\:padding-x-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .mobile-lg\:padding-top-7 {
    padding-top: 3.5rem !important;
  }

  .mobile-lg\:padding-right-7 {
    padding-right: 3.5rem !important;
  }

  .mobile-lg\:padding-bottom-7 {
    padding-bottom: 3.5rem !important;
  }

  .mobile-lg\:padding-left-7 {
    padding-left: 3.5rem !important;
  }

  .mobile-lg\:padding-8 {
    padding: 4rem !important;
  }

  .mobile-lg\:padding-y-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .mobile-lg\:padding-x-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .mobile-lg\:padding-top-8 {
    padding-top: 4rem !important;
  }

  .mobile-lg\:padding-right-8 {
    padding-right: 4rem !important;
  }

  .mobile-lg\:padding-bottom-8 {
    padding-bottom: 4rem !important;
  }

  .mobile-lg\:padding-left-8 {
    padding-left: 4rem !important;
  }

  .mobile-lg\:padding-9 {
    padding: 4.5rem !important;
  }

  .mobile-lg\:padding-y-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .mobile-lg\:padding-x-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .mobile-lg\:padding-top-9 {
    padding-top: 4.5rem !important;
  }

  .mobile-lg\:padding-right-9 {
    padding-right: 4.5rem !important;
  }

  .mobile-lg\:padding-bottom-9 {
    padding-bottom: 4.5rem !important;
  }

  .mobile-lg\:padding-left-9 {
    padding-left: 4.5rem !important;
  }

  .mobile-lg\:padding-10 {
    padding: 5rem !important;
  }

  .mobile-lg\:padding-y-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .mobile-lg\:padding-x-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .mobile-lg\:padding-top-10 {
    padding-top: 5rem !important;
  }

  .mobile-lg\:padding-right-10 {
    padding-right: 5rem !important;
  }

  .mobile-lg\:padding-bottom-10 {
    padding-bottom: 5rem !important;
  }

  .mobile-lg\:padding-left-10 {
    padding-left: 5rem !important;
  }

  .mobile-lg\:padding-15 {
    padding: 7.5rem !important;
  }

  .mobile-lg\:padding-y-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .mobile-lg\:padding-x-15 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .mobile-lg\:padding-top-15 {
    padding-top: 7.5rem !important;
  }

  .mobile-lg\:padding-right-15 {
    padding-right: 7.5rem !important;
  }

  .mobile-lg\:padding-bottom-15 {
    padding-bottom: 7.5rem !important;
  }

  .mobile-lg\:padding-left-15 {
    padding-left: 7.5rem !important;
  }

  .mobile-lg\:padding-0 {
    padding: 0 !important;
  }

  .mobile-lg\:padding-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .mobile-lg\:padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mobile-lg\:padding-top-0 {
    padding-top: 0 !important;
  }

  .mobile-lg\:padding-right-0 {
    padding-right: 0 !important;
  }

  .mobile-lg\:padding-bottom-0 {
    padding-bottom: 0 !important;
  }

  .mobile-lg\:padding-left-0 {
    padding-left: 0 !important;
  }

  .mobile-lg\:width-1px {
    width: 1px !important;
  }

  .mobile-lg\:width-2px {
    width: 2px !important;
  }

  .mobile-lg\:width-05 {
    width: .25rem !important;
  }

  .mobile-lg\:width-1 {
    width: .5rem !important;
  }

  .mobile-lg\:width-105 {
    width: .75rem !important;
  }

  .mobile-lg\:width-2 {
    width: 1rem !important;
  }

  .mobile-lg\:width-205 {
    width: 1.25rem !important;
  }

  .mobile-lg\:width-3 {
    width: 1.5rem !important;
  }

  .mobile-lg\:width-4 {
    width: 2rem !important;
  }

  .mobile-lg\:width-5 {
    width: 2.5rem !important;
  }

  .mobile-lg\:width-6 {
    width: 3rem !important;
  }

  .mobile-lg\:width-7 {
    width: 3.5rem !important;
  }

  .mobile-lg\:width-8 {
    width: 4rem !important;
  }

  .mobile-lg\:width-9 {
    width: 4.5rem !important;
  }

  .mobile-lg\:width-10 {
    width: 5rem !important;
  }

  .mobile-lg\:width-15 {
    width: 7.5rem !important;
  }

  .mobile-lg\:width-card {
    width: 10rem !important;
  }

  .mobile-lg\:width-card-lg {
    width: 15rem !important;
  }

  .mobile-lg\:width-mobile {
    width: 20rem !important;
  }

  .mobile-lg\:width-mobile-lg {
    width: 30rem !important;
  }

  .mobile-lg\:width-tablet {
    width: 40rem !important;
  }

  .mobile-lg\:width-tablet-lg {
    width: 55rem !important;
  }

  .mobile-lg\:width-desktop {
    width: 64rem !important;
  }

  .mobile-lg\:width-desktop-lg {
    width: 75rem !important;
  }

  .mobile-lg\:width-widescreen {
    width: 87.5rem !important;
  }

  .mobile-lg\:width-0 {
    width: 0 !important;
  }

  .mobile-lg\:width-full {
    width: 100% !important;
  }

  .mobile-lg\:width-auto {
    width: auto !important;
  }
}

@media (width >= 40em) {
  .tablet\:border-1px, .tablet\:hover\:border-1px:hover {
    border: 1px solid !important;
  }

  .tablet\:border-y-1px, .tablet\:hover\:border-y-1px:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .tablet\:border-x-1px, .tablet\:hover\:border-x-1px:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .tablet\:border-top-1px, .tablet\:hover\:border-top-1px:hover {
    border-top: 1px solid !important;
  }

  .tablet\:border-right-1px, .tablet\:hover\:border-right-1px:hover {
    border-right: 1px solid !important;
  }

  .tablet\:border-bottom-1px, .tablet\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid !important;
  }

  .tablet\:border-left-1px, .tablet\:hover\:border-left-1px:hover {
    border-left: 1px solid !important;
  }

  .tablet\:border-2px, .tablet\:hover\:border-2px:hover {
    border: 2px solid !important;
  }

  .tablet\:border-y-2px, .tablet\:hover\:border-y-2px:hover {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }

  .tablet\:border-x-2px, .tablet\:hover\:border-x-2px:hover {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }

  .tablet\:border-top-2px, .tablet\:hover\:border-top-2px:hover {
    border-top: 2px solid !important;
  }

  .tablet\:border-right-2px, .tablet\:hover\:border-right-2px:hover {
    border-right: 2px solid !important;
  }

  .tablet\:border-bottom-2px, .tablet\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid !important;
  }

  .tablet\:border-left-2px, .tablet\:hover\:border-left-2px:hover {
    border-left: 2px solid !important;
  }

  .tablet\:border-05, .tablet\:hover\:border-05:hover {
    border: .25rem solid !important;
  }

  .tablet\:border-y-05, .tablet\:hover\:border-y-05:hover {
    border-top: .25rem solid !important;
    border-bottom: .25rem solid !important;
  }

  .tablet\:border-x-05, .tablet\:hover\:border-x-05:hover {
    border-left: .25rem solid !important;
    border-right: .25rem solid !important;
  }

  .tablet\:border-top-05, .tablet\:hover\:border-top-05:hover {
    border-top: .25rem solid !important;
  }

  .tablet\:border-right-05, .tablet\:hover\:border-right-05:hover {
    border-right: .25rem solid !important;
  }

  .tablet\:border-bottom-05, .tablet\:hover\:border-bottom-05:hover {
    border-bottom: .25rem solid !important;
  }

  .tablet\:border-left-05, .tablet\:hover\:border-left-05:hover {
    border-left: .25rem solid !important;
  }

  .tablet\:border-1, .tablet\:hover\:border-1:hover {
    border: .5rem solid !important;
  }

  .tablet\:border-y-1, .tablet\:hover\:border-y-1:hover {
    border-top: .5rem solid !important;
    border-bottom: .5rem solid !important;
  }

  .tablet\:border-x-1, .tablet\:hover\:border-x-1:hover {
    border-left: .5rem solid !important;
    border-right: .5rem solid !important;
  }

  .tablet\:border-top-1, .tablet\:hover\:border-top-1:hover {
    border-top: .5rem solid !important;
  }

  .tablet\:border-right-1, .tablet\:hover\:border-right-1:hover {
    border-right: .5rem solid !important;
  }

  .tablet\:border-bottom-1, .tablet\:hover\:border-bottom-1:hover {
    border-bottom: .5rem solid !important;
  }

  .tablet\:border-left-1, .tablet\:hover\:border-left-1:hover {
    border-left: .5rem solid !important;
  }

  .tablet\:border-105, .tablet\:hover\:border-105:hover {
    border: .75rem solid !important;
  }

  .tablet\:border-y-105, .tablet\:hover\:border-y-105:hover {
    border-top: .75rem solid !important;
    border-bottom: .75rem solid !important;
  }

  .tablet\:border-x-105, .tablet\:hover\:border-x-105:hover {
    border-left: .75rem solid !important;
    border-right: .75rem solid !important;
  }

  .tablet\:border-top-105, .tablet\:hover\:border-top-105:hover {
    border-top: .75rem solid !important;
  }

  .tablet\:border-right-105, .tablet\:hover\:border-right-105:hover {
    border-right: .75rem solid !important;
  }

  .tablet\:border-bottom-105, .tablet\:hover\:border-bottom-105:hover {
    border-bottom: .75rem solid !important;
  }

  .tablet\:border-left-105, .tablet\:hover\:border-left-105:hover {
    border-left: .75rem solid !important;
  }

  .tablet\:border-2, .tablet\:hover\:border-2:hover {
    border: 1rem solid !important;
  }

  .tablet\:border-y-2, .tablet\:hover\:border-y-2:hover {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }

  .tablet\:border-x-2, .tablet\:hover\:border-x-2:hover {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }

  .tablet\:border-top-2, .tablet\:hover\:border-top-2:hover {
    border-top: 1rem solid !important;
  }

  .tablet\:border-right-2, .tablet\:hover\:border-right-2:hover {
    border-right: 1rem solid !important;
  }

  .tablet\:border-bottom-2, .tablet\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid !important;
  }

  .tablet\:border-left-2, .tablet\:hover\:border-left-2:hover {
    border-left: 1rem solid !important;
  }

  .tablet\:border-205, .tablet\:hover\:border-205:hover {
    border: 1.25rem solid !important;
  }

  .tablet\:border-y-205, .tablet\:hover\:border-y-205:hover {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }

  .tablet\:border-x-205, .tablet\:hover\:border-x-205:hover {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }

  .tablet\:border-top-205, .tablet\:hover\:border-top-205:hover {
    border-top: 1.25rem solid !important;
  }

  .tablet\:border-right-205, .tablet\:hover\:border-right-205:hover {
    border-right: 1.25rem solid !important;
  }

  .tablet\:border-bottom-205, .tablet\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid !important;
  }

  .tablet\:border-left-205, .tablet\:hover\:border-left-205:hover {
    border-left: 1.25rem solid !important;
  }

  .tablet\:border-3, .tablet\:hover\:border-3:hover {
    border: 1.5rem solid !important;
  }

  .tablet\:border-y-3, .tablet\:hover\:border-y-3:hover {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }

  .tablet\:border-x-3, .tablet\:hover\:border-x-3:hover {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }

  .tablet\:border-top-3, .tablet\:hover\:border-top-3:hover {
    border-top: 1.5rem solid !important;
  }

  .tablet\:border-right-3, .tablet\:hover\:border-right-3:hover {
    border-right: 1.5rem solid !important;
  }

  .tablet\:border-bottom-3, .tablet\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid !important;
  }

  .tablet\:border-left-3, .tablet\:hover\:border-left-3:hover {
    border-left: 1.5rem solid !important;
  }

  .tablet\:border-0, .tablet\:hover\:border-0:hover {
    border: 0 solid !important;
  }

  .tablet\:border-y-0, .tablet\:hover\:border-y-0:hover {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }

  .tablet\:border-x-0, .tablet\:hover\:border-x-0:hover {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }

  .tablet\:border-top-0, .tablet\:hover\:border-top-0:hover {
    border-top: 0 solid !important;
  }

  .tablet\:border-right-0, .tablet\:hover\:border-right-0:hover {
    border-right: 0 solid !important;
  }

  .tablet\:border-bottom-0, .tablet\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid !important;
  }

  .tablet\:border-left-0, .tablet\:hover\:border-left-0:hover {
    border-left: 0 solid !important;
  }

  .tablet\:border, .tablet\:hover\:border:hover {
    border: 1px solid !important;
  }

  .tablet\:border-y, .tablet\:hover\:border-y:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .tablet\:border-x, .tablet\:hover\:border-x:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .tablet\:border-top, .tablet\:hover\:border-top:hover {
    border-top: 1px solid !important;
  }

  .tablet\:border-right, .tablet\:hover\:border-right:hover {
    border-right: 1px solid !important;
  }

  .tablet\:border-bottom, .tablet\:hover\:border-bottom:hover {
    border-bottom: 1px solid !important;
  }

  .tablet\:border-left, .tablet\:hover\:border-left:hover {
    border-left: 1px solid !important;
  }

  .tablet\:border-transparent, .tablet\:hover\:border-transparent:hover {
    border-color: #0000 !important;
  }

  .tablet\:border-black, .tablet\:hover\:border-black:hover {
    border-color: #000 !important;
  }

  .tablet\:border-white, .tablet\:hover\:border-white:hover {
    border-color: #fff !important;
  }

  .tablet\:border-red, .tablet\:hover\:border-red:hover {
    border-color: #e52207 !important;
  }

  .tablet\:border-orange, .tablet\:hover\:border-orange:hover {
    border-color: #e66f0e !important;
  }

  .tablet\:border-gold, .tablet\:hover\:border-gold:hover {
    border-color: #ffbe2e !important;
  }

  .tablet\:border-yellow, .tablet\:hover\:border-yellow:hover {
    border-color: #fee685 !important;
  }

  .tablet\:border-green, .tablet\:hover\:border-green:hover {
    border-color: #538200 !important;
  }

  .tablet\:border-mint, .tablet\:hover\:border-mint:hover {
    border-color: #04c585 !important;
  }

  .tablet\:border-cyan, .tablet\:hover\:border-cyan:hover {
    border-color: #009ec1 !important;
  }

  .tablet\:border-blue, .tablet\:hover\:border-blue:hover {
    border-color: #0076d6 !important;
  }

  .tablet\:border-indigo, .tablet\:hover\:border-indigo:hover {
    border-color: #676cc8 !important;
  }

  .tablet\:border-violet, .tablet\:hover\:border-violet:hover {
    border-color: #8168b3 !important;
  }

  .tablet\:border-magenta, .tablet\:hover\:border-magenta:hover {
    border-color: #d72d79 !important;
  }

  .tablet\:border-gray-5, .tablet\:hover\:border-gray-5:hover {
    border-color: #f0f0f0 !important;
  }

  .tablet\:border-gray-10, .tablet\:hover\:border-gray-10:hover {
    border-color: #e6e6e6 !important;
  }

  .tablet\:border-gray-30, .tablet\:hover\:border-gray-30:hover {
    border-color: #adadad !important;
  }

  .tablet\:border-gray-50, .tablet\:hover\:border-gray-50:hover {
    border-color: #757575 !important;
  }

  .tablet\:border-gray-70, .tablet\:hover\:border-gray-70:hover {
    border-color: #454545 !important;
  }

  .tablet\:border-gray-90, .tablet\:hover\:border-gray-90:hover {
    border-color: #1b1b1b !important;
  }

  .tablet\:border-base-lightest, .tablet\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }

  .tablet\:border-base-lighter, .tablet\:hover\:border-base-lighter:hover {
    border-color: #dfe1e2 !important;
  }

  .tablet\:border-base-light, .tablet\:hover\:border-base-light:hover {
    border-color: #a9aeb1 !important;
  }

  .tablet\:border-base, .tablet\:hover\:border-base:hover {
    border-color: #71767a !important;
  }

  .tablet\:border-base-dark, .tablet\:hover\:border-base-dark:hover {
    border-color: #565c65 !important;
  }

  .tablet\:border-base-darker, .tablet\:hover\:border-base-darker:hover {
    border-color: #3d4551 !important;
  }

  .tablet\:border-base-darkest, .tablet\:hover\:border-base-darkest:hover {
    border-color: #2d2e2f !important;
  }

  .tablet\:border-ink, .tablet\:hover\:border-ink:hover {
    border-color: #1c1d1f !important;
  }

  .tablet\:border-primary-lighter, .tablet\:hover\:border-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }

  .tablet\:border-primary-light, .tablet\:hover\:border-primary-light:hover {
    border-color: #73b3e7 !important;
  }

  .tablet\:border-primary, .tablet\:hover\:border-primary:hover {
    border-color: #005ea2 !important;
  }

  .tablet\:border-primary-vivid, .tablet\:hover\:border-primary-vivid:hover {
    border-color: #0050d8 !important;
  }

  .tablet\:border-primary-dark, .tablet\:hover\:border-primary-dark:hover {
    border-color: #1a4480 !important;
  }

  .tablet\:border-primary-darker, .tablet\:hover\:border-primary-darker:hover {
    border-color: #162e51 !important;
  }

  .tablet\:border-secondary-lighter, .tablet\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4 !important;
  }

  .tablet\:border-secondary-light, .tablet\:hover\:border-secondary-light:hover {
    border-color: #f2938c !important;
  }

  .tablet\:border-secondary, .tablet\:hover\:border-secondary:hover {
    border-color: #d83933 !important;
  }

  .tablet\:border-secondary-vivid, .tablet\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }

  .tablet\:border-secondary-dark, .tablet\:hover\:border-secondary-dark:hover {
    border-color: #b50909 !important;
  }

  .tablet\:border-secondary-darker, .tablet\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03 !important;
  }

  .tablet\:border-accent-warm-darker, .tablet\:hover\:border-accent-warm-darker:hover {
    border-color: #775540 !important;
  }

  .tablet\:border-accent-warm-dark, .tablet\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }

  .tablet\:border-accent-warm, .tablet\:hover\:border-accent-warm:hover {
    border-color: #fa9441 !important;
  }

  .tablet\:border-accent-warm-light, .tablet\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }

  .tablet\:border-accent-warm-lighter, .tablet\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }

  .tablet\:border-accent-cool-darker, .tablet\:hover\:border-accent-cool-darker:hover {
    border-color: #07648d !important;
  }

  .tablet\:border-accent-cool-dark, .tablet\:hover\:border-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }

  .tablet\:border-accent-cool, .tablet\:hover\:border-accent-cool:hover {
    border-color: #00bde3 !important;
  }

  .tablet\:border-accent-cool-light, .tablet\:hover\:border-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }

  .tablet\:border-accent-cool-lighter, .tablet\:hover\:border-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }

  .tablet\:border-error-lighter, .tablet\:hover\:border-error-lighter:hover {
    border-color: #f4e3db !important;
  }

  .tablet\:border-error-light, .tablet\:hover\:border-error-light:hover {
    border-color: #f39268 !important;
  }

  .tablet\:border-error, .tablet\:hover\:border-error:hover {
    border-color: #d54309 !important;
  }

  .tablet\:border-error-dark, .tablet\:hover\:border-error-dark:hover {
    border-color: #b50909 !important;
  }

  .tablet\:border-error-darker, .tablet\:hover\:border-error-darker:hover {
    border-color: #6f3331 !important;
  }

  .tablet\:border-warning-lighter, .tablet\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1 !important;
  }

  .tablet\:border-warning-light, .tablet\:hover\:border-warning-light:hover {
    border-color: #fee685 !important;
  }

  .tablet\:border-warning, .tablet\:hover\:border-warning:hover {
    border-color: #ffbe2e !important;
  }

  .tablet\:border-warning-dark, .tablet\:hover\:border-warning-dark:hover {
    border-color: #e5a000 !important;
  }

  .tablet\:border-warning-darker, .tablet\:hover\:border-warning-darker:hover {
    border-color: #936f38 !important;
  }

  .tablet\:border-success-lighter, .tablet\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec !important;
  }

  .tablet\:border-success-light, .tablet\:hover\:border-success-light:hover {
    border-color: #70e17b !important;
  }

  .tablet\:border-success, .tablet\:hover\:border-success:hover {
    border-color: #00a91c !important;
  }

  .tablet\:border-success-dark, .tablet\:hover\:border-success-dark:hover {
    border-color: #008817 !important;
  }

  .tablet\:border-success-darker, .tablet\:hover\:border-success-darker:hover {
    border-color: #216e1f !important;
  }

  .tablet\:border-info-lighter, .tablet\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8 !important;
  }

  .tablet\:border-info-light, .tablet\:hover\:border-info-light:hover {
    border-color: #99deea !important;
  }

  .tablet\:border-info, .tablet\:hover\:border-info:hover {
    border-color: #00bde3 !important;
  }

  .tablet\:border-info-dark, .tablet\:hover\:border-info-dark:hover {
    border-color: #009ec1 !important;
  }

  .tablet\:border-info-darker, .tablet\:hover\:border-info-darker:hover {
    border-color: #2e6276 !important;
  }

  .tablet\:border-disabled-lighter, .tablet\:hover\:border-disabled-lighter:hover {
    border-color: #c9c9c9 !important;
  }

  .tablet\:border-disabled-light, .tablet\:hover\:border-disabled-light:hover {
    border-color: #919191 !important;
  }

  .tablet\:border-disabled, .tablet\:hover\:border-disabled:hover {
    border-color: #757575 !important;
  }

  .tablet\:border-disabled-dark, .tablet\:hover\:border-disabled-dark:hover {
    border-color: #454545 !important;
  }

  .tablet\:border-disabled-darker, .tablet\:hover\:border-disabled-darker:hover {
    border-color: #1b1b1b !important;
  }

  .tablet\:border-emergency, .tablet\:hover\:border-emergency:hover {
    border-color: #9c3d10 !important;
  }

  .tablet\:border-emergency-dark, .tablet\:hover\:border-emergency-dark:hover {
    border-color: #332d29 !important;
  }

  .tablet\:radius-0 {
    border-radius: 0 !important;
  }

  .tablet\:radius-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .tablet\:radius-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .tablet\:radius-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .tablet\:radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .tablet\:radius-sm {
    border-radius: 2px !important;
  }

  .tablet\:radius-top-sm {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
  }

  .tablet\:radius-right-sm {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  .tablet\:radius-bottom-sm {
    border-bottom-right-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .tablet\:radius-left-sm {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .tablet\:radius-md {
    border-radius: .25rem !important;
  }

  .tablet\:radius-top-md {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .tablet\:radius-right-md {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .tablet\:radius-bottom-md {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .tablet\:radius-left-md {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .tablet\:radius-lg {
    border-radius: .5rem !important;
  }

  .tablet\:radius-top-lg {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .tablet\:radius-right-lg {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .tablet\:radius-bottom-lg {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .tablet\:radius-left-lg {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .tablet\:radius-pill {
    border-radius: 99rem !important;
  }

  .tablet\:radius-top-pill {
    border-top-left-radius: 99rem !important;
    border-top-right-radius: 99rem !important;
  }

  .tablet\:radius-right-pill {
    border-top-right-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }

  .tablet\:radius-bottom-pill {
    border-bottom-right-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  .tablet\:radius-left-pill {
    border-top-left-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  .tablet\:display-block {
    display: block !important;
  }

  .tablet\:display-flex {
    display: flex !important;
  }

  .tablet\:display-none {
    display: none !important;
  }

  .tablet\:display-inline {
    display: inline !important;
  }

  .tablet\:display-inline-block {
    display: inline-block !important;
  }

  .tablet\:display-inline-flex {
    display: inline-flex !important;
  }

  .tablet\:display-table {
    display: table !important;
  }

  .tablet\:display-table-cell {
    display: table-cell !important;
  }

  .tablet\:display-table-row {
    display: table-row !important;
  }

  .tablet\:font-mono-3xs {
    font-size: .77rem !important;
  }

  .tablet\:font-mono-2xs {
    font-size: .83rem !important;
  }

  .tablet\:font-mono-xs {
    font-size: .89rem !important;
  }

  .tablet\:font-mono-sm {
    font-size: .95rem !important;
  }

  .tablet\:font-mono-md {
    font-size: 1.19rem !important;
  }

  .tablet\:font-mono-lg {
    font-size: 1.31rem !important;
  }

  .tablet\:font-mono-xl {
    font-size: 1.91rem !important;
  }

  .tablet\:font-mono-2xl {
    font-size: 2.38rem !important;
  }

  .tablet\:font-mono-3xl {
    font-size: 2.86rem !important;
  }

  .tablet\:font-sans-3xs {
    font-size: .81rem !important;
  }

  .tablet\:font-sans-2xs {
    font-size: .87rem !important;
  }

  .tablet\:font-sans-xs {
    font-size: .93rem !important;
  }

  .tablet\:font-sans-sm {
    font-size: .99rem !important;
  }

  .tablet\:font-sans-md {
    font-size: 1.24rem !important;
  }

  .tablet\:font-sans-lg {
    font-size: 1.37rem !important;
  }

  .tablet\:font-sans-xl {
    font-size: 1.99rem !important;
  }

  .tablet\:font-sans-2xl {
    font-size: 2.49rem !important;
  }

  .tablet\:font-sans-3xl {
    font-size: 2.98rem !important;
  }

  .tablet\:font-serif-3xs {
    font-size: .81rem !important;
  }

  .tablet\:font-serif-2xs {
    font-size: .87rem !important;
  }

  .tablet\:font-serif-xs {
    font-size: .93rem !important;
  }

  .tablet\:font-serif-sm {
    font-size: .99rem !important;
  }

  .tablet\:font-serif-md {
    font-size: 1.24rem !important;
  }

  .tablet\:font-serif-lg {
    font-size: 1.37rem !important;
  }

  .tablet\:font-serif-xl {
    font-size: 1.99rem !important;
  }

  .tablet\:font-serif-2xl {
    font-size: 2.49rem !important;
  }

  .tablet\:font-serif-3xl {
    font-size: 2.98rem !important;
  }

  .tablet\:font-heading-3xs {
    font-size: .81rem !important;
  }

  .tablet\:font-heading-2xs {
    font-size: .87rem !important;
  }

  .tablet\:font-heading-xs {
    font-size: .93rem !important;
  }

  .tablet\:font-heading-sm {
    font-size: .99rem !important;
  }

  .tablet\:font-heading-md {
    font-size: 1.24rem !important;
  }

  .tablet\:font-heading-lg {
    font-size: 1.37rem !important;
  }

  .tablet\:font-heading-xl {
    font-size: 1.99rem !important;
  }

  .tablet\:font-heading-2xl {
    font-size: 2.49rem !important;
  }

  .tablet\:font-heading-3xl {
    font-size: 2.98rem !important;
  }

  .tablet\:font-body-3xs {
    font-size: .81rem !important;
  }

  .tablet\:font-body-2xs {
    font-size: .87rem !important;
  }

  .tablet\:font-body-xs {
    font-size: .93rem !important;
  }

  .tablet\:font-body-sm {
    font-size: .99rem !important;
  }

  .tablet\:font-body-md {
    font-size: 1.24rem !important;
  }

  .tablet\:font-body-lg {
    font-size: 1.37rem !important;
  }

  .tablet\:font-body-xl {
    font-size: 1.99rem !important;
  }

  .tablet\:font-body-2xl {
    font-size: 2.49rem !important;
  }

  .tablet\:font-body-3xl {
    font-size: 2.98rem !important;
  }

  .tablet\:font-code-3xs {
    font-size: .77rem !important;
  }

  .tablet\:font-code-2xs {
    font-size: .83rem !important;
  }

  .tablet\:font-code-xs {
    font-size: .89rem !important;
  }

  .tablet\:font-code-sm {
    font-size: .95rem !important;
  }

  .tablet\:font-code-md {
    font-size: 1.19rem !important;
  }

  .tablet\:font-code-lg {
    font-size: 1.31rem !important;
  }

  .tablet\:font-code-xl {
    font-size: 1.91rem !important;
  }

  .tablet\:font-code-2xl {
    font-size: 2.38rem !important;
  }

  .tablet\:font-code-3xl {
    font-size: 2.86rem !important;
  }

  .tablet\:font-alt-3xs {
    font-size: .81rem !important;
  }

  .tablet\:font-alt-2xs {
    font-size: .87rem !important;
  }

  .tablet\:font-alt-xs {
    font-size: .93rem !important;
  }

  .tablet\:font-alt-sm {
    font-size: .99rem !important;
  }

  .tablet\:font-alt-md {
    font-size: 1.24rem !important;
  }

  .tablet\:font-alt-lg {
    font-size: 1.37rem !important;
  }

  .tablet\:font-alt-xl {
    font-size: 1.99rem !important;
  }

  .tablet\:font-alt-2xl {
    font-size: 2.49rem !important;
  }

  .tablet\:font-alt-3xl {
    font-size: 2.98rem !important;
  }

  .tablet\:font-ui-3xs {
    font-size: .81rem !important;
  }

  .tablet\:font-ui-2xs {
    font-size: .87rem !important;
  }

  .tablet\:font-ui-xs {
    font-size: .93rem !important;
  }

  .tablet\:font-ui-sm {
    font-size: .99rem !important;
  }

  .tablet\:font-ui-md {
    font-size: 1.24rem !important;
  }

  .tablet\:font-ui-lg {
    font-size: 1.37rem !important;
  }

  .tablet\:font-ui-xl {
    font-size: 1.99rem !important;
  }

  .tablet\:font-ui-2xl {
    font-size: 2.49rem !important;
  }

  .tablet\:font-ui-3xl {
    font-size: 2.98rem !important;
  }

  .tablet\:text-light {
    font-weight: 300 !important;
  }

  .tablet\:text-normal {
    font-weight: normal !important;
  }

  .tablet\:text-semibold {
    font-weight: 600 !important;
  }

  .tablet\:text-bold {
    font-weight: 700 !important;
  }

  .tablet\:flex-justify-center {
    justify-content: center !important;
  }

  .tablet\:flex-justify-start {
    justify-content: flex-start !important;
  }

  .tablet\:flex-justify-end {
    justify-content: flex-end !important;
  }

  .tablet\:flex-justify {
    justify-content: space-between !important;
  }

  .tablet\:line-height-sans-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-sans-2 {
    line-height: 1.2 !important;
  }

  .tablet\:line-height-sans-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-sans-4 {
    line-height: 1.5 !important;
  }

  .tablet\:line-height-sans-5 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-sans-6 {
    line-height: 1.8 !important;
  }

  .tablet\:line-height-serif-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-serif-2 {
    line-height: 1.2 !important;
  }

  .tablet\:line-height-serif-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-serif-4 {
    line-height: 1.5 !important;
  }

  .tablet\:line-height-serif-5 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-serif-6 {
    line-height: 1.8 !important;
  }

  .tablet\:line-height-mono-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-mono-2 {
    line-height: 1.3 !important;
  }

  .tablet\:line-height-mono-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-mono-4 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-mono-5 {
    line-height: 1.7 !important;
  }

  .tablet\:line-height-mono-6 {
    line-height: 1.8 !important;
  }

  .tablet\:line-height-heading-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-heading-2 {
    line-height: 1.2 !important;
  }

  .tablet\:line-height-heading-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-heading-4 {
    line-height: 1.5 !important;
  }

  .tablet\:line-height-heading-5 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-heading-6 {
    line-height: 1.8 !important;
  }

  .tablet\:line-height-ui-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-ui-2 {
    line-height: 1.2 !important;
  }

  .tablet\:line-height-ui-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-ui-4 {
    line-height: 1.5 !important;
  }

  .tablet\:line-height-ui-5 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-ui-6 {
    line-height: 1.8 !important;
  }

  .tablet\:line-height-body-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-body-2 {
    line-height: 1.2 !important;
  }

  .tablet\:line-height-body-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-body-4 {
    line-height: 1.5 !important;
  }

  .tablet\:line-height-body-5 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-body-6 {
    line-height: 1.8 !important;
  }

  .tablet\:line-height-code-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-code-2 {
    line-height: 1.3 !important;
  }

  .tablet\:line-height-code-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-code-4 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-code-5 {
    line-height: 1.7 !important;
  }

  .tablet\:line-height-code-6 {
    line-height: 1.8 !important;
  }

  .tablet\:line-height-alt-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-alt-2 {
    line-height: 1.2 !important;
  }

  .tablet\:line-height-alt-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-alt-4 {
    line-height: 1.5 !important;
  }

  .tablet\:line-height-alt-5 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-alt-6 {
    line-height: 1.8 !important;
  }

  .tablet\:margin-neg-1px {
    margin: -1px !important;
  }

  .tablet\:margin-neg-2px {
    margin: -2px !important;
  }

  .tablet\:margin-neg-05 {
    margin: -.25rem !important;
  }

  .tablet\:margin-neg-1 {
    margin: -.5rem !important;
  }

  .tablet\:margin-neg-105 {
    margin: -.75rem !important;
  }

  .tablet\:margin-neg-2 {
    margin: -1rem !important;
  }

  .tablet\:margin-neg-205 {
    margin: -1.25rem !important;
  }

  .tablet\:margin-neg-3 {
    margin: -1.5rem !important;
  }

  .tablet\:margin-1px {
    margin: 1px !important;
  }

  .tablet\:margin-2px {
    margin: 2px !important;
  }

  .tablet\:margin-05 {
    margin: .25rem !important;
  }

  .tablet\:margin-1 {
    margin: .5rem !important;
  }

  .tablet\:margin-105 {
    margin: .75rem !important;
  }

  .tablet\:margin-2 {
    margin: 1rem !important;
  }

  .tablet\:margin-205 {
    margin: 1.25rem !important;
  }

  .tablet\:margin-3 {
    margin: 1.5rem !important;
  }

  .tablet\:margin-neg-4 {
    margin: -2rem !important;
  }

  .tablet\:margin-neg-5 {
    margin: -2.5rem !important;
  }

  .tablet\:margin-neg-6 {
    margin: -3rem !important;
  }

  .tablet\:margin-neg-7 {
    margin: -3.5rem !important;
  }

  .tablet\:margin-neg-8 {
    margin: -4rem !important;
  }

  .tablet\:margin-neg-9 {
    margin: -4.5rem !important;
  }

  .tablet\:margin-neg-10 {
    margin: -5rem !important;
  }

  .tablet\:margin-neg-15 {
    margin: -7.5rem !important;
  }

  .tablet\:margin-4 {
    margin: 2rem !important;
  }

  .tablet\:margin-5 {
    margin: 2.5rem !important;
  }

  .tablet\:margin-6 {
    margin: 3rem !important;
  }

  .tablet\:margin-7 {
    margin: 3.5rem !important;
  }

  .tablet\:margin-8 {
    margin: 4rem !important;
  }

  .tablet\:margin-9 {
    margin: 4.5rem !important;
  }

  .tablet\:margin-10 {
    margin: 5rem !important;
  }

  .tablet\:margin-15 {
    margin: 7.5rem !important;
  }

  .tablet\:margin-05em {
    margin: .5em !important;
  }

  .tablet\:margin-1em {
    margin: 1em !important;
  }

  .tablet\:margin-105em {
    margin: 1.5em !important;
  }

  .tablet\:margin-2em {
    margin: 2em !important;
  }

  .tablet\:margin-0 {
    margin: 0 !important;
  }

  .tablet\:margin-y-1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .tablet\:margin-top-1px {
    margin-top: 1px !important;
  }

  .tablet\:margin-bottom-1px {
    margin-bottom: 1px !important;
  }

  .tablet\:margin-y-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .tablet\:margin-top-2px {
    margin-top: 2px !important;
  }

  .tablet\:margin-bottom-2px {
    margin-bottom: 2px !important;
  }

  .tablet\:margin-y-05 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .tablet\:margin-top-05 {
    margin-top: .25rem !important;
  }

  .tablet\:margin-bottom-05 {
    margin-bottom: .25rem !important;
  }

  .tablet\:margin-y-1 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .tablet\:margin-top-1 {
    margin-top: .5rem !important;
  }

  .tablet\:margin-bottom-1 {
    margin-bottom: .5rem !important;
  }

  .tablet\:margin-y-105 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .tablet\:margin-top-105 {
    margin-top: .75rem !important;
  }

  .tablet\:margin-bottom-105 {
    margin-bottom: .75rem !important;
  }

  .tablet\:margin-y-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .tablet\:margin-top-2 {
    margin-top: 1rem !important;
  }

  .tablet\:margin-bottom-2 {
    margin-bottom: 1rem !important;
  }

  .tablet\:margin-y-205 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .tablet\:margin-top-205 {
    margin-top: 1.25rem !important;
  }

  .tablet\:margin-bottom-205 {
    margin-bottom: 1.25rem !important;
  }

  .tablet\:margin-y-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .tablet\:margin-top-3 {
    margin-top: 1.5rem !important;
  }

  .tablet\:margin-bottom-3 {
    margin-bottom: 1.5rem !important;
  }

  .tablet\:margin-y-neg-1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .tablet\:margin-top-neg-1px {
    margin-top: -1px !important;
  }

  .tablet\:margin-bottom-neg-1px {
    margin-bottom: -1px !important;
  }

  .tablet\:margin-y-neg-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .tablet\:margin-top-neg-2px {
    margin-top: -2px !important;
  }

  .tablet\:margin-bottom-neg-2px {
    margin-bottom: -2px !important;
  }

  .tablet\:margin-y-neg-05 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .tablet\:margin-top-neg-05 {
    margin-top: -.25rem !important;
  }

  .tablet\:margin-bottom-neg-05 {
    margin-bottom: -.25rem !important;
  }

  .tablet\:margin-y-neg-1 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .tablet\:margin-top-neg-1 {
    margin-top: -.5rem !important;
  }

  .tablet\:margin-bottom-neg-1 {
    margin-bottom: -.5rem !important;
  }

  .tablet\:margin-y-neg-105 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .tablet\:margin-top-neg-105 {
    margin-top: -.75rem !important;
  }

  .tablet\:margin-bottom-neg-105 {
    margin-bottom: -.75rem !important;
  }

  .tablet\:margin-y-neg-2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .tablet\:margin-top-neg-2 {
    margin-top: -1rem !important;
  }

  .tablet\:margin-bottom-neg-2 {
    margin-bottom: -1rem !important;
  }

  .tablet\:margin-y-neg-205 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .tablet\:margin-top-neg-205 {
    margin-top: -1.25rem !important;
  }

  .tablet\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem !important;
  }

  .tablet\:margin-y-neg-3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .tablet\:margin-top-neg-3 {
    margin-top: -1.5rem !important;
  }

  .tablet\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem !important;
  }

  .tablet\:margin-y-neg-4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .tablet\:margin-top-neg-4 {
    margin-top: -2rem !important;
  }

  .tablet\:margin-bottom-neg-4 {
    margin-bottom: -2rem !important;
  }

  .tablet\:margin-y-neg-5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .tablet\:margin-top-neg-5 {
    margin-top: -2.5rem !important;
  }

  .tablet\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem !important;
  }

  .tablet\:margin-y-neg-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .tablet\:margin-top-neg-6 {
    margin-top: -3rem !important;
  }

  .tablet\:margin-bottom-neg-6 {
    margin-bottom: -3rem !important;
  }

  .tablet\:margin-y-neg-7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .tablet\:margin-top-neg-7 {
    margin-top: -3.5rem !important;
  }

  .tablet\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem !important;
  }

  .tablet\:margin-y-neg-8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .tablet\:margin-top-neg-8 {
    margin-top: -4rem !important;
  }

  .tablet\:margin-bottom-neg-8 {
    margin-bottom: -4rem !important;
  }

  .tablet\:margin-y-neg-9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .tablet\:margin-top-neg-9 {
    margin-top: -4.5rem !important;
  }

  .tablet\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem !important;
  }

  .tablet\:margin-y-neg-10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .tablet\:margin-top-neg-10 {
    margin-top: -5rem !important;
  }

  .tablet\:margin-bottom-neg-10 {
    margin-bottom: -5rem !important;
  }

  .tablet\:margin-y-neg-15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .tablet\:margin-top-neg-15 {
    margin-top: -7.5rem !important;
  }

  .tablet\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem !important;
  }

  .tablet\:margin-y-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .tablet\:margin-top-4 {
    margin-top: 2rem !important;
  }

  .tablet\:margin-bottom-4 {
    margin-bottom: 2rem !important;
  }

  .tablet\:margin-y-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .tablet\:margin-top-5 {
    margin-top: 2.5rem !important;
  }

  .tablet\:margin-bottom-5 {
    margin-bottom: 2.5rem !important;
  }

  .tablet\:margin-y-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .tablet\:margin-top-6 {
    margin-top: 3rem !important;
  }

  .tablet\:margin-bottom-6 {
    margin-bottom: 3rem !important;
  }

  .tablet\:margin-y-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .tablet\:margin-top-7 {
    margin-top: 3.5rem !important;
  }

  .tablet\:margin-bottom-7 {
    margin-bottom: 3.5rem !important;
  }

  .tablet\:margin-y-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .tablet\:margin-top-8 {
    margin-top: 4rem !important;
  }

  .tablet\:margin-bottom-8 {
    margin-bottom: 4rem !important;
  }

  .tablet\:margin-y-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .tablet\:margin-top-9 {
    margin-top: 4.5rem !important;
  }

  .tablet\:margin-bottom-9 {
    margin-bottom: 4.5rem !important;
  }

  .tablet\:margin-y-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .tablet\:margin-top-10 {
    margin-top: 5rem !important;
  }

  .tablet\:margin-bottom-10 {
    margin-bottom: 5rem !important;
  }

  .tablet\:margin-y-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .tablet\:margin-top-15 {
    margin-top: 7.5rem !important;
  }

  .tablet\:margin-bottom-15 {
    margin-bottom: 7.5rem !important;
  }

  .tablet\:margin-y-05em {
    margin-top: .5em !important;
    margin-bottom: .5em !important;
  }

  .tablet\:margin-top-05em {
    margin-top: .5em !important;
  }

  .tablet\:margin-bottom-05em {
    margin-bottom: .5em !important;
  }

  .tablet\:margin-y-1em {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  .tablet\:margin-top-1em {
    margin-top: 1em !important;
  }

  .tablet\:margin-bottom-1em {
    margin-bottom: 1em !important;
  }

  .tablet\:margin-y-105em {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }

  .tablet\:margin-top-105em {
    margin-top: 1.5em !important;
  }

  .tablet\:margin-bottom-105em {
    margin-bottom: 1.5em !important;
  }

  .tablet\:margin-y-2em {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }

  .tablet\:margin-top-2em {
    margin-top: 2em !important;
  }

  .tablet\:margin-bottom-2em {
    margin-bottom: 2em !important;
  }

  .tablet\:margin-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .tablet\:margin-top-0 {
    margin-top: 0 !important;
  }

  .tablet\:margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  .tablet\:margin-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .tablet\:margin-top-auto {
    margin-top: auto !important;
  }

  .tablet\:margin-bottom-auto {
    margin-bottom: auto !important;
  }

  .tablet\:margin-x-1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .tablet\:margin-right-1px {
    margin-right: 1px !important;
  }

  .tablet\:margin-left-1px {
    margin-left: 1px !important;
  }

  .tablet\:margin-x-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .tablet\:margin-right-2px {
    margin-right: 2px !important;
  }

  .tablet\:margin-left-2px {
    margin-left: 2px !important;
  }

  .tablet\:margin-x-05 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .tablet\:margin-right-05 {
    margin-right: .25rem !important;
  }

  .tablet\:margin-left-05 {
    margin-left: .25rem !important;
  }

  .tablet\:margin-x-1 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .tablet\:margin-right-1 {
    margin-right: .5rem !important;
  }

  .tablet\:margin-left-1 {
    margin-left: .5rem !important;
  }

  .tablet\:margin-x-105 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .tablet\:margin-right-105 {
    margin-right: .75rem !important;
  }

  .tablet\:margin-left-105 {
    margin-left: .75rem !important;
  }

  .tablet\:margin-x-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .tablet\:margin-right-2 {
    margin-right: 1rem !important;
  }

  .tablet\:margin-left-2 {
    margin-left: 1rem !important;
  }

  .tablet\:margin-x-205 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .tablet\:margin-right-205 {
    margin-right: 1.25rem !important;
  }

  .tablet\:margin-left-205 {
    margin-left: 1.25rem !important;
  }

  .tablet\:margin-x-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .tablet\:margin-right-3 {
    margin-right: 1.5rem !important;
  }

  .tablet\:margin-left-3 {
    margin-left: 1.5rem !important;
  }

  .tablet\:margin-x-neg-1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .tablet\:margin-right-neg-1px {
    margin-right: -1px !important;
  }

  .tablet\:margin-left-neg-1px {
    margin-left: -1px !important;
  }

  .tablet\:margin-x-neg-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .tablet\:margin-right-neg-2px {
    margin-right: -2px !important;
  }

  .tablet\:margin-left-neg-2px {
    margin-left: -2px !important;
  }

  .tablet\:margin-x-neg-05 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .tablet\:margin-right-neg-05 {
    margin-right: -.25rem !important;
  }

  .tablet\:margin-left-neg-05 {
    margin-left: -.25rem !important;
  }

  .tablet\:margin-x-neg-1 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .tablet\:margin-right-neg-1 {
    margin-right: -.5rem !important;
  }

  .tablet\:margin-left-neg-1 {
    margin-left: -.5rem !important;
  }

  .tablet\:margin-x-neg-105 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .tablet\:margin-right-neg-105 {
    margin-right: -.75rem !important;
  }

  .tablet\:margin-left-neg-105 {
    margin-left: -.75rem !important;
  }

  .tablet\:margin-x-neg-2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .tablet\:margin-right-neg-2 {
    margin-right: -1rem !important;
  }

  .tablet\:margin-left-neg-2 {
    margin-left: -1rem !important;
  }

  .tablet\:margin-x-neg-205 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .tablet\:margin-right-neg-205 {
    margin-right: -1.25rem !important;
  }

  .tablet\:margin-left-neg-205 {
    margin-left: -1.25rem !important;
  }

  .tablet\:margin-x-neg-3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .tablet\:margin-right-neg-3 {
    margin-right: -1.5rem !important;
  }

  .tablet\:margin-left-neg-3 {
    margin-left: -1.5rem !important;
  }

  .tablet\:margin-x-neg-4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .tablet\:margin-right-neg-4 {
    margin-right: -2rem !important;
  }

  .tablet\:margin-left-neg-4 {
    margin-left: -2rem !important;
  }

  .tablet\:margin-x-neg-5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .tablet\:margin-right-neg-5 {
    margin-right: -2.5rem !important;
  }

  .tablet\:margin-left-neg-5 {
    margin-left: -2.5rem !important;
  }

  .tablet\:margin-x-neg-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .tablet\:margin-right-neg-6 {
    margin-right: -3rem !important;
  }

  .tablet\:margin-left-neg-6 {
    margin-left: -3rem !important;
  }

  .tablet\:margin-x-neg-7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .tablet\:margin-right-neg-7 {
    margin-right: -3.5rem !important;
  }

  .tablet\:margin-left-neg-7 {
    margin-left: -3.5rem !important;
  }

  .tablet\:margin-x-neg-8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .tablet\:margin-right-neg-8 {
    margin-right: -4rem !important;
  }

  .tablet\:margin-left-neg-8 {
    margin-left: -4rem !important;
  }

  .tablet\:margin-x-neg-9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .tablet\:margin-right-neg-9 {
    margin-right: -4.5rem !important;
  }

  .tablet\:margin-left-neg-9 {
    margin-left: -4.5rem !important;
  }

  .tablet\:margin-x-neg-10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .tablet\:margin-right-neg-10 {
    margin-right: -5rem !important;
  }

  .tablet\:margin-left-neg-10 {
    margin-left: -5rem !important;
  }

  .tablet\:margin-x-neg-15 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .tablet\:margin-right-neg-15 {
    margin-right: -7.5rem !important;
  }

  .tablet\:margin-left-neg-15 {
    margin-left: -7.5rem !important;
  }

  .tablet\:margin-x-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .tablet\:margin-right-4 {
    margin-right: 2rem !important;
  }

  .tablet\:margin-left-4 {
    margin-left: 2rem !important;
  }

  .tablet\:margin-x-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .tablet\:margin-right-5 {
    margin-right: 2.5rem !important;
  }

  .tablet\:margin-left-5 {
    margin-left: 2.5rem !important;
  }

  .tablet\:margin-x-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .tablet\:margin-right-6 {
    margin-right: 3rem !important;
  }

  .tablet\:margin-left-6 {
    margin-left: 3rem !important;
  }

  .tablet\:margin-x-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .tablet\:margin-right-7 {
    margin-right: 3.5rem !important;
  }

  .tablet\:margin-left-7 {
    margin-left: 3.5rem !important;
  }

  .tablet\:margin-x-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .tablet\:margin-right-8 {
    margin-right: 4rem !important;
  }

  .tablet\:margin-left-8 {
    margin-left: 4rem !important;
  }

  .tablet\:margin-x-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .tablet\:margin-right-9 {
    margin-right: 4.5rem !important;
  }

  .tablet\:margin-left-9 {
    margin-left: 4.5rem !important;
  }

  .tablet\:margin-x-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .tablet\:margin-right-10 {
    margin-right: 5rem !important;
  }

  .tablet\:margin-left-10 {
    margin-left: 5rem !important;
  }

  .tablet\:margin-x-15 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .tablet\:margin-right-15 {
    margin-right: 7.5rem !important;
  }

  .tablet\:margin-left-15 {
    margin-left: 7.5rem !important;
  }

  .tablet\:margin-x-card {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .tablet\:margin-right-card {
    margin-right: 10rem !important;
  }

  .tablet\:margin-left-card {
    margin-left: 10rem !important;
  }

  .tablet\:margin-x-card-lg {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .tablet\:margin-right-card-lg {
    margin-right: 15rem !important;
  }

  .tablet\:margin-left-card-lg {
    margin-left: 15rem !important;
  }

  .tablet\:margin-x-mobile {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .tablet\:margin-right-mobile {
    margin-right: 20rem !important;
  }

  .tablet\:margin-left-mobile {
    margin-left: 20rem !important;
  }

  .tablet\:margin-x-05em {
    margin-left: .5em !important;
    margin-right: .5em !important;
  }

  .tablet\:margin-right-05em {
    margin-right: .5em !important;
  }

  .tablet\:margin-left-05em {
    margin-left: .5em !important;
  }

  .tablet\:margin-x-1em {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }

  .tablet\:margin-right-1em {
    margin-right: 1em !important;
  }

  .tablet\:margin-left-1em {
    margin-left: 1em !important;
  }

  .tablet\:margin-x-105em {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }

  .tablet\:margin-right-105em {
    margin-right: 1.5em !important;
  }

  .tablet\:margin-left-105em {
    margin-left: 1.5em !important;
  }

  .tablet\:margin-x-2em {
    margin-left: 2em !important;
    margin-right: 2em !important;
  }

  .tablet\:margin-right-2em {
    margin-right: 2em !important;
  }

  .tablet\:margin-left-2em {
    margin-left: 2em !important;
  }

  .tablet\:margin-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .tablet\:margin-right-0 {
    margin-right: 0 !important;
  }

  .tablet\:margin-left-0 {
    margin-left: 0 !important;
  }

  .tablet\:margin-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .tablet\:margin-right-auto {
    margin-right: auto !important;
  }

  .tablet\:margin-left-auto {
    margin-left: auto !important;
  }

  .tablet\:measure-1 {
    max-width: 44ex !important;
  }

  .tablet\:measure-2 {
    max-width: 60ex !important;
  }

  .tablet\:measure-3 {
    max-width: 64ex !important;
  }

  .tablet\:measure-4 {
    max-width: 68ex !important;
  }

  .tablet\:measure-5 {
    max-width: 72ex !important;
  }

  .tablet\:measure-6 {
    max-width: 88ex !important;
  }

  .tablet\:measure-none {
    max-width: none !important;
  }

  .tablet\:order-first {
    order: -1 !important;
  }

  .tablet\:order-last {
    order: 999 !important;
  }

  .tablet\:order-initial {
    order: initial !important;
  }

  .tablet\:order-0 {
    order: 0 !important;
  }

  .tablet\:order-1 {
    order: 1 !important;
  }

  .tablet\:order-2 {
    order: 2 !important;
  }

  .tablet\:order-3 {
    order: 3 !important;
  }

  .tablet\:order-4 {
    order: 4 !important;
  }

  .tablet\:order-5 {
    order: 5 !important;
  }

  .tablet\:order-6 {
    order: 6 !important;
  }

  .tablet\:order-7 {
    order: 7 !important;
  }

  .tablet\:order-8 {
    order: 8 !important;
  }

  .tablet\:order-9 {
    order: 9 !important;
  }

  .tablet\:order-10 {
    order: 10 !important;
  }

  .tablet\:order-11 {
    order: 11 !important;
  }

  .tablet\:padding-1px {
    padding: 1px !important;
  }

  .tablet\:padding-y-1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .tablet\:padding-x-1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .tablet\:padding-top-1px {
    padding-top: 1px !important;
  }

  .tablet\:padding-right-1px {
    padding-right: 1px !important;
  }

  .tablet\:padding-bottom-1px {
    padding-bottom: 1px !important;
  }

  .tablet\:padding-left-1px {
    padding-left: 1px !important;
  }

  .tablet\:padding-2px {
    padding: 2px !important;
  }

  .tablet\:padding-y-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .tablet\:padding-x-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .tablet\:padding-top-2px {
    padding-top: 2px !important;
  }

  .tablet\:padding-right-2px {
    padding-right: 2px !important;
  }

  .tablet\:padding-bottom-2px {
    padding-bottom: 2px !important;
  }

  .tablet\:padding-left-2px {
    padding-left: 2px !important;
  }

  .tablet\:padding-05 {
    padding: .25rem !important;
  }

  .tablet\:padding-y-05 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .tablet\:padding-x-05 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .tablet\:padding-top-05 {
    padding-top: .25rem !important;
  }

  .tablet\:padding-right-05 {
    padding-right: .25rem !important;
  }

  .tablet\:padding-bottom-05 {
    padding-bottom: .25rem !important;
  }

  .tablet\:padding-left-05 {
    padding-left: .25rem !important;
  }

  .tablet\:padding-1 {
    padding: .5rem !important;
  }

  .tablet\:padding-y-1 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .tablet\:padding-x-1 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .tablet\:padding-top-1 {
    padding-top: .5rem !important;
  }

  .tablet\:padding-right-1 {
    padding-right: .5rem !important;
  }

  .tablet\:padding-bottom-1 {
    padding-bottom: .5rem !important;
  }

  .tablet\:padding-left-1 {
    padding-left: .5rem !important;
  }

  .tablet\:padding-105 {
    padding: .75rem !important;
  }

  .tablet\:padding-y-105 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .tablet\:padding-x-105 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .tablet\:padding-top-105 {
    padding-top: .75rem !important;
  }

  .tablet\:padding-right-105 {
    padding-right: .75rem !important;
  }

  .tablet\:padding-bottom-105 {
    padding-bottom: .75rem !important;
  }

  .tablet\:padding-left-105 {
    padding-left: .75rem !important;
  }

  .tablet\:padding-2 {
    padding: 1rem !important;
  }

  .tablet\:padding-y-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .tablet\:padding-x-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .tablet\:padding-top-2 {
    padding-top: 1rem !important;
  }

  .tablet\:padding-right-2 {
    padding-right: 1rem !important;
  }

  .tablet\:padding-bottom-2 {
    padding-bottom: 1rem !important;
  }

  .tablet\:padding-left-2 {
    padding-left: 1rem !important;
  }

  .tablet\:padding-205 {
    padding: 1.25rem !important;
  }

  .tablet\:padding-y-205 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .tablet\:padding-x-205 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .tablet\:padding-top-205 {
    padding-top: 1.25rem !important;
  }

  .tablet\:padding-right-205 {
    padding-right: 1.25rem !important;
  }

  .tablet\:padding-bottom-205 {
    padding-bottom: 1.25rem !important;
  }

  .tablet\:padding-left-205 {
    padding-left: 1.25rem !important;
  }

  .tablet\:padding-3 {
    padding: 1.5rem !important;
  }

  .tablet\:padding-y-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .tablet\:padding-x-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .tablet\:padding-top-3 {
    padding-top: 1.5rem !important;
  }

  .tablet\:padding-right-3 {
    padding-right: 1.5rem !important;
  }

  .tablet\:padding-bottom-3 {
    padding-bottom: 1.5rem !important;
  }

  .tablet\:padding-left-3 {
    padding-left: 1.5rem !important;
  }

  .tablet\:padding-4 {
    padding: 2rem !important;
  }

  .tablet\:padding-y-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .tablet\:padding-x-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .tablet\:padding-top-4 {
    padding-top: 2rem !important;
  }

  .tablet\:padding-right-4 {
    padding-right: 2rem !important;
  }

  .tablet\:padding-bottom-4 {
    padding-bottom: 2rem !important;
  }

  .tablet\:padding-left-4 {
    padding-left: 2rem !important;
  }

  .tablet\:padding-5 {
    padding: 2.5rem !important;
  }

  .tablet\:padding-y-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .tablet\:padding-x-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .tablet\:padding-top-5 {
    padding-top: 2.5rem !important;
  }

  .tablet\:padding-right-5 {
    padding-right: 2.5rem !important;
  }

  .tablet\:padding-bottom-5 {
    padding-bottom: 2.5rem !important;
  }

  .tablet\:padding-left-5 {
    padding-left: 2.5rem !important;
  }

  .tablet\:padding-6 {
    padding: 3rem !important;
  }

  .tablet\:padding-y-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .tablet\:padding-x-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .tablet\:padding-top-6 {
    padding-top: 3rem !important;
  }

  .tablet\:padding-right-6 {
    padding-right: 3rem !important;
  }

  .tablet\:padding-bottom-6 {
    padding-bottom: 3rem !important;
  }

  .tablet\:padding-left-6 {
    padding-left: 3rem !important;
  }

  .tablet\:padding-7 {
    padding: 3.5rem !important;
  }

  .tablet\:padding-y-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .tablet\:padding-x-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .tablet\:padding-top-7 {
    padding-top: 3.5rem !important;
  }

  .tablet\:padding-right-7 {
    padding-right: 3.5rem !important;
  }

  .tablet\:padding-bottom-7 {
    padding-bottom: 3.5rem !important;
  }

  .tablet\:padding-left-7 {
    padding-left: 3.5rem !important;
  }

  .tablet\:padding-8 {
    padding: 4rem !important;
  }

  .tablet\:padding-y-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .tablet\:padding-x-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .tablet\:padding-top-8 {
    padding-top: 4rem !important;
  }

  .tablet\:padding-right-8 {
    padding-right: 4rem !important;
  }

  .tablet\:padding-bottom-8 {
    padding-bottom: 4rem !important;
  }

  .tablet\:padding-left-8 {
    padding-left: 4rem !important;
  }

  .tablet\:padding-9 {
    padding: 4.5rem !important;
  }

  .tablet\:padding-y-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .tablet\:padding-x-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .tablet\:padding-top-9 {
    padding-top: 4.5rem !important;
  }

  .tablet\:padding-right-9 {
    padding-right: 4.5rem !important;
  }

  .tablet\:padding-bottom-9 {
    padding-bottom: 4.5rem !important;
  }

  .tablet\:padding-left-9 {
    padding-left: 4.5rem !important;
  }

  .tablet\:padding-10 {
    padding: 5rem !important;
  }

  .tablet\:padding-y-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .tablet\:padding-x-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .tablet\:padding-top-10 {
    padding-top: 5rem !important;
  }

  .tablet\:padding-right-10 {
    padding-right: 5rem !important;
  }

  .tablet\:padding-bottom-10 {
    padding-bottom: 5rem !important;
  }

  .tablet\:padding-left-10 {
    padding-left: 5rem !important;
  }

  .tablet\:padding-15 {
    padding: 7.5rem !important;
  }

  .tablet\:padding-y-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .tablet\:padding-x-15 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .tablet\:padding-top-15 {
    padding-top: 7.5rem !important;
  }

  .tablet\:padding-right-15 {
    padding-right: 7.5rem !important;
  }

  .tablet\:padding-bottom-15 {
    padding-bottom: 7.5rem !important;
  }

  .tablet\:padding-left-15 {
    padding-left: 7.5rem !important;
  }

  .tablet\:padding-0 {
    padding: 0 !important;
  }

  .tablet\:padding-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .tablet\:padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .tablet\:padding-top-0 {
    padding-top: 0 !important;
  }

  .tablet\:padding-right-0 {
    padding-right: 0 !important;
  }

  .tablet\:padding-bottom-0 {
    padding-bottom: 0 !important;
  }

  .tablet\:padding-left-0 {
    padding-left: 0 !important;
  }

  .tablet\:width-1px {
    width: 1px !important;
  }

  .tablet\:width-2px {
    width: 2px !important;
  }

  .tablet\:width-05 {
    width: .25rem !important;
  }

  .tablet\:width-1 {
    width: .5rem !important;
  }

  .tablet\:width-105 {
    width: .75rem !important;
  }

  .tablet\:width-2 {
    width: 1rem !important;
  }

  .tablet\:width-205 {
    width: 1.25rem !important;
  }

  .tablet\:width-3 {
    width: 1.5rem !important;
  }

  .tablet\:width-4 {
    width: 2rem !important;
  }

  .tablet\:width-5 {
    width: 2.5rem !important;
  }

  .tablet\:width-6 {
    width: 3rem !important;
  }

  .tablet\:width-7 {
    width: 3.5rem !important;
  }

  .tablet\:width-8 {
    width: 4rem !important;
  }

  .tablet\:width-9 {
    width: 4.5rem !important;
  }

  .tablet\:width-10 {
    width: 5rem !important;
  }

  .tablet\:width-15 {
    width: 7.5rem !important;
  }

  .tablet\:width-card {
    width: 10rem !important;
  }

  .tablet\:width-card-lg {
    width: 15rem !important;
  }

  .tablet\:width-mobile {
    width: 20rem !important;
  }

  .tablet\:width-mobile-lg {
    width: 30rem !important;
  }

  .tablet\:width-tablet {
    width: 40rem !important;
  }

  .tablet\:width-tablet-lg {
    width: 55rem !important;
  }

  .tablet\:width-desktop {
    width: 64rem !important;
  }

  .tablet\:width-desktop-lg {
    width: 75rem !important;
  }

  .tablet\:width-widescreen {
    width: 87.5rem !important;
  }

  .tablet\:width-0 {
    width: 0 !important;
  }

  .tablet\:width-full {
    width: 100% !important;
  }

  .tablet\:width-auto {
    width: auto !important;
  }
}

@media (width >= 64em) {
  .desktop\:border-1px, .desktop\:hover\:border-1px:hover {
    border: 1px solid !important;
  }

  .desktop\:border-y-1px, .desktop\:hover\:border-y-1px:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .desktop\:border-x-1px, .desktop\:hover\:border-x-1px:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .desktop\:border-top-1px, .desktop\:hover\:border-top-1px:hover {
    border-top: 1px solid !important;
  }

  .desktop\:border-right-1px, .desktop\:hover\:border-right-1px:hover {
    border-right: 1px solid !important;
  }

  .desktop\:border-bottom-1px, .desktop\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid !important;
  }

  .desktop\:border-left-1px, .desktop\:hover\:border-left-1px:hover {
    border-left: 1px solid !important;
  }

  .desktop\:border-2px, .desktop\:hover\:border-2px:hover {
    border: 2px solid !important;
  }

  .desktop\:border-y-2px, .desktop\:hover\:border-y-2px:hover {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }

  .desktop\:border-x-2px, .desktop\:hover\:border-x-2px:hover {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }

  .desktop\:border-top-2px, .desktop\:hover\:border-top-2px:hover {
    border-top: 2px solid !important;
  }

  .desktop\:border-right-2px, .desktop\:hover\:border-right-2px:hover {
    border-right: 2px solid !important;
  }

  .desktop\:border-bottom-2px, .desktop\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid !important;
  }

  .desktop\:border-left-2px, .desktop\:hover\:border-left-2px:hover {
    border-left: 2px solid !important;
  }

  .desktop\:border-05, .desktop\:hover\:border-05:hover {
    border: .25rem solid !important;
  }

  .desktop\:border-y-05, .desktop\:hover\:border-y-05:hover {
    border-top: .25rem solid !important;
    border-bottom: .25rem solid !important;
  }

  .desktop\:border-x-05, .desktop\:hover\:border-x-05:hover {
    border-left: .25rem solid !important;
    border-right: .25rem solid !important;
  }

  .desktop\:border-top-05, .desktop\:hover\:border-top-05:hover {
    border-top: .25rem solid !important;
  }

  .desktop\:border-right-05, .desktop\:hover\:border-right-05:hover {
    border-right: .25rem solid !important;
  }

  .desktop\:border-bottom-05, .desktop\:hover\:border-bottom-05:hover {
    border-bottom: .25rem solid !important;
  }

  .desktop\:border-left-05, .desktop\:hover\:border-left-05:hover {
    border-left: .25rem solid !important;
  }

  .desktop\:border-1, .desktop\:hover\:border-1:hover {
    border: .5rem solid !important;
  }

  .desktop\:border-y-1, .desktop\:hover\:border-y-1:hover {
    border-top: .5rem solid !important;
    border-bottom: .5rem solid !important;
  }

  .desktop\:border-x-1, .desktop\:hover\:border-x-1:hover {
    border-left: .5rem solid !important;
    border-right: .5rem solid !important;
  }

  .desktop\:border-top-1, .desktop\:hover\:border-top-1:hover {
    border-top: .5rem solid !important;
  }

  .desktop\:border-right-1, .desktop\:hover\:border-right-1:hover {
    border-right: .5rem solid !important;
  }

  .desktop\:border-bottom-1, .desktop\:hover\:border-bottom-1:hover {
    border-bottom: .5rem solid !important;
  }

  .desktop\:border-left-1, .desktop\:hover\:border-left-1:hover {
    border-left: .5rem solid !important;
  }

  .desktop\:border-105, .desktop\:hover\:border-105:hover {
    border: .75rem solid !important;
  }

  .desktop\:border-y-105, .desktop\:hover\:border-y-105:hover {
    border-top: .75rem solid !important;
    border-bottom: .75rem solid !important;
  }

  .desktop\:border-x-105, .desktop\:hover\:border-x-105:hover {
    border-left: .75rem solid !important;
    border-right: .75rem solid !important;
  }

  .desktop\:border-top-105, .desktop\:hover\:border-top-105:hover {
    border-top: .75rem solid !important;
  }

  .desktop\:border-right-105, .desktop\:hover\:border-right-105:hover {
    border-right: .75rem solid !important;
  }

  .desktop\:border-bottom-105, .desktop\:hover\:border-bottom-105:hover {
    border-bottom: .75rem solid !important;
  }

  .desktop\:border-left-105, .desktop\:hover\:border-left-105:hover {
    border-left: .75rem solid !important;
  }

  .desktop\:border-2, .desktop\:hover\:border-2:hover {
    border: 1rem solid !important;
  }

  .desktop\:border-y-2, .desktop\:hover\:border-y-2:hover {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }

  .desktop\:border-x-2, .desktop\:hover\:border-x-2:hover {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }

  .desktop\:border-top-2, .desktop\:hover\:border-top-2:hover {
    border-top: 1rem solid !important;
  }

  .desktop\:border-right-2, .desktop\:hover\:border-right-2:hover {
    border-right: 1rem solid !important;
  }

  .desktop\:border-bottom-2, .desktop\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid !important;
  }

  .desktop\:border-left-2, .desktop\:hover\:border-left-2:hover {
    border-left: 1rem solid !important;
  }

  .desktop\:border-205, .desktop\:hover\:border-205:hover {
    border: 1.25rem solid !important;
  }

  .desktop\:border-y-205, .desktop\:hover\:border-y-205:hover {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }

  .desktop\:border-x-205, .desktop\:hover\:border-x-205:hover {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }

  .desktop\:border-top-205, .desktop\:hover\:border-top-205:hover {
    border-top: 1.25rem solid !important;
  }

  .desktop\:border-right-205, .desktop\:hover\:border-right-205:hover {
    border-right: 1.25rem solid !important;
  }

  .desktop\:border-bottom-205, .desktop\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid !important;
  }

  .desktop\:border-left-205, .desktop\:hover\:border-left-205:hover {
    border-left: 1.25rem solid !important;
  }

  .desktop\:border-3, .desktop\:hover\:border-3:hover {
    border: 1.5rem solid !important;
  }

  .desktop\:border-y-3, .desktop\:hover\:border-y-3:hover {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }

  .desktop\:border-x-3, .desktop\:hover\:border-x-3:hover {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }

  .desktop\:border-top-3, .desktop\:hover\:border-top-3:hover {
    border-top: 1.5rem solid !important;
  }

  .desktop\:border-right-3, .desktop\:hover\:border-right-3:hover {
    border-right: 1.5rem solid !important;
  }

  .desktop\:border-bottom-3, .desktop\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid !important;
  }

  .desktop\:border-left-3, .desktop\:hover\:border-left-3:hover {
    border-left: 1.5rem solid !important;
  }

  .desktop\:border-0, .desktop\:hover\:border-0:hover {
    border: 0 solid !important;
  }

  .desktop\:border-y-0, .desktop\:hover\:border-y-0:hover {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }

  .desktop\:border-x-0, .desktop\:hover\:border-x-0:hover {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }

  .desktop\:border-top-0, .desktop\:hover\:border-top-0:hover {
    border-top: 0 solid !important;
  }

  .desktop\:border-right-0, .desktop\:hover\:border-right-0:hover {
    border-right: 0 solid !important;
  }

  .desktop\:border-bottom-0, .desktop\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid !important;
  }

  .desktop\:border-left-0, .desktop\:hover\:border-left-0:hover {
    border-left: 0 solid !important;
  }

  .desktop\:border, .desktop\:hover\:border:hover {
    border: 1px solid !important;
  }

  .desktop\:border-y, .desktop\:hover\:border-y:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .desktop\:border-x, .desktop\:hover\:border-x:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .desktop\:border-top, .desktop\:hover\:border-top:hover {
    border-top: 1px solid !important;
  }

  .desktop\:border-right, .desktop\:hover\:border-right:hover {
    border-right: 1px solid !important;
  }

  .desktop\:border-bottom, .desktop\:hover\:border-bottom:hover {
    border-bottom: 1px solid !important;
  }

  .desktop\:border-left, .desktop\:hover\:border-left:hover {
    border-left: 1px solid !important;
  }

  .desktop\:border-transparent, .desktop\:hover\:border-transparent:hover {
    border-color: #0000 !important;
  }

  .desktop\:border-black, .desktop\:hover\:border-black:hover {
    border-color: #000 !important;
  }

  .desktop\:border-white, .desktop\:hover\:border-white:hover {
    border-color: #fff !important;
  }

  .desktop\:border-red, .desktop\:hover\:border-red:hover {
    border-color: #e52207 !important;
  }

  .desktop\:border-orange, .desktop\:hover\:border-orange:hover {
    border-color: #e66f0e !important;
  }

  .desktop\:border-gold, .desktop\:hover\:border-gold:hover {
    border-color: #ffbe2e !important;
  }

  .desktop\:border-yellow, .desktop\:hover\:border-yellow:hover {
    border-color: #fee685 !important;
  }

  .desktop\:border-green, .desktop\:hover\:border-green:hover {
    border-color: #538200 !important;
  }

  .desktop\:border-mint, .desktop\:hover\:border-mint:hover {
    border-color: #04c585 !important;
  }

  .desktop\:border-cyan, .desktop\:hover\:border-cyan:hover {
    border-color: #009ec1 !important;
  }

  .desktop\:border-blue, .desktop\:hover\:border-blue:hover {
    border-color: #0076d6 !important;
  }

  .desktop\:border-indigo, .desktop\:hover\:border-indigo:hover {
    border-color: #676cc8 !important;
  }

  .desktop\:border-violet, .desktop\:hover\:border-violet:hover {
    border-color: #8168b3 !important;
  }

  .desktop\:border-magenta, .desktop\:hover\:border-magenta:hover {
    border-color: #d72d79 !important;
  }

  .desktop\:border-gray-5, .desktop\:hover\:border-gray-5:hover {
    border-color: #f0f0f0 !important;
  }

  .desktop\:border-gray-10, .desktop\:hover\:border-gray-10:hover {
    border-color: #e6e6e6 !important;
  }

  .desktop\:border-gray-30, .desktop\:hover\:border-gray-30:hover {
    border-color: #adadad !important;
  }

  .desktop\:border-gray-50, .desktop\:hover\:border-gray-50:hover {
    border-color: #757575 !important;
  }

  .desktop\:border-gray-70, .desktop\:hover\:border-gray-70:hover {
    border-color: #454545 !important;
  }

  .desktop\:border-gray-90, .desktop\:hover\:border-gray-90:hover {
    border-color: #1b1b1b !important;
  }

  .desktop\:border-base-lightest, .desktop\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }

  .desktop\:border-base-lighter, .desktop\:hover\:border-base-lighter:hover {
    border-color: #dfe1e2 !important;
  }

  .desktop\:border-base-light, .desktop\:hover\:border-base-light:hover {
    border-color: #a9aeb1 !important;
  }

  .desktop\:border-base, .desktop\:hover\:border-base:hover {
    border-color: #71767a !important;
  }

  .desktop\:border-base-dark, .desktop\:hover\:border-base-dark:hover {
    border-color: #565c65 !important;
  }

  .desktop\:border-base-darker, .desktop\:hover\:border-base-darker:hover {
    border-color: #3d4551 !important;
  }

  .desktop\:border-base-darkest, .desktop\:hover\:border-base-darkest:hover {
    border-color: #2d2e2f !important;
  }

  .desktop\:border-ink, .desktop\:hover\:border-ink:hover {
    border-color: #1c1d1f !important;
  }

  .desktop\:border-primary-lighter, .desktop\:hover\:border-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }

  .desktop\:border-primary-light, .desktop\:hover\:border-primary-light:hover {
    border-color: #73b3e7 !important;
  }

  .desktop\:border-primary, .desktop\:hover\:border-primary:hover {
    border-color: #005ea2 !important;
  }

  .desktop\:border-primary-vivid, .desktop\:hover\:border-primary-vivid:hover {
    border-color: #0050d8 !important;
  }

  .desktop\:border-primary-dark, .desktop\:hover\:border-primary-dark:hover {
    border-color: #1a4480 !important;
  }

  .desktop\:border-primary-darker, .desktop\:hover\:border-primary-darker:hover {
    border-color: #162e51 !important;
  }

  .desktop\:border-secondary-lighter, .desktop\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4 !important;
  }

  .desktop\:border-secondary-light, .desktop\:hover\:border-secondary-light:hover {
    border-color: #f2938c !important;
  }

  .desktop\:border-secondary, .desktop\:hover\:border-secondary:hover {
    border-color: #d83933 !important;
  }

  .desktop\:border-secondary-vivid, .desktop\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }

  .desktop\:border-secondary-dark, .desktop\:hover\:border-secondary-dark:hover {
    border-color: #b50909 !important;
  }

  .desktop\:border-secondary-darker, .desktop\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03 !important;
  }

  .desktop\:border-accent-warm-darker, .desktop\:hover\:border-accent-warm-darker:hover {
    border-color: #775540 !important;
  }

  .desktop\:border-accent-warm-dark, .desktop\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }

  .desktop\:border-accent-warm, .desktop\:hover\:border-accent-warm:hover {
    border-color: #fa9441 !important;
  }

  .desktop\:border-accent-warm-light, .desktop\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }

  .desktop\:border-accent-warm-lighter, .desktop\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }

  .desktop\:border-accent-cool-darker, .desktop\:hover\:border-accent-cool-darker:hover {
    border-color: #07648d !important;
  }

  .desktop\:border-accent-cool-dark, .desktop\:hover\:border-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }

  .desktop\:border-accent-cool, .desktop\:hover\:border-accent-cool:hover {
    border-color: #00bde3 !important;
  }

  .desktop\:border-accent-cool-light, .desktop\:hover\:border-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }

  .desktop\:border-accent-cool-lighter, .desktop\:hover\:border-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }

  .desktop\:border-error-lighter, .desktop\:hover\:border-error-lighter:hover {
    border-color: #f4e3db !important;
  }

  .desktop\:border-error-light, .desktop\:hover\:border-error-light:hover {
    border-color: #f39268 !important;
  }

  .desktop\:border-error, .desktop\:hover\:border-error:hover {
    border-color: #d54309 !important;
  }

  .desktop\:border-error-dark, .desktop\:hover\:border-error-dark:hover {
    border-color: #b50909 !important;
  }

  .desktop\:border-error-darker, .desktop\:hover\:border-error-darker:hover {
    border-color: #6f3331 !important;
  }

  .desktop\:border-warning-lighter, .desktop\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1 !important;
  }

  .desktop\:border-warning-light, .desktop\:hover\:border-warning-light:hover {
    border-color: #fee685 !important;
  }

  .desktop\:border-warning, .desktop\:hover\:border-warning:hover {
    border-color: #ffbe2e !important;
  }

  .desktop\:border-warning-dark, .desktop\:hover\:border-warning-dark:hover {
    border-color: #e5a000 !important;
  }

  .desktop\:border-warning-darker, .desktop\:hover\:border-warning-darker:hover {
    border-color: #936f38 !important;
  }

  .desktop\:border-success-lighter, .desktop\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec !important;
  }

  .desktop\:border-success-light, .desktop\:hover\:border-success-light:hover {
    border-color: #70e17b !important;
  }

  .desktop\:border-success, .desktop\:hover\:border-success:hover {
    border-color: #00a91c !important;
  }

  .desktop\:border-success-dark, .desktop\:hover\:border-success-dark:hover {
    border-color: #008817 !important;
  }

  .desktop\:border-success-darker, .desktop\:hover\:border-success-darker:hover {
    border-color: #216e1f !important;
  }

  .desktop\:border-info-lighter, .desktop\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8 !important;
  }

  .desktop\:border-info-light, .desktop\:hover\:border-info-light:hover {
    border-color: #99deea !important;
  }

  .desktop\:border-info, .desktop\:hover\:border-info:hover {
    border-color: #00bde3 !important;
  }

  .desktop\:border-info-dark, .desktop\:hover\:border-info-dark:hover {
    border-color: #009ec1 !important;
  }

  .desktop\:border-info-darker, .desktop\:hover\:border-info-darker:hover {
    border-color: #2e6276 !important;
  }

  .desktop\:border-disabled-lighter, .desktop\:hover\:border-disabled-lighter:hover {
    border-color: #c9c9c9 !important;
  }

  .desktop\:border-disabled-light, .desktop\:hover\:border-disabled-light:hover {
    border-color: #919191 !important;
  }

  .desktop\:border-disabled, .desktop\:hover\:border-disabled:hover {
    border-color: #757575 !important;
  }

  .desktop\:border-disabled-dark, .desktop\:hover\:border-disabled-dark:hover {
    border-color: #454545 !important;
  }

  .desktop\:border-disabled-darker, .desktop\:hover\:border-disabled-darker:hover {
    border-color: #1b1b1b !important;
  }

  .desktop\:border-emergency, .desktop\:hover\:border-emergency:hover {
    border-color: #9c3d10 !important;
  }

  .desktop\:border-emergency-dark, .desktop\:hover\:border-emergency-dark:hover {
    border-color: #332d29 !important;
  }

  .desktop\:radius-0 {
    border-radius: 0 !important;
  }

  .desktop\:radius-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .desktop\:radius-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .desktop\:radius-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .desktop\:radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .desktop\:radius-sm {
    border-radius: 2px !important;
  }

  .desktop\:radius-top-sm {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
  }

  .desktop\:radius-right-sm {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  .desktop\:radius-bottom-sm {
    border-bottom-right-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .desktop\:radius-left-sm {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .desktop\:radius-md {
    border-radius: .25rem !important;
  }

  .desktop\:radius-top-md {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .desktop\:radius-right-md {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .desktop\:radius-bottom-md {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .desktop\:radius-left-md {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .desktop\:radius-lg {
    border-radius: .5rem !important;
  }

  .desktop\:radius-top-lg {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .desktop\:radius-right-lg {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .desktop\:radius-bottom-lg {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .desktop\:radius-left-lg {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .desktop\:radius-pill {
    border-radius: 99rem !important;
  }

  .desktop\:radius-top-pill {
    border-top-left-radius: 99rem !important;
    border-top-right-radius: 99rem !important;
  }

  .desktop\:radius-right-pill {
    border-top-right-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }

  .desktop\:radius-bottom-pill {
    border-bottom-right-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  .desktop\:radius-left-pill {
    border-top-left-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  .desktop\:display-block {
    display: block !important;
  }

  .desktop\:display-flex {
    display: flex !important;
  }

  .desktop\:display-none {
    display: none !important;
  }

  .desktop\:display-inline {
    display: inline !important;
  }

  .desktop\:display-inline-block {
    display: inline-block !important;
  }

  .desktop\:display-inline-flex {
    display: inline-flex !important;
  }

  .desktop\:display-table {
    display: table !important;
  }

  .desktop\:display-table-cell {
    display: table-cell !important;
  }

  .desktop\:display-table-row {
    display: table-row !important;
  }

  .desktop\:font-mono-3xs {
    font-size: .77rem !important;
  }

  .desktop\:font-mono-2xs {
    font-size: .83rem !important;
  }

  .desktop\:font-mono-xs {
    font-size: .89rem !important;
  }

  .desktop\:font-mono-sm {
    font-size: .95rem !important;
  }

  .desktop\:font-mono-md {
    font-size: 1.19rem !important;
  }

  .desktop\:font-mono-lg {
    font-size: 1.31rem !important;
  }

  .desktop\:font-mono-xl {
    font-size: 1.91rem !important;
  }

  .desktop\:font-mono-2xl {
    font-size: 2.38rem !important;
  }

  .desktop\:font-mono-3xl {
    font-size: 2.86rem !important;
  }

  .desktop\:font-sans-3xs {
    font-size: .81rem !important;
  }

  .desktop\:font-sans-2xs {
    font-size: .87rem !important;
  }

  .desktop\:font-sans-xs {
    font-size: .93rem !important;
  }

  .desktop\:font-sans-sm {
    font-size: .99rem !important;
  }

  .desktop\:font-sans-md {
    font-size: 1.24rem !important;
  }

  .desktop\:font-sans-lg {
    font-size: 1.37rem !important;
  }

  .desktop\:font-sans-xl {
    font-size: 1.99rem !important;
  }

  .desktop\:font-sans-2xl {
    font-size: 2.49rem !important;
  }

  .desktop\:font-sans-3xl {
    font-size: 2.98rem !important;
  }

  .desktop\:font-serif-3xs {
    font-size: .81rem !important;
  }

  .desktop\:font-serif-2xs {
    font-size: .87rem !important;
  }

  .desktop\:font-serif-xs {
    font-size: .93rem !important;
  }

  .desktop\:font-serif-sm {
    font-size: .99rem !important;
  }

  .desktop\:font-serif-md {
    font-size: 1.24rem !important;
  }

  .desktop\:font-serif-lg {
    font-size: 1.37rem !important;
  }

  .desktop\:font-serif-xl {
    font-size: 1.99rem !important;
  }

  .desktop\:font-serif-2xl {
    font-size: 2.49rem !important;
  }

  .desktop\:font-serif-3xl {
    font-size: 2.98rem !important;
  }

  .desktop\:font-heading-3xs {
    font-size: .81rem !important;
  }

  .desktop\:font-heading-2xs {
    font-size: .87rem !important;
  }

  .desktop\:font-heading-xs {
    font-size: .93rem !important;
  }

  .desktop\:font-heading-sm {
    font-size: .99rem !important;
  }

  .desktop\:font-heading-md {
    font-size: 1.24rem !important;
  }

  .desktop\:font-heading-lg {
    font-size: 1.37rem !important;
  }

  .desktop\:font-heading-xl {
    font-size: 1.99rem !important;
  }

  .desktop\:font-heading-2xl {
    font-size: 2.49rem !important;
  }

  .desktop\:font-heading-3xl {
    font-size: 2.98rem !important;
  }

  .desktop\:font-body-3xs {
    font-size: .81rem !important;
  }

  .desktop\:font-body-2xs {
    font-size: .87rem !important;
  }

  .desktop\:font-body-xs {
    font-size: .93rem !important;
  }

  .desktop\:font-body-sm {
    font-size: .99rem !important;
  }

  .desktop\:font-body-md {
    font-size: 1.24rem !important;
  }

  .desktop\:font-body-lg {
    font-size: 1.37rem !important;
  }

  .desktop\:font-body-xl {
    font-size: 1.99rem !important;
  }

  .desktop\:font-body-2xl {
    font-size: 2.49rem !important;
  }

  .desktop\:font-body-3xl {
    font-size: 2.98rem !important;
  }

  .desktop\:font-code-3xs {
    font-size: .77rem !important;
  }

  .desktop\:font-code-2xs {
    font-size: .83rem !important;
  }

  .desktop\:font-code-xs {
    font-size: .89rem !important;
  }

  .desktop\:font-code-sm {
    font-size: .95rem !important;
  }

  .desktop\:font-code-md {
    font-size: 1.19rem !important;
  }

  .desktop\:font-code-lg {
    font-size: 1.31rem !important;
  }

  .desktop\:font-code-xl {
    font-size: 1.91rem !important;
  }

  .desktop\:font-code-2xl {
    font-size: 2.38rem !important;
  }

  .desktop\:font-code-3xl {
    font-size: 2.86rem !important;
  }

  .desktop\:font-alt-3xs {
    font-size: .81rem !important;
  }

  .desktop\:font-alt-2xs {
    font-size: .87rem !important;
  }

  .desktop\:font-alt-xs {
    font-size: .93rem !important;
  }

  .desktop\:font-alt-sm {
    font-size: .99rem !important;
  }

  .desktop\:font-alt-md {
    font-size: 1.24rem !important;
  }

  .desktop\:font-alt-lg {
    font-size: 1.37rem !important;
  }

  .desktop\:font-alt-xl {
    font-size: 1.99rem !important;
  }

  .desktop\:font-alt-2xl {
    font-size: 2.49rem !important;
  }

  .desktop\:font-alt-3xl {
    font-size: 2.98rem !important;
  }

  .desktop\:font-ui-3xs {
    font-size: .81rem !important;
  }

  .desktop\:font-ui-2xs {
    font-size: .87rem !important;
  }

  .desktop\:font-ui-xs {
    font-size: .93rem !important;
  }

  .desktop\:font-ui-sm {
    font-size: .99rem !important;
  }

  .desktop\:font-ui-md {
    font-size: 1.24rem !important;
  }

  .desktop\:font-ui-lg {
    font-size: 1.37rem !important;
  }

  .desktop\:font-ui-xl {
    font-size: 1.99rem !important;
  }

  .desktop\:font-ui-2xl {
    font-size: 2.49rem !important;
  }

  .desktop\:font-ui-3xl {
    font-size: 2.98rem !important;
  }

  .desktop\:text-light {
    font-weight: 300 !important;
  }

  .desktop\:text-normal {
    font-weight: normal !important;
  }

  .desktop\:text-semibold {
    font-weight: 600 !important;
  }

  .desktop\:text-bold {
    font-weight: 700 !important;
  }

  .desktop\:flex-justify-center {
    justify-content: center !important;
  }

  .desktop\:flex-justify-start {
    justify-content: flex-start !important;
  }

  .desktop\:flex-justify-end {
    justify-content: flex-end !important;
  }

  .desktop\:flex-justify {
    justify-content: space-between !important;
  }

  .desktop\:line-height-sans-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-sans-2 {
    line-height: 1.2 !important;
  }

  .desktop\:line-height-sans-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-sans-4 {
    line-height: 1.5 !important;
  }

  .desktop\:line-height-sans-5 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-sans-6 {
    line-height: 1.8 !important;
  }

  .desktop\:line-height-serif-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-serif-2 {
    line-height: 1.2 !important;
  }

  .desktop\:line-height-serif-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-serif-4 {
    line-height: 1.5 !important;
  }

  .desktop\:line-height-serif-5 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-serif-6 {
    line-height: 1.8 !important;
  }

  .desktop\:line-height-mono-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-mono-2 {
    line-height: 1.3 !important;
  }

  .desktop\:line-height-mono-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-mono-4 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-mono-5 {
    line-height: 1.7 !important;
  }

  .desktop\:line-height-mono-6 {
    line-height: 1.8 !important;
  }

  .desktop\:line-height-heading-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-heading-2 {
    line-height: 1.2 !important;
  }

  .desktop\:line-height-heading-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-heading-4 {
    line-height: 1.5 !important;
  }

  .desktop\:line-height-heading-5 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-heading-6 {
    line-height: 1.8 !important;
  }

  .desktop\:line-height-ui-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-ui-2 {
    line-height: 1.2 !important;
  }

  .desktop\:line-height-ui-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-ui-4 {
    line-height: 1.5 !important;
  }

  .desktop\:line-height-ui-5 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-ui-6 {
    line-height: 1.8 !important;
  }

  .desktop\:line-height-body-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-body-2 {
    line-height: 1.2 !important;
  }

  .desktop\:line-height-body-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-body-4 {
    line-height: 1.5 !important;
  }

  .desktop\:line-height-body-5 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-body-6 {
    line-height: 1.8 !important;
  }

  .desktop\:line-height-code-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-code-2 {
    line-height: 1.3 !important;
  }

  .desktop\:line-height-code-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-code-4 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-code-5 {
    line-height: 1.7 !important;
  }

  .desktop\:line-height-code-6 {
    line-height: 1.8 !important;
  }

  .desktop\:line-height-alt-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-alt-2 {
    line-height: 1.2 !important;
  }

  .desktop\:line-height-alt-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-alt-4 {
    line-height: 1.5 !important;
  }

  .desktop\:line-height-alt-5 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-alt-6 {
    line-height: 1.8 !important;
  }

  .desktop\:margin-neg-1px {
    margin: -1px !important;
  }

  .desktop\:margin-neg-2px {
    margin: -2px !important;
  }

  .desktop\:margin-neg-05 {
    margin: -.25rem !important;
  }

  .desktop\:margin-neg-1 {
    margin: -.5rem !important;
  }

  .desktop\:margin-neg-105 {
    margin: -.75rem !important;
  }

  .desktop\:margin-neg-2 {
    margin: -1rem !important;
  }

  .desktop\:margin-neg-205 {
    margin: -1.25rem !important;
  }

  .desktop\:margin-neg-3 {
    margin: -1.5rem !important;
  }

  .desktop\:margin-1px {
    margin: 1px !important;
  }

  .desktop\:margin-2px {
    margin: 2px !important;
  }

  .desktop\:margin-05 {
    margin: .25rem !important;
  }

  .desktop\:margin-1 {
    margin: .5rem !important;
  }

  .desktop\:margin-105 {
    margin: .75rem !important;
  }

  .desktop\:margin-2 {
    margin: 1rem !important;
  }

  .desktop\:margin-205 {
    margin: 1.25rem !important;
  }

  .desktop\:margin-3 {
    margin: 1.5rem !important;
  }

  .desktop\:margin-neg-4 {
    margin: -2rem !important;
  }

  .desktop\:margin-neg-5 {
    margin: -2.5rem !important;
  }

  .desktop\:margin-neg-6 {
    margin: -3rem !important;
  }

  .desktop\:margin-neg-7 {
    margin: -3.5rem !important;
  }

  .desktop\:margin-neg-8 {
    margin: -4rem !important;
  }

  .desktop\:margin-neg-9 {
    margin: -4.5rem !important;
  }

  .desktop\:margin-neg-10 {
    margin: -5rem !important;
  }

  .desktop\:margin-neg-15 {
    margin: -7.5rem !important;
  }

  .desktop\:margin-4 {
    margin: 2rem !important;
  }

  .desktop\:margin-5 {
    margin: 2.5rem !important;
  }

  .desktop\:margin-6 {
    margin: 3rem !important;
  }

  .desktop\:margin-7 {
    margin: 3.5rem !important;
  }

  .desktop\:margin-8 {
    margin: 4rem !important;
  }

  .desktop\:margin-9 {
    margin: 4.5rem !important;
  }

  .desktop\:margin-10 {
    margin: 5rem !important;
  }

  .desktop\:margin-15 {
    margin: 7.5rem !important;
  }

  .desktop\:margin-05em {
    margin: .5em !important;
  }

  .desktop\:margin-1em {
    margin: 1em !important;
  }

  .desktop\:margin-105em {
    margin: 1.5em !important;
  }

  .desktop\:margin-2em {
    margin: 2em !important;
  }

  .desktop\:margin-0 {
    margin: 0 !important;
  }

  .desktop\:margin-y-1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .desktop\:margin-top-1px {
    margin-top: 1px !important;
  }

  .desktop\:margin-bottom-1px {
    margin-bottom: 1px !important;
  }

  .desktop\:margin-y-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .desktop\:margin-top-2px {
    margin-top: 2px !important;
  }

  .desktop\:margin-bottom-2px {
    margin-bottom: 2px !important;
  }

  .desktop\:margin-y-05 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .desktop\:margin-top-05 {
    margin-top: .25rem !important;
  }

  .desktop\:margin-bottom-05 {
    margin-bottom: .25rem !important;
  }

  .desktop\:margin-y-1 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .desktop\:margin-top-1 {
    margin-top: .5rem !important;
  }

  .desktop\:margin-bottom-1 {
    margin-bottom: .5rem !important;
  }

  .desktop\:margin-y-105 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .desktop\:margin-top-105 {
    margin-top: .75rem !important;
  }

  .desktop\:margin-bottom-105 {
    margin-bottom: .75rem !important;
  }

  .desktop\:margin-y-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .desktop\:margin-top-2 {
    margin-top: 1rem !important;
  }

  .desktop\:margin-bottom-2 {
    margin-bottom: 1rem !important;
  }

  .desktop\:margin-y-205 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .desktop\:margin-top-205 {
    margin-top: 1.25rem !important;
  }

  .desktop\:margin-bottom-205 {
    margin-bottom: 1.25rem !important;
  }

  .desktop\:margin-y-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .desktop\:margin-top-3 {
    margin-top: 1.5rem !important;
  }

  .desktop\:margin-bottom-3 {
    margin-bottom: 1.5rem !important;
  }

  .desktop\:margin-y-neg-1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .desktop\:margin-top-neg-1px {
    margin-top: -1px !important;
  }

  .desktop\:margin-bottom-neg-1px {
    margin-bottom: -1px !important;
  }

  .desktop\:margin-y-neg-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .desktop\:margin-top-neg-2px {
    margin-top: -2px !important;
  }

  .desktop\:margin-bottom-neg-2px {
    margin-bottom: -2px !important;
  }

  .desktop\:margin-y-neg-05 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .desktop\:margin-top-neg-05 {
    margin-top: -.25rem !important;
  }

  .desktop\:margin-bottom-neg-05 {
    margin-bottom: -.25rem !important;
  }

  .desktop\:margin-y-neg-1 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .desktop\:margin-top-neg-1 {
    margin-top: -.5rem !important;
  }

  .desktop\:margin-bottom-neg-1 {
    margin-bottom: -.5rem !important;
  }

  .desktop\:margin-y-neg-105 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .desktop\:margin-top-neg-105 {
    margin-top: -.75rem !important;
  }

  .desktop\:margin-bottom-neg-105 {
    margin-bottom: -.75rem !important;
  }

  .desktop\:margin-y-neg-2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .desktop\:margin-top-neg-2 {
    margin-top: -1rem !important;
  }

  .desktop\:margin-bottom-neg-2 {
    margin-bottom: -1rem !important;
  }

  .desktop\:margin-y-neg-205 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .desktop\:margin-top-neg-205 {
    margin-top: -1.25rem !important;
  }

  .desktop\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem !important;
  }

  .desktop\:margin-y-neg-3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .desktop\:margin-top-neg-3 {
    margin-top: -1.5rem !important;
  }

  .desktop\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem !important;
  }

  .desktop\:margin-y-neg-4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .desktop\:margin-top-neg-4 {
    margin-top: -2rem !important;
  }

  .desktop\:margin-bottom-neg-4 {
    margin-bottom: -2rem !important;
  }

  .desktop\:margin-y-neg-5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .desktop\:margin-top-neg-5 {
    margin-top: -2.5rem !important;
  }

  .desktop\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem !important;
  }

  .desktop\:margin-y-neg-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .desktop\:margin-top-neg-6 {
    margin-top: -3rem !important;
  }

  .desktop\:margin-bottom-neg-6 {
    margin-bottom: -3rem !important;
  }

  .desktop\:margin-y-neg-7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .desktop\:margin-top-neg-7 {
    margin-top: -3.5rem !important;
  }

  .desktop\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem !important;
  }

  .desktop\:margin-y-neg-8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .desktop\:margin-top-neg-8 {
    margin-top: -4rem !important;
  }

  .desktop\:margin-bottom-neg-8 {
    margin-bottom: -4rem !important;
  }

  .desktop\:margin-y-neg-9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .desktop\:margin-top-neg-9 {
    margin-top: -4.5rem !important;
  }

  .desktop\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem !important;
  }

  .desktop\:margin-y-neg-10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .desktop\:margin-top-neg-10 {
    margin-top: -5rem !important;
  }

  .desktop\:margin-bottom-neg-10 {
    margin-bottom: -5rem !important;
  }

  .desktop\:margin-y-neg-15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .desktop\:margin-top-neg-15 {
    margin-top: -7.5rem !important;
  }

  .desktop\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem !important;
  }

  .desktop\:margin-y-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .desktop\:margin-top-4 {
    margin-top: 2rem !important;
  }

  .desktop\:margin-bottom-4 {
    margin-bottom: 2rem !important;
  }

  .desktop\:margin-y-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .desktop\:margin-top-5 {
    margin-top: 2.5rem !important;
  }

  .desktop\:margin-bottom-5 {
    margin-bottom: 2.5rem !important;
  }

  .desktop\:margin-y-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .desktop\:margin-top-6 {
    margin-top: 3rem !important;
  }

  .desktop\:margin-bottom-6 {
    margin-bottom: 3rem !important;
  }

  .desktop\:margin-y-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .desktop\:margin-top-7 {
    margin-top: 3.5rem !important;
  }

  .desktop\:margin-bottom-7 {
    margin-bottom: 3.5rem !important;
  }

  .desktop\:margin-y-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .desktop\:margin-top-8 {
    margin-top: 4rem !important;
  }

  .desktop\:margin-bottom-8 {
    margin-bottom: 4rem !important;
  }

  .desktop\:margin-y-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .desktop\:margin-top-9 {
    margin-top: 4.5rem !important;
  }

  .desktop\:margin-bottom-9 {
    margin-bottom: 4.5rem !important;
  }

  .desktop\:margin-y-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .desktop\:margin-top-10 {
    margin-top: 5rem !important;
  }

  .desktop\:margin-bottom-10 {
    margin-bottom: 5rem !important;
  }

  .desktop\:margin-y-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .desktop\:margin-top-15 {
    margin-top: 7.5rem !important;
  }

  .desktop\:margin-bottom-15 {
    margin-bottom: 7.5rem !important;
  }

  .desktop\:margin-y-05em {
    margin-top: .5em !important;
    margin-bottom: .5em !important;
  }

  .desktop\:margin-top-05em {
    margin-top: .5em !important;
  }

  .desktop\:margin-bottom-05em {
    margin-bottom: .5em !important;
  }

  .desktop\:margin-y-1em {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  .desktop\:margin-top-1em {
    margin-top: 1em !important;
  }

  .desktop\:margin-bottom-1em {
    margin-bottom: 1em !important;
  }

  .desktop\:margin-y-105em {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }

  .desktop\:margin-top-105em {
    margin-top: 1.5em !important;
  }

  .desktop\:margin-bottom-105em {
    margin-bottom: 1.5em !important;
  }

  .desktop\:margin-y-2em {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }

  .desktop\:margin-top-2em {
    margin-top: 2em !important;
  }

  .desktop\:margin-bottom-2em {
    margin-bottom: 2em !important;
  }

  .desktop\:margin-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .desktop\:margin-top-0 {
    margin-top: 0 !important;
  }

  .desktop\:margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  .desktop\:margin-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .desktop\:margin-top-auto {
    margin-top: auto !important;
  }

  .desktop\:margin-bottom-auto {
    margin-bottom: auto !important;
  }

  .desktop\:margin-x-1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .desktop\:margin-right-1px {
    margin-right: 1px !important;
  }

  .desktop\:margin-left-1px {
    margin-left: 1px !important;
  }

  .desktop\:margin-x-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .desktop\:margin-right-2px {
    margin-right: 2px !important;
  }

  .desktop\:margin-left-2px {
    margin-left: 2px !important;
  }

  .desktop\:margin-x-05 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .desktop\:margin-right-05 {
    margin-right: .25rem !important;
  }

  .desktop\:margin-left-05 {
    margin-left: .25rem !important;
  }

  .desktop\:margin-x-1 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .desktop\:margin-right-1 {
    margin-right: .5rem !important;
  }

  .desktop\:margin-left-1 {
    margin-left: .5rem !important;
  }

  .desktop\:margin-x-105 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .desktop\:margin-right-105 {
    margin-right: .75rem !important;
  }

  .desktop\:margin-left-105 {
    margin-left: .75rem !important;
  }

  .desktop\:margin-x-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .desktop\:margin-right-2 {
    margin-right: 1rem !important;
  }

  .desktop\:margin-left-2 {
    margin-left: 1rem !important;
  }

  .desktop\:margin-x-205 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .desktop\:margin-right-205 {
    margin-right: 1.25rem !important;
  }

  .desktop\:margin-left-205 {
    margin-left: 1.25rem !important;
  }

  .desktop\:margin-x-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .desktop\:margin-right-3 {
    margin-right: 1.5rem !important;
  }

  .desktop\:margin-left-3 {
    margin-left: 1.5rem !important;
  }

  .desktop\:margin-x-neg-1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .desktop\:margin-right-neg-1px {
    margin-right: -1px !important;
  }

  .desktop\:margin-left-neg-1px {
    margin-left: -1px !important;
  }

  .desktop\:margin-x-neg-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .desktop\:margin-right-neg-2px {
    margin-right: -2px !important;
  }

  .desktop\:margin-left-neg-2px {
    margin-left: -2px !important;
  }

  .desktop\:margin-x-neg-05 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .desktop\:margin-right-neg-05 {
    margin-right: -.25rem !important;
  }

  .desktop\:margin-left-neg-05 {
    margin-left: -.25rem !important;
  }

  .desktop\:margin-x-neg-1 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .desktop\:margin-right-neg-1 {
    margin-right: -.5rem !important;
  }

  .desktop\:margin-left-neg-1 {
    margin-left: -.5rem !important;
  }

  .desktop\:margin-x-neg-105 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .desktop\:margin-right-neg-105 {
    margin-right: -.75rem !important;
  }

  .desktop\:margin-left-neg-105 {
    margin-left: -.75rem !important;
  }

  .desktop\:margin-x-neg-2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .desktop\:margin-right-neg-2 {
    margin-right: -1rem !important;
  }

  .desktop\:margin-left-neg-2 {
    margin-left: -1rem !important;
  }

  .desktop\:margin-x-neg-205 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .desktop\:margin-right-neg-205 {
    margin-right: -1.25rem !important;
  }

  .desktop\:margin-left-neg-205 {
    margin-left: -1.25rem !important;
  }

  .desktop\:margin-x-neg-3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .desktop\:margin-right-neg-3 {
    margin-right: -1.5rem !important;
  }

  .desktop\:margin-left-neg-3 {
    margin-left: -1.5rem !important;
  }

  .desktop\:margin-x-neg-4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .desktop\:margin-right-neg-4 {
    margin-right: -2rem !important;
  }

  .desktop\:margin-left-neg-4 {
    margin-left: -2rem !important;
  }

  .desktop\:margin-x-neg-5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .desktop\:margin-right-neg-5 {
    margin-right: -2.5rem !important;
  }

  .desktop\:margin-left-neg-5 {
    margin-left: -2.5rem !important;
  }

  .desktop\:margin-x-neg-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .desktop\:margin-right-neg-6 {
    margin-right: -3rem !important;
  }

  .desktop\:margin-left-neg-6 {
    margin-left: -3rem !important;
  }

  .desktop\:margin-x-neg-7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .desktop\:margin-right-neg-7 {
    margin-right: -3.5rem !important;
  }

  .desktop\:margin-left-neg-7 {
    margin-left: -3.5rem !important;
  }

  .desktop\:margin-x-neg-8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .desktop\:margin-right-neg-8 {
    margin-right: -4rem !important;
  }

  .desktop\:margin-left-neg-8 {
    margin-left: -4rem !important;
  }

  .desktop\:margin-x-neg-9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .desktop\:margin-right-neg-9 {
    margin-right: -4.5rem !important;
  }

  .desktop\:margin-left-neg-9 {
    margin-left: -4.5rem !important;
  }

  .desktop\:margin-x-neg-10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .desktop\:margin-right-neg-10 {
    margin-right: -5rem !important;
  }

  .desktop\:margin-left-neg-10 {
    margin-left: -5rem !important;
  }

  .desktop\:margin-x-neg-15 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .desktop\:margin-right-neg-15 {
    margin-right: -7.5rem !important;
  }

  .desktop\:margin-left-neg-15 {
    margin-left: -7.5rem !important;
  }

  .desktop\:margin-x-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .desktop\:margin-right-4 {
    margin-right: 2rem !important;
  }

  .desktop\:margin-left-4 {
    margin-left: 2rem !important;
  }

  .desktop\:margin-x-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .desktop\:margin-right-5 {
    margin-right: 2.5rem !important;
  }

  .desktop\:margin-left-5 {
    margin-left: 2.5rem !important;
  }

  .desktop\:margin-x-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .desktop\:margin-right-6 {
    margin-right: 3rem !important;
  }

  .desktop\:margin-left-6 {
    margin-left: 3rem !important;
  }

  .desktop\:margin-x-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .desktop\:margin-right-7 {
    margin-right: 3.5rem !important;
  }

  .desktop\:margin-left-7 {
    margin-left: 3.5rem !important;
  }

  .desktop\:margin-x-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .desktop\:margin-right-8 {
    margin-right: 4rem !important;
  }

  .desktop\:margin-left-8 {
    margin-left: 4rem !important;
  }

  .desktop\:margin-x-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .desktop\:margin-right-9 {
    margin-right: 4.5rem !important;
  }

  .desktop\:margin-left-9 {
    margin-left: 4.5rem !important;
  }

  .desktop\:margin-x-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .desktop\:margin-right-10 {
    margin-right: 5rem !important;
  }

  .desktop\:margin-left-10 {
    margin-left: 5rem !important;
  }

  .desktop\:margin-x-15 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .desktop\:margin-right-15 {
    margin-right: 7.5rem !important;
  }

  .desktop\:margin-left-15 {
    margin-left: 7.5rem !important;
  }

  .desktop\:margin-x-card {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .desktop\:margin-right-card {
    margin-right: 10rem !important;
  }

  .desktop\:margin-left-card {
    margin-left: 10rem !important;
  }

  .desktop\:margin-x-card-lg {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .desktop\:margin-right-card-lg {
    margin-right: 15rem !important;
  }

  .desktop\:margin-left-card-lg {
    margin-left: 15rem !important;
  }

  .desktop\:margin-x-mobile {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .desktop\:margin-right-mobile {
    margin-right: 20rem !important;
  }

  .desktop\:margin-left-mobile {
    margin-left: 20rem !important;
  }

  .desktop\:margin-x-05em {
    margin-left: .5em !important;
    margin-right: .5em !important;
  }

  .desktop\:margin-right-05em {
    margin-right: .5em !important;
  }

  .desktop\:margin-left-05em {
    margin-left: .5em !important;
  }

  .desktop\:margin-x-1em {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }

  .desktop\:margin-right-1em {
    margin-right: 1em !important;
  }

  .desktop\:margin-left-1em {
    margin-left: 1em !important;
  }

  .desktop\:margin-x-105em {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }

  .desktop\:margin-right-105em {
    margin-right: 1.5em !important;
  }

  .desktop\:margin-left-105em {
    margin-left: 1.5em !important;
  }

  .desktop\:margin-x-2em {
    margin-left: 2em !important;
    margin-right: 2em !important;
  }

  .desktop\:margin-right-2em {
    margin-right: 2em !important;
  }

  .desktop\:margin-left-2em {
    margin-left: 2em !important;
  }

  .desktop\:margin-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .desktop\:margin-right-0 {
    margin-right: 0 !important;
  }

  .desktop\:margin-left-0 {
    margin-left: 0 !important;
  }

  .desktop\:margin-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .desktop\:margin-right-auto {
    margin-right: auto !important;
  }

  .desktop\:margin-left-auto {
    margin-left: auto !important;
  }

  .desktop\:measure-1 {
    max-width: 44ex !important;
  }

  .desktop\:measure-2 {
    max-width: 60ex !important;
  }

  .desktop\:measure-3 {
    max-width: 64ex !important;
  }

  .desktop\:measure-4 {
    max-width: 68ex !important;
  }

  .desktop\:measure-5 {
    max-width: 72ex !important;
  }

  .desktop\:measure-6 {
    max-width: 88ex !important;
  }

  .desktop\:measure-none {
    max-width: none !important;
  }

  .desktop\:order-first {
    order: -1 !important;
  }

  .desktop\:order-last {
    order: 999 !important;
  }

  .desktop\:order-initial {
    order: initial !important;
  }

  .desktop\:order-0 {
    order: 0 !important;
  }

  .desktop\:order-1 {
    order: 1 !important;
  }

  .desktop\:order-2 {
    order: 2 !important;
  }

  .desktop\:order-3 {
    order: 3 !important;
  }

  .desktop\:order-4 {
    order: 4 !important;
  }

  .desktop\:order-5 {
    order: 5 !important;
  }

  .desktop\:order-6 {
    order: 6 !important;
  }

  .desktop\:order-7 {
    order: 7 !important;
  }

  .desktop\:order-8 {
    order: 8 !important;
  }

  .desktop\:order-9 {
    order: 9 !important;
  }

  .desktop\:order-10 {
    order: 10 !important;
  }

  .desktop\:order-11 {
    order: 11 !important;
  }

  .desktop\:padding-1px {
    padding: 1px !important;
  }

  .desktop\:padding-y-1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .desktop\:padding-x-1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .desktop\:padding-top-1px {
    padding-top: 1px !important;
  }

  .desktop\:padding-right-1px {
    padding-right: 1px !important;
  }

  .desktop\:padding-bottom-1px {
    padding-bottom: 1px !important;
  }

  .desktop\:padding-left-1px {
    padding-left: 1px !important;
  }

  .desktop\:padding-2px {
    padding: 2px !important;
  }

  .desktop\:padding-y-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .desktop\:padding-x-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .desktop\:padding-top-2px {
    padding-top: 2px !important;
  }

  .desktop\:padding-right-2px {
    padding-right: 2px !important;
  }

  .desktop\:padding-bottom-2px {
    padding-bottom: 2px !important;
  }

  .desktop\:padding-left-2px {
    padding-left: 2px !important;
  }

  .desktop\:padding-05 {
    padding: .25rem !important;
  }

  .desktop\:padding-y-05 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .desktop\:padding-x-05 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .desktop\:padding-top-05 {
    padding-top: .25rem !important;
  }

  .desktop\:padding-right-05 {
    padding-right: .25rem !important;
  }

  .desktop\:padding-bottom-05 {
    padding-bottom: .25rem !important;
  }

  .desktop\:padding-left-05 {
    padding-left: .25rem !important;
  }

  .desktop\:padding-1 {
    padding: .5rem !important;
  }

  .desktop\:padding-y-1 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .desktop\:padding-x-1 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .desktop\:padding-top-1 {
    padding-top: .5rem !important;
  }

  .desktop\:padding-right-1 {
    padding-right: .5rem !important;
  }

  .desktop\:padding-bottom-1 {
    padding-bottom: .5rem !important;
  }

  .desktop\:padding-left-1 {
    padding-left: .5rem !important;
  }

  .desktop\:padding-105 {
    padding: .75rem !important;
  }

  .desktop\:padding-y-105 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .desktop\:padding-x-105 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .desktop\:padding-top-105 {
    padding-top: .75rem !important;
  }

  .desktop\:padding-right-105 {
    padding-right: .75rem !important;
  }

  .desktop\:padding-bottom-105 {
    padding-bottom: .75rem !important;
  }

  .desktop\:padding-left-105 {
    padding-left: .75rem !important;
  }

  .desktop\:padding-2 {
    padding: 1rem !important;
  }

  .desktop\:padding-y-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .desktop\:padding-x-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .desktop\:padding-top-2 {
    padding-top: 1rem !important;
  }

  .desktop\:padding-right-2 {
    padding-right: 1rem !important;
  }

  .desktop\:padding-bottom-2 {
    padding-bottom: 1rem !important;
  }

  .desktop\:padding-left-2 {
    padding-left: 1rem !important;
  }

  .desktop\:padding-205 {
    padding: 1.25rem !important;
  }

  .desktop\:padding-y-205 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .desktop\:padding-x-205 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .desktop\:padding-top-205 {
    padding-top: 1.25rem !important;
  }

  .desktop\:padding-right-205 {
    padding-right: 1.25rem !important;
  }

  .desktop\:padding-bottom-205 {
    padding-bottom: 1.25rem !important;
  }

  .desktop\:padding-left-205 {
    padding-left: 1.25rem !important;
  }

  .desktop\:padding-3 {
    padding: 1.5rem !important;
  }

  .desktop\:padding-y-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .desktop\:padding-x-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .desktop\:padding-top-3 {
    padding-top: 1.5rem !important;
  }

  .desktop\:padding-right-3 {
    padding-right: 1.5rem !important;
  }

  .desktop\:padding-bottom-3 {
    padding-bottom: 1.5rem !important;
  }

  .desktop\:padding-left-3 {
    padding-left: 1.5rem !important;
  }

  .desktop\:padding-4 {
    padding: 2rem !important;
  }

  .desktop\:padding-y-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .desktop\:padding-x-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .desktop\:padding-top-4 {
    padding-top: 2rem !important;
  }

  .desktop\:padding-right-4 {
    padding-right: 2rem !important;
  }

  .desktop\:padding-bottom-4 {
    padding-bottom: 2rem !important;
  }

  .desktop\:padding-left-4 {
    padding-left: 2rem !important;
  }

  .desktop\:padding-5 {
    padding: 2.5rem !important;
  }

  .desktop\:padding-y-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .desktop\:padding-x-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .desktop\:padding-top-5 {
    padding-top: 2.5rem !important;
  }

  .desktop\:padding-right-5 {
    padding-right: 2.5rem !important;
  }

  .desktop\:padding-bottom-5 {
    padding-bottom: 2.5rem !important;
  }

  .desktop\:padding-left-5 {
    padding-left: 2.5rem !important;
  }

  .desktop\:padding-6 {
    padding: 3rem !important;
  }

  .desktop\:padding-y-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .desktop\:padding-x-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .desktop\:padding-top-6 {
    padding-top: 3rem !important;
  }

  .desktop\:padding-right-6 {
    padding-right: 3rem !important;
  }

  .desktop\:padding-bottom-6 {
    padding-bottom: 3rem !important;
  }

  .desktop\:padding-left-6 {
    padding-left: 3rem !important;
  }

  .desktop\:padding-7 {
    padding: 3.5rem !important;
  }

  .desktop\:padding-y-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .desktop\:padding-x-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .desktop\:padding-top-7 {
    padding-top: 3.5rem !important;
  }

  .desktop\:padding-right-7 {
    padding-right: 3.5rem !important;
  }

  .desktop\:padding-bottom-7 {
    padding-bottom: 3.5rem !important;
  }

  .desktop\:padding-left-7 {
    padding-left: 3.5rem !important;
  }

  .desktop\:padding-8 {
    padding: 4rem !important;
  }

  .desktop\:padding-y-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .desktop\:padding-x-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .desktop\:padding-top-8 {
    padding-top: 4rem !important;
  }

  .desktop\:padding-right-8 {
    padding-right: 4rem !important;
  }

  .desktop\:padding-bottom-8 {
    padding-bottom: 4rem !important;
  }

  .desktop\:padding-left-8 {
    padding-left: 4rem !important;
  }

  .desktop\:padding-9 {
    padding: 4.5rem !important;
  }

  .desktop\:padding-y-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .desktop\:padding-x-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .desktop\:padding-top-9 {
    padding-top: 4.5rem !important;
  }

  .desktop\:padding-right-9 {
    padding-right: 4.5rem !important;
  }

  .desktop\:padding-bottom-9 {
    padding-bottom: 4.5rem !important;
  }

  .desktop\:padding-left-9 {
    padding-left: 4.5rem !important;
  }

  .desktop\:padding-10 {
    padding: 5rem !important;
  }

  .desktop\:padding-y-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .desktop\:padding-x-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .desktop\:padding-top-10 {
    padding-top: 5rem !important;
  }

  .desktop\:padding-right-10 {
    padding-right: 5rem !important;
  }

  .desktop\:padding-bottom-10 {
    padding-bottom: 5rem !important;
  }

  .desktop\:padding-left-10 {
    padding-left: 5rem !important;
  }

  .desktop\:padding-15 {
    padding: 7.5rem !important;
  }

  .desktop\:padding-y-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .desktop\:padding-x-15 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .desktop\:padding-top-15 {
    padding-top: 7.5rem !important;
  }

  .desktop\:padding-right-15 {
    padding-right: 7.5rem !important;
  }

  .desktop\:padding-bottom-15 {
    padding-bottom: 7.5rem !important;
  }

  .desktop\:padding-left-15 {
    padding-left: 7.5rem !important;
  }

  .desktop\:padding-0 {
    padding: 0 !important;
  }

  .desktop\:padding-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .desktop\:padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .desktop\:padding-top-0 {
    padding-top: 0 !important;
  }

  .desktop\:padding-right-0 {
    padding-right: 0 !important;
  }

  .desktop\:padding-bottom-0 {
    padding-bottom: 0 !important;
  }

  .desktop\:padding-left-0 {
    padding-left: 0 !important;
  }

  .desktop\:width-1px {
    width: 1px !important;
  }

  .desktop\:width-2px {
    width: 2px !important;
  }

  .desktop\:width-05 {
    width: .25rem !important;
  }

  .desktop\:width-1 {
    width: .5rem !important;
  }

  .desktop\:width-105 {
    width: .75rem !important;
  }

  .desktop\:width-2 {
    width: 1rem !important;
  }

  .desktop\:width-205 {
    width: 1.25rem !important;
  }

  .desktop\:width-3 {
    width: 1.5rem !important;
  }

  .desktop\:width-4 {
    width: 2rem !important;
  }

  .desktop\:width-5 {
    width: 2.5rem !important;
  }

  .desktop\:width-6 {
    width: 3rem !important;
  }

  .desktop\:width-7 {
    width: 3.5rem !important;
  }

  .desktop\:width-8 {
    width: 4rem !important;
  }

  .desktop\:width-9 {
    width: 4.5rem !important;
  }

  .desktop\:width-10 {
    width: 5rem !important;
  }

  .desktop\:width-15 {
    width: 7.5rem !important;
  }

  .desktop\:width-card {
    width: 10rem !important;
  }

  .desktop\:width-card-lg {
    width: 15rem !important;
  }

  .desktop\:width-mobile {
    width: 20rem !important;
  }

  .desktop\:width-mobile-lg {
    width: 30rem !important;
  }

  .desktop\:width-tablet {
    width: 40rem !important;
  }

  .desktop\:width-tablet-lg {
    width: 55rem !important;
  }

  .desktop\:width-desktop {
    width: 64rem !important;
  }

  .desktop\:width-desktop-lg {
    width: 75rem !important;
  }

  .desktop\:width-widescreen {
    width: 87.5rem !important;
  }

  .desktop\:width-0 {
    width: 0 !important;
  }

  .desktop\:width-full {
    width: 100% !important;
  }

  .desktop\:width-auto {
    width: auto !important;
  }
}

@media (width >= 64em) {
  .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-card {
    max-width: 10rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-card-lg {
    max-width: 15rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-mobile {
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-mobile-lg {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-tablet {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-tablet-lg {
    max-width: 55rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-desktop {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-desktop-lg {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-widescreen {
    max-width: 87.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-card {
    max-width: 10rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-card-lg {
    max-width: 15rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-mobile {
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-mobile-lg {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-tablet {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-tablet-lg {
    max-width: 55rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-desktop {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-desktop-lg {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-widescreen {
    max-width: 87.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-card {
    max-width: 10rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-card-lg {
    max-width: 15rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-mobile {
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-mobile-lg {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-tablet {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-tablet-lg {
    max-width: 55rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-desktop {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-desktop-lg {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-widescreen {
    max-width: 87.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .grid-row.grid-gap {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .grid-row.grid-gap > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) {
  .grid-row.mobile-lg\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .grid-row.mobile-lg\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }

  .grid-row.mobile-lg\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .grid-row.mobile-lg\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }

  .grid-row.mobile-lg\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }

  .grid-row.mobile-lg\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }

  .grid-row.mobile-lg\:grid-gap-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .grid-row.mobile-lg\:grid-gap-1 > * {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .grid-row.mobile-lg\:grid-gap-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .grid-row.mobile-lg\:grid-gap-2 > * {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .grid-row.mobile-lg\:grid-gap-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .grid-row.mobile-lg\:grid-gap-3 > * {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .grid-row.mobile-lg\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .grid-row.mobile-lg\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .grid-row.mobile-lg\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .grid-row.mobile-lg\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .grid-row.mobile-lg\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .grid-row.mobile-lg\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .grid-row.mobile-lg\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }

  .grid-row.mobile-lg\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }

  .grid-row.mobile-lg\:grid-gap-md {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .grid-row.mobile-lg\:grid-gap-md > * {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .grid-row.mobile-lg\:grid-gap-lg {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .grid-row.mobile-lg\:grid-gap-lg > * {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

@media (width >= 40em) {
  .grid-row.tablet\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .grid-row.tablet\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }

  .grid-row.tablet\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .grid-row.tablet\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }

  .grid-row.tablet\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }

  .grid-row.tablet\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }

  .grid-row.tablet\:grid-gap-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .grid-row.tablet\:grid-gap-1 > * {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .grid-row.tablet\:grid-gap-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .grid-row.tablet\:grid-gap-2 > * {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .grid-row.tablet\:grid-gap-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .grid-row.tablet\:grid-gap-3 > * {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .grid-row.tablet\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .grid-row.tablet\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .grid-row.tablet\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .grid-row.tablet\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .grid-row.tablet\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .grid-row.tablet\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .grid-row.tablet\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }

  .grid-row.tablet\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }

  .grid-row.tablet\:grid-gap-md {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .grid-row.tablet\:grid-gap-md > * {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .grid-row.tablet\:grid-gap-lg {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .grid-row.tablet\:grid-gap-lg > * {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

@media (width >= 64em) {
  .grid-row.desktop\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .grid-row.desktop\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }

  .grid-row.desktop\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .grid-row.desktop\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }

  .grid-row.desktop\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }

  .grid-row.desktop\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }

  .grid-row.desktop\:grid-gap-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .grid-row.desktop\:grid-gap-1 > * {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .grid-row.desktop\:grid-gap-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .grid-row.desktop\:grid-gap-2 > * {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .grid-row.desktop\:grid-gap-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .grid-row.desktop\:grid-gap-3 > * {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .grid-row.desktop\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .grid-row.desktop\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .grid-row.desktop\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .grid-row.desktop\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .grid-row.desktop\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .grid-row.desktop\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .grid-row.desktop\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }

  .grid-row.desktop\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }

  .grid-row.desktop\:grid-gap-md {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .grid-row.desktop\:grid-gap-md > * {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .grid-row.desktop\:grid-gap-lg {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .grid-row.desktop\:grid-gap-lg > * {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-col, .mobile-lg\:grid-col-fill {
    flex: 1;
    width: auto;
    min-width: 1px;
    max-width: 100%;
  }

  .mobile-lg\:grid-col-auto {
    flex: 0 auto;
    width: auto;
    max-width: 100%;
  }

  .mobile-lg\:grid-col-1 {
    flex: 0 auto;
    width: 8.33333%;
  }

  .mobile-lg\:grid-col-2 {
    flex: 0 auto;
    width: 16.6667%;
  }

  .mobile-lg\:grid-col-3 {
    flex: 0 auto;
    width: 25%;
  }

  .mobile-lg\:grid-col-4 {
    flex: 0 auto;
    width: 33.3333%;
  }

  .mobile-lg\:grid-col-5 {
    flex: 0 auto;
    width: 41.6667%;
  }

  .mobile-lg\:grid-col-6 {
    flex: 0 auto;
    width: 50%;
  }

  .mobile-lg\:grid-col-7 {
    flex: 0 auto;
    width: 58.3333%;
  }

  .mobile-lg\:grid-col-8 {
    flex: 0 auto;
    width: 66.6667%;
  }

  .mobile-lg\:grid-col-9 {
    flex: 0 auto;
    width: 75%;
  }

  .mobile-lg\:grid-col-10 {
    flex: 0 auto;
    width: 83.3333%;
  }

  .mobile-lg\:grid-col-11 {
    flex: 0 auto;
    width: 91.6667%;
  }

  .mobile-lg\:grid-col-12 {
    flex: 0 auto;
    width: 100%;
  }
}

@media (width >= 40em) {
  .tablet\:grid-col, .tablet\:grid-col-fill {
    flex: 1;
    width: auto;
    min-width: 1px;
    max-width: 100%;
  }

  .tablet\:grid-col-auto {
    flex: 0 auto;
    width: auto;
    max-width: 100%;
  }

  .tablet\:grid-col-1 {
    flex: 0 auto;
    width: 8.33333%;
  }

  .tablet\:grid-col-2 {
    flex: 0 auto;
    width: 16.6667%;
  }

  .tablet\:grid-col-3 {
    flex: 0 auto;
    width: 25%;
  }

  .tablet\:grid-col-4 {
    flex: 0 auto;
    width: 33.3333%;
  }

  .tablet\:grid-col-5 {
    flex: 0 auto;
    width: 41.6667%;
  }

  .tablet\:grid-col-6 {
    flex: 0 auto;
    width: 50%;
  }

  .tablet\:grid-col-7 {
    flex: 0 auto;
    width: 58.3333%;
  }

  .tablet\:grid-col-8 {
    flex: 0 auto;
    width: 66.6667%;
  }

  .tablet\:grid-col-9 {
    flex: 0 auto;
    width: 75%;
  }

  .tablet\:grid-col-10 {
    flex: 0 auto;
    width: 83.3333%;
  }

  .tablet\:grid-col-11 {
    flex: 0 auto;
    width: 91.6667%;
  }

  .tablet\:grid-col-12 {
    flex: 0 auto;
    width: 100%;
  }
}

@media (width >= 64em) {
  .desktop\:grid-col, .desktop\:grid-col-fill {
    flex: 1;
    width: auto;
    min-width: 1px;
    max-width: 100%;
  }

  .desktop\:grid-col-auto {
    flex: 0 auto;
    width: auto;
    max-width: 100%;
  }

  .desktop\:grid-col-1 {
    flex: 0 auto;
    width: 8.33333%;
  }

  .desktop\:grid-col-2 {
    flex: 0 auto;
    width: 16.6667%;
  }

  .desktop\:grid-col-3 {
    flex: 0 auto;
    width: 25%;
  }

  .desktop\:grid-col-4 {
    flex: 0 auto;
    width: 33.3333%;
  }

  .desktop\:grid-col-5 {
    flex: 0 auto;
    width: 41.6667%;
  }

  .desktop\:grid-col-6 {
    flex: 0 auto;
    width: 50%;
  }

  .desktop\:grid-col-7 {
    flex: 0 auto;
    width: 58.3333%;
  }

  .desktop\:grid-col-8 {
    flex: 0 auto;
    width: 66.6667%;
  }

  .desktop\:grid-col-9 {
    flex: 0 auto;
    width: 75%;
  }

  .desktop\:grid-col-10 {
    flex: 0 auto;
    width: 83.3333%;
  }

  .desktop\:grid-col-11 {
    flex: 0 auto;
    width: 91.6667%;
  }

  .desktop\:grid-col-12 {
    flex: 0 auto;
    width: 100%;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-offset-1 {
    margin-left: 8.33333%;
  }

  .mobile-lg\:grid-offset-2 {
    margin-left: 16.6667%;
  }

  .mobile-lg\:grid-offset-3 {
    margin-left: 25%;
  }

  .mobile-lg\:grid-offset-4 {
    margin-left: 33.3333%;
  }

  .mobile-lg\:grid-offset-5 {
    margin-left: 41.6667%;
  }

  .mobile-lg\:grid-offset-6 {
    margin-left: 50%;
  }

  .mobile-lg\:grid-offset-7 {
    margin-left: 58.3333%;
  }

  .mobile-lg\:grid-offset-8 {
    margin-left: 66.6667%;
  }

  .mobile-lg\:grid-offset-9 {
    margin-left: 75%;
  }

  .mobile-lg\:grid-offset-10 {
    margin-left: 83.3333%;
  }

  .mobile-lg\:grid-offset-11 {
    margin-left: 91.6667%;
  }

  .mobile-lg\:grid-offset-12 {
    margin-left: 100%;
  }

  .mobile-lg\:grid-offset-none {
    margin-left: 0;
  }
}

@media (width >= 40em) {
  .tablet\:grid-offset-1 {
    margin-left: 8.33333%;
  }

  .tablet\:grid-offset-2 {
    margin-left: 16.6667%;
  }

  .tablet\:grid-offset-3 {
    margin-left: 25%;
  }

  .tablet\:grid-offset-4 {
    margin-left: 33.3333%;
  }

  .tablet\:grid-offset-5 {
    margin-left: 41.6667%;
  }

  .tablet\:grid-offset-6 {
    margin-left: 50%;
  }

  .tablet\:grid-offset-7 {
    margin-left: 58.3333%;
  }

  .tablet\:grid-offset-8 {
    margin-left: 66.6667%;
  }

  .tablet\:grid-offset-9 {
    margin-left: 75%;
  }

  .tablet\:grid-offset-10 {
    margin-left: 83.3333%;
  }

  .tablet\:grid-offset-11 {
    margin-left: 91.6667%;
  }

  .tablet\:grid-offset-12 {
    margin-left: 100%;
  }

  .tablet\:grid-offset-none {
    margin-left: 0;
  }
}

@media (width >= 64em) {
  .desktop\:grid-offset-1 {
    margin-left: 8.33333%;
  }

  .desktop\:grid-offset-2 {
    margin-left: 16.6667%;
  }

  .desktop\:grid-offset-3 {
    margin-left: 25%;
  }

  .desktop\:grid-offset-4 {
    margin-left: 33.3333%;
  }

  .desktop\:grid-offset-5 {
    margin-left: 41.6667%;
  }

  .desktop\:grid-offset-6 {
    margin-left: 50%;
  }

  .desktop\:grid-offset-7 {
    margin-left: 58.3333%;
  }

  .desktop\:grid-offset-8 {
    margin-left: 66.6667%;
  }

  .desktop\:grid-offset-9 {
    margin-left: 75%;
  }

  .desktop\:grid-offset-10 {
    margin-left: 83.3333%;
  }

  .desktop\:grid-offset-11 {
    margin-left: 91.6667%;
  }

  .desktop\:grid-offset-12 {
    margin-left: 100%;
  }

  .desktop\:grid-offset-none {
    margin-left: 0;
  }
}

@font-face {
  font-family: Roboto Mono Web;
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url("./fonts/roboto-mono/roboto-mono-v5-latin-300.woff2") format("woff2");
}

@font-face {
  font-family: Roboto Mono Web;
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url("./fonts/roboto-mono/roboto-mono-v5-latin-regular.woff2") format("woff2");
}

@font-face {
  font-family: Roboto Mono Web;
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url("./fonts/roboto-mono/roboto-mono-v5-latin-700.woff2") format("woff2");
}

@font-face {
  font-family: Roboto Mono Web;
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: url("./fonts/roboto-mono/roboto-mono-v5-latin-300italic.woff2") format("woff2");
}

@font-face {
  font-family: Roboto Mono Web;
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url("./fonts/roboto-mono/roboto-mono-v5-latin-italic.woff2") format("woff2");
}

@font-face {
  font-family: Roboto Mono Web;
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url("./fonts/roboto-mono/roboto-mono-v5-latin-700italic.woff2") format("woff2");
}

@media (width >= 30em) {
  .usa-button {
    width: auto;
  }
}

@media (forced-colors: active) {
  .usa-button:disabled, .usa-button[aria-disabled="true"] {
    color: graytext;
    border: 0;
  }

  .usa-button:disabled:hover, .usa-button:disabled:active, .usa-button:disabled:focus, .usa-button:disabled.usa-focus, .usa-button[aria-disabled="true"]:hover, .usa-button[aria-disabled="true"]:active, .usa-button[aria-disabled="true"]:focus, .usa-button[aria-disabled="true"].usa-focus {
    color: graytext;
  }
}

@media (forced-colors: active) {
  .usa-button:disabled.usa-button--hover, .usa-button:disabled.usa-button--active, .usa-button[aria-disabled="true"].usa-button--hover, .usa-button[aria-disabled="true"].usa-button--active {
    color: graytext;
    border: 0;
  }

  .usa-button:disabled.usa-button--hover:hover, .usa-button:disabled.usa-button--hover:active, .usa-button:disabled.usa-button--hover:focus, .usa-button:disabled.usa-button--hover.usa-focus, .usa-button:disabled.usa-button--active:hover, .usa-button:disabled.usa-button--active:active, .usa-button:disabled.usa-button--active:focus, .usa-button:disabled.usa-button--active.usa-focus, .usa-button[aria-disabled="true"].usa-button--hover:hover, .usa-button[aria-disabled="true"].usa-button--hover:active, .usa-button[aria-disabled="true"].usa-button--hover:focus, .usa-button[aria-disabled="true"].usa-button--hover.usa-focus, .usa-button[aria-disabled="true"].usa-button--active:hover, .usa-button[aria-disabled="true"].usa-button--active:active, .usa-button[aria-disabled="true"].usa-button--active:focus, .usa-button[aria-disabled="true"].usa-button--active.usa-focus {
    color: graytext;
  }

  .usa-button:disabled:not(.usa-button--unstyled), .usa-button[aria-disabled="true"]:not(.usa-button--unstyled) {
    border: 2px solid graytext;
  }
}

@media (forced-colors: active) {
  .usa-button:not(.usa-button--unstyled) {
    border: 2px solid #0000;
  }
}

.usa-button--accent-cool, .usa-button--accent-cool:visited {
  color: #1c1d1f;
  background-color: #00bde3;
}

.usa-button--accent-warm, .usa-button--accent-warm:visited {
  color: #1c1d1f;
  background-color: #fa9441;
}

@media (forced-colors: active) {
  .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"], .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"]:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"]:focus {
    color: graytext;
  }
}

@media (forced-colors: active) {
  .usa-button--outline:disabled.usa-button--inverse, .usa-button--outline[aria-disabled="true"].usa-button--inverse {
    color: graytext;
  }
}

@media (forced-colors: active) {
  .usa-button--unstyled:disabled, .usa-button--unstyled[aria-disabled="true"], .usa-button--unstyled:disabled:hover, .usa-button--unstyled[aria-disabled="true"]:hover, .usa-button--unstyled[aria-disabled="true"]:focus {
    color: graytext;
  }
}

.usa-card-group, .usa-prose .usa-card-group {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

@media (width >= 40em) {
  .usa-card-group {
    flex-direction: row;
  }
}

@media (width >= 40em) {
  .usa-card, .usa-card:last-child {
    margin-bottom: 2rem;
  }
}

@media (width >= 40em) {
  .usa-prose .usa-card, .usa-prose .usa-card:last-child {
    margin-bottom: 2rem;
  }
}

@media (width >= 40em) {
  .usa-card--flag .usa-card__media {
    width: 15rem;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  .usa-card--flag .usa-card__img {
    border-radius: calc(.5rem - 2px) 0 0 calc(.5rem - 2px);
  }

  .usa-card--flag .usa-card__header, .usa-card--flag .usa-card__body, .usa-card--flag .usa-card__footer {
    margin-left: 15rem;
  }

  .usa-card--flag .usa-card__media--exdent {
    margin: -2px;
  }

  .usa-card--flag .usa-card__media--exdent .usa-card__img {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .usa-card--flag .usa-card__media--inset {
    padding-bottom: 1.5rem;
    padding-right: 0;
  }

  .usa-card--flag .usa-card__media--inset .usa-card__img {
    border-radius: 0;
  }

  .usa-card--flag.usa-card--header-first .usa-card__header {
    padding-bottom: .5rem;
  }

  .usa-card--flag.usa-card--header-first .usa-card__body {
    padding-top: .5rem;
  }

  .usa-card--flag.usa-card--header-first .usa-card__media--inset {
    padding-top: 1.5rem;
  }

  .usa-card--flag.usa-card--media-right .usa-card__media {
    left: auto;
    right: 0;
  }

  .usa-card--flag.usa-card--media-right .usa-card__media--inset {
    padding-left: 0;
    padding-right: 1.5rem;
  }

  .usa-card--flag.usa-card--media-right .usa-card__media--inset .usa-card__img {
    border-radius: 0;
  }

  .usa-card--flag.usa-card--media-right .usa-card__header, .usa-card--flag.usa-card--media-right .usa-card__body, .usa-card--flag.usa-card--media-right .usa-card__footer {
    margin-left: 0;
    margin-right: 15rem;
  }

  .usa-card--flag.usa-card--media-right .usa-card__img {
    border-radius: 0 .5rem .5rem 0;
  }
}

@supports (aspect-ratio: 1) {
  .usa-card:not(.usa-card--flag).usa-card__media--set-aspect {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1.77778;
    max-width: 100%;
  }

  .usa-card:not(.usa-card--flag).usa-card__media--set-aspect > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  img.usa-card:not(.usa-card--flag).usa-card__media--set-aspect, .usa-card:not(.usa-card--flag).usa-card__media--set-aspect > img {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@media (width >= 30em) {
  .usa-button-group {
    flex-flow: row;
    align-items: stretch;
  }
}

@media (width >= 30em) {
  .usa-button-group .usa-button-group .usa-button-group__item {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (width >= 30em) {
  .usa-button-group__item:last-child {
    margin-right: 0;
  }
}

@media (width >= 30em) {
  .usa-button-group--segmented {
    justify-content: flex-start;
  }
}

@media (width >= 30em) {
  .usa-button-group--segmented .usa-button {
    width: auto;
  }
}

@media (width >= 30em) {
  .usa-button-group--segmented .usa-button-group__item {
    width: auto;
  }
}

@media (width >= 30em) {
  .usa-button-group--segmented .usa-button-group__item:last-child > .usa-button {
    width: auto;
    margin-left: -1px;
  }
}

@media (width >= 40em) {
  .usa-modal--lg .usa-modal__heading {
    font-family: var(--base-font-family), ;
    font-size: 1.99rem;
  }
}

@media (forced-colors: active) {
  .usa-input:disabled, .usa-input[aria-disabled="true"] {
    color: graytext;
    border: 0;
  }

  .usa-input:disabled:hover, .usa-input:disabled:active, .usa-input:disabled:focus, .usa-input:disabled.usa-focus, .usa-input[aria-disabled="true"]:hover, .usa-input[aria-disabled="true"]:active, .usa-input[aria-disabled="true"]:focus, .usa-input[aria-disabled="true"].usa-focus {
    color: graytext;
  }

  .usa-input:disabled, .usa-input[aria-disabled="true"] {
    border: 2px solid graytext;
  }
}

@media (width >= 30em) {
  .usa-form {
    max-width: 20rem;
  }
}

@media (width >= 30em) {
  .usa-form .usa-button {
    margin-top: 1.5rem;
  }
}

@media (width >= 30em) {
  .usa-form--large {
    max-width: 30rem;
  }
}

@media (forced-colors: active) {
  .usa-show-password:disabled, .usa-show-password[aria-disabled="true"], .usa-show-password:disabled:hover, .usa-show-password[aria-disabled="true"]:hover, .usa-show-password[aria-disabled="true"]:focus {
    color: graytext;
  }
}

@media (width >= 64em) {
  .usa-footer > .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .usa-footer__nav {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .usa-footer__nav {
    border-bottom: none;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .usa-footer__nav {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .usa-footer__primary-section > .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .usa-footer__primary-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .usa-footer__primary-link {
    padding-left: 0;
    padding-right: 0;
  }
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-footer__primary-link--button:before {
    background: currentColor;
    -webkit-mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 1.25rem 1.25rem;
    mask-size: 1.25rem 1.25rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

@media (forced-colors: active) {
  .usa-footer__primary-link--button:before {
    background-color: buttontext !important;
  }
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-footer__primary-link--button[aria-expanded="false"]:before {
    background: currentColor;
    -webkit-mask-image: url("./img/usa-icons/navigate_next.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/navigate_next.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 1.25rem 1.25rem;
    mask-size: 1.25rem 1.25rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

@media (width >= 30em) {
  .usa-footer__secondary-link {
    margin-left: 0;
  }
}

@media (width >= 30em) {
  .usa-footer__contact-info {
    justify-content: flex-end;
    margin-top: .5rem;
  }
}

@media (width >= 30em) {
  .usa-footer__primary-content {
    border: none;
  }
}

@media (width >= 64em) {
  .usa-footer__secondary-section > .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .usa-footer__logo {
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (width >= 30em) {
  .usa-footer__contact-links {
    text-align: right;
    margin-top: 0;
  }
}

@media (width >= 30em) {
  .usa-footer__contact-heading {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }
}

@media (width >= 30em) {
  .usa-footer__social-links {
    justify-content: flex-end;
  }
}

@media (forced-colors: active) {
  .usa-social-link {
    forced-color-adjust: none;
    background-color: #d3d3d3;
  }
}

@media (width >= 30em) {
  .usa-footer__address {
    justify-content: flex-end;
  }
}

@media (width >= 64em) {
  .usa-footer--slim .usa-footer__nav {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 30em) {
  .usa-footer--slim .usa-footer__address {
    padding: 0;
  }
}

@media (width >= 30em) {
  .usa-footer--slim .usa-footer__contact-info {
    margin-top: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (width >= 30em) {
  .usa-footer--big .usa-footer__nav {
    border-bottom: 1px solid #a9aeb1;
    padding-top: 2rem;
  }
}

@media (width >= 40em) {
  .usa-footer--big .usa-footer__nav {
    border-bottom: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 30em) {
  .usa-footer--big .usa-footer__primary-link {
    margin-bottom: .5rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  .usa-footer--big .usa-footer__primary-link:hover {
    cursor: auto;
    text-decoration: none;
  }
}

@media (width >= 30em) {
  .usa-footer--big .usa-footer__primary-content--collapsible .usa-list--unstyled {
    padding: .75rem 0 2rem;
  }
}

@media (width >= 30em) {
  .usa-search [type="submit"] {
    width: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (forced-colors: active) {
  .usa-search [type="submit"]:before {
    content: "";
    vertical-align: middle;
    background-image: url("./img/usa-icons/search.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.5rem 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: auto;
    display: inline-block;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-search [type="submit"]:before {
      background: buttontext;
      -webkit-mask-image: url("./img/usa-icons/search.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/search.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1.5rem 1.5rem;
      mask-size: 1.5rem 1.5rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }

  .usa-search [type="submit"]:focus {
    outline-offset: 0;
  }
}

@media (forced-colors: active) and (width >= 30em) {
  .usa-search [type="submit"]:before {
    content: none;
  }
}

@media (width >= 30em) {
  .usa-search__submit-icon {
    display: none;
  }
}

@media (forced-colors: active) {
  .usa-search__submit-icon {
    display: none;
  }
}

@media (width >= 30em) {
  .usa-search--big [type="search"], .usa-search--big .usa-search__input {
    height: 3rem;
    font-size: .99rem;
  }

  .usa-search--big [type="submit"], .usa-search--big .usa-search__submit {
    width: auto;
    height: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.37rem;
  }
}

@media (forced-colors: active) and (width >= 30em) {
  .usa-search--small [type="submit"]:before {
    content: "";
  }
}

@media (forced-colors: active) {
  .usa-search--small .usa-search__submit-icon {
    display: none;
  }
}

@media (width >= 30em) {
  .usa-search__submit-text {
    display: block;
  }
}

@media (forced-colors: active) {
  .usa-accordion__button:disabled, .usa-accordion__button[aria-disabled="true"], .usa-accordion__button:disabled:hover, .usa-accordion__button[aria-disabled="true"]:hover, .usa-accordion__button[aria-disabled="true"]:focus {
    color: graytext;
  }
}

@media (forced-colors: active) {
  .usa-accordion__button {
    border: 2px solid #0000;
    position: relative;
  }

  .usa-accordion__button:before {
    content: "";
    background-image: url("./img/usa-icons/remove.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.5rem 1.5rem;
    width: 1.5rem;
    height: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 1.25rem;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-accordion__button:before {
      background: buttontext;
      -webkit-mask-image: url("./img/usa-icons/remove.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/remove.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1.5rem 1.5rem;
      mask-size: 1.5rem 1.5rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }
}

@media (forced-colors: active) {
  .usa-accordion__button[aria-expanded="false"]:before {
    background-image: url("./img/usa-icons/add.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.5rem 1.5rem;
    width: 1.5rem;
    height: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 1.25rem;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-accordion__button[aria-expanded="false"]:before {
      background: buttontext;
      -webkit-mask-image: url("./img/usa-icons/add.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/add.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1.5rem 1.5rem;
      mask-size: 1.5rem 1.5rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }
}

@keyframes slidein-left {
  from {
    transform: translateX(15rem);
  }

  to {
    transform: translateX(0);
  }
}

@media (width <= 63.99em) {
  .usa-nav {
    z-index: 500;
    background: #fff;
    border-right: 0;
    flex-direction: column;
    width: 15rem;
    padding: 1rem;
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
  }

  .usa-nav.is-visible {
    animation: .3s ease-in-out slidein-left;
    display: flex;
  }
}

@media (width >= 64em) {
  .usa-nav {
    float: right;
    position: relative;
  }
}

@media (width >= 64em) {
  .usa-nav .usa-search {
    margin-top: 0;
    margin-left: 1rem;
  }
}

@media (width <= 63.99em) {
  .usa-nav__primary {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
  }

  .usa-nav__primary > li {
    max-width: unset;
    margin-bottom: 0;
  }

  .usa-nav__primary-item {
    border-top: 1px solid #dfe1e2;
  }

  .usa-nav__primary a:not(.usa-button) {
    padding: .5rem 1rem;
    text-decoration: none;
    display: block;
  }

  .usa-nav__primary a:not(.usa-button):hover {
    background-color: #f0f0f0;
    text-decoration: none;
  }

  .usa-nav__primary a:not(.usa-button):not(.usa-current) {
    color: #565c65;
  }

  .usa-nav__primary a:not(.usa-button):not(.usa-current):hover {
    color: #005ea2;
  }

  .usa-nav__primary a:not(.usa-button):not(.usa-current):focus {
    outline-offset: 0;
  }

  .usa-nav__primary .usa-current {
    color: #005ea2;
    font-weight: 700;
    position: relative;
  }

  .usa-nav__primary .usa-current:after {
    content: "";
    background-color: #005ea2;
    border-radius: 99rem;
    width: .25rem;
    display: block;
    position: absolute;
    top: .25rem;
    bottom: .25rem;
    left: .25rem;
  }
}

@media (width <= 63.99em) and (width >= 40em) {
  .usa-nav__primary .usa-current {
    position: relative;
  }

  .usa-nav__primary .usa-current:after {
    content: "";
    background-color: #005ea2;
    border-radius: 99rem;
    width: .25rem;
    display: block;
    position: absolute;
    top: .25rem;
    bottom: .25rem;
    left: 0;
  }
}

@media (width <= 63.99em) {
  .usa-nav__primary a {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }
}

@media (width >= 64em) {
  .usa-nav__primary {
    align-items: stretch;
    display: flex;
  }
}

@media (width >= 64em) {
  .usa-nav__primary > .usa-nav__primary-item {
    font-size: .87rem;
    line-height: 1;
  }

  .usa-nav__primary > .usa-nav__primary-item > a {
    color: #565c65;
    align-items: center;
    padding: 1rem;
    font-weight: 700;
    line-height: 1;
    display: flex;
  }

  .usa-nav__primary > .usa-nav__primary-item > a:hover {
    color: #005ea2;
  }

  .usa-nav__primary > .usa-nav__primary-item > button, .usa-nav__primary > .usa-nav__primary-item > a {
    height: 100%;
  }

  .usa-nav__primary a {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}

@media (forced-colors: active) {
  .usa-nav__primary button:disabled, .usa-nav__primary button[aria-disabled="true"], .usa-nav__primary button:disabled:hover, .usa-nav__primary button[aria-disabled="true"]:hover, .usa-nav__primary button[aria-disabled="true"]:focus {
    color: graytext;
  }
}

@media (width >= 64em) {
  .usa-nav__primary button {
    padding: 1rem;
    font-size: .87rem;
    font-weight: 700;
    line-height: 1;
  }
}

@media (forced-colors: active) {
  .usa-nav__primary button {
    forced-color-adjust: auto;
  }
}

@media (width >= 64em) {
  .usa-nav__primary button:hover {
    background-color: #0000;
  }
}

@media (forced-colors: active) {
  .usa-nav__primary button[aria-expanded]:before, .usa-nav__primary button[aria-expanded]:hover:before {
    content: none;
  }
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-nav__primary button[aria-expanded="false"] span:after {
    background: buttontext;
    -webkit-mask-image: url("./img/usa-icons/add.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/add.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 1.25rem 1.25rem;
    mask-size: 1.25rem 1.25rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

@media (width >= 64em) {
  .usa-nav__primary button[aria-expanded="false"] span:after {
    content: "";
    vertical-align: middle;
    background-image: url("./img/usa-icons/expand_more.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    display: inline-block;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-nav__primary button[aria-expanded="false"] span:after {
      background: buttontext;
      -webkit-mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1rem 1rem;
      mask-size: 1rem 1rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }

  .usa-nav__primary button[aria-expanded="false"] span:after {
    right: .75rem;
  }

  .usa-nav__primary button[aria-expanded="false"]:hover span:after {
    background-color: #1a4480;
  }
}

@media (width >= 64em) and (forced-colors: active) {
  .usa-nav__primary button[aria-expanded="false"]:hover span:after {
    background-color: buttontext;
  }
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-nav__primary button[aria-expanded="true"] span:after {
    background: buttontext;
    -webkit-mask-image: url("./img/usa-icons/remove.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/remove.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 1.25rem 1.25rem;
    mask-size: 1.25rem 1.25rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

@media (forced-colors: active) {
  .usa-nav__primary button[aria-expanded="true"] span:after {
    background-color: buttontext;
  }
}

@media (width >= 64em) {
  .usa-nav__primary button[aria-expanded="true"] {
    color: #fff;
    background-color: #162e51;
    background-image: none;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .usa-nav__primary button[aria-expanded="true"] span:after {
    content: "";
    vertical-align: middle;
    background-image: url("./img/usa-icons/expand_less.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    display: inline-block;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-nav__primary button[aria-expanded="true"] span:after {
      background: buttontext;
      -webkit-mask-image: url("./img/usa-icons/expand_less.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/expand_less.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1rem 1rem;
      mask-size: 1rem 1rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }

  .usa-nav__primary button[aria-expanded="true"] span:after {
    background-color: #fff;
    right: .75rem;
  }
}

@media (width >= 64em) and (width >= 64em) and (forced-colors: active) {
  .usa-nav__primary button[aria-expanded="true"] span:after {
    background-color: buttontext;
  }
}

@media (width >= 64em) {
  .usa-nav__primary .usa-accordion__button span {
    margin-right: 0;
    padding-right: 1rem;
    display: inline-block;
  }
}

@media (width >= 64em) {
  .usa-nav__secondary {
    flex-direction: column;
    align-items: flex-end;
    min-width: calc(27ch + 3rem);
    margin-top: .5rem;
    font-size: .87rem;
    display: flex;
    position: absolute;
    bottom: 4rem;
    right: 2rem;
  }
}

@media (width >= 64em) {
  .usa-nav__secondary .usa-search {
    margin-top: .5rem;
    margin-left: 0;
  }
}

@media (width >= 64em) {
  .usa-nav__secondary-links {
    -moz-column-gap: .5rem;
    flex-flow: row;
    column-gap: .5rem;
    margin-top: 0;
    margin-bottom: .25rem;
    line-height: 1;
    display: flex;
  }

  .usa-nav__secondary-links .usa-nav__secondary-item {
    padding-left: .25rem;
  }

  .usa-nav__secondary-links .usa-nav__secondary-item + .usa-nav__secondary-item {
    border-left: 1px solid #dfe1e2;
    padding-left: .5rem;
  }
}

@media (width <= 63.99em) {
  .usa-nav__submenu {
    margin: 0;
    padding-left: 0;
    list-style-type: none;
  }

  .usa-nav__submenu > li {
    max-width: unset;
    margin-bottom: 0;
  }

  .usa-nav__submenu-item {
    border-top: 1px solid #dfe1e2;
    font-size: .87rem;
  }

  .usa-nav__submenu .usa-current:after {
    display: none;
  }
}

@media (width <= 63.99em) and (width >= 40em) {
  .usa-nav__submenu .usa-current:after {
    display: none;
  }
}

@media (width <= 63.99em) {
  .usa-nav__submenu a:not(.usa-button) {
    padding-left: 2rem;
  }

  .usa-nav__submenu .usa-nav__submenu a:not(.usa-button) {
    padding-left: 3rem;
  }

  .usa-nav__submenu .usa-nav__submenu .usa-nav__submenu a:not(.usa-button) {
    padding-left: 4rem;
  }
}

@media (width >= 64em) {
  .usa-nav__submenu {
    z-index: 400;
    background-color: #162e51;
    width: 15rem;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 0;
    list-style-type: none;
    position: absolute;
  }
}

@media (width >= 64em) {
  .usa-nav__submenu .usa-nav__submenu-item a {
    color: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 1.4;
    display: block;
  }

  .usa-nav__submenu .usa-nav__submenu-item a:focus {
    outline-offset: -.25rem;
  }

  .usa-nav__submenu .usa-nav__submenu-item a:hover {
    color: #fff;
    text-decoration: underline;
  }
}

@media (forced-colors: active) {
  .usa-nav__close:disabled, .usa-nav__close[aria-disabled="true"], .usa-nav__close:disabled:hover, .usa-nav__close[aria-disabled="true"]:hover, .usa-nav__close[aria-disabled="true"]:focus {
    color: graytext;
  }

  .usa-nav__close:before {
    content: "";
    vertical-align: middle;
    background-image: url("./img/usa-icons/close.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.25rem 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: auto;
    display: inline-block;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-nav__close:before {
      background: buttontext;
      -webkit-mask-image: url("./img/usa-icons/close.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/close.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1.25rem 1.25rem;
      mask-size: 1.25rem 1.25rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }

  .usa-nav__close:before {
    background-color: buttontext;
  }
}

@media (width >= 64em) {
  .usa-nav__close {
    display: none;
  }
}

@media (forced-colors: active) {
  .usa-nav__close img {
    display: none;
  }
}

@media (width >= 63.06rem) {
  .usa-js-mobile-nav--active.is-safari {
    top: var(--scrolltop, 0);
    position: fixed;
    overflow-y: scroll;
  }
}

@media (width >= 64em) {
  .usa-header .usa-search {
    float: right;
  }

  .usa-header [role="search"] {
    float: right;
    width: 100%;
    max-width: calc(27ch + 3rem);
  }
}

@media (width >= 64em) {
  .usa-header + .usa-hero {
    border-top: 1px solid #fff;
  }

  .usa-header + .usa-section, .usa-header + main {
    border-top: 1px solid #dfe1e2;
  }
}

@media (width <= 63.99em) {
  .usa-logo {
    flex: 1;
    margin-left: 1rem;
    font-size: .87rem;
    line-height: 1;
  }
}

@media (width >= 64em) {
  .usa-logo {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.37rem;
    line-height: 1.2;
  }
}

@media (forced-colors: active) {
  .usa-menu-btn:disabled, .usa-menu-btn[aria-disabled="true"], .usa-menu-btn:disabled:hover, .usa-menu-btn[aria-disabled="true"]:hover, .usa-menu-btn[aria-disabled="true"]:focus {
    color: graytext;
  }
}

@media (width >= 64em) {
  .usa-menu-btn {
    display: none;
  }
}

.usa-menu-btn:active, .usa-menu-btn:visited {
  color: #fff;
}

@media (width >= 64em) {
  .usa-header--basic .usa-navbar {
    width: 33%;
    position: relative;
  }

  .usa-header--basic .usa-nav {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 0 0 .5rem .5rem;
    display: flex;
  }

  .usa-header--basic .usa-nav-container {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
  }

  .usa-header--basic .usa-nav__primary-item > .usa-current, .usa-header--basic .usa-nav__link:hover {
    position: relative;
  }

  .usa-header--basic .usa-nav__primary-item > .usa-current:after, .usa-header--basic .usa-nav__link:hover:after {
    content: "";
    background-color: #005ea2;
    border-radius: 0;
    height: .25rem;
    display: block;
    position: absolute;
    bottom: -.25rem;
    left: 1rem;
    right: 1rem;
  }
}

@media (width >= 64em) and (forced-colors: active) {
  .usa-header--basic .usa-nav__primary-item > .usa-current:after, .usa-header--basic .usa-nav__link:hover:after {
    background-color: buttontext;
  }
}

@media (width >= 64em) {
  .usa-header--basic .usa-nav__link[aria-expanded="true"]:after, .usa-header--basic .usa-nav__link[aria-expanded="true"]:hover:after {
    display: none;
  }

  .usa-header--basic .usa-nav__primary {
    width: auto;
  }

  .usa-header--basic .usa-nav__primary-item:last-of-type {
    position: relative;
  }

  .usa-header--basic .usa-nav__primary-item:last-of-type .usa-nav__submenu {
    position: absolute;
    right: 0;
  }

  .usa-header--basic .usa-search {
    top: 0;
  }
}

@media (width >= 64em) {
  .usa-header--basic.usa-header--megamenu .usa-nav__inner {
    float: right;
    margin-top: -2.5rem;
    display: block;
  }

  .usa-header--basic.usa-header--megamenu .usa-nav__primary-item:last-of-type {
    position: static;
  }

  .usa-header--extended {
    padding-top: 0;
  }

  .usa-header--extended .usa-nav__primary-item > .usa-current, .usa-header--extended .usa-nav__primary-item > .usa-nav__link:hover {
    position: relative;
  }

  .usa-header--extended .usa-nav__primary-item > .usa-current:after, .usa-header--extended .usa-nav__primary-item > .usa-nav__link:hover:after {
    content: "";
    background-color: #005ea2;
    border-radius: 0;
    height: .25rem;
    display: block;
    position: absolute;
    bottom: 0;
    left: 1rem;
    right: 1rem;
  }
}

@media (width >= 64em) and (forced-colors: active) {
  .usa-header--extended .usa-nav__primary-item > .usa-current:after, .usa-header--extended .usa-nav__primary-item > .usa-nav__link:hover:after {
    background-color: buttontext;
  }
}

@media (width >= 64em) {
  .usa-header--extended .usa-nav__link[aria-expanded="true"]:after, .usa-header--extended .usa-nav__link[aria-expanded="true"]:hover:after {
    display: none;
  }

  .usa-header--extended .usa-logo {
    max-width: 33%;
    margin: 2rem 0 1.5rem;
    font-size: 1.99rem;
  }

  .usa-header--extended .usa-navbar {
    max-width: 64rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    display: block;
    overflow: auto;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .usa-header--extended .usa-navbar {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .usa-header--extended .usa-nav {
    border-top: 1px solid #dfe1e2;
    width: 100%;
    padding: 0;
  }

  .usa-header--extended .usa-nav__inner {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .usa-header--extended .usa-nav__inner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .usa-header--extended .usa-nav__primary {
    margin-left: -1rem;
  }

  .usa-header--extended .usa-nav__primary:after {
    clear: both;
    content: "";
    display: block;
  }

  .usa-header--extended .usa-nav__link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .usa-header--extended .usa-nav__submenu .usa-grid-full {
    padding-left: .75rem;
  }

  .usa-header--extended .usa-nav__submenu.usa-megamenu {
    padding-left: 2rem;
    padding-right: 2rem;
    left: 0;
  }
}

@media (width >= 64em) {
  .usa-megamenu .usa-col {
    flex: 4;
  }

  .usa-megamenu .usa-col .usa-nav__submenu-item a {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .usa-megamenu .usa-col:first-child .usa-nav__submenu-item a {
    padding-left: 0;
  }

  .usa-megamenu .usa-col:last-child .usa-nav__submenu-item a {
    padding-right: 0;
  }

  .usa-megamenu.usa-nav__submenu {
    width: auto;
    padding: 2rem 0;
    left: -33%;
    right: 0;
  }

  .usa-megamenu.usa-nav__submenu:before {
    content: "";
    background-color: #162e51;
    width: calc(50vw - 30rem);
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
  }

  .usa-megamenu.usa-nav__submenu:after {
    content: "";
    background-color: #162e51;
    width: calc(50vw - 30rem);
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
  }

  .usa-header--extended .usa-megamenu.usa-nav__submenu:before, .usa-header--extended .usa-megamenu.usa-nav__submenu:after {
    content: "";
    background-color: #162e51;
    width: calc(50vw - 32rem);
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .usa-nav-container {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .usa-nav-container:after {
    clear: both;
    content: "";
    display: block;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .usa-nav-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width <= 63.99em) {
  .usa-navbar {
    border-bottom: 1px solid #dfe1e2;
    align-items: center;
    display: flex;
  }
}

@media (width >= 64em) {
  .usa-navbar {
    border-bottom: none;
    height: auto;
    display: inline-block;
  }
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--success .usa-alert__body:before {
    background: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/check_circle.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/check_circle.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--warning .usa-alert__body:before {
    background: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/warning.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/warning.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--error .usa-alert__body:before {
    background: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/error.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/error.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--info .usa-alert__body:before {
    background: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/info.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/info.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--emergency .usa-alert__body:before {
    background: #fff;
    -webkit-mask-image: url("./img/usa-icons/error.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/error.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.usa-alert--emergency .usa-alert__body .usa-link, .usa-alert--emergency .usa-alert__body .usa-link:visited {
  color: #dfe1e2;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--slim .usa-alert__body:before {
    -webkit-mask-size: 1.5rem;
    mask-size: 1.5rem;
  }
}

@media (width >= 64em) {
  .usa-site-alert .usa-alert .usa-alert__body {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .usa-site-alert .usa-alert .usa-alert__body:before {
    left: 1.58333rem;
  }
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-site-alert--info .usa-alert .usa-alert__body:before {
    background: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/info.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/info.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-site-alert--emergency .usa-alert .usa-alert__body:before {
    background: #fff;
    -webkit-mask-image: url("./img/usa-icons/error.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/error.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.usa-site-alert--emergency .usa-alert .usa-alert__body .usa-link, .usa-site-alert--emergency .usa-alert .usa-alert__body .usa-link:visited {
  color: #dfe1e2;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-site-alert--slim .usa-alert .usa-alert__body:before {
    -webkit-mask-size: 1.5rem;
    mask-size: 1.5rem;
  }
}

@media (width >= 64em) {
  .usa-site-alert--slim .usa-alert .usa-alert__body:before {
    left: 1.625rem;
  }
}

@media (width >= 40em) {
  .usa-banner {
    padding-bottom: 0;
    font-size: .81rem;
  }
}

@media (width >= 40em) {
  .usa-banner .grid-row .tablet\:grid-col-6 {
    flex: 0 auto;
    width: 50%;
  }
}

@media (width >= 64em) {
  .usa-banner__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .usa-banner__content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media (width >= 40em) {
  .usa-banner__guidance {
    padding-top: 0;
  }
}

@media (forced-colors: active) {
  .usa-banner__lock-image path {
    fill: canvastext;
  }
}

@media (width >= 64em) {
  .usa-banner__inner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .usa-banner__inner {
    align-items: center;
  }
}

@media (width >= 40em) {
  .usa-banner__header {
    min-height: 0;
    padding-top: .25rem;
    padding-bottom: .25rem;
  }
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-banner__header-action:after {
    background: #005ea2;
    -webkit-mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 1rem 1rem;
    mask-size: 1rem 1rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }

  .usa-banner__header-action:after:hover {
    background-color: #1a4480;
  }
}

@media (width >= 40em) {
  .usa-banner__header-action {
    display: none;
  }
}

@media (forced-colors: active) {
  .usa-banner__header-action {
    color: linktext;
  }

  .usa-banner__header-action:after {
    background-color: buttontext;
  }
}

@media (width >= 40em) {
  .usa-banner__header-flag {
    margin-right: .5rem;
    padding-top: 0;
  }
}

@media (width >= 40em) {
  .usa-banner__header--expanded {
    background-color: #0000;
    min-height: 0;
    padding-right: 0;
    font-size: .75rem;
    font-weight: normal;
    display: block;
  }
}

@media (width >= 40em) {
  .usa-banner__header--expanded .usa-banner__inner {
    margin-left: auto;
  }
}

@media (forced-colors: active) {
  .usa-banner__button:disabled, .usa-banner__button[aria-disabled="true"], .usa-banner__button:disabled:hover, .usa-banner__button[aria-disabled="true"]:hover, .usa-banner__button[aria-disabled="true"]:focus {
    color: graytext;
  }
}

@media (width <= 39.99em) {
  .usa-banner__button {
    width: 100%;
  }

  .usa-banner__button:enabled:focus {
    outline-offset: -.25rem;
  }
}

@media (width >= 40em) {
  .usa-banner__button {
    color: #005ea2;
    margin-left: .5rem;
    display: inline;
    position: relative;
    inset: auto;
  }

  .usa-banner__button:after {
    content: "";
    vertical-align: middle;
    background-image: url("./img/usa-icons/expand_more.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-left: 2px;
    display: inline-block;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-banner__button:after {
      background: #005ea2;
      -webkit-mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1rem 1rem;
      mask-size: 1rem 1rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }

    .usa-banner__button:after:hover {
      background-color: #1a4480;
    }
  }

  .usa-banner__button:hover:after {
    content: "";
    background-color: #1a4480;
  }

  .usa-banner__button:visited {
    color: #54278f;
  }

  .usa-banner__button:hover, .usa-banner__button:active {
    color: #1a4480;
  }

  .usa-banner__button:after, .usa-banner__button:hover:after {
    position: absolute;
  }
}

@media (width >= 40em) and (forced-colors: active) {
  .usa-banner__button:after, .usa-banner__button:hover:after {
    background-color: buttontext;
  }
}

@media (width >= 40em) {
  .usa-banner__button:hover {
    text-decoration: none;
  }
}

@media (forced-colors: active) {
  .usa-banner__button[aria-expanded="false"]:before, .usa-banner__button[aria-expanded="false"]:hover:before, .usa-banner__button[aria-expanded="true"]:before, .usa-banner__button[aria-expanded="true"]:hover:before {
    content: none;
  }
}

@media (width <= 39.99em) {
  .usa-banner__button[aria-expanded="true"]:after {
    content: "";
    vertical-align: middle;
    background-image: url("./img/usa-icons/close.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.5rem 1.5rem;
    width: 3rem;
    height: 3rem;
    margin-left: 0;
    display: inline-block;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-banner__button[aria-expanded="true"]:after {
      background: #005ea2;
      -webkit-mask-image: url("./img/usa-icons/close.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/close.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1.5rem 1.5rem;
      mask-size: 1.5rem 1.5rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }

  .usa-banner__button[aria-expanded="true"]:before {
    content: "";
    background-color: #dfe1e2;
    width: 3rem;
    height: 3rem;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .usa-banner__button[aria-expanded="true"]:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

@media (width >= 40em) {
  .usa-banner__button[aria-expanded="true"] {
    height: auto;
    padding: 0;
    position: relative;
  }

  .usa-banner__button[aria-expanded="true"]:after {
    content: "";
    vertical-align: middle;
    background-image: url("./img/usa-icons/expand_less.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-left: 2px;
    display: inline-block;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-banner__button[aria-expanded="true"]:after {
      background: #005ea2;
      -webkit-mask-image: url("./img/usa-icons/expand_less.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/expand_less.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1rem 1rem;
      mask-size: 1rem 1rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }

    .usa-banner__button[aria-expanded="true"]:after:hover {
      background-color: #1a4480;
    }
  }

  .usa-banner__button[aria-expanded="true"]:hover:after {
    content: "";
    background-color: #1a4480;
  }

  .usa-banner__button[aria-expanded="true"]:after, .usa-banner__button[aria-expanded="true"]:hover:after {
    position: absolute;
  }
}

@media (width >= 40em) and (forced-colors: active) {
  .usa-banner__button[aria-expanded="true"]:after, .usa-banner__button[aria-expanded="true"]:hover:after {
    background-color: buttontext;
  }
}

@media (width >= 40em) {
  .usa-banner__button-text {
    display: inline;
    position: static;
  }
}

@media (forced-colors: active) {
  .usa-banner__button-text {
    color: linktext;
  }
}

@media (width >= 64rem) {
  .usa-nav__secondary-links {
    margin-bottom: 1.5rem;
  }

  .usa-nav__secondary-item .usa-nav__link {
    color: #565c65;
    padding: 0;
  }

  .usa-nav__primary button[aria-expanded="false"] span:after {
    background-color: #565c65;
  }
}

@media (width <= 64rem) {
  .usa-nav__primary button {
    font-weight: 300;
  }

  .usa-nav__primary button[aria-expanded="false"] span:after, .usa-nav__primary button[aria-expanded="true"] span:after {
    display: none;
  }

  .usa-nav__primary button[aria-expanded="true"] {
    font-weight: 700;
  }

  .usa-nav__primary button[aria-expanded="true"]:after {
    content: "";
    background-color: #005ea2;
    width: .25rem;
    display: block;
    position: absolute;
    top: .25rem;
    bottom: .25rem;
    left: .25rem;
  }

  .usa-nav__primary button:hover {
    background-color: #fff;
  }

  .usa-nav__submenu-item {
    font-size: .99rem;
  }
}

@media (width <= 40rem) {
  .return-to-top-container {
    padding: 1.5rem;
  }
}

@media (width <= 40rem) {
  .footer_primary_container {
    padding: 1.5rem;
  }

  .footer_primary_container .grid-row.grid-gap {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .footer_primary_container .grid-row.grid-gap > li {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width <= 40rem) {
  .usa-footer__address > .grid-row {
    justify-content: flex-start;
  }

  .usa-footer__address {
    padding: 2rem 0 !important;
  }
}

@media (width <= 40rem) {
  #footer_secondary_container {
    flex-direction: column;
  }
}

@media (width <= 40rem) {
  .usa-footer__contact-info {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media (width <= 64rem) {
  .usa-footer__address .mobile-lg\:grid-col-12, .usa-footer__nav .mobile-lg\:grid-col-6 {
    width: auto;
  }
}

@media (width <= 40rem) {
  .usa-footer__address .grid-row [class*="grid-col"] {
    width: auto !important;
  }

  .usa-footer .grid-row [class*="grid-col"] {
    width: 100%;
  }

  .usa-footer__primary-content {
    border-top: 1px solid #a9aeb1;
  }

  .usa-footer__primary-content:last-child {
    border-bottom: 1px solid #a9aeb1;
  }
}

@media (width <= 40rem) and (width <= 30rem) {
  .usa-footer__primary-content:last-child {
    border-bottom: 0;
  }
}

.usa-input {
  font-family: var(--base-font-family), ;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #1c1d1f;
  border: 1px solid #565c65;
  border-radius: 0;
  width: 100%;
  max-width: 30rem;
  height: 2.5rem;
  margin-top: .5rem;
  padding: .5rem;
  font-size: .99rem;
  line-height: 1.4;
  display: block;
}

[class*="font-mono-"] {
  font-family: Roboto Mono Web, Bitstream Vera Sans Mono, Consolas, Courier, monospace !important;
}

[class*="font-sans-"], [class*="font-serif-"], [class*="font-ui-"], [class*="font-heading-"], [class*="font-body-"] {
  font-family: var(--base-font-family),  !important;
}

[class*="font-code-"] {
  font-family: Roboto Mono Web, Bitstream Vera Sans Mono, Consolas, Courier, monospace !important;
}

[class*="font-alt-"] {
  font-family: var(--base-font-family),  !important;
}

.add-aspect-9x16 {
  box-sizing: border-box;
  height: 0;
  padding-bottom: 177.778%;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: 1) {
  .add-aspect-9x16 {
    height: inherit;
    padding: inherit;
    aspect-ratio: .5625;
    max-width: 100%;
  }

  .add-aspect-9x16 > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  img.add-aspect-9x16, .add-aspect-9x16 > img {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.add-aspect-16x9 {
  box-sizing: border-box;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: 1) {
  .add-aspect-16x9 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1.77778;
    max-width: 100%;
  }

  .add-aspect-16x9 > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  img.add-aspect-16x9, .add-aspect-16x9 > img {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.add-aspect-1x1 {
  box-sizing: border-box;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: 1) {
  .add-aspect-1x1 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1;
    max-width: 100%;
  }

  .add-aspect-1x1 > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  img.add-aspect-1x1, .add-aspect-1x1 > img {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.add-aspect-4x3 {
  box-sizing: border-box;
  height: 0;
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: 1) {
  .add-aspect-4x3 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1.33333;
    max-width: 100%;
  }

  .add-aspect-4x3 > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  img.add-aspect-4x3, .add-aspect-4x3 > img {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.add-aspect-2x1 {
  box-sizing: border-box;
  height: 0;
  padding-bottom: 50%;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: 1) {
  .add-aspect-2x1 {
    height: inherit;
    padding: inherit;
    aspect-ratio: 2;
    max-width: 100%;
  }

  .add-aspect-2x1 > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  img.add-aspect-2x1, .add-aspect-2x1 > img {
    -o-object-fit: cover;
    object-fit: cover;
  }

  :where(img[class*="add-aspect-"]) {
    width: min-content;
  }
}

.add-list-reset {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
  list-style: none !important;
}

.flex-align-start {
  align-items: flex-start !important;
}

.flex-align-end {
  align-items: flex-end !important;
}

.flex-align-center {
  align-items: center !important;
}

.flex-align-stretch {
  align-items: stretch !important;
}

.flex-align-baseline {
  align-items: baseline !important;
}

.flex-align-self-start {
  align-self: flex-start !important;
}

.flex-align-self-end {
  align-self: flex-end !important;
}

.flex-align-self-center {
  align-self: center !important;
}

.flex-align-self-stretch {
  align-self: stretch !important;
}

.flex-align-self-baseline {
  align-self: baseline !important;
}

.bg-transparent, .hover\:bg-transparent:hover {
  background-color: #0000 !important;
}

.bg-black, .hover\:bg-black:hover {
  background-color: #000 !important;
}

.bg-white, .hover\:bg-white:hover {
  background-color: #fff !important;
}

.bg-red, .hover\:bg-red:hover {
  background-color: #e52207 !important;
}

.bg-orange, .hover\:bg-orange:hover {
  background-color: #e66f0e !important;
}

.bg-gold, .hover\:bg-gold:hover {
  background-color: #ffbe2e !important;
}

.bg-yellow, .hover\:bg-yellow:hover {
  background-color: #fee685 !important;
}

.bg-green, .hover\:bg-green:hover {
  background-color: #538200 !important;
}

.bg-mint, .hover\:bg-mint:hover {
  background-color: #04c585 !important;
}

.bg-cyan, .hover\:bg-cyan:hover {
  background-color: #009ec1 !important;
}

.bg-blue, .hover\:bg-blue:hover {
  background-color: #0076d6 !important;
}

.bg-indigo, .hover\:bg-indigo:hover {
  background-color: #676cc8 !important;
}

.bg-violet, .hover\:bg-violet:hover {
  background-color: #8168b3 !important;
}

.bg-magenta, .hover\:bg-magenta:hover {
  background-color: #d72d79 !important;
}

.bg-gray-5, .hover\:bg-gray-5:hover {
  background-color: #f0f0f0 !important;
}

.bg-gray-10, .hover\:bg-gray-10:hover {
  background-color: #e6e6e6 !important;
}

.bg-gray-30, .hover\:bg-gray-30:hover {
  background-color: #adadad !important;
}

.bg-gray-50, .hover\:bg-gray-50:hover {
  background-color: #757575 !important;
}

.bg-gray-70, .hover\:bg-gray-70:hover {
  background-color: #454545 !important;
}

.bg-gray-90, .hover\:bg-gray-90:hover {
  background-color: #1b1b1b !important;
}

.bg-base-lightest, .hover\:bg-base-lightest:hover {
  background-color: #f0f0f0 !important;
}

.bg-base-lighter, .hover\:bg-base-lighter:hover {
  background-color: #dfe1e2 !important;
}

.bg-base-light, .hover\:bg-base-light:hover {
  background-color: #a9aeb1 !important;
}

.bg-base, .hover\:bg-base:hover {
  background-color: #71767a !important;
}

.bg-base-dark, .hover\:bg-base-dark:hover {
  background-color: #565c65 !important;
}

.bg-base-darker, .hover\:bg-base-darker:hover {
  background-color: #3d4551 !important;
}

.bg-base-darkest, .hover\:bg-base-darkest:hover {
  background-color: #2d2e2f !important;
}

.bg-ink, .hover\:bg-ink:hover {
  background-color: #1c1d1f !important;
}

.bg-primary-lighter, .hover\:bg-primary-lighter:hover {
  background-color: #d9e8f6 !important;
}

.bg-primary-light, .hover\:bg-primary-light:hover {
  background-color: #73b3e7 !important;
}

.bg-primary, .hover\:bg-primary:hover {
  background-color: #005ea2 !important;
}

.bg-primary-vivid, .hover\:bg-primary-vivid:hover {
  background-color: #0050d8 !important;
}

.bg-primary-dark, .hover\:bg-primary-dark:hover {
  background-color: #1a4480 !important;
}

.bg-primary-darker, .hover\:bg-primary-darker:hover {
  background-color: #162e51 !important;
}

.bg-secondary-lighter, .hover\:bg-secondary-lighter:hover {
  background-color: #f3e1e4 !important;
}

.bg-secondary-light, .hover\:bg-secondary-light:hover {
  background-color: #f2938c !important;
}

.bg-secondary, .hover\:bg-secondary:hover {
  background-color: #d83933 !important;
}

.bg-secondary-vivid, .hover\:bg-secondary-vivid:hover {
  background-color: #e41d3d !important;
}

.bg-secondary-dark, .hover\:bg-secondary-dark:hover {
  background-color: #b50909 !important;
}

.bg-secondary-darker, .hover\:bg-secondary-darker:hover {
  background-color: #8b0a03 !important;
}

.bg-accent-warm-darker, .hover\:bg-accent-warm-darker:hover {
  background-color: #775540 !important;
}

.bg-accent-warm-dark, .hover\:bg-accent-warm-dark:hover {
  background-color: #c05600 !important;
}

.bg-accent-warm, .hover\:bg-accent-warm:hover {
  background-color: #fa9441 !important;
}

.bg-accent-warm-light, .hover\:bg-accent-warm-light:hover {
  background-color: #ffbc78 !important;
}

.bg-accent-warm-lighter, .hover\:bg-accent-warm-lighter:hover {
  background-color: #f2e4d4 !important;
}

.bg-accent-cool-darker, .hover\:bg-accent-cool-darker:hover {
  background-color: #07648d !important;
}

.bg-accent-cool-dark, .hover\:bg-accent-cool-dark:hover {
  background-color: #28a0cb !important;
}

.bg-accent-cool, .hover\:bg-accent-cool:hover {
  background-color: #00bde3 !important;
}

.bg-accent-cool-light, .hover\:bg-accent-cool-light:hover {
  background-color: #97d4ea !important;
}

.bg-accent-cool-lighter, .hover\:bg-accent-cool-lighter:hover {
  background-color: #e1f3f8 !important;
}

.bg-error-lighter, .hover\:bg-error-lighter:hover {
  background-color: #f4e3db !important;
}

.bg-error-light, .hover\:bg-error-light:hover {
  background-color: #f39268 !important;
}

.bg-error, .hover\:bg-error:hover {
  background-color: #d54309 !important;
}

.bg-error-dark, .hover\:bg-error-dark:hover {
  background-color: #b50909 !important;
}

.bg-error-darker, .hover\:bg-error-darker:hover {
  background-color: #6f3331 !important;
}

.bg-warning-lighter, .hover\:bg-warning-lighter:hover {
  background-color: #faf3d1 !important;
}

.bg-warning-light, .hover\:bg-warning-light:hover {
  background-color: #fee685 !important;
}

.bg-warning, .hover\:bg-warning:hover {
  background-color: #ffbe2e !important;
}

.bg-warning-dark, .hover\:bg-warning-dark:hover {
  background-color: #e5a000 !important;
}

.bg-warning-darker, .hover\:bg-warning-darker:hover {
  background-color: #936f38 !important;
}

.bg-success-lighter, .hover\:bg-success-lighter:hover {
  background-color: #ecf3ec !important;
}

.bg-success-light, .hover\:bg-success-light:hover {
  background-color: #70e17b !important;
}

.bg-success, .hover\:bg-success:hover {
  background-color: #00a91c !important;
}

.bg-success-dark, .hover\:bg-success-dark:hover {
  background-color: #008817 !important;
}

.bg-success-darker, .hover\:bg-success-darker:hover {
  background-color: #216e1f !important;
}

.bg-info-lighter, .hover\:bg-info-lighter:hover {
  background-color: #e7f6f8 !important;
}

.bg-info-light, .hover\:bg-info-light:hover {
  background-color: #99deea !important;
}

.bg-info, .hover\:bg-info:hover {
  background-color: #00bde3 !important;
}

.bg-info-dark, .hover\:bg-info-dark:hover {
  background-color: #009ec1 !important;
}

.bg-info-darker, .hover\:bg-info-darker:hover {
  background-color: #2e6276 !important;
}

.bg-disabled-lighter, .hover\:bg-disabled-lighter:hover {
  background-color: #c9c9c9 !important;
}

.bg-disabled-light, .hover\:bg-disabled-light:hover {
  background-color: #919191 !important;
}

.bg-disabled, .hover\:bg-disabled:hover {
  background-color: #757575 !important;
}

.bg-disabled-dark, .hover\:bg-disabled-dark:hover {
  background-color: #454545 !important;
}

.bg-disabled-darker, .hover\:bg-disabled-darker:hover {
  background-color: #1b1b1b !important;
}

.bg-emergency, .hover\:bg-emergency:hover {
  background-color: #9c3d10 !important;
}

.bg-emergency-dark, .hover\:bg-emergency-dark:hover {
  background-color: #332d29 !important;
}

.border-1px, .hover\:border-1px:hover {
  border: 1px solid !important;
}

.border-y-1px, .hover\:border-y-1px:hover {
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}

.border-x-1px, .hover\:border-x-1px:hover {
  border-left: 1px solid !important;
  border-right: 1px solid !important;
}

.border-top-1px, .hover\:border-top-1px:hover {
  border-top: 1px solid !important;
}

.border-right-1px, .hover\:border-right-1px:hover {
  border-right: 1px solid !important;
}

.border-bottom-1px, .hover\:border-bottom-1px:hover {
  border-bottom: 1px solid !important;
}

.border-left-1px, .hover\:border-left-1px:hover {
  border-left: 1px solid !important;
}

.border-2px, .hover\:border-2px:hover {
  border: 2px solid !important;
}

.border-y-2px, .hover\:border-y-2px:hover {
  border-top: 2px solid !important;
  border-bottom: 2px solid !important;
}

.border-x-2px, .hover\:border-x-2px:hover {
  border-left: 2px solid !important;
  border-right: 2px solid !important;
}

.border-top-2px, .hover\:border-top-2px:hover {
  border-top: 2px solid !important;
}

.border-right-2px, .hover\:border-right-2px:hover {
  border-right: 2px solid !important;
}

.border-bottom-2px, .hover\:border-bottom-2px:hover {
  border-bottom: 2px solid !important;
}

.border-left-2px, .hover\:border-left-2px:hover {
  border-left: 2px solid !important;
}

.border-05, .hover\:border-05:hover {
  border: .25rem solid !important;
}

.border-y-05, .hover\:border-y-05:hover {
  border-top: .25rem solid !important;
  border-bottom: .25rem solid !important;
}

.border-x-05, .hover\:border-x-05:hover {
  border-left: .25rem solid !important;
  border-right: .25rem solid !important;
}

.border-top-05, .hover\:border-top-05:hover {
  border-top: .25rem solid !important;
}

.border-right-05, .hover\:border-right-05:hover {
  border-right: .25rem solid !important;
}

.border-bottom-05, .hover\:border-bottom-05:hover {
  border-bottom: .25rem solid !important;
}

.border-left-05, .hover\:border-left-05:hover {
  border-left: .25rem solid !important;
}

.border-1, .hover\:border-1:hover {
  border: .5rem solid !important;
}

.border-y-1, .hover\:border-y-1:hover {
  border-top: .5rem solid !important;
  border-bottom: .5rem solid !important;
}

.border-x-1, .hover\:border-x-1:hover {
  border-left: .5rem solid !important;
  border-right: .5rem solid !important;
}

.border-top-1, .hover\:border-top-1:hover {
  border-top: .5rem solid !important;
}

.border-right-1, .hover\:border-right-1:hover {
  border-right: .5rem solid !important;
}

.border-bottom-1, .hover\:border-bottom-1:hover {
  border-bottom: .5rem solid !important;
}

.border-left-1, .hover\:border-left-1:hover {
  border-left: .5rem solid !important;
}

.border-105, .hover\:border-105:hover {
  border: .75rem solid !important;
}

.border-y-105, .hover\:border-y-105:hover {
  border-top: .75rem solid !important;
  border-bottom: .75rem solid !important;
}

.border-x-105, .hover\:border-x-105:hover {
  border-left: .75rem solid !important;
  border-right: .75rem solid !important;
}

.border-top-105, .hover\:border-top-105:hover {
  border-top: .75rem solid !important;
}

.border-right-105, .hover\:border-right-105:hover {
  border-right: .75rem solid !important;
}

.border-bottom-105, .hover\:border-bottom-105:hover {
  border-bottom: .75rem solid !important;
}

.border-left-105, .hover\:border-left-105:hover {
  border-left: .75rem solid !important;
}

.border-2, .hover\:border-2:hover {
  border: 1rem solid !important;
}

.border-y-2, .hover\:border-y-2:hover {
  border-top: 1rem solid !important;
  border-bottom: 1rem solid !important;
}

.border-x-2, .hover\:border-x-2:hover {
  border-left: 1rem solid !important;
  border-right: 1rem solid !important;
}

.border-top-2, .hover\:border-top-2:hover {
  border-top: 1rem solid !important;
}

.border-right-2, .hover\:border-right-2:hover {
  border-right: 1rem solid !important;
}

.border-bottom-2, .hover\:border-bottom-2:hover {
  border-bottom: 1rem solid !important;
}

.border-left-2, .hover\:border-left-2:hover {
  border-left: 1rem solid !important;
}

.border-205, .hover\:border-205:hover {
  border: 1.25rem solid !important;
}

.border-y-205, .hover\:border-y-205:hover {
  border-top: 1.25rem solid !important;
  border-bottom: 1.25rem solid !important;
}

.border-x-205, .hover\:border-x-205:hover {
  border-left: 1.25rem solid !important;
  border-right: 1.25rem solid !important;
}

.border-top-205, .hover\:border-top-205:hover {
  border-top: 1.25rem solid !important;
}

.border-right-205, .hover\:border-right-205:hover {
  border-right: 1.25rem solid !important;
}

.border-bottom-205, .hover\:border-bottom-205:hover {
  border-bottom: 1.25rem solid !important;
}

.border-left-205, .hover\:border-left-205:hover {
  border-left: 1.25rem solid !important;
}

.border-3, .hover\:border-3:hover {
  border: 1.5rem solid !important;
}

.border-y-3, .hover\:border-y-3:hover {
  border-top: 1.5rem solid !important;
  border-bottom: 1.5rem solid !important;
}

.border-x-3, .hover\:border-x-3:hover {
  border-left: 1.5rem solid !important;
  border-right: 1.5rem solid !important;
}

.border-top-3, .hover\:border-top-3:hover {
  border-top: 1.5rem solid !important;
}

.border-right-3, .hover\:border-right-3:hover {
  border-right: 1.5rem solid !important;
}

.border-bottom-3, .hover\:border-bottom-3:hover {
  border-bottom: 1.5rem solid !important;
}

.border-left-3, .hover\:border-left-3:hover {
  border-left: 1.5rem solid !important;
}

.border-0, .hover\:border-0:hover {
  border: 0 solid !important;
}

.border-y-0, .hover\:border-y-0:hover {
  border-top: 0 solid !important;
  border-bottom: 0 solid !important;
}

.border-x-0, .hover\:border-x-0:hover {
  border-left: 0 solid !important;
  border-right: 0 solid !important;
}

.border-top-0, .hover\:border-top-0:hover {
  border-top: 0 solid !important;
}

.border-right-0, .hover\:border-right-0:hover {
  border-right: 0 solid !important;
}

.border-bottom-0, .hover\:border-bottom-0:hover {
  border-bottom: 0 solid !important;
}

.border-left-0, .hover\:border-left-0:hover {
  border-left: 0 solid !important;
}

.border, .hover\:border:hover {
  border: 1px solid !important;
}

.border-y, .hover\:border-y:hover {
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}

.border-x, .hover\:border-x:hover {
  border-left: 1px solid !important;
  border-right: 1px solid !important;
}

.border-top, .hover\:border-top:hover {
  border-top: 1px solid !important;
}

.border-right, .hover\:border-right:hover {
  border-right: 1px solid !important;
}

.border-bottom, .hover\:border-bottom:hover {
  border-bottom: 1px solid !important;
}

.border-left, .hover\:border-left:hover {
  border-left: 1px solid !important;
}

.border-transparent, .hover\:border-transparent:hover {
  border-color: #0000 !important;
}

.border-black, .hover\:border-black:hover {
  border-color: #000 !important;
}

.border-white, .hover\:border-white:hover {
  border-color: #fff !important;
}

.border-red, .hover\:border-red:hover {
  border-color: #e52207 !important;
}

.border-orange, .hover\:border-orange:hover {
  border-color: #e66f0e !important;
}

.border-gold, .hover\:border-gold:hover {
  border-color: #ffbe2e !important;
}

.border-yellow, .hover\:border-yellow:hover {
  border-color: #fee685 !important;
}

.border-green, .hover\:border-green:hover {
  border-color: #538200 !important;
}

.border-mint, .hover\:border-mint:hover {
  border-color: #04c585 !important;
}

.border-cyan, .hover\:border-cyan:hover {
  border-color: #009ec1 !important;
}

.border-blue, .hover\:border-blue:hover {
  border-color: #0076d6 !important;
}

.border-indigo, .hover\:border-indigo:hover {
  border-color: #676cc8 !important;
}

.border-violet, .hover\:border-violet:hover {
  border-color: #8168b3 !important;
}

.border-magenta, .hover\:border-magenta:hover {
  border-color: #d72d79 !important;
}

.border-gray-5, .hover\:border-gray-5:hover {
  border-color: #f0f0f0 !important;
}

.border-gray-10, .hover\:border-gray-10:hover {
  border-color: #e6e6e6 !important;
}

.border-gray-30, .hover\:border-gray-30:hover {
  border-color: #adadad !important;
}

.border-gray-50, .hover\:border-gray-50:hover {
  border-color: #757575 !important;
}

.border-gray-70, .hover\:border-gray-70:hover {
  border-color: #454545 !important;
}

.border-gray-90, .hover\:border-gray-90:hover {
  border-color: #1b1b1b !important;
}

.border-base-lightest, .hover\:border-base-lightest:hover {
  border-color: #f0f0f0 !important;
}

.border-base-lighter, .hover\:border-base-lighter:hover {
  border-color: #dfe1e2 !important;
}

.border-base-light, .hover\:border-base-light:hover {
  border-color: #a9aeb1 !important;
}

.border-base, .hover\:border-base:hover {
  border-color: #71767a !important;
}

.border-base-dark, .hover\:border-base-dark:hover {
  border-color: #565c65 !important;
}

.border-base-darker, .hover\:border-base-darker:hover {
  border-color: #3d4551 !important;
}

.border-base-darkest, .hover\:border-base-darkest:hover {
  border-color: #2d2e2f !important;
}

.border-ink, .hover\:border-ink:hover {
  border-color: #1c1d1f !important;
}

.border-primary-lighter, .hover\:border-primary-lighter:hover {
  border-color: #d9e8f6 !important;
}

.border-primary-light, .hover\:border-primary-light:hover {
  border-color: #73b3e7 !important;
}

.border-primary, .hover\:border-primary:hover {
  border-color: #005ea2 !important;
}

.border-primary-vivid, .hover\:border-primary-vivid:hover {
  border-color: #0050d8 !important;
}

.border-primary-dark, .hover\:border-primary-dark:hover {
  border-color: #1a4480 !important;
}

.border-primary-darker, .hover\:border-primary-darker:hover {
  border-color: #162e51 !important;
}

.border-secondary-lighter, .hover\:border-secondary-lighter:hover {
  border-color: #f3e1e4 !important;
}

.border-secondary-light, .hover\:border-secondary-light:hover {
  border-color: #f2938c !important;
}

.border-secondary, .hover\:border-secondary:hover {
  border-color: #d83933 !important;
}

.border-secondary-vivid, .hover\:border-secondary-vivid:hover {
  border-color: #e41d3d !important;
}

.border-secondary-dark, .hover\:border-secondary-dark:hover {
  border-color: #b50909 !important;
}

.border-secondary-darker, .hover\:border-secondary-darker:hover {
  border-color: #8b0a03 !important;
}

.border-accent-warm-darker, .hover\:border-accent-warm-darker:hover {
  border-color: #775540 !important;
}

.border-accent-warm-dark, .hover\:border-accent-warm-dark:hover {
  border-color: #c05600 !important;
}

.border-accent-warm, .hover\:border-accent-warm:hover {
  border-color: #fa9441 !important;
}

.border-accent-warm-light, .hover\:border-accent-warm-light:hover {
  border-color: #ffbc78 !important;
}

.border-accent-warm-lighter, .hover\:border-accent-warm-lighter:hover {
  border-color: #f2e4d4 !important;
}

.border-accent-cool-darker, .hover\:border-accent-cool-darker:hover {
  border-color: #07648d !important;
}

.border-accent-cool-dark, .hover\:border-accent-cool-dark:hover {
  border-color: #28a0cb !important;
}

.border-accent-cool, .hover\:border-accent-cool:hover {
  border-color: #00bde3 !important;
}

.border-accent-cool-light, .hover\:border-accent-cool-light:hover {
  border-color: #97d4ea !important;
}

.border-accent-cool-lighter, .hover\:border-accent-cool-lighter:hover {
  border-color: #e1f3f8 !important;
}

.border-error-lighter, .hover\:border-error-lighter:hover {
  border-color: #f4e3db !important;
}

.border-error-light, .hover\:border-error-light:hover {
  border-color: #f39268 !important;
}

.border-error, .hover\:border-error:hover {
  border-color: #d54309 !important;
}

.border-error-dark, .hover\:border-error-dark:hover {
  border-color: #b50909 !important;
}

.border-error-darker, .hover\:border-error-darker:hover {
  border-color: #6f3331 !important;
}

.border-warning-lighter, .hover\:border-warning-lighter:hover {
  border-color: #faf3d1 !important;
}

.border-warning-light, .hover\:border-warning-light:hover {
  border-color: #fee685 !important;
}

.border-warning, .hover\:border-warning:hover {
  border-color: #ffbe2e !important;
}

.border-warning-dark, .hover\:border-warning-dark:hover {
  border-color: #e5a000 !important;
}

.border-warning-darker, .hover\:border-warning-darker:hover {
  border-color: #936f38 !important;
}

.border-success-lighter, .hover\:border-success-lighter:hover {
  border-color: #ecf3ec !important;
}

.border-success-light, .hover\:border-success-light:hover {
  border-color: #70e17b !important;
}

.border-success, .hover\:border-success:hover {
  border-color: #00a91c !important;
}

.border-success-dark, .hover\:border-success-dark:hover {
  border-color: #008817 !important;
}

.border-success-darker, .hover\:border-success-darker:hover {
  border-color: #216e1f !important;
}

.border-info-lighter, .hover\:border-info-lighter:hover {
  border-color: #e7f6f8 !important;
}

.border-info-light, .hover\:border-info-light:hover {
  border-color: #99deea !important;
}

.border-info, .hover\:border-info:hover {
  border-color: #00bde3 !important;
}

.border-info-dark, .hover\:border-info-dark:hover {
  border-color: #009ec1 !important;
}

.border-info-darker, .hover\:border-info-darker:hover {
  border-color: #2e6276 !important;
}

.border-disabled-lighter, .hover\:border-disabled-lighter:hover {
  border-color: #c9c9c9 !important;
}

.border-disabled-light, .hover\:border-disabled-light:hover {
  border-color: #919191 !important;
}

.border-disabled, .hover\:border-disabled:hover {
  border-color: #757575 !important;
}

.border-disabled-dark, .hover\:border-disabled-dark:hover {
  border-color: #454545 !important;
}

.border-disabled-darker, .hover\:border-disabled-darker:hover {
  border-color: #1b1b1b !important;
}

.border-emergency, .hover\:border-emergency:hover {
  border-color: #9c3d10 !important;
}

.border-emergency-dark, .hover\:border-emergency-dark:hover {
  border-color: #332d29 !important;
}

.radius-0 {
  border-radius: 0 !important;
}

.radius-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.radius-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.radius-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.radius-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.radius-sm {
  border-radius: 2px !important;
}

.radius-top-sm {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
}

.radius-right-sm {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.radius-bottom-sm {
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.radius-left-sm {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.radius-md {
  border-radius: .25rem !important;
}

.radius-top-md {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.radius-right-md {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.radius-bottom-md {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.radius-left-md {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.radius-lg {
  border-radius: .5rem !important;
}

.radius-top-lg {
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
}

.radius-right-lg {
  border-top-right-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
}

.radius-bottom-lg {
  border-bottom-right-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
}

.radius-left-lg {
  border-top-left-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
}

.radius-pill {
  border-radius: 99rem !important;
}

.radius-top-pill {
  border-top-left-radius: 99rem !important;
  border-top-right-radius: 99rem !important;
}

.radius-right-pill {
  border-top-right-radius: 99rem !important;
  border-bottom-right-radius: 99rem !important;
}

.radius-bottom-pill {
  border-bottom-right-radius: 99rem !important;
  border-bottom-left-radius: 99rem !important;
}

.radius-left-pill {
  border-top-left-radius: 99rem !important;
  border-bottom-left-radius: 99rem !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-solid {
  border-style: solid !important;
}

.border-width-1px {
  border-width: 1px !important;
}

.border-y-width-1px {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.border-x-width-1px {
  border-left-width: 1px !important;
  border-right-width: 1px !important;
}

.border-top-width-1px {
  border-top-width: 1px !important;
}

.border-right-width-1px {
  border-right-width: 1px !important;
}

.border-bottom-width-1px {
  border-bottom-width: 1px !important;
}

.border-left-width-1px {
  border-left-width: 1px !important;
}

.border-width-2px {
  border-width: 2px !important;
}

.border-y-width-2px {
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}

.border-x-width-2px {
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}

.border-top-width-2px {
  border-top-width: 2px !important;
}

.border-right-width-2px {
  border-right-width: 2px !important;
}

.border-bottom-width-2px {
  border-bottom-width: 2px !important;
}

.border-left-width-2px {
  border-left-width: 2px !important;
}

.border-width-05 {
  border-width: .25rem !important;
}

.border-y-width-05 {
  border-top-width: .25rem !important;
  border-bottom-width: .25rem !important;
}

.border-x-width-05 {
  border-left-width: .25rem !important;
  border-right-width: .25rem !important;
}

.border-top-width-05 {
  border-top-width: .25rem !important;
}

.border-right-width-05 {
  border-right-width: .25rem !important;
}

.border-bottom-width-05 {
  border-bottom-width: .25rem !important;
}

.border-left-width-05 {
  border-left-width: .25rem !important;
}

.border-width-1 {
  border-width: .5rem !important;
}

.border-y-width-1 {
  border-top-width: .5rem !important;
  border-bottom-width: .5rem !important;
}

.border-x-width-1 {
  border-left-width: .5rem !important;
  border-right-width: .5rem !important;
}

.border-top-width-1 {
  border-top-width: .5rem !important;
}

.border-right-width-1 {
  border-right-width: .5rem !important;
}

.border-bottom-width-1 {
  border-bottom-width: .5rem !important;
}

.border-left-width-1 {
  border-left-width: .5rem !important;
}

.border-width-105 {
  border-width: .75rem !important;
}

.border-y-width-105 {
  border-top-width: .75rem !important;
  border-bottom-width: .75rem !important;
}

.border-x-width-105 {
  border-left-width: .75rem !important;
  border-right-width: .75rem !important;
}

.border-top-width-105 {
  border-top-width: .75rem !important;
}

.border-right-width-105 {
  border-right-width: .75rem !important;
}

.border-bottom-width-105 {
  border-bottom-width: .75rem !important;
}

.border-left-width-105 {
  border-left-width: .75rem !important;
}

.border-width-2 {
  border-width: 1rem !important;
}

.border-y-width-2 {
  border-top-width: 1rem !important;
  border-bottom-width: 1rem !important;
}

.border-x-width-2 {
  border-left-width: 1rem !important;
  border-right-width: 1rem !important;
}

.border-top-width-2 {
  border-top-width: 1rem !important;
}

.border-right-width-2 {
  border-right-width: 1rem !important;
}

.border-bottom-width-2 {
  border-bottom-width: 1rem !important;
}

.border-left-width-2 {
  border-left-width: 1rem !important;
}

.border-width-205 {
  border-width: 1.25rem !important;
}

.border-y-width-205 {
  border-top-width: 1.25rem !important;
  border-bottom-width: 1.25rem !important;
}

.border-x-width-205 {
  border-left-width: 1.25rem !important;
  border-right-width: 1.25rem !important;
}

.border-top-width-205 {
  border-top-width: 1.25rem !important;
}

.border-right-width-205 {
  border-right-width: 1.25rem !important;
}

.border-bottom-width-205 {
  border-bottom-width: 1.25rem !important;
}

.border-left-width-205 {
  border-left-width: 1.25rem !important;
}

.border-width-3 {
  border-width: 1.5rem !important;
}

.border-y-width-3 {
  border-top-width: 1.5rem !important;
  border-bottom-width: 1.5rem !important;
}

.border-x-width-3 {
  border-left-width: 1.5rem !important;
  border-right-width: 1.5rem !important;
}

.border-top-width-3 {
  border-top-width: 1.5rem !important;
}

.border-right-width-3 {
  border-right-width: 1.5rem !important;
}

.border-bottom-width-3 {
  border-bottom-width: 1.5rem !important;
}

.border-left-width-3 {
  border-left-width: 1.5rem !important;
}

.border-width-0 {
  border-width: 0 !important;
}

.border-y-width-0 {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
}

.border-x-width-0 {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

.border-top-width-0 {
  border-top-width: 0 !important;
}

.border-right-width-0 {
  border-right-width: 0 !important;
}

.border-bottom-width-0 {
  border-bottom-width: 0 !important;
}

.border-left-width-0 {
  border-left-width: 0 !important;
}

.bottom-1px {
  bottom: 1px !important;
}

.bottom-2px {
  bottom: 2px !important;
}

.bottom-05 {
  bottom: .25rem !important;
}

.bottom-1 {
  bottom: .5rem !important;
}

.bottom-105 {
  bottom: .75rem !important;
}

.bottom-2 {
  bottom: 1rem !important;
}

.bottom-205 {
  bottom: 1.25rem !important;
}

.bottom-3 {
  bottom: 1.5rem !important;
}

.bottom-neg-1px {
  bottom: -1px !important;
}

.bottom-neg-2px {
  bottom: -2px !important;
}

.bottom-neg-05 {
  bottom: -.25rem !important;
}

.bottom-neg-1 {
  bottom: -.5rem !important;
}

.bottom-neg-105 {
  bottom: -.75rem !important;
}

.bottom-neg-2 {
  bottom: -1rem !important;
}

.bottom-neg-205 {
  bottom: -1.25rem !important;
}

.bottom-neg-3 {
  bottom: -1.5rem !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-auto {
  bottom: auto !important;
}

.bottom-full {
  bottom: 100% !important;
}

.shadow-none, .hover\:shadow-none:hover {
  box-shadow: none !important;
}

.shadow-1, .hover\:shadow-1:hover {
  box-shadow: 0 1px .25rem #0000001a !important;
}

.shadow-2, .hover\:shadow-2:hover {
  box-shadow: 0 .25rem .5rem #0000001a !important;
}

.shadow-3, .hover\:shadow-3:hover {
  box-shadow: 0 .5rem 1rem #0000001a !important;
}

.shadow-4, .hover\:shadow-4:hover {
  box-shadow: 0 .75rem 1.5rem #0000001a !important;
}

.shadow-5, .hover\:shadow-5:hover {
  box-shadow: 0 1rem 2rem #0000001a !important;
}

.circle-1px {
  border-radius: 50% !important;
  width: 1px !important;
  height: 1px !important;
}

.circle-2px {
  border-radius: 50% !important;
  width: 2px !important;
  height: 2px !important;
}

.circle-05 {
  border-radius: 50% !important;
  width: .25rem !important;
  height: .25rem !important;
}

.circle-1 {
  border-radius: 50% !important;
  width: .5rem !important;
  height: .5rem !important;
}

.circle-105 {
  border-radius: 50% !important;
  width: .75rem !important;
  height: .75rem !important;
}

.circle-2 {
  border-radius: 50% !important;
  width: 1rem !important;
  height: 1rem !important;
}

.circle-205 {
  border-radius: 50% !important;
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.circle-3 {
  border-radius: 50% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.circle-4 {
  border-radius: 50% !important;
  width: 2rem !important;
  height: 2rem !important;
}

.circle-5 {
  border-radius: 50% !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.circle-6 {
  border-radius: 50% !important;
  width: 3rem !important;
  height: 3rem !important;
}

.circle-7 {
  border-radius: 50% !important;
  width: 3.5rem !important;
  height: 3.5rem !important;
}

.circle-8 {
  border-radius: 50% !important;
  width: 4rem !important;
  height: 4rem !important;
}

.circle-9 {
  border-radius: 50% !important;
  width: 4.5rem !important;
  height: 4.5rem !important;
}

.circle-10 {
  border-radius: 50% !important;
  width: 5rem !important;
  height: 5rem !important;
}

.circle-15 {
  border-radius: 50% !important;
  width: 7.5rem !important;
  height: 7.5rem !important;
}

.circle-card {
  border-radius: 50% !important;
  width: 10rem !important;
  height: 10rem !important;
}

.circle-card-lg {
  border-radius: 50% !important;
  width: 15rem !important;
  height: 15rem !important;
}

.circle-mobile {
  border-radius: 50% !important;
  width: 20rem !important;
  height: 20rem !important;
}

.clearfix:after {
  content: "" !important;
  clear: both !important;
  display: block !important;
}

.text-transparent, .hover\:text-transparent:hover {
  color: #0000 !important;
}

.text-black, .hover\:text-black:hover {
  color: #000 !important;
}

.text-white, .hover\:text-white:hover {
  color: #fff !important;
}

.text-red, .hover\:text-red:hover {
  color: #e52207 !important;
}

.text-orange, .hover\:text-orange:hover {
  color: #e66f0e !important;
}

.text-gold, .hover\:text-gold:hover {
  color: #ffbe2e !important;
}

.text-yellow, .hover\:text-yellow:hover {
  color: #fee685 !important;
}

.text-green, .hover\:text-green:hover {
  color: #538200 !important;
}

.text-mint, .hover\:text-mint:hover {
  color: #04c585 !important;
}

.text-cyan, .hover\:text-cyan:hover {
  color: #009ec1 !important;
}

.text-blue, .hover\:text-blue:hover {
  color: #0076d6 !important;
}

.text-indigo, .hover\:text-indigo:hover {
  color: #676cc8 !important;
}

.text-violet, .hover\:text-violet:hover {
  color: #8168b3 !important;
}

.text-magenta, .hover\:text-magenta:hover {
  color: #d72d79 !important;
}

.text-gray-5, .hover\:text-gray-5:hover {
  color: #f0f0f0 !important;
}

.text-gray-10, .hover\:text-gray-10:hover {
  color: #e6e6e6 !important;
}

.text-gray-30, .hover\:text-gray-30:hover {
  color: #adadad !important;
}

.text-gray-50, .hover\:text-gray-50:hover {
  color: #757575 !important;
}

.text-gray-70, .hover\:text-gray-70:hover {
  color: #454545 !important;
}

.text-gray-90, .hover\:text-gray-90:hover {
  color: #1b1b1b !important;
}

.text-base-lightest, .hover\:text-base-lightest:hover {
  color: #f0f0f0 !important;
}

.text-base-lighter, .hover\:text-base-lighter:hover {
  color: #dfe1e2 !important;
}

.text-base-light, .hover\:text-base-light:hover {
  color: #a9aeb1 !important;
}

.text-base, .hover\:text-base:hover {
  color: #71767a !important;
}

.text-base-dark, .hover\:text-base-dark:hover {
  color: #565c65 !important;
}

.text-base-darker, .hover\:text-base-darker:hover {
  color: #3d4551 !important;
}

.text-base-darkest, .hover\:text-base-darkest:hover {
  color: #2d2e2f !important;
}

.text-ink, .hover\:text-ink:hover {
  color: #1c1d1f !important;
}

.text-primary-lighter, .hover\:text-primary-lighter:hover {
  color: #d9e8f6 !important;
}

.text-primary-light, .hover\:text-primary-light:hover {
  color: #73b3e7 !important;
}

.text-primary, .hover\:text-primary:hover {
  color: #005ea2 !important;
}

.text-primary-vivid, .hover\:text-primary-vivid:hover {
  color: #0050d8 !important;
}

.text-primary-dark, .hover\:text-primary-dark:hover {
  color: #1a4480 !important;
}

.text-primary-darker, .hover\:text-primary-darker:hover {
  color: #162e51 !important;
}

.text-secondary-lighter, .hover\:text-secondary-lighter:hover {
  color: #f3e1e4 !important;
}

.text-secondary-light, .hover\:text-secondary-light:hover {
  color: #f2938c !important;
}

.text-secondary, .hover\:text-secondary:hover {
  color: #d83933 !important;
}

.text-secondary-vivid, .hover\:text-secondary-vivid:hover {
  color: #e41d3d !important;
}

.text-secondary-dark, .hover\:text-secondary-dark:hover {
  color: #b50909 !important;
}

.text-secondary-darker, .hover\:text-secondary-darker:hover {
  color: #8b0a03 !important;
}

.text-accent-warm-darker, .hover\:text-accent-warm-darker:hover {
  color: #775540 !important;
}

.text-accent-warm-dark, .hover\:text-accent-warm-dark:hover {
  color: #c05600 !important;
}

.text-accent-warm, .hover\:text-accent-warm:hover {
  color: #fa9441 !important;
}

.text-accent-warm-light, .hover\:text-accent-warm-light:hover {
  color: #ffbc78 !important;
}

.text-accent-warm-lighter, .hover\:text-accent-warm-lighter:hover {
  color: #f2e4d4 !important;
}

.text-accent-cool-darker, .hover\:text-accent-cool-darker:hover {
  color: #07648d !important;
}

.text-accent-cool-dark, .hover\:text-accent-cool-dark:hover {
  color: #28a0cb !important;
}

.text-accent-cool, .hover\:text-accent-cool:hover {
  color: #00bde3 !important;
}

.text-accent-cool-light, .hover\:text-accent-cool-light:hover {
  color: #97d4ea !important;
}

.text-accent-cool-lighter, .hover\:text-accent-cool-lighter:hover {
  color: #e1f3f8 !important;
}

.text-error-lighter, .hover\:text-error-lighter:hover {
  color: #f4e3db !important;
}

.text-error-light, .hover\:text-error-light:hover {
  color: #f39268 !important;
}

.text-error, .hover\:text-error:hover {
  color: #d54309 !important;
}

.text-error-dark, .hover\:text-error-dark:hover {
  color: #b50909 !important;
}

.text-error-darker, .hover\:text-error-darker:hover {
  color: #6f3331 !important;
}

.text-warning-lighter, .hover\:text-warning-lighter:hover {
  color: #faf3d1 !important;
}

.text-warning-light, .hover\:text-warning-light:hover {
  color: #fee685 !important;
}

.text-warning, .hover\:text-warning:hover {
  color: #ffbe2e !important;
}

.text-warning-dark, .hover\:text-warning-dark:hover {
  color: #e5a000 !important;
}

.text-warning-darker, .hover\:text-warning-darker:hover {
  color: #936f38 !important;
}

.text-success-lighter, .hover\:text-success-lighter:hover {
  color: #ecf3ec !important;
}

.text-success-light, .hover\:text-success-light:hover {
  color: #70e17b !important;
}

.text-success, .hover\:text-success:hover {
  color: #00a91c !important;
}

.text-success-dark, .hover\:text-success-dark:hover {
  color: #008817 !important;
}

.text-success-darker, .hover\:text-success-darker:hover {
  color: #216e1f !important;
}

.text-info-lighter, .hover\:text-info-lighter:hover {
  color: #e7f6f8 !important;
}

.text-info-light, .hover\:text-info-light:hover {
  color: #99deea !important;
}

.text-info, .hover\:text-info:hover {
  color: #00bde3 !important;
}

.text-info-dark, .hover\:text-info-dark:hover {
  color: #009ec1 !important;
}

.text-info-darker, .hover\:text-info-darker:hover {
  color: #2e6276 !important;
}

.text-disabled-lighter, .hover\:text-disabled-lighter:hover {
  color: #c9c9c9 !important;
}

.text-disabled-light, .hover\:text-disabled-light:hover {
  color: #919191 !important;
}

.text-disabled, .hover\:text-disabled:hover {
  color: #757575 !important;
}

.text-disabled-dark, .hover\:text-disabled-dark:hover {
  color: #454545 !important;
}

.text-disabled-darker, .hover\:text-disabled-darker:hover {
  color: #1b1b1b !important;
}

.text-emergency, .hover\:text-emergency:hover {
  color: #9c3d10 !important;
}

.text-emergency-dark, .hover\:text-emergency-dark:hover {
  color: #332d29 !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.display-block {
  display: block !important;
}

.display-flex {
  display: flex !important;
}

.display-none {
  display: none !important;
}

.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-inline-flex {
  display: inline-flex !important;
}

.display-table {
  display: table !important;
}

.display-table-cell {
  display: table-cell !important;
}

.display-table-row {
  display: table-row !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-2 {
  flex: 2 !important;
}

.flex-3 {
  flex: 3 !important;
}

.flex-4 {
  flex: 4 !important;
}

.flex-5 {
  flex: 5 !important;
}

.flex-6 {
  flex: 6 !important;
}

.flex-7 {
  flex: 7 !important;
}

.flex-8 {
  flex: 8 !important;
}

.flex-9 {
  flex: 9 !important;
}

.flex-10 {
  flex: 10 !important;
}

.flex-11 {
  flex: 11 !important;
}

.flex-12 {
  flex: 12 !important;
}

.flex-fill {
  flex: 1 !important;
}

.flex-auto {
  flex: 0 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-no-wrap {
  flex-wrap: nowrap !important;
}

.float-left {
  float: left !important;
}

.float-none {
  float: none !important;
}

.float-right {
  float: right !important;
}

.font-mono-3xs {
  font-size: .77rem !important;
}

.font-mono-2xs {
  font-size: .83rem !important;
}

.font-mono-xs {
  font-size: .89rem !important;
}

.font-mono-sm {
  font-size: .95rem !important;
}

.font-mono-md {
  font-size: 1.19rem !important;
}

.font-mono-lg {
  font-size: 1.31rem !important;
}

.font-mono-xl {
  font-size: 1.91rem !important;
}

.font-mono-2xl {
  font-size: 2.38rem !important;
}

.font-mono-3xl {
  font-size: 2.86rem !important;
}

.font-sans-3xs {
  font-size: .81rem !important;
}

.font-sans-2xs {
  font-size: .87rem !important;
}

.font-sans-xs {
  font-size: .93rem !important;
}

.font-sans-sm {
  font-size: .99rem !important;
}

.font-sans-md {
  font-size: 1.24rem !important;
}

.font-sans-lg {
  font-size: 1.37rem !important;
}

.font-sans-xl {
  font-size: 1.99rem !important;
}

.font-sans-2xl {
  font-size: 2.49rem !important;
}

.font-sans-3xl {
  font-size: 2.98rem !important;
}

.font-serif-3xs {
  font-size: .81rem !important;
}

.font-serif-2xs {
  font-size: .87rem !important;
}

.font-serif-xs {
  font-size: .93rem !important;
}

.font-serif-sm {
  font-size: .99rem !important;
}

.font-serif-md {
  font-size: 1.24rem !important;
}

.font-serif-lg {
  font-size: 1.37rem !important;
}

.font-serif-xl {
  font-size: 1.99rem !important;
}

.font-serif-2xl {
  font-size: 2.49rem !important;
}

.font-serif-3xl {
  font-size: 2.98rem !important;
}

.font-heading-3xs {
  font-size: .81rem !important;
}

.font-heading-2xs {
  font-size: .87rem !important;
}

.font-heading-xs {
  font-size: .93rem !important;
}

.font-heading-sm {
  font-size: .99rem !important;
}

.font-heading-md {
  font-size: 1.24rem !important;
}

.font-heading-lg {
  font-size: 1.37rem !important;
}

.font-heading-xl {
  font-size: 1.99rem !important;
}

.font-heading-2xl {
  font-size: 2.49rem !important;
}

.font-heading-3xl {
  font-size: 2.98rem !important;
}

.font-body-3xs {
  font-size: .81rem !important;
}

.font-body-2xs {
  font-size: .87rem !important;
}

.font-body-xs {
  font-size: .93rem !important;
}

.font-body-sm {
  font-size: .99rem !important;
}

.font-body-md {
  font-size: 1.24rem !important;
}

.font-body-lg {
  font-size: 1.37rem !important;
}

.font-body-xl {
  font-size: 1.99rem !important;
}

.font-body-2xl {
  font-size: 2.49rem !important;
}

.font-body-3xl {
  font-size: 2.98rem !important;
}

.font-code-3xs {
  font-size: .77rem !important;
}

.font-code-2xs {
  font-size: .83rem !important;
}

.font-code-xs {
  font-size: .89rem !important;
}

.font-code-sm {
  font-size: .95rem !important;
}

.font-code-md {
  font-size: 1.19rem !important;
}

.font-code-lg {
  font-size: 1.31rem !important;
}

.font-code-xl {
  font-size: 1.91rem !important;
}

.font-code-2xl {
  font-size: 2.38rem !important;
}

.font-code-3xl {
  font-size: 2.86rem !important;
}

.font-alt-3xs {
  font-size: .81rem !important;
}

.font-alt-2xs {
  font-size: .87rem !important;
}

.font-alt-xs {
  font-size: .93rem !important;
}

.font-alt-sm {
  font-size: .99rem !important;
}

.font-alt-md {
  font-size: 1.24rem !important;
}

.font-alt-lg {
  font-size: 1.37rem !important;
}

.font-alt-xl {
  font-size: 1.99rem !important;
}

.font-alt-2xl {
  font-size: 2.49rem !important;
}

.font-alt-3xl {
  font-size: 2.98rem !important;
}

.font-ui-3xs {
  font-size: .81rem !important;
}

.font-ui-2xs {
  font-size: .87rem !important;
}

.font-ui-xs {
  font-size: .93rem !important;
}

.font-ui-sm {
  font-size: .99rem !important;
}

.font-ui-md {
  font-size: 1.24rem !important;
}

.font-ui-lg {
  font-size: 1.37rem !important;
}

.font-ui-xl {
  font-size: 1.99rem !important;
}

.font-ui-2xl {
  font-size: 2.49rem !important;
}

.font-ui-3xl {
  font-size: 2.98rem !important;
}

.font-family-mono {
  font-family: Roboto Mono Web, Bitstream Vera Sans Mono, Consolas, Courier, monospace !important;
}

.font-family-sans, .font-family-serif, .font-family-ui, .font-family-heading, .font-family-body {
  font-family: var(--base-font-family),  !important;
}

.font-family-code {
  font-family: Roboto Mono Web, Bitstream Vera Sans Mono, Consolas, Courier, monospace !important;
}

.font-family-alt {
  font-family: var(--base-font-family),  !important;
}

.text-tabular {
  font-feature-settings: "tnum" 1, "kern" 1 !important;
}

.text-no-tabular {
  font-feature-settings: "kern" 1 !important;
}

.text-italic {
  font-style: italic !important;
}

.text-no-italic {
  font-style: normal !important;
}

.text-light {
  font-weight: 300 !important;
}

.text-normal {
  font-weight: normal !important;
}

.text-semibold {
  font-weight: 600 !important;
}

.text-bold {
  font-weight: 700 !important;
}

.height-1px {
  height: 1px !important;
}

.height-2px {
  height: 2px !important;
}

.height-05 {
  height: .25rem !important;
}

.height-1 {
  height: .5rem !important;
}

.height-105 {
  height: .75rem !important;
}

.height-2 {
  height: 1rem !important;
}

.height-205 {
  height: 1.25rem !important;
}

.height-3 {
  height: 1.5rem !important;
}

.height-4 {
  height: 2rem !important;
}

.height-5 {
  height: 2.5rem !important;
}

.height-6 {
  height: 3rem !important;
}

.height-7 {
  height: 3.5rem !important;
}

.height-8 {
  height: 4rem !important;
}

.height-9 {
  height: 4.5rem !important;
}

.height-10 {
  height: 5rem !important;
}

.height-15 {
  height: 7.5rem !important;
}

.height-card {
  height: 10rem !important;
}

.height-card-lg {
  height: 15rem !important;
}

.height-mobile {
  height: 20rem !important;
}

.height-0 {
  height: 0 !important;
}

.height-auto {
  height: auto !important;
}

.height-full {
  height: 100% !important;
}

.height-viewport {
  height: 100vh !important;
}

.flex-justify-center {
  justify-content: center !important;
}

.flex-justify-start {
  justify-content: flex-start !important;
}

.flex-justify-end {
  justify-content: flex-end !important;
}

.flex-justify {
  justify-content: space-between !important;
}

.left-1px {
  left: 1px !important;
}

.left-2px {
  left: 2px !important;
}

.left-05 {
  left: .25rem !important;
}

.left-1 {
  left: .5rem !important;
}

.left-105 {
  left: .75rem !important;
}

.left-2 {
  left: 1rem !important;
}

.left-205 {
  left: 1.25rem !important;
}

.left-3 {
  left: 1.5rem !important;
}

.left-neg-1px {
  left: -1px !important;
}

.left-neg-2px {
  left: -2px !important;
}

.left-neg-05 {
  left: -.25rem !important;
}

.left-neg-1 {
  left: -.5rem !important;
}

.left-neg-105 {
  left: -.75rem !important;
}

.left-neg-2 {
  left: -1rem !important;
}

.left-neg-205 {
  left: -1.25rem !important;
}

.left-neg-3 {
  left: -1.5rem !important;
}

.left-0 {
  left: 0 !important;
}

.left-auto {
  left: auto !important;
}

.left-full {
  left: 100% !important;
}

.text-ls-auto {
  letter-spacing: initial !important;
}

.text-ls-neg-3 {
  letter-spacing: -.03em !important;
}

.text-ls-neg-2 {
  letter-spacing: -.02em !important;
}

.text-ls-neg-1 {
  letter-spacing: -.01em !important;
}

.text-ls-1 {
  letter-spacing: .025em !important;
}

.text-ls-2 {
  letter-spacing: .1em !important;
}

.text-ls-3 {
  letter-spacing: .15em !important;
}

.line-height-sans-1 {
  line-height: 1 !important;
}

.line-height-sans-2 {
  line-height: 1.2 !important;
}

.line-height-sans-3 {
  line-height: 1.4 !important;
}

.line-height-sans-4 {
  line-height: 1.5 !important;
}

.line-height-sans-5 {
  line-height: 1.6 !important;
}

.line-height-sans-6 {
  line-height: 1.8 !important;
}

.line-height-serif-1 {
  line-height: 1 !important;
}

.line-height-serif-2 {
  line-height: 1.2 !important;
}

.line-height-serif-3 {
  line-height: 1.4 !important;
}

.line-height-serif-4 {
  line-height: 1.5 !important;
}

.line-height-serif-5 {
  line-height: 1.6 !important;
}

.line-height-serif-6 {
  line-height: 1.8 !important;
}

.line-height-mono-1 {
  line-height: 1 !important;
}

.line-height-mono-2 {
  line-height: 1.3 !important;
}

.line-height-mono-3 {
  line-height: 1.4 !important;
}

.line-height-mono-4 {
  line-height: 1.6 !important;
}

.line-height-mono-5 {
  line-height: 1.7 !important;
}

.line-height-mono-6 {
  line-height: 1.8 !important;
}

.line-height-heading-1 {
  line-height: 1 !important;
}

.line-height-heading-2 {
  line-height: 1.2 !important;
}

.line-height-heading-3 {
  line-height: 1.4 !important;
}

.line-height-heading-4 {
  line-height: 1.5 !important;
}

.line-height-heading-5 {
  line-height: 1.6 !important;
}

.line-height-heading-6 {
  line-height: 1.8 !important;
}

.line-height-ui-1 {
  line-height: 1 !important;
}

.line-height-ui-2 {
  line-height: 1.2 !important;
}

.line-height-ui-3 {
  line-height: 1.4 !important;
}

.line-height-ui-4 {
  line-height: 1.5 !important;
}

.line-height-ui-5 {
  line-height: 1.6 !important;
}

.line-height-ui-6 {
  line-height: 1.8 !important;
}

.line-height-body-1 {
  line-height: 1 !important;
}

.line-height-body-2 {
  line-height: 1.2 !important;
}

.line-height-body-3 {
  line-height: 1.4 !important;
}

.line-height-body-4 {
  line-height: 1.5 !important;
}

.line-height-body-5 {
  line-height: 1.6 !important;
}

.line-height-body-6 {
  line-height: 1.8 !important;
}

.line-height-code-1 {
  line-height: 1 !important;
}

.line-height-code-2 {
  line-height: 1.3 !important;
}

.line-height-code-3 {
  line-height: 1.4 !important;
}

.line-height-code-4 {
  line-height: 1.6 !important;
}

.line-height-code-5 {
  line-height: 1.7 !important;
}

.line-height-code-6 {
  line-height: 1.8 !important;
}

.line-height-alt-1 {
  line-height: 1 !important;
}

.line-height-alt-2 {
  line-height: 1.2 !important;
}

.line-height-alt-3 {
  line-height: 1.4 !important;
}

.line-height-alt-4 {
  line-height: 1.5 !important;
}

.line-height-alt-5 {
  line-height: 1.6 !important;
}

.line-height-alt-6 {
  line-height: 1.8 !important;
}

.margin-neg-1px {
  margin: -1px !important;
}

.margin-neg-2px {
  margin: -2px !important;
}

.margin-neg-05 {
  margin: -.25rem !important;
}

.margin-neg-1 {
  margin: -.5rem !important;
}

.margin-neg-105 {
  margin: -.75rem !important;
}

.margin-neg-2 {
  margin: -1rem !important;
}

.margin-neg-205 {
  margin: -1.25rem !important;
}

.margin-neg-3 {
  margin: -1.5rem !important;
}

.margin-1px {
  margin: 1px !important;
}

.margin-2px {
  margin: 2px !important;
}

.margin-05 {
  margin: .25rem !important;
}

.margin-1 {
  margin: .5rem !important;
}

.margin-105 {
  margin: .75rem !important;
}

.margin-2 {
  margin: 1rem !important;
}

.margin-205 {
  margin: 1.25rem !important;
}

.margin-3 {
  margin: 1.5rem !important;
}

.margin-neg-4 {
  margin: -2rem !important;
}

.margin-neg-5 {
  margin: -2.5rem !important;
}

.margin-neg-6 {
  margin: -3rem !important;
}

.margin-neg-7 {
  margin: -3.5rem !important;
}

.margin-neg-8 {
  margin: -4rem !important;
}

.margin-neg-9 {
  margin: -4.5rem !important;
}

.margin-neg-10 {
  margin: -5rem !important;
}

.margin-neg-15 {
  margin: -7.5rem !important;
}

.margin-4 {
  margin: 2rem !important;
}

.margin-5 {
  margin: 2.5rem !important;
}

.margin-6 {
  margin: 3rem !important;
}

.margin-7 {
  margin: 3.5rem !important;
}

.margin-8 {
  margin: 4rem !important;
}

.margin-9 {
  margin: 4.5rem !important;
}

.margin-10 {
  margin: 5rem !important;
}

.margin-15 {
  margin: 7.5rem !important;
}

.margin-05em {
  margin: .5em !important;
}

.margin-1em {
  margin: 1em !important;
}

.margin-105em {
  margin: 1.5em !important;
}

.margin-2em {
  margin: 2em !important;
}

.margin-0 {
  margin: 0 !important;
}

.margin-y-1px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.margin-top-1px {
  margin-top: 1px !important;
}

.margin-bottom-1px {
  margin-bottom: 1px !important;
}

.margin-y-2px {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.margin-top-2px {
  margin-top: 2px !important;
}

.margin-bottom-2px {
  margin-bottom: 2px !important;
}

.margin-y-05 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.margin-top-05 {
  margin-top: .25rem !important;
}

.margin-bottom-05 {
  margin-bottom: .25rem !important;
}

.margin-y-1 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.margin-top-1 {
  margin-top: .5rem !important;
}

.margin-bottom-1 {
  margin-bottom: .5rem !important;
}

.margin-y-105 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.margin-top-105 {
  margin-top: .75rem !important;
}

.margin-bottom-105 {
  margin-bottom: .75rem !important;
}

.margin-y-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.margin-top-2 {
  margin-top: 1rem !important;
}

.margin-bottom-2 {
  margin-bottom: 1rem !important;
}

.margin-y-205 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.margin-top-205 {
  margin-top: 1.25rem !important;
}

.margin-bottom-205 {
  margin-bottom: 1.25rem !important;
}

.margin-y-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.margin-top-3 {
  margin-top: 1.5rem !important;
}

.margin-bottom-3 {
  margin-bottom: 1.5rem !important;
}

.margin-y-neg-1px {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

.margin-top-neg-1px {
  margin-top: -1px !important;
}

.margin-bottom-neg-1px {
  margin-bottom: -1px !important;
}

.margin-y-neg-2px {
  margin-top: -2px !important;
  margin-bottom: -2px !important;
}

.margin-top-neg-2px {
  margin-top: -2px !important;
}

.margin-bottom-neg-2px {
  margin-bottom: -2px !important;
}

.margin-y-neg-05 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.margin-top-neg-05 {
  margin-top: -.25rem !important;
}

.margin-bottom-neg-05 {
  margin-bottom: -.25rem !important;
}

.margin-y-neg-1 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.margin-top-neg-1 {
  margin-top: -.5rem !important;
}

.margin-bottom-neg-1 {
  margin-bottom: -.5rem !important;
}

.margin-y-neg-105 {
  margin-top: -.75rem !important;
  margin-bottom: -.75rem !important;
}

.margin-top-neg-105 {
  margin-top: -.75rem !important;
}

.margin-bottom-neg-105 {
  margin-bottom: -.75rem !important;
}

.margin-y-neg-2 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.margin-top-neg-2 {
  margin-top: -1rem !important;
}

.margin-bottom-neg-2 {
  margin-bottom: -1rem !important;
}

.margin-y-neg-205 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.margin-top-neg-205 {
  margin-top: -1.25rem !important;
}

.margin-bottom-neg-205 {
  margin-bottom: -1.25rem !important;
}

.margin-y-neg-3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.margin-top-neg-3 {
  margin-top: -1.5rem !important;
}

.margin-bottom-neg-3 {
  margin-bottom: -1.5rem !important;
}

.margin-y-neg-4 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.margin-top-neg-4 {
  margin-top: -2rem !important;
}

.margin-bottom-neg-4 {
  margin-bottom: -2rem !important;
}

.margin-y-neg-5 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.margin-top-neg-5 {
  margin-top: -2.5rem !important;
}

.margin-bottom-neg-5 {
  margin-bottom: -2.5rem !important;
}

.margin-y-neg-6 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.margin-top-neg-6 {
  margin-top: -3rem !important;
}

.margin-bottom-neg-6 {
  margin-bottom: -3rem !important;
}

.margin-y-neg-7 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.margin-top-neg-7 {
  margin-top: -3.5rem !important;
}

.margin-bottom-neg-7 {
  margin-bottom: -3.5rem !important;
}

.margin-y-neg-8 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.margin-top-neg-8 {
  margin-top: -4rem !important;
}

.margin-bottom-neg-8 {
  margin-bottom: -4rem !important;
}

.margin-y-neg-9 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.margin-top-neg-9 {
  margin-top: -4.5rem !important;
}

.margin-bottom-neg-9 {
  margin-bottom: -4.5rem !important;
}

.margin-y-neg-10 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.margin-top-neg-10 {
  margin-top: -5rem !important;
}

.margin-bottom-neg-10 {
  margin-bottom: -5rem !important;
}

.margin-y-neg-15 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important;
}

.margin-top-neg-15 {
  margin-top: -7.5rem !important;
}

.margin-bottom-neg-15 {
  margin-bottom: -7.5rem !important;
}

.margin-y-4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.margin-top-4 {
  margin-top: 2rem !important;
}

.margin-bottom-4 {
  margin-bottom: 2rem !important;
}

.margin-y-5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.margin-top-5 {
  margin-top: 2.5rem !important;
}

.margin-bottom-5 {
  margin-bottom: 2.5rem !important;
}

.margin-y-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.margin-top-6 {
  margin-top: 3rem !important;
}

.margin-bottom-6 {
  margin-bottom: 3rem !important;
}

.margin-y-7 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.margin-top-7 {
  margin-top: 3.5rem !important;
}

.margin-bottom-7 {
  margin-bottom: 3.5rem !important;
}

.margin-y-8 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.margin-top-8 {
  margin-top: 4rem !important;
}

.margin-bottom-8 {
  margin-bottom: 4rem !important;
}

.margin-y-9 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.margin-top-9 {
  margin-top: 4.5rem !important;
}

.margin-bottom-9 {
  margin-bottom: 4.5rem !important;
}

.margin-y-10 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.margin-top-10 {
  margin-top: 5rem !important;
}

.margin-bottom-10 {
  margin-bottom: 5rem !important;
}

.margin-y-15 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.margin-top-15 {
  margin-top: 7.5rem !important;
}

.margin-bottom-15 {
  margin-bottom: 7.5rem !important;
}

.margin-y-05em {
  margin-top: .5em !important;
  margin-bottom: .5em !important;
}

.margin-top-05em {
  margin-top: .5em !important;
}

.margin-bottom-05em {
  margin-bottom: .5em !important;
}

.margin-y-1em {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.margin-top-1em {
  margin-top: 1em !important;
}

.margin-bottom-1em {
  margin-bottom: 1em !important;
}

.margin-y-105em {
  margin-top: 1.5em !important;
  margin-bottom: 1.5em !important;
}

.margin-top-105em {
  margin-top: 1.5em !important;
}

.margin-bottom-105em {
  margin-bottom: 1.5em !important;
}

.margin-y-2em {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.margin-top-2em {
  margin-top: 2em !important;
}

.margin-bottom-2em {
  margin-bottom: 2em !important;
}

.margin-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-y-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.margin-top-auto {
  margin-top: auto !important;
}

.margin-bottom-auto {
  margin-bottom: auto !important;
}

.margin-x-1px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.margin-right-1px {
  margin-right: 1px !important;
}

.margin-left-1px {
  margin-left: 1px !important;
}

.margin-x-2px {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.margin-right-2px {
  margin-right: 2px !important;
}

.margin-left-2px {
  margin-left: 2px !important;
}

.margin-x-05 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.margin-right-05 {
  margin-right: .25rem !important;
}

.margin-left-05 {
  margin-left: .25rem !important;
}

.margin-x-1 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.margin-right-1 {
  margin-right: .5rem !important;
}

.margin-left-1 {
  margin-left: .5rem !important;
}

.margin-x-105 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.margin-right-105 {
  margin-right: .75rem !important;
}

.margin-left-105 {
  margin-left: .75rem !important;
}

.margin-x-2 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.margin-right-2 {
  margin-right: 1rem !important;
}

.margin-left-2 {
  margin-left: 1rem !important;
}

.margin-x-205 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.margin-right-205 {
  margin-right: 1.25rem !important;
}

.margin-left-205 {
  margin-left: 1.25rem !important;
}

.margin-x-3 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.margin-right-3 {
  margin-right: 1.5rem !important;
}

.margin-left-3 {
  margin-left: 1.5rem !important;
}

.margin-x-neg-1px {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.margin-right-neg-1px {
  margin-right: -1px !important;
}

.margin-left-neg-1px {
  margin-left: -1px !important;
}

.margin-x-neg-2px {
  margin-left: -2px !important;
  margin-right: -2px !important;
}

.margin-right-neg-2px {
  margin-right: -2px !important;
}

.margin-left-neg-2px {
  margin-left: -2px !important;
}

.margin-x-neg-05 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.margin-right-neg-05 {
  margin-right: -.25rem !important;
}

.margin-left-neg-05 {
  margin-left: -.25rem !important;
}

.margin-x-neg-1 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.margin-right-neg-1 {
  margin-right: -.5rem !important;
}

.margin-left-neg-1 {
  margin-left: -.5rem !important;
}

.margin-x-neg-105 {
  margin-left: -.75rem !important;
  margin-right: -.75rem !important;
}

.margin-right-neg-105 {
  margin-right: -.75rem !important;
}

.margin-left-neg-105 {
  margin-left: -.75rem !important;
}

.margin-x-neg-2 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.margin-right-neg-2 {
  margin-right: -1rem !important;
}

.margin-left-neg-2 {
  margin-left: -1rem !important;
}

.margin-x-neg-205 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.margin-right-neg-205 {
  margin-right: -1.25rem !important;
}

.margin-left-neg-205 {
  margin-left: -1.25rem !important;
}

.margin-x-neg-3 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.margin-right-neg-3 {
  margin-right: -1.5rem !important;
}

.margin-left-neg-3 {
  margin-left: -1.5rem !important;
}

.margin-x-neg-4 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.margin-right-neg-4 {
  margin-right: -2rem !important;
}

.margin-left-neg-4 {
  margin-left: -2rem !important;
}

.margin-x-neg-5 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.margin-right-neg-5 {
  margin-right: -2.5rem !important;
}

.margin-left-neg-5 {
  margin-left: -2.5rem !important;
}

.margin-x-neg-6 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.margin-right-neg-6 {
  margin-right: -3rem !important;
}

.margin-left-neg-6 {
  margin-left: -3rem !important;
}

.margin-x-neg-7 {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

.margin-right-neg-7 {
  margin-right: -3.5rem !important;
}

.margin-left-neg-7 {
  margin-left: -3.5rem !important;
}

.margin-x-neg-8 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.margin-right-neg-8 {
  margin-right: -4rem !important;
}

.margin-left-neg-8 {
  margin-left: -4rem !important;
}

.margin-x-neg-9 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}

.margin-right-neg-9 {
  margin-right: -4.5rem !important;
}

.margin-left-neg-9 {
  margin-left: -4.5rem !important;
}

.margin-x-neg-10 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.margin-right-neg-10 {
  margin-right: -5rem !important;
}

.margin-left-neg-10 {
  margin-left: -5rem !important;
}

.margin-x-neg-15 {
  margin-left: -7.5rem !important;
  margin-right: -7.5rem !important;
}

.margin-right-neg-15 {
  margin-right: -7.5rem !important;
}

.margin-left-neg-15 {
  margin-left: -7.5rem !important;
}

.margin-x-4 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.margin-right-4 {
  margin-right: 2rem !important;
}

.margin-left-4 {
  margin-left: 2rem !important;
}

.margin-x-5 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.margin-right-5 {
  margin-right: 2.5rem !important;
}

.margin-left-5 {
  margin-left: 2.5rem !important;
}

.margin-x-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.margin-right-6 {
  margin-right: 3rem !important;
}

.margin-left-6 {
  margin-left: 3rem !important;
}

.margin-x-7 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.margin-right-7 {
  margin-right: 3.5rem !important;
}

.margin-left-7 {
  margin-left: 3.5rem !important;
}

.margin-x-8 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.margin-right-8 {
  margin-right: 4rem !important;
}

.margin-left-8 {
  margin-left: 4rem !important;
}

.margin-x-9 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

.margin-right-9 {
  margin-right: 4.5rem !important;
}

.margin-left-9 {
  margin-left: 4.5rem !important;
}

.margin-x-10 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.margin-right-10 {
  margin-right: 5rem !important;
}

.margin-left-10 {
  margin-left: 5rem !important;
}

.margin-x-15 {
  margin-left: 7.5rem !important;
  margin-right: 7.5rem !important;
}

.margin-right-15 {
  margin-right: 7.5rem !important;
}

.margin-left-15 {
  margin-left: 7.5rem !important;
}

.margin-x-card {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

.margin-right-card {
  margin-right: 10rem !important;
}

.margin-left-card {
  margin-left: 10rem !important;
}

.margin-x-card-lg {
  margin-left: 15rem !important;
  margin-right: 15rem !important;
}

.margin-right-card-lg {
  margin-right: 15rem !important;
}

.margin-left-card-lg {
  margin-left: 15rem !important;
}

.margin-x-mobile {
  margin-left: 20rem !important;
  margin-right: 20rem !important;
}

.margin-right-mobile {
  margin-right: 20rem !important;
}

.margin-left-mobile {
  margin-left: 20rem !important;
}

.margin-x-05em {
  margin-left: .5em !important;
  margin-right: .5em !important;
}

.margin-right-05em {
  margin-right: .5em !important;
}

.margin-left-05em {
  margin-left: .5em !important;
}

.margin-x-1em {
  margin-left: 1em !important;
  margin-right: 1em !important;
}

.margin-right-1em {
  margin-right: 1em !important;
}

.margin-left-1em {
  margin-left: 1em !important;
}

.margin-x-105em {
  margin-left: 1.5em !important;
  margin-right: 1.5em !important;
}

.margin-right-105em {
  margin-right: 1.5em !important;
}

.margin-left-105em {
  margin-left: 1.5em !important;
}

.margin-x-2em {
  margin-left: 2em !important;
  margin-right: 2em !important;
}

.margin-right-2em {
  margin-right: 2em !important;
}

.margin-left-2em {
  margin-left: 2em !important;
}

.margin-x-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.margin-right-0 {
  margin-right: 0 !important;
}

.margin-left-0 {
  margin-left: 0 !important;
}

.margin-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.margin-right-auto {
  margin-right: auto !important;
}

.margin-left-auto {
  margin-left: auto !important;
}

.maxh-05 {
  max-height: .25rem !important;
}

.maxh-1 {
  max-height: .5rem !important;
}

.maxh-105 {
  max-height: .75rem !important;
}

.maxh-2 {
  max-height: 1rem !important;
}

.maxh-205 {
  max-height: 1.25rem !important;
}

.maxh-3 {
  max-height: 1.5rem !important;
}

.maxh-4 {
  max-height: 2rem !important;
}

.maxh-5 {
  max-height: 2.5rem !important;
}

.maxh-6 {
  max-height: 3rem !important;
}

.maxh-7 {
  max-height: 3.5rem !important;
}

.maxh-8 {
  max-height: 4rem !important;
}

.maxh-9 {
  max-height: 4.5rem !important;
}

.maxh-10 {
  max-height: 5rem !important;
}

.maxh-15 {
  max-height: 7.5rem !important;
}

.maxh-card {
  max-height: 10rem !important;
}

.maxh-card-lg {
  max-height: 15rem !important;
}

.maxh-mobile {
  max-height: 20rem !important;
}

.maxh-mobile-lg {
  max-height: 30rem !important;
}

.maxh-tablet {
  max-height: 40rem !important;
}

.maxh-tablet-lg {
  max-height: 55rem !important;
}

.maxh-none {
  max-height: none !important;
}

.maxh-viewport {
  max-height: 100vh !important;
}

.maxw-05 {
  max-width: .25rem !important;
}

.maxw-1 {
  max-width: .5rem !important;
}

.maxw-105 {
  max-width: .75rem !important;
}

.maxw-2 {
  max-width: 1rem !important;
}

.maxw-205 {
  max-width: 1.25rem !important;
}

.maxw-3 {
  max-width: 1.5rem !important;
}

.maxw-4 {
  max-width: 2rem !important;
}

.maxw-5 {
  max-width: 2.5rem !important;
}

.maxw-6 {
  max-width: 3rem !important;
}

.maxw-7 {
  max-width: 3.5rem !important;
}

.maxw-8 {
  max-width: 4rem !important;
}

.maxw-9 {
  max-width: 4.5rem !important;
}

.maxw-10 {
  max-width: 5rem !important;
}

.maxw-15 {
  max-width: 7.5rem !important;
}

.maxw-card {
  max-width: 10rem !important;
}

.maxw-card-lg {
  max-width: 15rem !important;
}

.maxw-mobile {
  max-width: 20rem !important;
}

.maxw-mobile-lg {
  max-width: 30rem !important;
}

.maxw-tablet {
  max-width: 40rem !important;
}

.maxw-tablet-lg {
  max-width: 55rem !important;
}

.maxw-desktop {
  max-width: 64rem !important;
}

.maxw-desktop-lg {
  max-width: 75rem !important;
}

.maxw-widescreen {
  max-width: 87.5rem !important;
}

.maxw-none {
  max-width: none !important;
}

.maxw-full {
  max-width: 100% !important;
}

.measure-1 {
  max-width: 44ex !important;
}

.measure-2 {
  max-width: 60ex !important;
}

.measure-3 {
  max-width: 64ex !important;
}

.measure-4 {
  max-width: 68ex !important;
}

.measure-5 {
  max-width: 72ex !important;
}

.measure-6 {
  max-width: 88ex !important;
}

.measure-none {
  max-width: none !important;
}

.minh-1px {
  min-height: 1px !important;
}

.minh-2px {
  min-height: 2px !important;
}

.minh-05 {
  min-height: .25rem !important;
}

.minh-1 {
  min-height: .5rem !important;
}

.minh-105 {
  min-height: .75rem !important;
}

.minh-2 {
  min-height: 1rem !important;
}

.minh-205 {
  min-height: 1.25rem !important;
}

.minh-3 {
  min-height: 1.5rem !important;
}

.minh-4 {
  min-height: 2rem !important;
}

.minh-5 {
  min-height: 2.5rem !important;
}

.minh-6 {
  min-height: 3rem !important;
}

.minh-7 {
  min-height: 3.5rem !important;
}

.minh-8 {
  min-height: 4rem !important;
}

.minh-9 {
  min-height: 4.5rem !important;
}

.minh-10 {
  min-height: 5rem !important;
}

.minh-15 {
  min-height: 7.5rem !important;
}

.minh-card {
  min-height: 10rem !important;
}

.minh-card-lg {
  min-height: 15rem !important;
}

.minh-mobile {
  min-height: 20rem !important;
}

.minh-mobile-lg {
  min-height: 30rem !important;
}

.minh-tablet {
  min-height: 40rem !important;
}

.minh-tablet-lg {
  min-height: 55rem !important;
}

.minh-0 {
  min-height: 0 !important;
}

.minh-full {
  min-height: 100% !important;
}

.minh-viewport {
  min-height: 100vh !important;
}

.minw-05 {
  min-width: .25rem !important;
}

.minw-1 {
  min-width: .5rem !important;
}

.minw-105 {
  min-width: .75rem !important;
}

.minw-2 {
  min-width: 1rem !important;
}

.minw-205 {
  min-width: 1.25rem !important;
}

.minw-3 {
  min-width: 1.5rem !important;
}

.minw-4 {
  min-width: 2rem !important;
}

.minw-5 {
  min-width: 2.5rem !important;
}

.minw-6 {
  min-width: 3rem !important;
}

.minw-7 {
  min-width: 3.5rem !important;
}

.minw-8 {
  min-width: 4rem !important;
}

.minw-9 {
  min-width: 4.5rem !important;
}

.minw-10 {
  min-width: 5rem !important;
}

.minw-15 {
  min-width: 7.5rem !important;
}

.minw-0 {
  min-width: 0 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: .1 !important;
}

.opacity-20 {
  opacity: .2 !important;
}

.opacity-30 {
  opacity: .3 !important;
}

.opacity-40 {
  opacity: .4 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-70 {
  opacity: .7 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.opacity-90 {
  opacity: .9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.order-first {
  order: -1 !important;
}

.order-last {
  order: 999 !important;
}

.order-initial {
  order: initial !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-6 {
  order: 6 !important;
}

.order-7 {
  order: 7 !important;
}

.order-8 {
  order: 8 !important;
}

.order-9 {
  order: 9 !important;
}

.order-10 {
  order: 10 !important;
}

.order-11 {
  order: 11 !important;
}

.outline-1px {
  outline: 1px solid !important;
}

.outline-2px {
  outline: 2px solid !important;
}

.outline-0 {
  outline: 0 solid !important;
}

.outline-05 {
  outline: .25rem solid !important;
}

.outline-transparent {
  outline-color: #0000 !important;
}

.outline-black {
  outline-color: #000 !important;
}

.outline-white {
  outline-color: #fff !important;
}

.outline-red {
  outline-color: #e52207 !important;
}

.outline-orange {
  outline-color: #e66f0e !important;
}

.outline-gold {
  outline-color: #ffbe2e !important;
}

.outline-yellow {
  outline-color: #fee685 !important;
}

.outline-green {
  outline-color: #538200 !important;
}

.outline-mint {
  outline-color: #04c585 !important;
}

.outline-cyan {
  outline-color: #009ec1 !important;
}

.outline-blue {
  outline-color: #0076d6 !important;
}

.outline-indigo {
  outline-color: #676cc8 !important;
}

.outline-violet {
  outline-color: #8168b3 !important;
}

.outline-magenta {
  outline-color: #d72d79 !important;
}

.outline-gray-5 {
  outline-color: #f0f0f0 !important;
}

.outline-gray-10 {
  outline-color: #e6e6e6 !important;
}

.outline-gray-30 {
  outline-color: #adadad !important;
}

.outline-gray-50 {
  outline-color: #757575 !important;
}

.outline-gray-70 {
  outline-color: #454545 !important;
}

.outline-gray-90 {
  outline-color: #1b1b1b !important;
}

.outline-base-lightest {
  outline-color: #f0f0f0 !important;
}

.outline-base-lighter {
  outline-color: #dfe1e2 !important;
}

.outline-base-light {
  outline-color: #a9aeb1 !important;
}

.outline-base {
  outline-color: #71767a !important;
}

.outline-base-dark {
  outline-color: #565c65 !important;
}

.outline-base-darker {
  outline-color: #3d4551 !important;
}

.outline-base-darkest {
  outline-color: #2d2e2f !important;
}

.outline-ink {
  outline-color: #1c1d1f !important;
}

.outline-primary-lighter {
  outline-color: #d9e8f6 !important;
}

.outline-primary-light {
  outline-color: #73b3e7 !important;
}

.outline-primary {
  outline-color: #005ea2 !important;
}

.outline-primary-vivid {
  outline-color: #0050d8 !important;
}

.outline-primary-dark {
  outline-color: #1a4480 !important;
}

.outline-primary-darker {
  outline-color: #162e51 !important;
}

.outline-secondary-lighter {
  outline-color: #f3e1e4 !important;
}

.outline-secondary-light {
  outline-color: #f2938c !important;
}

.outline-secondary {
  outline-color: #d83933 !important;
}

.outline-secondary-vivid {
  outline-color: #e41d3d !important;
}

.outline-secondary-dark {
  outline-color: #b50909 !important;
}

.outline-secondary-darker {
  outline-color: #8b0a03 !important;
}

.outline-accent-warm-darker {
  outline-color: #775540 !important;
}

.outline-accent-warm-dark {
  outline-color: #c05600 !important;
}

.outline-accent-warm {
  outline-color: #fa9441 !important;
}

.outline-accent-warm-light {
  outline-color: #ffbc78 !important;
}

.outline-accent-warm-lighter {
  outline-color: #f2e4d4 !important;
}

.outline-accent-cool-darker {
  outline-color: #07648d !important;
}

.outline-accent-cool-dark {
  outline-color: #28a0cb !important;
}

.outline-accent-cool {
  outline-color: #00bde3 !important;
}

.outline-accent-cool-light {
  outline-color: #97d4ea !important;
}

.outline-accent-cool-lighter {
  outline-color: #e1f3f8 !important;
}

.outline-error-lighter {
  outline-color: #f4e3db !important;
}

.outline-error-light {
  outline-color: #f39268 !important;
}

.outline-error {
  outline-color: #d54309 !important;
}

.outline-error-dark {
  outline-color: #b50909 !important;
}

.outline-error-darker {
  outline-color: #6f3331 !important;
}

.outline-warning-lighter {
  outline-color: #faf3d1 !important;
}

.outline-warning-light {
  outline-color: #fee685 !important;
}

.outline-warning {
  outline-color: #ffbe2e !important;
}

.outline-warning-dark {
  outline-color: #e5a000 !important;
}

.outline-warning-darker {
  outline-color: #936f38 !important;
}

.outline-success-lighter {
  outline-color: #ecf3ec !important;
}

.outline-success-light {
  outline-color: #70e17b !important;
}

.outline-success {
  outline-color: #00a91c !important;
}

.outline-success-dark {
  outline-color: #008817 !important;
}

.outline-success-darker {
  outline-color: #216e1f !important;
}

.outline-info-lighter {
  outline-color: #e7f6f8 !important;
}

.outline-info-light {
  outline-color: #99deea !important;
}

.outline-info {
  outline-color: #00bde3 !important;
}

.outline-info-dark {
  outline-color: #009ec1 !important;
}

.outline-info-darker {
  outline-color: #2e6276 !important;
}

.outline-disabled-lighter {
  outline-color: #c9c9c9 !important;
}

.outline-disabled-light {
  outline-color: #919191 !important;
}

.outline-disabled {
  outline-color: #757575 !important;
}

.outline-disabled-dark {
  outline-color: #454545 !important;
}

.outline-disabled-darker {
  outline-color: #1b1b1b !important;
}

.outline-emergency {
  outline-color: #9c3d10 !important;
}

.outline-emergency-dark {
  outline-color: #332d29 !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.padding-1px {
  padding: 1px !important;
}

.padding-y-1px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.padding-x-1px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.padding-top-1px {
  padding-top: 1px !important;
}

.padding-right-1px {
  padding-right: 1px !important;
}

.padding-bottom-1px {
  padding-bottom: 1px !important;
}

.padding-left-1px {
  padding-left: 1px !important;
}

.padding-2px {
  padding: 2px !important;
}

.padding-y-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.padding-x-2px {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.padding-top-2px {
  padding-top: 2px !important;
}

.padding-right-2px {
  padding-right: 2px !important;
}

.padding-bottom-2px {
  padding-bottom: 2px !important;
}

.padding-left-2px {
  padding-left: 2px !important;
}

.padding-05 {
  padding: .25rem !important;
}

.padding-y-05 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.padding-x-05 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.padding-top-05 {
  padding-top: .25rem !important;
}

.padding-right-05 {
  padding-right: .25rem !important;
}

.padding-bottom-05 {
  padding-bottom: .25rem !important;
}

.padding-left-05 {
  padding-left: .25rem !important;
}

.padding-1 {
  padding: .5rem !important;
}

.padding-y-1 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.padding-x-1 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.padding-top-1 {
  padding-top: .5rem !important;
}

.padding-right-1 {
  padding-right: .5rem !important;
}

.padding-bottom-1 {
  padding-bottom: .5rem !important;
}

.padding-left-1 {
  padding-left: .5rem !important;
}

.padding-105 {
  padding: .75rem !important;
}

.padding-y-105 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.padding-x-105 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.padding-top-105 {
  padding-top: .75rem !important;
}

.padding-right-105 {
  padding-right: .75rem !important;
}

.padding-bottom-105 {
  padding-bottom: .75rem !important;
}

.padding-left-105 {
  padding-left: .75rem !important;
}

.padding-2 {
  padding: 1rem !important;
}

.padding-y-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.padding-x-2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.padding-top-2 {
  padding-top: 1rem !important;
}

.padding-right-2 {
  padding-right: 1rem !important;
}

.padding-bottom-2 {
  padding-bottom: 1rem !important;
}

.padding-left-2 {
  padding-left: 1rem !important;
}

.padding-205 {
  padding: 1.25rem !important;
}

.padding-y-205 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.padding-x-205 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.padding-top-205 {
  padding-top: 1.25rem !important;
}

.padding-right-205 {
  padding-right: 1.25rem !important;
}

.padding-bottom-205 {
  padding-bottom: 1.25rem !important;
}

.padding-left-205 {
  padding-left: 1.25rem !important;
}

.padding-3 {
  padding: 1.5rem !important;
}

.padding-y-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.padding-x-3 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.padding-top-3 {
  padding-top: 1.5rem !important;
}

.padding-right-3 {
  padding-right: 1.5rem !important;
}

.padding-bottom-3 {
  padding-bottom: 1.5rem !important;
}

.padding-left-3 {
  padding-left: 1.5rem !important;
}

.padding-4 {
  padding: 2rem !important;
}

.padding-y-4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.padding-x-4 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.padding-top-4 {
  padding-top: 2rem !important;
}

.padding-right-4 {
  padding-right: 2rem !important;
}

.padding-bottom-4 {
  padding-bottom: 2rem !important;
}

.padding-left-4 {
  padding-left: 2rem !important;
}

.padding-5 {
  padding: 2.5rem !important;
}

.padding-y-5 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.padding-x-5 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.padding-top-5 {
  padding-top: 2.5rem !important;
}

.padding-right-5 {
  padding-right: 2.5rem !important;
}

.padding-bottom-5 {
  padding-bottom: 2.5rem !important;
}

.padding-left-5 {
  padding-left: 2.5rem !important;
}

.padding-6 {
  padding: 3rem !important;
}

.padding-y-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.padding-x-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.padding-top-6 {
  padding-top: 3rem !important;
}

.padding-right-6 {
  padding-right: 3rem !important;
}

.padding-bottom-6 {
  padding-bottom: 3rem !important;
}

.padding-left-6 {
  padding-left: 3rem !important;
}

.padding-7 {
  padding: 3.5rem !important;
}

.padding-y-7 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.padding-x-7 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.padding-top-7 {
  padding-top: 3.5rem !important;
}

.padding-right-7 {
  padding-right: 3.5rem !important;
}

.padding-bottom-7 {
  padding-bottom: 3.5rem !important;
}

.padding-left-7 {
  padding-left: 3.5rem !important;
}

.padding-8 {
  padding: 4rem !important;
}

.padding-y-8 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.padding-x-8 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.padding-top-8 {
  padding-top: 4rem !important;
}

.padding-right-8 {
  padding-right: 4rem !important;
}

.padding-bottom-8 {
  padding-bottom: 4rem !important;
}

.padding-left-8 {
  padding-left: 4rem !important;
}

.padding-9 {
  padding: 4.5rem !important;
}

.padding-y-9 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.padding-x-9 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.padding-top-9 {
  padding-top: 4.5rem !important;
}

.padding-right-9 {
  padding-right: 4.5rem !important;
}

.padding-bottom-9 {
  padding-bottom: 4.5rem !important;
}

.padding-left-9 {
  padding-left: 4.5rem !important;
}

.padding-10 {
  padding: 5rem !important;
}

.padding-y-10 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.padding-x-10 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.padding-top-10 {
  padding-top: 5rem !important;
}

.padding-right-10 {
  padding-right: 5rem !important;
}

.padding-bottom-10 {
  padding-bottom: 5rem !important;
}

.padding-left-10 {
  padding-left: 5rem !important;
}

.padding-15 {
  padding: 7.5rem !important;
}

.padding-y-15 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.padding-x-15 {
  padding-left: 7.5rem !important;
  padding-right: 7.5rem !important;
}

.padding-top-15 {
  padding-top: 7.5rem !important;
}

.padding-right-15 {
  padding-right: 7.5rem !important;
}

.padding-bottom-15 {
  padding-bottom: 7.5rem !important;
}

.padding-left-15 {
  padding-left: 7.5rem !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.padding-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.pin-all {
  position: absolute !important;
  inset: 0 !important;
}

.pin-x {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
}

.pin-y {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
}

.pin-bottom {
  position: absolute !important;
  bottom: 0 !important;
}

.pin-left {
  position: absolute !important;
  left: 0 !important;
}

.pin-right {
  position: absolute !important;
  right: 0 !important;
}

.pin-top {
  position: absolute !important;
  top: 0 !important;
}

.pin-none {
  position: static !important;
  inset: auto !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-relative {
  position: relative !important;
}

.position-static {
  position: static !important;
}

.position-sticky {
  position: sticky !important;
}

.right-1px {
  right: 1px !important;
}

.right-2px {
  right: 2px !important;
}

.right-05 {
  right: .25rem !important;
}

.right-1 {
  right: .5rem !important;
}

.right-105 {
  right: .75rem !important;
}

.right-2 {
  right: 1rem !important;
}

.right-205 {
  right: 1.25rem !important;
}

.right-3 {
  right: 1.5rem !important;
}

.right-neg-1px {
  right: -1px !important;
}

.right-neg-2px {
  right: -2px !important;
}

.right-neg-05 {
  right: -.25rem !important;
}

.right-neg-1 {
  right: -.5rem !important;
}

.right-neg-105 {
  right: -.75rem !important;
}

.right-neg-2 {
  right: -1rem !important;
}

.right-neg-205 {
  right: -1.25rem !important;
}

.right-neg-3 {
  right: -1.5rem !important;
}

.right-0 {
  right: 0 !important;
}

.right-auto {
  right: auto !important;
}

.right-full {
  right: 100% !important;
}

.square-1px {
  width: 1px !important;
  height: 1px !important;
}

.square-2px {
  width: 2px !important;
  height: 2px !important;
}

.square-05 {
  width: .25rem !important;
  height: .25rem !important;
}

.square-1 {
  width: .5rem !important;
  height: .5rem !important;
}

.square-105 {
  width: .75rem !important;
  height: .75rem !important;
}

.square-2 {
  width: 1rem !important;
  height: 1rem !important;
}

.square-205 {
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.square-3 {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.square-4 {
  width: 2rem !important;
  height: 2rem !important;
}

.square-5 {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.square-6 {
  width: 3rem !important;
  height: 3rem !important;
}

.square-7 {
  width: 3.5rem !important;
  height: 3.5rem !important;
}

.square-8 {
  width: 4rem !important;
  height: 4rem !important;
}

.square-9 {
  width: 4.5rem !important;
  height: 4.5rem !important;
}

.square-10 {
  width: 5rem !important;
  height: 5rem !important;
}

.square-15 {
  width: 7.5rem !important;
  height: 7.5rem !important;
}

.square-card {
  width: 10rem !important;
  height: 10rem !important;
}

.square-card-lg {
  width: 15rem !important;
  height: 15rem !important;
}

.square-mobile {
  width: 20rem !important;
  height: 20rem !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-justify {
  text-align: justify !important;
}

.text-right {
  text-align: right !important;
}

.text-strike, .hover\:text-strike:hover {
  text-decoration: line-through !important;
}

.text-underline, .hover\:text-underline:hover {
  text-decoration: underline !important;
}

.text-no-underline, .hover\:text-no-underline:hover, .text-no-strike, .hover\:text-no-strike:hover {
  text-decoration: none !important;
}

.underline-red, .hover\:underline-red:hover {
  text-decoration-color: #e52207 !important;
}

.underline-orange, .hover\:underline-orange:hover {
  text-decoration-color: #e66f0e !important;
}

.underline-gold, .hover\:underline-gold:hover {
  text-decoration-color: #ffbe2e !important;
}

.underline-yellow, .hover\:underline-yellow:hover {
  text-decoration-color: #fee685 !important;
}

.underline-green, .hover\:underline-green:hover {
  text-decoration-color: #538200 !important;
}

.underline-mint, .hover\:underline-mint:hover {
  text-decoration-color: #04c585 !important;
}

.underline-cyan, .hover\:underline-cyan:hover {
  text-decoration-color: #009ec1 !important;
}

.underline-blue, .hover\:underline-blue:hover {
  text-decoration-color: #0076d6 !important;
}

.underline-indigo, .hover\:underline-indigo:hover {
  text-decoration-color: #676cc8 !important;
}

.underline-violet, .hover\:underline-violet:hover {
  text-decoration-color: #8168b3 !important;
}

.underline-magenta, .hover\:underline-magenta:hover {
  text-decoration-color: #d72d79 !important;
}

.underline-gray-5, .hover\:underline-gray-5:hover {
  text-decoration-color: #f0f0f0 !important;
}

.underline-gray-10, .hover\:underline-gray-10:hover {
  text-decoration-color: #e6e6e6 !important;
}

.underline-gray-30, .hover\:underline-gray-30:hover {
  text-decoration-color: #adadad !important;
}

.underline-gray-50, .hover\:underline-gray-50:hover {
  text-decoration-color: #757575 !important;
}

.underline-gray-70, .hover\:underline-gray-70:hover {
  text-decoration-color: #454545 !important;
}

.underline-gray-90, .hover\:underline-gray-90:hover {
  text-decoration-color: #1b1b1b !important;
}

.underline-base-lightest, .hover\:underline-base-lightest:hover {
  text-decoration-color: #f0f0f0 !important;
}

.underline-base-lighter, .hover\:underline-base-lighter:hover {
  text-decoration-color: #dfe1e2 !important;
}

.underline-base-light, .hover\:underline-base-light:hover {
  text-decoration-color: #a9aeb1 !important;
}

.underline-base, .hover\:underline-base:hover {
  text-decoration-color: #71767a !important;
}

.underline-base-dark, .hover\:underline-base-dark:hover {
  text-decoration-color: #565c65 !important;
}

.underline-base-darker, .hover\:underline-base-darker:hover {
  text-decoration-color: #3d4551 !important;
}

.underline-base-darkest, .hover\:underline-base-darkest:hover {
  text-decoration-color: #2d2e2f !important;
}

.underline-ink, .hover\:underline-ink:hover {
  text-decoration-color: #1c1d1f !important;
}

.underline-primary-lighter, .hover\:underline-primary-lighter:hover {
  text-decoration-color: #d9e8f6 !important;
}

.underline-primary-light, .hover\:underline-primary-light:hover {
  text-decoration-color: #73b3e7 !important;
}

.underline-primary, .hover\:underline-primary:hover {
  text-decoration-color: #005ea2 !important;
}

.underline-primary-vivid, .hover\:underline-primary-vivid:hover {
  text-decoration-color: #0050d8 !important;
}

.underline-primary-dark, .hover\:underline-primary-dark:hover {
  text-decoration-color: #1a4480 !important;
}

.underline-primary-darker, .hover\:underline-primary-darker:hover {
  text-decoration-color: #162e51 !important;
}

.underline-secondary-lighter, .hover\:underline-secondary-lighter:hover {
  text-decoration-color: #f3e1e4 !important;
}

.underline-secondary-light, .hover\:underline-secondary-light:hover {
  text-decoration-color: #f2938c !important;
}

.underline-secondary, .hover\:underline-secondary:hover {
  text-decoration-color: #d83933 !important;
}

.underline-secondary-vivid, .hover\:underline-secondary-vivid:hover {
  text-decoration-color: #e41d3d !important;
}

.underline-secondary-dark, .hover\:underline-secondary-dark:hover {
  text-decoration-color: #b50909 !important;
}

.underline-secondary-darker, .hover\:underline-secondary-darker:hover {
  text-decoration-color: #8b0a03 !important;
}

.underline-accent-warm-darker, .hover\:underline-accent-warm-darker:hover {
  text-decoration-color: #775540 !important;
}

.underline-accent-warm-dark, .hover\:underline-accent-warm-dark:hover {
  text-decoration-color: #c05600 !important;
}

.underline-accent-warm, .hover\:underline-accent-warm:hover {
  text-decoration-color: #fa9441 !important;
}

.underline-accent-warm-light, .hover\:underline-accent-warm-light:hover {
  text-decoration-color: #ffbc78 !important;
}

.underline-accent-warm-lighter, .hover\:underline-accent-warm-lighter:hover {
  text-decoration-color: #f2e4d4 !important;
}

.underline-accent-cool-darker, .hover\:underline-accent-cool-darker:hover {
  text-decoration-color: #07648d !important;
}

.underline-accent-cool-dark, .hover\:underline-accent-cool-dark:hover {
  text-decoration-color: #28a0cb !important;
}

.underline-accent-cool, .hover\:underline-accent-cool:hover {
  text-decoration-color: #00bde3 !important;
}

.underline-accent-cool-light, .hover\:underline-accent-cool-light:hover {
  text-decoration-color: #97d4ea !important;
}

.underline-accent-cool-lighter, .hover\:underline-accent-cool-lighter:hover {
  text-decoration-color: #e1f3f8 !important;
}

.underline-error-lighter, .hover\:underline-error-lighter:hover {
  text-decoration-color: #f4e3db !important;
}

.underline-error-light, .hover\:underline-error-light:hover {
  text-decoration-color: #f39268 !important;
}

.underline-error, .hover\:underline-error:hover {
  text-decoration-color: #d54309 !important;
}

.underline-error-dark, .hover\:underline-error-dark:hover {
  text-decoration-color: #b50909 !important;
}

.underline-error-darker, .hover\:underline-error-darker:hover {
  text-decoration-color: #6f3331 !important;
}

.underline-warning-lighter, .hover\:underline-warning-lighter:hover {
  text-decoration-color: #faf3d1 !important;
}

.underline-warning-light, .hover\:underline-warning-light:hover {
  text-decoration-color: #fee685 !important;
}

.underline-warning, .hover\:underline-warning:hover {
  text-decoration-color: #ffbe2e !important;
}

.underline-warning-dark, .hover\:underline-warning-dark:hover {
  text-decoration-color: #e5a000 !important;
}

.underline-warning-darker, .hover\:underline-warning-darker:hover {
  text-decoration-color: #936f38 !important;
}

.underline-success-lighter, .hover\:underline-success-lighter:hover {
  text-decoration-color: #ecf3ec !important;
}

.underline-success-light, .hover\:underline-success-light:hover {
  text-decoration-color: #70e17b !important;
}

.underline-success, .hover\:underline-success:hover {
  text-decoration-color: #00a91c !important;
}

.underline-success-dark, .hover\:underline-success-dark:hover {
  text-decoration-color: #008817 !important;
}

.underline-success-darker, .hover\:underline-success-darker:hover {
  text-decoration-color: #216e1f !important;
}

.underline-info-lighter, .hover\:underline-info-lighter:hover {
  text-decoration-color: #e7f6f8 !important;
}

.underline-info-light, .hover\:underline-info-light:hover {
  text-decoration-color: #99deea !important;
}

.underline-info, .hover\:underline-info:hover {
  text-decoration-color: #00bde3 !important;
}

.underline-info-dark, .hover\:underline-info-dark:hover {
  text-decoration-color: #009ec1 !important;
}

.underline-info-darker, .hover\:underline-info-darker:hover {
  text-decoration-color: #2e6276 !important;
}

.underline-disabled-lighter, .hover\:underline-disabled-lighter:hover {
  text-decoration-color: #c9c9c9 !important;
}

.underline-disabled-light, .hover\:underline-disabled-light:hover {
  text-decoration-color: #919191 !important;
}

.underline-disabled, .hover\:underline-disabled:hover {
  text-decoration-color: #757575 !important;
}

.underline-disabled-dark, .hover\:underline-disabled-dark:hover {
  text-decoration-color: #454545 !important;
}

.underline-disabled-darker, .hover\:underline-disabled-darker:hover {
  text-decoration-color: #1b1b1b !important;
}

.underline-emergency, .hover\:underline-emergency:hover {
  text-decoration-color: #9c3d10 !important;
}

.underline-emergency-dark, .hover\:underline-emergency-dark:hover {
  text-decoration-color: #332d29 !important;
}

.text-indent-0 {
  text-indent: 0 !important;
}

.text-indent-05 {
  text-indent: .25rem !important;
}

.text-indent-1 {
  text-indent: .5rem !important;
}

.text-indent-105 {
  text-indent: .75rem !important;
}

.text-indent-2 {
  text-indent: 1rem !important;
}

.text-indent-205 {
  text-indent: 1.25rem !important;
}

.text-indent-3 {
  text-indent: 1.5rem !important;
}

.text-indent-neg-05 {
  text-indent: -.25rem !important;
}

.text-indent-neg-1 {
  text-indent: -.5rem !important;
}

.text-indent-neg-105 {
  text-indent: -.75rem !important;
}

.text-indent-neg-2 {
  text-indent: -1rem !important;
}

.text-indent-neg-205 {
  text-indent: -1.25rem !important;
}

.text-indent-neg-3 {
  text-indent: -1.5rem !important;
}

.text-indent-4 {
  text-indent: 2rem !important;
}

.text-indent-5 {
  text-indent: 2.5rem !important;
}

.text-indent-6 {
  text-indent: 3rem !important;
}

.text-indent-7 {
  text-indent: 3.5rem !important;
}

.text-indent-8 {
  text-indent: 4rem !important;
}

.text-indent-9 {
  text-indent: 4.5rem !important;
}

.text-indent-10 {
  text-indent: 5rem !important;
}

.text-indent-15 {
  text-indent: 7.5rem !important;
}

.text-indent-neg-4 {
  text-indent: -2rem !important;
}

.text-indent-neg-5 {
  text-indent: -2.5rem !important;
}

.text-indent-neg-6 {
  text-indent: -3rem !important;
}

.text-indent-neg-7 {
  text-indent: -3.5rem !important;
}

.text-indent-neg-8 {
  text-indent: -4rem !important;
}

.text-indent-neg-9 {
  text-indent: -4.5rem !important;
}

.text-indent-neg-10 {
  text-indent: -5rem !important;
}

.text-indent-neg-15 {
  text-indent: -7.5rem !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-no-uppercase {
  text-transform: none !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-no-lowercase {
  text-transform: none !important;
}

.top-1px {
  top: 1px !important;
}

.top-2px {
  top: 2px !important;
}

.top-05 {
  top: .25rem !important;
}

.top-1 {
  top: .5rem !important;
}

.top-105 {
  top: .75rem !important;
}

.top-2 {
  top: 1rem !important;
}

.top-205 {
  top: 1.25rem !important;
}

.top-3 {
  top: 1.5rem !important;
}

.top-neg-1px {
  top: -1px !important;
}

.top-neg-2px {
  top: -2px !important;
}

.top-neg-05 {
  top: -.25rem !important;
}

.top-neg-1 {
  top: -.5rem !important;
}

.top-neg-105 {
  top: -.75rem !important;
}

.top-neg-2 {
  top: -1rem !important;
}

.top-neg-205 {
  top: -1.25rem !important;
}

.top-neg-3 {
  top: -1.5rem !important;
}

.top-0 {
  top: 0 !important;
}

.top-auto {
  top: auto !important;
}

.top-full {
  top: 100% !important;
}

.text-baseline {
  vertical-align: baseline !important;
}

.text-bottom {
  vertical-align: bottom !important;
}

.text-middle {
  vertical-align: middle !important;
}

.text-sub {
  vertical-align: sub !important;
}

.text-super {
  vertical-align: super !important;
}

.text-tbottom {
  vertical-align: text-bottom !important;
}

.text-ttop {
  vertical-align: text-top !important;
}

.text-top {
  vertical-align: top !important;
}

.text-pre {
  white-space: pre !important;
}

.text-pre-line {
  white-space: pre-line !important;
}

.text-pre-wrap {
  white-space: pre-wrap !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-no-wrap {
  white-space: nowrap !important;
}

.width-1px {
  width: 1px !important;
}

.width-2px {
  width: 2px !important;
}

.width-05 {
  width: .25rem !important;
}

.width-1 {
  width: .5rem !important;
}

.width-105 {
  width: .75rem !important;
}

.width-2 {
  width: 1rem !important;
}

.width-205 {
  width: 1.25rem !important;
}

.width-3 {
  width: 1.5rem !important;
}

.width-4 {
  width: 2rem !important;
}

.width-5 {
  width: 2.5rem !important;
}

.width-6 {
  width: 3rem !important;
}

.width-7 {
  width: 3.5rem !important;
}

.width-8 {
  width: 4rem !important;
}

.width-9 {
  width: 4.5rem !important;
}

.width-10 {
  width: 5rem !important;
}

.width-15 {
  width: 7.5rem !important;
}

.width-card {
  width: 10rem !important;
}

.width-card-lg {
  width: 15rem !important;
}

.width-mobile {
  width: 20rem !important;
}

.width-mobile-lg {
  width: 30rem !important;
}

.width-tablet {
  width: 40rem !important;
}

.width-tablet-lg {
  width: 55rem !important;
}

.width-desktop {
  width: 64rem !important;
}

.width-desktop-lg {
  width: 75rem !important;
}

.width-widescreen {
  width: 87.5rem !important;
}

.width-0 {
  width: 0 !important;
}

.width-full {
  width: 100% !important;
}

.width-auto {
  width: auto !important;
}

.z-auto {
  z-index: auto !important;
}

.z-bottom {
  z-index: -100 !important;
}

.z-top {
  z-index: 99999 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-100 {
  z-index: 100 !important;
}

.z-200 {
  z-index: 200 !important;
}

.z-300 {
  z-index: 300 !important;
}

.z-400 {
  z-index: 400 !important;
}

.z-500 {
  z-index: 500 !important;
}

@media (width >= 30em) {
  .mobile-lg\:border-1px, .mobile-lg\:hover\:border-1px:hover {
    border: 1px solid !important;
  }

  .mobile-lg\:border-y-1px, .mobile-lg\:hover\:border-y-1px:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .mobile-lg\:border-x-1px, .mobile-lg\:hover\:border-x-1px:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .mobile-lg\:border-top-1px, .mobile-lg\:hover\:border-top-1px:hover {
    border-top: 1px solid !important;
  }

  .mobile-lg\:border-right-1px, .mobile-lg\:hover\:border-right-1px:hover {
    border-right: 1px solid !important;
  }

  .mobile-lg\:border-bottom-1px, .mobile-lg\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid !important;
  }

  .mobile-lg\:border-left-1px, .mobile-lg\:hover\:border-left-1px:hover {
    border-left: 1px solid !important;
  }

  .mobile-lg\:border-2px, .mobile-lg\:hover\:border-2px:hover {
    border: 2px solid !important;
  }

  .mobile-lg\:border-y-2px, .mobile-lg\:hover\:border-y-2px:hover {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }

  .mobile-lg\:border-x-2px, .mobile-lg\:hover\:border-x-2px:hover {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }

  .mobile-lg\:border-top-2px, .mobile-lg\:hover\:border-top-2px:hover {
    border-top: 2px solid !important;
  }

  .mobile-lg\:border-right-2px, .mobile-lg\:hover\:border-right-2px:hover {
    border-right: 2px solid !important;
  }

  .mobile-lg\:border-bottom-2px, .mobile-lg\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid !important;
  }

  .mobile-lg\:border-left-2px, .mobile-lg\:hover\:border-left-2px:hover {
    border-left: 2px solid !important;
  }

  .mobile-lg\:border-05, .mobile-lg\:hover\:border-05:hover {
    border: .25rem solid !important;
  }

  .mobile-lg\:border-y-05, .mobile-lg\:hover\:border-y-05:hover {
    border-top: .25rem solid !important;
    border-bottom: .25rem solid !important;
  }

  .mobile-lg\:border-x-05, .mobile-lg\:hover\:border-x-05:hover {
    border-left: .25rem solid !important;
    border-right: .25rem solid !important;
  }

  .mobile-lg\:border-top-05, .mobile-lg\:hover\:border-top-05:hover {
    border-top: .25rem solid !important;
  }

  .mobile-lg\:border-right-05, .mobile-lg\:hover\:border-right-05:hover {
    border-right: .25rem solid !important;
  }

  .mobile-lg\:border-bottom-05, .mobile-lg\:hover\:border-bottom-05:hover {
    border-bottom: .25rem solid !important;
  }

  .mobile-lg\:border-left-05, .mobile-lg\:hover\:border-left-05:hover {
    border-left: .25rem solid !important;
  }

  .mobile-lg\:border-1, .mobile-lg\:hover\:border-1:hover {
    border: .5rem solid !important;
  }

  .mobile-lg\:border-y-1, .mobile-lg\:hover\:border-y-1:hover {
    border-top: .5rem solid !important;
    border-bottom: .5rem solid !important;
  }

  .mobile-lg\:border-x-1, .mobile-lg\:hover\:border-x-1:hover {
    border-left: .5rem solid !important;
    border-right: .5rem solid !important;
  }

  .mobile-lg\:border-top-1, .mobile-lg\:hover\:border-top-1:hover {
    border-top: .5rem solid !important;
  }

  .mobile-lg\:border-right-1, .mobile-lg\:hover\:border-right-1:hover {
    border-right: .5rem solid !important;
  }

  .mobile-lg\:border-bottom-1, .mobile-lg\:hover\:border-bottom-1:hover {
    border-bottom: .5rem solid !important;
  }

  .mobile-lg\:border-left-1, .mobile-lg\:hover\:border-left-1:hover {
    border-left: .5rem solid !important;
  }

  .mobile-lg\:border-105, .mobile-lg\:hover\:border-105:hover {
    border: .75rem solid !important;
  }

  .mobile-lg\:border-y-105, .mobile-lg\:hover\:border-y-105:hover {
    border-top: .75rem solid !important;
    border-bottom: .75rem solid !important;
  }

  .mobile-lg\:border-x-105, .mobile-lg\:hover\:border-x-105:hover {
    border-left: .75rem solid !important;
    border-right: .75rem solid !important;
  }

  .mobile-lg\:border-top-105, .mobile-lg\:hover\:border-top-105:hover {
    border-top: .75rem solid !important;
  }

  .mobile-lg\:border-right-105, .mobile-lg\:hover\:border-right-105:hover {
    border-right: .75rem solid !important;
  }

  .mobile-lg\:border-bottom-105, .mobile-lg\:hover\:border-bottom-105:hover {
    border-bottom: .75rem solid !important;
  }

  .mobile-lg\:border-left-105, .mobile-lg\:hover\:border-left-105:hover {
    border-left: .75rem solid !important;
  }

  .mobile-lg\:border-2, .mobile-lg\:hover\:border-2:hover {
    border: 1rem solid !important;
  }

  .mobile-lg\:border-y-2, .mobile-lg\:hover\:border-y-2:hover {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }

  .mobile-lg\:border-x-2, .mobile-lg\:hover\:border-x-2:hover {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }

  .mobile-lg\:border-top-2, .mobile-lg\:hover\:border-top-2:hover {
    border-top: 1rem solid !important;
  }

  .mobile-lg\:border-right-2, .mobile-lg\:hover\:border-right-2:hover {
    border-right: 1rem solid !important;
  }

  .mobile-lg\:border-bottom-2, .mobile-lg\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid !important;
  }

  .mobile-lg\:border-left-2, .mobile-lg\:hover\:border-left-2:hover {
    border-left: 1rem solid !important;
  }

  .mobile-lg\:border-205, .mobile-lg\:hover\:border-205:hover {
    border: 1.25rem solid !important;
  }

  .mobile-lg\:border-y-205, .mobile-lg\:hover\:border-y-205:hover {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }

  .mobile-lg\:border-x-205, .mobile-lg\:hover\:border-x-205:hover {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }

  .mobile-lg\:border-top-205, .mobile-lg\:hover\:border-top-205:hover {
    border-top: 1.25rem solid !important;
  }

  .mobile-lg\:border-right-205, .mobile-lg\:hover\:border-right-205:hover {
    border-right: 1.25rem solid !important;
  }

  .mobile-lg\:border-bottom-205, .mobile-lg\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid !important;
  }

  .mobile-lg\:border-left-205, .mobile-lg\:hover\:border-left-205:hover {
    border-left: 1.25rem solid !important;
  }

  .mobile-lg\:border-3, .mobile-lg\:hover\:border-3:hover {
    border: 1.5rem solid !important;
  }

  .mobile-lg\:border-y-3, .mobile-lg\:hover\:border-y-3:hover {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }

  .mobile-lg\:border-x-3, .mobile-lg\:hover\:border-x-3:hover {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }

  .mobile-lg\:border-top-3, .mobile-lg\:hover\:border-top-3:hover {
    border-top: 1.5rem solid !important;
  }

  .mobile-lg\:border-right-3, .mobile-lg\:hover\:border-right-3:hover {
    border-right: 1.5rem solid !important;
  }

  .mobile-lg\:border-bottom-3, .mobile-lg\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid !important;
  }

  .mobile-lg\:border-left-3, .mobile-lg\:hover\:border-left-3:hover {
    border-left: 1.5rem solid !important;
  }

  .mobile-lg\:border-0, .mobile-lg\:hover\:border-0:hover {
    border: 0 solid !important;
  }

  .mobile-lg\:border-y-0, .mobile-lg\:hover\:border-y-0:hover {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }

  .mobile-lg\:border-x-0, .mobile-lg\:hover\:border-x-0:hover {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }

  .mobile-lg\:border-top-0, .mobile-lg\:hover\:border-top-0:hover {
    border-top: 0 solid !important;
  }

  .mobile-lg\:border-right-0, .mobile-lg\:hover\:border-right-0:hover {
    border-right: 0 solid !important;
  }

  .mobile-lg\:border-bottom-0, .mobile-lg\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid !important;
  }

  .mobile-lg\:border-left-0, .mobile-lg\:hover\:border-left-0:hover {
    border-left: 0 solid !important;
  }

  .mobile-lg\:border, .mobile-lg\:hover\:border:hover {
    border: 1px solid !important;
  }

  .mobile-lg\:border-y, .mobile-lg\:hover\:border-y:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .mobile-lg\:border-x, .mobile-lg\:hover\:border-x:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .mobile-lg\:border-top, .mobile-lg\:hover\:border-top:hover {
    border-top: 1px solid !important;
  }

  .mobile-lg\:border-right, .mobile-lg\:hover\:border-right:hover {
    border-right: 1px solid !important;
  }

  .mobile-lg\:border-bottom, .mobile-lg\:hover\:border-bottom:hover {
    border-bottom: 1px solid !important;
  }

  .mobile-lg\:border-left, .mobile-lg\:hover\:border-left:hover {
    border-left: 1px solid !important;
  }

  .mobile-lg\:border-transparent, .mobile-lg\:hover\:border-transparent:hover {
    border-color: #0000 !important;
  }

  .mobile-lg\:border-black, .mobile-lg\:hover\:border-black:hover {
    border-color: #000 !important;
  }

  .mobile-lg\:border-white, .mobile-lg\:hover\:border-white:hover {
    border-color: #fff !important;
  }

  .mobile-lg\:border-red, .mobile-lg\:hover\:border-red:hover {
    border-color: #e52207 !important;
  }

  .mobile-lg\:border-orange, .mobile-lg\:hover\:border-orange:hover {
    border-color: #e66f0e !important;
  }

  .mobile-lg\:border-gold, .mobile-lg\:hover\:border-gold:hover {
    border-color: #ffbe2e !important;
  }

  .mobile-lg\:border-yellow, .mobile-lg\:hover\:border-yellow:hover {
    border-color: #fee685 !important;
  }

  .mobile-lg\:border-green, .mobile-lg\:hover\:border-green:hover {
    border-color: #538200 !important;
  }

  .mobile-lg\:border-mint, .mobile-lg\:hover\:border-mint:hover {
    border-color: #04c585 !important;
  }

  .mobile-lg\:border-cyan, .mobile-lg\:hover\:border-cyan:hover {
    border-color: #009ec1 !important;
  }

  .mobile-lg\:border-blue, .mobile-lg\:hover\:border-blue:hover {
    border-color: #0076d6 !important;
  }

  .mobile-lg\:border-indigo, .mobile-lg\:hover\:border-indigo:hover {
    border-color: #676cc8 !important;
  }

  .mobile-lg\:border-violet, .mobile-lg\:hover\:border-violet:hover {
    border-color: #8168b3 !important;
  }

  .mobile-lg\:border-magenta, .mobile-lg\:hover\:border-magenta:hover {
    border-color: #d72d79 !important;
  }

  .mobile-lg\:border-gray-5, .mobile-lg\:hover\:border-gray-5:hover {
    border-color: #f0f0f0 !important;
  }

  .mobile-lg\:border-gray-10, .mobile-lg\:hover\:border-gray-10:hover {
    border-color: #e6e6e6 !important;
  }

  .mobile-lg\:border-gray-30, .mobile-lg\:hover\:border-gray-30:hover {
    border-color: #adadad !important;
  }

  .mobile-lg\:border-gray-50, .mobile-lg\:hover\:border-gray-50:hover {
    border-color: #757575 !important;
  }

  .mobile-lg\:border-gray-70, .mobile-lg\:hover\:border-gray-70:hover {
    border-color: #454545 !important;
  }

  .mobile-lg\:border-gray-90, .mobile-lg\:hover\:border-gray-90:hover {
    border-color: #1b1b1b !important;
  }

  .mobile-lg\:border-base-lightest, .mobile-lg\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }

  .mobile-lg\:border-base-lighter, .mobile-lg\:hover\:border-base-lighter:hover {
    border-color: #dfe1e2 !important;
  }

  .mobile-lg\:border-base-light, .mobile-lg\:hover\:border-base-light:hover {
    border-color: #a9aeb1 !important;
  }

  .mobile-lg\:border-base, .mobile-lg\:hover\:border-base:hover {
    border-color: #71767a !important;
  }

  .mobile-lg\:border-base-dark, .mobile-lg\:hover\:border-base-dark:hover {
    border-color: #565c65 !important;
  }

  .mobile-lg\:border-base-darker, .mobile-lg\:hover\:border-base-darker:hover {
    border-color: #3d4551 !important;
  }

  .mobile-lg\:border-base-darkest, .mobile-lg\:hover\:border-base-darkest:hover {
    border-color: #2d2e2f !important;
  }

  .mobile-lg\:border-ink, .mobile-lg\:hover\:border-ink:hover {
    border-color: #1c1d1f !important;
  }

  .mobile-lg\:border-primary-lighter, .mobile-lg\:hover\:border-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }

  .mobile-lg\:border-primary-light, .mobile-lg\:hover\:border-primary-light:hover {
    border-color: #73b3e7 !important;
  }

  .mobile-lg\:border-primary, .mobile-lg\:hover\:border-primary:hover {
    border-color: #005ea2 !important;
  }

  .mobile-lg\:border-primary-vivid, .mobile-lg\:hover\:border-primary-vivid:hover {
    border-color: #0050d8 !important;
  }

  .mobile-lg\:border-primary-dark, .mobile-lg\:hover\:border-primary-dark:hover {
    border-color: #1a4480 !important;
  }

  .mobile-lg\:border-primary-darker, .mobile-lg\:hover\:border-primary-darker:hover {
    border-color: #162e51 !important;
  }

  .mobile-lg\:border-secondary-lighter, .mobile-lg\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4 !important;
  }

  .mobile-lg\:border-secondary-light, .mobile-lg\:hover\:border-secondary-light:hover {
    border-color: #f2938c !important;
  }

  .mobile-lg\:border-secondary, .mobile-lg\:hover\:border-secondary:hover {
    border-color: #d83933 !important;
  }

  .mobile-lg\:border-secondary-vivid, .mobile-lg\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }

  .mobile-lg\:border-secondary-dark, .mobile-lg\:hover\:border-secondary-dark:hover {
    border-color: #b50909 !important;
  }

  .mobile-lg\:border-secondary-darker, .mobile-lg\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03 !important;
  }

  .mobile-lg\:border-accent-warm-darker, .mobile-lg\:hover\:border-accent-warm-darker:hover {
    border-color: #775540 !important;
  }

  .mobile-lg\:border-accent-warm-dark, .mobile-lg\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }

  .mobile-lg\:border-accent-warm, .mobile-lg\:hover\:border-accent-warm:hover {
    border-color: #fa9441 !important;
  }

  .mobile-lg\:border-accent-warm-light, .mobile-lg\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }

  .mobile-lg\:border-accent-warm-lighter, .mobile-lg\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }

  .mobile-lg\:border-accent-cool-darker, .mobile-lg\:hover\:border-accent-cool-darker:hover {
    border-color: #07648d !important;
  }

  .mobile-lg\:border-accent-cool-dark, .mobile-lg\:hover\:border-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }

  .mobile-lg\:border-accent-cool, .mobile-lg\:hover\:border-accent-cool:hover {
    border-color: #00bde3 !important;
  }

  .mobile-lg\:border-accent-cool-light, .mobile-lg\:hover\:border-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }

  .mobile-lg\:border-accent-cool-lighter, .mobile-lg\:hover\:border-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }

  .mobile-lg\:border-error-lighter, .mobile-lg\:hover\:border-error-lighter:hover {
    border-color: #f4e3db !important;
  }

  .mobile-lg\:border-error-light, .mobile-lg\:hover\:border-error-light:hover {
    border-color: #f39268 !important;
  }

  .mobile-lg\:border-error, .mobile-lg\:hover\:border-error:hover {
    border-color: #d54309 !important;
  }

  .mobile-lg\:border-error-dark, .mobile-lg\:hover\:border-error-dark:hover {
    border-color: #b50909 !important;
  }

  .mobile-lg\:border-error-darker, .mobile-lg\:hover\:border-error-darker:hover {
    border-color: #6f3331 !important;
  }

  .mobile-lg\:border-warning-lighter, .mobile-lg\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1 !important;
  }

  .mobile-lg\:border-warning-light, .mobile-lg\:hover\:border-warning-light:hover {
    border-color: #fee685 !important;
  }

  .mobile-lg\:border-warning, .mobile-lg\:hover\:border-warning:hover {
    border-color: #ffbe2e !important;
  }

  .mobile-lg\:border-warning-dark, .mobile-lg\:hover\:border-warning-dark:hover {
    border-color: #e5a000 !important;
  }

  .mobile-lg\:border-warning-darker, .mobile-lg\:hover\:border-warning-darker:hover {
    border-color: #936f38 !important;
  }

  .mobile-lg\:border-success-lighter, .mobile-lg\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec !important;
  }

  .mobile-lg\:border-success-light, .mobile-lg\:hover\:border-success-light:hover {
    border-color: #70e17b !important;
  }

  .mobile-lg\:border-success, .mobile-lg\:hover\:border-success:hover {
    border-color: #00a91c !important;
  }

  .mobile-lg\:border-success-dark, .mobile-lg\:hover\:border-success-dark:hover {
    border-color: #008817 !important;
  }

  .mobile-lg\:border-success-darker, .mobile-lg\:hover\:border-success-darker:hover {
    border-color: #216e1f !important;
  }

  .mobile-lg\:border-info-lighter, .mobile-lg\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8 !important;
  }

  .mobile-lg\:border-info-light, .mobile-lg\:hover\:border-info-light:hover {
    border-color: #99deea !important;
  }

  .mobile-lg\:border-info, .mobile-lg\:hover\:border-info:hover {
    border-color: #00bde3 !important;
  }

  .mobile-lg\:border-info-dark, .mobile-lg\:hover\:border-info-dark:hover {
    border-color: #009ec1 !important;
  }

  .mobile-lg\:border-info-darker, .mobile-lg\:hover\:border-info-darker:hover {
    border-color: #2e6276 !important;
  }

  .mobile-lg\:border-disabled-lighter, .mobile-lg\:hover\:border-disabled-lighter:hover {
    border-color: #c9c9c9 !important;
  }

  .mobile-lg\:border-disabled-light, .mobile-lg\:hover\:border-disabled-light:hover {
    border-color: #919191 !important;
  }

  .mobile-lg\:border-disabled, .mobile-lg\:hover\:border-disabled:hover {
    border-color: #757575 !important;
  }

  .mobile-lg\:border-disabled-dark, .mobile-lg\:hover\:border-disabled-dark:hover {
    border-color: #454545 !important;
  }

  .mobile-lg\:border-disabled-darker, .mobile-lg\:hover\:border-disabled-darker:hover {
    border-color: #1b1b1b !important;
  }

  .mobile-lg\:border-emergency, .mobile-lg\:hover\:border-emergency:hover {
    border-color: #9c3d10 !important;
  }

  .mobile-lg\:border-emergency-dark, .mobile-lg\:hover\:border-emergency-dark:hover {
    border-color: #332d29 !important;
  }

  .mobile-lg\:radius-0 {
    border-radius: 0 !important;
  }

  .mobile-lg\:radius-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .mobile-lg\:radius-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .mobile-lg\:radius-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .mobile-lg\:radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .mobile-lg\:radius-sm {
    border-radius: 2px !important;
  }

  .mobile-lg\:radius-top-sm {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
  }

  .mobile-lg\:radius-right-sm {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  .mobile-lg\:radius-bottom-sm {
    border-bottom-right-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .mobile-lg\:radius-left-sm {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .mobile-lg\:radius-md {
    border-radius: .25rem !important;
  }

  .mobile-lg\:radius-top-md {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .mobile-lg\:radius-right-md {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .mobile-lg\:radius-bottom-md {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .mobile-lg\:radius-left-md {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .mobile-lg\:radius-lg {
    border-radius: .5rem !important;
  }

  .mobile-lg\:radius-top-lg {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .mobile-lg\:radius-right-lg {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .mobile-lg\:radius-bottom-lg {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .mobile-lg\:radius-left-lg {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .mobile-lg\:radius-pill {
    border-radius: 99rem !important;
  }

  .mobile-lg\:radius-top-pill {
    border-top-left-radius: 99rem !important;
    border-top-right-radius: 99rem !important;
  }

  .mobile-lg\:radius-right-pill {
    border-top-right-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }

  .mobile-lg\:radius-bottom-pill {
    border-bottom-right-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  .mobile-lg\:radius-left-pill {
    border-top-left-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  .mobile-lg\:display-block {
    display: block !important;
  }

  .mobile-lg\:display-flex {
    display: flex !important;
  }

  .mobile-lg\:display-none {
    display: none !important;
  }

  .mobile-lg\:display-inline {
    display: inline !important;
  }

  .mobile-lg\:display-inline-block {
    display: inline-block !important;
  }

  .mobile-lg\:display-inline-flex {
    display: inline-flex !important;
  }

  .mobile-lg\:display-table {
    display: table !important;
  }

  .mobile-lg\:display-table-cell {
    display: table-cell !important;
  }

  .mobile-lg\:display-table-row {
    display: table-row !important;
  }

  .mobile-lg\:font-mono-3xs {
    font-size: .77rem !important;
  }

  .mobile-lg\:font-mono-2xs {
    font-size: .83rem !important;
  }

  .mobile-lg\:font-mono-xs {
    font-size: .89rem !important;
  }

  .mobile-lg\:font-mono-sm {
    font-size: .95rem !important;
  }

  .mobile-lg\:font-mono-md {
    font-size: 1.19rem !important;
  }

  .mobile-lg\:font-mono-lg {
    font-size: 1.31rem !important;
  }

  .mobile-lg\:font-mono-xl {
    font-size: 1.91rem !important;
  }

  .mobile-lg\:font-mono-2xl {
    font-size: 2.38rem !important;
  }

  .mobile-lg\:font-mono-3xl {
    font-size: 2.86rem !important;
  }

  .mobile-lg\:font-sans-3xs {
    font-size: .81rem !important;
  }

  .mobile-lg\:font-sans-2xs {
    font-size: .87rem !important;
  }

  .mobile-lg\:font-sans-xs {
    font-size: .93rem !important;
  }

  .mobile-lg\:font-sans-sm {
    font-size: .99rem !important;
  }

  .mobile-lg\:font-sans-md {
    font-size: 1.24rem !important;
  }

  .mobile-lg\:font-sans-lg {
    font-size: 1.37rem !important;
  }

  .mobile-lg\:font-sans-xl {
    font-size: 1.99rem !important;
  }

  .mobile-lg\:font-sans-2xl {
    font-size: 2.49rem !important;
  }

  .mobile-lg\:font-sans-3xl {
    font-size: 2.98rem !important;
  }

  .mobile-lg\:font-serif-3xs {
    font-size: .81rem !important;
  }

  .mobile-lg\:font-serif-2xs {
    font-size: .87rem !important;
  }

  .mobile-lg\:font-serif-xs {
    font-size: .93rem !important;
  }

  .mobile-lg\:font-serif-sm {
    font-size: .99rem !important;
  }

  .mobile-lg\:font-serif-md {
    font-size: 1.24rem !important;
  }

  .mobile-lg\:font-serif-lg {
    font-size: 1.37rem !important;
  }

  .mobile-lg\:font-serif-xl {
    font-size: 1.99rem !important;
  }

  .mobile-lg\:font-serif-2xl {
    font-size: 2.49rem !important;
  }

  .mobile-lg\:font-serif-3xl {
    font-size: 2.98rem !important;
  }

  .mobile-lg\:font-heading-3xs {
    font-size: .81rem !important;
  }

  .mobile-lg\:font-heading-2xs {
    font-size: .87rem !important;
  }

  .mobile-lg\:font-heading-xs {
    font-size: .93rem !important;
  }

  .mobile-lg\:font-heading-sm {
    font-size: .99rem !important;
  }

  .mobile-lg\:font-heading-md {
    font-size: 1.24rem !important;
  }

  .mobile-lg\:font-heading-lg {
    font-size: 1.37rem !important;
  }

  .mobile-lg\:font-heading-xl {
    font-size: 1.99rem !important;
  }

  .mobile-lg\:font-heading-2xl {
    font-size: 2.49rem !important;
  }

  .mobile-lg\:font-heading-3xl {
    font-size: 2.98rem !important;
  }

  .mobile-lg\:font-body-3xs {
    font-size: .81rem !important;
  }

  .mobile-lg\:font-body-2xs {
    font-size: .87rem !important;
  }

  .mobile-lg\:font-body-xs {
    font-size: .93rem !important;
  }

  .mobile-lg\:font-body-sm {
    font-size: .99rem !important;
  }

  .mobile-lg\:font-body-md {
    font-size: 1.24rem !important;
  }

  .mobile-lg\:font-body-lg {
    font-size: 1.37rem !important;
  }

  .mobile-lg\:font-body-xl {
    font-size: 1.99rem !important;
  }

  .mobile-lg\:font-body-2xl {
    font-size: 2.49rem !important;
  }

  .mobile-lg\:font-body-3xl {
    font-size: 2.98rem !important;
  }

  .mobile-lg\:font-code-3xs {
    font-size: .77rem !important;
  }

  .mobile-lg\:font-code-2xs {
    font-size: .83rem !important;
  }

  .mobile-lg\:font-code-xs {
    font-size: .89rem !important;
  }

  .mobile-lg\:font-code-sm {
    font-size: .95rem !important;
  }

  .mobile-lg\:font-code-md {
    font-size: 1.19rem !important;
  }

  .mobile-lg\:font-code-lg {
    font-size: 1.31rem !important;
  }

  .mobile-lg\:font-code-xl {
    font-size: 1.91rem !important;
  }

  .mobile-lg\:font-code-2xl {
    font-size: 2.38rem !important;
  }

  .mobile-lg\:font-code-3xl {
    font-size: 2.86rem !important;
  }

  .mobile-lg\:font-alt-3xs {
    font-size: .81rem !important;
  }

  .mobile-lg\:font-alt-2xs {
    font-size: .87rem !important;
  }

  .mobile-lg\:font-alt-xs {
    font-size: .93rem !important;
  }

  .mobile-lg\:font-alt-sm {
    font-size: .99rem !important;
  }

  .mobile-lg\:font-alt-md {
    font-size: 1.24rem !important;
  }

  .mobile-lg\:font-alt-lg {
    font-size: 1.37rem !important;
  }

  .mobile-lg\:font-alt-xl {
    font-size: 1.99rem !important;
  }

  .mobile-lg\:font-alt-2xl {
    font-size: 2.49rem !important;
  }

  .mobile-lg\:font-alt-3xl {
    font-size: 2.98rem !important;
  }

  .mobile-lg\:font-ui-3xs {
    font-size: .81rem !important;
  }

  .mobile-lg\:font-ui-2xs {
    font-size: .87rem !important;
  }

  .mobile-lg\:font-ui-xs {
    font-size: .93rem !important;
  }

  .mobile-lg\:font-ui-sm {
    font-size: .99rem !important;
  }

  .mobile-lg\:font-ui-md {
    font-size: 1.24rem !important;
  }

  .mobile-lg\:font-ui-lg {
    font-size: 1.37rem !important;
  }

  .mobile-lg\:font-ui-xl {
    font-size: 1.99rem !important;
  }

  .mobile-lg\:font-ui-2xl {
    font-size: 2.49rem !important;
  }

  .mobile-lg\:font-ui-3xl {
    font-size: 2.98rem !important;
  }

  .mobile-lg\:text-light {
    font-weight: 300 !important;
  }

  .mobile-lg\:text-normal {
    font-weight: normal !important;
  }

  .mobile-lg\:text-semibold {
    font-weight: 600 !important;
  }

  .mobile-lg\:text-bold {
    font-weight: 700 !important;
  }

  .mobile-lg\:flex-justify-center {
    justify-content: center !important;
  }

  .mobile-lg\:flex-justify-start {
    justify-content: flex-start !important;
  }

  .mobile-lg\:flex-justify-end {
    justify-content: flex-end !important;
  }

  .mobile-lg\:flex-justify {
    justify-content: space-between !important;
  }

  .mobile-lg\:line-height-sans-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-sans-2 {
    line-height: 1.2 !important;
  }

  .mobile-lg\:line-height-sans-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-sans-4 {
    line-height: 1.5 !important;
  }

  .mobile-lg\:line-height-sans-5 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-sans-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:line-height-serif-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-serif-2 {
    line-height: 1.2 !important;
  }

  .mobile-lg\:line-height-serif-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-serif-4 {
    line-height: 1.5 !important;
  }

  .mobile-lg\:line-height-serif-5 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-serif-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:line-height-mono-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-mono-2 {
    line-height: 1.3 !important;
  }

  .mobile-lg\:line-height-mono-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-mono-4 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-mono-5 {
    line-height: 1.7 !important;
  }

  .mobile-lg\:line-height-mono-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:line-height-heading-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-heading-2 {
    line-height: 1.2 !important;
  }

  .mobile-lg\:line-height-heading-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-heading-4 {
    line-height: 1.5 !important;
  }

  .mobile-lg\:line-height-heading-5 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-heading-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:line-height-ui-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-ui-2 {
    line-height: 1.2 !important;
  }

  .mobile-lg\:line-height-ui-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-ui-4 {
    line-height: 1.5 !important;
  }

  .mobile-lg\:line-height-ui-5 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-ui-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:line-height-body-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-body-2 {
    line-height: 1.2 !important;
  }

  .mobile-lg\:line-height-body-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-body-4 {
    line-height: 1.5 !important;
  }

  .mobile-lg\:line-height-body-5 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-body-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:line-height-code-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-code-2 {
    line-height: 1.3 !important;
  }

  .mobile-lg\:line-height-code-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-code-4 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-code-5 {
    line-height: 1.7 !important;
  }

  .mobile-lg\:line-height-code-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:line-height-alt-1 {
    line-height: 1 !important;
  }

  .mobile-lg\:line-height-alt-2 {
    line-height: 1.2 !important;
  }

  .mobile-lg\:line-height-alt-3 {
    line-height: 1.4 !important;
  }

  .mobile-lg\:line-height-alt-4 {
    line-height: 1.5 !important;
  }

  .mobile-lg\:line-height-alt-5 {
    line-height: 1.6 !important;
  }

  .mobile-lg\:line-height-alt-6 {
    line-height: 1.8 !important;
  }

  .mobile-lg\:margin-neg-1px {
    margin: -1px !important;
  }

  .mobile-lg\:margin-neg-2px {
    margin: -2px !important;
  }

  .mobile-lg\:margin-neg-05 {
    margin: -.25rem !important;
  }

  .mobile-lg\:margin-neg-1 {
    margin: -.5rem !important;
  }

  .mobile-lg\:margin-neg-105 {
    margin: -.75rem !important;
  }

  .mobile-lg\:margin-neg-2 {
    margin: -1rem !important;
  }

  .mobile-lg\:margin-neg-205 {
    margin: -1.25rem !important;
  }

  .mobile-lg\:margin-neg-3 {
    margin: -1.5rem !important;
  }

  .mobile-lg\:margin-1px {
    margin: 1px !important;
  }

  .mobile-lg\:margin-2px {
    margin: 2px !important;
  }

  .mobile-lg\:margin-05 {
    margin: .25rem !important;
  }

  .mobile-lg\:margin-1 {
    margin: .5rem !important;
  }

  .mobile-lg\:margin-105 {
    margin: .75rem !important;
  }

  .mobile-lg\:margin-2 {
    margin: 1rem !important;
  }

  .mobile-lg\:margin-205 {
    margin: 1.25rem !important;
  }

  .mobile-lg\:margin-3 {
    margin: 1.5rem !important;
  }

  .mobile-lg\:margin-neg-4 {
    margin: -2rem !important;
  }

  .mobile-lg\:margin-neg-5 {
    margin: -2.5rem !important;
  }

  .mobile-lg\:margin-neg-6 {
    margin: -3rem !important;
  }

  .mobile-lg\:margin-neg-7 {
    margin: -3.5rem !important;
  }

  .mobile-lg\:margin-neg-8 {
    margin: -4rem !important;
  }

  .mobile-lg\:margin-neg-9 {
    margin: -4.5rem !important;
  }

  .mobile-lg\:margin-neg-10 {
    margin: -5rem !important;
  }

  .mobile-lg\:margin-neg-15 {
    margin: -7.5rem !important;
  }

  .mobile-lg\:margin-4 {
    margin: 2rem !important;
  }

  .mobile-lg\:margin-5 {
    margin: 2.5rem !important;
  }

  .mobile-lg\:margin-6 {
    margin: 3rem !important;
  }

  .mobile-lg\:margin-7 {
    margin: 3.5rem !important;
  }

  .mobile-lg\:margin-8 {
    margin: 4rem !important;
  }

  .mobile-lg\:margin-9 {
    margin: 4.5rem !important;
  }

  .mobile-lg\:margin-10 {
    margin: 5rem !important;
  }

  .mobile-lg\:margin-15 {
    margin: 7.5rem !important;
  }

  .mobile-lg\:margin-05em {
    margin: .5em !important;
  }

  .mobile-lg\:margin-1em {
    margin: 1em !important;
  }

  .mobile-lg\:margin-105em {
    margin: 1.5em !important;
  }

  .mobile-lg\:margin-2em {
    margin: 2em !important;
  }

  .mobile-lg\:margin-0 {
    margin: 0 !important;
  }

  .mobile-lg\:margin-y-1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mobile-lg\:margin-top-1px {
    margin-top: 1px !important;
  }

  .mobile-lg\:margin-bottom-1px {
    margin-bottom: 1px !important;
  }

  .mobile-lg\:margin-y-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mobile-lg\:margin-top-2px {
    margin-top: 2px !important;
  }

  .mobile-lg\:margin-bottom-2px {
    margin-bottom: 2px !important;
  }

  .mobile-lg\:margin-y-05 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .mobile-lg\:margin-top-05 {
    margin-top: .25rem !important;
  }

  .mobile-lg\:margin-bottom-05 {
    margin-bottom: .25rem !important;
  }

  .mobile-lg\:margin-y-1 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .mobile-lg\:margin-top-1 {
    margin-top: .5rem !important;
  }

  .mobile-lg\:margin-bottom-1 {
    margin-bottom: .5rem !important;
  }

  .mobile-lg\:margin-y-105 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .mobile-lg\:margin-top-105 {
    margin-top: .75rem !important;
  }

  .mobile-lg\:margin-bottom-105 {
    margin-bottom: .75rem !important;
  }

  .mobile-lg\:margin-y-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .mobile-lg\:margin-top-2 {
    margin-top: 1rem !important;
  }

  .mobile-lg\:margin-bottom-2 {
    margin-bottom: 1rem !important;
  }

  .mobile-lg\:margin-y-205 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .mobile-lg\:margin-top-205 {
    margin-top: 1.25rem !important;
  }

  .mobile-lg\:margin-bottom-205 {
    margin-bottom: 1.25rem !important;
  }

  .mobile-lg\:margin-y-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .mobile-lg\:margin-top-3 {
    margin-top: 1.5rem !important;
  }

  .mobile-lg\:margin-bottom-3 {
    margin-bottom: 1.5rem !important;
  }

  .mobile-lg\:margin-y-neg-1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .mobile-lg\:margin-top-neg-1px {
    margin-top: -1px !important;
  }

  .mobile-lg\:margin-bottom-neg-1px {
    margin-bottom: -1px !important;
  }

  .mobile-lg\:margin-y-neg-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .mobile-lg\:margin-top-neg-2px {
    margin-top: -2px !important;
  }

  .mobile-lg\:margin-bottom-neg-2px {
    margin-bottom: -2px !important;
  }

  .mobile-lg\:margin-y-neg-05 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .mobile-lg\:margin-top-neg-05 {
    margin-top: -.25rem !important;
  }

  .mobile-lg\:margin-bottom-neg-05 {
    margin-bottom: -.25rem !important;
  }

  .mobile-lg\:margin-y-neg-1 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .mobile-lg\:margin-top-neg-1 {
    margin-top: -.5rem !important;
  }

  .mobile-lg\:margin-bottom-neg-1 {
    margin-bottom: -.5rem !important;
  }

  .mobile-lg\:margin-y-neg-105 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .mobile-lg\:margin-top-neg-105 {
    margin-top: -.75rem !important;
  }

  .mobile-lg\:margin-bottom-neg-105 {
    margin-bottom: -.75rem !important;
  }

  .mobile-lg\:margin-y-neg-2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .mobile-lg\:margin-top-neg-2 {
    margin-top: -1rem !important;
  }

  .mobile-lg\:margin-bottom-neg-2 {
    margin-bottom: -1rem !important;
  }

  .mobile-lg\:margin-y-neg-205 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .mobile-lg\:margin-top-neg-205 {
    margin-top: -1.25rem !important;
  }

  .mobile-lg\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem !important;
  }

  .mobile-lg\:margin-y-neg-3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .mobile-lg\:margin-top-neg-3 {
    margin-top: -1.5rem !important;
  }

  .mobile-lg\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem !important;
  }

  .mobile-lg\:margin-y-neg-4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .mobile-lg\:margin-top-neg-4 {
    margin-top: -2rem !important;
  }

  .mobile-lg\:margin-bottom-neg-4 {
    margin-bottom: -2rem !important;
  }

  .mobile-lg\:margin-y-neg-5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .mobile-lg\:margin-top-neg-5 {
    margin-top: -2.5rem !important;
  }

  .mobile-lg\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem !important;
  }

  .mobile-lg\:margin-y-neg-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mobile-lg\:margin-top-neg-6 {
    margin-top: -3rem !important;
  }

  .mobile-lg\:margin-bottom-neg-6 {
    margin-bottom: -3rem !important;
  }

  .mobile-lg\:margin-y-neg-7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .mobile-lg\:margin-top-neg-7 {
    margin-top: -3.5rem !important;
  }

  .mobile-lg\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem !important;
  }

  .mobile-lg\:margin-y-neg-8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .mobile-lg\:margin-top-neg-8 {
    margin-top: -4rem !important;
  }

  .mobile-lg\:margin-bottom-neg-8 {
    margin-bottom: -4rem !important;
  }

  .mobile-lg\:margin-y-neg-9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mobile-lg\:margin-top-neg-9 {
    margin-top: -4.5rem !important;
  }

  .mobile-lg\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem !important;
  }

  .mobile-lg\:margin-y-neg-10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mobile-lg\:margin-top-neg-10 {
    margin-top: -5rem !important;
  }

  .mobile-lg\:margin-bottom-neg-10 {
    margin-bottom: -5rem !important;
  }

  .mobile-lg\:margin-y-neg-15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .mobile-lg\:margin-top-neg-15 {
    margin-top: -7.5rem !important;
  }

  .mobile-lg\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem !important;
  }

  .mobile-lg\:margin-y-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .mobile-lg\:margin-top-4 {
    margin-top: 2rem !important;
  }

  .mobile-lg\:margin-bottom-4 {
    margin-bottom: 2rem !important;
  }

  .mobile-lg\:margin-y-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .mobile-lg\:margin-top-5 {
    margin-top: 2.5rem !important;
  }

  .mobile-lg\:margin-bottom-5 {
    margin-bottom: 2.5rem !important;
  }

  .mobile-lg\:margin-y-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .mobile-lg\:margin-top-6 {
    margin-top: 3rem !important;
  }

  .mobile-lg\:margin-bottom-6 {
    margin-bottom: 3rem !important;
  }

  .mobile-lg\:margin-y-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .mobile-lg\:margin-top-7 {
    margin-top: 3.5rem !important;
  }

  .mobile-lg\:margin-bottom-7 {
    margin-bottom: 3.5rem !important;
  }

  .mobile-lg\:margin-y-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .mobile-lg\:margin-top-8 {
    margin-top: 4rem !important;
  }

  .mobile-lg\:margin-bottom-8 {
    margin-bottom: 4rem !important;
  }

  .mobile-lg\:margin-y-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .mobile-lg\:margin-top-9 {
    margin-top: 4.5rem !important;
  }

  .mobile-lg\:margin-bottom-9 {
    margin-bottom: 4.5rem !important;
  }

  .mobile-lg\:margin-y-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .mobile-lg\:margin-top-10 {
    margin-top: 5rem !important;
  }

  .mobile-lg\:margin-bottom-10 {
    margin-bottom: 5rem !important;
  }

  .mobile-lg\:margin-y-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .mobile-lg\:margin-top-15 {
    margin-top: 7.5rem !important;
  }

  .mobile-lg\:margin-bottom-15 {
    margin-bottom: 7.5rem !important;
  }

  .mobile-lg\:margin-y-05em {
    margin-top: .5em !important;
    margin-bottom: .5em !important;
  }

  .mobile-lg\:margin-top-05em {
    margin-top: .5em !important;
  }

  .mobile-lg\:margin-bottom-05em {
    margin-bottom: .5em !important;
  }

  .mobile-lg\:margin-y-1em {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  .mobile-lg\:margin-top-1em {
    margin-top: 1em !important;
  }

  .mobile-lg\:margin-bottom-1em {
    margin-bottom: 1em !important;
  }

  .mobile-lg\:margin-y-105em {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }

  .mobile-lg\:margin-top-105em {
    margin-top: 1.5em !important;
  }

  .mobile-lg\:margin-bottom-105em {
    margin-bottom: 1.5em !important;
  }

  .mobile-lg\:margin-y-2em {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }

  .mobile-lg\:margin-top-2em {
    margin-top: 2em !important;
  }

  .mobile-lg\:margin-bottom-2em {
    margin-bottom: 2em !important;
  }

  .mobile-lg\:margin-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .mobile-lg\:margin-top-0 {
    margin-top: 0 !important;
  }

  .mobile-lg\:margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  .mobile-lg\:margin-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mobile-lg\:margin-top-auto {
    margin-top: auto !important;
  }

  .mobile-lg\:margin-bottom-auto {
    margin-bottom: auto !important;
  }

  .mobile-lg\:margin-x-1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mobile-lg\:margin-right-1px {
    margin-right: 1px !important;
  }

  .mobile-lg\:margin-left-1px {
    margin-left: 1px !important;
  }

  .mobile-lg\:margin-x-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mobile-lg\:margin-right-2px {
    margin-right: 2px !important;
  }

  .mobile-lg\:margin-left-2px {
    margin-left: 2px !important;
  }

  .mobile-lg\:margin-x-05 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mobile-lg\:margin-right-05 {
    margin-right: .25rem !important;
  }

  .mobile-lg\:margin-left-05 {
    margin-left: .25rem !important;
  }

  .mobile-lg\:margin-x-1 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mobile-lg\:margin-right-1 {
    margin-right: .5rem !important;
  }

  .mobile-lg\:margin-left-1 {
    margin-left: .5rem !important;
  }

  .mobile-lg\:margin-x-105 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mobile-lg\:margin-right-105 {
    margin-right: .75rem !important;
  }

  .mobile-lg\:margin-left-105 {
    margin-left: .75rem !important;
  }

  .mobile-lg\:margin-x-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mobile-lg\:margin-right-2 {
    margin-right: 1rem !important;
  }

  .mobile-lg\:margin-left-2 {
    margin-left: 1rem !important;
  }

  .mobile-lg\:margin-x-205 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .mobile-lg\:margin-right-205 {
    margin-right: 1.25rem !important;
  }

  .mobile-lg\:margin-left-205 {
    margin-left: 1.25rem !important;
  }

  .mobile-lg\:margin-x-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mobile-lg\:margin-right-3 {
    margin-right: 1.5rem !important;
  }

  .mobile-lg\:margin-left-3 {
    margin-left: 1.5rem !important;
  }

  .mobile-lg\:margin-x-neg-1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .mobile-lg\:margin-right-neg-1px {
    margin-right: -1px !important;
  }

  .mobile-lg\:margin-left-neg-1px {
    margin-left: -1px !important;
  }

  .mobile-lg\:margin-x-neg-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .mobile-lg\:margin-right-neg-2px {
    margin-right: -2px !important;
  }

  .mobile-lg\:margin-left-neg-2px {
    margin-left: -2px !important;
  }

  .mobile-lg\:margin-x-neg-05 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mobile-lg\:margin-right-neg-05 {
    margin-right: -.25rem !important;
  }

  .mobile-lg\:margin-left-neg-05 {
    margin-left: -.25rem !important;
  }

  .mobile-lg\:margin-x-neg-1 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mobile-lg\:margin-right-neg-1 {
    margin-right: -.5rem !important;
  }

  .mobile-lg\:margin-left-neg-1 {
    margin-left: -.5rem !important;
  }

  .mobile-lg\:margin-x-neg-105 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mobile-lg\:margin-right-neg-105 {
    margin-right: -.75rem !important;
  }

  .mobile-lg\:margin-left-neg-105 {
    margin-left: -.75rem !important;
  }

  .mobile-lg\:margin-x-neg-2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mobile-lg\:margin-right-neg-2 {
    margin-right: -1rem !important;
  }

  .mobile-lg\:margin-left-neg-2 {
    margin-left: -1rem !important;
  }

  .mobile-lg\:margin-x-neg-205 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .mobile-lg\:margin-right-neg-205 {
    margin-right: -1.25rem !important;
  }

  .mobile-lg\:margin-left-neg-205 {
    margin-left: -1.25rem !important;
  }

  .mobile-lg\:margin-x-neg-3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mobile-lg\:margin-right-neg-3 {
    margin-right: -1.5rem !important;
  }

  .mobile-lg\:margin-left-neg-3 {
    margin-left: -1.5rem !important;
  }

  .mobile-lg\:margin-x-neg-4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mobile-lg\:margin-right-neg-4 {
    margin-right: -2rem !important;
  }

  .mobile-lg\:margin-left-neg-4 {
    margin-left: -2rem !important;
  }

  .mobile-lg\:margin-x-neg-5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mobile-lg\:margin-right-neg-5 {
    margin-right: -2.5rem !important;
  }

  .mobile-lg\:margin-left-neg-5 {
    margin-left: -2.5rem !important;
  }

  .mobile-lg\:margin-x-neg-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mobile-lg\:margin-right-neg-6 {
    margin-right: -3rem !important;
  }

  .mobile-lg\:margin-left-neg-6 {
    margin-left: -3rem !important;
  }

  .mobile-lg\:margin-x-neg-7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mobile-lg\:margin-right-neg-7 {
    margin-right: -3.5rem !important;
  }

  .mobile-lg\:margin-left-neg-7 {
    margin-left: -3.5rem !important;
  }

  .mobile-lg\:margin-x-neg-8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mobile-lg\:margin-right-neg-8 {
    margin-right: -4rem !important;
  }

  .mobile-lg\:margin-left-neg-8 {
    margin-left: -4rem !important;
  }

  .mobile-lg\:margin-x-neg-9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .mobile-lg\:margin-right-neg-9 {
    margin-right: -4.5rem !important;
  }

  .mobile-lg\:margin-left-neg-9 {
    margin-left: -4.5rem !important;
  }

  .mobile-lg\:margin-x-neg-10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mobile-lg\:margin-right-neg-10 {
    margin-right: -5rem !important;
  }

  .mobile-lg\:margin-left-neg-10 {
    margin-left: -5rem !important;
  }

  .mobile-lg\:margin-x-neg-15 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .mobile-lg\:margin-right-neg-15 {
    margin-right: -7.5rem !important;
  }

  .mobile-lg\:margin-left-neg-15 {
    margin-left: -7.5rem !important;
  }

  .mobile-lg\:margin-x-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mobile-lg\:margin-right-4 {
    margin-right: 2rem !important;
  }

  .mobile-lg\:margin-left-4 {
    margin-left: 2rem !important;
  }

  .mobile-lg\:margin-x-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mobile-lg\:margin-right-5 {
    margin-right: 2.5rem !important;
  }

  .mobile-lg\:margin-left-5 {
    margin-left: 2.5rem !important;
  }

  .mobile-lg\:margin-x-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mobile-lg\:margin-right-6 {
    margin-right: 3rem !important;
  }

  .mobile-lg\:margin-left-6 {
    margin-left: 3rem !important;
  }

  .mobile-lg\:margin-x-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mobile-lg\:margin-right-7 {
    margin-right: 3.5rem !important;
  }

  .mobile-lg\:margin-left-7 {
    margin-left: 3.5rem !important;
  }

  .mobile-lg\:margin-x-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mobile-lg\:margin-right-8 {
    margin-right: 4rem !important;
  }

  .mobile-lg\:margin-left-8 {
    margin-left: 4rem !important;
  }

  .mobile-lg\:margin-x-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mobile-lg\:margin-right-9 {
    margin-right: 4.5rem !important;
  }

  .mobile-lg\:margin-left-9 {
    margin-left: 4.5rem !important;
  }

  .mobile-lg\:margin-x-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mobile-lg\:margin-right-10 {
    margin-right: 5rem !important;
  }

  .mobile-lg\:margin-left-10 {
    margin-left: 5rem !important;
  }

  .mobile-lg\:margin-x-15 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .mobile-lg\:margin-right-15 {
    margin-right: 7.5rem !important;
  }

  .mobile-lg\:margin-left-15 {
    margin-left: 7.5rem !important;
  }

  .mobile-lg\:margin-x-card {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .mobile-lg\:margin-right-card {
    margin-right: 10rem !important;
  }

  .mobile-lg\:margin-left-card {
    margin-left: 10rem !important;
  }

  .mobile-lg\:margin-x-card-lg {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .mobile-lg\:margin-right-card-lg {
    margin-right: 15rem !important;
  }

  .mobile-lg\:margin-left-card-lg {
    margin-left: 15rem !important;
  }

  .mobile-lg\:margin-x-mobile {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .mobile-lg\:margin-right-mobile {
    margin-right: 20rem !important;
  }

  .mobile-lg\:margin-left-mobile {
    margin-left: 20rem !important;
  }

  .mobile-lg\:margin-x-05em {
    margin-left: .5em !important;
    margin-right: .5em !important;
  }

  .mobile-lg\:margin-right-05em {
    margin-right: .5em !important;
  }

  .mobile-lg\:margin-left-05em {
    margin-left: .5em !important;
  }

  .mobile-lg\:margin-x-1em {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }

  .mobile-lg\:margin-right-1em {
    margin-right: 1em !important;
  }

  .mobile-lg\:margin-left-1em {
    margin-left: 1em !important;
  }

  .mobile-lg\:margin-x-105em {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }

  .mobile-lg\:margin-right-105em {
    margin-right: 1.5em !important;
  }

  .mobile-lg\:margin-left-105em {
    margin-left: 1.5em !important;
  }

  .mobile-lg\:margin-x-2em {
    margin-left: 2em !important;
    margin-right: 2em !important;
  }

  .mobile-lg\:margin-right-2em {
    margin-right: 2em !important;
  }

  .mobile-lg\:margin-left-2em {
    margin-left: 2em !important;
  }

  .mobile-lg\:margin-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mobile-lg\:margin-right-0 {
    margin-right: 0 !important;
  }

  .mobile-lg\:margin-left-0 {
    margin-left: 0 !important;
  }

  .mobile-lg\:margin-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .mobile-lg\:margin-right-auto {
    margin-right: auto !important;
  }

  .mobile-lg\:margin-left-auto {
    margin-left: auto !important;
  }

  .mobile-lg\:measure-1 {
    max-width: 44ex !important;
  }

  .mobile-lg\:measure-2 {
    max-width: 60ex !important;
  }

  .mobile-lg\:measure-3 {
    max-width: 64ex !important;
  }

  .mobile-lg\:measure-4 {
    max-width: 68ex !important;
  }

  .mobile-lg\:measure-5 {
    max-width: 72ex !important;
  }

  .mobile-lg\:measure-6 {
    max-width: 88ex !important;
  }

  .mobile-lg\:measure-none {
    max-width: none !important;
  }

  .mobile-lg\:order-first {
    order: -1 !important;
  }

  .mobile-lg\:order-last {
    order: 999 !important;
  }

  .mobile-lg\:order-initial {
    order: initial !important;
  }

  .mobile-lg\:order-0 {
    order: 0 !important;
  }

  .mobile-lg\:order-1 {
    order: 1 !important;
  }

  .mobile-lg\:order-2 {
    order: 2 !important;
  }

  .mobile-lg\:order-3 {
    order: 3 !important;
  }

  .mobile-lg\:order-4 {
    order: 4 !important;
  }

  .mobile-lg\:order-5 {
    order: 5 !important;
  }

  .mobile-lg\:order-6 {
    order: 6 !important;
  }

  .mobile-lg\:order-7 {
    order: 7 !important;
  }

  .mobile-lg\:order-8 {
    order: 8 !important;
  }

  .mobile-lg\:order-9 {
    order: 9 !important;
  }

  .mobile-lg\:order-10 {
    order: 10 !important;
  }

  .mobile-lg\:order-11 {
    order: 11 !important;
  }

  .mobile-lg\:padding-1px {
    padding: 1px !important;
  }

  .mobile-lg\:padding-y-1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .mobile-lg\:padding-x-1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .mobile-lg\:padding-top-1px {
    padding-top: 1px !important;
  }

  .mobile-lg\:padding-right-1px {
    padding-right: 1px !important;
  }

  .mobile-lg\:padding-bottom-1px {
    padding-bottom: 1px !important;
  }

  .mobile-lg\:padding-left-1px {
    padding-left: 1px !important;
  }

  .mobile-lg\:padding-2px {
    padding: 2px !important;
  }

  .mobile-lg\:padding-y-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .mobile-lg\:padding-x-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .mobile-lg\:padding-top-2px {
    padding-top: 2px !important;
  }

  .mobile-lg\:padding-right-2px {
    padding-right: 2px !important;
  }

  .mobile-lg\:padding-bottom-2px {
    padding-bottom: 2px !important;
  }

  .mobile-lg\:padding-left-2px {
    padding-left: 2px !important;
  }

  .mobile-lg\:padding-05 {
    padding: .25rem !important;
  }

  .mobile-lg\:padding-y-05 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .mobile-lg\:padding-x-05 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .mobile-lg\:padding-top-05 {
    padding-top: .25rem !important;
  }

  .mobile-lg\:padding-right-05 {
    padding-right: .25rem !important;
  }

  .mobile-lg\:padding-bottom-05 {
    padding-bottom: .25rem !important;
  }

  .mobile-lg\:padding-left-05 {
    padding-left: .25rem !important;
  }

  .mobile-lg\:padding-1 {
    padding: .5rem !important;
  }

  .mobile-lg\:padding-y-1 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .mobile-lg\:padding-x-1 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .mobile-lg\:padding-top-1 {
    padding-top: .5rem !important;
  }

  .mobile-lg\:padding-right-1 {
    padding-right: .5rem !important;
  }

  .mobile-lg\:padding-bottom-1 {
    padding-bottom: .5rem !important;
  }

  .mobile-lg\:padding-left-1 {
    padding-left: .5rem !important;
  }

  .mobile-lg\:padding-105 {
    padding: .75rem !important;
  }

  .mobile-lg\:padding-y-105 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .mobile-lg\:padding-x-105 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .mobile-lg\:padding-top-105 {
    padding-top: .75rem !important;
  }

  .mobile-lg\:padding-right-105 {
    padding-right: .75rem !important;
  }

  .mobile-lg\:padding-bottom-105 {
    padding-bottom: .75rem !important;
  }

  .mobile-lg\:padding-left-105 {
    padding-left: .75rem !important;
  }

  .mobile-lg\:padding-2 {
    padding: 1rem !important;
  }

  .mobile-lg\:padding-y-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .mobile-lg\:padding-x-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .mobile-lg\:padding-top-2 {
    padding-top: 1rem !important;
  }

  .mobile-lg\:padding-right-2 {
    padding-right: 1rem !important;
  }

  .mobile-lg\:padding-bottom-2 {
    padding-bottom: 1rem !important;
  }

  .mobile-lg\:padding-left-2 {
    padding-left: 1rem !important;
  }

  .mobile-lg\:padding-205 {
    padding: 1.25rem !important;
  }

  .mobile-lg\:padding-y-205 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .mobile-lg\:padding-x-205 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .mobile-lg\:padding-top-205 {
    padding-top: 1.25rem !important;
  }

  .mobile-lg\:padding-right-205 {
    padding-right: 1.25rem !important;
  }

  .mobile-lg\:padding-bottom-205 {
    padding-bottom: 1.25rem !important;
  }

  .mobile-lg\:padding-left-205 {
    padding-left: 1.25rem !important;
  }

  .mobile-lg\:padding-3 {
    padding: 1.5rem !important;
  }

  .mobile-lg\:padding-y-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .mobile-lg\:padding-x-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .mobile-lg\:padding-top-3 {
    padding-top: 1.5rem !important;
  }

  .mobile-lg\:padding-right-3 {
    padding-right: 1.5rem !important;
  }

  .mobile-lg\:padding-bottom-3 {
    padding-bottom: 1.5rem !important;
  }

  .mobile-lg\:padding-left-3 {
    padding-left: 1.5rem !important;
  }

  .mobile-lg\:padding-4 {
    padding: 2rem !important;
  }

  .mobile-lg\:padding-y-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .mobile-lg\:padding-x-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .mobile-lg\:padding-top-4 {
    padding-top: 2rem !important;
  }

  .mobile-lg\:padding-right-4 {
    padding-right: 2rem !important;
  }

  .mobile-lg\:padding-bottom-4 {
    padding-bottom: 2rem !important;
  }

  .mobile-lg\:padding-left-4 {
    padding-left: 2rem !important;
  }

  .mobile-lg\:padding-5 {
    padding: 2.5rem !important;
  }

  .mobile-lg\:padding-y-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .mobile-lg\:padding-x-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .mobile-lg\:padding-top-5 {
    padding-top: 2.5rem !important;
  }

  .mobile-lg\:padding-right-5 {
    padding-right: 2.5rem !important;
  }

  .mobile-lg\:padding-bottom-5 {
    padding-bottom: 2.5rem !important;
  }

  .mobile-lg\:padding-left-5 {
    padding-left: 2.5rem !important;
  }

  .mobile-lg\:padding-6 {
    padding: 3rem !important;
  }

  .mobile-lg\:padding-y-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .mobile-lg\:padding-x-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .mobile-lg\:padding-top-6 {
    padding-top: 3rem !important;
  }

  .mobile-lg\:padding-right-6 {
    padding-right: 3rem !important;
  }

  .mobile-lg\:padding-bottom-6 {
    padding-bottom: 3rem !important;
  }

  .mobile-lg\:padding-left-6 {
    padding-left: 3rem !important;
  }

  .mobile-lg\:padding-7 {
    padding: 3.5rem !important;
  }

  .mobile-lg\:padding-y-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .mobile-lg\:padding-x-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .mobile-lg\:padding-top-7 {
    padding-top: 3.5rem !important;
  }

  .mobile-lg\:padding-right-7 {
    padding-right: 3.5rem !important;
  }

  .mobile-lg\:padding-bottom-7 {
    padding-bottom: 3.5rem !important;
  }

  .mobile-lg\:padding-left-7 {
    padding-left: 3.5rem !important;
  }

  .mobile-lg\:padding-8 {
    padding: 4rem !important;
  }

  .mobile-lg\:padding-y-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .mobile-lg\:padding-x-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .mobile-lg\:padding-top-8 {
    padding-top: 4rem !important;
  }

  .mobile-lg\:padding-right-8 {
    padding-right: 4rem !important;
  }

  .mobile-lg\:padding-bottom-8 {
    padding-bottom: 4rem !important;
  }

  .mobile-lg\:padding-left-8 {
    padding-left: 4rem !important;
  }

  .mobile-lg\:padding-9 {
    padding: 4.5rem !important;
  }

  .mobile-lg\:padding-y-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .mobile-lg\:padding-x-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .mobile-lg\:padding-top-9 {
    padding-top: 4.5rem !important;
  }

  .mobile-lg\:padding-right-9 {
    padding-right: 4.5rem !important;
  }

  .mobile-lg\:padding-bottom-9 {
    padding-bottom: 4.5rem !important;
  }

  .mobile-lg\:padding-left-9 {
    padding-left: 4.5rem !important;
  }

  .mobile-lg\:padding-10 {
    padding: 5rem !important;
  }

  .mobile-lg\:padding-y-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .mobile-lg\:padding-x-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .mobile-lg\:padding-top-10 {
    padding-top: 5rem !important;
  }

  .mobile-lg\:padding-right-10 {
    padding-right: 5rem !important;
  }

  .mobile-lg\:padding-bottom-10 {
    padding-bottom: 5rem !important;
  }

  .mobile-lg\:padding-left-10 {
    padding-left: 5rem !important;
  }

  .mobile-lg\:padding-15 {
    padding: 7.5rem !important;
  }

  .mobile-lg\:padding-y-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .mobile-lg\:padding-x-15 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .mobile-lg\:padding-top-15 {
    padding-top: 7.5rem !important;
  }

  .mobile-lg\:padding-right-15 {
    padding-right: 7.5rem !important;
  }

  .mobile-lg\:padding-bottom-15 {
    padding-bottom: 7.5rem !important;
  }

  .mobile-lg\:padding-left-15 {
    padding-left: 7.5rem !important;
  }

  .mobile-lg\:padding-0 {
    padding: 0 !important;
  }

  .mobile-lg\:padding-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .mobile-lg\:padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mobile-lg\:padding-top-0 {
    padding-top: 0 !important;
  }

  .mobile-lg\:padding-right-0 {
    padding-right: 0 !important;
  }

  .mobile-lg\:padding-bottom-0 {
    padding-bottom: 0 !important;
  }

  .mobile-lg\:padding-left-0 {
    padding-left: 0 !important;
  }

  .mobile-lg\:width-1px {
    width: 1px !important;
  }

  .mobile-lg\:width-2px {
    width: 2px !important;
  }

  .mobile-lg\:width-05 {
    width: .25rem !important;
  }

  .mobile-lg\:width-1 {
    width: .5rem !important;
  }

  .mobile-lg\:width-105 {
    width: .75rem !important;
  }

  .mobile-lg\:width-2 {
    width: 1rem !important;
  }

  .mobile-lg\:width-205 {
    width: 1.25rem !important;
  }

  .mobile-lg\:width-3 {
    width: 1.5rem !important;
  }

  .mobile-lg\:width-4 {
    width: 2rem !important;
  }

  .mobile-lg\:width-5 {
    width: 2.5rem !important;
  }

  .mobile-lg\:width-6 {
    width: 3rem !important;
  }

  .mobile-lg\:width-7 {
    width: 3.5rem !important;
  }

  .mobile-lg\:width-8 {
    width: 4rem !important;
  }

  .mobile-lg\:width-9 {
    width: 4.5rem !important;
  }

  .mobile-lg\:width-10 {
    width: 5rem !important;
  }

  .mobile-lg\:width-15 {
    width: 7.5rem !important;
  }

  .mobile-lg\:width-card {
    width: 10rem !important;
  }

  .mobile-lg\:width-card-lg {
    width: 15rem !important;
  }

  .mobile-lg\:width-mobile {
    width: 20rem !important;
  }

  .mobile-lg\:width-mobile-lg {
    width: 30rem !important;
  }

  .mobile-lg\:width-tablet {
    width: 40rem !important;
  }

  .mobile-lg\:width-tablet-lg {
    width: 55rem !important;
  }

  .mobile-lg\:width-desktop {
    width: 64rem !important;
  }

  .mobile-lg\:width-desktop-lg {
    width: 75rem !important;
  }

  .mobile-lg\:width-widescreen {
    width: 87.5rem !important;
  }

  .mobile-lg\:width-0 {
    width: 0 !important;
  }

  .mobile-lg\:width-full {
    width: 100% !important;
  }

  .mobile-lg\:width-auto {
    width: auto !important;
  }
}

@media (width >= 40em) {
  .tablet\:border-1px, .tablet\:hover\:border-1px:hover {
    border: 1px solid !important;
  }

  .tablet\:border-y-1px, .tablet\:hover\:border-y-1px:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .tablet\:border-x-1px, .tablet\:hover\:border-x-1px:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .tablet\:border-top-1px, .tablet\:hover\:border-top-1px:hover {
    border-top: 1px solid !important;
  }

  .tablet\:border-right-1px, .tablet\:hover\:border-right-1px:hover {
    border-right: 1px solid !important;
  }

  .tablet\:border-bottom-1px, .tablet\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid !important;
  }

  .tablet\:border-left-1px, .tablet\:hover\:border-left-1px:hover {
    border-left: 1px solid !important;
  }

  .tablet\:border-2px, .tablet\:hover\:border-2px:hover {
    border: 2px solid !important;
  }

  .tablet\:border-y-2px, .tablet\:hover\:border-y-2px:hover {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }

  .tablet\:border-x-2px, .tablet\:hover\:border-x-2px:hover {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }

  .tablet\:border-top-2px, .tablet\:hover\:border-top-2px:hover {
    border-top: 2px solid !important;
  }

  .tablet\:border-right-2px, .tablet\:hover\:border-right-2px:hover {
    border-right: 2px solid !important;
  }

  .tablet\:border-bottom-2px, .tablet\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid !important;
  }

  .tablet\:border-left-2px, .tablet\:hover\:border-left-2px:hover {
    border-left: 2px solid !important;
  }

  .tablet\:border-05, .tablet\:hover\:border-05:hover {
    border: .25rem solid !important;
  }

  .tablet\:border-y-05, .tablet\:hover\:border-y-05:hover {
    border-top: .25rem solid !important;
    border-bottom: .25rem solid !important;
  }

  .tablet\:border-x-05, .tablet\:hover\:border-x-05:hover {
    border-left: .25rem solid !important;
    border-right: .25rem solid !important;
  }

  .tablet\:border-top-05, .tablet\:hover\:border-top-05:hover {
    border-top: .25rem solid !important;
  }

  .tablet\:border-right-05, .tablet\:hover\:border-right-05:hover {
    border-right: .25rem solid !important;
  }

  .tablet\:border-bottom-05, .tablet\:hover\:border-bottom-05:hover {
    border-bottom: .25rem solid !important;
  }

  .tablet\:border-left-05, .tablet\:hover\:border-left-05:hover {
    border-left: .25rem solid !important;
  }

  .tablet\:border-1, .tablet\:hover\:border-1:hover {
    border: .5rem solid !important;
  }

  .tablet\:border-y-1, .tablet\:hover\:border-y-1:hover {
    border-top: .5rem solid !important;
    border-bottom: .5rem solid !important;
  }

  .tablet\:border-x-1, .tablet\:hover\:border-x-1:hover {
    border-left: .5rem solid !important;
    border-right: .5rem solid !important;
  }

  .tablet\:border-top-1, .tablet\:hover\:border-top-1:hover {
    border-top: .5rem solid !important;
  }

  .tablet\:border-right-1, .tablet\:hover\:border-right-1:hover {
    border-right: .5rem solid !important;
  }

  .tablet\:border-bottom-1, .tablet\:hover\:border-bottom-1:hover {
    border-bottom: .5rem solid !important;
  }

  .tablet\:border-left-1, .tablet\:hover\:border-left-1:hover {
    border-left: .5rem solid !important;
  }

  .tablet\:border-105, .tablet\:hover\:border-105:hover {
    border: .75rem solid !important;
  }

  .tablet\:border-y-105, .tablet\:hover\:border-y-105:hover {
    border-top: .75rem solid !important;
    border-bottom: .75rem solid !important;
  }

  .tablet\:border-x-105, .tablet\:hover\:border-x-105:hover {
    border-left: .75rem solid !important;
    border-right: .75rem solid !important;
  }

  .tablet\:border-top-105, .tablet\:hover\:border-top-105:hover {
    border-top: .75rem solid !important;
  }

  .tablet\:border-right-105, .tablet\:hover\:border-right-105:hover {
    border-right: .75rem solid !important;
  }

  .tablet\:border-bottom-105, .tablet\:hover\:border-bottom-105:hover {
    border-bottom: .75rem solid !important;
  }

  .tablet\:border-left-105, .tablet\:hover\:border-left-105:hover {
    border-left: .75rem solid !important;
  }

  .tablet\:border-2, .tablet\:hover\:border-2:hover {
    border: 1rem solid !important;
  }

  .tablet\:border-y-2, .tablet\:hover\:border-y-2:hover {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }

  .tablet\:border-x-2, .tablet\:hover\:border-x-2:hover {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }

  .tablet\:border-top-2, .tablet\:hover\:border-top-2:hover {
    border-top: 1rem solid !important;
  }

  .tablet\:border-right-2, .tablet\:hover\:border-right-2:hover {
    border-right: 1rem solid !important;
  }

  .tablet\:border-bottom-2, .tablet\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid !important;
  }

  .tablet\:border-left-2, .tablet\:hover\:border-left-2:hover {
    border-left: 1rem solid !important;
  }

  .tablet\:border-205, .tablet\:hover\:border-205:hover {
    border: 1.25rem solid !important;
  }

  .tablet\:border-y-205, .tablet\:hover\:border-y-205:hover {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }

  .tablet\:border-x-205, .tablet\:hover\:border-x-205:hover {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }

  .tablet\:border-top-205, .tablet\:hover\:border-top-205:hover {
    border-top: 1.25rem solid !important;
  }

  .tablet\:border-right-205, .tablet\:hover\:border-right-205:hover {
    border-right: 1.25rem solid !important;
  }

  .tablet\:border-bottom-205, .tablet\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid !important;
  }

  .tablet\:border-left-205, .tablet\:hover\:border-left-205:hover {
    border-left: 1.25rem solid !important;
  }

  .tablet\:border-3, .tablet\:hover\:border-3:hover {
    border: 1.5rem solid !important;
  }

  .tablet\:border-y-3, .tablet\:hover\:border-y-3:hover {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }

  .tablet\:border-x-3, .tablet\:hover\:border-x-3:hover {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }

  .tablet\:border-top-3, .tablet\:hover\:border-top-3:hover {
    border-top: 1.5rem solid !important;
  }

  .tablet\:border-right-3, .tablet\:hover\:border-right-3:hover {
    border-right: 1.5rem solid !important;
  }

  .tablet\:border-bottom-3, .tablet\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid !important;
  }

  .tablet\:border-left-3, .tablet\:hover\:border-left-3:hover {
    border-left: 1.5rem solid !important;
  }

  .tablet\:border-0, .tablet\:hover\:border-0:hover {
    border: 0 solid !important;
  }

  .tablet\:border-y-0, .tablet\:hover\:border-y-0:hover {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }

  .tablet\:border-x-0, .tablet\:hover\:border-x-0:hover {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }

  .tablet\:border-top-0, .tablet\:hover\:border-top-0:hover {
    border-top: 0 solid !important;
  }

  .tablet\:border-right-0, .tablet\:hover\:border-right-0:hover {
    border-right: 0 solid !important;
  }

  .tablet\:border-bottom-0, .tablet\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid !important;
  }

  .tablet\:border-left-0, .tablet\:hover\:border-left-0:hover {
    border-left: 0 solid !important;
  }

  .tablet\:border, .tablet\:hover\:border:hover {
    border: 1px solid !important;
  }

  .tablet\:border-y, .tablet\:hover\:border-y:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .tablet\:border-x, .tablet\:hover\:border-x:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .tablet\:border-top, .tablet\:hover\:border-top:hover {
    border-top: 1px solid !important;
  }

  .tablet\:border-right, .tablet\:hover\:border-right:hover {
    border-right: 1px solid !important;
  }

  .tablet\:border-bottom, .tablet\:hover\:border-bottom:hover {
    border-bottom: 1px solid !important;
  }

  .tablet\:border-left, .tablet\:hover\:border-left:hover {
    border-left: 1px solid !important;
  }

  .tablet\:border-transparent, .tablet\:hover\:border-transparent:hover {
    border-color: #0000 !important;
  }

  .tablet\:border-black, .tablet\:hover\:border-black:hover {
    border-color: #000 !important;
  }

  .tablet\:border-white, .tablet\:hover\:border-white:hover {
    border-color: #fff !important;
  }

  .tablet\:border-red, .tablet\:hover\:border-red:hover {
    border-color: #e52207 !important;
  }

  .tablet\:border-orange, .tablet\:hover\:border-orange:hover {
    border-color: #e66f0e !important;
  }

  .tablet\:border-gold, .tablet\:hover\:border-gold:hover {
    border-color: #ffbe2e !important;
  }

  .tablet\:border-yellow, .tablet\:hover\:border-yellow:hover {
    border-color: #fee685 !important;
  }

  .tablet\:border-green, .tablet\:hover\:border-green:hover {
    border-color: #538200 !important;
  }

  .tablet\:border-mint, .tablet\:hover\:border-mint:hover {
    border-color: #04c585 !important;
  }

  .tablet\:border-cyan, .tablet\:hover\:border-cyan:hover {
    border-color: #009ec1 !important;
  }

  .tablet\:border-blue, .tablet\:hover\:border-blue:hover {
    border-color: #0076d6 !important;
  }

  .tablet\:border-indigo, .tablet\:hover\:border-indigo:hover {
    border-color: #676cc8 !important;
  }

  .tablet\:border-violet, .tablet\:hover\:border-violet:hover {
    border-color: #8168b3 !important;
  }

  .tablet\:border-magenta, .tablet\:hover\:border-magenta:hover {
    border-color: #d72d79 !important;
  }

  .tablet\:border-gray-5, .tablet\:hover\:border-gray-5:hover {
    border-color: #f0f0f0 !important;
  }

  .tablet\:border-gray-10, .tablet\:hover\:border-gray-10:hover {
    border-color: #e6e6e6 !important;
  }

  .tablet\:border-gray-30, .tablet\:hover\:border-gray-30:hover {
    border-color: #adadad !important;
  }

  .tablet\:border-gray-50, .tablet\:hover\:border-gray-50:hover {
    border-color: #757575 !important;
  }

  .tablet\:border-gray-70, .tablet\:hover\:border-gray-70:hover {
    border-color: #454545 !important;
  }

  .tablet\:border-gray-90, .tablet\:hover\:border-gray-90:hover {
    border-color: #1b1b1b !important;
  }

  .tablet\:border-base-lightest, .tablet\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }

  .tablet\:border-base-lighter, .tablet\:hover\:border-base-lighter:hover {
    border-color: #dfe1e2 !important;
  }

  .tablet\:border-base-light, .tablet\:hover\:border-base-light:hover {
    border-color: #a9aeb1 !important;
  }

  .tablet\:border-base, .tablet\:hover\:border-base:hover {
    border-color: #71767a !important;
  }

  .tablet\:border-base-dark, .tablet\:hover\:border-base-dark:hover {
    border-color: #565c65 !important;
  }

  .tablet\:border-base-darker, .tablet\:hover\:border-base-darker:hover {
    border-color: #3d4551 !important;
  }

  .tablet\:border-base-darkest, .tablet\:hover\:border-base-darkest:hover {
    border-color: #2d2e2f !important;
  }

  .tablet\:border-ink, .tablet\:hover\:border-ink:hover {
    border-color: #1c1d1f !important;
  }

  .tablet\:border-primary-lighter, .tablet\:hover\:border-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }

  .tablet\:border-primary-light, .tablet\:hover\:border-primary-light:hover {
    border-color: #73b3e7 !important;
  }

  .tablet\:border-primary, .tablet\:hover\:border-primary:hover {
    border-color: #005ea2 !important;
  }

  .tablet\:border-primary-vivid, .tablet\:hover\:border-primary-vivid:hover {
    border-color: #0050d8 !important;
  }

  .tablet\:border-primary-dark, .tablet\:hover\:border-primary-dark:hover {
    border-color: #1a4480 !important;
  }

  .tablet\:border-primary-darker, .tablet\:hover\:border-primary-darker:hover {
    border-color: #162e51 !important;
  }

  .tablet\:border-secondary-lighter, .tablet\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4 !important;
  }

  .tablet\:border-secondary-light, .tablet\:hover\:border-secondary-light:hover {
    border-color: #f2938c !important;
  }

  .tablet\:border-secondary, .tablet\:hover\:border-secondary:hover {
    border-color: #d83933 !important;
  }

  .tablet\:border-secondary-vivid, .tablet\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }

  .tablet\:border-secondary-dark, .tablet\:hover\:border-secondary-dark:hover {
    border-color: #b50909 !important;
  }

  .tablet\:border-secondary-darker, .tablet\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03 !important;
  }

  .tablet\:border-accent-warm-darker, .tablet\:hover\:border-accent-warm-darker:hover {
    border-color: #775540 !important;
  }

  .tablet\:border-accent-warm-dark, .tablet\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }

  .tablet\:border-accent-warm, .tablet\:hover\:border-accent-warm:hover {
    border-color: #fa9441 !important;
  }

  .tablet\:border-accent-warm-light, .tablet\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }

  .tablet\:border-accent-warm-lighter, .tablet\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }

  .tablet\:border-accent-cool-darker, .tablet\:hover\:border-accent-cool-darker:hover {
    border-color: #07648d !important;
  }

  .tablet\:border-accent-cool-dark, .tablet\:hover\:border-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }

  .tablet\:border-accent-cool, .tablet\:hover\:border-accent-cool:hover {
    border-color: #00bde3 !important;
  }

  .tablet\:border-accent-cool-light, .tablet\:hover\:border-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }

  .tablet\:border-accent-cool-lighter, .tablet\:hover\:border-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }

  .tablet\:border-error-lighter, .tablet\:hover\:border-error-lighter:hover {
    border-color: #f4e3db !important;
  }

  .tablet\:border-error-light, .tablet\:hover\:border-error-light:hover {
    border-color: #f39268 !important;
  }

  .tablet\:border-error, .tablet\:hover\:border-error:hover {
    border-color: #d54309 !important;
  }

  .tablet\:border-error-dark, .tablet\:hover\:border-error-dark:hover {
    border-color: #b50909 !important;
  }

  .tablet\:border-error-darker, .tablet\:hover\:border-error-darker:hover {
    border-color: #6f3331 !important;
  }

  .tablet\:border-warning-lighter, .tablet\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1 !important;
  }

  .tablet\:border-warning-light, .tablet\:hover\:border-warning-light:hover {
    border-color: #fee685 !important;
  }

  .tablet\:border-warning, .tablet\:hover\:border-warning:hover {
    border-color: #ffbe2e !important;
  }

  .tablet\:border-warning-dark, .tablet\:hover\:border-warning-dark:hover {
    border-color: #e5a000 !important;
  }

  .tablet\:border-warning-darker, .tablet\:hover\:border-warning-darker:hover {
    border-color: #936f38 !important;
  }

  .tablet\:border-success-lighter, .tablet\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec !important;
  }

  .tablet\:border-success-light, .tablet\:hover\:border-success-light:hover {
    border-color: #70e17b !important;
  }

  .tablet\:border-success, .tablet\:hover\:border-success:hover {
    border-color: #00a91c !important;
  }

  .tablet\:border-success-dark, .tablet\:hover\:border-success-dark:hover {
    border-color: #008817 !important;
  }

  .tablet\:border-success-darker, .tablet\:hover\:border-success-darker:hover {
    border-color: #216e1f !important;
  }

  .tablet\:border-info-lighter, .tablet\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8 !important;
  }

  .tablet\:border-info-light, .tablet\:hover\:border-info-light:hover {
    border-color: #99deea !important;
  }

  .tablet\:border-info, .tablet\:hover\:border-info:hover {
    border-color: #00bde3 !important;
  }

  .tablet\:border-info-dark, .tablet\:hover\:border-info-dark:hover {
    border-color: #009ec1 !important;
  }

  .tablet\:border-info-darker, .tablet\:hover\:border-info-darker:hover {
    border-color: #2e6276 !important;
  }

  .tablet\:border-disabled-lighter, .tablet\:hover\:border-disabled-lighter:hover {
    border-color: #c9c9c9 !important;
  }

  .tablet\:border-disabled-light, .tablet\:hover\:border-disabled-light:hover {
    border-color: #919191 !important;
  }

  .tablet\:border-disabled, .tablet\:hover\:border-disabled:hover {
    border-color: #757575 !important;
  }

  .tablet\:border-disabled-dark, .tablet\:hover\:border-disabled-dark:hover {
    border-color: #454545 !important;
  }

  .tablet\:border-disabled-darker, .tablet\:hover\:border-disabled-darker:hover {
    border-color: #1b1b1b !important;
  }

  .tablet\:border-emergency, .tablet\:hover\:border-emergency:hover {
    border-color: #9c3d10 !important;
  }

  .tablet\:border-emergency-dark, .tablet\:hover\:border-emergency-dark:hover {
    border-color: #332d29 !important;
  }

  .tablet\:radius-0 {
    border-radius: 0 !important;
  }

  .tablet\:radius-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .tablet\:radius-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .tablet\:radius-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .tablet\:radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .tablet\:radius-sm {
    border-radius: 2px !important;
  }

  .tablet\:radius-top-sm {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
  }

  .tablet\:radius-right-sm {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  .tablet\:radius-bottom-sm {
    border-bottom-right-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .tablet\:radius-left-sm {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .tablet\:radius-md {
    border-radius: .25rem !important;
  }

  .tablet\:radius-top-md {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .tablet\:radius-right-md {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .tablet\:radius-bottom-md {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .tablet\:radius-left-md {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .tablet\:radius-lg {
    border-radius: .5rem !important;
  }

  .tablet\:radius-top-lg {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .tablet\:radius-right-lg {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .tablet\:radius-bottom-lg {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .tablet\:radius-left-lg {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .tablet\:radius-pill {
    border-radius: 99rem !important;
  }

  .tablet\:radius-top-pill {
    border-top-left-radius: 99rem !important;
    border-top-right-radius: 99rem !important;
  }

  .tablet\:radius-right-pill {
    border-top-right-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }

  .tablet\:radius-bottom-pill {
    border-bottom-right-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  .tablet\:radius-left-pill {
    border-top-left-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  .tablet\:display-block {
    display: block !important;
  }

  .tablet\:display-flex {
    display: flex !important;
  }

  .tablet\:display-none {
    display: none !important;
  }

  .tablet\:display-inline {
    display: inline !important;
  }

  .tablet\:display-inline-block {
    display: inline-block !important;
  }

  .tablet\:display-inline-flex {
    display: inline-flex !important;
  }

  .tablet\:display-table {
    display: table !important;
  }

  .tablet\:display-table-cell {
    display: table-cell !important;
  }

  .tablet\:display-table-row {
    display: table-row !important;
  }

  .tablet\:font-mono-3xs {
    font-size: .77rem !important;
  }

  .tablet\:font-mono-2xs {
    font-size: .83rem !important;
  }

  .tablet\:font-mono-xs {
    font-size: .89rem !important;
  }

  .tablet\:font-mono-sm {
    font-size: .95rem !important;
  }

  .tablet\:font-mono-md {
    font-size: 1.19rem !important;
  }

  .tablet\:font-mono-lg {
    font-size: 1.31rem !important;
  }

  .tablet\:font-mono-xl {
    font-size: 1.91rem !important;
  }

  .tablet\:font-mono-2xl {
    font-size: 2.38rem !important;
  }

  .tablet\:font-mono-3xl {
    font-size: 2.86rem !important;
  }

  .tablet\:font-sans-3xs {
    font-size: .81rem !important;
  }

  .tablet\:font-sans-2xs {
    font-size: .87rem !important;
  }

  .tablet\:font-sans-xs {
    font-size: .93rem !important;
  }

  .tablet\:font-sans-sm {
    font-size: .99rem !important;
  }

  .tablet\:font-sans-md {
    font-size: 1.24rem !important;
  }

  .tablet\:font-sans-lg {
    font-size: 1.37rem !important;
  }

  .tablet\:font-sans-xl {
    font-size: 1.99rem !important;
  }

  .tablet\:font-sans-2xl {
    font-size: 2.49rem !important;
  }

  .tablet\:font-sans-3xl {
    font-size: 2.98rem !important;
  }

  .tablet\:font-serif-3xs {
    font-size: .81rem !important;
  }

  .tablet\:font-serif-2xs {
    font-size: .87rem !important;
  }

  .tablet\:font-serif-xs {
    font-size: .93rem !important;
  }

  .tablet\:font-serif-sm {
    font-size: .99rem !important;
  }

  .tablet\:font-serif-md {
    font-size: 1.24rem !important;
  }

  .tablet\:font-serif-lg {
    font-size: 1.37rem !important;
  }

  .tablet\:font-serif-xl {
    font-size: 1.99rem !important;
  }

  .tablet\:font-serif-2xl {
    font-size: 2.49rem !important;
  }

  .tablet\:font-serif-3xl {
    font-size: 2.98rem !important;
  }

  .tablet\:font-heading-3xs {
    font-size: .81rem !important;
  }

  .tablet\:font-heading-2xs {
    font-size: .87rem !important;
  }

  .tablet\:font-heading-xs {
    font-size: .93rem !important;
  }

  .tablet\:font-heading-sm {
    font-size: .99rem !important;
  }

  .tablet\:font-heading-md {
    font-size: 1.24rem !important;
  }

  .tablet\:font-heading-lg {
    font-size: 1.37rem !important;
  }

  .tablet\:font-heading-xl {
    font-size: 1.99rem !important;
  }

  .tablet\:font-heading-2xl {
    font-size: 2.49rem !important;
  }

  .tablet\:font-heading-3xl {
    font-size: 2.98rem !important;
  }

  .tablet\:font-body-3xs {
    font-size: .81rem !important;
  }

  .tablet\:font-body-2xs {
    font-size: .87rem !important;
  }

  .tablet\:font-body-xs {
    font-size: .93rem !important;
  }

  .tablet\:font-body-sm {
    font-size: .99rem !important;
  }

  .tablet\:font-body-md {
    font-size: 1.24rem !important;
  }

  .tablet\:font-body-lg {
    font-size: 1.37rem !important;
  }

  .tablet\:font-body-xl {
    font-size: 1.99rem !important;
  }

  .tablet\:font-body-2xl {
    font-size: 2.49rem !important;
  }

  .tablet\:font-body-3xl {
    font-size: 2.98rem !important;
  }

  .tablet\:font-code-3xs {
    font-size: .77rem !important;
  }

  .tablet\:font-code-2xs {
    font-size: .83rem !important;
  }

  .tablet\:font-code-xs {
    font-size: .89rem !important;
  }

  .tablet\:font-code-sm {
    font-size: .95rem !important;
  }

  .tablet\:font-code-md {
    font-size: 1.19rem !important;
  }

  .tablet\:font-code-lg {
    font-size: 1.31rem !important;
  }

  .tablet\:font-code-xl {
    font-size: 1.91rem !important;
  }

  .tablet\:font-code-2xl {
    font-size: 2.38rem !important;
  }

  .tablet\:font-code-3xl {
    font-size: 2.86rem !important;
  }

  .tablet\:font-alt-3xs {
    font-size: .81rem !important;
  }

  .tablet\:font-alt-2xs {
    font-size: .87rem !important;
  }

  .tablet\:font-alt-xs {
    font-size: .93rem !important;
  }

  .tablet\:font-alt-sm {
    font-size: .99rem !important;
  }

  .tablet\:font-alt-md {
    font-size: 1.24rem !important;
  }

  .tablet\:font-alt-lg {
    font-size: 1.37rem !important;
  }

  .tablet\:font-alt-xl {
    font-size: 1.99rem !important;
  }

  .tablet\:font-alt-2xl {
    font-size: 2.49rem !important;
  }

  .tablet\:font-alt-3xl {
    font-size: 2.98rem !important;
  }

  .tablet\:font-ui-3xs {
    font-size: .81rem !important;
  }

  .tablet\:font-ui-2xs {
    font-size: .87rem !important;
  }

  .tablet\:font-ui-xs {
    font-size: .93rem !important;
  }

  .tablet\:font-ui-sm {
    font-size: .99rem !important;
  }

  .tablet\:font-ui-md {
    font-size: 1.24rem !important;
  }

  .tablet\:font-ui-lg {
    font-size: 1.37rem !important;
  }

  .tablet\:font-ui-xl {
    font-size: 1.99rem !important;
  }

  .tablet\:font-ui-2xl {
    font-size: 2.49rem !important;
  }

  .tablet\:font-ui-3xl {
    font-size: 2.98rem !important;
  }

  .tablet\:text-light {
    font-weight: 300 !important;
  }

  .tablet\:text-normal {
    font-weight: normal !important;
  }

  .tablet\:text-semibold {
    font-weight: 600 !important;
  }

  .tablet\:text-bold {
    font-weight: 700 !important;
  }

  .tablet\:flex-justify-center {
    justify-content: center !important;
  }

  .tablet\:flex-justify-start {
    justify-content: flex-start !important;
  }

  .tablet\:flex-justify-end {
    justify-content: flex-end !important;
  }

  .tablet\:flex-justify {
    justify-content: space-between !important;
  }

  .tablet\:line-height-sans-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-sans-2 {
    line-height: 1.2 !important;
  }

  .tablet\:line-height-sans-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-sans-4 {
    line-height: 1.5 !important;
  }

  .tablet\:line-height-sans-5 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-sans-6 {
    line-height: 1.8 !important;
  }

  .tablet\:line-height-serif-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-serif-2 {
    line-height: 1.2 !important;
  }

  .tablet\:line-height-serif-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-serif-4 {
    line-height: 1.5 !important;
  }

  .tablet\:line-height-serif-5 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-serif-6 {
    line-height: 1.8 !important;
  }

  .tablet\:line-height-mono-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-mono-2 {
    line-height: 1.3 !important;
  }

  .tablet\:line-height-mono-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-mono-4 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-mono-5 {
    line-height: 1.7 !important;
  }

  .tablet\:line-height-mono-6 {
    line-height: 1.8 !important;
  }

  .tablet\:line-height-heading-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-heading-2 {
    line-height: 1.2 !important;
  }

  .tablet\:line-height-heading-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-heading-4 {
    line-height: 1.5 !important;
  }

  .tablet\:line-height-heading-5 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-heading-6 {
    line-height: 1.8 !important;
  }

  .tablet\:line-height-ui-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-ui-2 {
    line-height: 1.2 !important;
  }

  .tablet\:line-height-ui-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-ui-4 {
    line-height: 1.5 !important;
  }

  .tablet\:line-height-ui-5 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-ui-6 {
    line-height: 1.8 !important;
  }

  .tablet\:line-height-body-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-body-2 {
    line-height: 1.2 !important;
  }

  .tablet\:line-height-body-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-body-4 {
    line-height: 1.5 !important;
  }

  .tablet\:line-height-body-5 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-body-6 {
    line-height: 1.8 !important;
  }

  .tablet\:line-height-code-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-code-2 {
    line-height: 1.3 !important;
  }

  .tablet\:line-height-code-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-code-4 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-code-5 {
    line-height: 1.7 !important;
  }

  .tablet\:line-height-code-6 {
    line-height: 1.8 !important;
  }

  .tablet\:line-height-alt-1 {
    line-height: 1 !important;
  }

  .tablet\:line-height-alt-2 {
    line-height: 1.2 !important;
  }

  .tablet\:line-height-alt-3 {
    line-height: 1.4 !important;
  }

  .tablet\:line-height-alt-4 {
    line-height: 1.5 !important;
  }

  .tablet\:line-height-alt-5 {
    line-height: 1.6 !important;
  }

  .tablet\:line-height-alt-6 {
    line-height: 1.8 !important;
  }

  .tablet\:margin-neg-1px {
    margin: -1px !important;
  }

  .tablet\:margin-neg-2px {
    margin: -2px !important;
  }

  .tablet\:margin-neg-05 {
    margin: -.25rem !important;
  }

  .tablet\:margin-neg-1 {
    margin: -.5rem !important;
  }

  .tablet\:margin-neg-105 {
    margin: -.75rem !important;
  }

  .tablet\:margin-neg-2 {
    margin: -1rem !important;
  }

  .tablet\:margin-neg-205 {
    margin: -1.25rem !important;
  }

  .tablet\:margin-neg-3 {
    margin: -1.5rem !important;
  }

  .tablet\:margin-1px {
    margin: 1px !important;
  }

  .tablet\:margin-2px {
    margin: 2px !important;
  }

  .tablet\:margin-05 {
    margin: .25rem !important;
  }

  .tablet\:margin-1 {
    margin: .5rem !important;
  }

  .tablet\:margin-105 {
    margin: .75rem !important;
  }

  .tablet\:margin-2 {
    margin: 1rem !important;
  }

  .tablet\:margin-205 {
    margin: 1.25rem !important;
  }

  .tablet\:margin-3 {
    margin: 1.5rem !important;
  }

  .tablet\:margin-neg-4 {
    margin: -2rem !important;
  }

  .tablet\:margin-neg-5 {
    margin: -2.5rem !important;
  }

  .tablet\:margin-neg-6 {
    margin: -3rem !important;
  }

  .tablet\:margin-neg-7 {
    margin: -3.5rem !important;
  }

  .tablet\:margin-neg-8 {
    margin: -4rem !important;
  }

  .tablet\:margin-neg-9 {
    margin: -4.5rem !important;
  }

  .tablet\:margin-neg-10 {
    margin: -5rem !important;
  }

  .tablet\:margin-neg-15 {
    margin: -7.5rem !important;
  }

  .tablet\:margin-4 {
    margin: 2rem !important;
  }

  .tablet\:margin-5 {
    margin: 2.5rem !important;
  }

  .tablet\:margin-6 {
    margin: 3rem !important;
  }

  .tablet\:margin-7 {
    margin: 3.5rem !important;
  }

  .tablet\:margin-8 {
    margin: 4rem !important;
  }

  .tablet\:margin-9 {
    margin: 4.5rem !important;
  }

  .tablet\:margin-10 {
    margin: 5rem !important;
  }

  .tablet\:margin-15 {
    margin: 7.5rem !important;
  }

  .tablet\:margin-05em {
    margin: .5em !important;
  }

  .tablet\:margin-1em {
    margin: 1em !important;
  }

  .tablet\:margin-105em {
    margin: 1.5em !important;
  }

  .tablet\:margin-2em {
    margin: 2em !important;
  }

  .tablet\:margin-0 {
    margin: 0 !important;
  }

  .tablet\:margin-y-1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .tablet\:margin-top-1px {
    margin-top: 1px !important;
  }

  .tablet\:margin-bottom-1px {
    margin-bottom: 1px !important;
  }

  .tablet\:margin-y-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .tablet\:margin-top-2px {
    margin-top: 2px !important;
  }

  .tablet\:margin-bottom-2px {
    margin-bottom: 2px !important;
  }

  .tablet\:margin-y-05 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .tablet\:margin-top-05 {
    margin-top: .25rem !important;
  }

  .tablet\:margin-bottom-05 {
    margin-bottom: .25rem !important;
  }

  .tablet\:margin-y-1 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .tablet\:margin-top-1 {
    margin-top: .5rem !important;
  }

  .tablet\:margin-bottom-1 {
    margin-bottom: .5rem !important;
  }

  .tablet\:margin-y-105 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .tablet\:margin-top-105 {
    margin-top: .75rem !important;
  }

  .tablet\:margin-bottom-105 {
    margin-bottom: .75rem !important;
  }

  .tablet\:margin-y-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .tablet\:margin-top-2 {
    margin-top: 1rem !important;
  }

  .tablet\:margin-bottom-2 {
    margin-bottom: 1rem !important;
  }

  .tablet\:margin-y-205 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .tablet\:margin-top-205 {
    margin-top: 1.25rem !important;
  }

  .tablet\:margin-bottom-205 {
    margin-bottom: 1.25rem !important;
  }

  .tablet\:margin-y-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .tablet\:margin-top-3 {
    margin-top: 1.5rem !important;
  }

  .tablet\:margin-bottom-3 {
    margin-bottom: 1.5rem !important;
  }

  .tablet\:margin-y-neg-1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .tablet\:margin-top-neg-1px {
    margin-top: -1px !important;
  }

  .tablet\:margin-bottom-neg-1px {
    margin-bottom: -1px !important;
  }

  .tablet\:margin-y-neg-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .tablet\:margin-top-neg-2px {
    margin-top: -2px !important;
  }

  .tablet\:margin-bottom-neg-2px {
    margin-bottom: -2px !important;
  }

  .tablet\:margin-y-neg-05 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .tablet\:margin-top-neg-05 {
    margin-top: -.25rem !important;
  }

  .tablet\:margin-bottom-neg-05 {
    margin-bottom: -.25rem !important;
  }

  .tablet\:margin-y-neg-1 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .tablet\:margin-top-neg-1 {
    margin-top: -.5rem !important;
  }

  .tablet\:margin-bottom-neg-1 {
    margin-bottom: -.5rem !important;
  }

  .tablet\:margin-y-neg-105 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .tablet\:margin-top-neg-105 {
    margin-top: -.75rem !important;
  }

  .tablet\:margin-bottom-neg-105 {
    margin-bottom: -.75rem !important;
  }

  .tablet\:margin-y-neg-2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .tablet\:margin-top-neg-2 {
    margin-top: -1rem !important;
  }

  .tablet\:margin-bottom-neg-2 {
    margin-bottom: -1rem !important;
  }

  .tablet\:margin-y-neg-205 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .tablet\:margin-top-neg-205 {
    margin-top: -1.25rem !important;
  }

  .tablet\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem !important;
  }

  .tablet\:margin-y-neg-3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .tablet\:margin-top-neg-3 {
    margin-top: -1.5rem !important;
  }

  .tablet\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem !important;
  }

  .tablet\:margin-y-neg-4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .tablet\:margin-top-neg-4 {
    margin-top: -2rem !important;
  }

  .tablet\:margin-bottom-neg-4 {
    margin-bottom: -2rem !important;
  }

  .tablet\:margin-y-neg-5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .tablet\:margin-top-neg-5 {
    margin-top: -2.5rem !important;
  }

  .tablet\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem !important;
  }

  .tablet\:margin-y-neg-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .tablet\:margin-top-neg-6 {
    margin-top: -3rem !important;
  }

  .tablet\:margin-bottom-neg-6 {
    margin-bottom: -3rem !important;
  }

  .tablet\:margin-y-neg-7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .tablet\:margin-top-neg-7 {
    margin-top: -3.5rem !important;
  }

  .tablet\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem !important;
  }

  .tablet\:margin-y-neg-8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .tablet\:margin-top-neg-8 {
    margin-top: -4rem !important;
  }

  .tablet\:margin-bottom-neg-8 {
    margin-bottom: -4rem !important;
  }

  .tablet\:margin-y-neg-9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .tablet\:margin-top-neg-9 {
    margin-top: -4.5rem !important;
  }

  .tablet\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem !important;
  }

  .tablet\:margin-y-neg-10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .tablet\:margin-top-neg-10 {
    margin-top: -5rem !important;
  }

  .tablet\:margin-bottom-neg-10 {
    margin-bottom: -5rem !important;
  }

  .tablet\:margin-y-neg-15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .tablet\:margin-top-neg-15 {
    margin-top: -7.5rem !important;
  }

  .tablet\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem !important;
  }

  .tablet\:margin-y-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .tablet\:margin-top-4 {
    margin-top: 2rem !important;
  }

  .tablet\:margin-bottom-4 {
    margin-bottom: 2rem !important;
  }

  .tablet\:margin-y-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .tablet\:margin-top-5 {
    margin-top: 2.5rem !important;
  }

  .tablet\:margin-bottom-5 {
    margin-bottom: 2.5rem !important;
  }

  .tablet\:margin-y-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .tablet\:margin-top-6 {
    margin-top: 3rem !important;
  }

  .tablet\:margin-bottom-6 {
    margin-bottom: 3rem !important;
  }

  .tablet\:margin-y-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .tablet\:margin-top-7 {
    margin-top: 3.5rem !important;
  }

  .tablet\:margin-bottom-7 {
    margin-bottom: 3.5rem !important;
  }

  .tablet\:margin-y-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .tablet\:margin-top-8 {
    margin-top: 4rem !important;
  }

  .tablet\:margin-bottom-8 {
    margin-bottom: 4rem !important;
  }

  .tablet\:margin-y-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .tablet\:margin-top-9 {
    margin-top: 4.5rem !important;
  }

  .tablet\:margin-bottom-9 {
    margin-bottom: 4.5rem !important;
  }

  .tablet\:margin-y-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .tablet\:margin-top-10 {
    margin-top: 5rem !important;
  }

  .tablet\:margin-bottom-10 {
    margin-bottom: 5rem !important;
  }

  .tablet\:margin-y-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .tablet\:margin-top-15 {
    margin-top: 7.5rem !important;
  }

  .tablet\:margin-bottom-15 {
    margin-bottom: 7.5rem !important;
  }

  .tablet\:margin-y-05em {
    margin-top: .5em !important;
    margin-bottom: .5em !important;
  }

  .tablet\:margin-top-05em {
    margin-top: .5em !important;
  }

  .tablet\:margin-bottom-05em {
    margin-bottom: .5em !important;
  }

  .tablet\:margin-y-1em {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  .tablet\:margin-top-1em {
    margin-top: 1em !important;
  }

  .tablet\:margin-bottom-1em {
    margin-bottom: 1em !important;
  }

  .tablet\:margin-y-105em {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }

  .tablet\:margin-top-105em {
    margin-top: 1.5em !important;
  }

  .tablet\:margin-bottom-105em {
    margin-bottom: 1.5em !important;
  }

  .tablet\:margin-y-2em {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }

  .tablet\:margin-top-2em {
    margin-top: 2em !important;
  }

  .tablet\:margin-bottom-2em {
    margin-bottom: 2em !important;
  }

  .tablet\:margin-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .tablet\:margin-top-0 {
    margin-top: 0 !important;
  }

  .tablet\:margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  .tablet\:margin-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .tablet\:margin-top-auto {
    margin-top: auto !important;
  }

  .tablet\:margin-bottom-auto {
    margin-bottom: auto !important;
  }

  .tablet\:margin-x-1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .tablet\:margin-right-1px {
    margin-right: 1px !important;
  }

  .tablet\:margin-left-1px {
    margin-left: 1px !important;
  }

  .tablet\:margin-x-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .tablet\:margin-right-2px {
    margin-right: 2px !important;
  }

  .tablet\:margin-left-2px {
    margin-left: 2px !important;
  }

  .tablet\:margin-x-05 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .tablet\:margin-right-05 {
    margin-right: .25rem !important;
  }

  .tablet\:margin-left-05 {
    margin-left: .25rem !important;
  }

  .tablet\:margin-x-1 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .tablet\:margin-right-1 {
    margin-right: .5rem !important;
  }

  .tablet\:margin-left-1 {
    margin-left: .5rem !important;
  }

  .tablet\:margin-x-105 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .tablet\:margin-right-105 {
    margin-right: .75rem !important;
  }

  .tablet\:margin-left-105 {
    margin-left: .75rem !important;
  }

  .tablet\:margin-x-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .tablet\:margin-right-2 {
    margin-right: 1rem !important;
  }

  .tablet\:margin-left-2 {
    margin-left: 1rem !important;
  }

  .tablet\:margin-x-205 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .tablet\:margin-right-205 {
    margin-right: 1.25rem !important;
  }

  .tablet\:margin-left-205 {
    margin-left: 1.25rem !important;
  }

  .tablet\:margin-x-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .tablet\:margin-right-3 {
    margin-right: 1.5rem !important;
  }

  .tablet\:margin-left-3 {
    margin-left: 1.5rem !important;
  }

  .tablet\:margin-x-neg-1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .tablet\:margin-right-neg-1px {
    margin-right: -1px !important;
  }

  .tablet\:margin-left-neg-1px {
    margin-left: -1px !important;
  }

  .tablet\:margin-x-neg-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .tablet\:margin-right-neg-2px {
    margin-right: -2px !important;
  }

  .tablet\:margin-left-neg-2px {
    margin-left: -2px !important;
  }

  .tablet\:margin-x-neg-05 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .tablet\:margin-right-neg-05 {
    margin-right: -.25rem !important;
  }

  .tablet\:margin-left-neg-05 {
    margin-left: -.25rem !important;
  }

  .tablet\:margin-x-neg-1 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .tablet\:margin-right-neg-1 {
    margin-right: -.5rem !important;
  }

  .tablet\:margin-left-neg-1 {
    margin-left: -.5rem !important;
  }

  .tablet\:margin-x-neg-105 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .tablet\:margin-right-neg-105 {
    margin-right: -.75rem !important;
  }

  .tablet\:margin-left-neg-105 {
    margin-left: -.75rem !important;
  }

  .tablet\:margin-x-neg-2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .tablet\:margin-right-neg-2 {
    margin-right: -1rem !important;
  }

  .tablet\:margin-left-neg-2 {
    margin-left: -1rem !important;
  }

  .tablet\:margin-x-neg-205 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .tablet\:margin-right-neg-205 {
    margin-right: -1.25rem !important;
  }

  .tablet\:margin-left-neg-205 {
    margin-left: -1.25rem !important;
  }

  .tablet\:margin-x-neg-3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .tablet\:margin-right-neg-3 {
    margin-right: -1.5rem !important;
  }

  .tablet\:margin-left-neg-3 {
    margin-left: -1.5rem !important;
  }

  .tablet\:margin-x-neg-4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .tablet\:margin-right-neg-4 {
    margin-right: -2rem !important;
  }

  .tablet\:margin-left-neg-4 {
    margin-left: -2rem !important;
  }

  .tablet\:margin-x-neg-5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .tablet\:margin-right-neg-5 {
    margin-right: -2.5rem !important;
  }

  .tablet\:margin-left-neg-5 {
    margin-left: -2.5rem !important;
  }

  .tablet\:margin-x-neg-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .tablet\:margin-right-neg-6 {
    margin-right: -3rem !important;
  }

  .tablet\:margin-left-neg-6 {
    margin-left: -3rem !important;
  }

  .tablet\:margin-x-neg-7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .tablet\:margin-right-neg-7 {
    margin-right: -3.5rem !important;
  }

  .tablet\:margin-left-neg-7 {
    margin-left: -3.5rem !important;
  }

  .tablet\:margin-x-neg-8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .tablet\:margin-right-neg-8 {
    margin-right: -4rem !important;
  }

  .tablet\:margin-left-neg-8 {
    margin-left: -4rem !important;
  }

  .tablet\:margin-x-neg-9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .tablet\:margin-right-neg-9 {
    margin-right: -4.5rem !important;
  }

  .tablet\:margin-left-neg-9 {
    margin-left: -4.5rem !important;
  }

  .tablet\:margin-x-neg-10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .tablet\:margin-right-neg-10 {
    margin-right: -5rem !important;
  }

  .tablet\:margin-left-neg-10 {
    margin-left: -5rem !important;
  }

  .tablet\:margin-x-neg-15 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .tablet\:margin-right-neg-15 {
    margin-right: -7.5rem !important;
  }

  .tablet\:margin-left-neg-15 {
    margin-left: -7.5rem !important;
  }

  .tablet\:margin-x-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .tablet\:margin-right-4 {
    margin-right: 2rem !important;
  }

  .tablet\:margin-left-4 {
    margin-left: 2rem !important;
  }

  .tablet\:margin-x-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .tablet\:margin-right-5 {
    margin-right: 2.5rem !important;
  }

  .tablet\:margin-left-5 {
    margin-left: 2.5rem !important;
  }

  .tablet\:margin-x-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .tablet\:margin-right-6 {
    margin-right: 3rem !important;
  }

  .tablet\:margin-left-6 {
    margin-left: 3rem !important;
  }

  .tablet\:margin-x-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .tablet\:margin-right-7 {
    margin-right: 3.5rem !important;
  }

  .tablet\:margin-left-7 {
    margin-left: 3.5rem !important;
  }

  .tablet\:margin-x-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .tablet\:margin-right-8 {
    margin-right: 4rem !important;
  }

  .tablet\:margin-left-8 {
    margin-left: 4rem !important;
  }

  .tablet\:margin-x-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .tablet\:margin-right-9 {
    margin-right: 4.5rem !important;
  }

  .tablet\:margin-left-9 {
    margin-left: 4.5rem !important;
  }

  .tablet\:margin-x-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .tablet\:margin-right-10 {
    margin-right: 5rem !important;
  }

  .tablet\:margin-left-10 {
    margin-left: 5rem !important;
  }

  .tablet\:margin-x-15 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .tablet\:margin-right-15 {
    margin-right: 7.5rem !important;
  }

  .tablet\:margin-left-15 {
    margin-left: 7.5rem !important;
  }

  .tablet\:margin-x-card {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .tablet\:margin-right-card {
    margin-right: 10rem !important;
  }

  .tablet\:margin-left-card {
    margin-left: 10rem !important;
  }

  .tablet\:margin-x-card-lg {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .tablet\:margin-right-card-lg {
    margin-right: 15rem !important;
  }

  .tablet\:margin-left-card-lg {
    margin-left: 15rem !important;
  }

  .tablet\:margin-x-mobile {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .tablet\:margin-right-mobile {
    margin-right: 20rem !important;
  }

  .tablet\:margin-left-mobile {
    margin-left: 20rem !important;
  }

  .tablet\:margin-x-05em {
    margin-left: .5em !important;
    margin-right: .5em !important;
  }

  .tablet\:margin-right-05em {
    margin-right: .5em !important;
  }

  .tablet\:margin-left-05em {
    margin-left: .5em !important;
  }

  .tablet\:margin-x-1em {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }

  .tablet\:margin-right-1em {
    margin-right: 1em !important;
  }

  .tablet\:margin-left-1em {
    margin-left: 1em !important;
  }

  .tablet\:margin-x-105em {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }

  .tablet\:margin-right-105em {
    margin-right: 1.5em !important;
  }

  .tablet\:margin-left-105em {
    margin-left: 1.5em !important;
  }

  .tablet\:margin-x-2em {
    margin-left: 2em !important;
    margin-right: 2em !important;
  }

  .tablet\:margin-right-2em {
    margin-right: 2em !important;
  }

  .tablet\:margin-left-2em {
    margin-left: 2em !important;
  }

  .tablet\:margin-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .tablet\:margin-right-0 {
    margin-right: 0 !important;
  }

  .tablet\:margin-left-0 {
    margin-left: 0 !important;
  }

  .tablet\:margin-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .tablet\:margin-right-auto {
    margin-right: auto !important;
  }

  .tablet\:margin-left-auto {
    margin-left: auto !important;
  }

  .tablet\:measure-1 {
    max-width: 44ex !important;
  }

  .tablet\:measure-2 {
    max-width: 60ex !important;
  }

  .tablet\:measure-3 {
    max-width: 64ex !important;
  }

  .tablet\:measure-4 {
    max-width: 68ex !important;
  }

  .tablet\:measure-5 {
    max-width: 72ex !important;
  }

  .tablet\:measure-6 {
    max-width: 88ex !important;
  }

  .tablet\:measure-none {
    max-width: none !important;
  }

  .tablet\:order-first {
    order: -1 !important;
  }

  .tablet\:order-last {
    order: 999 !important;
  }

  .tablet\:order-initial {
    order: initial !important;
  }

  .tablet\:order-0 {
    order: 0 !important;
  }

  .tablet\:order-1 {
    order: 1 !important;
  }

  .tablet\:order-2 {
    order: 2 !important;
  }

  .tablet\:order-3 {
    order: 3 !important;
  }

  .tablet\:order-4 {
    order: 4 !important;
  }

  .tablet\:order-5 {
    order: 5 !important;
  }

  .tablet\:order-6 {
    order: 6 !important;
  }

  .tablet\:order-7 {
    order: 7 !important;
  }

  .tablet\:order-8 {
    order: 8 !important;
  }

  .tablet\:order-9 {
    order: 9 !important;
  }

  .tablet\:order-10 {
    order: 10 !important;
  }

  .tablet\:order-11 {
    order: 11 !important;
  }

  .tablet\:padding-1px {
    padding: 1px !important;
  }

  .tablet\:padding-y-1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .tablet\:padding-x-1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .tablet\:padding-top-1px {
    padding-top: 1px !important;
  }

  .tablet\:padding-right-1px {
    padding-right: 1px !important;
  }

  .tablet\:padding-bottom-1px {
    padding-bottom: 1px !important;
  }

  .tablet\:padding-left-1px {
    padding-left: 1px !important;
  }

  .tablet\:padding-2px {
    padding: 2px !important;
  }

  .tablet\:padding-y-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .tablet\:padding-x-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .tablet\:padding-top-2px {
    padding-top: 2px !important;
  }

  .tablet\:padding-right-2px {
    padding-right: 2px !important;
  }

  .tablet\:padding-bottom-2px {
    padding-bottom: 2px !important;
  }

  .tablet\:padding-left-2px {
    padding-left: 2px !important;
  }

  .tablet\:padding-05 {
    padding: .25rem !important;
  }

  .tablet\:padding-y-05 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .tablet\:padding-x-05 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .tablet\:padding-top-05 {
    padding-top: .25rem !important;
  }

  .tablet\:padding-right-05 {
    padding-right: .25rem !important;
  }

  .tablet\:padding-bottom-05 {
    padding-bottom: .25rem !important;
  }

  .tablet\:padding-left-05 {
    padding-left: .25rem !important;
  }

  .tablet\:padding-1 {
    padding: .5rem !important;
  }

  .tablet\:padding-y-1 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .tablet\:padding-x-1 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .tablet\:padding-top-1 {
    padding-top: .5rem !important;
  }

  .tablet\:padding-right-1 {
    padding-right: .5rem !important;
  }

  .tablet\:padding-bottom-1 {
    padding-bottom: .5rem !important;
  }

  .tablet\:padding-left-1 {
    padding-left: .5rem !important;
  }

  .tablet\:padding-105 {
    padding: .75rem !important;
  }

  .tablet\:padding-y-105 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .tablet\:padding-x-105 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .tablet\:padding-top-105 {
    padding-top: .75rem !important;
  }

  .tablet\:padding-right-105 {
    padding-right: .75rem !important;
  }

  .tablet\:padding-bottom-105 {
    padding-bottom: .75rem !important;
  }

  .tablet\:padding-left-105 {
    padding-left: .75rem !important;
  }

  .tablet\:padding-2 {
    padding: 1rem !important;
  }

  .tablet\:padding-y-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .tablet\:padding-x-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .tablet\:padding-top-2 {
    padding-top: 1rem !important;
  }

  .tablet\:padding-right-2 {
    padding-right: 1rem !important;
  }

  .tablet\:padding-bottom-2 {
    padding-bottom: 1rem !important;
  }

  .tablet\:padding-left-2 {
    padding-left: 1rem !important;
  }

  .tablet\:padding-205 {
    padding: 1.25rem !important;
  }

  .tablet\:padding-y-205 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .tablet\:padding-x-205 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .tablet\:padding-top-205 {
    padding-top: 1.25rem !important;
  }

  .tablet\:padding-right-205 {
    padding-right: 1.25rem !important;
  }

  .tablet\:padding-bottom-205 {
    padding-bottom: 1.25rem !important;
  }

  .tablet\:padding-left-205 {
    padding-left: 1.25rem !important;
  }

  .tablet\:padding-3 {
    padding: 1.5rem !important;
  }

  .tablet\:padding-y-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .tablet\:padding-x-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .tablet\:padding-top-3 {
    padding-top: 1.5rem !important;
  }

  .tablet\:padding-right-3 {
    padding-right: 1.5rem !important;
  }

  .tablet\:padding-bottom-3 {
    padding-bottom: 1.5rem !important;
  }

  .tablet\:padding-left-3 {
    padding-left: 1.5rem !important;
  }

  .tablet\:padding-4 {
    padding: 2rem !important;
  }

  .tablet\:padding-y-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .tablet\:padding-x-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .tablet\:padding-top-4 {
    padding-top: 2rem !important;
  }

  .tablet\:padding-right-4 {
    padding-right: 2rem !important;
  }

  .tablet\:padding-bottom-4 {
    padding-bottom: 2rem !important;
  }

  .tablet\:padding-left-4 {
    padding-left: 2rem !important;
  }

  .tablet\:padding-5 {
    padding: 2.5rem !important;
  }

  .tablet\:padding-y-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .tablet\:padding-x-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .tablet\:padding-top-5 {
    padding-top: 2.5rem !important;
  }

  .tablet\:padding-right-5 {
    padding-right: 2.5rem !important;
  }

  .tablet\:padding-bottom-5 {
    padding-bottom: 2.5rem !important;
  }

  .tablet\:padding-left-5 {
    padding-left: 2.5rem !important;
  }

  .tablet\:padding-6 {
    padding: 3rem !important;
  }

  .tablet\:padding-y-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .tablet\:padding-x-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .tablet\:padding-top-6 {
    padding-top: 3rem !important;
  }

  .tablet\:padding-right-6 {
    padding-right: 3rem !important;
  }

  .tablet\:padding-bottom-6 {
    padding-bottom: 3rem !important;
  }

  .tablet\:padding-left-6 {
    padding-left: 3rem !important;
  }

  .tablet\:padding-7 {
    padding: 3.5rem !important;
  }

  .tablet\:padding-y-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .tablet\:padding-x-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .tablet\:padding-top-7 {
    padding-top: 3.5rem !important;
  }

  .tablet\:padding-right-7 {
    padding-right: 3.5rem !important;
  }

  .tablet\:padding-bottom-7 {
    padding-bottom: 3.5rem !important;
  }

  .tablet\:padding-left-7 {
    padding-left: 3.5rem !important;
  }

  .tablet\:padding-8 {
    padding: 4rem !important;
  }

  .tablet\:padding-y-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .tablet\:padding-x-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .tablet\:padding-top-8 {
    padding-top: 4rem !important;
  }

  .tablet\:padding-right-8 {
    padding-right: 4rem !important;
  }

  .tablet\:padding-bottom-8 {
    padding-bottom: 4rem !important;
  }

  .tablet\:padding-left-8 {
    padding-left: 4rem !important;
  }

  .tablet\:padding-9 {
    padding: 4.5rem !important;
  }

  .tablet\:padding-y-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .tablet\:padding-x-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .tablet\:padding-top-9 {
    padding-top: 4.5rem !important;
  }

  .tablet\:padding-right-9 {
    padding-right: 4.5rem !important;
  }

  .tablet\:padding-bottom-9 {
    padding-bottom: 4.5rem !important;
  }

  .tablet\:padding-left-9 {
    padding-left: 4.5rem !important;
  }

  .tablet\:padding-10 {
    padding: 5rem !important;
  }

  .tablet\:padding-y-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .tablet\:padding-x-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .tablet\:padding-top-10 {
    padding-top: 5rem !important;
  }

  .tablet\:padding-right-10 {
    padding-right: 5rem !important;
  }

  .tablet\:padding-bottom-10 {
    padding-bottom: 5rem !important;
  }

  .tablet\:padding-left-10 {
    padding-left: 5rem !important;
  }

  .tablet\:padding-15 {
    padding: 7.5rem !important;
  }

  .tablet\:padding-y-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .tablet\:padding-x-15 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .tablet\:padding-top-15 {
    padding-top: 7.5rem !important;
  }

  .tablet\:padding-right-15 {
    padding-right: 7.5rem !important;
  }

  .tablet\:padding-bottom-15 {
    padding-bottom: 7.5rem !important;
  }

  .tablet\:padding-left-15 {
    padding-left: 7.5rem !important;
  }

  .tablet\:padding-0 {
    padding: 0 !important;
  }

  .tablet\:padding-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .tablet\:padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .tablet\:padding-top-0 {
    padding-top: 0 !important;
  }

  .tablet\:padding-right-0 {
    padding-right: 0 !important;
  }

  .tablet\:padding-bottom-0 {
    padding-bottom: 0 !important;
  }

  .tablet\:padding-left-0 {
    padding-left: 0 !important;
  }

  .tablet\:width-1px {
    width: 1px !important;
  }

  .tablet\:width-2px {
    width: 2px !important;
  }

  .tablet\:width-05 {
    width: .25rem !important;
  }

  .tablet\:width-1 {
    width: .5rem !important;
  }

  .tablet\:width-105 {
    width: .75rem !important;
  }

  .tablet\:width-2 {
    width: 1rem !important;
  }

  .tablet\:width-205 {
    width: 1.25rem !important;
  }

  .tablet\:width-3 {
    width: 1.5rem !important;
  }

  .tablet\:width-4 {
    width: 2rem !important;
  }

  .tablet\:width-5 {
    width: 2.5rem !important;
  }

  .tablet\:width-6 {
    width: 3rem !important;
  }

  .tablet\:width-7 {
    width: 3.5rem !important;
  }

  .tablet\:width-8 {
    width: 4rem !important;
  }

  .tablet\:width-9 {
    width: 4.5rem !important;
  }

  .tablet\:width-10 {
    width: 5rem !important;
  }

  .tablet\:width-15 {
    width: 7.5rem !important;
  }

  .tablet\:width-card {
    width: 10rem !important;
  }

  .tablet\:width-card-lg {
    width: 15rem !important;
  }

  .tablet\:width-mobile {
    width: 20rem !important;
  }

  .tablet\:width-mobile-lg {
    width: 30rem !important;
  }

  .tablet\:width-tablet {
    width: 40rem !important;
  }

  .tablet\:width-tablet-lg {
    width: 55rem !important;
  }

  .tablet\:width-desktop {
    width: 64rem !important;
  }

  .tablet\:width-desktop-lg {
    width: 75rem !important;
  }

  .tablet\:width-widescreen {
    width: 87.5rem !important;
  }

  .tablet\:width-0 {
    width: 0 !important;
  }

  .tablet\:width-full {
    width: 100% !important;
  }

  .tablet\:width-auto {
    width: auto !important;
  }
}

@media (width >= 64em) {
  .desktop\:border-1px, .desktop\:hover\:border-1px:hover {
    border: 1px solid !important;
  }

  .desktop\:border-y-1px, .desktop\:hover\:border-y-1px:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .desktop\:border-x-1px, .desktop\:hover\:border-x-1px:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .desktop\:border-top-1px, .desktop\:hover\:border-top-1px:hover {
    border-top: 1px solid !important;
  }

  .desktop\:border-right-1px, .desktop\:hover\:border-right-1px:hover {
    border-right: 1px solid !important;
  }

  .desktop\:border-bottom-1px, .desktop\:hover\:border-bottom-1px:hover {
    border-bottom: 1px solid !important;
  }

  .desktop\:border-left-1px, .desktop\:hover\:border-left-1px:hover {
    border-left: 1px solid !important;
  }

  .desktop\:border-2px, .desktop\:hover\:border-2px:hover {
    border: 2px solid !important;
  }

  .desktop\:border-y-2px, .desktop\:hover\:border-y-2px:hover {
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }

  .desktop\:border-x-2px, .desktop\:hover\:border-x-2px:hover {
    border-left: 2px solid !important;
    border-right: 2px solid !important;
  }

  .desktop\:border-top-2px, .desktop\:hover\:border-top-2px:hover {
    border-top: 2px solid !important;
  }

  .desktop\:border-right-2px, .desktop\:hover\:border-right-2px:hover {
    border-right: 2px solid !important;
  }

  .desktop\:border-bottom-2px, .desktop\:hover\:border-bottom-2px:hover {
    border-bottom: 2px solid !important;
  }

  .desktop\:border-left-2px, .desktop\:hover\:border-left-2px:hover {
    border-left: 2px solid !important;
  }

  .desktop\:border-05, .desktop\:hover\:border-05:hover {
    border: .25rem solid !important;
  }

  .desktop\:border-y-05, .desktop\:hover\:border-y-05:hover {
    border-top: .25rem solid !important;
    border-bottom: .25rem solid !important;
  }

  .desktop\:border-x-05, .desktop\:hover\:border-x-05:hover {
    border-left: .25rem solid !important;
    border-right: .25rem solid !important;
  }

  .desktop\:border-top-05, .desktop\:hover\:border-top-05:hover {
    border-top: .25rem solid !important;
  }

  .desktop\:border-right-05, .desktop\:hover\:border-right-05:hover {
    border-right: .25rem solid !important;
  }

  .desktop\:border-bottom-05, .desktop\:hover\:border-bottom-05:hover {
    border-bottom: .25rem solid !important;
  }

  .desktop\:border-left-05, .desktop\:hover\:border-left-05:hover {
    border-left: .25rem solid !important;
  }

  .desktop\:border-1, .desktop\:hover\:border-1:hover {
    border: .5rem solid !important;
  }

  .desktop\:border-y-1, .desktop\:hover\:border-y-1:hover {
    border-top: .5rem solid !important;
    border-bottom: .5rem solid !important;
  }

  .desktop\:border-x-1, .desktop\:hover\:border-x-1:hover {
    border-left: .5rem solid !important;
    border-right: .5rem solid !important;
  }

  .desktop\:border-top-1, .desktop\:hover\:border-top-1:hover {
    border-top: .5rem solid !important;
  }

  .desktop\:border-right-1, .desktop\:hover\:border-right-1:hover {
    border-right: .5rem solid !important;
  }

  .desktop\:border-bottom-1, .desktop\:hover\:border-bottom-1:hover {
    border-bottom: .5rem solid !important;
  }

  .desktop\:border-left-1, .desktop\:hover\:border-left-1:hover {
    border-left: .5rem solid !important;
  }

  .desktop\:border-105, .desktop\:hover\:border-105:hover {
    border: .75rem solid !important;
  }

  .desktop\:border-y-105, .desktop\:hover\:border-y-105:hover {
    border-top: .75rem solid !important;
    border-bottom: .75rem solid !important;
  }

  .desktop\:border-x-105, .desktop\:hover\:border-x-105:hover {
    border-left: .75rem solid !important;
    border-right: .75rem solid !important;
  }

  .desktop\:border-top-105, .desktop\:hover\:border-top-105:hover {
    border-top: .75rem solid !important;
  }

  .desktop\:border-right-105, .desktop\:hover\:border-right-105:hover {
    border-right: .75rem solid !important;
  }

  .desktop\:border-bottom-105, .desktop\:hover\:border-bottom-105:hover {
    border-bottom: .75rem solid !important;
  }

  .desktop\:border-left-105, .desktop\:hover\:border-left-105:hover {
    border-left: .75rem solid !important;
  }

  .desktop\:border-2, .desktop\:hover\:border-2:hover {
    border: 1rem solid !important;
  }

  .desktop\:border-y-2, .desktop\:hover\:border-y-2:hover {
    border-top: 1rem solid !important;
    border-bottom: 1rem solid !important;
  }

  .desktop\:border-x-2, .desktop\:hover\:border-x-2:hover {
    border-left: 1rem solid !important;
    border-right: 1rem solid !important;
  }

  .desktop\:border-top-2, .desktop\:hover\:border-top-2:hover {
    border-top: 1rem solid !important;
  }

  .desktop\:border-right-2, .desktop\:hover\:border-right-2:hover {
    border-right: 1rem solid !important;
  }

  .desktop\:border-bottom-2, .desktop\:hover\:border-bottom-2:hover {
    border-bottom: 1rem solid !important;
  }

  .desktop\:border-left-2, .desktop\:hover\:border-left-2:hover {
    border-left: 1rem solid !important;
  }

  .desktop\:border-205, .desktop\:hover\:border-205:hover {
    border: 1.25rem solid !important;
  }

  .desktop\:border-y-205, .desktop\:hover\:border-y-205:hover {
    border-top: 1.25rem solid !important;
    border-bottom: 1.25rem solid !important;
  }

  .desktop\:border-x-205, .desktop\:hover\:border-x-205:hover {
    border-left: 1.25rem solid !important;
    border-right: 1.25rem solid !important;
  }

  .desktop\:border-top-205, .desktop\:hover\:border-top-205:hover {
    border-top: 1.25rem solid !important;
  }

  .desktop\:border-right-205, .desktop\:hover\:border-right-205:hover {
    border-right: 1.25rem solid !important;
  }

  .desktop\:border-bottom-205, .desktop\:hover\:border-bottom-205:hover {
    border-bottom: 1.25rem solid !important;
  }

  .desktop\:border-left-205, .desktop\:hover\:border-left-205:hover {
    border-left: 1.25rem solid !important;
  }

  .desktop\:border-3, .desktop\:hover\:border-3:hover {
    border: 1.5rem solid !important;
  }

  .desktop\:border-y-3, .desktop\:hover\:border-y-3:hover {
    border-top: 1.5rem solid !important;
    border-bottom: 1.5rem solid !important;
  }

  .desktop\:border-x-3, .desktop\:hover\:border-x-3:hover {
    border-left: 1.5rem solid !important;
    border-right: 1.5rem solid !important;
  }

  .desktop\:border-top-3, .desktop\:hover\:border-top-3:hover {
    border-top: 1.5rem solid !important;
  }

  .desktop\:border-right-3, .desktop\:hover\:border-right-3:hover {
    border-right: 1.5rem solid !important;
  }

  .desktop\:border-bottom-3, .desktop\:hover\:border-bottom-3:hover {
    border-bottom: 1.5rem solid !important;
  }

  .desktop\:border-left-3, .desktop\:hover\:border-left-3:hover {
    border-left: 1.5rem solid !important;
  }

  .desktop\:border-0, .desktop\:hover\:border-0:hover {
    border: 0 solid !important;
  }

  .desktop\:border-y-0, .desktop\:hover\:border-y-0:hover {
    border-top: 0 solid !important;
    border-bottom: 0 solid !important;
  }

  .desktop\:border-x-0, .desktop\:hover\:border-x-0:hover {
    border-left: 0 solid !important;
    border-right: 0 solid !important;
  }

  .desktop\:border-top-0, .desktop\:hover\:border-top-0:hover {
    border-top: 0 solid !important;
  }

  .desktop\:border-right-0, .desktop\:hover\:border-right-0:hover {
    border-right: 0 solid !important;
  }

  .desktop\:border-bottom-0, .desktop\:hover\:border-bottom-0:hover {
    border-bottom: 0 solid !important;
  }

  .desktop\:border-left-0, .desktop\:hover\:border-left-0:hover {
    border-left: 0 solid !important;
  }

  .desktop\:border, .desktop\:hover\:border:hover {
    border: 1px solid !important;
  }

  .desktop\:border-y, .desktop\:hover\:border-y:hover {
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
  }

  .desktop\:border-x, .desktop\:hover\:border-x:hover {
    border-left: 1px solid !important;
    border-right: 1px solid !important;
  }

  .desktop\:border-top, .desktop\:hover\:border-top:hover {
    border-top: 1px solid !important;
  }

  .desktop\:border-right, .desktop\:hover\:border-right:hover {
    border-right: 1px solid !important;
  }

  .desktop\:border-bottom, .desktop\:hover\:border-bottom:hover {
    border-bottom: 1px solid !important;
  }

  .desktop\:border-left, .desktop\:hover\:border-left:hover {
    border-left: 1px solid !important;
  }

  .desktop\:border-transparent, .desktop\:hover\:border-transparent:hover {
    border-color: #0000 !important;
  }

  .desktop\:border-black, .desktop\:hover\:border-black:hover {
    border-color: #000 !important;
  }

  .desktop\:border-white, .desktop\:hover\:border-white:hover {
    border-color: #fff !important;
  }

  .desktop\:border-red, .desktop\:hover\:border-red:hover {
    border-color: #e52207 !important;
  }

  .desktop\:border-orange, .desktop\:hover\:border-orange:hover {
    border-color: #e66f0e !important;
  }

  .desktop\:border-gold, .desktop\:hover\:border-gold:hover {
    border-color: #ffbe2e !important;
  }

  .desktop\:border-yellow, .desktop\:hover\:border-yellow:hover {
    border-color: #fee685 !important;
  }

  .desktop\:border-green, .desktop\:hover\:border-green:hover {
    border-color: #538200 !important;
  }

  .desktop\:border-mint, .desktop\:hover\:border-mint:hover {
    border-color: #04c585 !important;
  }

  .desktop\:border-cyan, .desktop\:hover\:border-cyan:hover {
    border-color: #009ec1 !important;
  }

  .desktop\:border-blue, .desktop\:hover\:border-blue:hover {
    border-color: #0076d6 !important;
  }

  .desktop\:border-indigo, .desktop\:hover\:border-indigo:hover {
    border-color: #676cc8 !important;
  }

  .desktop\:border-violet, .desktop\:hover\:border-violet:hover {
    border-color: #8168b3 !important;
  }

  .desktop\:border-magenta, .desktop\:hover\:border-magenta:hover {
    border-color: #d72d79 !important;
  }

  .desktop\:border-gray-5, .desktop\:hover\:border-gray-5:hover {
    border-color: #f0f0f0 !important;
  }

  .desktop\:border-gray-10, .desktop\:hover\:border-gray-10:hover {
    border-color: #e6e6e6 !important;
  }

  .desktop\:border-gray-30, .desktop\:hover\:border-gray-30:hover {
    border-color: #adadad !important;
  }

  .desktop\:border-gray-50, .desktop\:hover\:border-gray-50:hover {
    border-color: #757575 !important;
  }

  .desktop\:border-gray-70, .desktop\:hover\:border-gray-70:hover {
    border-color: #454545 !important;
  }

  .desktop\:border-gray-90, .desktop\:hover\:border-gray-90:hover {
    border-color: #1b1b1b !important;
  }

  .desktop\:border-base-lightest, .desktop\:hover\:border-base-lightest:hover {
    border-color: #f0f0f0 !important;
  }

  .desktop\:border-base-lighter, .desktop\:hover\:border-base-lighter:hover {
    border-color: #dfe1e2 !important;
  }

  .desktop\:border-base-light, .desktop\:hover\:border-base-light:hover {
    border-color: #a9aeb1 !important;
  }

  .desktop\:border-base, .desktop\:hover\:border-base:hover {
    border-color: #71767a !important;
  }

  .desktop\:border-base-dark, .desktop\:hover\:border-base-dark:hover {
    border-color: #565c65 !important;
  }

  .desktop\:border-base-darker, .desktop\:hover\:border-base-darker:hover {
    border-color: #3d4551 !important;
  }

  .desktop\:border-base-darkest, .desktop\:hover\:border-base-darkest:hover {
    border-color: #2d2e2f !important;
  }

  .desktop\:border-ink, .desktop\:hover\:border-ink:hover {
    border-color: #1c1d1f !important;
  }

  .desktop\:border-primary-lighter, .desktop\:hover\:border-primary-lighter:hover {
    border-color: #d9e8f6 !important;
  }

  .desktop\:border-primary-light, .desktop\:hover\:border-primary-light:hover {
    border-color: #73b3e7 !important;
  }

  .desktop\:border-primary, .desktop\:hover\:border-primary:hover {
    border-color: #005ea2 !important;
  }

  .desktop\:border-primary-vivid, .desktop\:hover\:border-primary-vivid:hover {
    border-color: #0050d8 !important;
  }

  .desktop\:border-primary-dark, .desktop\:hover\:border-primary-dark:hover {
    border-color: #1a4480 !important;
  }

  .desktop\:border-primary-darker, .desktop\:hover\:border-primary-darker:hover {
    border-color: #162e51 !important;
  }

  .desktop\:border-secondary-lighter, .desktop\:hover\:border-secondary-lighter:hover {
    border-color: #f3e1e4 !important;
  }

  .desktop\:border-secondary-light, .desktop\:hover\:border-secondary-light:hover {
    border-color: #f2938c !important;
  }

  .desktop\:border-secondary, .desktop\:hover\:border-secondary:hover {
    border-color: #d83933 !important;
  }

  .desktop\:border-secondary-vivid, .desktop\:hover\:border-secondary-vivid:hover {
    border-color: #e41d3d !important;
  }

  .desktop\:border-secondary-dark, .desktop\:hover\:border-secondary-dark:hover {
    border-color: #b50909 !important;
  }

  .desktop\:border-secondary-darker, .desktop\:hover\:border-secondary-darker:hover {
    border-color: #8b0a03 !important;
  }

  .desktop\:border-accent-warm-darker, .desktop\:hover\:border-accent-warm-darker:hover {
    border-color: #775540 !important;
  }

  .desktop\:border-accent-warm-dark, .desktop\:hover\:border-accent-warm-dark:hover {
    border-color: #c05600 !important;
  }

  .desktop\:border-accent-warm, .desktop\:hover\:border-accent-warm:hover {
    border-color: #fa9441 !important;
  }

  .desktop\:border-accent-warm-light, .desktop\:hover\:border-accent-warm-light:hover {
    border-color: #ffbc78 !important;
  }

  .desktop\:border-accent-warm-lighter, .desktop\:hover\:border-accent-warm-lighter:hover {
    border-color: #f2e4d4 !important;
  }

  .desktop\:border-accent-cool-darker, .desktop\:hover\:border-accent-cool-darker:hover {
    border-color: #07648d !important;
  }

  .desktop\:border-accent-cool-dark, .desktop\:hover\:border-accent-cool-dark:hover {
    border-color: #28a0cb !important;
  }

  .desktop\:border-accent-cool, .desktop\:hover\:border-accent-cool:hover {
    border-color: #00bde3 !important;
  }

  .desktop\:border-accent-cool-light, .desktop\:hover\:border-accent-cool-light:hover {
    border-color: #97d4ea !important;
  }

  .desktop\:border-accent-cool-lighter, .desktop\:hover\:border-accent-cool-lighter:hover {
    border-color: #e1f3f8 !important;
  }

  .desktop\:border-error-lighter, .desktop\:hover\:border-error-lighter:hover {
    border-color: #f4e3db !important;
  }

  .desktop\:border-error-light, .desktop\:hover\:border-error-light:hover {
    border-color: #f39268 !important;
  }

  .desktop\:border-error, .desktop\:hover\:border-error:hover {
    border-color: #d54309 !important;
  }

  .desktop\:border-error-dark, .desktop\:hover\:border-error-dark:hover {
    border-color: #b50909 !important;
  }

  .desktop\:border-error-darker, .desktop\:hover\:border-error-darker:hover {
    border-color: #6f3331 !important;
  }

  .desktop\:border-warning-lighter, .desktop\:hover\:border-warning-lighter:hover {
    border-color: #faf3d1 !important;
  }

  .desktop\:border-warning-light, .desktop\:hover\:border-warning-light:hover {
    border-color: #fee685 !important;
  }

  .desktop\:border-warning, .desktop\:hover\:border-warning:hover {
    border-color: #ffbe2e !important;
  }

  .desktop\:border-warning-dark, .desktop\:hover\:border-warning-dark:hover {
    border-color: #e5a000 !important;
  }

  .desktop\:border-warning-darker, .desktop\:hover\:border-warning-darker:hover {
    border-color: #936f38 !important;
  }

  .desktop\:border-success-lighter, .desktop\:hover\:border-success-lighter:hover {
    border-color: #ecf3ec !important;
  }

  .desktop\:border-success-light, .desktop\:hover\:border-success-light:hover {
    border-color: #70e17b !important;
  }

  .desktop\:border-success, .desktop\:hover\:border-success:hover {
    border-color: #00a91c !important;
  }

  .desktop\:border-success-dark, .desktop\:hover\:border-success-dark:hover {
    border-color: #008817 !important;
  }

  .desktop\:border-success-darker, .desktop\:hover\:border-success-darker:hover {
    border-color: #216e1f !important;
  }

  .desktop\:border-info-lighter, .desktop\:hover\:border-info-lighter:hover {
    border-color: #e7f6f8 !important;
  }

  .desktop\:border-info-light, .desktop\:hover\:border-info-light:hover {
    border-color: #99deea !important;
  }

  .desktop\:border-info, .desktop\:hover\:border-info:hover {
    border-color: #00bde3 !important;
  }

  .desktop\:border-info-dark, .desktop\:hover\:border-info-dark:hover {
    border-color: #009ec1 !important;
  }

  .desktop\:border-info-darker, .desktop\:hover\:border-info-darker:hover {
    border-color: #2e6276 !important;
  }

  .desktop\:border-disabled-lighter, .desktop\:hover\:border-disabled-lighter:hover {
    border-color: #c9c9c9 !important;
  }

  .desktop\:border-disabled-light, .desktop\:hover\:border-disabled-light:hover {
    border-color: #919191 !important;
  }

  .desktop\:border-disabled, .desktop\:hover\:border-disabled:hover {
    border-color: #757575 !important;
  }

  .desktop\:border-disabled-dark, .desktop\:hover\:border-disabled-dark:hover {
    border-color: #454545 !important;
  }

  .desktop\:border-disabled-darker, .desktop\:hover\:border-disabled-darker:hover {
    border-color: #1b1b1b !important;
  }

  .desktop\:border-emergency, .desktop\:hover\:border-emergency:hover {
    border-color: #9c3d10 !important;
  }

  .desktop\:border-emergency-dark, .desktop\:hover\:border-emergency-dark:hover {
    border-color: #332d29 !important;
  }

  .desktop\:radius-0 {
    border-radius: 0 !important;
  }

  .desktop\:radius-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .desktop\:radius-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .desktop\:radius-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .desktop\:radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .desktop\:radius-sm {
    border-radius: 2px !important;
  }

  .desktop\:radius-top-sm {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
  }

  .desktop\:radius-right-sm {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  .desktop\:radius-bottom-sm {
    border-bottom-right-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .desktop\:radius-left-sm {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .desktop\:radius-md {
    border-radius: .25rem !important;
  }

  .desktop\:radius-top-md {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .desktop\:radius-right-md {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .desktop\:radius-bottom-md {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .desktop\:radius-left-md {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .desktop\:radius-lg {
    border-radius: .5rem !important;
  }

  .desktop\:radius-top-lg {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .desktop\:radius-right-lg {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .desktop\:radius-bottom-lg {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .desktop\:radius-left-lg {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .desktop\:radius-pill {
    border-radius: 99rem !important;
  }

  .desktop\:radius-top-pill {
    border-top-left-radius: 99rem !important;
    border-top-right-radius: 99rem !important;
  }

  .desktop\:radius-right-pill {
    border-top-right-radius: 99rem !important;
    border-bottom-right-radius: 99rem !important;
  }

  .desktop\:radius-bottom-pill {
    border-bottom-right-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  .desktop\:radius-left-pill {
    border-top-left-radius: 99rem !important;
    border-bottom-left-radius: 99rem !important;
  }

  .desktop\:display-block {
    display: block !important;
  }

  .desktop\:display-flex {
    display: flex !important;
  }

  .desktop\:display-none {
    display: none !important;
  }

  .desktop\:display-inline {
    display: inline !important;
  }

  .desktop\:display-inline-block {
    display: inline-block !important;
  }

  .desktop\:display-inline-flex {
    display: inline-flex !important;
  }

  .desktop\:display-table {
    display: table !important;
  }

  .desktop\:display-table-cell {
    display: table-cell !important;
  }

  .desktop\:display-table-row {
    display: table-row !important;
  }

  .desktop\:font-mono-3xs {
    font-size: .77rem !important;
  }

  .desktop\:font-mono-2xs {
    font-size: .83rem !important;
  }

  .desktop\:font-mono-xs {
    font-size: .89rem !important;
  }

  .desktop\:font-mono-sm {
    font-size: .95rem !important;
  }

  .desktop\:font-mono-md {
    font-size: 1.19rem !important;
  }

  .desktop\:font-mono-lg {
    font-size: 1.31rem !important;
  }

  .desktop\:font-mono-xl {
    font-size: 1.91rem !important;
  }

  .desktop\:font-mono-2xl {
    font-size: 2.38rem !important;
  }

  .desktop\:font-mono-3xl {
    font-size: 2.86rem !important;
  }

  .desktop\:font-sans-3xs {
    font-size: .81rem !important;
  }

  .desktop\:font-sans-2xs {
    font-size: .87rem !important;
  }

  .desktop\:font-sans-xs {
    font-size: .93rem !important;
  }

  .desktop\:font-sans-sm {
    font-size: .99rem !important;
  }

  .desktop\:font-sans-md {
    font-size: 1.24rem !important;
  }

  .desktop\:font-sans-lg {
    font-size: 1.37rem !important;
  }

  .desktop\:font-sans-xl {
    font-size: 1.99rem !important;
  }

  .desktop\:font-sans-2xl {
    font-size: 2.49rem !important;
  }

  .desktop\:font-sans-3xl {
    font-size: 2.98rem !important;
  }

  .desktop\:font-serif-3xs {
    font-size: .81rem !important;
  }

  .desktop\:font-serif-2xs {
    font-size: .87rem !important;
  }

  .desktop\:font-serif-xs {
    font-size: .93rem !important;
  }

  .desktop\:font-serif-sm {
    font-size: .99rem !important;
  }

  .desktop\:font-serif-md {
    font-size: 1.24rem !important;
  }

  .desktop\:font-serif-lg {
    font-size: 1.37rem !important;
  }

  .desktop\:font-serif-xl {
    font-size: 1.99rem !important;
  }

  .desktop\:font-serif-2xl {
    font-size: 2.49rem !important;
  }

  .desktop\:font-serif-3xl {
    font-size: 2.98rem !important;
  }

  .desktop\:font-heading-3xs {
    font-size: .81rem !important;
  }

  .desktop\:font-heading-2xs {
    font-size: .87rem !important;
  }

  .desktop\:font-heading-xs {
    font-size: .93rem !important;
  }

  .desktop\:font-heading-sm {
    font-size: .99rem !important;
  }

  .desktop\:font-heading-md {
    font-size: 1.24rem !important;
  }

  .desktop\:font-heading-lg {
    font-size: 1.37rem !important;
  }

  .desktop\:font-heading-xl {
    font-size: 1.99rem !important;
  }

  .desktop\:font-heading-2xl {
    font-size: 2.49rem !important;
  }

  .desktop\:font-heading-3xl {
    font-size: 2.98rem !important;
  }

  .desktop\:font-body-3xs {
    font-size: .81rem !important;
  }

  .desktop\:font-body-2xs {
    font-size: .87rem !important;
  }

  .desktop\:font-body-xs {
    font-size: .93rem !important;
  }

  .desktop\:font-body-sm {
    font-size: .99rem !important;
  }

  .desktop\:font-body-md {
    font-size: 1.24rem !important;
  }

  .desktop\:font-body-lg {
    font-size: 1.37rem !important;
  }

  .desktop\:font-body-xl {
    font-size: 1.99rem !important;
  }

  .desktop\:font-body-2xl {
    font-size: 2.49rem !important;
  }

  .desktop\:font-body-3xl {
    font-size: 2.98rem !important;
  }

  .desktop\:font-code-3xs {
    font-size: .77rem !important;
  }

  .desktop\:font-code-2xs {
    font-size: .83rem !important;
  }

  .desktop\:font-code-xs {
    font-size: .89rem !important;
  }

  .desktop\:font-code-sm {
    font-size: .95rem !important;
  }

  .desktop\:font-code-md {
    font-size: 1.19rem !important;
  }

  .desktop\:font-code-lg {
    font-size: 1.31rem !important;
  }

  .desktop\:font-code-xl {
    font-size: 1.91rem !important;
  }

  .desktop\:font-code-2xl {
    font-size: 2.38rem !important;
  }

  .desktop\:font-code-3xl {
    font-size: 2.86rem !important;
  }

  .desktop\:font-alt-3xs {
    font-size: .81rem !important;
  }

  .desktop\:font-alt-2xs {
    font-size: .87rem !important;
  }

  .desktop\:font-alt-xs {
    font-size: .93rem !important;
  }

  .desktop\:font-alt-sm {
    font-size: .99rem !important;
  }

  .desktop\:font-alt-md {
    font-size: 1.24rem !important;
  }

  .desktop\:font-alt-lg {
    font-size: 1.37rem !important;
  }

  .desktop\:font-alt-xl {
    font-size: 1.99rem !important;
  }

  .desktop\:font-alt-2xl {
    font-size: 2.49rem !important;
  }

  .desktop\:font-alt-3xl {
    font-size: 2.98rem !important;
  }

  .desktop\:font-ui-3xs {
    font-size: .81rem !important;
  }

  .desktop\:font-ui-2xs {
    font-size: .87rem !important;
  }

  .desktop\:font-ui-xs {
    font-size: .93rem !important;
  }

  .desktop\:font-ui-sm {
    font-size: .99rem !important;
  }

  .desktop\:font-ui-md {
    font-size: 1.24rem !important;
  }

  .desktop\:font-ui-lg {
    font-size: 1.37rem !important;
  }

  .desktop\:font-ui-xl {
    font-size: 1.99rem !important;
  }

  .desktop\:font-ui-2xl {
    font-size: 2.49rem !important;
  }

  .desktop\:font-ui-3xl {
    font-size: 2.98rem !important;
  }

  .desktop\:text-light {
    font-weight: 300 !important;
  }

  .desktop\:text-normal {
    font-weight: normal !important;
  }

  .desktop\:text-semibold {
    font-weight: 600 !important;
  }

  .desktop\:text-bold {
    font-weight: 700 !important;
  }

  .desktop\:flex-justify-center {
    justify-content: center !important;
  }

  .desktop\:flex-justify-start {
    justify-content: flex-start !important;
  }

  .desktop\:flex-justify-end {
    justify-content: flex-end !important;
  }

  .desktop\:flex-justify {
    justify-content: space-between !important;
  }

  .desktop\:line-height-sans-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-sans-2 {
    line-height: 1.2 !important;
  }

  .desktop\:line-height-sans-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-sans-4 {
    line-height: 1.5 !important;
  }

  .desktop\:line-height-sans-5 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-sans-6 {
    line-height: 1.8 !important;
  }

  .desktop\:line-height-serif-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-serif-2 {
    line-height: 1.2 !important;
  }

  .desktop\:line-height-serif-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-serif-4 {
    line-height: 1.5 !important;
  }

  .desktop\:line-height-serif-5 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-serif-6 {
    line-height: 1.8 !important;
  }

  .desktop\:line-height-mono-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-mono-2 {
    line-height: 1.3 !important;
  }

  .desktop\:line-height-mono-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-mono-4 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-mono-5 {
    line-height: 1.7 !important;
  }

  .desktop\:line-height-mono-6 {
    line-height: 1.8 !important;
  }

  .desktop\:line-height-heading-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-heading-2 {
    line-height: 1.2 !important;
  }

  .desktop\:line-height-heading-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-heading-4 {
    line-height: 1.5 !important;
  }

  .desktop\:line-height-heading-5 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-heading-6 {
    line-height: 1.8 !important;
  }

  .desktop\:line-height-ui-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-ui-2 {
    line-height: 1.2 !important;
  }

  .desktop\:line-height-ui-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-ui-4 {
    line-height: 1.5 !important;
  }

  .desktop\:line-height-ui-5 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-ui-6 {
    line-height: 1.8 !important;
  }

  .desktop\:line-height-body-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-body-2 {
    line-height: 1.2 !important;
  }

  .desktop\:line-height-body-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-body-4 {
    line-height: 1.5 !important;
  }

  .desktop\:line-height-body-5 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-body-6 {
    line-height: 1.8 !important;
  }

  .desktop\:line-height-code-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-code-2 {
    line-height: 1.3 !important;
  }

  .desktop\:line-height-code-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-code-4 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-code-5 {
    line-height: 1.7 !important;
  }

  .desktop\:line-height-code-6 {
    line-height: 1.8 !important;
  }

  .desktop\:line-height-alt-1 {
    line-height: 1 !important;
  }

  .desktop\:line-height-alt-2 {
    line-height: 1.2 !important;
  }

  .desktop\:line-height-alt-3 {
    line-height: 1.4 !important;
  }

  .desktop\:line-height-alt-4 {
    line-height: 1.5 !important;
  }

  .desktop\:line-height-alt-5 {
    line-height: 1.6 !important;
  }

  .desktop\:line-height-alt-6 {
    line-height: 1.8 !important;
  }

  .desktop\:margin-neg-1px {
    margin: -1px !important;
  }

  .desktop\:margin-neg-2px {
    margin: -2px !important;
  }

  .desktop\:margin-neg-05 {
    margin: -.25rem !important;
  }

  .desktop\:margin-neg-1 {
    margin: -.5rem !important;
  }

  .desktop\:margin-neg-105 {
    margin: -.75rem !important;
  }

  .desktop\:margin-neg-2 {
    margin: -1rem !important;
  }

  .desktop\:margin-neg-205 {
    margin: -1.25rem !important;
  }

  .desktop\:margin-neg-3 {
    margin: -1.5rem !important;
  }

  .desktop\:margin-1px {
    margin: 1px !important;
  }

  .desktop\:margin-2px {
    margin: 2px !important;
  }

  .desktop\:margin-05 {
    margin: .25rem !important;
  }

  .desktop\:margin-1 {
    margin: .5rem !important;
  }

  .desktop\:margin-105 {
    margin: .75rem !important;
  }

  .desktop\:margin-2 {
    margin: 1rem !important;
  }

  .desktop\:margin-205 {
    margin: 1.25rem !important;
  }

  .desktop\:margin-3 {
    margin: 1.5rem !important;
  }

  .desktop\:margin-neg-4 {
    margin: -2rem !important;
  }

  .desktop\:margin-neg-5 {
    margin: -2.5rem !important;
  }

  .desktop\:margin-neg-6 {
    margin: -3rem !important;
  }

  .desktop\:margin-neg-7 {
    margin: -3.5rem !important;
  }

  .desktop\:margin-neg-8 {
    margin: -4rem !important;
  }

  .desktop\:margin-neg-9 {
    margin: -4.5rem !important;
  }

  .desktop\:margin-neg-10 {
    margin: -5rem !important;
  }

  .desktop\:margin-neg-15 {
    margin: -7.5rem !important;
  }

  .desktop\:margin-4 {
    margin: 2rem !important;
  }

  .desktop\:margin-5 {
    margin: 2.5rem !important;
  }

  .desktop\:margin-6 {
    margin: 3rem !important;
  }

  .desktop\:margin-7 {
    margin: 3.5rem !important;
  }

  .desktop\:margin-8 {
    margin: 4rem !important;
  }

  .desktop\:margin-9 {
    margin: 4.5rem !important;
  }

  .desktop\:margin-10 {
    margin: 5rem !important;
  }

  .desktop\:margin-15 {
    margin: 7.5rem !important;
  }

  .desktop\:margin-05em {
    margin: .5em !important;
  }

  .desktop\:margin-1em {
    margin: 1em !important;
  }

  .desktop\:margin-105em {
    margin: 1.5em !important;
  }

  .desktop\:margin-2em {
    margin: 2em !important;
  }

  .desktop\:margin-0 {
    margin: 0 !important;
  }

  .desktop\:margin-y-1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .desktop\:margin-top-1px {
    margin-top: 1px !important;
  }

  .desktop\:margin-bottom-1px {
    margin-bottom: 1px !important;
  }

  .desktop\:margin-y-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .desktop\:margin-top-2px {
    margin-top: 2px !important;
  }

  .desktop\:margin-bottom-2px {
    margin-bottom: 2px !important;
  }

  .desktop\:margin-y-05 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .desktop\:margin-top-05 {
    margin-top: .25rem !important;
  }

  .desktop\:margin-bottom-05 {
    margin-bottom: .25rem !important;
  }

  .desktop\:margin-y-1 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .desktop\:margin-top-1 {
    margin-top: .5rem !important;
  }

  .desktop\:margin-bottom-1 {
    margin-bottom: .5rem !important;
  }

  .desktop\:margin-y-105 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .desktop\:margin-top-105 {
    margin-top: .75rem !important;
  }

  .desktop\:margin-bottom-105 {
    margin-bottom: .75rem !important;
  }

  .desktop\:margin-y-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .desktop\:margin-top-2 {
    margin-top: 1rem !important;
  }

  .desktop\:margin-bottom-2 {
    margin-bottom: 1rem !important;
  }

  .desktop\:margin-y-205 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .desktop\:margin-top-205 {
    margin-top: 1.25rem !important;
  }

  .desktop\:margin-bottom-205 {
    margin-bottom: 1.25rem !important;
  }

  .desktop\:margin-y-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .desktop\:margin-top-3 {
    margin-top: 1.5rem !important;
  }

  .desktop\:margin-bottom-3 {
    margin-bottom: 1.5rem !important;
  }

  .desktop\:margin-y-neg-1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .desktop\:margin-top-neg-1px {
    margin-top: -1px !important;
  }

  .desktop\:margin-bottom-neg-1px {
    margin-bottom: -1px !important;
  }

  .desktop\:margin-y-neg-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .desktop\:margin-top-neg-2px {
    margin-top: -2px !important;
  }

  .desktop\:margin-bottom-neg-2px {
    margin-bottom: -2px !important;
  }

  .desktop\:margin-y-neg-05 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .desktop\:margin-top-neg-05 {
    margin-top: -.25rem !important;
  }

  .desktop\:margin-bottom-neg-05 {
    margin-bottom: -.25rem !important;
  }

  .desktop\:margin-y-neg-1 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .desktop\:margin-top-neg-1 {
    margin-top: -.5rem !important;
  }

  .desktop\:margin-bottom-neg-1 {
    margin-bottom: -.5rem !important;
  }

  .desktop\:margin-y-neg-105 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .desktop\:margin-top-neg-105 {
    margin-top: -.75rem !important;
  }

  .desktop\:margin-bottom-neg-105 {
    margin-bottom: -.75rem !important;
  }

  .desktop\:margin-y-neg-2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .desktop\:margin-top-neg-2 {
    margin-top: -1rem !important;
  }

  .desktop\:margin-bottom-neg-2 {
    margin-bottom: -1rem !important;
  }

  .desktop\:margin-y-neg-205 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .desktop\:margin-top-neg-205 {
    margin-top: -1.25rem !important;
  }

  .desktop\:margin-bottom-neg-205 {
    margin-bottom: -1.25rem !important;
  }

  .desktop\:margin-y-neg-3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .desktop\:margin-top-neg-3 {
    margin-top: -1.5rem !important;
  }

  .desktop\:margin-bottom-neg-3 {
    margin-bottom: -1.5rem !important;
  }

  .desktop\:margin-y-neg-4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .desktop\:margin-top-neg-4 {
    margin-top: -2rem !important;
  }

  .desktop\:margin-bottom-neg-4 {
    margin-bottom: -2rem !important;
  }

  .desktop\:margin-y-neg-5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .desktop\:margin-top-neg-5 {
    margin-top: -2.5rem !important;
  }

  .desktop\:margin-bottom-neg-5 {
    margin-bottom: -2.5rem !important;
  }

  .desktop\:margin-y-neg-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .desktop\:margin-top-neg-6 {
    margin-top: -3rem !important;
  }

  .desktop\:margin-bottom-neg-6 {
    margin-bottom: -3rem !important;
  }

  .desktop\:margin-y-neg-7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .desktop\:margin-top-neg-7 {
    margin-top: -3.5rem !important;
  }

  .desktop\:margin-bottom-neg-7 {
    margin-bottom: -3.5rem !important;
  }

  .desktop\:margin-y-neg-8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .desktop\:margin-top-neg-8 {
    margin-top: -4rem !important;
  }

  .desktop\:margin-bottom-neg-8 {
    margin-bottom: -4rem !important;
  }

  .desktop\:margin-y-neg-9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .desktop\:margin-top-neg-9 {
    margin-top: -4.5rem !important;
  }

  .desktop\:margin-bottom-neg-9 {
    margin-bottom: -4.5rem !important;
  }

  .desktop\:margin-y-neg-10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .desktop\:margin-top-neg-10 {
    margin-top: -5rem !important;
  }

  .desktop\:margin-bottom-neg-10 {
    margin-bottom: -5rem !important;
  }

  .desktop\:margin-y-neg-15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .desktop\:margin-top-neg-15 {
    margin-top: -7.5rem !important;
  }

  .desktop\:margin-bottom-neg-15 {
    margin-bottom: -7.5rem !important;
  }

  .desktop\:margin-y-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .desktop\:margin-top-4 {
    margin-top: 2rem !important;
  }

  .desktop\:margin-bottom-4 {
    margin-bottom: 2rem !important;
  }

  .desktop\:margin-y-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .desktop\:margin-top-5 {
    margin-top: 2.5rem !important;
  }

  .desktop\:margin-bottom-5 {
    margin-bottom: 2.5rem !important;
  }

  .desktop\:margin-y-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .desktop\:margin-top-6 {
    margin-top: 3rem !important;
  }

  .desktop\:margin-bottom-6 {
    margin-bottom: 3rem !important;
  }

  .desktop\:margin-y-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .desktop\:margin-top-7 {
    margin-top: 3.5rem !important;
  }

  .desktop\:margin-bottom-7 {
    margin-bottom: 3.5rem !important;
  }

  .desktop\:margin-y-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .desktop\:margin-top-8 {
    margin-top: 4rem !important;
  }

  .desktop\:margin-bottom-8 {
    margin-bottom: 4rem !important;
  }

  .desktop\:margin-y-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .desktop\:margin-top-9 {
    margin-top: 4.5rem !important;
  }

  .desktop\:margin-bottom-9 {
    margin-bottom: 4.5rem !important;
  }

  .desktop\:margin-y-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .desktop\:margin-top-10 {
    margin-top: 5rem !important;
  }

  .desktop\:margin-bottom-10 {
    margin-bottom: 5rem !important;
  }

  .desktop\:margin-y-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .desktop\:margin-top-15 {
    margin-top: 7.5rem !important;
  }

  .desktop\:margin-bottom-15 {
    margin-bottom: 7.5rem !important;
  }

  .desktop\:margin-y-05em {
    margin-top: .5em !important;
    margin-bottom: .5em !important;
  }

  .desktop\:margin-top-05em {
    margin-top: .5em !important;
  }

  .desktop\:margin-bottom-05em {
    margin-bottom: .5em !important;
  }

  .desktop\:margin-y-1em {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  .desktop\:margin-top-1em {
    margin-top: 1em !important;
  }

  .desktop\:margin-bottom-1em {
    margin-bottom: 1em !important;
  }

  .desktop\:margin-y-105em {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }

  .desktop\:margin-top-105em {
    margin-top: 1.5em !important;
  }

  .desktop\:margin-bottom-105em {
    margin-bottom: 1.5em !important;
  }

  .desktop\:margin-y-2em {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }

  .desktop\:margin-top-2em {
    margin-top: 2em !important;
  }

  .desktop\:margin-bottom-2em {
    margin-bottom: 2em !important;
  }

  .desktop\:margin-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .desktop\:margin-top-0 {
    margin-top: 0 !important;
  }

  .desktop\:margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  .desktop\:margin-y-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .desktop\:margin-top-auto {
    margin-top: auto !important;
  }

  .desktop\:margin-bottom-auto {
    margin-bottom: auto !important;
  }

  .desktop\:margin-x-1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .desktop\:margin-right-1px {
    margin-right: 1px !important;
  }

  .desktop\:margin-left-1px {
    margin-left: 1px !important;
  }

  .desktop\:margin-x-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .desktop\:margin-right-2px {
    margin-right: 2px !important;
  }

  .desktop\:margin-left-2px {
    margin-left: 2px !important;
  }

  .desktop\:margin-x-05 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .desktop\:margin-right-05 {
    margin-right: .25rem !important;
  }

  .desktop\:margin-left-05 {
    margin-left: .25rem !important;
  }

  .desktop\:margin-x-1 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .desktop\:margin-right-1 {
    margin-right: .5rem !important;
  }

  .desktop\:margin-left-1 {
    margin-left: .5rem !important;
  }

  .desktop\:margin-x-105 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .desktop\:margin-right-105 {
    margin-right: .75rem !important;
  }

  .desktop\:margin-left-105 {
    margin-left: .75rem !important;
  }

  .desktop\:margin-x-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .desktop\:margin-right-2 {
    margin-right: 1rem !important;
  }

  .desktop\:margin-left-2 {
    margin-left: 1rem !important;
  }

  .desktop\:margin-x-205 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .desktop\:margin-right-205 {
    margin-right: 1.25rem !important;
  }

  .desktop\:margin-left-205 {
    margin-left: 1.25rem !important;
  }

  .desktop\:margin-x-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .desktop\:margin-right-3 {
    margin-right: 1.5rem !important;
  }

  .desktop\:margin-left-3 {
    margin-left: 1.5rem !important;
  }

  .desktop\:margin-x-neg-1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .desktop\:margin-right-neg-1px {
    margin-right: -1px !important;
  }

  .desktop\:margin-left-neg-1px {
    margin-left: -1px !important;
  }

  .desktop\:margin-x-neg-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .desktop\:margin-right-neg-2px {
    margin-right: -2px !important;
  }

  .desktop\:margin-left-neg-2px {
    margin-left: -2px !important;
  }

  .desktop\:margin-x-neg-05 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .desktop\:margin-right-neg-05 {
    margin-right: -.25rem !important;
  }

  .desktop\:margin-left-neg-05 {
    margin-left: -.25rem !important;
  }

  .desktop\:margin-x-neg-1 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .desktop\:margin-right-neg-1 {
    margin-right: -.5rem !important;
  }

  .desktop\:margin-left-neg-1 {
    margin-left: -.5rem !important;
  }

  .desktop\:margin-x-neg-105 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .desktop\:margin-right-neg-105 {
    margin-right: -.75rem !important;
  }

  .desktop\:margin-left-neg-105 {
    margin-left: -.75rem !important;
  }

  .desktop\:margin-x-neg-2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .desktop\:margin-right-neg-2 {
    margin-right: -1rem !important;
  }

  .desktop\:margin-left-neg-2 {
    margin-left: -1rem !important;
  }

  .desktop\:margin-x-neg-205 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .desktop\:margin-right-neg-205 {
    margin-right: -1.25rem !important;
  }

  .desktop\:margin-left-neg-205 {
    margin-left: -1.25rem !important;
  }

  .desktop\:margin-x-neg-3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .desktop\:margin-right-neg-3 {
    margin-right: -1.5rem !important;
  }

  .desktop\:margin-left-neg-3 {
    margin-left: -1.5rem !important;
  }

  .desktop\:margin-x-neg-4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .desktop\:margin-right-neg-4 {
    margin-right: -2rem !important;
  }

  .desktop\:margin-left-neg-4 {
    margin-left: -2rem !important;
  }

  .desktop\:margin-x-neg-5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .desktop\:margin-right-neg-5 {
    margin-right: -2.5rem !important;
  }

  .desktop\:margin-left-neg-5 {
    margin-left: -2.5rem !important;
  }

  .desktop\:margin-x-neg-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .desktop\:margin-right-neg-6 {
    margin-right: -3rem !important;
  }

  .desktop\:margin-left-neg-6 {
    margin-left: -3rem !important;
  }

  .desktop\:margin-x-neg-7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .desktop\:margin-right-neg-7 {
    margin-right: -3.5rem !important;
  }

  .desktop\:margin-left-neg-7 {
    margin-left: -3.5rem !important;
  }

  .desktop\:margin-x-neg-8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .desktop\:margin-right-neg-8 {
    margin-right: -4rem !important;
  }

  .desktop\:margin-left-neg-8 {
    margin-left: -4rem !important;
  }

  .desktop\:margin-x-neg-9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .desktop\:margin-right-neg-9 {
    margin-right: -4.5rem !important;
  }

  .desktop\:margin-left-neg-9 {
    margin-left: -4.5rem !important;
  }

  .desktop\:margin-x-neg-10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .desktop\:margin-right-neg-10 {
    margin-right: -5rem !important;
  }

  .desktop\:margin-left-neg-10 {
    margin-left: -5rem !important;
  }

  .desktop\:margin-x-neg-15 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .desktop\:margin-right-neg-15 {
    margin-right: -7.5rem !important;
  }

  .desktop\:margin-left-neg-15 {
    margin-left: -7.5rem !important;
  }

  .desktop\:margin-x-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .desktop\:margin-right-4 {
    margin-right: 2rem !important;
  }

  .desktop\:margin-left-4 {
    margin-left: 2rem !important;
  }

  .desktop\:margin-x-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .desktop\:margin-right-5 {
    margin-right: 2.5rem !important;
  }

  .desktop\:margin-left-5 {
    margin-left: 2.5rem !important;
  }

  .desktop\:margin-x-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .desktop\:margin-right-6 {
    margin-right: 3rem !important;
  }

  .desktop\:margin-left-6 {
    margin-left: 3rem !important;
  }

  .desktop\:margin-x-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .desktop\:margin-right-7 {
    margin-right: 3.5rem !important;
  }

  .desktop\:margin-left-7 {
    margin-left: 3.5rem !important;
  }

  .desktop\:margin-x-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .desktop\:margin-right-8 {
    margin-right: 4rem !important;
  }

  .desktop\:margin-left-8 {
    margin-left: 4rem !important;
  }

  .desktop\:margin-x-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .desktop\:margin-right-9 {
    margin-right: 4.5rem !important;
  }

  .desktop\:margin-left-9 {
    margin-left: 4.5rem !important;
  }

  .desktop\:margin-x-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .desktop\:margin-right-10 {
    margin-right: 5rem !important;
  }

  .desktop\:margin-left-10 {
    margin-left: 5rem !important;
  }

  .desktop\:margin-x-15 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .desktop\:margin-right-15 {
    margin-right: 7.5rem !important;
  }

  .desktop\:margin-left-15 {
    margin-left: 7.5rem !important;
  }

  .desktop\:margin-x-card {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .desktop\:margin-right-card {
    margin-right: 10rem !important;
  }

  .desktop\:margin-left-card {
    margin-left: 10rem !important;
  }

  .desktop\:margin-x-card-lg {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .desktop\:margin-right-card-lg {
    margin-right: 15rem !important;
  }

  .desktop\:margin-left-card-lg {
    margin-left: 15rem !important;
  }

  .desktop\:margin-x-mobile {
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .desktop\:margin-right-mobile {
    margin-right: 20rem !important;
  }

  .desktop\:margin-left-mobile {
    margin-left: 20rem !important;
  }

  .desktop\:margin-x-05em {
    margin-left: .5em !important;
    margin-right: .5em !important;
  }

  .desktop\:margin-right-05em {
    margin-right: .5em !important;
  }

  .desktop\:margin-left-05em {
    margin-left: .5em !important;
  }

  .desktop\:margin-x-1em {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }

  .desktop\:margin-right-1em {
    margin-right: 1em !important;
  }

  .desktop\:margin-left-1em {
    margin-left: 1em !important;
  }

  .desktop\:margin-x-105em {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }

  .desktop\:margin-right-105em {
    margin-right: 1.5em !important;
  }

  .desktop\:margin-left-105em {
    margin-left: 1.5em !important;
  }

  .desktop\:margin-x-2em {
    margin-left: 2em !important;
    margin-right: 2em !important;
  }

  .desktop\:margin-right-2em {
    margin-right: 2em !important;
  }

  .desktop\:margin-left-2em {
    margin-left: 2em !important;
  }

  .desktop\:margin-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .desktop\:margin-right-0 {
    margin-right: 0 !important;
  }

  .desktop\:margin-left-0 {
    margin-left: 0 !important;
  }

  .desktop\:margin-x-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .desktop\:margin-right-auto {
    margin-right: auto !important;
  }

  .desktop\:margin-left-auto {
    margin-left: auto !important;
  }

  .desktop\:measure-1 {
    max-width: 44ex !important;
  }

  .desktop\:measure-2 {
    max-width: 60ex !important;
  }

  .desktop\:measure-3 {
    max-width: 64ex !important;
  }

  .desktop\:measure-4 {
    max-width: 68ex !important;
  }

  .desktop\:measure-5 {
    max-width: 72ex !important;
  }

  .desktop\:measure-6 {
    max-width: 88ex !important;
  }

  .desktop\:measure-none {
    max-width: none !important;
  }

  .desktop\:order-first {
    order: -1 !important;
  }

  .desktop\:order-last {
    order: 999 !important;
  }

  .desktop\:order-initial {
    order: initial !important;
  }

  .desktop\:order-0 {
    order: 0 !important;
  }

  .desktop\:order-1 {
    order: 1 !important;
  }

  .desktop\:order-2 {
    order: 2 !important;
  }

  .desktop\:order-3 {
    order: 3 !important;
  }

  .desktop\:order-4 {
    order: 4 !important;
  }

  .desktop\:order-5 {
    order: 5 !important;
  }

  .desktop\:order-6 {
    order: 6 !important;
  }

  .desktop\:order-7 {
    order: 7 !important;
  }

  .desktop\:order-8 {
    order: 8 !important;
  }

  .desktop\:order-9 {
    order: 9 !important;
  }

  .desktop\:order-10 {
    order: 10 !important;
  }

  .desktop\:order-11 {
    order: 11 !important;
  }

  .desktop\:padding-1px {
    padding: 1px !important;
  }

  .desktop\:padding-y-1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .desktop\:padding-x-1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .desktop\:padding-top-1px {
    padding-top: 1px !important;
  }

  .desktop\:padding-right-1px {
    padding-right: 1px !important;
  }

  .desktop\:padding-bottom-1px {
    padding-bottom: 1px !important;
  }

  .desktop\:padding-left-1px {
    padding-left: 1px !important;
  }

  .desktop\:padding-2px {
    padding: 2px !important;
  }

  .desktop\:padding-y-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .desktop\:padding-x-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .desktop\:padding-top-2px {
    padding-top: 2px !important;
  }

  .desktop\:padding-right-2px {
    padding-right: 2px !important;
  }

  .desktop\:padding-bottom-2px {
    padding-bottom: 2px !important;
  }

  .desktop\:padding-left-2px {
    padding-left: 2px !important;
  }

  .desktop\:padding-05 {
    padding: .25rem !important;
  }

  .desktop\:padding-y-05 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .desktop\:padding-x-05 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .desktop\:padding-top-05 {
    padding-top: .25rem !important;
  }

  .desktop\:padding-right-05 {
    padding-right: .25rem !important;
  }

  .desktop\:padding-bottom-05 {
    padding-bottom: .25rem !important;
  }

  .desktop\:padding-left-05 {
    padding-left: .25rem !important;
  }

  .desktop\:padding-1 {
    padding: .5rem !important;
  }

  .desktop\:padding-y-1 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .desktop\:padding-x-1 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .desktop\:padding-top-1 {
    padding-top: .5rem !important;
  }

  .desktop\:padding-right-1 {
    padding-right: .5rem !important;
  }

  .desktop\:padding-bottom-1 {
    padding-bottom: .5rem !important;
  }

  .desktop\:padding-left-1 {
    padding-left: .5rem !important;
  }

  .desktop\:padding-105 {
    padding: .75rem !important;
  }

  .desktop\:padding-y-105 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .desktop\:padding-x-105 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .desktop\:padding-top-105 {
    padding-top: .75rem !important;
  }

  .desktop\:padding-right-105 {
    padding-right: .75rem !important;
  }

  .desktop\:padding-bottom-105 {
    padding-bottom: .75rem !important;
  }

  .desktop\:padding-left-105 {
    padding-left: .75rem !important;
  }

  .desktop\:padding-2 {
    padding: 1rem !important;
  }

  .desktop\:padding-y-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .desktop\:padding-x-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .desktop\:padding-top-2 {
    padding-top: 1rem !important;
  }

  .desktop\:padding-right-2 {
    padding-right: 1rem !important;
  }

  .desktop\:padding-bottom-2 {
    padding-bottom: 1rem !important;
  }

  .desktop\:padding-left-2 {
    padding-left: 1rem !important;
  }

  .desktop\:padding-205 {
    padding: 1.25rem !important;
  }

  .desktop\:padding-y-205 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .desktop\:padding-x-205 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .desktop\:padding-top-205 {
    padding-top: 1.25rem !important;
  }

  .desktop\:padding-right-205 {
    padding-right: 1.25rem !important;
  }

  .desktop\:padding-bottom-205 {
    padding-bottom: 1.25rem !important;
  }

  .desktop\:padding-left-205 {
    padding-left: 1.25rem !important;
  }

  .desktop\:padding-3 {
    padding: 1.5rem !important;
  }

  .desktop\:padding-y-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .desktop\:padding-x-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .desktop\:padding-top-3 {
    padding-top: 1.5rem !important;
  }

  .desktop\:padding-right-3 {
    padding-right: 1.5rem !important;
  }

  .desktop\:padding-bottom-3 {
    padding-bottom: 1.5rem !important;
  }

  .desktop\:padding-left-3 {
    padding-left: 1.5rem !important;
  }

  .desktop\:padding-4 {
    padding: 2rem !important;
  }

  .desktop\:padding-y-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .desktop\:padding-x-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .desktop\:padding-top-4 {
    padding-top: 2rem !important;
  }

  .desktop\:padding-right-4 {
    padding-right: 2rem !important;
  }

  .desktop\:padding-bottom-4 {
    padding-bottom: 2rem !important;
  }

  .desktop\:padding-left-4 {
    padding-left: 2rem !important;
  }

  .desktop\:padding-5 {
    padding: 2.5rem !important;
  }

  .desktop\:padding-y-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .desktop\:padding-x-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .desktop\:padding-top-5 {
    padding-top: 2.5rem !important;
  }

  .desktop\:padding-right-5 {
    padding-right: 2.5rem !important;
  }

  .desktop\:padding-bottom-5 {
    padding-bottom: 2.5rem !important;
  }

  .desktop\:padding-left-5 {
    padding-left: 2.5rem !important;
  }

  .desktop\:padding-6 {
    padding: 3rem !important;
  }

  .desktop\:padding-y-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .desktop\:padding-x-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .desktop\:padding-top-6 {
    padding-top: 3rem !important;
  }

  .desktop\:padding-right-6 {
    padding-right: 3rem !important;
  }

  .desktop\:padding-bottom-6 {
    padding-bottom: 3rem !important;
  }

  .desktop\:padding-left-6 {
    padding-left: 3rem !important;
  }

  .desktop\:padding-7 {
    padding: 3.5rem !important;
  }

  .desktop\:padding-y-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .desktop\:padding-x-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .desktop\:padding-top-7 {
    padding-top: 3.5rem !important;
  }

  .desktop\:padding-right-7 {
    padding-right: 3.5rem !important;
  }

  .desktop\:padding-bottom-7 {
    padding-bottom: 3.5rem !important;
  }

  .desktop\:padding-left-7 {
    padding-left: 3.5rem !important;
  }

  .desktop\:padding-8 {
    padding: 4rem !important;
  }

  .desktop\:padding-y-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .desktop\:padding-x-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .desktop\:padding-top-8 {
    padding-top: 4rem !important;
  }

  .desktop\:padding-right-8 {
    padding-right: 4rem !important;
  }

  .desktop\:padding-bottom-8 {
    padding-bottom: 4rem !important;
  }

  .desktop\:padding-left-8 {
    padding-left: 4rem !important;
  }

  .desktop\:padding-9 {
    padding: 4.5rem !important;
  }

  .desktop\:padding-y-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .desktop\:padding-x-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .desktop\:padding-top-9 {
    padding-top: 4.5rem !important;
  }

  .desktop\:padding-right-9 {
    padding-right: 4.5rem !important;
  }

  .desktop\:padding-bottom-9 {
    padding-bottom: 4.5rem !important;
  }

  .desktop\:padding-left-9 {
    padding-left: 4.5rem !important;
  }

  .desktop\:padding-10 {
    padding: 5rem !important;
  }

  .desktop\:padding-y-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .desktop\:padding-x-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .desktop\:padding-top-10 {
    padding-top: 5rem !important;
  }

  .desktop\:padding-right-10 {
    padding-right: 5rem !important;
  }

  .desktop\:padding-bottom-10 {
    padding-bottom: 5rem !important;
  }

  .desktop\:padding-left-10 {
    padding-left: 5rem !important;
  }

  .desktop\:padding-15 {
    padding: 7.5rem !important;
  }

  .desktop\:padding-y-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .desktop\:padding-x-15 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .desktop\:padding-top-15 {
    padding-top: 7.5rem !important;
  }

  .desktop\:padding-right-15 {
    padding-right: 7.5rem !important;
  }

  .desktop\:padding-bottom-15 {
    padding-bottom: 7.5rem !important;
  }

  .desktop\:padding-left-15 {
    padding-left: 7.5rem !important;
  }

  .desktop\:padding-0 {
    padding: 0 !important;
  }

  .desktop\:padding-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .desktop\:padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .desktop\:padding-top-0 {
    padding-top: 0 !important;
  }

  .desktop\:padding-right-0 {
    padding-right: 0 !important;
  }

  .desktop\:padding-bottom-0 {
    padding-bottom: 0 !important;
  }

  .desktop\:padding-left-0 {
    padding-left: 0 !important;
  }

  .desktop\:width-1px {
    width: 1px !important;
  }

  .desktop\:width-2px {
    width: 2px !important;
  }

  .desktop\:width-05 {
    width: .25rem !important;
  }

  .desktop\:width-1 {
    width: .5rem !important;
  }

  .desktop\:width-105 {
    width: .75rem !important;
  }

  .desktop\:width-2 {
    width: 1rem !important;
  }

  .desktop\:width-205 {
    width: 1.25rem !important;
  }

  .desktop\:width-3 {
    width: 1.5rem !important;
  }

  .desktop\:width-4 {
    width: 2rem !important;
  }

  .desktop\:width-5 {
    width: 2.5rem !important;
  }

  .desktop\:width-6 {
    width: 3rem !important;
  }

  .desktop\:width-7 {
    width: 3.5rem !important;
  }

  .desktop\:width-8 {
    width: 4rem !important;
  }

  .desktop\:width-9 {
    width: 4.5rem !important;
  }

  .desktop\:width-10 {
    width: 5rem !important;
  }

  .desktop\:width-15 {
    width: 7.5rem !important;
  }

  .desktop\:width-card {
    width: 10rem !important;
  }

  .desktop\:width-card-lg {
    width: 15rem !important;
  }

  .desktop\:width-mobile {
    width: 20rem !important;
  }

  .desktop\:width-mobile-lg {
    width: 30rem !important;
  }

  .desktop\:width-tablet {
    width: 40rem !important;
  }

  .desktop\:width-tablet-lg {
    width: 55rem !important;
  }

  .desktop\:width-desktop {
    width: 64rem !important;
  }

  .desktop\:width-desktop-lg {
    width: 75rem !important;
  }

  .desktop\:width-widescreen {
    width: 87.5rem !important;
  }

  .desktop\:width-0 {
    width: 0 !important;
  }

  .desktop\:width-full {
    width: 100% !important;
  }

  .desktop\:width-auto {
    width: auto !important;
  }
}

.grid-container {
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 64em) {
  .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-card {
  max-width: 10rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 64em) {
  .grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-card-lg {
  max-width: 15rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 64em) {
  .grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-mobile {
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 64em) {
  .grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-mobile-lg {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 64em) {
  .grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-tablet {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 64em) {
  .grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-tablet-lg {
  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 64em) {
  .grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-desktop {
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 64em) {
  .grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-desktop-lg {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 64em) {
  .grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-container-widescreen {
  max-width: 87.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 64em) {
  .grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-card {
    max-width: 10rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-card-lg {
    max-width: 15rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-mobile {
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-mobile-lg {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-tablet {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-tablet-lg {
    max-width: 55rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-desktop {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-desktop-lg {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .mobile-lg\:grid-container-widescreen {
    max-width: 87.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .mobile-lg\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-card {
    max-width: 10rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-card-lg {
    max-width: 15rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-mobile {
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-mobile-lg {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-tablet {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-tablet-lg {
    max-width: 55rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-desktop {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-desktop-lg {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .tablet\:grid-container-widescreen {
    max-width: 87.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 40em) and (width >= 64em) {
  .tablet\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-card {
    max-width: 10rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-card-lg {
    max-width: 15rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-mobile {
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-mobile-lg {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-tablet {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-tablet-lg {
    max-width: 55rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-desktop {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-desktop-lg {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .desktop\:grid-container-widescreen {
    max-width: 87.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .desktop\:grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grid-row {
  flex-wrap: wrap;
  display: flex;
}

.grid-row.grid-gap {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.grid-row.grid-gap > * {
  padding-left: .5rem;
  padding-right: .5rem;
}

@media (width >= 64em) {
  .grid-row.grid-gap {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .grid-row.grid-gap > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.grid-row.grid-gap-0 {
  margin-left: 0;
  margin-right: 0;
}

.grid-row.grid-gap-0 > * {
  padding-left: 0;
  padding-right: 0;
}

.grid-row.grid-gap-2px {
  margin-left: -1px;
  margin-right: -1px;
}

.grid-row.grid-gap-2px > * {
  padding-left: 1px;
  padding-right: 1px;
}

.grid-row.grid-gap-05 {
  margin-left: -2px;
  margin-right: -2px;
}

.grid-row.grid-gap-05 > * {
  padding-left: 2px;
  padding-right: 2px;
}

.grid-row.grid-gap-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.grid-row.grid-gap-1 > * {
  padding-left: .25rem;
  padding-right: .25rem;
}

.grid-row.grid-gap-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.grid-row.grid-gap-2 > * {
  padding-left: .5rem;
  padding-right: .5rem;
}

.grid-row.grid-gap-3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.grid-row.grid-gap-3 > * {
  padding-left: .75rem;
  padding-right: .75rem;
}

.grid-row.grid-gap-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.grid-row.grid-gap-4 > * {
  padding-left: 1rem;
  padding-right: 1rem;
}

.grid-row.grid-gap-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.grid-row.grid-gap-5 > * {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.grid-row.grid-gap-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.grid-row.grid-gap-6 > * {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.grid-row.grid-gap-sm {
  margin-left: -1px;
  margin-right: -1px;
}

.grid-row.grid-gap-sm > * {
  padding-left: 1px;
  padding-right: 1px;
}

.grid-row.grid-gap-md {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.grid-row.grid-gap-md > * {
  padding-left: .5rem;
  padding-right: .5rem;
}

.grid-row.grid-gap-lg {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.grid-row.grid-gap-lg > * {
  padding-left: .75rem;
  padding-right: .75rem;
}

@media (width >= 30em) {
  .grid-row.mobile-lg\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .grid-row.mobile-lg\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }

  .grid-row.mobile-lg\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .grid-row.mobile-lg\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }

  .grid-row.mobile-lg\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }

  .grid-row.mobile-lg\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }

  .grid-row.mobile-lg\:grid-gap-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .grid-row.mobile-lg\:grid-gap-1 > * {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .grid-row.mobile-lg\:grid-gap-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .grid-row.mobile-lg\:grid-gap-2 > * {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .grid-row.mobile-lg\:grid-gap-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .grid-row.mobile-lg\:grid-gap-3 > * {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .grid-row.mobile-lg\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .grid-row.mobile-lg\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .grid-row.mobile-lg\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .grid-row.mobile-lg\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .grid-row.mobile-lg\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .grid-row.mobile-lg\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .grid-row.mobile-lg\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }

  .grid-row.mobile-lg\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }

  .grid-row.mobile-lg\:grid-gap-md {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .grid-row.mobile-lg\:grid-gap-md > * {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .grid-row.mobile-lg\:grid-gap-lg {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .grid-row.mobile-lg\:grid-gap-lg > * {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

@media (width >= 40em) {
  .grid-row.tablet\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .grid-row.tablet\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }

  .grid-row.tablet\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .grid-row.tablet\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }

  .grid-row.tablet\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }

  .grid-row.tablet\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }

  .grid-row.tablet\:grid-gap-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .grid-row.tablet\:grid-gap-1 > * {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .grid-row.tablet\:grid-gap-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .grid-row.tablet\:grid-gap-2 > * {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .grid-row.tablet\:grid-gap-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .grid-row.tablet\:grid-gap-3 > * {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .grid-row.tablet\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .grid-row.tablet\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .grid-row.tablet\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .grid-row.tablet\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .grid-row.tablet\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .grid-row.tablet\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .grid-row.tablet\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }

  .grid-row.tablet\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }

  .grid-row.tablet\:grid-gap-md {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .grid-row.tablet\:grid-gap-md > * {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .grid-row.tablet\:grid-gap-lg {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .grid-row.tablet\:grid-gap-lg > * {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

@media (width >= 64em) {
  .grid-row.desktop\:grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .grid-row.desktop\:grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }

  .grid-row.desktop\:grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .grid-row.desktop\:grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }

  .grid-row.desktop\:grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }

  .grid-row.desktop\:grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }

  .grid-row.desktop\:grid-gap-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .grid-row.desktop\:grid-gap-1 > * {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .grid-row.desktop\:grid-gap-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .grid-row.desktop\:grid-gap-2 > * {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .grid-row.desktop\:grid-gap-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .grid-row.desktop\:grid-gap-3 > * {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .grid-row.desktop\:grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .grid-row.desktop\:grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .grid-row.desktop\:grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .grid-row.desktop\:grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .grid-row.desktop\:grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .grid-row.desktop\:grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .grid-row.desktop\:grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }

  .grid-row.desktop\:grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }

  .grid-row.desktop\:grid-gap-md {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .grid-row.desktop\:grid-gap-md > * {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .grid-row.desktop\:grid-gap-lg {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .grid-row.desktop\:grid-gap-lg > * {
    padding-left: .75rem;
    padding-right: .75rem;
  }
}

[class*="grid-col"] {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.grid-col {
  flex: 1;
  width: auto;
  min-width: 1px;
  max-width: 100%;
}

.grid-col-auto {
  flex: 0 auto;
  width: auto;
  max-width: 100%;
}

.grid-col-fill {
  flex: 1;
  width: auto;
  min-width: 1px;
  max-width: 100%;
}

.grid-col-1 {
  flex: 0 auto;
  width: 8.33333%;
}

.grid-col-2 {
  flex: 0 auto;
  width: 16.6667%;
}

.grid-col-3 {
  flex: 0 auto;
  width: 25%;
}

.grid-col-4 {
  flex: 0 auto;
  width: 33.3333%;
}

.grid-col-5 {
  flex: 0 auto;
  width: 41.6667%;
}

.grid-col-6 {
  flex: 0 auto;
  width: 50%;
}

.grid-col-7 {
  flex: 0 auto;
  width: 58.3333%;
}

.grid-col-8 {
  flex: 0 auto;
  width: 66.6667%;
}

.grid-col-9 {
  flex: 0 auto;
  width: 75%;
}

.grid-col-10 {
  flex: 0 auto;
  width: 83.3333%;
}

.grid-col-11 {
  flex: 0 auto;
  width: 91.6667%;
}

.grid-col-12 {
  flex: 0 auto;
  width: 100%;
}

@media (width >= 30em) {
  .mobile-lg\:grid-col, .mobile-lg\:grid-col-fill {
    flex: 1;
    width: auto;
    min-width: 1px;
    max-width: 100%;
  }

  .mobile-lg\:grid-col-auto {
    flex: 0 auto;
    width: auto;
    max-width: 100%;
  }

  .mobile-lg\:grid-col-1 {
    flex: 0 auto;
    width: 8.33333%;
  }

  .mobile-lg\:grid-col-2 {
    flex: 0 auto;
    width: 16.6667%;
  }

  .mobile-lg\:grid-col-3 {
    flex: 0 auto;
    width: 25%;
  }

  .mobile-lg\:grid-col-4 {
    flex: 0 auto;
    width: 33.3333%;
  }

  .mobile-lg\:grid-col-5 {
    flex: 0 auto;
    width: 41.6667%;
  }

  .mobile-lg\:grid-col-6 {
    flex: 0 auto;
    width: 50%;
  }

  .mobile-lg\:grid-col-7 {
    flex: 0 auto;
    width: 58.3333%;
  }

  .mobile-lg\:grid-col-8 {
    flex: 0 auto;
    width: 66.6667%;
  }

  .mobile-lg\:grid-col-9 {
    flex: 0 auto;
    width: 75%;
  }

  .mobile-lg\:grid-col-10 {
    flex: 0 auto;
    width: 83.3333%;
  }

  .mobile-lg\:grid-col-11 {
    flex: 0 auto;
    width: 91.6667%;
  }

  .mobile-lg\:grid-col-12 {
    flex: 0 auto;
    width: 100%;
  }
}

@media (width >= 40em) {
  .tablet\:grid-col, .tablet\:grid-col-fill {
    flex: 1;
    width: auto;
    min-width: 1px;
    max-width: 100%;
  }

  .tablet\:grid-col-auto {
    flex: 0 auto;
    width: auto;
    max-width: 100%;
  }

  .tablet\:grid-col-1 {
    flex: 0 auto;
    width: 8.33333%;
  }

  .tablet\:grid-col-2 {
    flex: 0 auto;
    width: 16.6667%;
  }

  .tablet\:grid-col-3 {
    flex: 0 auto;
    width: 25%;
  }

  .tablet\:grid-col-4 {
    flex: 0 auto;
    width: 33.3333%;
  }

  .tablet\:grid-col-5 {
    flex: 0 auto;
    width: 41.6667%;
  }

  .tablet\:grid-col-6 {
    flex: 0 auto;
    width: 50%;
  }

  .tablet\:grid-col-7 {
    flex: 0 auto;
    width: 58.3333%;
  }

  .tablet\:grid-col-8 {
    flex: 0 auto;
    width: 66.6667%;
  }

  .tablet\:grid-col-9 {
    flex: 0 auto;
    width: 75%;
  }

  .tablet\:grid-col-10 {
    flex: 0 auto;
    width: 83.3333%;
  }

  .tablet\:grid-col-11 {
    flex: 0 auto;
    width: 91.6667%;
  }

  .tablet\:grid-col-12 {
    flex: 0 auto;
    width: 100%;
  }
}

@media (width >= 64em) {
  .desktop\:grid-col, .desktop\:grid-col-fill {
    flex: 1;
    width: auto;
    min-width: 1px;
    max-width: 100%;
  }

  .desktop\:grid-col-auto {
    flex: 0 auto;
    width: auto;
    max-width: 100%;
  }

  .desktop\:grid-col-1 {
    flex: 0 auto;
    width: 8.33333%;
  }

  .desktop\:grid-col-2 {
    flex: 0 auto;
    width: 16.6667%;
  }

  .desktop\:grid-col-3 {
    flex: 0 auto;
    width: 25%;
  }

  .desktop\:grid-col-4 {
    flex: 0 auto;
    width: 33.3333%;
  }

  .desktop\:grid-col-5 {
    flex: 0 auto;
    width: 41.6667%;
  }

  .desktop\:grid-col-6 {
    flex: 0 auto;
    width: 50%;
  }

  .desktop\:grid-col-7 {
    flex: 0 auto;
    width: 58.3333%;
  }

  .desktop\:grid-col-8 {
    flex: 0 auto;
    width: 66.6667%;
  }

  .desktop\:grid-col-9 {
    flex: 0 auto;
    width: 75%;
  }

  .desktop\:grid-col-10 {
    flex: 0 auto;
    width: 83.3333%;
  }

  .desktop\:grid-col-11 {
    flex: 0 auto;
    width: 91.6667%;
  }

  .desktop\:grid-col-12 {
    flex: 0 auto;
    width: 100%;
  }
}

.grid-offset-1 {
  margin-left: 8.33333%;
}

.grid-offset-2 {
  margin-left: 16.6667%;
}

.grid-offset-3 {
  margin-left: 25%;
}

.grid-offset-4 {
  margin-left: 33.3333%;
}

.grid-offset-5 {
  margin-left: 41.6667%;
}

.grid-offset-6 {
  margin-left: 50%;
}

.grid-offset-7 {
  margin-left: 58.3333%;
}

.grid-offset-8 {
  margin-left: 66.6667%;
}

.grid-offset-9 {
  margin-left: 75%;
}

.grid-offset-10 {
  margin-left: 83.3333%;
}

.grid-offset-11 {
  margin-left: 91.6667%;
}

.grid-offset-12 {
  margin-left: 100%;
}

.grid-offset-none {
  margin-left: 0;
}

@media (width >= 30em) {
  .mobile-lg\:grid-offset-1 {
    margin-left: 8.33333%;
  }

  .mobile-lg\:grid-offset-2 {
    margin-left: 16.6667%;
  }

  .mobile-lg\:grid-offset-3 {
    margin-left: 25%;
  }

  .mobile-lg\:grid-offset-4 {
    margin-left: 33.3333%;
  }

  .mobile-lg\:grid-offset-5 {
    margin-left: 41.6667%;
  }

  .mobile-lg\:grid-offset-6 {
    margin-left: 50%;
  }

  .mobile-lg\:grid-offset-7 {
    margin-left: 58.3333%;
  }

  .mobile-lg\:grid-offset-8 {
    margin-left: 66.6667%;
  }

  .mobile-lg\:grid-offset-9 {
    margin-left: 75%;
  }

  .mobile-lg\:grid-offset-10 {
    margin-left: 83.3333%;
  }

  .mobile-lg\:grid-offset-11 {
    margin-left: 91.6667%;
  }

  .mobile-lg\:grid-offset-12 {
    margin-left: 100%;
  }

  .mobile-lg\:grid-offset-none {
    margin-left: 0;
  }
}

@media (width >= 40em) {
  .tablet\:grid-offset-1 {
    margin-left: 8.33333%;
  }

  .tablet\:grid-offset-2 {
    margin-left: 16.6667%;
  }

  .tablet\:grid-offset-3 {
    margin-left: 25%;
  }

  .tablet\:grid-offset-4 {
    margin-left: 33.3333%;
  }

  .tablet\:grid-offset-5 {
    margin-left: 41.6667%;
  }

  .tablet\:grid-offset-6 {
    margin-left: 50%;
  }

  .tablet\:grid-offset-7 {
    margin-left: 58.3333%;
  }

  .tablet\:grid-offset-8 {
    margin-left: 66.6667%;
  }

  .tablet\:grid-offset-9 {
    margin-left: 75%;
  }

  .tablet\:grid-offset-10 {
    margin-left: 83.3333%;
  }

  .tablet\:grid-offset-11 {
    margin-left: 91.6667%;
  }

  .tablet\:grid-offset-12 {
    margin-left: 100%;
  }

  .tablet\:grid-offset-none {
    margin-left: 0;
  }
}

@media (width >= 64em) {
  .desktop\:grid-offset-1 {
    margin-left: 8.33333%;
  }

  .desktop\:grid-offset-2 {
    margin-left: 16.6667%;
  }

  .desktop\:grid-offset-3 {
    margin-left: 25%;
  }

  .desktop\:grid-offset-4 {
    margin-left: 33.3333%;
  }

  .desktop\:grid-offset-5 {
    margin-left: 41.6667%;
  }

  .desktop\:grid-offset-6 {
    margin-left: 50%;
  }

  .desktop\:grid-offset-7 {
    margin-left: 58.3333%;
  }

  .desktop\:grid-offset-8 {
    margin-left: 66.6667%;
  }

  .desktop\:grid-offset-9 {
    margin-left: 75%;
  }

  .desktop\:grid-offset-10 {
    margin-left: 83.3333%;
  }

  .desktop\:grid-offset-11 {
    margin-left: 91.6667%;
  }

  .desktop\:grid-offset-12 {
    margin-left: 100%;
  }

  .desktop\:grid-offset-none {
    margin-left: 0;
  }
}

@font-face {
  font-family: Roboto Mono Web;
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url("./fonts/roboto-mono/roboto-mono-v5-latin-300.woff2") format("woff2");
}

@font-face {
  font-family: Roboto Mono Web;
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url("./fonts/roboto-mono/roboto-mono-v5-latin-regular.woff2") format("woff2");
}

@font-face {
  font-family: Roboto Mono Web;
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url("./fonts/roboto-mono/roboto-mono-v5-latin-700.woff2") format("woff2");
}

@font-face {
  font-family: Roboto Mono Web;
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: url("./fonts/roboto-mono/roboto-mono-v5-latin-300italic.woff2") format("woff2");
}

@font-face {
  font-family: Roboto Mono Web;
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url("./fonts/roboto-mono/roboto-mono-v5-latin-italic.woff2") format("woff2");
}

@font-face {
  font-family: Roboto Mono Web;
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url("./fonts/roboto-mono/roboto-mono-v5-latin-700italic.woff2") format("woff2");
}

.usa-button {
  font-family: var(--base-font-family), ;
  color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  -moz-column-gap: .5rem;
  text-align: center;
  background-color: #005ea2;
  border: 0;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  column-gap: .5rem;
  width: 100%;
  margin-right: .5rem;
  padding: .75rem 1.25rem;
  font-size: .99rem;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
}

@media (width >= 30em) {
  .usa-button {
    width: auto;
  }
}

.usa-button:visited {
  color: #fff;
}

.usa-button:hover, .usa-button.usa-button--hover {
  color: #fff;
  background-color: #1a4480;
  border-bottom: 0;
  text-decoration: none;
}

.usa-button:active, .usa-button.usa-button--active {
  color: #fff;
  background-color: #162e51;
}

.usa-button:not([disabled]):focus, .usa-button:not([disabled]).usa-focus {
  outline-offset: .25rem;
}

.usa-button:disabled, .usa-button[aria-disabled="true"] {
  color: #454545;
  cursor: not-allowed;
  opacity: 1;
  background-color: #c9c9c9;
}

.usa-button:disabled:hover, .usa-button:disabled:active, .usa-button:disabled:focus, .usa-button:disabled.usa-focus, .usa-button[aria-disabled="true"]:hover, .usa-button[aria-disabled="true"]:active, .usa-button[aria-disabled="true"]:focus, .usa-button[aria-disabled="true"].usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}

@media (forced-colors: active) {
  .usa-button:disabled, .usa-button[aria-disabled="true"] {
    color: graytext;
    border: 0;
  }

  .usa-button:disabled:hover, .usa-button:disabled:active, .usa-button:disabled:focus, .usa-button:disabled.usa-focus, .usa-button[aria-disabled="true"]:hover, .usa-button[aria-disabled="true"]:active, .usa-button[aria-disabled="true"]:focus, .usa-button[aria-disabled="true"].usa-focus {
    color: graytext;
  }
}

.usa-button:disabled.usa-button--hover, .usa-button:disabled.usa-button--active, .usa-button[aria-disabled="true"].usa-button--hover, .usa-button[aria-disabled="true"].usa-button--active {
  color: #454545;
  cursor: not-allowed;
  opacity: 1;
  background-color: #c9c9c9;
}

.usa-button:disabled.usa-button--hover:hover, .usa-button:disabled.usa-button--hover:active, .usa-button:disabled.usa-button--hover:focus, .usa-button:disabled.usa-button--hover.usa-focus, .usa-button:disabled.usa-button--active:hover, .usa-button:disabled.usa-button--active:active, .usa-button:disabled.usa-button--active:focus, .usa-button:disabled.usa-button--active.usa-focus, .usa-button[aria-disabled="true"].usa-button--hover:hover, .usa-button[aria-disabled="true"].usa-button--hover:active, .usa-button[aria-disabled="true"].usa-button--hover:focus, .usa-button[aria-disabled="true"].usa-button--hover.usa-focus, .usa-button[aria-disabled="true"].usa-button--active:hover, .usa-button[aria-disabled="true"].usa-button--active:active, .usa-button[aria-disabled="true"].usa-button--active:focus, .usa-button[aria-disabled="true"].usa-button--active.usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}

@media (forced-colors: active) {
  .usa-button:disabled.usa-button--hover, .usa-button:disabled.usa-button--active, .usa-button[aria-disabled="true"].usa-button--hover, .usa-button[aria-disabled="true"].usa-button--active {
    color: graytext;
    border: 0;
  }

  .usa-button:disabled.usa-button--hover:hover, .usa-button:disabled.usa-button--hover:active, .usa-button:disabled.usa-button--hover:focus, .usa-button:disabled.usa-button--hover.usa-focus, .usa-button:disabled.usa-button--active:hover, .usa-button:disabled.usa-button--active:active, .usa-button:disabled.usa-button--active:focus, .usa-button:disabled.usa-button--active.usa-focus, .usa-button[aria-disabled="true"].usa-button--hover:hover, .usa-button[aria-disabled="true"].usa-button--hover:active, .usa-button[aria-disabled="true"].usa-button--hover:focus, .usa-button[aria-disabled="true"].usa-button--hover.usa-focus, .usa-button[aria-disabled="true"].usa-button--active:hover, .usa-button[aria-disabled="true"].usa-button--active:active, .usa-button[aria-disabled="true"].usa-button--active:focus, .usa-button[aria-disabled="true"].usa-button--active.usa-focus {
    color: graytext;
  }

  .usa-button:disabled:not(.usa-button--unstyled), .usa-button[aria-disabled="true"]:not(.usa-button--unstyled) {
    border: 2px solid graytext;
  }
}

.usa-button .usa-icon {
  flex-shrink: 0;
}

@media (forced-colors: active) {
  .usa-button:not(.usa-button--unstyled) {
    border: 2px solid #0000;
  }
}

.usa-button--accent-cool, .usa-button--accent-cool:visited {
  color: #1c1d1f;
  background-color: #00bde3;
}

.usa-button--accent-cool:hover, .usa-button--accent-cool.usa-button--hover {
  color: #fff;
  background-color: #28a0cb;
}

.usa-button--accent-cool:active, .usa-button--accent-cool.usa-button--active {
  color: #fff;
  background-color: #07648d;
}

.usa-button--accent-warm, .usa-button--accent-warm:visited {
  color: #1c1d1f;
  background-color: #fa9441;
}

.usa-button--accent-warm:hover, .usa-button--accent-warm.usa-button--hover {
  color: #fff;
  background-color: #c05600;
}

.usa-button--accent-warm:active, .usa-button--accent-warm.usa-button--active {
  color: #fff;
  background-color: #775540;
}

.usa-button--outline {
  color: #005ea2;
  background-color: #0000;
  box-shadow: inset 0 0 0 2px #005ea2;
}

.usa-button--outline:visited {
  color: #005ea2;
}

.usa-button--outline:hover, .usa-button--outline.usa-button--hover {
  color: #1a4480;
  background-color: #0000;
  box-shadow: inset 0 0 0 2px #1a4480;
}

.usa-button--outline:active, .usa-button--outline.usa-button--active {
  color: #162e51;
  background-color: #0000;
  box-shadow: inset 0 0 0 2px #162e51;
}

.usa-button--outline.usa-button--inverse {
  color: #dfe1e2;
  box-shadow: inset 0 0 0 2px #dfe1e2;
}

.usa-button--outline.usa-button--inverse:visited {
  color: #dfe1e2;
}

.usa-button--outline.usa-button--inverse:hover, .usa-button--outline.usa-button--inverse.usa-button--hover {
  color: #f0f0f0;
  box-shadow: inset 0 0 0 2px #f0f0f0;
}

.usa-button--outline.usa-button--inverse:active, .usa-button--outline.usa-button--inverse.usa-button--active {
  color: #fff;
  background-color: #0000;
  box-shadow: inset 0 0 0 2px #fff;
}

.usa-button--outline.usa-button--inverse.usa-button--unstyled {
  color: #dfe1e2;
  box-shadow: none;
  text-align: left;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  justify-content: normal;
  width: auto;
  margin: 0;
  padding: 0;
  font-weight: normal;
  text-decoration: underline;
}

.usa-button--outline.usa-button--inverse.usa-button--unstyled:hover {
  color: #1a4480;
}

.usa-button--outline.usa-button--inverse.usa-button--unstyled:active {
  color: #162e51;
}

.usa-button--outline.usa-button--inverse.usa-button--unstyled:focus {
  outline-offset: 0rem;
  outline: .25rem solid #2491ff;
}

.usa-button--outline.usa-button--inverse.usa-button--unstyled:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"]:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled.usa-button--hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"].usa-button--hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled:active, .usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--active, .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:active, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"]:active, .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled.usa-button--active, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"].usa-button--active, .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:focus, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"]:focus, .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled.usa-focus, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"].usa-focus, .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"], .usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--disabled {
  box-shadow: none;
  background-color: #0000;
  text-decoration: underline;
}

.usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--hover {
  color: #1a4480;
}

.usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--active {
  color: #162e51;
}

.usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"], .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"]:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"]:focus {
  color: #757575;
}

@media (forced-colors: active) {
  .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"], .usa-button--outline.usa-button--inverse.usa-button--unstyled:disabled:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"]:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled[aria-disabled="true"]:focus {
    color: graytext;
  }
}

.usa-button--outline.usa-button--inverse.usa-button--unstyled:visited {
  color: #dfe1e2;
}

.usa-button--outline.usa-button--inverse.usa-button--unstyled:hover, .usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--hover {
  color: #f0f0f0;
}

.usa-button--outline.usa-button--inverse.usa-button--unstyled:active, .usa-button--outline.usa-button--inverse.usa-button--unstyled.usa-button--active {
  color: #fff;
}

.usa-button--base {
  color: #fff;
  background-color: #71767a;
}

.usa-button--base:hover, .usa-button--base.usa-button--hover {
  color: #fff;
  background-color: #565c65;
}

.usa-button--base:active, .usa-button--base.usa-button--active {
  color: #fff;
  background-color: #3d4551;
}

.usa-button--secondary {
  color: #fff;
  background-color: #d83933;
}

.usa-button--secondary:hover, .usa-button--secondary.usa-button--hover {
  color: #fff;
  background-color: #b50909;
}

.usa-button--secondary:active, .usa-button--secondary.usa-button--active {
  color: #fff;
  background-color: #8b0a03;
}

.usa-button--big {
  border-radius: .25rem;
  padding: 1rem 1.5rem;
  font-size: 1.37rem;
}

.usa-button--outline:disabled, .usa-button--outline:disabled:hover, .usa-button--outline:disabled:active, .usa-button--outline:disabled:focus, .usa-button--outline[aria-disabled="true"], .usa-button--outline[aria-disabled="true"]:hover, .usa-button--outline[aria-disabled="true"]:active, .usa-button--outline[aria-disabled="true"]:focus, .usa-button--outline-inverse:disabled, .usa-button--outline-inverse:disabled:hover, .usa-button--outline-inverse:disabled:active, .usa-button--outline-inverse:disabled:focus, .usa-button--outline-inverse[aria-disabled="true"], .usa-button--outline-inverse[aria-disabled="true"]:hover, .usa-button--outline-inverse[aria-disabled="true"]:active, .usa-button--outline-inverse[aria-disabled="true"]:focus {
  color: #757575;
  background-color: #0000;
}

.usa-button--outline:disabled, .usa-button--outline[aria-disabled="true"] {
  box-shadow: inset 0 0 0 2px #c9c9c9;
}

.usa-button--outline:disabled.usa-button--inverse, .usa-button--outline[aria-disabled="true"].usa-button--inverse {
  color: #919191;
  box-shadow: inset 0 0 0 2px #919191;
}

@media (forced-colors: active) {
  .usa-button--outline:disabled.usa-button--inverse, .usa-button--outline[aria-disabled="true"].usa-button--inverse {
    color: graytext;
  }
}

.usa-button--unstyled {
  color: #005ea2;
  box-shadow: none;
  text-align: left;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  justify-content: normal;
  width: auto;
  margin: 0;
  padding: 0;
  font-weight: normal;
  text-decoration: underline;
}

.usa-button--unstyled:visited {
  color: #54278f;
}

.usa-button--unstyled:hover {
  color: #1a4480;
}

.usa-button--unstyled:active {
  color: #162e51;
}

.usa-button--unstyled:focus {
  outline-offset: 0rem;
  outline: .25rem solid #2491ff;
}

.usa-button--unstyled:hover, .usa-button--unstyled.usa-button--hover, .usa-button--unstyled:disabled:hover, .usa-button--unstyled[aria-disabled="true"]:hover, .usa-button--unstyled:disabled.usa-button--hover, .usa-button--unstyled[aria-disabled="true"].usa-button--hover, .usa-button--unstyled:active, .usa-button--unstyled.usa-button--active, .usa-button--unstyled:disabled:active, .usa-button--unstyled[aria-disabled="true"]:active, .usa-button--unstyled:disabled.usa-button--active, .usa-button--unstyled[aria-disabled="true"].usa-button--active, .usa-button--unstyled:disabled:focus, .usa-button--unstyled[aria-disabled="true"]:focus, .usa-button--unstyled:disabled.usa-focus, .usa-button--unstyled[aria-disabled="true"].usa-focus, .usa-button--unstyled:disabled, .usa-button--unstyled[aria-disabled="true"], .usa-button--unstyled.usa-button--disabled {
  box-shadow: none;
  background-color: #0000;
  text-decoration: underline;
}

.usa-button--unstyled.usa-button--hover {
  color: #1a4480;
}

.usa-button--unstyled.usa-button--active {
  color: #162e51;
}

.usa-button--unstyled:disabled, .usa-button--unstyled[aria-disabled="true"], .usa-button--unstyled:disabled:hover, .usa-button--unstyled[aria-disabled="true"]:hover, .usa-button--unstyled[aria-disabled="true"]:focus {
  color: #757575;
}

@media (forced-colors: active) {
  .usa-button--unstyled:disabled, .usa-button--unstyled[aria-disabled="true"], .usa-button--unstyled:disabled:hover, .usa-button--unstyled[aria-disabled="true"]:hover, .usa-button--unstyled[aria-disabled="true"]:focus {
    color: graytext;
  }
}

.usa-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  position: relative;
}

.usa-icon--size-3 {
  width: 1.5rem;
  height: 1.5rem;
}

.usa-icon--size-4 {
  width: 2rem;
  height: 2rem;
}

.usa-icon--size-5 {
  width: 2.5rem;
  height: 2.5rem;
}

.usa-icon--size-6 {
  width: 3rem;
  height: 3rem;
}

.usa-icon--size-7 {
  width: 3.5rem;
  height: 3.5rem;
}

.usa-icon--size-8 {
  width: 4rem;
  height: 4rem;
}

.usa-icon--size-9 {
  width: 4.5rem;
  height: 4.5rem;
}

.usa-card-group, .usa-prose .usa-card-group {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.usa-card-group {
  flex-flow: column wrap;
  align-items: stretch;
  margin-left: -.5rem;
  margin-right: -.5rem;
  display: flex;
}

@media (width >= 40em) {
  .usa-card-group {
    flex-direction: row;
  }
}

.usa-card {
  max-width: none;
  margin-bottom: 2.5rem;
}

.usa-card:last-child {
  margin-bottom: 2.5rem;
}

@media (width >= 40em) {
  .usa-card, .usa-card:last-child {
    margin-bottom: 2rem;
  }
}

.usa-prose .usa-card {
  max-width: none;
  margin-bottom: 2.5rem;
}

.usa-prose .usa-card:last-child {
  margin-bottom: 2.5rem;
}

@media (width >= 40em) {
  .usa-prose .usa-card, .usa-prose .usa-card:last-child {
    margin-bottom: 2rem;
  }
}

.usa-card__container {
  color: #1c1d1f;
  font-family: var(--base-font-family), ;
  background-color: #fff;
  border: 2px solid #dfe1e2;
  border-radius: .5rem;
  flex-direction: column;
  height: 100%;
  margin-left: .5rem;
  margin-right: .5rem;
  font-size: .99rem;
  line-height: 1.6;
  display: flex;
  position: relative;
}

.usa-card:not(.usa-card--flag) .usa-card__container > :only-child {
  padding: 1.5rem;
}

.usa-card .usa-card__img {
  display: block;
}

.usa-card__header {
  padding: 1.5rem 1.5rem .5rem;
}

.usa-card__header:last-child {
  padding-bottom: 1.5rem;
}

.usa-card__heading {
  font-family: var(--base-font-family), ;
  margin: 0;
  font-size: 1.37rem;
  line-height: 1.2;
}

.usa-card__media {
  order: -1;
  min-height: 1px;
}

.usa-card__img {
  background-color: #f0f0f0;
  border-top-left-radius: calc(.5rem - 2px);
  border-top-right-radius: calc(.5rem - 2px);
  position: relative;
  overflow: hidden;
}

.usa-card__img img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.usa-card__media--inset {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.usa-card__media--inset .usa-card__img {
  border-radius: 0;
}

.usa-card__body {
  flex: auto;
  padding: .5rem 1.5rem;
}

.usa-card__body:last-child {
  padding-bottom: 1.5rem;
}

.usa-card__body:first-child {
  padding-top: 1.5rem;
}

.usa-card__body:only-child {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.usa-card__footer {
  padding: .5rem 1.5rem 1.5rem;
}

.usa-card__footer .usa-button:only-of-type {
  margin-right: 0;
}

.usa-card__header > :last-child, .usa-card__body > :last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.usa-card__header > :first-child, .usa-card__body > :first-child {
  margin-top: 0;
  padding-top: 0;
}

.usa-card__header > :only-child, .usa-card__body > :only-child {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.usa-card__header--exdent, .usa-card__media--exdent, .usa-card__body--exdent, .usa-card__footer--exdent {
  margin-inline: -2px;
}

.usa-card__header--exdent > *, .usa-card__body--exdent > *, .usa-card__footer--exdent > * {
  padding-left: 2px;
  padding-right: 2px;
}

.usa-card__media--exdent {
  margin-top: -2px;
}

.usa-card__media--exdent .usa-card__img {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.usa-card--header-first .usa-card__header {
  border-top-left-radius: calc(.5rem - 2px);
  border-top-right-radius: calc(.5rem - 2px);
  padding-bottom: 1rem;
}

.usa-card--header-first .usa-card__header--exdent {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  margin-top: -2px;
}

.usa-card--header-first .usa-card__media--inset {
  padding-top: 0;
}

.usa-card--header-first .usa-card__media {
  order: 0;
}

.usa-card--header-first .usa-card__media--exdent {
  margin-top: 0;
}

.usa-card--header-first .usa-card__img {
  border-radius: 0;
}

.usa-card--header-first .usa-card__body {
  padding-top: 1rem;
}

@media (width >= 40em) {
  .usa-card--flag .usa-card__media {
    width: 15rem;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  .usa-card--flag .usa-card__img {
    border-radius: calc(.5rem - 2px) 0 0 calc(.5rem - 2px);
  }

  .usa-card--flag .usa-card__header, .usa-card--flag .usa-card__body, .usa-card--flag .usa-card__footer {
    margin-left: 15rem;
  }

  .usa-card--flag .usa-card__media--exdent {
    margin: -2px;
  }

  .usa-card--flag .usa-card__media--exdent .usa-card__img {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .usa-card--flag .usa-card__media--inset {
    padding-bottom: 1.5rem;
    padding-right: 0;
  }

  .usa-card--flag .usa-card__media--inset .usa-card__img {
    border-radius: 0;
  }

  .usa-card--flag.usa-card--header-first .usa-card__header {
    padding-bottom: .5rem;
  }

  .usa-card--flag.usa-card--header-first .usa-card__body {
    padding-top: .5rem;
  }

  .usa-card--flag.usa-card--header-first .usa-card__media--inset {
    padding-top: 1.5rem;
  }

  .usa-card--flag.usa-card--media-right .usa-card__media {
    left: auto;
    right: 0;
  }

  .usa-card--flag.usa-card--media-right .usa-card__media--inset {
    padding-left: 0;
    padding-right: 1.5rem;
  }

  .usa-card--flag.usa-card--media-right .usa-card__media--inset .usa-card__img {
    border-radius: 0;
  }

  .usa-card--flag.usa-card--media-right .usa-card__header, .usa-card--flag.usa-card--media-right .usa-card__body, .usa-card--flag.usa-card--media-right .usa-card__footer {
    margin-left: 0;
    margin-right: 15rem;
  }

  .usa-card--flag.usa-card--media-right .usa-card__img {
    border-radius: 0 .5rem .5rem 0;
  }
}

.usa-card:not(.usa-card--flag).usa-card__media--set-aspect {
  box-sizing: border-box;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: 1) {
  .usa-card:not(.usa-card--flag).usa-card__media--set-aspect {
    height: inherit;
    padding: inherit;
    aspect-ratio: 1.77778;
    max-width: 100%;
  }

  .usa-card:not(.usa-card--flag).usa-card__media--set-aspect > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  img.usa-card:not(.usa-card--flag).usa-card__media--set-aspect, .usa-card:not(.usa-card--flag).usa-card__media--set-aspect > img {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.usa-card:not(.usa-card--flag).usa-card__media--set-aspect .usa-card__img {
  position: absolute;
  inset: 0;
}

.usa-button-group {
  flex-flow: column wrap;
  margin: 0 -.25rem;
  padding-left: 0;
  list-style-type: none;
  display: flex;
}

@media (width >= 30em) {
  .usa-button-group {
    flex-flow: row;
    align-items: stretch;
  }
}

.usa-button-group .usa-button-group {
  height: 100%;
}

@media (width >= 30em) {
  .usa-button-group .usa-button-group .usa-button-group__item {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.usa-button-group .usa-button-group--segmented .usa-button-group__item {
  margin-top: 0;
  margin-bottom: 0;
}

.usa-button-group__item {
  margin: .25rem;
}

@media (width >= 30em) {
  .usa-button-group__item:last-child {
    margin-right: 0;
  }
}

.usa-button-group__item .usa-button {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
}

.usa-button-group--segmented {
  flex-flow: row;
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
}

@media (width >= 30em) {
  .usa-button-group--segmented {
    justify-content: flex-start;
  }
}

.usa-button-group--segmented .usa-button {
  width: calc(100% + 2px);
  position: relative;
}

@media (width >= 30em) {
  .usa-button-group--segmented .usa-button {
    width: auto;
  }
}

.usa-button-group--segmented .usa-button:hover, .usa-button-group--segmented .usa-button:active {
  z-index: 2;
}

.usa-button-group--segmented .usa-button:focus {
  z-index: 3;
}

.usa-button-group--segmented .usa-button-group__item {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media (width >= 30em) {
  .usa-button-group--segmented .usa-button-group__item {
    width: auto;
  }
}

.usa-button-group--segmented .usa-button-group__item:first-child > .usa-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.usa-button-group--segmented .usa-button-group__item:last-child > .usa-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: calc(100% + 2px);
  margin-left: -2px;
  margin-right: 0;
}

@media (width >= 30em) {
  .usa-button-group--segmented .usa-button-group__item:last-child > .usa-button {
    width: auto;
    margin-left: -1px;
  }
}

.usa-button-group--segmented .usa-button-group__item:where(:not(:first-child):not(:last-child)) > .usa-button {
  border-radius: 0;
  margin-left: -1px;
  margin-right: -1px;
}

.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button:before {
  content: "";
  z-index: 3;
  border-right: 1px solid #1a4480;
  width: 1px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1px;
}

.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button--secondary:before {
  border-right-color: #b50909;
}

.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button--accent-cool:before {
  border-right-color: #28a0cb;
}

.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button--base:before {
  border-right-color: #565c65;
}

.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) [class*="usa-button"]:disabled:before, .usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) [class*="usa-button"][aria-disabled="true"]:before {
  border-right-color: #fff;
}

.usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button:active:before, .usa-button-group--segmented .usa-button-group__item:where(:not(:last-child)) .usa-button--outline:before {
  display: none;
}

.usa-js-no-click {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.usa-js-no-click .usa-modal, .usa-js-no-click .usa-modal * {
  pointer-events: auto;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
}

[data-open-modal] * {
  pointer-events: none;
}

.usa-modal-wrapper {
  text-align: center;
  transition: opacity .15s ease-in-out;
}

.usa-modal-wrapper.is-hidden {
  visibility: hidden;
  opacity: 0;
  position: fixed;
}

.usa-modal-wrapper.is-visible {
  visibility: visible;
  opacity: 1;
  z-index: 99999;
  position: fixed;
}

.usa-modal-overlay {
  scroll-behavior: smooth;
  background: #000000b3;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden scroll;
}

.usa-modal-overlay:before {
  content: "";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.usa-modal-overlay[data-force-action="true"] {
  pointer-events: none;
}

.usa-modal-overlay[data-force-action="true"] * {
  pointer-events: auto;
}

.usa-js-loading .usa-modal-wrapper {
  position: absolute;
  left: -999em;
  right: auto;
}

.usa-js-loading .usa-modal-wrapper:target {
  position: static;
}

.usa-modal {
  font-family: var(--base-font-family), ;
  color: #1c1d1f;
  text-align: left;
  vertical-align: middle;
  background: #fff;
  border-radius: .5rem;
  width: 100%;
  max-width: 30rem;
  margin: 1.25rem auto;
  font-size: .99rem;
  line-height: 1.6;
  display: inline-block;
  position: relative;
}

.usa-modal:focus {
  outline: none;
}

.usa-modal__content {
  flex-direction: column-reverse;
  width: 100%;
  padding-top: 2rem;
  display: flex;
}

.usa-modal__main {
  margin: 0 auto;
  padding: .5rem 2rem 2rem;
}

.usa-modal-wrapper [data-close-modal] > .usa-icon, .usa-modal-wrapper [data-close-modal] > .usa-icon use {
  pointer-events: none !important;
}

.usa-modal__close {
  color: #71767a;
  background-color: #0000;
  flex-shrink: 0;
  align-self: flex-end;
  align-items: center;
  width: auto;
  margin: -2rem 0 0 auto;
  padding: .25rem;
  font-size: .87rem;
  display: flex;
}

.usa-modal__close:hover, .usa-modal__close:active {
  color: #1c1d1f;
  background-color: #0000;
}

.usa-modal__close:focus {
  outline-offset: 0;
}

.usa-modal__close .usa-icon {
  width: 2rem;
  height: 2rem;
  margin: 2px 2px 0 0;
}

.usa-modal__heading {
  font-family: var(--base-font-family), ;
  margin-top: 0;
  font-size: 1.37rem;
  line-height: 1.4;
}

.usa-modal__footer {
  margin-top: 1.5rem;
}

.usa-modal--lg {
  width: 100%;
  max-width: 55rem;
}

.usa-modal--lg .usa-modal__main {
  width: 100%;
  max-width: 40rem;
  padding-top: 1.25rem;
  padding-bottom: 4rem;
}

@media (width >= 40em) {
  .usa-modal--lg .usa-modal__heading {
    font-family: var(--base-font-family), ;
    font-size: 1.99rem;
  }
}

.usa-js-modal--active {
  overflow: hidden;
}

.usa-input:disabled, .usa-input[aria-disabled="true"] {
  color: #454545;
  cursor: not-allowed;
  opacity: 1;
  -webkit-text-fill-color: #454545;
  background-color: #c9c9c9;
}

.usa-input:disabled:hover, .usa-input:disabled:active, .usa-input:disabled:focus, .usa-input:disabled.usa-focus, .usa-input[aria-disabled="true"]:hover, .usa-input[aria-disabled="true"]:active, .usa-input[aria-disabled="true"]:focus, .usa-input[aria-disabled="true"].usa-focus {
  color: #454545;
  background-color: #c9c9c9;
}

@media (forced-colors: active) {
  .usa-input:disabled, .usa-input[aria-disabled="true"] {
    color: graytext;
    border: 0;
  }

  .usa-input:disabled:hover, .usa-input:disabled:active, .usa-input:disabled:focus, .usa-input:disabled.usa-focus, .usa-input[aria-disabled="true"]:hover, .usa-input[aria-disabled="true"]:active, .usa-input[aria-disabled="true"]:focus, .usa-input[aria-disabled="true"].usa-focus {
    color: graytext;
  }

  .usa-input:disabled, .usa-input[aria-disabled="true"] {
    border: 2px solid graytext;
  }
}

.usa-input--error {
  border: .25rem solid #b50909;
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.usa-input--success {
  border: .25rem solid #00a91c;
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.usa-form {
  font-family: var(--base-font-family), ;
  font-size: .99rem;
  line-height: 1.4;
}

@media (width >= 30em) {
  .usa-form {
    max-width: 20rem;
  }
}

.usa-form abbr[title="required"] {
  text-decoration: none;
}

.usa-form .usa-input, .usa-form .usa-range, .usa-form .usa-select, .usa-form .usa-textarea {
  max-width: none;
}

.usa-form .usa-input--2xs, .usa-form .usa-input-group--2xs {
  max-width: 5ex;
}

.usa-form .usa-input--xs, .usa-form .usa-input-group--xs {
  max-width: 9ex;
}

.usa-form .usa-input--sm, .usa-form .usa-input--small, .usa-form .usa-input-group--sm, .usa-form .usa-input-group--small {
  max-width: 13ex;
}

.usa-form .usa-input--md, .usa-form .usa-input--medium, .usa-form .usa-input-group--md, .usa-form .usa-input-group--medium {
  max-width: 20ex;
}

.usa-form .usa-input--lg, .usa-form .usa-input-group--lg {
  max-width: 30ex;
}

.usa-form .usa-input--xl, .usa-form .usa-input-group--xl {
  max-width: 40ex;
}

.usa-form .usa-input--2xl, .usa-form .usa-input-group--2xl {
  max-width: 50ex;
}

.usa-form .usa-button {
  margin-top: .5rem;
}

@media (width >= 30em) {
  .usa-form .usa-button {
    margin-top: 1.5rem;
  }
}

.usa-form a:where(:not(.usa-button)) {
  color: #005ea2;
  text-decoration: underline;
}

.usa-form a:where(:not(.usa-button)):visited {
  color: #54278f;
}

.usa-form a:where(:not(.usa-button)):hover {
  color: #1a4480;
}

.usa-form a:where(:not(.usa-button)):active {
  color: #162e51;
}

.usa-form a:where(:not(.usa-button)):focus {
  outline-offset: 0rem;
  outline: .25rem solid #2491ff;
}

@media (width >= 30em) {
  .usa-form--large {
    max-width: 30rem;
  }
}

.usa-show-password {
  color: #005ea2;
  box-shadow: none;
  text-align: left;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  justify-content: normal;
  width: auto;
  margin: 0;
  padding: 0;
  font-weight: normal;
  text-decoration: underline;
}

.usa-show-password:visited {
  color: #54278f;
}

.usa-show-password:hover {
  color: #1a4480;
}

.usa-show-password:active {
  color: #162e51;
}

.usa-show-password:focus {
  outline-offset: 0rem;
  outline: .25rem solid #2491ff;
}

.usa-show-password:hover, .usa-show-password.usa-button--hover, .usa-show-password:disabled:hover, .usa-show-password[aria-disabled="true"]:hover, .usa-show-password:disabled.usa-button--hover, .usa-show-password[aria-disabled="true"].usa-button--hover, .usa-show-password:active, .usa-show-password.usa-button--active, .usa-show-password:disabled:active, .usa-show-password[aria-disabled="true"]:active, .usa-show-password:disabled.usa-button--active, .usa-show-password[aria-disabled="true"].usa-button--active, .usa-show-password:disabled:focus, .usa-show-password[aria-disabled="true"]:focus, .usa-show-password:disabled.usa-focus, .usa-show-password[aria-disabled="true"].usa-focus, .usa-show-password:disabled, .usa-show-password[aria-disabled="true"], .usa-show-password.usa-button--disabled {
  box-shadow: none;
  background-color: #0000;
  text-decoration: underline;
}

.usa-show-password.usa-button--hover {
  color: #1a4480;
}

.usa-show-password.usa-button--active {
  color: #162e51;
}

.usa-show-password:disabled, .usa-show-password[aria-disabled="true"], .usa-show-password:disabled:hover, .usa-show-password[aria-disabled="true"]:hover, .usa-show-password[aria-disabled="true"]:focus {
  color: #757575;
}

@media (forced-colors: active) {
  .usa-show-password:disabled, .usa-show-password[aria-disabled="true"], .usa-show-password:disabled:hover, .usa-show-password[aria-disabled="true"]:hover, .usa-show-password[aria-disabled="true"]:focus {
    color: graytext;
  }
}

.usa-form__note, .usa-show-password {
  font-family: var(--base-font-family), ;
  float: right;
  margin: .25rem 0 1rem;
  font-size: .87rem;
  line-height: 1.4;
}

.usa-label {
  font-family: var(--base-font-family), ;
  max-width: 30rem;
  margin-top: 1.5rem;
  font-size: .99rem;
  font-weight: normal;
  line-height: 1.4;
  display: block;
}

.usa-label--error {
  margin-top: 0;
  font-weight: 700;
}

.usa-label--required {
  color: #b50909;
}

.usa-list {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 3ch;
  line-height: 1.6;
}

.usa-list:last-child {
  margin-bottom: 0;
}

.usa-list ul, .usa-list ol {
  margin-top: .25em;
}

.usa-list li {
  max-width: 68ex;
  margin-bottom: .25em;
}

.usa-list li:last-child {
  margin-bottom: 0;
}

.usa-list--unstyled {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.usa-list--unstyled > li {
  max-width: unset;
  margin-bottom: 0;
}

.usa-prose .usa-list--unstyled {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.usa-prose .usa-list--unstyled > li {
  max-width: unset;
  margin-bottom: 0;
}

.usa-footer {
  font-family: var(--base-font-family), ;
  font-size: .99rem;
  line-height: 1.6;
  overflow: hidden;
}

.usa-footer > .grid-container {
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 64em) {
  .usa-footer > .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.usa-footer__return-to-top {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  line-height: 1;
}

.usa-footer__return-to-top a {
  color: #005ea2;
  text-decoration: underline;
}

.usa-footer__return-to-top a:visited {
  color: #54278f;
}

.usa-footer__return-to-top a:hover {
  color: #1a4480;
}

.usa-footer__return-to-top a:active {
  color: #162e51;
}

.usa-footer__return-to-top a:focus {
  outline-offset: 0rem;
  outline: .25rem solid #2491ff;
}

.usa-footer__nav {
  border-bottom: 1px solid #a9aeb1;
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

@media (width >= 64em) {
  .usa-footer__nav {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 30em) {
  .usa-footer__nav {
    border-bottom: none;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 30em) and (width >= 64em) {
  .usa-footer__nav {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.usa-footer__nav > ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.usa-footer__primary-section {
  background-color: #f0f0f0;
}

.usa-footer__primary-section > .grid-container {
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 64em) {
  .usa-footer__primary-section > .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.usa-footer__primary-container {
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 64em) {
  .usa-footer__primary-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.usa-footer__primary-content {
  line-height: 1.2;
}

.usa-footer__primary-link {
  color: #1c1d1f;
  padding: 1rem;
  font-weight: 700;
  display: block;
}

@media (width >= 30em) {
  .usa-footer__primary-link {
    padding-left: 0;
    padding-right: 0;
  }
}

.usa-footer__primary-link--button {
  cursor: pointer;
  border: 0;
  width: 100%;
}

.usa-footer__primary-link--button:before {
  content: "";
  vertical-align: middle;
  background-image: url("./img/usa-icons/expand_more.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.25rem 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .25rem;
  display: inline-block;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-footer__primary-link--button:before {
    background: currentColor;
    -webkit-mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 1.25rem 1.25rem;
    mask-size: 1.25rem 1.25rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.usa-footer__primary-link--button:not([disabled]):focus {
  outline-offset: -.25rem;
  outline: .25rem solid #2491ff;
}

.usa-footer__primary-link--button:before {
  content: "";
  background-size: contain;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: -.25rem;
  margin-right: .25rem;
  display: inline-flex;
}

@media (forced-colors: active) {
  .usa-footer__primary-link--button:before {
    background-color: buttontext !important;
  }
}

.usa-footer__primary-link--button + .usa-list--unstyled {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.usa-footer__primary-link--button[aria-expanded="false"]:before {
  content: "";
  vertical-align: middle;
  background-image: url("./img/usa-icons/navigate_next.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.25rem 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .25rem;
  display: inline-block;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-footer__primary-link--button[aria-expanded="false"]:before {
    background: currentColor;
    -webkit-mask-image: url("./img/usa-icons/navigate_next.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/navigate_next.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 1.25rem 1.25rem;
    mask-size: 1.25rem 1.25rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.usa-footer__primary-link--button[aria-expanded="false"] + .usa-list--unstyled {
  display: none;
}

.usa-footer__secondary-link {
  margin-left: 1rem;
  padding: 0;
  line-height: 1.2;
}

.usa-footer__secondary-link a {
  color: #005ea2;
  text-decoration: underline;
}

.usa-footer__secondary-link a:visited {
  color: #54278f;
}

.usa-footer__secondary-link a:hover {
  color: #1a4480;
}

.usa-footer__secondary-link a:active {
  color: #162e51;
}

.usa-footer__secondary-link a:focus {
  outline-offset: 0rem;
  outline: .25rem solid #2491ff;
}

.usa-footer__secondary-link + .usa-footer__secondary-link {
  padding-top: 1rem;
}

@media (width >= 30em) {
  .usa-footer__secondary-link {
    margin-left: 0;
  }
}

.usa-footer__contact-info a {
  color: #1c1d1f;
}

@media (width >= 30em) {
  .usa-footer__contact-info {
    justify-content: flex-end;
    margin-top: .5rem;
  }
}

.usa-footer__primary-content {
  border-top: 1px solid #a9aeb1;
}

@media (width >= 30em) {
  .usa-footer__primary-content {
    border: none;
  }
}

.usa-sign-up {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

.usa-sign-up .usa-label, .usa-sign-up .usa-button {
  margin-top: .75rem;
}

.usa-sign-up__heading {
  font-family: var(--base-font-family), ;
  margin: 0;
  font-size: 1.37rem;
  font-weight: 700;
  line-height: 1.2;
}

.usa-footer__secondary-section {
  color: #1c1d1f;
  background-color: #dfe1e2;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.usa-footer__secondary-section > .grid-container {
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 64em) {
  .usa-footer__secondary-section > .grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.usa-footer__secondary-section > .grid-container > .grid-row {
  justify-content: space-between;
}

.usa-footer__secondary-section a {
  color: #1c1d1f;
}

.usa-footer__logo {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

@media (width >= 30em) {
  .usa-footer__logo {
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.usa-footer__logo-img {
  max-width: 5rem;
}

.usa-footer__logo-heading {
  font-family: var(--base-font-family), ;
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: 1.37rem;
  font-weight: 700;
  line-height: 1;
}

.usa-footer__contact-links {
  margin-top: 1.5rem;
}

@media (width >= 30em) {
  .usa-footer__contact-links {
    text-align: right;
    margin-top: 0;
  }
}

.usa-footer__contact-heading {
  font-family: var(--base-font-family), ;
  margin-top: 0;
  font-size: 1.37rem;
  font-weight: 700;
  line-height: 1.2;
}

@media (width >= 30em) {
  .usa-footer__contact-heading {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }
}

.usa-footer__social-links {
  padding-bottom: .5rem;
  line-height: 1;
}

.usa-footer__social-links a {
  text-decoration: none;
}

@media (width >= 30em) {
  .usa-footer__social-links {
    justify-content: flex-end;
  }
}

.usa-social-link {
  background-color: #0000001a;
  width: 3rem;
  height: 3rem;
  padding: .25rem;
  display: inline-block;
}

.usa-social-link:hover {
  background-color: #fff;
}

@media (forced-colors: active) {
  .usa-social-link {
    forced-color-adjust: none;
    background-color: #d3d3d3;
  }
}

.usa-social-link__icon {
  width: 100%;
  height: auto;
  display: block;
}

@media (width >= 30em) {
  .usa-footer__address {
    justify-content: flex-end;
  }
}

@media (width >= 64em) {
  .usa-footer--slim .usa-footer__nav {
    padding-left: 0;
    padding-right: 0;
  }
}

.usa-footer--slim .usa-footer__address {
  padding: 1rem;
}

@media (width >= 30em) {
  .usa-footer--slim .usa-footer__address {
    padding: 0;
  }
}

.usa-footer--slim .usa-footer__logo {
  align-items: center;
}

.usa-footer--slim .usa-footer__logo-img {
  max-width: 3rem;
}

.usa-footer--slim .usa-footer__contact-info {
  display: inline-block;
}

@media (width >= 30em) {
  .usa-footer--slim .usa-footer__contact-info {
    margin-top: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.usa-footer--big .usa-footer__nav {
  margin-left: -1rem;
  margin-right: -1rem;
}

@media (width >= 30em) {
  .usa-footer--big .usa-footer__nav {
    border-bottom: 1px solid #a9aeb1;
    padding-top: 2rem;
  }
}

@media (width >= 40em) {
  .usa-footer--big .usa-footer__nav {
    border-bottom: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.usa-footer--big .usa-footer__primary-link {
  font-family: var(--base-font-family), ;
  margin: 0;
  font-size: .99rem;
  font-weight: 700;
  line-height: 1.2;
}

@media (width >= 30em) {
  .usa-footer--big .usa-footer__primary-link {
    margin-bottom: .5rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  .usa-footer--big .usa-footer__primary-link:hover {
    cursor: auto;
    text-decoration: none;
  }
}

.usa-footer--big .usa-footer__primary-content--collapsible .usa-footer__primary-link {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.usa-footer--big .usa-footer__primary-content--collapsible .usa-list--unstyled {
  padding-bottom: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 30em) {
  .usa-footer--big .usa-footer__primary-content--collapsible .usa-list--unstyled {
    padding: .75rem 0 2rem;
  }
}

.usa-focus {
  outline-offset: 0rem;
  outline: .25rem solid #2491ff;
}

.usa-sr-only {
  position: absolute;
  left: -999em;
  right: auto;
}

.usa-skipnav {
  font-family: var(--base-font-family), ;
  color: #005ea2;
  z-index: 100;
  background: none;
  padding: .5rem 1rem;
  font-size: .99rem;
  line-height: 1.6;
  text-decoration: underline;
  transition: all .15s ease-in-out;
  position: absolute;
  top: -3.8rem;
  left: 0;
}

.usa-skipnav:visited {
  color: #54278f;
}

.usa-skipnav:hover {
  color: #1a4480;
}

.usa-skipnav:active {
  color: #162e51;
}

.usa-skipnav:focus {
  outline-offset: 0rem;
  outline: .25rem solid #2491ff;
}

.usa-skipnav:focus, .usa-skipnav.usa-focus {
  background: #fff;
  transition: all .15s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.usa-skipnav__container {
  position: relative;
}

.usa-search {
  font-family: var(--base-font-family), ;
  font-size: .99rem;
  line-height: 1.6;
  position: relative;
}

.usa-search:after {
  clear: both;
  content: "";
  display: block;
}

.usa-search[role="search"], .usa-search[role="search"] > div, .usa-search [role="search"] {
  display: flex;
}

.usa-search [type="submit"] {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 3rem;
  height: 2rem;
  margin: 0;
  padding: 0;
}

@media (width >= 30em) {
  .usa-search [type="submit"] {
    width: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (forced-colors: active) {
  .usa-search [type="submit"]:before {
    content: "";
    vertical-align: middle;
    background-image: url("./img/usa-icons/search.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.5rem 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: auto;
    display: inline-block;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-search [type="submit"]:before {
      background: buttontext;
      -webkit-mask-image: url("./img/usa-icons/search.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/search.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1.5rem 1.5rem;
      mask-size: 1.5rem 1.5rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }

  .usa-search [type="submit"]:focus {
    outline-offset: 0;
  }
}

@media (forced-colors: active) and (width >= 30em) {
  .usa-search [type="submit"]:before {
    content: none;
  }
}

@media (width >= 30em) {
  .usa-search__submit-icon {
    display: none;
  }
}

@media (forced-colors: active) {
  .usa-search__submit-icon {
    display: none;
  }
}

@media (width >= 30em) {
  .usa-search--big [type="search"], .usa-search--big .usa-search__input {
    height: 3rem;
    font-size: .99rem;
  }

  .usa-search--big [type="submit"], .usa-search--big .usa-search__submit {
    width: auto;
    height: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.37rem;
  }
}

.usa-search--small [type="submit"], .usa-search--small .usa-search__submit {
  min-width: 3rem;
  padding-left: .75rem;
  padding-right: .75rem;
}

@media (forced-colors: active) and (width >= 30em) {
  .usa-search--small [type="submit"]:before {
    content: "";
  }
}

.usa-search--small .usa-search__submit-icon {
  width: 1.5rem;
  height: 1.5rem;
  display: block;
}

@media (forced-colors: active) {
  .usa-search--small .usa-search__submit-icon {
    display: none;
  }
}

input[type="search"] {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

[type="search"], .usa-search__input {
  box-sizing: border-box;
  float: left;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 2rem;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: .93rem;
}

.usa-search__submit-text {
  display: none;
}

@media (width >= 30em) {
  .usa-search__submit-text {
    display: block;
  }
}

.usa-accordion {
  color: #1c1d1f;
  font-family: var(--base-font-family), ;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: .99rem;
  line-height: 1.6;
  list-style-type: none;
}

.usa-accordion > li {
  max-width: unset;
  margin-bottom: 0;
}

.usa-accordion > ul li ul {
  list-style: disc;
}

.usa-accordion > ul li ul > li > ul {
  list-style: circle;
}

.usa-accordion > ul li ul > li > ul > li > ul {
  list-style: square;
}

.usa-accordion + .usa-accordion, .usa-accordion + .usa-accordion--bordered {
  margin-top: .5rem;
}

.usa-accordion--bordered .usa-accordion__content {
  border-bottom: .25rem solid #f0f0f0;
  border-left: .25rem solid #f0f0f0;
  border-right: .25rem solid #f0f0f0;
  padding-bottom: 1rem;
}

.usa-accordion--bordered .usa-accordion__heading {
  margin-bottom: 0;
}

.usa-accordion__heading, .usa-prose .usa-accordion__heading {
  font-family: var(--base-font-family), ;
  margin: 0;
  font-size: .99rem;
  line-height: 1;
}

.usa-accordion__heading:not(:first-child), .usa-prose .usa-accordion__heading:not(:first-child) {
  margin-top: .5rem;
}

.usa-accordion__content {
  color: #1c1d1f;
  background-color: #fff;
  margin-top: 0;
  padding: 1rem 1.25rem .75rem;
  overflow: auto;
}

.usa-accordion__content > :first-child {
  margin-top: 0;
}

.usa-accordion__content > :last-child {
  margin-bottom: 0;
}

.usa-accordion__button {
  color: #1c1d1f;
  box-shadow: none;
  text-align: left;
  cursor: pointer;
  background-color: #f0f0f0;
  background-image: url("./img/usa-icons/remove.svg"), linear-gradient(#0000, #0000);
  background-position: right 1.25rem center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  border: 0;
  border-radius: 0;
  justify-content: normal;
  width: 100%;
  margin: 0;
  padding: 1rem 3.5rem 1rem 1.25rem;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
}

.usa-accordion__button:visited {
  color: #54278f;
}

.usa-accordion__button:hover {
  color: #1a4480;
}

.usa-accordion__button:active {
  color: #162e51;
}

.usa-accordion__button:focus {
  outline-offset: 0rem;
  outline: .25rem solid #2491ff;
}

.usa-accordion__button:hover, .usa-accordion__button.usa-button--hover, .usa-accordion__button:disabled:hover, .usa-accordion__button[aria-disabled="true"]:hover, .usa-accordion__button:disabled.usa-button--hover, .usa-accordion__button[aria-disabled="true"].usa-button--hover, .usa-accordion__button:active, .usa-accordion__button.usa-button--active, .usa-accordion__button:disabled:active, .usa-accordion__button[aria-disabled="true"]:active, .usa-accordion__button:disabled.usa-button--active, .usa-accordion__button[aria-disabled="true"].usa-button--active, .usa-accordion__button:disabled:focus, .usa-accordion__button[aria-disabled="true"]:focus, .usa-accordion__button:disabled.usa-focus, .usa-accordion__button[aria-disabled="true"].usa-focus, .usa-accordion__button:disabled, .usa-accordion__button[aria-disabled="true"], .usa-accordion__button.usa-button--disabled {
  box-shadow: none;
  background-color: #0000;
  text-decoration: underline;
}

.usa-accordion__button.usa-button--hover {
  color: #1a4480;
}

.usa-accordion__button.usa-button--active {
  color: #162e51;
}

.usa-accordion__button:disabled, .usa-accordion__button[aria-disabled="true"], .usa-accordion__button:disabled:hover, .usa-accordion__button[aria-disabled="true"]:hover, .usa-accordion__button[aria-disabled="true"]:focus {
  color: #757575;
}

@media (forced-colors: active) {
  .usa-accordion__button:disabled, .usa-accordion__button[aria-disabled="true"], .usa-accordion__button:disabled:hover, .usa-accordion__button[aria-disabled="true"]:hover, .usa-accordion__button[aria-disabled="true"]:focus {
    color: graytext;
  }
}

.usa-accordion__button:hover {
  color: #1c1d1f;
  background-color: #dfe1e2;
  background-image: url("./img/usa-icons/remove.svg"), linear-gradient(#0000, #0000);
  background-repeat: no-repeat;
  text-decoration: none;
}

@media (forced-colors: active) {
  .usa-accordion__button {
    border: 2px solid #0000;
    position: relative;
  }

  .usa-accordion__button:before {
    content: "";
    background-image: url("./img/usa-icons/remove.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.5rem 1.5rem;
    width: 1.5rem;
    height: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 1.25rem;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-accordion__button:before {
      background: buttontext;
      -webkit-mask-image: url("./img/usa-icons/remove.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/remove.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1.5rem 1.5rem;
      mask-size: 1.5rem 1.5rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }
}

.usa-accordion__button[aria-expanded="false"] {
  background-image: url("./img/usa-icons/add.svg"), linear-gradient(#0000, #0000);
  background-repeat: no-repeat;
  background-size: 1.5rem;
}

.usa-accordion__button[aria-expanded="false"]:hover {
  background-image: url("./img/usa-icons/add.svg"), linear-gradient(#0000, #0000);
  background-repeat: no-repeat;
}

@media (forced-colors: active) {
  .usa-accordion__button[aria-expanded="false"]:before {
    background-image: url("./img/usa-icons/add.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.5rem 1.5rem;
    width: 1.5rem;
    height: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 1.25rem;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-accordion__button[aria-expanded="false"]:before {
      background: buttontext;
      -webkit-mask-image: url("./img/usa-icons/add.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/add.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1.5rem 1.5rem;
      mask-size: 1.5rem 1.5rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }
}

.usa-nav {
  font-family: var(--base-font-family), ;
  font-size: .99rem;
  line-height: 1;
}

@media (width <= 63.99em) {
  .usa-nav {
    z-index: 500;
    background: #fff;
    border-right: 0;
    flex-direction: column;
    width: 15rem;
    padding: 1rem;
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
  }

  .usa-nav.is-visible {
    animation: .3s ease-in-out slidein-left;
    display: flex;
  }
}

@media (width >= 64em) {
  .usa-nav {
    float: right;
    position: relative;
  }
}

.usa-nav .usa-search {
  margin-top: 1rem;
}

@media (width >= 64em) {
  .usa-nav .usa-search {
    margin-top: 0;
    margin-left: 1rem;
  }
}

.usa-nav .usa-accordion {
  font-family: var(--base-font-family), ;
  font-size: .99rem;
  line-height: 1;
}

@media (width <= 63.99em) {
  .usa-nav__primary {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
  }

  .usa-nav__primary > li {
    max-width: unset;
    margin-bottom: 0;
  }

  .usa-nav__primary-item {
    border-top: 1px solid #dfe1e2;
  }

  .usa-nav__primary a:not(.usa-button) {
    padding: .5rem 1rem;
    text-decoration: none;
    display: block;
  }

  .usa-nav__primary a:not(.usa-button):hover {
    background-color: #f0f0f0;
    text-decoration: none;
  }

  .usa-nav__primary a:not(.usa-button):not(.usa-current) {
    color: #565c65;
  }

  .usa-nav__primary a:not(.usa-button):not(.usa-current):hover {
    color: #005ea2;
  }

  .usa-nav__primary a:not(.usa-button):not(.usa-current):focus {
    outline-offset: 0;
  }

  .usa-nav__primary .usa-current {
    color: #005ea2;
    font-weight: 700;
    position: relative;
  }

  .usa-nav__primary .usa-current:after {
    content: "";
    background-color: #005ea2;
    border-radius: 99rem;
    width: .25rem;
    display: block;
    position: absolute;
    top: .25rem;
    bottom: .25rem;
    left: .25rem;
  }
}

@media (width <= 63.99em) and (width >= 40em) {
  .usa-nav__primary .usa-current {
    position: relative;
  }

  .usa-nav__primary .usa-current:after {
    content: "";
    background-color: #005ea2;
    border-radius: 99rem;
    width: .25rem;
    display: block;
    position: absolute;
    top: .25rem;
    bottom: .25rem;
    left: 0;
  }
}

@media (width <= 63.99em) {
  .usa-nav__primary a {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }
}

@media (width >= 64em) {
  .usa-nav__primary {
    align-items: stretch;
    display: flex;
  }
}

.usa-nav__primary .usa-nav__primary-item a {
  text-decoration: none;
}

.usa-nav__primary > .usa-nav__primary-item {
  line-height: 1.2;
}

@media (width >= 64em) {
  .usa-nav__primary > .usa-nav__primary-item {
    font-size: .87rem;
    line-height: 1;
  }

  .usa-nav__primary > .usa-nav__primary-item > a {
    color: #565c65;
    align-items: center;
    padding: 1rem;
    font-weight: 700;
    line-height: 1;
    display: flex;
  }

  .usa-nav__primary > .usa-nav__primary-item > a:hover {
    color: #005ea2;
  }

  .usa-nav__primary > .usa-nav__primary-item > button, .usa-nav__primary > .usa-nav__primary-item > a {
    height: 100%;
  }

  .usa-nav__primary a {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}

.usa-nav__primary button {
  color: #565c65;
  box-shadow: none;
  text-align: left;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  justify-content: normal;
  width: 100%;
  margin: 0;
  padding: .75rem 1rem;
  font-weight: normal;
  line-height: 1.2;
  text-decoration: none;
  position: relative;
}

.usa-nav__primary button:visited {
  color: #54278f;
}

.usa-nav__primary button:hover {
  color: #1a4480;
}

.usa-nav__primary button:active {
  color: #162e51;
}

.usa-nav__primary button:focus {
  outline-offset: 0rem;
  outline: .25rem solid #2491ff;
}

.usa-nav__primary button:hover, .usa-nav__primary button.usa-button--hover, .usa-nav__primary button:disabled:hover, .usa-nav__primary button[aria-disabled="true"]:hover, .usa-nav__primary button:disabled.usa-button--hover, .usa-nav__primary button[aria-disabled="true"].usa-button--hover, .usa-nav__primary button:active, .usa-nav__primary button.usa-button--active, .usa-nav__primary button:disabled:active, .usa-nav__primary button[aria-disabled="true"]:active, .usa-nav__primary button:disabled.usa-button--active, .usa-nav__primary button[aria-disabled="true"].usa-button--active, .usa-nav__primary button:disabled:focus, .usa-nav__primary button[aria-disabled="true"]:focus, .usa-nav__primary button:disabled.usa-focus, .usa-nav__primary button[aria-disabled="true"].usa-focus, .usa-nav__primary button:disabled, .usa-nav__primary button[aria-disabled="true"], .usa-nav__primary button.usa-button--disabled {
  box-shadow: none;
  background-color: #0000;
  text-decoration: underline;
}

.usa-nav__primary button.usa-button--hover {
  color: #1a4480;
}

.usa-nav__primary button.usa-button--active {
  color: #162e51;
}

.usa-nav__primary button:disabled, .usa-nav__primary button[aria-disabled="true"], .usa-nav__primary button:disabled:hover, .usa-nav__primary button[aria-disabled="true"]:hover, .usa-nav__primary button[aria-disabled="true"]:focus {
  color: #757575;
}

@media (forced-colors: active) {
  .usa-nav__primary button:disabled, .usa-nav__primary button[aria-disabled="true"], .usa-nav__primary button:disabled:hover, .usa-nav__primary button[aria-disabled="true"]:hover, .usa-nav__primary button[aria-disabled="true"]:focus {
    color: graytext;
  }
}

@media (width >= 64em) {
  .usa-nav__primary button {
    padding: 1rem;
    font-size: .87rem;
    font-weight: 700;
    line-height: 1;
  }
}

@media (forced-colors: active) {
  .usa-nav__primary button {
    forced-color-adjust: auto;
  }
}

.usa-nav__primary button:hover {
  color: #005ea2;
  background-color: #f0f0f0;
  text-decoration: none;
}

@media (width >= 64em) {
  .usa-nav__primary button:hover {
    background-color: #0000;
  }
}

.usa-nav__primary button[aria-expanded], .usa-nav__primary button[aria-expanded]:hover {
  background-image: none;
}

@media (forced-colors: active) {
  .usa-nav__primary button[aria-expanded]:before, .usa-nav__primary button[aria-expanded]:hover:before {
    content: none;
  }
}

.usa-nav__primary button[aria-expanded] span:after {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.usa-nav__primary button[aria-expanded="false"] span:after {
  content: "";
  vertical-align: middle;
  background-image: url("./img/usa-icons/add.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.25rem 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  display: inline-block;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-nav__primary button[aria-expanded="false"] span:after {
    background: buttontext;
    -webkit-mask-image: url("./img/usa-icons/add.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/add.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 1.25rem 1.25rem;
    mask-size: 1.25rem 1.25rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.usa-nav__primary button[aria-expanded="false"] span:after:hover {
  background-color: buttontext;
}

@media (width >= 64em) {
  .usa-nav__primary button[aria-expanded="false"] span:after {
    content: "";
    vertical-align: middle;
    background-image: url("./img/usa-icons/expand_more.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    display: inline-block;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-nav__primary button[aria-expanded="false"] span:after {
      background: buttontext;
      -webkit-mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1rem 1rem;
      mask-size: 1rem 1rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }

  .usa-nav__primary button[aria-expanded="false"] span:after {
    right: .75rem;
  }

  .usa-nav__primary button[aria-expanded="false"]:hover span:after {
    background-color: #1a4480;
  }
}

@media (width >= 64em) and (forced-colors: active) {
  .usa-nav__primary button[aria-expanded="false"]:hover span:after {
    background-color: buttontext;
  }
}

.usa-nav__primary button[aria-expanded="true"] span:after {
  content: "";
  vertical-align: middle;
  background-image: url("./img/usa-icons/remove.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.25rem 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  display: inline-block;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-nav__primary button[aria-expanded="true"] span:after {
    background: buttontext;
    -webkit-mask-image: url("./img/usa-icons/remove.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/remove.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 1.25rem 1.25rem;
    mask-size: 1.25rem 1.25rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.usa-nav__primary button[aria-expanded="true"] span:after {
  position: absolute;
  right: 0;
}

@media (forced-colors: active) {
  .usa-nav__primary button[aria-expanded="true"] span:after {
    background-color: buttontext;
  }
}

@media (width >= 64em) {
  .usa-nav__primary button[aria-expanded="true"] {
    color: #fff;
    background-color: #162e51;
    background-image: none;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .usa-nav__primary button[aria-expanded="true"] span:after {
    content: "";
    vertical-align: middle;
    background-image: url("./img/usa-icons/expand_less.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    display: inline-block;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-nav__primary button[aria-expanded="true"] span:after {
      background: buttontext;
      -webkit-mask-image: url("./img/usa-icons/expand_less.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/expand_less.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1rem 1rem;
      mask-size: 1rem 1rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }

  .usa-nav__primary button[aria-expanded="true"] span:after {
    background-color: #fff;
    right: .75rem;
  }
}

@media (width >= 64em) and (width >= 64em) and (forced-colors: active) {
  .usa-nav__primary button[aria-expanded="true"] span:after {
    background-color: buttontext;
  }
}

@media (width >= 64em) {
  .usa-nav__primary .usa-accordion__button span {
    margin-right: 0;
    padding-right: 1rem;
    display: inline-block;
  }
}

.usa-nav__secondary {
  margin-top: 1rem;
}

@media (width >= 64em) {
  .usa-nav__secondary {
    flex-direction: column;
    align-items: flex-end;
    min-width: calc(27ch + 3rem);
    margin-top: .5rem;
    font-size: .87rem;
    display: flex;
    position: absolute;
    bottom: 4rem;
    right: 2rem;
  }
}

.usa-nav__secondary .usa-search {
  width: 100%;
}

@media (width >= 64em) {
  .usa-nav__secondary .usa-search {
    margin-top: .5rem;
    margin-left: 0;
  }
}

.usa-nav__secondary-links {
  margin-top: 1.5rem;
  margin-bottom: 0;
  padding-left: 0;
  line-height: 1.4;
  list-style-type: none;
}

@media (width >= 64em) {
  .usa-nav__secondary-links {
    -moz-column-gap: .5rem;
    flex-flow: row;
    column-gap: .5rem;
    margin-top: 0;
    margin-bottom: .25rem;
    line-height: 1;
    display: flex;
  }

  .usa-nav__secondary-links .usa-nav__secondary-item {
    padding-left: .25rem;
  }

  .usa-nav__secondary-links .usa-nav__secondary-item + .usa-nav__secondary-item {
    border-left: 1px solid #dfe1e2;
    padding-left: .5rem;
  }
}

.usa-nav__secondary-links a {
  color: #71767a;
  font-size: .87rem;
  text-decoration: none;
  display: inline-block;
}

.usa-nav__secondary-links a:hover {
  color: #005ea2;
  text-decoration: underline;
}

@media (width <= 63.99em) {
  .usa-nav__submenu {
    margin: 0;
    padding-left: 0;
    list-style-type: none;
  }

  .usa-nav__submenu > li {
    max-width: unset;
    margin-bottom: 0;
  }

  .usa-nav__submenu-item {
    border-top: 1px solid #dfe1e2;
    font-size: .87rem;
  }

  .usa-nav__submenu .usa-current:after {
    display: none;
  }
}

@media (width <= 63.99em) and (width >= 40em) {
  .usa-nav__submenu .usa-current:after {
    display: none;
  }
}

@media (width <= 63.99em) {
  .usa-nav__submenu a:not(.usa-button) {
    padding-left: 2rem;
  }

  .usa-nav__submenu .usa-nav__submenu a:not(.usa-button) {
    padding-left: 3rem;
  }

  .usa-nav__submenu .usa-nav__submenu .usa-nav__submenu a:not(.usa-button) {
    padding-left: 4rem;
  }
}

@media (width >= 64em) {
  .usa-nav__submenu {
    z-index: 400;
    background-color: #162e51;
    width: 15rem;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 0;
    list-style-type: none;
    position: absolute;
  }
}

.usa-nav__submenu[aria-hidden="true"] {
  display: none;
}

@media (width >= 64em) {
  .usa-nav__submenu .usa-nav__submenu-item a {
    color: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 1.4;
    display: block;
  }

  .usa-nav__submenu .usa-nav__submenu-item a:focus {
    outline-offset: -.25rem;
  }

  .usa-nav__submenu .usa-nav__submenu-item a:hover {
    color: #fff;
    text-decoration: underline;
  }
}

.usa-nav__submenu-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.usa-nav__submenu-list > li {
  max-width: unset;
  margin-bottom: 0;
}

.usa-nav__submenu-list .usa-nav__submenu-list-item {
  margin: 0;
  font-size: .87rem;
}

.usa-nav__submenu-list .usa-nav__submenu-list-item a {
  line-height: 1.4;
}

.usa-nav__close {
  color: currentColor;
  box-shadow: none;
  text-align: left;
  float: right;
  text-align: center;
  background-color: #0000;
  background-image: none;
  border: 0;
  border-radius: 0;
  flex: none;
  justify-content: normal;
  width: 3rem;
  height: 3rem;
  margin: -.75rem -1rem 1rem auto;
  padding: 0;
  font-weight: normal;
  text-decoration: underline;
}

.usa-nav__close:visited {
  color: #54278f;
}

.usa-nav__close:hover {
  color: #1a4480;
}

.usa-nav__close:active {
  color: #162e51;
}

.usa-nav__close:focus {
  outline-offset: 0rem;
  outline: .25rem solid #2491ff;
}

.usa-nav__close:hover, .usa-nav__close.usa-button--hover, .usa-nav__close:disabled:hover, .usa-nav__close[aria-disabled="true"]:hover, .usa-nav__close:disabled.usa-button--hover, .usa-nav__close[aria-disabled="true"].usa-button--hover, .usa-nav__close:active, .usa-nav__close.usa-button--active, .usa-nav__close:disabled:active, .usa-nav__close[aria-disabled="true"]:active, .usa-nav__close:disabled.usa-button--active, .usa-nav__close[aria-disabled="true"].usa-button--active, .usa-nav__close:disabled:focus, .usa-nav__close[aria-disabled="true"]:focus, .usa-nav__close:disabled.usa-focus, .usa-nav__close[aria-disabled="true"].usa-focus, .usa-nav__close:disabled, .usa-nav__close[aria-disabled="true"], .usa-nav__close.usa-button--disabled {
  box-shadow: none;
  background-color: #0000;
  text-decoration: underline;
}

.usa-nav__close.usa-button--hover {
  color: #1a4480;
}

.usa-nav__close.usa-button--active {
  color: #162e51;
}

.usa-nav__close:disabled, .usa-nav__close[aria-disabled="true"], .usa-nav__close:disabled:hover, .usa-nav__close[aria-disabled="true"]:hover, .usa-nav__close[aria-disabled="true"]:focus {
  color: #757575;
}

@media (forced-colors: active) {
  .usa-nav__close:disabled, .usa-nav__close[aria-disabled="true"], .usa-nav__close:disabled:hover, .usa-nav__close[aria-disabled="true"]:hover, .usa-nav__close[aria-disabled="true"]:focus {
    color: graytext;
  }

  .usa-nav__close:before {
    content: "";
    vertical-align: middle;
    background-image: url("./img/usa-icons/close.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.25rem 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: auto;
    display: inline-block;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-nav__close:before {
      background: buttontext;
      -webkit-mask-image: url("./img/usa-icons/close.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/close.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1.25rem 1.25rem;
      mask-size: 1.25rem 1.25rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }

  .usa-nav__close:before {
    background-color: buttontext;
  }
}

.usa-nav__close:hover {
  color: currentColor;
  text-decoration: none;
}

@media (width >= 64em) {
  .usa-nav__close {
    display: none;
  }
}

.usa-nav__close img {
  width: 1.5rem;
}

@media (forced-colors: active) {
  .usa-nav__close img {
    display: none;
  }
}

.usa-nav__close + * {
  clear: both;
}

.usa-js-mobile-nav--active {
  overflow: hidden;
}

@media (width >= 63.06rem) {
  .usa-js-mobile-nav--active.is-safari {
    top: var(--scrolltop, 0);
    position: fixed;
    overflow-y: scroll;
  }
}

.usa-header {
  font-family: var(--base-font-family), ;
  font-size: .99rem;
  line-height: 1.6;
}

.usa-header:after {
  clear: both;
  content: "";
  display: block;
}

.usa-header a {
  border-bottom: none;
}

.usa-header .usa-search {
  margin-top: .5rem;
}

@media (width >= 64em) {
  .usa-header .usa-search {
    float: right;
  }

  .usa-header [role="search"] {
    float: right;
    width: 100%;
    max-width: calc(27ch + 3rem);
  }
}

.usa-header [type="search"] {
  min-width: 0;
}

@media (width >= 64em) {
  .usa-header + .usa-hero {
    border-top: 1px solid #fff;
  }

  .usa-header + .usa-section, .usa-header + main {
    border-top: 1px solid #dfe1e2;
  }
}

@media (width <= 63.99em) {
  .usa-logo {
    flex: 1;
    margin-left: 1rem;
    font-size: .87rem;
    line-height: 1;
  }
}

@media (width >= 64em) {
  .usa-logo {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.37rem;
    line-height: 1.2;
  }
}

.usa-logo a {
  color: #1c1d1f;
  text-decoration: none;
}

.usa-logo__text {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  display: block;
}

.usa-menu-btn {
  color: #fff;
  box-shadow: none;
  text-align: left;
  text-align: center;
  text-transform: uppercase;
  background-color: #005ea2;
  border: 0;
  border-radius: 0;
  flex: 0 auto;
  justify-content: normal;
  width: auto;
  height: 3rem;
  margin: 0;
  padding: 0 .75rem;
  font-size: .81rem;
  font-weight: normal;
  text-decoration: none;
}

.usa-menu-btn:visited {
  color: #54278f;
}

.usa-menu-btn:hover {
  color: #1a4480;
}

.usa-menu-btn:focus {
  outline-offset: 0rem;
  outline: .25rem solid #2491ff;
}

.usa-menu-btn:hover, .usa-menu-btn.usa-button--hover, .usa-menu-btn:disabled:hover, .usa-menu-btn[aria-disabled="true"]:hover, .usa-menu-btn:disabled.usa-button--hover, .usa-menu-btn[aria-disabled="true"].usa-button--hover, .usa-menu-btn:active, .usa-menu-btn.usa-button--active, .usa-menu-btn:disabled:active, .usa-menu-btn[aria-disabled="true"]:active, .usa-menu-btn:disabled.usa-button--active, .usa-menu-btn[aria-disabled="true"].usa-button--active, .usa-menu-btn:disabled:focus, .usa-menu-btn[aria-disabled="true"]:focus, .usa-menu-btn:disabled.usa-focus, .usa-menu-btn[aria-disabled="true"].usa-focus, .usa-menu-btn:disabled, .usa-menu-btn[aria-disabled="true"], .usa-menu-btn.usa-button--disabled {
  box-shadow: none;
  background-color: #0000;
  text-decoration: underline;
}

.usa-menu-btn.usa-button--hover {
  color: #1a4480;
}

.usa-menu-btn.usa-button--active {
  color: #162e51;
}

.usa-menu-btn:disabled, .usa-menu-btn[aria-disabled="true"], .usa-menu-btn:disabled:hover, .usa-menu-btn[aria-disabled="true"]:hover, .usa-menu-btn[aria-disabled="true"]:focus {
  color: #757575;
}

@media (forced-colors: active) {
  .usa-menu-btn:disabled, .usa-menu-btn[aria-disabled="true"], .usa-menu-btn:disabled:hover, .usa-menu-btn[aria-disabled="true"]:hover, .usa-menu-btn[aria-disabled="true"]:focus {
    color: graytext;
  }
}

@media (width >= 64em) {
  .usa-menu-btn {
    display: none;
  }
}

.usa-menu-btn:hover {
  color: #fff;
  background-color: #1a4480;
  text-decoration: none;
}

.usa-menu-btn:active, .usa-menu-btn:visited {
  color: #fff;
}

.usa-overlay {
  opacity: 0;
  visibility: hidden;
  z-index: 400;
  background: #000000b3;
  transition: opacity .15s ease-in-out;
  position: fixed;
  inset: 0;
}

.usa-overlay.is-visible {
  opacity: 1;
  visibility: visible;
}

@media (width >= 64em) {
  .usa-header--basic .usa-navbar {
    width: 33%;
    position: relative;
  }

  .usa-header--basic .usa-nav {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 0 0 .5rem .5rem;
    display: flex;
  }

  .usa-header--basic .usa-nav-container {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
  }

  .usa-header--basic .usa-nav__primary-item > .usa-current, .usa-header--basic .usa-nav__link:hover {
    position: relative;
  }

  .usa-header--basic .usa-nav__primary-item > .usa-current:after, .usa-header--basic .usa-nav__link:hover:after {
    content: "";
    background-color: #005ea2;
    border-radius: 0;
    height: .25rem;
    display: block;
    position: absolute;
    bottom: -.25rem;
    left: 1rem;
    right: 1rem;
  }
}

@media (width >= 64em) and (forced-colors: active) {
  .usa-header--basic .usa-nav__primary-item > .usa-current:after, .usa-header--basic .usa-nav__link:hover:after {
    background-color: buttontext;
  }
}

@media (width >= 64em) {
  .usa-header--basic .usa-nav__link[aria-expanded="true"]:after, .usa-header--basic .usa-nav__link[aria-expanded="true"]:hover:after {
    display: none;
  }

  .usa-header--basic .usa-nav__primary {
    width: auto;
  }

  .usa-header--basic .usa-nav__primary-item:last-of-type {
    position: relative;
  }

  .usa-header--basic .usa-nav__primary-item:last-of-type .usa-nav__submenu {
    position: absolute;
    right: 0;
  }

  .usa-header--basic .usa-search {
    top: 0;
  }
}

.usa-header--basic.usa-header--megamenu .usa-nav__inner {
  flex-direction: column;
  display: flex;
}

@media (width >= 64em) {
  .usa-header--basic.usa-header--megamenu .usa-nav__inner {
    float: right;
    margin-top: -2.5rem;
    display: block;
  }

  .usa-header--basic.usa-header--megamenu .usa-nav__primary-item:last-of-type {
    position: static;
  }

  .usa-header--extended {
    padding-top: 0;
  }

  .usa-header--extended .usa-nav__primary-item > .usa-current, .usa-header--extended .usa-nav__primary-item > .usa-nav__link:hover {
    position: relative;
  }

  .usa-header--extended .usa-nav__primary-item > .usa-current:after, .usa-header--extended .usa-nav__primary-item > .usa-nav__link:hover:after {
    content: "";
    background-color: #005ea2;
    border-radius: 0;
    height: .25rem;
    display: block;
    position: absolute;
    bottom: 0;
    left: 1rem;
    right: 1rem;
  }
}

@media (width >= 64em) and (forced-colors: active) {
  .usa-header--extended .usa-nav__primary-item > .usa-current:after, .usa-header--extended .usa-nav__primary-item > .usa-nav__link:hover:after {
    background-color: buttontext;
  }
}

@media (width >= 64em) {
  .usa-header--extended .usa-nav__link[aria-expanded="true"]:after, .usa-header--extended .usa-nav__link[aria-expanded="true"]:hover:after {
    display: none;
  }

  .usa-header--extended .usa-logo {
    max-width: 33%;
    margin: 2rem 0 1.5rem;
    font-size: 1.99rem;
  }

  .usa-header--extended .usa-navbar {
    max-width: 64rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    display: block;
    overflow: auto;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .usa-header--extended .usa-navbar {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .usa-header--extended .usa-nav {
    border-top: 1px solid #dfe1e2;
    width: 100%;
    padding: 0;
  }

  .usa-header--extended .usa-nav__inner {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .usa-header--extended .usa-nav__inner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 64em) {
  .usa-header--extended .usa-nav__primary {
    margin-left: -1rem;
  }

  .usa-header--extended .usa-nav__primary:after {
    clear: both;
    content: "";
    display: block;
  }

  .usa-header--extended .usa-nav__link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .usa-header--extended .usa-nav__submenu .usa-grid-full {
    padding-left: .75rem;
  }

  .usa-header--extended .usa-nav__submenu.usa-megamenu {
    padding-left: 2rem;
    padding-right: 2rem;
    left: 0;
  }
}

html.usa-js-loading .usa-nav__submenu, html.usa-js-loading .usa-nav__submenu.usa-megamenu {
  position: absolute;
  left: -999em;
  right: auto;
}

.usa-megamenu .usa-col {
  flex: auto;
}

@media (width >= 64em) {
  .usa-megamenu .usa-col {
    flex: 4;
  }

  .usa-megamenu .usa-col .usa-nav__submenu-item a {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .usa-megamenu .usa-col:first-child .usa-nav__submenu-item a {
    padding-left: 0;
  }

  .usa-megamenu .usa-col:last-child .usa-nav__submenu-item a {
    padding-right: 0;
  }

  .usa-megamenu.usa-nav__submenu {
    width: auto;
    padding: 2rem 0;
    left: -33%;
    right: 0;
  }

  .usa-megamenu.usa-nav__submenu:before {
    content: "";
    background-color: #162e51;
    width: calc(50vw - 30rem);
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
  }

  .usa-megamenu.usa-nav__submenu:after {
    content: "";
    background-color: #162e51;
    width: calc(50vw - 30rem);
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
  }

  .usa-header--extended .usa-megamenu.usa-nav__submenu:before, .usa-header--extended .usa-megamenu.usa-nav__submenu:after {
    content: "";
    background-color: #162e51;
    width: calc(50vw - 32rem);
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .usa-nav-container {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .usa-nav-container:after {
    clear: both;
    content: "";
    display: block;
  }
}

@media (width >= 64em) and (width >= 64em) {
  .usa-nav-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.usa-navbar {
  height: 3rem;
}

@media (width <= 63.99em) {
  .usa-navbar {
    border-bottom: 1px solid #dfe1e2;
    align-items: center;
    display: flex;
  }
}

@media (width >= 64em) {
  .usa-navbar {
    border-bottom: none;
    height: auto;
    display: inline-block;
  }
}

.usa-alert {
  color: #1c1d1f;
  background-color: #f0f0f0;
  border-left: .5rem solid #a9aeb1;
}

.usa-alert .usa-alert__body {
  font-family: var(--base-font-family), ;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: .99rem;
  line-height: 1.6;
  position: relative;
}

.usa-alert .usa-alert__text {
  margin-top: 0;
  margin-bottom: 0;
}

.usa-alert .usa-alert__text:only-child {
  padding-top: 0;
  padding-bottom: 0;
}

.usa-alert .usa-alert__heading {
  font-family: var(--base-font-family), ;
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.37rem;
  line-height: 1;
}

.usa-alert > .usa-list, .usa-alert .usa-alert__body > .usa-list {
  padding-left: 2ch;
}

.usa-alert > .usa-list:last-child, .usa-alert .usa-alert__body > .usa-list:last-child {
  margin-bottom: 0;
}

.usa-alert .usa-alert__body {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.usa-alert .usa-alert__body:before {
  left: 1.08333rem;
}

* + .usa-alert {
  margin-top: 1rem;
}

.usa-alert--success {
  background-color: #ecf3ec;
  border-left-color: #00a91c;
}

.usa-alert--success .usa-alert__body {
  color: #1c1d1f;
  background-color: #ecf3ec;
  flex-direction: column;
  justify-content: center;
  min-height: 3.33333rem;
  display: flex;
}

.usa-alert--success .usa-alert__body:before {
  content: "";
  background-image: url("./img/usa-icons/check_circle.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
  width: 2rem;
  height: 2rem;
  display: block;
  position: absolute;
  top: .666667rem;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--success .usa-alert__body:before {
    background: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/check_circle.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/check_circle.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.usa-alert--success .usa-alert__body > * {
  margin-left: 2.5rem;
}

.usa-alert--success .usa-alert__body .usa-link {
  color: #005ea2;
}

.usa-alert--success .usa-alert__body .usa-link:visited {
  color: #54278f;
}

.usa-alert--success .usa-alert__body .usa-link:hover, .usa-alert--success .usa-alert__body .usa-link:active {
  color: #1a4480;
}

.usa-alert--warning {
  background-color: #faf3d1;
  border-left-color: #ffbe2e;
}

.usa-alert--warning .usa-alert__body {
  color: #1c1d1f;
  background-color: #faf3d1;
  flex-direction: column;
  justify-content: center;
  min-height: 3.33333rem;
  display: flex;
}

.usa-alert--warning .usa-alert__body:before {
  content: "";
  background-image: url("./img/usa-icons/warning.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
  width: 2rem;
  height: 2rem;
  display: block;
  position: absolute;
  top: .666667rem;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--warning .usa-alert__body:before {
    background: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/warning.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/warning.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.usa-alert--warning .usa-alert__body > * {
  margin-left: 2.5rem;
}

.usa-alert--warning .usa-alert__body .usa-link {
  color: #005ea2;
}

.usa-alert--warning .usa-alert__body .usa-link:visited {
  color: #54278f;
}

.usa-alert--warning .usa-alert__body .usa-link:hover, .usa-alert--warning .usa-alert__body .usa-link:active {
  color: #1a4480;
}

.usa-alert--error {
  background-color: #f4e3db;
  border-left-color: #d54309;
}

.usa-alert--error .usa-alert__body {
  color: #1c1d1f;
  background-color: #f4e3db;
  flex-direction: column;
  justify-content: center;
  min-height: 3.33333rem;
  display: flex;
}

.usa-alert--error .usa-alert__body:before {
  content: "";
  background-image: url("./img/usa-icons/error.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
  width: 2rem;
  height: 2rem;
  display: block;
  position: absolute;
  top: .666667rem;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--error .usa-alert__body:before {
    background: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/error.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/error.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.usa-alert--error .usa-alert__body > * {
  margin-left: 2.5rem;
}

.usa-alert--error .usa-alert__body .usa-link {
  color: #005ea2;
}

.usa-alert--error .usa-alert__body .usa-link:visited {
  color: #54278f;
}

.usa-alert--error .usa-alert__body .usa-link:hover, .usa-alert--error .usa-alert__body .usa-link:active {
  color: #1a4480;
}

.usa-alert--info {
  background-color: #e7f6f8;
  border-left-color: #00bde3;
}

.usa-alert--info .usa-alert__body {
  color: #1c1d1f;
  background-color: #e7f6f8;
  flex-direction: column;
  justify-content: center;
  min-height: 3.33333rem;
  display: flex;
}

.usa-alert--info .usa-alert__body:before {
  content: "";
  background-image: url("./img/usa-icons/info.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
  width: 2rem;
  height: 2rem;
  display: block;
  position: absolute;
  top: .666667rem;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--info .usa-alert__body:before {
    background: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/info.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/info.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.usa-alert--info .usa-alert__body > * {
  margin-left: 2.5rem;
}

.usa-alert--info .usa-alert__body .usa-link {
  color: #005ea2;
}

.usa-alert--info .usa-alert__body .usa-link:visited {
  color: #54278f;
}

.usa-alert--info .usa-alert__body .usa-link:hover, .usa-alert--info .usa-alert__body .usa-link:active {
  color: #1a4480;
}

.usa-alert--emergency {
  background-color: #9c3d10;
  border-left-color: #9c3d10;
}

.usa-alert--emergency .usa-alert__body {
  color: #fff;
  background-color: #9c3d10;
  flex-direction: column;
  justify-content: center;
  min-height: 3.33333rem;
  display: flex;
}

.usa-alert--emergency .usa-alert__body:before {
  content: "";
  background-image: url("./img/usa-icons-bg/error--white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
  width: 2rem;
  height: 2rem;
  display: block;
  position: absolute;
  top: .666667rem;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--emergency .usa-alert__body:before {
    background: #fff;
    -webkit-mask-image: url("./img/usa-icons/error.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/error.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.usa-alert--emergency .usa-alert__body > * {
  margin-left: 2.5rem;
}

.usa-alert--emergency .usa-alert__body .usa-link, .usa-alert--emergency .usa-alert__body .usa-link:visited {
  color: #dfe1e2;
}

.usa-alert--emergency .usa-alert__body .usa-link:hover, .usa-alert--emergency .usa-alert__body .usa-link:active {
  color: #f0f0f0;
}

.usa-alert--slim .usa-alert__body {
  min-height: 0;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.usa-alert--slim .usa-alert__body:before {
  width: 1.5rem;
  height: 1.5rem;
  top: .5rem;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-alert--slim .usa-alert__body:before {
    -webkit-mask-size: 1.5rem;
    mask-size: 1.5rem;
  }
}

.usa-alert--slim .usa-alert__body > * {
  margin-left: 2rem;
}

.usa-alert--slim .usa-alert__body:before {
  left: 1.125rem;
}

.usa-alert--no-icon .usa-alert__body {
  min-height: 0;
}

.usa-alert--no-icon .usa-alert__body:before {
  display: none;
}

.usa-alert--no-icon .usa-alert__body > * {
  margin-left: 0;
}

.usa-alert--validation .usa-checklist {
  margin-top: 1rem;
}

.usa-site-alert .usa-alert {
  color: #1c1d1f;
  background-color: #f0f0f0;
  border-left: .5rem solid #a9aeb1;
}

.usa-site-alert .usa-alert .usa-alert__body {
  font-family: var(--base-font-family), ;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: .99rem;
  line-height: 1.6;
  position: relative;
}

.usa-site-alert .usa-alert .usa-alert__text {
  margin-top: 0;
  margin-bottom: 0;
}

.usa-site-alert .usa-alert .usa-alert__text:only-child {
  padding-top: 0;
  padding-bottom: 0;
}

.usa-site-alert .usa-alert .usa-alert__heading {
  font-family: var(--base-font-family), ;
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: 1.37rem;
  line-height: 1;
}

.usa-site-alert .usa-alert > .usa-list, .usa-site-alert .usa-alert .usa-alert__body > .usa-list {
  padding-left: 2ch;
}

.usa-site-alert .usa-alert > .usa-list:last-child, .usa-site-alert .usa-alert .usa-alert__body > .usa-list:last-child {
  margin-bottom: 0;
}

.usa-site-alert .usa-alert .usa-alert__body {
  max-width: 64rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.usa-site-alert .usa-alert .usa-alert__body:before {
  left: 1.08333rem;
}

@media (width >= 64em) {
  .usa-site-alert .usa-alert .usa-alert__body {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .usa-site-alert .usa-alert .usa-alert__body:before {
    left: 1.58333rem;
  }
}

.usa-site-alert--info .usa-alert {
  background-color: #e7f6f8;
  border-left-color: #00bde3;
}

.usa-site-alert--info .usa-alert .usa-alert__body {
  color: #1c1d1f;
  background-color: #e7f6f8;
  flex-direction: column;
  justify-content: center;
  min-height: 3.33333rem;
  display: flex;
}

.usa-site-alert--info .usa-alert .usa-alert__body:before {
  content: "";
  background-image: url("./img/usa-icons/info.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
  width: 2rem;
  height: 2rem;
  display: block;
  position: absolute;
  top: .666667rem;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-site-alert--info .usa-alert .usa-alert__body:before {
    background: #1c1d1f;
    -webkit-mask-image: url("./img/usa-icons/info.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/info.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.usa-site-alert--info .usa-alert .usa-alert__body > * {
  margin-left: 2.5rem;
}

.usa-site-alert--info .usa-alert .usa-alert__body .usa-link {
  color: #005ea2;
}

.usa-site-alert--info .usa-alert .usa-alert__body .usa-link:visited {
  color: #54278f;
}

.usa-site-alert--info .usa-alert .usa-alert__body .usa-link:hover, .usa-site-alert--info .usa-alert .usa-alert__body .usa-link:active {
  color: #1a4480;
}

.usa-site-alert--emergency .usa-alert {
  background-color: #9c3d10;
  border-left-color: #9c3d10;
}

.usa-site-alert--emergency .usa-alert .usa-alert__body {
  color: #fff;
  background-color: #9c3d10;
  flex-direction: column;
  justify-content: center;
  min-height: 3.33333rem;
  display: flex;
}

.usa-site-alert--emergency .usa-alert .usa-alert__body:before {
  content: "";
  background-image: url("./img/usa-icons-bg/error--white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
  width: 2rem;
  height: 2rem;
  display: block;
  position: absolute;
  top: .666667rem;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-site-alert--emergency .usa-alert .usa-alert__body:before {
    background: #fff;
    -webkit-mask-image: url("./img/usa-icons/error.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/error.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 2rem 2rem;
    mask-size: 2rem 2rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

.usa-site-alert--emergency .usa-alert .usa-alert__body > * {
  margin-left: 2.5rem;
}

.usa-site-alert--emergency .usa-alert .usa-alert__body .usa-link, .usa-site-alert--emergency .usa-alert .usa-alert__body .usa-link:visited {
  color: #dfe1e2;
}

.usa-site-alert--emergency .usa-alert .usa-alert__body .usa-link:hover, .usa-site-alert--emergency .usa-alert .usa-alert__body .usa-link:active {
  color: #f0f0f0;
}

.usa-site-alert--slim .usa-alert .usa-alert__body {
  min-height: 0;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.usa-site-alert--slim .usa-alert .usa-alert__body:before {
  width: 1.5rem;
  height: 1.5rem;
  top: .5rem;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-site-alert--slim .usa-alert .usa-alert__body:before {
    -webkit-mask-size: 1.5rem;
    mask-size: 1.5rem;
  }
}

.usa-site-alert--slim .usa-alert .usa-alert__body > * {
  margin-left: 2rem;
}

.usa-site-alert--slim .usa-alert .usa-alert__body:before {
  left: 1.125rem;
}

@media (width >= 64em) {
  .usa-site-alert--slim .usa-alert .usa-alert__body:before {
    left: 1.625rem;
  }
}

.usa-site-alert--no-icon .usa-alert .usa-alert__body {
  min-height: 0;
}

.usa-site-alert--no-icon .usa-alert .usa-alert__body:before {
  display: none;
}

.usa-site-alert--no-icon .usa-alert .usa-alert__body > * {
  margin-left: 0;
}

.usa-media-block {
  align-items: flex-start;
  display: flex;
}

.usa-media-block__img {
  flex-shrink: 0;
  margin-right: .5rem;
}

.usa-media-block__body {
  flex: 1;
}

.usa-banner {
  font-family: var(--base-font-family), ;
  background-color: #f0f0f0;
  font-size: .99rem;
  line-height: 1.6;
}

@media (width >= 40em) {
  .usa-banner {
    padding-bottom: 0;
    font-size: .81rem;
  }
}

.usa-banner .usa-accordion {
  font-family: var(--base-font-family), ;
  font-size: .99rem;
  line-height: 1.6;
}

.usa-banner .grid-row {
  flex-wrap: wrap;
  display: flex;
}

.usa-banner .grid-row.grid-gap-lg {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.usa-banner .grid-row.grid-gap-lg > * {
  padding-left: .75rem;
  padding-right: .75rem;
}

@media (width >= 40em) {
  .usa-banner .grid-row .tablet\:grid-col-6 {
    flex: 0 auto;
    width: 50%;
  }
}

.usa-banner__header, .usa-banner__content {
  color: #1c1d1f;
}

.usa-banner__content {
  background-color: #0000;
  width: 100%;
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
  padding: .25rem 1rem 1rem .5rem;
  font-size: .93rem;
  overflow: hidden;
}

@media (width >= 64em) {
  .usa-banner__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .usa-banner__content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.usa-banner__content p:first-child {
  margin: 0;
}

.usa-banner__guidance {
  align-items: flex-start;
  max-width: 64ex;
  padding-top: 1rem;
  display: flex;
}

@media (width >= 40em) {
  .usa-banner__guidance {
    padding-top: 0;
  }
}

.usa-banner__lock-image {
  width: 1.21875ex;
  height: 1.5ex;
}

.usa-banner__lock-image path {
  fill: currentColor;
}

@media (forced-colors: active) {
  .usa-banner__lock-image path {
    fill: canvastext;
  }
}

.usa-banner__inner {
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 0;
  display: flex;
}

@media (width >= 64em) {
  .usa-banner__inner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .usa-banner__inner {
    align-items: center;
  }
}

.usa-banner__header {
  min-height: 3rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .75rem;
  font-weight: normal;
  position: relative;
}

@media (width >= 40em) {
  .usa-banner__header {
    min-height: 0;
    padding-top: .25rem;
    padding-bottom: .25rem;
  }
}

.usa-banner__header-text {
  margin-top: 0;
  margin-bottom: 0;
  font-size: .75rem;
  line-height: 1.2;
}

.usa-banner__header-action {
  color: #005ea2;
  margin-top: 2px;
  margin-bottom: 0;
  line-height: 1.2;
  text-decoration: underline;
}

.usa-banner__header-action:after {
  content: "";
  vertical-align: middle;
  background-image: url("./img/usa-icons/expand_more.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  display: inline-block;
}

@supports ((-webkit-mask: url("")) or (mask: url(""))) {
  .usa-banner__header-action:after {
    background: #005ea2;
    -webkit-mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
    mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: 1rem 1rem;
    mask-size: 1rem 1rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }

  .usa-banner__header-action:after:hover {
    background-color: #1a4480;
  }
}

.usa-banner__header-action:hover:after {
  content: "";
  background-color: #1a4480;
}

.usa-banner__header-action:visited {
  color: #54278f;
}

.usa-banner__header-action:hover, .usa-banner__header-action:active {
  color: #1a4480;
}

@media (width >= 40em) {
  .usa-banner__header-action {
    display: none;
  }
}

@media (forced-colors: active) {
  .usa-banner__header-action {
    color: linktext;
  }

  .usa-banner__header-action:after {
    background-color: buttontext;
  }
}

.usa-banner__header-flag {
  float: left;
  width: 1rem;
  margin-right: .5rem;
}

@media (width >= 40em) {
  .usa-banner__header-flag {
    margin-right: .5rem;
    padding-top: 0;
  }
}

.usa-banner__header--expanded {
  padding-right: 3.5rem;
}

@media (width >= 40em) {
  .usa-banner__header--expanded {
    background-color: #0000;
    min-height: 0;
    padding-right: 0;
    font-size: .75rem;
    font-weight: normal;
    display: block;
  }
}

.usa-banner__header--expanded .usa-banner__inner {
  margin-left: 0;
}

@media (width >= 40em) {
  .usa-banner__header--expanded .usa-banner__inner {
    margin-left: auto;
  }
}

.usa-banner__header--expanded .usa-banner__header-action {
  display: none;
}

.usa-banner__button {
  color: #005ea2;
  box-shadow: none;
  text-align: left;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  justify-content: normal;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0 0 0 0;
  font-size: .75rem;
  font-weight: normal;
  line-height: 1.2;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.usa-banner__button:hover {
  color: #1a4480;
}

.usa-banner__button:active {
  color: #162e51;
}

.usa-banner__button:focus {
  outline-offset: 0rem;
  outline: .25rem solid #2491ff;
}

.usa-banner__button:hover, .usa-banner__button.usa-button--hover, .usa-banner__button:disabled:hover, .usa-banner__button[aria-disabled="true"]:hover, .usa-banner__button:disabled.usa-button--hover, .usa-banner__button[aria-disabled="true"].usa-button--hover, .usa-banner__button:active, .usa-banner__button.usa-button--active, .usa-banner__button:disabled:active, .usa-banner__button[aria-disabled="true"]:active, .usa-banner__button:disabled.usa-button--active, .usa-banner__button[aria-disabled="true"].usa-button--active, .usa-banner__button:disabled:focus, .usa-banner__button[aria-disabled="true"]:focus, .usa-banner__button:disabled.usa-focus, .usa-banner__button[aria-disabled="true"].usa-focus, .usa-banner__button:disabled, .usa-banner__button[aria-disabled="true"], .usa-banner__button.usa-button--disabled {
  box-shadow: none;
  background-color: #0000;
  text-decoration: underline;
}

.usa-banner__button.usa-button--hover {
  color: #1a4480;
}

.usa-banner__button.usa-button--active {
  color: #162e51;
}

.usa-banner__button:disabled, .usa-banner__button[aria-disabled="true"], .usa-banner__button:disabled:hover, .usa-banner__button[aria-disabled="true"]:hover, .usa-banner__button[aria-disabled="true"]:focus {
  color: #757575;
}

@media (forced-colors: active) {
  .usa-banner__button:disabled, .usa-banner__button[aria-disabled="true"], .usa-banner__button:disabled:hover, .usa-banner__button[aria-disabled="true"]:hover, .usa-banner__button[aria-disabled="true"]:focus {
    color: graytext;
  }
}

.usa-banner__button:visited {
  color: #54278f;
}

.usa-banner__button:hover, .usa-banner__button:active {
  color: #1a4480;
}

@media (width <= 39.99em) {
  .usa-banner__button {
    width: 100%;
  }

  .usa-banner__button:enabled:focus {
    outline-offset: -.25rem;
  }
}

@media (width >= 40em) {
  .usa-banner__button {
    color: #005ea2;
    margin-left: .5rem;
    display: inline;
    position: relative;
    inset: auto;
  }

  .usa-banner__button:after {
    content: "";
    vertical-align: middle;
    background-image: url("./img/usa-icons/expand_more.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-left: 2px;
    display: inline-block;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-banner__button:after {
      background: #005ea2;
      -webkit-mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/expand_more.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1rem 1rem;
      mask-size: 1rem 1rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }

    .usa-banner__button:after:hover {
      background-color: #1a4480;
    }
  }

  .usa-banner__button:hover:after {
    content: "";
    background-color: #1a4480;
  }

  .usa-banner__button:visited {
    color: #54278f;
  }

  .usa-banner__button:hover, .usa-banner__button:active {
    color: #1a4480;
  }

  .usa-banner__button:after, .usa-banner__button:hover:after {
    position: absolute;
  }
}

@media (width >= 40em) and (forced-colors: active) {
  .usa-banner__button:after, .usa-banner__button:hover:after {
    background-color: buttontext;
  }
}

@media (width >= 40em) {
  .usa-banner__button:hover {
    text-decoration: none;
  }
}

.usa-banner__button[aria-expanded="false"], .usa-banner__button[aria-expanded="false"]:hover, .usa-banner__button[aria-expanded="true"], .usa-banner__button[aria-expanded="true"]:hover {
  background-image: none;
}

@media (forced-colors: active) {
  .usa-banner__button[aria-expanded="false"]:before, .usa-banner__button[aria-expanded="false"]:hover:before, .usa-banner__button[aria-expanded="true"]:before, .usa-banner__button[aria-expanded="true"]:hover:before {
    content: none;
  }
}

@media (width <= 39.99em) {
  .usa-banner__button[aria-expanded="true"]:after {
    content: "";
    vertical-align: middle;
    background-image: url("./img/usa-icons/close.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.5rem 1.5rem;
    width: 3rem;
    height: 3rem;
    margin-left: 0;
    display: inline-block;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-banner__button[aria-expanded="true"]:after {
      background: #005ea2;
      -webkit-mask-image: url("./img/usa-icons/close.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/close.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1.5rem 1.5rem;
      mask-size: 1.5rem 1.5rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }

  .usa-banner__button[aria-expanded="true"]:before {
    content: "";
    background-color: #dfe1e2;
    width: 3rem;
    height: 3rem;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .usa-banner__button[aria-expanded="true"]:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

@media (width >= 40em) {
  .usa-banner__button[aria-expanded="true"] {
    height: auto;
    padding: 0;
    position: relative;
  }

  .usa-banner__button[aria-expanded="true"]:after {
    content: "";
    vertical-align: middle;
    background-image: url("./img/usa-icons/expand_less.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-left: 2px;
    display: inline-block;
  }

  @supports ((-webkit-mask: url("")) or (mask: url(""))) {
    .usa-banner__button[aria-expanded="true"]:after {
      background: #005ea2;
      -webkit-mask-image: url("./img/usa-icons/expand_less.svg"), linear-gradient(#0000, #0000);
      mask-image: url("./img/usa-icons/expand_less.svg"), linear-gradient(#0000, #0000);
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-size: 1rem 1rem;
      mask-size: 1rem 1rem;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }

    .usa-banner__button[aria-expanded="true"]:after:hover {
      background-color: #1a4480;
    }
  }

  .usa-banner__button[aria-expanded="true"]:hover:after {
    content: "";
    background-color: #1a4480;
  }

  .usa-banner__button[aria-expanded="true"]:after, .usa-banner__button[aria-expanded="true"]:hover:after {
    position: absolute;
  }
}

@media (width >= 40em) and (forced-colors: active) {
  .usa-banner__button[aria-expanded="true"]:after, .usa-banner__button[aria-expanded="true"]:hover:after {
    background-color: buttontext;
  }
}

.usa-banner__button-text {
  text-decoration: underline;
  position: absolute;
  left: -999em;
  right: auto;
}

@media (width >= 40em) {
  .usa-banner__button-text {
    display: inline;
    position: static;
  }
}

@media (forced-colors: active) {
  .usa-banner__button-text {
    color: linktext;
  }
}

.usa-banner__icon {
  width: 2.5rem;
}

.usa-js-loading .usa-banner__content {
  position: absolute;
  left: -999em;
  right: auto;
}

.usa-logo {
  max-width: none !important;
}

.usa-nav__secondary-item {
  font-weight: normal;
}

@media (width >= 64rem) {
  .usa-nav__secondary-links {
    margin-bottom: 1.5rem;
  }

  .usa-nav__secondary-item .usa-nav__link {
    color: #565c65;
    padding: 0;
  }

  .usa-nav__primary button[aria-expanded="false"] span:after {
    background-color: #565c65;
  }
}

.usa-nav__secondary-links button {
  color: #71767a;
  padding: 0;
  font-size: .87rem;
  text-decoration: none;
  display: inline-block;
}

@media (width <= 64rem) {
  .usa-nav__primary button {
    font-weight: 300;
  }

  .usa-nav__primary button[aria-expanded="false"] span:after, .usa-nav__primary button[aria-expanded="true"] span:after {
    display: none;
  }

  .usa-nav__primary button[aria-expanded="true"] {
    font-weight: 700;
  }

  .usa-nav__primary button[aria-expanded="true"]:after {
    content: "";
    background-color: #005ea2;
    width: .25rem;
    display: block;
    position: absolute;
    top: .25rem;
    bottom: .25rem;
    left: .25rem;
  }

  .usa-nav__primary button:hover {
    background-color: #fff;
  }

  .usa-nav__submenu-item {
    font-size: .99rem;
  }
}

.usa-banner__button:after {
  top: 3px;
}

.banner--left-aligned .usa-banner__inner, .banner--left-aligned .usa-banner__content {
  margin: 0;
}

.react-calendar {
  z-index: 99999;
  font-family: inherit !important;
}

.react-calendar * {
  font-family: var(--base-font-family), ;
  font-size: .87rem;
  line-height: 1.6;
  font-family: inherit !important;
}

.react-calendar__navigation {
  margin-bottom: 0 !important;
}

.react-calendar__month-view__weekdays__weekday {
  color: #1b1b1b;
}

.react-calendar__tile--active, .react-calendar__tile--hasActive {
  color: #f0f0f0;
  background-color: #0050d8 !important;
}

.react-calendar__tile--now:not(.react-calendar__tile--active), .react-calendar__tile--now:not(.react-calendar__tile--hasActive) {
  color: #1b1b1b !important;
  background: none !important;
}

.react-calendar__tile--now:not(.react-calendar__tile--active):hover, .react-calendar__tile--now:not(.react-calendar__tile--active):focus, .react-calendar__tile--now:not(.react-calendar__tile--hasActive):hover, .react-calendar__tile--now:not(.react-calendar__tile--hasActive):focus {
  background-color: #e6e6e6 !important;
}

.react-calendar__tile--now:disabled {
  color: #adadad !important;
  background-color: #f0f0f0 !important;
}

.react-calendar__tile--now:disabled:hover, .react-calendar__tile--now:disabled:focus {
  background-color: #f0f0f0 !important;
}

.react-calendar .usa-icon {
  width: 32px;
  height: 20px;
}

.react-calendar__navigation-button {
  min-width: 32px;
}

.react-calendar [class*="--weekend"]:not(.react-calendar__tile--active) {
  color: #1b1b1b !important;
}

.react-calendar abbr[title] {
  font-weight: normal;
  text-decoration: none;
}

.react-calendar .react-calendar__year-view .react-calendar__tile, .react-calendar .react-calendar__decade-view .react-calendar__tile, .react-calendar .react-calendar__century-view .react-calendar__tile {
  padding: 1em .1em !important;
}

.react-calendar__tippy {
  box-shadow: none !important;
  background: none !important;
  border: none !important;
}

.react-calendar__tippy[data-placement^="top"] > .tippy-arrow:before {
  border-top-color: #0000 !important;
}

.react-calendar__tippy[data-placement^="bottom"] > .tippy-arrow:before {
  border-bottom-color: #0000 !important;
}

.react-calendar__tippy[data-placement^="left"] > .tippy-arrow:before {
  border-left-color: #0000 !important;
}

.react-calendar__tippy[data-placement^="right"] > .tippy-arrow:before {
  border-right-color: #0000 !important;
}

.react-calendar__tippy .tippy-content {
  padding: 0 !important;
}

.footer-text {
  color: #fff;
  vertical-align: baseline;
  gap: .75rem;
  font-size: .99rem;
  font-weight: normal;
  display: flex;
}

.return-to-top-container {
  max-width: 64rem;
  padding: 2rem 2.5rem;
}

@media (width <= 40rem) {
  .return-to-top-container {
    padding: 1.5rem;
  }
}

.footer_primary_container {
  background-color: #f1f3f6;
  padding: 2rem 2.5rem;
}

@media (width <= 40rem) {
  .footer_primary_container {
    padding: 1.5rem;
  }

  .footer_primary_container .grid-row.grid-gap {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .footer_primary_container .grid-row.grid-gap > li {
    padding-left: 0;
    padding-right: 0;
  }
}

.usa-footer__secondary-section {
  background-color: #2d2e2f;
}

.usa-footer__primary-section {
  text-underline-offset: .25rem;
  background-color: #f1f3f6;
}

.usa-footer__address > .grid-row {
  justify-content: flex-end;
}

@media (width <= 40rem) {
  .usa-footer__address > .grid-row {
    justify-content: flex-start;
  }

  .usa-footer__address {
    padding: 2rem 0 !important;
  }
}

#footer_secondary_container {
  flex-direction: row;
}

@media (width <= 40rem) {
  #footer_secondary_container {
    flex-direction: column;
  }
}

address {
  text-underline-offset: .25rem;
  font-size: .99rem;
  font-style: normal;
  font-weight: normal;
}

.usa-footer__contact-info {
  line-height: unset;
}

@media (width <= 40rem) {
  .usa-footer__contact-info {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.usa-footer__primary-content:first-child {
  border-top: none !important;
}

@media (width <= 64rem) {
  .usa-footer__address .mobile-lg\:grid-col-12, .usa-footer__nav .mobile-lg\:grid-col-6 {
    width: auto;
  }
}

@media (width <= 40rem) {
  .usa-footer__address .grid-row [class*="grid-col"] {
    width: auto !important;
  }

  .usa-footer .grid-row [class*="grid-col"] {
    width: 100%;
  }

  .usa-footer__primary-content {
    border-top: 1px solid #a9aeb1;
  }

  .usa-footer__primary-content:last-child {
    border-bottom: 1px solid #a9aeb1;
  }
}

@media (width <= 40rem) and (width <= 30rem) {
  .usa-footer__primary-content:last-child {
    border-bottom: 0;
  }
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  white-space: normal;
  background-color: #333;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  color: #333;
  width: 16px;
  height: 16px;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

#logo-container {
  font-family: var(--base-font-family), ;
  gap: .75rem;
  max-height: 2.5rem;
  display: flex;
}

#logo-container #logo-container-link {
  color: #1c1d1f;
  align-items: center;
  gap: .75rem;
  font-size: 1.37rem;
  font-weight: 700;
  display: flex;
}

#logo-container #nasa-logo-pos {
  opacity: 1;
  transform: translate(0, -100%);
}

#logo-container svg {
  width: auto;
  height: 2.5rem;
}

#logo-container #logo-container-beta-tag {
  color: #fff;
  background-color: #d83933;
  border-radius: 2px;
  align-self: center;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .81rem;
  font-weight: normal;
}
/*# sourceMappingURL=index.d7c48f87.css.map */
